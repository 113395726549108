import { FormBuilderProps } from '@/common/customComponenttypes'
import { Button, Fab, FormBuilder } from '@/components/common'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  getLoadLineInfoData,
  updateLoadLineInfoData
} from '@/modules/technical/pages/vesselParticulars/details/technical/api'
import {
  loadLineInfoFormSchema,
  loadLineInfoFormSchemaType
} from '@/modules/technical/pages/vesselParticulars/details/technical/schema'
import { formContainerClass } from '@/styles/variables'
import useCustomSnackBar from '@/utilities/customSnackBar'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'

type TechnicalDetailFormProps = {
  vesselID: string
  setEdit: (status: boolean) => void
  edit: boolean
  handleEdit: () => Promise<void>
  setWarningOpen: (value: React.SetStateAction<boolean>) => void
}

const LoadLineInfoForm: React.FC<TechnicalDetailFormProps> = ({
  vesselID,
  setEdit,
  edit,
  handleEdit,
  setWarningOpen
}) => {
  const { t } = useTranslation()
  const [actionLoader, setActionLoader] = useState<boolean>(false)
  const [rowData, setRowData] = useState<any>({})
  const { showSnackBar } = useCustomSnackBar()
  const { width } = useScreenBreakPoint()

  const getData = async () => {
    const data = await getLoadLineInfoData(vesselID)
    setRowData(data?.data)
  }
    const generateCommonSpacing = () => {
      if (width > 1200) {
        return 3
      } else if (width > 1000) {
        return 4
      } else if (width > 700) {
        return 6
      }
      return 12
    }
  useEffect(() => {
    getData()
  }, [])
  const initData = () => {
    return {
      ...rowData
    }
  }
  const formFields: FormBuilderProps[] = [
    {
      label: t('Deadweight Winter'),
      name: 'dwWinter',
      id: 'dwWinter',
      type: 'text',
      spacing: generateCommonSpacing(),
      adorement: 'MT'
    },
    {
      label: t('Deadweight Summer'),
      name: 'dwSummer',
      id: 'dwSummer',
      type: 'text',
      spacing: generateCommonSpacing(),
      adorement: 'MT'
    },
    {
      label: t('Deadweight Tropical'),
      name: 'dwTropical',
      id: 'dwTropical',
      type: 'text',
      spacing: generateCommonSpacing(),
      adorement: 'MT'
    },
    {
      label: t('Deadweight Lightship'),
      name: 'dwLightship',
      id: 'dwLightship',
      type: 'text',
      spacing: generateCommonSpacing(),
      adorement: 'MT'
    },
    {
      label: t('Deadweight NBC'),
      name: 'dwnbc',
      id: 'dwnbc',
      type: 'text',
      spacing: generateCommonSpacing(),
      adorement: 'MT'
    },
    {
      label: t('Deadweight SBC'),
      name: 'dwsbc',
      id: 'dwsbc',
      type: 'text',
      spacing: generateCommonSpacing(),
      adorement: 'MT'
    },
    {
      label: t('Draft Winter'),
      name: 'drafWinter',
      id: 'drafWinter',
      type: 'text',
      spacing: generateCommonSpacing(),
      adorement: 'm'
    },
    {
      label: t('Draft Summer'),
      name: 'drafSummer',
      id: 'drafSummer',
      type: 'text',
      spacing: generateCommonSpacing(),
      adorement: 'm'
    },
    {
      label: t('Draft Tropical'),
      name: 'drafTropical',
      id: 'drafTropical',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Draft Lightship'),
      name: 'drafLightship',
      id: 'drafLightship',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Draft NBC'),
      name: 'draftNBC',
      id: 'draftNBC',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Draft SBC'),
      name: 'draftSBC',
      id: 'draftSBC',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Freeboard Winter'),
      name: 'fbWinter',
      spacing: generateCommonSpacing(),
      id: 'fbWinter',
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Freeboard Summer'),
      name: 'fbSummer',
      id: 'fbSummer',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Freeboard Tropical'),
      name: 'fbTropical',
      id: 'fbTropical',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Freeboard Lightship'),
      name: 'fbLightship',
      id: 'fbLightship',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Freeboard NBC'),
      name: 'fbnbc',
      id: 'fbnbc',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Freeboard SBC'),
      name: 'fbsbc',
      id: 'fbsbc',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Displacement Winter'),
      name: 'dpWinter',
      id: 'dpWinter',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Displacement Summer'),
      name: 'dpSummer',
      id: 'dpSummer',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Displacement Tropical'),
      name: 'dpTropical',
      id: 'dpTropical',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Displacement Lightship'),
      name: 'dpLightship',
      id: 'dpLightship',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Displacement NBC'),
      name: 'dpnbc',
      id: 'dpnbc',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Displacement SBC'),
      name: 'dpsbc',
      id: 'dpsbc',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    }
  ]

  const onSuccessAction = () => {
    setEdit(false)
    setWarningOpen(false)
  }

  const onSubmit = (res: loadLineInfoFormSchemaType) => {
    setActionLoader(true)
    const api = updateLoadLineInfoData
    const payload = {
      ...res,
      vesselId: vesselID
    }
    api(payload)
      .then((res) => {
        showSnackBar(res)
        setRowData(payload)
        onSuccessAction()
      })
      .catch((err) => {
        showSnackBar(err, 'error')
      })
      .finally(() => {
        setActionLoader(false)
      })
  }
  const methods = useForm<loadLineInfoFormSchemaType>({
    resolver: zodResolver(loadLineInfoFormSchema),
    mode: 'onChange',
    defaultValues: initData()
  })
  useEffect(() => {
    methods.reset({ ...rowData })
  }, [rowData])

  const resetEdit = () => {
    setEdit(false)
        methods.reset({ ...rowData })
  }
  return (
    <div className="h-full relative">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={formContainerClass}>
            <FormBuilder data={formFields} edit={edit}  isView={!edit} />
            <div className="py-7">
              <div className="absolute bottom-0 right-0 p-2 py-1">
                {!edit ? (
                  <Fab
                    icontType="large"
                    onClick={() => {
                      handleEdit()
                    }}
                    className="fabShadow"
                  />
                ) : (
                  <div className="flex">
                    <Button
                      label={t('update')}
                      className="me-2"
                      isLoading={actionLoader}
                      onClick={methods.handleSubmit(onSubmit)}
                    />
                    <Button
                      label={t('cancel')}
                      outlined
                      onClick={() => {
                        resetEdit()
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default LoadLineInfoForm

import { useQuery } from '@tanstack/react-query'
import {
  deleteSystemMipsData,
  getSystemData,
  getSystemMipsData,
  postSystemMipsData,
  updateSystemData,
  updateSystemMipsData
} from '../../../../apis/api.helpers'
import apiSystemUrl from '../../../../apis/system.api.urls'

export const fetchUsersData = async (filters: any) => {
  return await postSystemMipsData(`${apiSystemUrl.USERS}/SearchUsers`, {
    ...filters
  })
}
export const useSystemUserList = (filters: any) => {
  return useQuery({
    queryKey: ['system-roles-user-list'],
    queryFn: () => fetchUsersData(filters),
    initialData: false,
    staleTime: 500
  })
}

export const deleteSystemUser = (id: string) => {
  return deleteSystemMipsData(`${apiSystemUrl.USERS}/${id}`)
}
export const createSystemUser = (data: any) => {
  return postSystemMipsData(`${apiSystemUrl.USERS}/CreateUser`, data)
}
export const updateSystemUser = (data: any) => {
  return updateSystemMipsData(`${apiSystemUrl.USERS}/UpdateUser`, data)
}
export const getEntitiesForUser = (id: string) => {
  return getSystemData(`${apiSystemUrl.USERS}/GetEntityAccess?Id=${id}`)
}
export const updateSystemUserEntityAccess = (id: string, data: any) => {
  return updateSystemData(`${apiSystemUrl.USERS}/UpdateEntityAccess/${id}`, data)
}
export const getUserDynamicMenu = (id: string, token: string) => {
  return getSystemMipsData(`${apiSystemUrl.USERS}/GetMenu?id=${id}&src=${token}`)
}
export const getUserDetailsById = (id: string) => {
  return getSystemMipsData(`${apiSystemUrl.USERS}/${id}`)
}
export const adminResetUserPassword = (id: string, data: any) => {
  return updateSystemMipsData(`${apiSystemUrl.USERS}/ResetPassword`, data)
}

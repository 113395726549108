import ManageUser from '@/components/app/system/users/ManageUser'
import { ManageUserEntity } from '@/components/app/system/users/ManageUserEntity'
import { CustomKendoTable, DeletePopUp } from '@/components/common'
import { TableHeight, guid, isAllow } from '@/constants'
import { useAppContext } from '@/store/context/appContext'
import useHasPermission from '@/utilities/checkActionPermission'
// import DialogModal from '@/components/common/modal/DialogModal'
// import { useQueryClient } from '@tanstack/react-query'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'

import { deleteSystemUser, getUserDynamicMenu, useSystemUserList } from './api'
import { useRolesUserFilterStore } from './store'

type PageParams = {
  pageNo: number
  pageSize: number
  sortBy: string
  NameOrDesc?: string
  departmentId?: any
  DeptName?: string
  DesignationId?: any
  DesigName?: string
  roleId?: any
  userROle?: string
}
const sortFields = ['LoginId']

export const SystemRolesUserList: React.FC<any> = () => {
  const urlParams = useParams()
  const { t } = useTranslation()
  const { showSnackBar } = useCustomSnackBar()
  const { setIsActionLoader, isActionLoader } = useAppContext()
  const { roleName, departmentId, departmentName } = useOutletContext<any>()

  const [sysUserID, setUserId] = useState<string>('')
  const [manageOpen, setManageOpen] = useState<boolean>(false)
  const [selectedRow, setSelectedRow] = useState<any>({})
  // const [sysUserName, setUserName] = useState<string>('')
  const [entityAccessOpen, setEntityAccessOpen] = useState(false)
  const [isView, setIsView] = useState(false)

  const [deleteOpen, setDeleteOpen] = useState(false)

  const hasPermission = useHasPermission('User', 'system_v2')

  const {
    search,
    department,
    role,
    designation,
    sortBy,
    pageNo,
    pageSize,
    setSortBy,
    setPageNo,
    setPageSize
    // resetFilters
  } = useRolesUserFilterStore()

  const [params, setPrams] = useState<PageParams>({
    pageNo: pageNo,
    pageSize: pageSize,
    sortBy: sortBy,
    NameOrDesc: search,
    departmentId: null,
    DeptName: '',
    DesignationId: null,
    DesigName: '',
    roleId: null,
    userROle: ''
  })
  const [formSearch] = useState<any>({
    search: search,
    department: department?.id ? department : { id: null, name: '' },
    role: role?.id ? role : { id: urlParams?.id, name: '' },
    designation: designation?.id ? designation : { id: null, name: '' }
  })
  const [searchKey, setSearchKey] = useState(1)
  const [total, setTotal] = useState(0)
  // const queryClient = useQueryClient()
  const { data, refetch, isFetching } = useSystemUserList({
    ...params,
    ...(formSearch?.search ? { NameOrDesc: formSearch.search } : {}),
    ...(formSearch?.department?.id
      ? {
          departmentId: formSearch.department.id,
          DeptName: formSearch.department.name
        }
      : {}),
    ...(formSearch?.designation?.id
      ? {
          DesignationId: formSearch.designation.id,
          DesigName: formSearch.designation.name
        }
      : {}),
    ...(formSearch?.role?.id
      ? {
          sysRoleId: formSearch.role.id,
          userROle: formSearch.role.name
        }
      : {})
  })

  // const onSearch = (data: any) => {

  //   setFormSearch({
  //     search: data.search,
  //     department: data.department,
  //     designation: data?.designation,
  //     role: data?.role
  //   })
  //   setPageNo(1)
  //   setPrams((prev) => ({
  //     ...prev,
  //     pageNo: 1,
  //     DeptName: data?.department?.name ?? undefined,
  //     departmentId: data?.department?.id ?? undefined,
  //     NameOrDesc: data?.user ?? undefined,
  //     DesignationId: data?.designation?.id ?? undefined,
  //     DesigName: data?.designation?.name ?? undefined,
  //     roleId: data?.role?.id ?? undefined,
  //     userROle: data?.role?.name ?? undefined
  //   }))

  //   setSearchKey((k) => k + 1)
  // }
  useEffect(() => {
    refetch()
    //searchinitialized && refetch()
  }, [searchKey])

  useEffect(() => {
    setTotal(data?.totalRecords)
  }, [data])

  const onPagination = (row: number) => {
    setPrams((prev) => ({
      ...prev,
      pageNo: row
    }))
    setPageNo(row)
    setSearchKey((k) => k + 1)
  }

  const onRowsPerPage = (row: number) => {
    setPrams((prev) => ({
      ...prev,
      pageSize: row,
      pageNo: 1
    }))
    setPageNo(1)
    setPageSize(row)
    setSearchKey((k) => k + 1)
  }

  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
    setSortBy(order)
    setSearchKey((k) => k + 1)
  }
  const handleView = (row: any) => {
    setSelectedRow(row)
    setIsView(true)
    setManageOpen(true)
  }
  const columns = [
    {
      title: t('Login ID'),
      field: 'loginId',
      type: 'text',
      sort: true,
      locked: true,
      action: true,
      width: 200,
      render: (row: any) => (
        <div
          onClick={() => handleView(row)}
          className="kendo-column-text-link cursor-pointer truncate"
        >
          {row?.loginId}
        </div>
      )
    },
    { title: 'User Name', field: 'userName', type: 'text', sort: true },
    { title: t('User Role'), field: 'sysRoleName', type: 'text', sort: true },
    { title: t('User Type'), field: 'userTypeName', type: 'text', sort: true },
    { title: t('Designation'), field: 'designationName', type: 'text', sort: true },
    {
      title: t('department'),
      field: 'departmentName',
      type: 'text',
      sort: true
    },
    {
      title: t('Last Login'),
      field: 'lastLoginDate',
      type: 'dateTime',
      width: 150,
      sort: true
    },
    {
      title: t('Password Reset On'),
      field: 'pswdDate',
      type: 'dateTime',
      width: 180,
      sort: true
    }
  ]

  const handleEdit = (permission?: boolean) => {
    if (permission) {
      setIsView(false)
      setManageOpen(true)
    }
  }

  const handleMenuActions = async (slug: string, rowData: any) => {
    setUserId(rowData?.userId)
    setSelectedRow(rowData)
    const permissionParams = {
      userAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE
    }
    const permission = await hasPermission(permissionParams)
    switch (slug) {
      case 'delete-user':
        setDeleteOpen(permission)
        break
      case 'entity-access':
        setEntityAccessOpen(permission)
        break
      case 'edit-user':
        handleEdit(permission)
        break

      default:
        break
    }
  }

  const handleDelete = () => {
    setIsActionLoader(true)
    deleteSystemUser(sysUserID)
      .then((res) => {
        showSnackBar(res)
        setUserId(guid)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err.response.data.error, 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }

  const handleClose = () => {
    setEntityAccessOpen(false)
    setUserId('')
    setSelectedRow({})
  }
  const handleDynamicmenu = async (row: any) => {
    const data = await getUserDynamicMenu(row.userId, 'grid')
    return data
  }
  const handleManageClose = () => {
    setManageOpen(false)
    setIsView(false)
    setSelectedRow({})
  }

  // const onHandleClose2 = () => {
  //   onHandleClose()
  //   data.Results = [{}]
  // }

  const handleCreate = async () => {
    setIsView(false)
    setSelectedRow({})

    const permission = await hasPermission({
      userAction: isAllow.CREATE
    })
    setManageOpen(permission)
  }

  return (
    <>
      <div className="px-kendo-x py-kendo-y bg-background-kendo rounded">
        <CustomKendoTable
          columns={columns}
          data={data?.results}
          isFetching={isFetching}
          pagination={true}
          defaultSort={sortFields[0]}
          title={t('User Details')}
          toolBar={true}
          sortable={true}
          resizable={true}
          isDownloadable={true}
          version={'v2'}
          handleSort={handleSort}
          // closeAction={{
          //   handleCloseAction: onHandleClose2
          // }}
          workflowParams={{
            isDynamicMenu: true,
            handleMenuItems: handleDynamicmenu,
            handleRefresh: refetch
          }}
          staticMenus={[
            {
              title: t('Edit User'),
              iconName: 'edit_user',
              slug: 'edit-user',
              id: '1'
            },
            {
              title: t('User Entity Access'),
              iconName: 'user_check',
              slug: 'entity-access',
              id: '2'
            },

            {
              title: t('Delete User'),
              iconName: 'delete',
              slug: 'delete-user',
              id: '5',
              isWarning: true
            }
          ]}
          handleMenuActions={handleMenuActions}
          primaryAction={{
            title: t('New'),
            handlePrimaryAction: () => handleCreate()
          }}
          tableHeight={`calc(100vh - ${TableHeight.TwoColSearch})`}
          minHeight={'200px'}
          paginationProps={{
            total: total,
            currentPage: params.pageNo,
            rowsPerPage: params.pageSize,
            onPagination,
            onRowsPerPage
          }}
        />
      </div>

      <DeletePopUp
        isOpen={deleteOpen}
        itemLabels={{ title: 'user', subTitle: selectedRow?.userName }}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
        actionLoader={isActionLoader}
      />

      <ManageUserEntity
        open={entityAccessOpen}
        rowData={selectedRow}
        userID={sysUserID}
        handleClose={handleClose}
      />
      <ManageUser
        open={manageOpen}
        view={isView}
        refetch={refetch}
        handleClose={handleManageClose}
        roleDetails={{
          roleId: urlParams?.id as string,
          roleName: roleName,
          departmentId: departmentId,
          departmentName: departmentName
        }}
        userID={selectedRow?.userId}
      />
    </>
  )
}
//export default SystemUserList

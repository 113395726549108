import { Switch } from '@/components/common'

const SwitchComponent = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="py-3">
          <div className="flex p-2">
            <Switch id="switch" name="switch" label="Switch" />
          </div>
        </div>
        <div className="py-3">
          <div className="flex p-2">
            <Switch
              id="switchDisabled"
              disabled
              name="switchDisabled"
              label="Switch Disabled"
            />
          </div>
        </div>
      </div>

      {/* -------------------------------------------------------------------------- */}
    </>
  )
}

export default SwitchComponent

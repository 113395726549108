import { PageProps } from '@/common/customComponenttypes'
import React, { FC, useEffect, useState } from 'react'

import CustomIcons from '../icons/customIcons'

const Pagination: FC<PageProps> = ({ paginationProps }) => {
  const {
    dropOptions,
    currentPage = 1,
    total,
    onRowsPerPage,
    rowsPerPage,
    onPagination
  } = paginationProps
  const [rowsPage, seRowsPage] = useState<number>(rowsPerPage ?? 10)

  const dropData = dropOptions ?? [10, 20, 50, 100, 200]
  const [count, setCount] = useState(1)
  const handleRowsPerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value)
    seRowsPage(value)
    onRowsPerPage && onRowsPerPage(value)
  }
  const handleFirst = (index: number) => {
    onPagination && onPagination(index, currentPage)
  }
  const handlePageIndex = () => {
    if (total) {
      const pageindex = Math.ceil(total / rowsPage)
      setCount(pageindex)
    }
  }
  const handleLast = () => {
    const value = count ?? 0
    onPagination && onPagination(value, currentPage)
  }

  useEffect(() => {
    handlePageIndex()
  }, [total, rowsPerPage])
  const PageIndex = () => {
    const renderPageNumbers = () => {
      const pageNumbers: any = []

      if (currentPage > 3) {
        pageNumbers.push('...')
      }

      for (
        let i = Math.max(1, currentPage - 2);
        i <= Math.min(count, currentPage + 2);
        i++
      ) {
        pageNumbers.push(i)
      }
      if (currentPage < count - 2) {
        pageNumbers.push('...')
      }

      return pageNumbers.map((pageNumber: any, index: number) => (
        <React.Fragment key={index}>
          {pageNumber !== '...' ? (
            <span
              key={index}
              onClick={() => handleFirst(pageNumber)}
              className={`block-item ${pageNumber === currentPage ? 'selected' : ''}`}
            >
              {pageNumber}
            </span>
          ) : (
            <span
              key={index}
              className={`block-item ${pageNumber === currentPage ? 'selected' : ''}`}
            >
              {pageNumber}
            </span>
          )}
        </React.Fragment>
      ))
    }

    return <>{renderPageNumbers()}</>
  }
  const handlePrevious = () => {
    onPagination && onPagination(currentPage - 1, currentPage)
  }
  const handleNext = () => {
    onPagination && onPagination(currentPage + 1, currentPage)
  }

  return (
    <div className={'custom-pagination w-full justify-between min-w-full bg-white'}>
      <div className="right-block flex flex-row-reverse w-full gap-2">
        <div className="flex items-center ">
          <button
            className="icon-container"
            disabled={currentPage === 1}
            onClick={() => handleFirst(1)}
          >
            <CustomIcons name="previous-most" />
          </button>
          <button
            className="icon-container"
            disabled={currentPage < 2}
            onClick={() => handlePrevious()}
          >
            <CustomIcons name="previous-arrow" />
          </button>
          <div className="block-container">
            <PageIndex />
          </div>
          <button
            className="icon-container"
            disabled={currentPage >= count}
            onClick={() => handleNext()}
          >
            <CustomIcons name="next-arrow" />
          </button>
          <button
            className="icon-container"
            disabled={currentPage === count}
            onClick={() => handleLast()}
          >
            <CustomIcons name="next-most" />
          </button>
        </div>
        <div className="flex items-center text-xs">
          <div className="flex items-center pr-2 text-shadow text-xs">
            Records per page
          </div>
          <div>
            <select
              onChange={(e) => handleRowsPerPage(e)}
              className="dropdown border border-table-pagination-rowsPerPage-select-border text-table-pagination-rowsPerPage-select-text  min-w-[75px] pl-3"
              value={rowsPage}
            >
              {dropData?.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Pagination

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const DashExpand: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M9.1 0H13V4.33333H11.7V1.44444H9.1V0ZM0 0H3.9V1.44444H1.3V4.33333H0V0ZM11.7 11.5556V8.66667H13V13H9.1V11.5556H11.7ZM1.3 11.5556H3.9V13H0V8.66667H1.3V11.5556Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DashExpand

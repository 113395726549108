import MentionInput from '@/components/common/inputs/MentionInput'
import React, { useState } from 'react'

const Mentions: React.FC<any> = () => {
  const [errors, setErrors] = useState<any>(undefined)
  const [value, setValue] = useState<any>(undefined)
  const toogleErrors = () => {
    if (errors) {
      setErrors(undefined)
    } else {
      setErrors({ mention: { message: 'error message' } })
    }
  }
  const handleChange = (e: any) => {
    setValue(e.target.value)
  }
  return (
    <div className="">
      <MentionInput
        name="mention"
        id="mention"
        errors={errors}
        onChange={handleChange}
        value={value}
        label="label"
        apiParams={{ key: 'VESCLASS' }}
        required
      />
      <button
        className="bg-primary text-white mt-5 p-2 rounded"
        onClick={() => {
          toogleErrors()
        }}
      >
        Test error
      </button>
    </div>
  )
}

export default Mentions

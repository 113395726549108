const DivisionIcon = () => {
  return (
    <path
      d="M6 12L10 8L6 4"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}
export default DivisionIcon

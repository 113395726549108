import {
  deleteCrewData,
  getCrewData,
  postCrewData,
  updateCrewData
} from '@/apis/api.helpers'
import apiCrewingUrl from '@/modules/crewing/apis/api.urls'
import { useQuery } from '@tanstack/react-query'

export const fetchCrewDocumentType = async (filters: any) => {
  const { data } = await postCrewData(
    `${apiCrewingUrl.CREWING_CREW_DOCUMENT_CATEGORY_TYPE}/Search`,
    {
      ...filters
    }
  )
  return data
}

export const fetchCrewDocumentTypeConfig = async (filters: any) => {
  const { data } = await postCrewData(
    `${apiCrewingUrl.CREWING_CREW_DOCUMENT_CATEGORY_TYPE_CONFIG}/Search`,
    {
      ...filters
    }
  )
  return data
}
export const useCrewDocumentType = (filters: any, searchCount: number) => {
  return useQuery({
    queryKey: ['crewing-doc-type-list', filters],
    queryFn: () => fetchCrewDocumentType(filters),
    // initialData: false,
    staleTime: 500,
    enabled: searchCount > 1 ? true : false
  })
}
export const useCrewDocumentTypeConfig = (filters: any, isEnabled: boolean) => {
  return useQuery({
    queryKey: ['crewing-doc-type-config-list', filters],
    queryFn: () => fetchCrewDocumentConfigType(filters),
    // initialData: false,
    staleTime: 500,
    enabled: isEnabled
  })
}

export const addCrewDocumentType = (data: any) => {
  return postCrewData(`${apiCrewingUrl.CREWING_CREW_DOCUMENT_CATEGORY_TYPE}/Create`, data)
}
export const updateCrewDocumentType = (data: any) => {
  return updateCrewData(
    `${apiCrewingUrl.CREWING_CREW_DOCUMENT_CATEGORY_TYPE}/Update`,
    data
  )
}
export const fetchCrewDocumentTypeById = (id: number) => {
  return getCrewData(
    `${apiCrewingUrl.CREWING_CREW_DOCUMENT_CATEGORY_TYPE}/GetDetails/${id}`
  )
}
export const deleteCrewDocumentType = (id: number) => {
  return deleteCrewData(
    `${apiCrewingUrl.CREWING_CREW_DOCUMENT_CATEGORY_TYPE}/Delete/${id}`
  )
}

export const addCrewDocumentTypeConfig = (data: any) => {
  return postCrewData(
    `${apiCrewingUrl.CREWING_CREW_DOCUMENT_CATEGORY_TYPE_CONFIG}/Create`,
    data
  )
}

export const updateCrewDocumentTypeConfig = (data: any) => {
  return updateCrewData(
    `${apiCrewingUrl.CREWING_CREW_DOCUMENT_CATEGORY_TYPE_CONFIG}/Update`,
    data
  )
}

export const fetchCrewDocumentConfigType = async (filters: any) => {
  const { data } = await postCrewData(
    `${apiCrewingUrl.CREWING_CREW_DOCUMENT_CATEGORY_TYPE_CONFIG}/Search`,
    {
      ...filters
    }
  )
  return data
}

export const fetchCrewDocumentConfigTypeById = (id: number) => {
  return getCrewData(
    `${apiCrewingUrl.CREWING_CREW_DOCUMENT_CATEGORY_TYPE_CONFIG}/GetDetails/${id}`
  )
}

export const deleteDocumentConfigType = (id: number) => {
  return deleteCrewData(
    `${apiCrewingUrl.CREWING_CREW_DOCUMENT_CATEGORY_TYPE_CONFIG}/Delete/${id}`
  )
}

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enCommon from '../langstore/en/common.json'
import enTechnical from '../modules/technical/langstore/en/lang.json'

const translationData = {
  ...enCommon.common,
  auth: enCommon.auth,
  commonDashboard: enCommon.commonDashboard,
  messages: enCommon.messages,
  particulars: enTechnical.particulars,
  technical: enTechnical.technical
}

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationData
    }
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
})

export default i18n

import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'

const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/
export const PersonalDetailsSchema = z.object({
  givenName: z
    .string({
      required_error: 'First Name is required',
      invalid_type_error: 'First Name is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  middleName: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional()
    .nullable(),
  surname: z
    .string({
      required_error: 'Surname is required',
      invalid_type_error: 'Surname is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  nationalityName: z.string({
    required_error: 'Nationality is required',
    invalid_type_error: 'Nationality is required'
  }),
  nationalityId: z.number({
    required_error: 'Nationality is required',
    invalid_type_error: 'Nationality is required'
  }),
  dateOfBirth: z
    .date({
      required_error: 'Date of Birth is required',
      invalid_type_error: 'Date of Birth is required'
    })
    .refine((date) => Boolean(date), {
      message: 'Date of Birth is required'
    }),
  placeOfBirth: z
    .string({
      required_error: 'Place of Birth is required',
      invalid_type_error: 'Place of Birth is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  genderName: z.string({
    required_error: 'Gender is required',
    invalid_type_error: 'Gender is required'
  }),
  genderId: z.number({
    required_error: 'Gender is required',
    invalid_type_error: 'Gender is required'
  }),
  maritalStatusName: z.string({
    required_error: 'Marital Status is required',
    invalid_type_error: 'Marital Status is required'
  }),
  maritalStatusId: z.number({
    required_error: 'Marital Status is required',
    invalid_type_error: 'Marital Status is required'
  }),
  mobile: z
    .string({
      required_error: 'Contact Number is required',
      invalid_type_error: 'Contact Number is required'
    })
    .regex(phoneRegex, 'Invalid phone Number!')
    .min(6, 'Phone number should be atleast 6 characters.')
    .max(15, 'The phone number should be a maximum of 15 characters.')
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  phone: z
    .string()
    .max(15, 'The phone number should be a maximum of 15 characters.')
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .refine((value) => !value || phoneRegex.test(value), {
      message: 'Invalid phone number!'
    })
    .optional()
    .nullable(),
  emailId: z
    .string({
      required_error: 'Email is required',
      invalid_type_error: 'Email is required'
    })
    .email('Invalid email address')
    .max(100, 'Email must be 100 characters or less'),
  nearestAirportName: z.string({
    required_error: 'Nearest Airport is required',
    invalid_type_error: 'Nearest Airport is required'
  }),
  nearestAirportId: z.number({
    required_error: 'Nearest Airport is required',
    invalid_type_error: 'Nearest Airport is required'
  }),
  alternateAirportName: z.string().optional().nullable(),
  alternateAirportId: z.number().optional().nullable(),
  permanentSameAsPresent: z.boolean().optional(),
  permanentAddressLine1: z
    .string({
      required_error: 'Address Line 1 is required',
      invalid_type_error: 'Address Line 1 is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  permanentAddressLine2: z
    .string({
      required_error: 'Address Line 2 is required',
      invalid_type_error: 'Address Line 2 is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  permanentCity: z
    .string({
      required_error: 'City is required',
      invalid_type_error: 'City is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  permanentState: z
    .string({
      required_error: 'State is required',
      invalid_type_error: 'State is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  permanentCountryName: z.string({
    required_error: 'Country is required',
    invalid_type_error: 'Country is required'
  }),
  permanentCountryId: z.number({
    required_error: 'Country is required',
    invalid_type_error: 'Country is required'
  }),
  permanentPostCode: z
    .string({
      required_error: 'Postcode is required',
      invalid_type_error: 'Postcode is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  presentAddressLine1: z.string().optional().nullable(),
  presentAddressLine2: z.string().optional().nullable(),
  presentCity: z.string().optional().nullable(),
  presentState: z.string().optional().nullable(),
  presentCountryName: z.string().optional().nullable(),
  presentCountryId: z.number().optional().nullable(),
  presentPostCode: z.string().optional().nullable(),
  crewId: z.string()
})

export type PersonalDetailsSchemaType = z.infer<typeof PersonalDetailsSchema>

import { CustomKendoTable, DeletePopUp } from '@/components/common'
import {
  deleteDocumentConfigType,
  fetchCrewDocumentConfigTypeById,
  useCrewDocumentTypeConfig
} from '@/modules/crewing/pages/registers/crewDocumentType/api'
import { useAppContext } from '@/store/context/appContext'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { parseSearchParams } from '@/utilities/dataParse'
import React, { useEffect, useState } from 'react'
import { CrewDocumentTypeConfigForm } from './DocumentTypeConfigForm'
import { TableHeight } from '@/constants'
const CrewDocumentExpansionComponent: React.FC<any> = ({ data: selection, open }) => {
  const [filters, setFilters] = useState({
    crewDocTypeId: selection.crewDocTypeId,
    crewDocCategoryId: selection.crewDocCategoryId,
    pageNo: 1,
    pageSize: 10,
    archived: false
  })
  const [enabled, setEnabled] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
  const { setIsActionLoader, isActionLoader } = useAppContext()

  const [crewDocConfigTypeId, setDocumentCategory] = useState<number>(0)
  const [crewDocConfigTypeName, setDocumentCategoryName] = useState<string>()

  const { data, isFetching, refetch } = useCrewDocumentTypeConfig(
    {
      ...parseSearchParams(filters, [])
    },
    enabled
  )

  useEffect(() => {
    if (selection.crewDocTypeId && selection.crewDocCategoryId && open) {
      setEnabled(true)
    } else {
      setEnabled(false)
    }
  }, [selection.crewDocTypeId, selection.crewDocCategoryId, open])
  const onPagination = (row: number) => {
    setFiltersAndUpdate({ ...filters, pageNo: row })
  }

  const onRowsPerPage = (row: number) => {
    setFiltersAndUpdate({ ...filters, pageSize: row, pageNo: 1 })
  }

  const manageViewEditToogle = (id?: number, mode = 'edit') => {
    if (id) {
      setIsCommonLoader(true)
      try {
        fetchCrewDocumentConfigTypeById(id).then((res) => {
          setRowData(res?.data)
          setIsCommonLoader(false)
          setViewable(mode === 'edit' ? false : true)
          setEdit(mode === 'edit' ? true : false)
          setCreateOpen(true)
        })
      } catch {
        setIsCommonLoader(false)
      }
    }
  }
  const handleMenuActions = async (slug: string, row: any) => {
    setDocumentCategory(row?.crewDocConfigId)
    setDocumentCategoryName(row?.countryName)

    // const permissionParams = {
    //   UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
    //   AccessKey: '',
    //   ID1: row?.VesClassId
    // }
    // const permission = await hasPermission(permissionParams)
    const permission = true
    if (slug === 'delete') {
      setDeleteOpen(permission)
    } else if (slug === 'edit') {
      if (permission) {
        manageViewEditToogle(row?.crewDocConfigId)
      }
    }
  }
  useEffect(() => {
    setTotal(data?.totalRecords)
  }, [data])
  const { showSnackBar } = useCustomSnackBar()
  const globalID = 0

  const handleDelete = () => {
    setIsActionLoader(true)
    deleteDocumentConfigType(crewDocConfigTypeId)
      .then((res) => {
        showSnackBar(res?.message)
        setDocumentCategory(globalID)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err, 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }

  const columns = [
    {
      title: 'Nationality',
      field: 'countryName',
      type: 'text',
      sort: true,
      action: true
    },
    {
      title: 'Renewal Required',
      field: 'renewalRequired',
      render: (row: any) => {
        const isRequired = row?.renewalRequired === true
        return <>{isRequired ? 'Yes' : 'No'}</>
      },
      sort: true
    },
    {
      title: 'Default Renewal Days',
      field: 'renewalDays',
      type: 'text',
      sort: true,
      render: (row: { renewalDays?: any }) => {
        const renewalDays = row?.renewalDays
        return <>{renewalDays ? renewalDays : 'NA'}</>
      }
    },
    {
      title: 'Document Number Required',
      field: 'documentNumberRequired',
      render: (row: any) => {
        const isRequired = row?.documentNumberRequired === true
        return <>{isRequired ? 'Yes' : 'No'}</>
      },
      sort: true
    },
    {
      title: 'Archived',
      field: 'archived',
      type: 'boolean',
      sort: true
    }
  ]
  const handleSort = (order: string) => {
    setFiltersAndUpdate({ ...filters, sortBy: order })
  }
  const setFiltersAndUpdate = (newFilters: any) => {
    setFilters(newFilters)
  }

  const initData = {
    crewDocConfigId: 0,
    crewDocTypeId: selection?.crewDocTypeId,
    renewalDays: null,
    renewalRequired: false,
    documentNumberRequired: false,
    archived: false,
    crewDocTypeName: selection?.crewDocTypeName,
    crewDocCategoryName: selection?.crewDocCategoryName
  }

  const { setIsCommonLoader } = useAppContext()
  const [viewable, setViewable] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [edit, setEdit] = useState(true)
  const [rowData, setRowData] = useState<any>(initData)

  const handleCreate = async () => {
    // setIsCommonLoader(true)
    setViewable(false)
    const permission = true
    //    await hasPermission({
    //    UserAction: isAllow.CREATE,
    //    AccessKey: '',
    //    ID1: ''
    //  })
    setEdit(false)
    setCreateOpen(permission)

    // setIsCommonLoader(false)
  }

  const handleCloseForm = () => {
    setCreateOpen(false)
    setRowData(initData)
    setEdit(false)
    setViewable(false)
  }

  const handleToolActions = (slug: string) => {
    if (slug === 'archived')
      setFiltersAndUpdate({ ...filters, archived: !filters?.archived, pageNo: 1 })
  }

  const getArchiveText = () => (filters?.archived ? 'Hide Archived' : 'Show Archived')

  return (
    <div className="w-full overflow-auto">
      <CustomKendoTable
        columns={columns}
        data={data?.results}
        isFetching={isFetching}
        isLoading={isFetching}
        inlineLoader={true}
        pagination={true}
        title="Configurations"
        // adjustWidth={0}
        toolBar={true}
        tableHeight={`calc(100vh - ${TableHeight.ExpandableBoxHeight})`}
        sortable={true}
        resizable={true}
        isDownloadable={true}
        handleMenuActions={handleMenuActions}
        primaryAction={{
          title: 'Configuration',
          handlePrimaryAction: handleCreate
        }}
        handleSort={handleSort}
        actionDropDown={[
          { title: 'Edit', iconName: 'edit', slug: 'edit', id: '1' },
          {
            title: 'Delete',
            iconName: 'delete',
            slug: 'delete',
            id: '2',
            isWarning: true
          }
        ]}
        paginationProps={{
          total: total,
          currentPage: filters.pageNo as number,
          rowsPerPage: filters.pageSize as number,
          onPagination,
          onRowsPerPage
        }}
        handleToolActions={handleToolActions}
        actionToolDropDown={[
          {
            title: getArchiveText(),
            iconName: 'archieve',
            slug: 'archived',
            id: '1'
          }
        ]}
      />
      <CrewDocumentTypeConfigForm
        edit={edit}
        data={rowData}
        onClose={handleCloseForm}
        open={createOpen}
        title={`${edit ? 'Edit ' : viewable ? 'View' : 'Add '} Document Configuration`}
        refetch={refetch}
        view={viewable}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
        actionLoader={isActionLoader}
        itemLabels={{
          title: 'configuration for the Nationality',
          subTitle: crewDocConfigTypeName
        }}
      />
    </div>
  )
}

export default CrewDocumentExpansionComponent

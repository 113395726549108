import { CardListProps } from '@/common/customComponenttypes'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import DynamicActionMenu from '../../dynamicMenu'
// import CustomIcons from '../icons/customIcons'
import { convertHexToRgbA } from '@/utilities/commonParsers'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import dayjs from 'dayjs'
import CustomIcons from '../../icons/customIcons'
import MenuDropDown from '../../menudropdown/MenuDropDown'
import DataColumn from './DataColumn'
import DataColumnFooter from './DataColumnFooter'
import DataColumnHead from './DataColumnHead'

export default function ColoredCard({
  columns,
  columnsHeader,
  onCardClick,
  rowData,
  index,
  actionDropDown,
  actionDropDownParams,
  updatedDate,
  handleMenuActions,
  themeKey = 'theme',
  cardRef,
  staticMenus,
  titleFooter,
  workflowParams
}: CardListProps) {
  const colRef = useRef<HTMLDivElement>(null)
  const { width } = useScreenBreakPoint()
  const getUpdatedDate = () => {
    return (
      <>
        {rowData[updatedDate] ? dayjs(rowData[updatedDate]).format('DD  MMM YYYY') : '-'}
      </>
    )
  }
  const [isClickActive, setIsClickActive] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const handleMenu = (slug: string) => {
    handleMenuActions?.(slug, rowData)
  }
  const handleScroll = useCallback(() => {
    onIconClick(false)
  }, [])
  useEffect(() => {
    const div = cardRef?.current
    div.addEventListener('scroll', handleScroll)
  }, [handleScroll])

  const isLastChild = (index: number, length: number) => {
    if (index === length - 1) {
      return true
    }
    return false
  }
  const onIconClick = (clicked: boolean) => {
    setIsClickActive(clicked)
  }

  const generateValidDatas = (chkdata: any) => {
    return chkdata?.filter((item: any) => rowData[item.field]) || []
  }

  let indexInc: number = 0
  return (
    <div
      style={{ borderColor: rowData?.[themeKey] || '#68d8d6' }}
      className={`flex flex-col justify-between rounded-[12px] bg-white   relative cursor-pointer group/item ${width >= 1700 ? 'col-span-4' : width <= 700 ? 'col-span-12' : 'col-span-6'} card-shadow`}
      onClick={() => {
        onCardClick?.(rowData)
      }}
      ref={colRef}
    >
      <div>
        <div
          className="flex flex-row w-full rounded-[12px] rounded-b-none h-[112px] bg-[#f8fafb]  pt-4 pr-4 pl-4 pb-3"
          key={index}
        >
          {rowData?.avatar && (
            <div
              className="rounded-full h-[86px] w-[86px] bg-opacity-50 flex items-center justify-center"
              style={{
                background: convertHexToRgbA(rowData?.[themeKey] || '#68d8d6', 0.5)
              }}
            >
              <div className="h-[80px] w-[80px] rounded-full bg-white flex items-center justify-center">
                <div className="rounded-full">
                  <img
                    src={rowData.avatar ?? '/assets/avatar_empty.jpeg'}
                    className="h-[76px] w-[76px] rounded-full"
                    alt="avatar"
                  />
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-col flex-grow px-[14px]">
            <div className="flex gap-x-[18px] w-full h-1/2 items-center ">
              {columnsHeader?.map((col, index) => (
                <>
                  {rowData[col.field] && (
                    <DataColumnHead col={col} rowData={rowData} key={index} />
                  )}
                </>
              ))}
            </div>
            {titleFooter && (
              <div className="flex gap-x-[14px] w-full h-1/2 items-center ">
                {generateValidDatas(titleFooter)?.map((col: any, index: number) => (
                  <React.Fragment key={index}>
                    <DataColumnFooter col={col} rowData={rowData} key={index} />
                    {generateValidDatas(titleFooter)?.length !== index + 1 ? (
                      <div className="py-1 h-full ">
                        {' '}
                        <div className="h-full w-[1px] bg-grey-borderLight" />
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>

          {workflowParams?.isDynamicMenu ? (
            <div
              className="w-[20px] bg-white group-hover:bg-table-hover z-9"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex items-center justify-center w-[20px] bg-white group-hover:bg-table-hover">
                <DynamicActionMenu
                  iconName="three_dot"
                  staticMenus={staticMenus}
                  handleMenuActions={handleMenu}
                  rowData={rowData}
                  handleMenuItems={() => workflowParams?.handleMenuItems?.(rowData)}
                  updateMenuRef={(ref) => (menuRef.current = ref)}
                  handleRefresh={(id) => workflowParams?.handleRefresh(id)}
                  fromTable={true}
                />
              </div>
            </div>
          ) : (
            <>
              {actionDropDown && !actionDropDownParams?.hideDropDown?.(rowData) && (
                <div className="w-[24px]" onClick={(e) => e.stopPropagation()}>
                  <div
                    className={`flex items-center justify-center py-1 text-[#667085] hover:bg-[#ffffff50]`}
                  >
                    <MenuDropDown
                      iconName="three_dot"
                      iconClass="text-[#667085]"
                      actionDropDown={actionDropDown}
                      onIconClick={onIconClick}
                      isClickActive={isClickActive}
                      handleMenuActions={handleMenu}
                      updateMenuRef={(ref) => (menuRef.current = ref)}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex  w-full ">
          <div className="grid grid-cols-12 w-full">
            {columns.map((col, index) => {
              indexInc = !col.hidden ? indexInc + 1 : indexInc
              return (
                <>
                  {!col.hidden && (
                    <DataColumn
                      col={col}
                      childrens={columns.filter((column) => !column.hidden)?.length || 0}
                      rowData={rowData}
                      key={index}
                      lastChild={isLastChild(
                        index,
                        columns.filter((column) => !column.hidden)?.length
                      )}
                      index={indexInc === 1 ? 0 : indexInc}
                    />
                  )}
                </>
              )
            })}
          </div>
        </div>
      </div>
      <div className="h-10 bg-[#F8FAFB] rounded-b-[12px]  flex justify-start items-center px-5">
        <div className="flex gap-1 text-grey-secondary text-xxs leading-3">
          <CustomIcons name="history" type="large" />
          <div className="flex items-center justify-start">
            Last status update on {getUpdatedDate()}
          </div>
        </div>
      </div>
    </div>
  )
}

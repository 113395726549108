const dataParse = (data: any) => {
  let result: string = ''
  data &&
    Object.keys(data)?.forEach((item: any) => {
      if (data[item]) result = `${result}${result ? '&' : '?'}${item}=${data[item]}`
    })
  return result
}

export default dataParse

export const parseSearchParams = (data: any, dataName?: any) => {
  let searchParams = data
  Object.keys(data)?.forEach((item: any) => {
    if (typeof data[item] === 'object' && data[item]?.id) {
      if (dataName?.includes(item)) {
        searchParams = { ...searchParams, [item]: data[item]?.name }
      } else {
        searchParams = { ...searchParams, [item]: data[item]?.id }
      }
    }
  })
  return searchParams
}

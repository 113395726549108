import { TabItemProps } from '@/common/customComponenttypes'
import { HeaderWrapper, SectionWraper } from '@/components/app/common'
import DetailHeaderComponent, {
  MainContentProps,
  SubContentProps
} from '@/components/app/common/DetailHeaderComponent'
import { Breadcrumb, Tab, TabContainer } from '@/components/common'
import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { routerStore } from '@/store'
import { useAppStore, useHeaderStore } from '@/store/appStore'
import { detailHeaderBackground } from '@/styles/variables'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import { getUserPrivilege } from '@/apis/common.api'
import { useTechnicalParticularDetails } from './api'
import { tabData } from './tabStore'

const VesselParticularDetails: React.FC = () => {
  const params = useParams()

  const navigate = useNavigate()
  const location = useLocation()
  const baseURL = `${routerStore.PARTICULAR}/${params.vesselID}`
  const items = location?.pathname?.split('/')
  const lastItem = items[items.length - 1]
  const [isOpen, setIsOpen] = useState(false)
  const [section, setSection] = useState<string | number>('')
  const { moduleId } = useHeaderStore()
  const [tabProps, setTabProps] = useState<any>([])
  const { t } = useTranslation()
  const { setIsLoading } = useAppStore()
  const { data, isLoading } = useTechnicalParticularDetails(params.vesselID as string)
  const [activeTab, setActiveTab] = useState<number | string>(lastItem)
  const [edit, setEdit] = useState<boolean>(false)
  const breadcrumbConfig = [
    {
      label: t('technical.technical'),
    },
    {
      label: t('particulars.particulars'),
      link: routerStore.PARTICULAR
    },
    { label: data?.VesselName || '--' }
  ]
  const getTabs = async () => {
    const data = await getUserPrivilege(moduleId)
    const reData = data.map((item: any) => ({
      ...item,
      label: item?.FunctionName,
      id: tabData?.find((tab) => tab.FunctionName === item.FunctionName)?.PathName,
    }))
    setTabProps(reData)
  }
  useEffect(() => {
    getTabs()
  }, [])
  useEffect(() => {
    setActiveTab(lastItem)
  }, [lastItem])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  const onTabClick = (selection: TabItemProps) => {
    if (edit) {
      setIsOpen(true)
      setSection(selection.id as string)
    } else {
      setActiveTab(selection.id as string)
      navigate(`${baseURL}/${selection.id as string}`)
    }
  }
  const handleConfirm = () => {
    setEdit(false)
    setIsOpen(false)
    setActiveTab(section)
    navigate(`${baseURL}/${section as string}`)
  }
  const detailHeaders: MainContentProps[] = [
    { data: data?.VesselName, type: 'title' },
    {
      data: data?.TypeName,
      type: 'desc'
    },
    {
      data: data?.StatusName,
      type: 'status',
      backgroundColor: data?.BackgroundColor,
      textColor: data?.TextColor
    }
  ]
  const detailContents: SubContentProps[] = [
    {
      label: t('owner'),
      value: data?.OwnerName,
      spacing: 6,
      hasDivider: true
    },

    {
      label: t('flag'),
      value: data?.FlagName,
      spacing: 3
    },
    {
      label: t('subType'),
      value: data?.SubTypeName,
      spacing: 3
    },
    {
      label: t('portRegistry'),
      value: data?.PortOfRegistry,
      spacing: 6,
      hasDivider: true
    },
    {
      label: t('fleet'),
      value: data?.FleetName,
      spacing: 3
    },

    {
      label: t('vesselCode'),
      value: data?.VesselCode,
      spacing: 3
    }
  ]

  // test
  const contextProps = { edit: edit, setEdit: setEdit, vesselID: params.vesselID }
  return (
    <>
      <Breadcrumb items={breadcrumbConfig} />
      <div className={detailHeaderBackground}>
        <HeaderWrapper search={false}>
          <DetailHeaderComponent
            isLoading={isLoading}
            detailHeaders={detailHeaders}
            detailContents={detailContents}
            avatar="/assets/ship_avatar.png"
          />
        </HeaderWrapper>
      </div>
      <SectionWraper>
        <TabContainer
          data={tabProps}
          isLoading={isLoading}
          activeTab={activeTab}
          isContainer={
            activeTab !== routerStore.PARTICULAR_DETAILS_ANTI_FOULINGS &&
            activeTab !== routerStore.PARTICULAR_DETAILS_SEA_TRAILS
          }
          onClick={onTabClick}
          tabBGClass="bg-tab-detail  px-[15px] rounded-sm"
        >
          <Tab>
            <Outlet context={contextProps} />
          </Tab>
        </TabContainer>
      </SectionWraper>
      <ConfirmModal
        isOpen={isOpen}
        modalId="errorPopup"
        title={'Confirmation'}
        type="warning"
        secondaryActionLabel="No"
        secondaryAction={() => setIsOpen(false)}
        actionLabel="Yes"
        onSubmit={() => handleConfirm()}
        topIcon="warning"
        scrollDisable={true}
        onClose={() => setIsOpen(false)}
        subTitle={'Do you want to discard all the changes made?'}
      />
    </>
  )
}

export default VesselParticularDetails

import React from 'react'

import { IconsProps } from '@/common/customComponenttypes'
import BellIcon from './Bell'
import CalendarIcon from './Calendar'
import CapacityIcon from './Capacity'
import CheckedMark from './CheckedBoxIcon'
import Close from './Close'
import ClosePopupIcon from './ClosePopup'
import CopyIcon from './Copy'
import DangerIcon from './Danger'
import DashClose from './DashClose'
import DashExpand from './DashExpand'
import DashIconBar from './DashIconBar'
import DashIconPie from './DashIconPie'
import DashIconTable from './DashIconTable'
import DashReload from './DashReload'
import DashToggle from './DashToggle'
import DeleteIcon from './Delete'
import DimenstionIcon from './Dimenstion'
import DownloadIcon from './Download'
import DropArrow from './DropArrow'
import EditIcon from './Edit'
import EditFabIcon from './EditFab'
import EditPrivilegeIcon from './EditPrivilege'
import ErrorIcon from './ErrorIcon'
import Explorer from './Explorer'
import ExplorerCloseIcon from './ExplorerClose'
import ExternalLink from './ExternalLink'
import EyeIcon from './Eye'
import EyeCloseIcon from './EyeClose'
import Favourite from './Favourite'
import Favourited from './Favourited'
import ForwardArrow from './ForwardArrow'
import HeaderDropArrow from './HeaderDropDown'
import HelpIcon from './Help'
import Home from './Home'
import LiveFleet from './LiveFleet'
import LoadlineIcon from './Loadline'
import LoginUserIcon from './LoginUser'
import LogOut from './Logout'
import MenuDownArrow from './MenuDown'
import MenuFavourite from './MenuFavourite'
import MenuListIcon from './MenuListIcon'
import MenuSectionIcon from './MenuSection'
import MenuUpArrow from './MenuUpArrow'
import MenuUpArrowIcon from './MenuUpArrowIcon'
import MinusCircleIcon from './MinusCircle'
import ModuleIcon from './Module'
import NextArrow from './NextArrow'
import NextMost from './NextMost'
import PlusIcon from './Plus'
import PlusLargeIcon from './PlusLarge'
import PlusMenuIcon from './PlusMenu'
import PlusTabIcon from './PlusTab'
import PreviousArrow from './PreviousArrow'
import PreviousMost from './PreviousMost'
import ProfileIcon from './ProfileIcon'
import RatedPowerIcon from './RatedPower'
import ResetSearchIcon from './ResetSearch'
import ReviewPrivilegeIcon from './ReviewPrivilege'
import Search from './Search'
import SettingsIcon from './Settings'
import ShipIcon from './Ship'
import ShipSmallIcon from './ShipSmall'
import ShipSmallSelected from './ShipSmallSelected'
import SideMenuLogout from './SideMenuLogout'
import SortAscIcon from './SortAsc'
import SortDescIcon from './SortDesc'
import SubMenuListIcon from './SubMenuListIcon'
import SuccessIcon from './Success'
import TableDelete from './TableDelete'
import TableEdit from './TableEdit'
import TechOthersIcon from './TechOthers'
import TextFieldCancel from './TextFieldCancel'
import TextFieldDone from './TextFieldDone'
import ThreeDotIcon from './ThreeDot'
import TonnageIcon from './Tonnage'
import TopbarMenu from './TopbarMenu'
import UserIcon from './User'
import ViewIcon from './ViewIcon'
import ViewLargeIcon from './ViewLarge'
import CheckMark from './checkMark'
import SubModuleIcon from './subModule'
import UnCheckedMark from './unCheckedBoxIcon'

// const Icons: React.FC<IconsProps> = ({ name, className = '', onClick = undefined }) => {
//   const getIcons = (): JSX.Element => {
//     switch (name) {
//       case 'plus':
//         return <PlusIcon className={className} />
//       case 'edit':
//         return <EditIcon className={className} />
//       case 'textfield-done':
//         return <TextFieldDone className={className} />
//       case 'textfield-cancel':
//         return <TextFieldCancel className={className} />
//       case 'three-dot':
//         return <ThreeDotIcon className={className} />
//       case 'eye':
//         return <EyeIcon className={className} />
//       case 'eye-close':
//         return <EyeCloseIcon className={className} />
//       case 'home':
//         return <Home className={className} />
//       case 'favourite':
//         return <Favourite className={className} />
//       case 'livefleet':
//         return <LiveFleet className={className} />
//       case 'explorer':
//         return <Explorer className={className} />
//       case 'forward-arrow':
//         return <ForwardArrow className={className} />
//       case 'menu-list':
//         return <MenuListIcon className={className} />
//       case 'side-menu-list':
//         return <SubMenuListIcon className={className} />
//       case 'menu-section':
//         return <MenuSectionIcon className={className} />
//       case 'menu-up-arrow':
//         return <MenuUpArrow className={className} />
//       case 'danger':
//         return <DangerIcon className={className} />
//       case 'success':
//         return <SuccessIcon className={className} />
//       case 'close-popup':
//         return <ClosePopupIcon className={className} />
//       case 'favourited':
//         return <Favourited className={className} />
//       case 'menu-favourite':
//         return <MenuFavourite className={className} />
//       case 'previous-most':
//         return <PreviousMost className={className} />
//       case 'previous-arrow':
//         return <PreviousArrow className={className} />
//       case 'next-most':
//         return <NextMost className={className} />
//       case 'next-arrow':
//         return <NextArrow className={className} />
//       case 'drop-arrow':
//         return <DropArrow className={className} />
//       case 'close':
//         return <Close className={className} />
//       case 'close-explorer':
//         return <ExplorerCloseIcon className={className} />
//       case 'sidemenu-logout':
//         return <SideMenuLogout className={className} />
//       case 'topbar-menu':
//         return <TopbarMenu className={className} />
//       case 'header-drop-arrow':
//         return <HeaderDropArrow className={className} />
//       case 'fab-edit':
//         return <EditFabIcon className={className} />
//       case 'plus-tab':
//         return <PlusTabIcon className={className} />
//       case 'sort-desc':
//         return <SortDescIcon className={className} />
//       case 'sort-asc':
//         return <SortAscIcon className={className} />
//       case 'search':
//         return <Search className={className} />
//       case 'external-link':
//         return <ExternalLink className={className} />
//       case 'table-edit':
//         return <TableEdit className={className} />
//       case 'table-delete':
//         return <TableDelete className={className} />
//       case 'settings-icon':
//         return <SettingsIcon className={className} />
//       case 'bell-icon':
//         return <BellIcon className={className} />
//       case 'help-icon':
//         return <HelpIcon className={className} />
//       case 'ship-icon':
//         return <ShipIcon className={className} />
//       case 'dimenstion-icon':
//         return <DimenstionIcon className={className} />
//       case 'capacity-icon':
//         return <CapacityIcon className={className} />
//       case 'loadline-icon':
//         return <LoadlineIcon className={className} />
//       case 'tonnage-icon':
//         return <TonnageIcon className={className} />
//       case 'ratedPower-icon':
//         return <RatedPowerIcon className={className} />
//       case 'techOthers-icon':
//         return <TechOthersIcon className={className} />
//       case 'dowload-icon':
//         return <DownloadIcon className={className} />
//       case 'checkmark':
//         return <CheckMark className={className} />
//       case 'resetSearch':
//         return <ResetSearchIcon className={className} />
//       case 'delete':
//         return <DeleteIcon className={className} />
//       case 'view':
//         return <ViewIcon className={className} />
//       case 'error':
//         return <ErrorIcon className={className} />
//       case 'checked-icon':
//         return <CheckedMark className={className} />
//       case 'un-checked-icon':
//         return <UnCheckedMark className={className} />
//       case 'ship-small-icon':
//         return <ShipSmallIcon className={className} />
//       case 'copy':
//         return <CopyIcon className={className} />
//       case 'minus-circle':
//         return <MinusCircleIcon className={className} />
//       case 'calendar':
//         return <CalendarIcon className={className} />
//       case 'dash-pie':
//         return <DashIconPie className={className} />
//       case 'dash-bar':
//         return <DashIconBar className={className} />
//       case 'dash-table':
//         return <DashIconTable className={className} />
//       case 'dash-close':
//         return <DashClose className={className} />
//       case 'dash-toggle':
//         return <DashToggle className={className} />
//       case 'dash-expand':
//         return <DashExpand className={className} />
//       case 'dash-reload':
//         return <DashReload className={className} />
//       case 'logout':
//         return <LogOut className={className} />
//       case 'view-large':
//         return <ViewLargeIcon className={className} />
//       case 'profile-icon':
//         return <ProfileIcon className={className} />
//       case 'ship-small-selected':
//         return <ShipSmallSelected className={className} />
//       case 'user-icon':
//         return <UserIcon className={className} />
//       case 'review-privilege':
//         return <ReviewPrivilegeIcon className={className} />
//       case 'edit-privilege':
//         return <EditPrivilegeIcon className={className} />
//       case 'module-icon':
//         return <ModuleIcon className={className} />
//       case 'sub-module-icon':
//         return <SubModuleIcon className={className} />
//       case 'menu-down-arrow':
//         return <MenuDownArrow className={className} />
//       default:
//         return <></>
//     }
//   }
//   return (
//     <span
//       data-testid={name}
//       onClick={onClick}
//       className={`${className ?? ' text-center text-white'}`}
//     >
//       {getIcons()}
//     </span>
//   )
// }

// export default Icons
const iconMapping: any = {
  plus: PlusIcon,
  edit: EditIcon,
  'textfield-done': TextFieldDone,
  'textfield-cancel': TextFieldCancel,
  three_dot: ThreeDotIcon,
  eye: EyeIcon,
  'eye-close': EyeCloseIcon,
  home: Home,
  favourite: Favourite,
  livefleet: LiveFleet,
  explorer: Explorer,
  'forward-arrow': ForwardArrow,
  'menu-list': MenuListIcon,
  'side-menu-list': SubMenuListIcon,
  'menu-section': MenuSectionIcon,
  'menu-up-arrow': MenuUpArrow,
  danger: DangerIcon,
  success: SuccessIcon,
  'close-popup': ClosePopupIcon,
  favourited: Favourited,
  'menu-favourite': MenuFavourite,
  'previous-most': PreviousMost,
  'previous-arrow': PreviousArrow,
  'next-most': NextMost,
  'next-arrow': NextArrow,
  'drop-arrow': DropArrow,
  close: Close,
  'close-explorer': ExplorerCloseIcon,
  'sidemenu-logout': SideMenuLogout,
  'topbar-menu': TopbarMenu,
  'header-drop-arrow': HeaderDropArrow,
  'fab-edit': EditFabIcon,
  'plus-tab': PlusTabIcon,
  'sort-desc': SortDescIcon,
  'sort-asc': SortAscIcon,
  search: Search,
  'external-link': ExternalLink,
  'table-edit': TableEdit,
  'table-delete': TableDelete,
  'settings-icon': SettingsIcon,
  'bell-icon': BellIcon,
  'help-icon': HelpIcon,
  'ship-icon': ShipIcon,
  'dimenstion-icon': DimenstionIcon,
  'capacity-icon': CapacityIcon,
  'loadline-icon': LoadlineIcon,
  'tonnage-icon': TonnageIcon,
  'ratedPower-icon': RatedPowerIcon,
  'techOthers-icon': TechOthersIcon,
  'dowload-icon': DownloadIcon,
  checkmark: CheckMark,
  resetSearch: ResetSearchIcon,
  delete: DeleteIcon,
  view: ViewIcon,
  error: ErrorIcon,
  'checked-icon': CheckedMark,
  'un-checked-icon': UnCheckedMark,
  'ship-small-icon': ShipSmallIcon,
  copy: CopyIcon,
  'minus-circle': MinusCircleIcon,
  calendar: CalendarIcon,
  'dash-pie': DashIconPie,
  'dash-bar': DashIconBar,
  'dash-table': DashIconTable,
  'dash-close': DashClose,
  'dash-toggle': DashToggle,
  'dash-expand': DashExpand,
  'dash-reload': DashReload,
  logout: LogOut,
  'view-large': ViewLargeIcon,
  'profile-icon': ProfileIcon,
  'ship-small-selected': ShipSmallSelected,
  'user-icon': UserIcon,
  'review-privilege': ReviewPrivilegeIcon,
  'edit-privilege': EditPrivilegeIcon,
  'module-icon': ModuleIcon,
  'sub-module-icon': SubModuleIcon,
  'menu-down-arrow': MenuDownArrow,
  'menu-plus-icon': PlusMenuIcon,
  'menu-up-arrow-icon': MenuUpArrowIcon,
  'login-user': LoginUserIcon,
  'plus-large': PlusLargeIcon
}

const Icons: React.FC<IconsProps> = ({
  name,
  className = '',
  onClick = undefined,
  color = ''
}) => {
  const IconComponent = iconMapping[name]

  if (!IconComponent) {
    return null
  }
  return (
    <span data-testid={name} onClick={onClick} className={`text-center ${className}`}>
      <IconComponent className={className} C />
    </span>
  )
}

export default Icons

import { FormBuilderProps } from '@/common/customComponenttypes'
import { Button, FormBuilder } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'
import { fetchKinById, updateKinDetails } from '@/modules/crewing/pages/crewSearch/api'
import { handleSchema } from '@/modules/crewing/pages/crewSearch/details/crewDetails/familyDetails/schema'
import { useCrewProfileStore } from '@/modules/crewing/pages/crewSearch/details/crewDetails/store'
import useGenerateCommonSpacing from '@/modules/crewing/pages/crewSearch/utils'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { getErrorMessage } from '@/utilities/dataGenerators'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type Props = {
  data?: any
  dataStore?: any
  pageRouting: boolean
  onResetData?: (data: any) => void
}

const KinDetails: React.FC<Props> = ({ data, dataStore, onResetData, pageRouting }) => {
  const { familyEdit, setFamilyEdit } = useCrewProfileStore()
  const generateCommonSpacing = useGenerateCommonSpacing()
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [maritualStatus, setMaritualStatus] = useState(0)
  const [relativeCrewId, setRelativeCrewId] = useState<string | null>(null)
  const [kinData, setKinData] = useState<any>()
  const { t } = useTranslation()
  const { showSnackBar } = useCustomSnackBar()
  const KinDetailsSchema = handleSchema(relativeCrewId, maritualStatus)

  type KinDetailsSchemaType = z.infer<typeof KinDetailsSchema>

  const initData = () => {
    return {
      ...kinData,
      //surName: kinData?.surname ?? null,
      dateOfBirth: kinData?.dateOfBirth ? new Date(kinData?.dateOfBirth) : null,
      weddingDate: kinData?.weddingDate ? new Date(kinData?.weddingDate) : null
    }
  }

  const getDetails = (callback: boolean = false) => {
    setLoading(true)
    fetchKinById(data?.crewNokId)
      .then((res) => {
        setKinData(res?.data || {})
        setRelativeCrewId(res?.data?.relativeCrewId)
        if (callback) onResetData?.(res?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    methods.reset({ ...initData() })
  }, [kinData])

  useEffect(() => {
    getDetails()
  }, [data.crewNokId])

  const methods = useForm<KinDetailsSchemaType>({
    resolver: zodResolver(KinDetailsSchema),
    mode: 'onChange',
    defaultValues: initData()
  })

  const { watch, clearErrors, setError, formState } = methods
  const maritalStatusId = watch('maritalStatusId')
  const weddingDate = watch('weddingDate')
  const { isSubmitted } = formState

  const checkValidations = () => {
    if (maritalStatusId === 1 && weddingDate === null) {
      setError('weddingDate', { message: 'Wedding Date is required' })
      return false
    } else {
      clearErrors('weddingDate')
    }
  }

  useEffect(() => {
    if (isSubmitted && (relativeCrewId === null || relativeCrewId === undefined)) {
      checkValidations()
    }
    setMaritualStatus(maritalStatusId)
  }, [maritalStatusId, weddingDate, isSubmitted])

  const formFields: FormBuilderProps[] = [
    {
      label: t('First Name'),
      placeholder: t('First Name'),
      name: 'givenName',
      id: 'givenName',
      required: true,
      display_name: kinData?.givenName,
      type: 'text',
      maxLength: 100,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Middle Name'),
      placeholder: t('Middle Name'),
      name: 'middleName',
      display_name: kinData?.middleName ?? '',
      id: 'middleName',
      type: 'text',
      maxLength: 100,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Surname'),
      placeholder: t('Surname'),
      name: 'surname',
      id: 'surname',
      display_name: kinData?.surname,
      required: true,
      type: 'text',
      maxLength: 100,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Relationship'),
      required: true,
      placeholder: t('Relationship'),
      name: 'relationName',
      id: 'relationId',
      display_name: kinData?.relationName,
      type: 'custom_select',
      data: dataStore?.relations,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Nationality'),
      required: true,
      placeholder: t('typeToSearch'),
      name: 'nationalityName',
      display_name: kinData?.nationalityName,
      noLocalFilter: true,
      id: 'nationalityId',
      type: 'autocomplete',
      apiParams: { key: 'COUNTRY' },
      isStaticList: true,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Date of Birth'),
      placeholder: t('DD-MM-YYYY'),
      name: 'dateOfBirth',
      id: 'dateOfBirth',
      type: 'date',
      display_name: kinData?.dateOfBirth
        ? dayjs(kinData?.dateOfBirth).format('DD MMM YYYY')
        : undefined,
      required: true,
      spacing: generateCommonSpacing,
      minYearRange: 100,
      maxDate: new Date(),
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Place of Birth'),
      placeholder: t('Place of Birth'),
      name: 'placeOfBirth',
      id: 'placeOfBirth',
      required: true,
      type: 'text',
      maxLength: 100,
      display_name: kinData?.placeOfBirth,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Gender'),
      required: true,
      placeholder: t('Gender'),
      name: 'genderName',
      id: 'genderId',
      display_name: kinData?.genderName,
      type: 'custom_select',
      data: dataStore?.gender,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Marital Status'),
      required: true,
      placeholder: t('Marital Status'),
      name: 'maritalStatusName',
      id: 'maritalStatusId',
      display_name: kinData?.maritalStatusName,
      type: 'custom_select',
      data: dataStore?.maritals,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Wedding Date'),
      placeholder: t('DD-MM-YYYY'),
      name: 'weddingDate',
      id: 'weddingDate',
      type: 'date',
      display_name: kinData?.weddingDate
        ? dayjs(kinData?.weddingDate).format('DD MMM YYYY')
        : undefined,
      required: maritalStatusId === 1 ? true : false,
      spacing: generateCommonSpacing,
      minYearRange: 100,
      maxDate: new Date(),
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Contact Number'),
      placeholder: t('Contact Number'),
      name: 'mobile',
      id: 'mobile',
      required: true,
      type: 'text',
      maxLength: 20,
      spacing: generateCommonSpacing,
      display_name: kinData?.mobile,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Alternate Contact Number'),
      placeholder: t('Alternate Contact Number'),
      name: 'phone',
      id: 'phone',
      type: 'text',
      maxLength: 20,
      display_name: kinData?.phone,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Email'),
      placeholder: t('Email'),
      name: 'emailId',
      id: 'emailId',
      required: true,
      display_name: kinData?.emailId,
      type: 'email',
      maxLength: 100,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Nearest Airport'),
      required: true,
      placeholder: t('typeToSearch'),
      name: 'nearestAirportName',
      display_name: kinData?.nearestAirportName,
      noLocalFilter: true,
      id: 'nearestAirportId',
      type: 'autocomplete',
      apiParams: { key: 'AIRPORT' },
      isStaticList: true,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('Alternate Airport'),
      placeholder: t('typeToSearch'),
      name: 'alternateAirportName',
      noLocalFilter: true,
      id: 'alternateAirportId',
      display_name: kinData?.alternateAirportName,
      type: 'autocomplete',
      apiParams: { key: 'AIRPORT' },
      isStaticList: true,
      spacing: generateCommonSpacing,
      disabled: kinData?.relativeCrewId ? true : false
    },
    {
      label: t('NOK'),
      name: 'nok',
      id: 'nok',
      display_name: kinData?.nok ? 'Yes' : 'No',
      type: 'switch',
      spacing: generateCommonSpacing
    },
    {
      label: t('ICE'),
      name: 'ice',
      id: 'ice',
      display_name: kinData?.ice ? 'Yes' : 'No',
      type: 'switch',
      spacing: generateCommonSpacing
    }
  ]

  const onSubmit = (res: KinDetailsSchemaType) => {
    setIsSubmitting(true)
    const payload = {
      ...res
    }

    updateKinDetails(payload)
      .then((res) => {
        showSnackBar(res?.message)
        getDetails(true)
        setFamilyEdit(false)
      })
      .catch((err) => {
        showSnackBar(getErrorMessage(err), 'error')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="h-full relative w-full pt-4">
          <div className="grid grid-cols-12 gap-5 w-full">
            <FormBuilder
              data={formFields}
              edit={true}
              isLoading={loading}
              isView={!familyEdit}
              hasCustombreakpoint
            />
          </div>
        </div>
        {pageRouting && (
          <div className="w-full">
            <div className="float-right pb-6">
              {!familyEdit ? (
                <div
                  className="flex items-start text-common leading-4 cursor-pointer mr-4"
                  onClick={() => {
                    setFamilyEdit(true)
                  }}
                >
                  <CustomIcons name="edit" type="large" className="mr-1" />
                  <span>Edit</span>
                </div>
              ) : (
                <div className="flex">
                  <Button
                    label={'Save'}
                    className="me-2"
                    primary={false}
                    isLoading={isSubmitting}
                    type="submit"
                  />
                  <Button
                    label={'Cancel'}
                    outlined
                    onClick={() => {
                      setFamilyEdit(false)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </form>
    </FormProvider>
  )
}

export default KinDetails

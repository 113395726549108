// import { useSnackbar } from '../../../utilities/commonUtilities'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useCallback, useState } from 'react'

import { FileUploadProps } from '../../../common/customComponenttypes'
// import InfoBox from '../../app/alertBox/infoBox'
import CustomIcons from '../icons/customIcons'
export const isValidFile = (file?: string, support?: string[]) => {
  if (file && support) {
    return support.some((format: string) => format.toLowerCase() === file.toLowerCase())
  }
  return false
}
const validateSize = (fileSIze: number, sizelimit: number) => {
  const size = sizelimit * 1024 * 1024

  if (size >= fileSIze) {
    return true
  } else {
    return false
  }
}
const FileUpload: React.FC<FileUploadProps> = ({
  name,
  id,
  label,
  fullwidth = true,
  type = 'file',
  disabled = false,
  required = false,
  isMultiple = false,
  errors,
  value,
  onChange,
  supportedFiles,
  sizeLimit,
  buttonLabel,
  iconName,
  accept = '*',
  supportedExtensions
}) => {
  const getErrors = (err: any) => {
    let errMsg = ''
    if (err.message) {
      errMsg = err?.message
    }
    return errMsg
  }
  const [file, setFile] = useState<any>(value)
  const { showSnackBar } = useCustomSnackBar()
  const handleClearFile = (indexToRemove?: number, item?: any) => {
    if (isMultiple) {
      const newFiles = file.filter((_: any, ind: number) => indexToRemove !== ind)
      setFile(newFiles)
      onChange?.(newFiles)
    } else if (item?.link) {
      setFile('')
    }
  }
  const handleAddFile = (fileArray: FileList) => {
    let isValid = { fileType: true, fileSize: true }
    if (supportedFiles?.length) {
      isValid = {
        fileType: isValidFile(fileArray[0].type, supportedExtensions),
        fileSize: validateSize(fileArray[0].size, sizeLimit as number)
      }
    }
    if (isValid.fileSize && isValid.fileType) {
      if (isMultiple) {
        const files = fileArray
        const existingFiles = file ?? []
        const filesArray = Array.from(files)
        setFile([...existingFiles, ...filesArray])
        // setTimeout(() => {
        onChange?.([...existingFiles, ...filesArray])
        // }, 200)
      } else {
        onChange?.(fileArray[0])
        setFile(fileArray[0])
      }
    } else {
      showSnackBar(
        !isValid?.fileType
          ? 'Invalid file type'
          : !isValid.fileSize
            ? `File size is greater than ${sizeLimit}MB `
            : '',
        'error'
      )
    }
  }
  const handleFileChange = (e: any) => {
    if (e.target.files.length) {
      handleAddFile(e.target.files)
    }
  }

  const onDragOver = useCallback((event: any) => {
    event.preventDefault()
  }, [])

  const onDrop = useCallback((event: any) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    if (files.length > 0) {
      handleAddFile(files)
    }
  }, [])
  return (
    <>
      <div className={`customFileUpload ${fullwidth ? 'w-full' : 'w-auto'}`}>
        {label && (
          <div className="">
            <label className={`labels label-text`}>
              {label}
              {required ? <span className="text-error"> *</span> : <></>}
            </label>
          </div>
        )}
        <div
          onDragOver={onDragOver}
          onDrop={onDrop}
          className="customFileUpload-field relative flex flex-col items-center bg-bgGrey border border-formBorder rounded-lg"
        >
          <input
            id={id}
            disabled={disabled}
            multiple={isMultiple}
            onChange={handleFileChange}
            // value={value}
            type={type}
            accept={accept}
          />
          <label
            className="flex flex-col items-center justify-center gap-2 cursor-pointer p-4 w-full min-h-[150px]"
            htmlFor={id}
          >
            {/* <Icons name="question-circle" /> */}
            {/* <p className=" text-primaryText text-xxs leading-4">
              {supportedFiles}{' '}
              <span className="text-primary text-xxs leading-4">
                (Max {sizeLimit} MB)
              </span>
            </p> */}
            {/* <p className="text-[#999696] font-bold text-sm">Drag and Drop</p>
          <p className="text-secondary font-bold text-sm uppercase">Or</p> */}
            <div className="p-4 rounded-sm shadow-sm border-2 border-[#EAECF0]">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 20L15 15M15 15L20 20M15 15V26.25M25 20.9285C26.5269 19.6676 27.5 17.7599 27.5 15.625C27.5 11.828 24.422 8.75 20.625 8.75C20.3519 8.75 20.0963 8.60749 19.9577 8.37217C18.3276 5.60604 15.318 3.75 11.875 3.75C6.69733 3.75 2.5 7.94733 2.5 13.125C2.5 15.7076 3.54431 18.0463 5.23369 19.7419"
                  stroke="#667085"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="flex  gap-1">
              <span className=" text-text-primary">Click to upload</span>
              <span className=" text-text-secondary"> or drag and drop</span>
            </div>
            <p className=" text-primaryText text-xxs leading-4">
              {supportedFiles}{' '}
              <span className="text-primary text-xxs leading-4">
                (Max {sizeLimit} MB)
              </span>
            </p>
            {/* <div className="relative   rounded-[4px] shadow-buttonShadow   btn h-10 text-sm p-2 min-w-[65px] btn-primary ">
              <div className="flex items-center justify-center gap-1  m-auto ">
                <CustomIcons className="iconWhite" name={`${iconName}`} />
                <div className="  font-semibold  text-bgWhite  ">{buttonLabel}</div>
              </div>
            </div> */}
          </label>
        </div>
        {errors && errors[name] && (
          <div className="text-error text-error-label mt-[1px]">
            {getErrors(errors[name])}
          </div>
        )}
        <div className="flex flex-col gap-2 mt-4 text-text-secondary ">
          {Array.isArray(file) && (
            <>
              <div>
                <span className="text-l font-medium">Uploaded Document</span>
              </div>
              <div className="flex flex-col gap-2 max-h-[200px] overflow-auto">
                {file?.map((item, index: number) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between gap-1.5 px-2.5 py-2 bg-table-headBG rounded-sm"
                  >
                    <CustomIcons name="paper_clip" className="iconWidthSm iconBlack" />
                    <p className="flex-1 text-common font-normal break-all">
                      {item?.name}
                    </p>
                    <CustomIcons
                      onClick={() => handleClearFile(index)}
                      name="close"
                      type="large-m"
                      className="iconBlack iconWidthSm cursor-pointer"
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {typeof file === 'object' && file && !isMultiple && (
            <>
              <div>
                <span className="text-l font-medium">Uploaded Document</span>
              </div>
              <div className="flex items-center justify-between gap-1.5 px-2.5 py-2 bg-table-headBG rounded-sm">
                <CustomIcons name="paper_clip" className="iconWidthSm iconBlack" />
                <a
                  href="#/"
                  onClick={() => (file?.link ? window.open(file.link) : '')}
                  className="flex-1  text-common font-normal overflow-hidden cursor-pointer break-all"
                >
                  {file?.name}
                </a>
                <span onClick={() => handleClearFile()} className=" cursor-pointer">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="white" />
                    <path
                      d="M16.8002 7.19941L7.2002 16.7994M7.2002 7.19941L16.8002 16.7994"
                      stroke="#85888E"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default FileUpload

import React from 'react'
const BusinessItemIcon: React.FC = () => {
  return (
    <path
      d="M12 5.99999V3.37548C12 2.75168 12 2.43978 11.8686 2.2481C11.7538 2.08063 11.576 1.96687 11.3758 1.9328C11.1467 1.89382 10.8635 2.02452 10.2971 2.28593L3.64426 5.35649C3.13913 5.58963 2.88656 5.70619 2.70158 5.88698C2.53804 6.04681 2.41321 6.24191 2.33663 6.45737C2.25 6.70108 2.25 6.97925 2.25 7.53559V11.25M12.375 10.875H12.3825M2.25 8.39999L2.25 13.35C2.25 14.1901 2.25 14.6101 2.41349 14.931C2.5573 15.2132 2.78677 15.4427 3.06901 15.5865C3.38988 15.75 3.80992 15.75 4.65 15.75H13.35C14.1901 15.75 14.6101 15.75 14.931 15.5865C15.2132 15.4427 15.4427 15.2132 15.5865 14.931C15.75 14.6101 15.75 14.1901 15.75 13.35V8.39999C15.75 7.55992 15.75 7.13988 15.5865 6.81901C15.4427 6.53677 15.2132 6.30729 14.931 6.16348C14.6101 5.99999 14.1901 5.99999 13.35 5.99999L4.65 5.99999C3.80992 5.99999 3.38988 5.99999 3.06901 6.16348C2.78677 6.30729 2.5573 6.53676 2.41349 6.81901C2.25 7.13987 2.25 7.55991 2.25 8.39999ZM12.75 10.875C12.75 11.0821 12.5821 11.25 12.375 11.25C12.1679 11.25 12 11.0821 12 10.875C12 10.6679 12.1679 10.5 12.375 10.5C12.5821 10.5 12.75 10.6679 12.75 10.875Z"
      stroke="#003C71"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}
export default BusinessItemIcon

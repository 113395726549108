import { TableProps } from '@/common/customComponenttypes'
import React, { useEffect, useRef, useState } from 'react'

import DataCell from './DataCell'

import Checkbox from '../inputs/ModernCheckbox'
import Radio from '../inputs/Radio'

export default function TableCell({
  columns,
  rowData,
  index,
  actionDropDown,
  actionDropDownParams,
  handleMenuActions,
  singleSelection,
  multiSelection,
  handleMultiSelect,
  tableRef,
  handleSingleSelect,
  rowKey,
  staticMenus,
  selectedKey,
  tableKey,
  cellWidth,
  setCheckedKeys,
  checkedKeys,
  workflowParams
}: TableProps) {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const rowRef = useRef<HTMLTableRowElement>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)
  // const handleMenu = (slug: string) => {
  //   handleMenuActions?.(slug, rowData)
  // }

  const clickSingleSelect = () => {
    if (handleSingleSelect) handleSingleSelect(rowData)
  }

  const setSelectKey = () => {
    return rowKey ? rowData[rowKey] : index
  }
  const checkSelectedKey = () => {
    if (rowKey && selectedKey) {
      if (typeof selectedKey === 'string' && selectedKey === rowData[rowKey]) {
        return true
      } else {
        return selectedKey?.includes(rowData[rowKey])
      }
    }
    return false
  }

  const handleScroll = () => {
    if (menuRef && menuRef.current && tableRef && rowRef.current) {
      let topM = singleSelection || multiSelection ? 12 : 20

      let curTop =
        rowRef.current.offsetTop -
        tableRef.current.scrollTop +
        rowRef.current.offsetHeight +
        topM

      menuRef.current.style.top = curTop + 'px'
      if (curTop < 80 || tableRef.current.clientHeight < curTop - 40) {
        menuRef.current.style.top = '-10000px'
      }
    }
  }

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener('scroll', handleScroll)
      tableRef.current.addEventListener('resize', handleScroll)
    }
    handleScroll()

    return () => {
      if (tableRef.current) {
        tableRef.current.removeEventListener('scroll', handleScroll)
        tableRef.current.addEventListener('resize', handleScroll)
      }
    }
  }, [tableRef, menuRef])

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (value !== undefined) {
      let updatedKeys = event.target.checked
        ? [...(checkedKeys as any), value]
        : checkedKeys?.filter((key) => key !== value)
      setCheckedKeys?.(updatedKeys as any)
      handleMultiSelect?.(updatedKeys as any)
    }
  }

  handleScroll()

  return (
    <tr
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      className="border-b border-table-border bg-white hover:bg-table-hover group ishovered"
      ref={rowRef}
    >
      {singleSelection && (
        <td
          key={setSelectKey()}
          className="p-cell text-xxs text-table-bodyColor font-normal flex items-center justify-center"
        >
          <Radio
            id={`radio-select-${setSelectKey()}`}
            name={`radio-select`}
            value={setSelectKey()}
            checked={checkSelectedKey()}
            handleChange={clickSingleSelect}
          />
        </td>
      )}
      {multiSelection && (
        <td
          key={setSelectKey()}
          className="p-cell text-common text-table-bodyColor font-normal flex items-center justify-center"
        >
          <Checkbox
            id={`check-select-${setSelectKey()}`}
            name={`check-select`}
            checked={checkedKeys?.includes(rowData[tableKey as string])}
            value={rowData[tableKey as any]}
            handleChange={handleCheck}
          />
        </td>
      )}
      {columns.map((col, index) => (
        <DataCell
          col={col}
          rowData={rowData}
          key={index}
          cellWidth={cellWidth}
          isHovered={isHovered}
        />
      ))}
      {/* {workflowParams?.isDynamicMenu ? (
        <td className="w-[20px] bg-white group-hover:bg-table-hover z-9">
          <div className="flex items-center justify-center w-[20px] bg-white group-hover:bg-table-hover">
            <DynamicActionMenu
              iconName="three_dot"
              staticMenus={staticMenus}
              handleMenuActions={handleMenu}
              rowData={rowData}
              handleMenuItems={() => workflowParams?.handleMenuItems?.(rowData)}
              updateMenuRef={(ref) => (menuRef.current = ref)}
              handleRefresh={(id) => workflowParams?.handleRefresh(id)}
              fromTable={true}
            />
          </div>
        </td>
      ) : (
        <>
          {actionDropDown && !actionDropDownParams?.hideDropDown?.(rowData) && (
            <td className="w-[20px] bg-white group-hover:bg-table-hover z-9">
              <div className="flex items-center justify-center w-[20px] bg-white group-hover:bg-table-hover">
                <MenuDropDown
                  fromTable={true}
                  iconName="three_dot"
                  // iconClass="group-hover:bg-table-hover bg-white"
                  actionDropDown={actionDropDown}
                  handleMenuActions={handleMenu}
                  updateMenuRef={(ref) => (menuRef.current = ref)}
                />
              </div>
            </td>
          )}
        </>
      )} */}
    </tr>
  )
}

import { Button, Fab, FormBuilder } from '@/components/common'
import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { isAllow } from '@/constants'
import {
  getInspectionTypeDetails,
  useGeneralInfo
} from '@/modules/technical/pages/vesselInspections/details/general/api'
import {
  GeneralSchemaType,
  generalSchema
} from '@/modules/technical/pages/vesselInspections/details/general/schema'
import { InspectionDetailsContextProps } from '@/modules/technical/pages/vesselInspections/details/schema'
import { formContainerClass } from '@/styles/variables'
import useHasPermission from '@/utilities/checkActionPermission'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

type VesselInspectionGeneralForms = {
  data: any
}
type InspectionTypeProps = {
  InspectionTypeId?: number
  RecVesDetained?: number
  RecSailing?: number
  RecMOU?: number
}
const VesselInspectionGeneralForm: React.FC<VesselInspectionGeneralForms> = ({
  data
}) => {
  const { t } = useTranslation()
  const { edit, setEdit, inspID, refetchData } =
    useOutletContext<InspectionDetailsContextProps>()
  const [isOpen, setPopupOpen] = useState<boolean>(false)
  const hasPermission = useHasPermission('Inspection')
  const [inspectType, setInspectType] = useState<InspectionTypeProps>({})

  const clearFieldsBasedOnInspType = (resp: any) => {}
  const handlesetInspectTypeProps = (id: number) => {
    getInspectionTypeDetails(id)
      .then((res) => {
        setInspectType(res)
        methods.setValue('RecMOU', res?.RecMOU)
        clearFieldsBasedOnInspType(res)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const { isMobile, isTab } = useScreenBreakPoint()

  const onSuccessAction = () => {
    setPopupOpen(true)
  }
  const handleClose = () => {
    setPopupOpen(false)
    setEdit(false)
    refetchData?.()
  }
  const { mutate, isLoading } = useGeneralInfo(onSuccessAction)
  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      StartDate: data.StartDate ? dayjs(data.StartDate).format('YYYY-MM-DD') : null,
      EndDate: data.EndDate ? dayjs(data.EndDate).format('YYYY-MM-DD') : null
    }
    mutate(payload)
  }
   const initData = () => {
     return {
       InspectionId: data?.InspectionId,
       InspTypeName: data?.InspTypeName,
       InspectionTypeId: data?.InspectionTypeId,
       CompanyName: data?.CompanyName,
       BranchId: data?.BranchId,
       PortFromName: data?.PortFromName ?? '',
       PortFromId: data?.PortFromId,
       PortToName: data?.PortToName ?? '',
       PortToId: data?.PortToId,
       VesselName: data?.VesselName,
       VesselId: data?.VesselId,
       InspectedByTypeName: data?.InspectedByTypeName,
       InspectedById: data?.InspectedById,
       StartDate: data?.StartDate && new Date(data?.StartDate),
       EndDate: data?.EndDate && new Date(data?.EndDate),
       InspectorName: data?.InspectorName,
       MasterName: data?.MasterName,
       ChengName: data?.ChengName,
       PscMouName: data?.PscMouName ?? '',
       PSCMouId: data?.PSCMouId,
       OfficerName: data?.OfficerName,
       OfficerId: data?.OfficerId,
       RecMOU: data?.RecMOU,
       Sailing: data?.Sailing,
       VesselDetained: data?.VesselDetained,
       InspectionReport: data?.InspectionReport
     }
   }

  const methods = useForm<GeneralSchemaType>({
    resolver: zodResolver(generalSchema),
    mode: 'onChange',
    defaultValues: initData()
  })
  const resetEdit = () => {
    methods.reset()
    setEdit(false)
  }

  const { StartDate } = methods.watch()
  const formConfig = [
    {
      label: 'Vessel',
      placeholder: 'Type to search',
      name: 'VesselName',
      type: 'autocomplete',
      required: true,
      id: 'VesselId',
      disabled: true,
      maxLength: 4,
      apiParams: { key: 'VESSELS' },
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: 'Inspection Type',
      placeholder: 'Type to search',
      name: 'InspTypeName',
      type: 'autocomplete',
      id: 'InspectionTypeId',
      disabled: true,
      required: true,
      apiParams: { key: 'INSPTYPE' },
      maxLength: 4,
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: 'Company',
      placeholder: 'Type to search',
      name: 'CompanyName',
      id: 'BranchId',
      type: 'autocomplete',
      required: true,
      apiParams: {
        key: 'COMPANY',
        filter1: 'INSPECTION',
        filter2: inspectType?.InspectionTypeId
      },
      maxLength: 4,
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: 'From Port',
      placeholder: 'Type to search',
      name: 'PortFromName',
      type: 'autocomplete',
      id: 'PortFromId',
      required: true,
      apiParams: { key: 'PORT' },
      maxLength: 4,
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: 'To Port',
      placeholder: 'Type to search',
      name: 'PortToName',
      type: 'autocomplete',
      id: 'PortToId',
      apiParams: { key: 'PORT' },
      maxLength: 4,
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: 'Inspector Type',
      placeholder: 'Type to search',
      name: 'InspectedByTypeName',
      type: 'autocomplete',
      id: 'InspectedById',
      required: true,
      apiParams: { key: 'INSPBYTYPE' },
      maxLength: 4,
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: t('Start Date'),
      placeholder: t('DD-MMM-YYYY'),
      name: 'StartDate',
      id: 'StartDate',
      type: 'date',
      maxDate: new Date(),
      required: true,
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: t('End Date'),
      placeholder: t('DD-MMM-YYYY'),
      name: 'EndDate',
      id: 'EndDate',
      type: 'date',
      disabled: !StartDate,
      minDate: StartDate || new Date(),
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: 'Inspector',
      placeholder: 'Inspector',
      name: 'InspectorName',
      type: 'text',
      maxLength: 100,
      required: true,
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: 'Master',
      placeholder: 'Master',
      name: 'MasterName',
      type: 'text',
      maxLength: 100,
      required: true,
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: 'Chief Engineer',
      placeholder: 'Chief Engineer',
      name: 'ChengName',
      type: 'text',
      maxLength: 100,
      required: true,
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: 'Officer In Charge',
      placeholder: 'Type to search',
      name: 'OfficerName',
      type: 'autocomplete',
      id: 'OfficerId',
      required: true,
      apiParams: { key: 'USERS', filter1: 'OFFICERS' },
      maxLength: 4,
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: 'MOU',
      placeholder: 'Type to search',
      name: 'PscMouName',
      type: 'autocomplete',
      id: 'PSCMouId',
      disabled: inspectType?.RecMOU === 0,
      required: inspectType?.RecMOU === 1,
      apiParams: { key: 'PSCMOU' },
      maxLength: 4,
      spacing: isMobile || isTab ? 6 : 3
    },
    {
      label: 'Sailing',
      id: 'Sailing',
      name: 'Sailing',
      type: 'switch',
      disabled: inspectType?.RecSailing === 0,
      spacing: 3
    },
    {
      label: 'Vessel Detained',
      name: 'VesselDetained',
      id: 'VesselDetained',
      type: 'switch',
      disabled: inspectType?.RecVesDetained === 0,
      spacing: 3
    },
    {
      label: 'Inspection Report',
      placeholder: 'Inspection Report',
      name: 'InspectionReport',
      type: 'textarea',
      id: 'InspectionReport',
      spacing: 12,
      rows: 10
    }
  ]

 


  useEffect(() => {
    methods.reset({ ...initData() })
  }, [data])

  useEffect(() => {
    if (methods.watch().InspectionTypeId) {
      handlesetInspectTypeProps(methods.watch()?.InspectionTypeId || data.TankTypeId)
    }
  }, [methods.watch().InspectionTypeId])

  const handleEdit = async () => {
    const permission = await hasPermission({
      UserAction: isAllow.UPDATE,
      AccessKey: '',
      ID1: inspID
    })
    setEdit(permission)
  }
  return (
    <div className="h-full relative w-full">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={formContainerClass}>
            <FormBuilder data={formConfig} edit={edit} />

            <div className="py-7">
              <div className="absolute bottom-0 right-0 p-2 py-1">
                {!edit ? (
                  <Fab
                    icontType="large"
                    onClick={() => {
                      handleEdit()
                    }}
                    className="fabShadow"
                  />
                ) : (
                  <div className="flex">
                    <Button
                      label={t('update')}
                      className="me-2"
                      isLoading={isLoading}
                      onClick={methods.handleSubmit(onSubmit)}
                    />
                    <Button
                      label={t('cancel')}
                      outlined
                      onClick={() => {
                        resetEdit()
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <ConfirmModal
        isOpen={isOpen}
        modalId="successPopup"
        topIcon="success"
        title="Success"
        type="success"
        actionLabel="OK"
        onSubmit={handleClose}
        scrollDisable={false}
        onClose={handleClose}
        subTitle="Thank you. You have updated successfully."
      />
    </div>
  )
}

export default VesselInspectionGeneralForm

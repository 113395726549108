import { useDebounceEffect } from '@/hooks/useDebounceEffect'
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  // convertToPixelCrop,
  makeAspectCrop
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'

import 'react-image-crop/dist/ReactCrop.css'
import { getFileExtension } from '@/utilities/dataGenerators'

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}
export type cropRefType = {
  onCrop: () => any
}
export type cropPropType = {
  imgURL?: string
  // fileName?: string
  fileName: string
}
const ImageCropper = forwardRef<cropRefType, cropPropType>(
  ({ imgURL, fileName }, ref) => {
    ImageCropper.displayName = 'ImageCropper'
    useImperativeHandle(ref, () => ({
      onCrop: onCrop
    }))
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const previewRoundedCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)

    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const scale = 1
    const rotate = 0
    const aspect: number = 1 / 1

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
      if (aspect) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, aspect))
      }
    }
    const onCrop = () => {
      return onCropClick()
    }
    async function onCropClick() {
      const image = imgRef.current
      const previewCanvas = previewCanvasRef.current

      if (!image || !previewCanvas || !completedCrop) {
        throw new Error('Crop canvas does not exist')
      }

      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height

      const offscreen: any = new OffscreenCanvas(
        completedCrop.width * scaleX,
        completedCrop.height * scaleY
      )
      const ctx = offscreen.getContext('2d') as any
      if (!ctx) {
        throw new Error('No 2d context')
      }

      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height
      )
      // You might want { type: "image/jpeg", quality: <0 to 1> } to
      // reduce image size
      const file_type = getFileExtension(fileName) || 'png'
    
      const blob = await offscreen.convertToBlob({
        type: `image/${file_type}`
      })

      if (blob) {
        return blob
      }
    }

    useDebounceEffect(
      async () => {
        if (
          completedCrop?.width &&
          completedCrop?.height &&
          imgRef.current &&
          previewCanvasRef.current
        ) {
          // We use canvasPreview as it's much faster than imgPreview.
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop,
            scale,
            rotate
          )
        }
        if (
          completedCrop?.width &&
          completedCrop?.height &&
          imgRef.current &&
          previewRoundedCanvasRef.current
        ) {
          // We use canvasPreview as it's much faster than imgPreview.
          canvasPreview(
            imgRef.current,
            previewRoundedCanvasRef.current,
            completedCrop,
            scale,
            rotate
          )
        }
      },
      100,
      [completedCrop, scale, rotate]
    )

    return (
      <div className="Crop-Controls">
        <div className="h-[400px] bg-black border border-text-primary flex items-center justify-center bg-opacity-20">
          <div>
            {!!imgURL && (
              <ReactCrop
                crop={crop}
                keepSelection
                ruleOfThirds
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                // minWidth={400}
                // minHeight={400}
                // maxHeight={400}
                // circularCrop
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgURL}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                    maxHeight: '400px'
                    // width:"100%",
                    // minHeight: '400px'
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </div>
        </div>
        {!!completedCrop && (
          <div className="pt-4 flex flex-col  gap-3">
            <div className="text-text-primary font-bold text-l pb-2">Preview</div>
            <div className="flex items-center justify-center w-full gap-8 bg-[#d9e2ea4a] rounded p-10">
              <div>
                <div>
                  <canvas
                    ref={previewCanvasRef}
                    className=" border-secondary border-opacity-30 border-2 object-contain w-[150px] h-[150px] "
                  />
                </div>
                <div className="text-text-primary text-xs font-semibold text-center pt-1.5">
                  Cropped Preview
                </div>
              </div>
              <div>
                <div>
                  <canvas
                    ref={previewRoundedCanvasRef}
                    className=" border-secondary border-opacity-30 border-2 object-contain w-[150px] h-[150px] rounded-full"
                  />
                </div>
                <div className="text-text-primary text-xs font-semibold text-center pt-1.5">
                  Avatar Preview
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
)

export default ImageCropper

export const crewingRouterStore: { [key: string]: string } = {
  //registers start
  REGISTERS_RANK: '/crewing/crewrank',
  REGISTER_ACTIVITY_TYPE: '/crewing/activityType',
  REGISTER_CREW_STATUS: '/crewing/crewstatus',
  REGISTER_ASSIGNMENT_TYPE: '/crewing/assignment',
  REGISTER_TOUR_OF_DUTY: '/crewing/tourofduty',
  REGISTER_CREW_DOCUMENT_CATEGORIES: '/crewing/crewdocumentcategories',
  REGISTER_CREW_DOCUMENT_CATEGORY_TYPES: '/crewing/doctypes',
  REGISTER_CREW_DOCUMENT_COMPLIANCE: '/crewing/CrewDocumentCompliance',
  //registers end
  // CREW SEARCH
  CREW_SEARCH: '/crwmgnt/crwsrch',
  CREW_DETAILS: '/crwmgnt/crwsrch/:id',
  // CREW DETAILS SUB ROUTES
  CREW_DETAILS_OVERVIEW: 'overview',

  CREW_DETAILS_TRAINING_AND_CERTIFICATIONS: 'documents-and-certifications',
  CREW_DETAILS_SEA_SERVICE_RECORD: 'sea-service-record',
  CREW_DETAILS_CORRESPONDENCE_OR_NOTES: 'correspondence-or-notes',
  CREW_DETAILS_APPRAISALS: 'appraisals',
  CREW_DETAILS_BANK_DETAILS: 'bank-details',
  CREW_DETAILS_BASIC_INFO: 'crew-details',

  CREW_DETAILS_EMPLOYMENT_AND_PAYROLL: 'employment-and-payroll-details',
  CREW_DETAILS_AUDIT_LOG: 'audit-log'
}

import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'

export const KinCreateSchema = z.object({
  relativeCrewName: z
    .string({
      required_error: 'Relative Crew is required',
      invalid_type_error: 'Relative Crew is required'
    })
    .min(1, 'Relative Crew is required'),
  relativeCrewId: z
    .string({
      required_error: 'Relative Crew is required',
      invalid_type_error: 'Relative Crew is required'
    })
    .min(1, 'Relative Crew is required'),
  relativeCrewNum: z.string().optional().nullable(),
  givenName: z
    .string({
      required_error: 'First Name is required',
      invalid_type_error: 'First Name is required'
    })
    .min(1, 'First Name is required')
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  surname: z
    .string({
      required_error: 'Surname is required',
      invalid_type_error: 'Surname is required'
    })
    .min(1, 'Surname is required')
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  relationName: z.string({
    required_error: 'Relationship is required',
    invalid_type_error: 'Relationship is required'
  }),
  relationId: z.number({
    required_error: 'Relationship is required',
    invalid_type_error: 'Relationship is required'
  }),

  nok: z.boolean().optional().nullable(),
  ice: z.boolean().optional().nullable(),
  crewId: z.string()
})

export const handleSchema = (existingCrew?: string | null) => {
  if (existingCrew === 'existing') {
    return KinCreateSchema.omit({
      givenName: true,
      surname: true
    })
  } else {
    return KinCreateSchema.omit({
      relativeCrewId: true,
      relativeCrewName: true
    })
  }
}

export type KinCreateSchemaType = z.infer<typeof KinCreateSchema>

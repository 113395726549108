import { FormBuilder } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { uploadFile } from '@/modules/technical/pages/vesselInspections/details/documents/api'
import {
  ACCEPTED_IMAGE_TYPES,
  DocumentSchemaType,
  documentSchemaType
} from '@/modules/technical/pages/vesselInspections/details/documents/schema'
import { formContainerClass } from '@/styles/variables'
// import useHasPermission from '@/utilities/checkActionPermission'
import { zodResolver } from '@hookform/resolvers/zod'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

// import { useTranslation } from 'react-i18next'
type VesselTankInfoProps = {
  open: boolean
  onClose: () => void
  title: string
  refetch: () => void
  type?: string
  parentId?: string
}

const DocumentUpload: React.FC<VesselTankInfoProps> = ({
  open,
  title,
  onClose,
  type,
  parentId,
  refetch
}) => {
  const [isSaving, setIsSaving] = useState(false)
 const { showSnackBar } = useCustomSnackBar()
  const onSubmit = (res: DocumentSchemaType) => {
    setIsSaving(true)
    const formData = new FormData()
    if (res.attachment) {
      res.attachment.forEach((item, index) => {
        formData.append(`files`, item)
      })
    }
    if (type === 'insp') {
      formData.append('InspectionId', parentId as string)
    } else {
      formData.append('FindingId', parentId as string)
    }
    uploadFile(formData)
      .then((res) => {
        setIsSaving(false)
        onSuccessAction()
        showSnackBar(res)
        refetch()
      })
      .catch((err) => {
        setIsSaving(false)
        showSnackBar(err?.response?.data?.error, 'error')
      })
  }

  const onSuccessAction = () => {
    handleCloseForm()
  }

  const formConfig = [
    {
      name: 'attachment',
      required: true,
      label: '',
      id: 'attachment',
      descId: 'id',
      type: 'file_upload',
      spacing: 12,
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      supportedFiles: ACCEPTED_IMAGE_TYPES,
      isMultiple: true
    }
  ]

  const methods = useForm<DocumentSchemaType>({
    resolver: zodResolver(documentSchemaType),
    mode: 'onChange',
    defaultValues: {
      attachment: []
    }
  })

  const handleCloseForm = () => {
    methods.reset({ attachment: [] })
    onClose()
  }
  return (
    <>
      <DialogModal
        isOpen={open}
        small={false}
        type="sm"
        title={title}
        body={
          <div className="h-full relative w-full">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={formContainerClass}>
                  <FormBuilder data={formConfig} edit={true} />
                </div>
              </form>
            </FormProvider>
          </div>
        }
        onClose={handleCloseForm}
        actionLabel={'Upload'}
        onSubmit={methods.handleSubmit(onSubmit)}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={'Cancel'}
        actionLoader={isSaving}
      />
    </>
  )
}

export default DocumentUpload

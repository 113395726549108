import { getTechnicalData } from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useQuery } from '@tanstack/react-query'

export const useVesselInspectionDetails = (id: string) => {
  const fetchData = async () => {
    return await getTechnicalData(
      `${apiTechnicalUrl.GET_VESSEL_INSPECTION}/GetById/${id}`
    )
  }

  return useQuery({
    queryKey: ['technical-inspection-details', id],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

import { FormBuilderProps } from '@/common/customComponenttypes'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import { getRegister } from '../../../apis/common.api'
import { generateSpacing } from '../../../utilities'
import FileUpload from '../fileupload'
import CustomIcons from '../icons/customIcons'
import {
  AutoComplete,
  Checkbox,
  DatePicker,
  MentionInput,
  Switch,
  TextArea,
  TextField
} from '../index'
import CustomColorSelect from '../inputs/CustomColorSelect'
import PresetColorSelect from '../inputs/PresetColor'
import QbsAutocompleteComponent from '../inputs/QbsAutoComplete'
import QbsAutocompleteExpandableComponent from '../inputs/QbsAutoCompleteExpandable'
import { getFormErrorMessage } from './utils/helpers'

type Props = {
  data?: any
  gridType?: string
  isView?: boolean
  showErrors?: boolean
  spacing?: number
  customValueStyle?: string
  hasCustombreakpoint?: boolean
  edit?: boolean
  isLoading?: boolean
  setAdditionalValues?: (key: string, value: any) => void
  isTab?: boolean //New boolean key added to manage default spacing (12)
  autoFocus?: boolean // disable auto focus manually
}
// function isValidInput(input: string) {
//   const regex = /^[a-zA-Z0-9-@ ]+$/
//   return regex.test(input)
// }
const FormBuilder: React.FC<Props> = (props) => {
  const {
    control,
    setValue,
    setFocus,
    formState: { errors, isSubmitted },
    watch
  } = useFormContext()

  const {
    data,
    isView = false,
    edit,
    customValueStyle,
    isLoading = false,
    hasCustombreakpoint = false,
    spacing = 4,
    setAdditionalValues,
    isTab,
    showErrors = true,
    autoFocus = true
  } = props

  const [focusField, setFocusField] = useState<string | undefined>(undefined)
  const [loader, setLoader] = useState(false)
  const { showSnackBar } = useCustomSnackBar()
  const { t } = useTranslation()

  const handleChange = useCallback((e: any, field: FormBuilderProps) => {
    setValue(field?.id, e.id)
    setValue(field.name, e.name === '' || e.name === null ? undefined : e.name, {
      // DEV: null issue
      shouldValidate: true
    })
    if (field.additionalValue) {
      setAdditionalValues?.(field.id, e)
    }
    field?.callbackFn?.(e)
  }, [])
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: FormBuilderProps
  ) => {
    const { value } = e.target

    const trimValue = value.trimStart()
    setValue(field.name, trimValue === '' || trimValue === null ? undefined : trimValue, {
      // DEV: null issue
      shouldValidate: true
    })
    field?.callbackFn?.(trimValue)
  }

  const onPrefixChange = useCallback((e: any, field: FormBuilderProps) => {
    setValue(field?.prefixes?.id, e.id)
    setValue(
      field?.prefixes?.name,
      e.name === '' || e.name === null ? undefined : e.name,
      {
        // DEV: null issue
        shouldValidate: true
      }
    )
    if (field?.prefixes?.additionalValue) {
      setAdditionalValues?.(field?.prefixes?.id, e)
    }
    field?.prefixes?.callbackFn?.(e)
  }, [])
  const handleMentionInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: FormBuilderProps
  ) => {
    const { value } = e.target

    const trimValue = value.trimStart()
    setValue(field.name, trimValue === '' || trimValue === null ? '' : trimValue, {
      // DEV: null issue
      shouldValidate: true
    })
    field?.callbackFn?.(trimValue)
  }
  const handleReturnValue = useCallback((field: FormBuilderProps) => {
    const data = watch() ?? {}
    return { id: data[field.id] ?? '', name: data[field.name] ?? '' }
  }, [])

  const getData = async (value?: string, apiParams?: any) => {
    return getRegister({ ...apiParams, desc: value }).then((resps) => {
      return resps.map((data: any) => {
        return {
          name: data.Description,
          id: data.Id,
          param1: data.Param1,
          param2: data.Param2
        }
      })
    })
  }

  const handleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: FormBuilderProps
  ) => {
    const { value } = e.target

    const parsedValue =
      value && value !== '' ? (field.decimal ? parseFloat(value) : parseInt(value)) : null

    setValue(field.name, parsedValue, {
      shouldValidate: true
    })

    field?.callbackFn?.(value)
  }

  const colorchange = (field: any, e: any) => {
    setValue(field.name, e?.target?.value, {
      shouldValidate: true
    })
  }

  const handleColorChange = (e: string | undefined, field: FormBuilderProps) => {
    setValue(field.name, e, {
      shouldValidate: true
    })
    field?.callbackFn?.(e)
  }

  const handleInputBlur = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: FormBuilderProps
  ) => {
    const { value } = e.target
    field?.callbackFn?.(value)
  }

  const setErrorFocus = (errField: string) => {
    data.forEach((field: FormBuilderProps) => {
      if (field?.name === errField) {
        if (
          !field?.hidden &&
          !field?.disabled &&
          [
            'custom_select',
            'custom_search_select',
            'multi_select',
            'date',
            'file_upload',
            'switch',
            'checkbox'
          ].indexOf(field?.type) === -1
        ) {
          setFocusField(errField)
        }
      }
    })
  }
  useEffect(() => {
    setFocusField(undefined)

    if (Object.keys(errors).length > 0 && isSubmitted) {
      setTimeout(() => {
        setErrorFocus(Object.keys(errors)[0])
      }, 0)
      const filteredLength = data.filter((field: FormBuilderProps) => {
        return !field.hidden
      }).length

      if (Object.keys(errors).length > 0 && filteredLength > 1 && showErrors) {
        const errMsg = getFormErrorMessage(errors, data)
        if (errMsg) showSnackBar(`${t('errorToastMessage')} ${errMsg}`, 'error')
      }
    }
  }, [errors, isSubmitted])
  useEffect(() => {
    if (autoFocus) setAutoFocusField()
  }, [])

  const setAutoFocusField = () => {
    let firstField: string | undefined = undefined

    let fieldChoosed: boolean = false
    data.forEach((field: FormBuilderProps) => {
      if (
        !field?.hidden &&
        !field?.disabled &&
        firstField === undefined &&
        !fieldChoosed &&
        edit
      ) {
        fieldChoosed = true
        if (
          [
            'custom_select',
            'custom_search_select',
            'multi_select',
            'date',
            'file_upload',
            'switch',
            'checkbox'
          ].indexOf(field?.type) === -1
        ) {
          firstField = field?.name
        }
      }
    })
    setFocusField(firstField)
  }

  const getAutoFocus = (field: any): boolean => {
    return focusField && (focusField === field?.name || focusField === field?.id)
      ? true
      : false
  }

  const handleMultiChange = useCallback((e: any, field: FormBuilderProps) => {
    setValue(field.name, e, { shouldValidate: true })
  }, [])
  const handleFileUpload = (val: any, field: FormBuilderProps) => {
    setValue(field.name, val, { shouldValidate: true })
  }

  // checkbox onchange event added
  const hadleCheckBoxChange = (e: any, name: string) => {
    setValue(name, e.target.checked, { shouldValidate: true })
  }

  const renderForm = (field: FormBuilderProps) => {
    switch (field.type) {
      case 'text':
      case 'email':
        return (
          <Controller
            name={field.name}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={field.label}
                placeholder={field.placeholder}
                id={field.name}
                name={field.name}
                uppercase={field.uppercase}
                type={field.type}
                isValid={field.isValid}
                prefixes={field.prefixes}
                autoFocus={getAutoFocus(field)}
                onPrefixChange={(e) => onPrefixChange(e, field)}
                value={watch()[field.name] ?? ''} // DEV: updated value to watch method
                prefixValue={
                  field?.prefixes
                    ? handleReturnValue(field.prefixes)
                    : {
                        id: '',
                        name: ''
                      }
                } // DEV: updated value to watch method
                setFocus={setFocus}
                onChange={(e) => handleInputChange(e, field)}
                onBlur={(e) => handleInputBlur(e, field)}
                required={field.required}
                adorement={field.adorement}
                errors={errors}
                maxLength={field.maxLength ?? 200}
                minLength={field.minLength}
                disabled={isEditable() || field.disabled}
                keyRegexPattern={field.keyRegexPattern}
              />
            )}
          />
        )
      case 'password':
        return (
          <Controller
            name={field.name}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={field.label}
                placeholder={field.placeholder}
                id={field.name}
                name={field.name}
                isValid={field.isValid}
                type={field.type}
                autoFocus={getAutoFocus(field)}
                value={value}
                setFocus={setFocus}
                onChange={(e) => handleInputChange(e, field)}
                onBlur={(e) => handleInputBlur(e, field)}
                required={field.required}
                adorement={field.adorement}
                errors={errors}
                maxLength={field.maxLength ?? 200}
                minLength={field.minLength}
                disabled={isEditable() || field.disabled}
              />
            )}
          />
        )
      case 'number':
        return (
          <Controller
            name={field.name}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={field.label}
                step={field.step}
                placeholder={field.placeholder}
                id={field.name}
                name={field.name}
                min={field.min}
                max={field.max}
                isValid={field.isValid}
                value={value}
                setFocus={setFocus}
                autoFocus={getAutoFocus(field)}
                type={field.type}
                onChange={(e) => handleNumberChange(e, field)}
                onBlur={(e) => handleInputBlur(e, field)}
                required={field.required}
                adorement={field.adorement}
                errors={errors}
                maxLength={field.maxLength ?? 200}
                minLength={field.minLength}
                disabled={isEditable() || field.disabled}
              />
            )}
          />
        )
      case 'color':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomColorSelect
                label={field.label}
                onChange={(e) => colorchange(field, e)}
                errors={errors}
                value={value}
                required={field.required}
                name={field.name}
                id={field.id}
                readOnly={false} // Set to true or false based on your requirement
                disabled={isEditable() || field.disabled}
                adjustFieldLabel={field.adjustFieldLabel}
              />
            )}
          />
        )

      case 'presetcolor':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <PresetColorSelect
                // errors={errors}
                value={value}
                onChange={(e) => handleColorChange(e, field)}
                required={field.required}
                name={field.name}
                // id={field.id}
                // readOnly={false}
                disabled={isEditable() || field.disabled}
                // adjustFieldLabel={field.adjustFieldLabel}
              />
            )}
          />
        )

      case 'textarea':
        return (
          <Controller
            name={field.name}
            render={({ field: { onChange, value } }) => (
              <TextArea
                label={field.label}
                placeholder={field.placeholder}
                id={field.name}
                name={field.name}
                autoFocus={getAutoFocus(field)}
                value={value}
                setFocus={setFocus}
                onChange={(e) => handleInputChange(e, field)}
                required={field.required}
                rows={field.rows || 5}
                errors={errors}
                maxLength={field.maxLength ?? 200}
                minLength={field.minLength}
                disabled={isEditable() || field.disabled}
              />
            )}
          />
        )
      case 'autocomplete':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoComplete
                key={field.key ? `${field.name}${field.key}` : field.name}
                autoFocus={getAutoFocus(field)}
                placeholder={field.placeholder}
                onChange={(e) => handleChange(e, field)}
                name={field.name}
                value={handleReturnValue(field) ?? { id: '', name: '' }}
                type="auto_complete"
                label={field.label}
                getData={
                  field.apiParams
                    ? (key) => getData(key, field.apiParams)
                    : (key) => field.data // to avoid api call if api params not present
                }
                noLocalFilter={field.isStaticList ? false : true}
                data={field.data}
                errors={errors}
                disabled={isEditable() || field.disabled}
                required={field.required}
                autoFilter={field.autoFilter ? field.autoFilter : false}
                isCustomPlaceholder={field.isCustomPlaceholder ?? false}
                isStaticList={field.isStaticList ?? false}
                checkParams={field.checkParams}
              />
            )}
          />
        )
      case 'mention':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <MentionInput
                key={field.name}
                onChange={(e) => handleMentionInputChange(e, field)}
                name={field.name}
                id={field.name}
                value={value}
                label={field.label}
                markup={field.markup}
                idKey={field.idKey}
                placeholder={field.placeholder}
                trigger={field.trigger}
                apiParams={field.apiParams}
                displayTransform={field.displayTransform}
                onAdd={field.onAdd}
                onkeydown={field.onkeydown}
                data={field.data}
                errors={errors}
                disabled={isEditable() || field.disabled}
                required={field.required}
              />
            )}
          />
        )
      case 'custom_search_select':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoComplete
                key={field.name}
                placeholder={field.placeholder}
                autoFocus={getAutoFocus(field)}
                onChange={(e) => handleChange(e, field)}
                name={field.name}
                value={handleReturnValue(field) ?? { id: '', name: '' }}
                type="custom_search_select"
                label={field.label}
                hideClear={field.hideClear}
                data={field.data}
                getData={(key) => getData(key, field.apiParams)}
                errors={errors}
                disabled={isEditable() || field.disabled}
                required={field.required}
                autoFilter={field.autoFilter ? field.autoFilter : false}
              />
            )}
          />
        )
      case 'custom_select':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoComplete
                key={field.key ? `${field.name}${field.key}` : field.name}
                placeholder={field.placeholder}
                autoFocus={getAutoFocus(field)}
                onChange={(e) => handleChange(e, field)}
                name={field.name}
                value={handleReturnValue(field) ?? { id: '', name: '' }}
                type="custom_select"
                label={field.label}
                hideClear={field.hideClear}
                data={field.data}
                getData={(key) => getData(key, field.apiParams)}
                errors={errors}
                disabled={isEditable() || field.disabled}
                required={field.required}
                autoFilter={field.autoFilter ? field.autoFilter : false}
              />
            )}
          />
        )
      case 'multi_select':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <QbsAutocompleteComponent
                key={field.key ? `${field.name}${field.key}` : field.name}
                name={field.name}
                type="custom_select"
                desc={field.desc as string}
                descId={field.descId as string}
                onChange={(e) => handleMultiChange(e, field)}
                selectedItems={field.selectedItems ?? watch()[field.name] ?? []}
                label={field.label}
                errors={errors}
                disabled={isEditable() || field.disabled}
                placeholder={field.placeholder}
                required={field.required}
                handleAction={field.handleAction}
                isMultiple={field.isMultiple}
                data={field.data}
              />
            )}
          />
        )
      case 'multi_select_expandable':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <QbsAutocompleteExpandableComponent
                key={field.key ? `${field.name}${field.key}` : field.name}
                name={field.name}
                type="auto_suggestion"
                desc={field.desc as string}
                descId={field.descId as string}
                onChange={(e) => handleMultiChange(e, field)}
                selectedItems={field.selectedItems ?? watch()[field.name] ?? []}
                label={field.label}
                errors={errors}
                disabled={isEditable() || field.disabled}
                placeholder={field.placeholder}
                required={field.required}
                handleAction={field.handleAction}
                isMultiple={field.isMultiple}
                getData={
                  field.apiParams
                    ? (key) => getData(key, field.apiParams)
                    : (key) => field.data // to avoid api call if api params not present
                }
                data={field.data}
              />
            )}
          />
        )
      case 'file_upload':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <FileUpload
                  name={field.name}
                  id={field.name}
                  onChange={(value) => handleFileUpload(value, field)}
                  label={field.label ?? ''}
                  value={field.selectedFiles}
                  isMultiple={field.isMultiple}
                  errors={errors}
                  handleDeleteFile={field.handleDeleteFile}
                  supportedExtensions={field?.supportedExtensions}
                  supportedFiles={field.acceptedFiles ?? 'PNG, PDF,JPG, EXCEL, DOC, CSV'}
                  sizeLimit={field.fileSize ?? 5}
                  buttonLabel={field.fileUploadLabel ?? 'Browse & Upload'}
                  iconName="cloud-upload"
                  accept={field.accept}
                />
              )
            }}
          />
        )
      case 'date':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <DatePicker
                  onChange={(data) => {
                    setValue(field.name, data.value, { shouldValidate: true })
                  }}
                  name={field.name}
                  value={value}
                  autoFocus={getAutoFocus(field)}
                  placeholder={field.placeholder || 'DD-MMM-YYYY'}
                  label={field.label}
                  minDate={field.minDate}
                  minYearRange={field.minYearRange} // added date dropdown years range
                  maxDate={field.maxDate}
                  showYearPicker={field.showYearPicker}
                  errors={errors}
                  disabled={isEditable() || field.disabled}
                  required={field.required}
                />
              )
            }}
          />
        )

      case 'switch':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                id={field.name}
                name={field.name}
                value={value}
                handleChange={(e: any) => {
                  onChange(e)
                  field.callbackFn?.(e)
                }}
                isLabelWrap={field.isLabelWrap}
                disabled={isEditable() || field.disabled}
                label={field.label}
                info={field.info}
              />
            )}
          />
        )
      case 'checkbox':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={() => (
              <Checkbox
                id={field.name}
                name={field.name}
                value={watch()[field.name]}
                checked={watch()[field.name]}
                handleChange={(e: any) => {
                  hadleCheckBoxChange(e, field.name)
                  field.callbackFn?.(e)
                }}
                disabled={isEditable() || field.disabled}
                label={field.label}
              />
            )}
          />
        )
      default:
        return null
    }
  }

  const isEditable = () => {
    return !edit
  }
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (!edit) {
      setLoader(true)

      timer = setTimeout(() => {
        setLoader(false)
      }, 1200)
    }

    if ((edit || !isView) && autoFocus) setAutoFocusField()

    return () => clearTimeout(timer)
  }, [edit, isView])

  const isNullOrUndefined = (val: any) => val === undefined || val === null

  const renderViewFields = (field: FormBuilderProps) => {
    const textAreaHeight = field.rows ? field.rows * 21 : 5 * 21
    let fieldValue = watch()?.[field.name]
    if (field.type === 'switch') {
      fieldValue = fieldValue ? 'Yes' : 'No'
    } else if (field.type === 'date') {
      fieldValue = fieldValue ? dayjs(fieldValue).format('DD-MMM-YYYY') : '-'
    }
    return (
      <div>
        <div className="text-grey-secondary text-xs font-medium truncate">
          {field.type === 'switch' ? (
            <div className="flex">
              <CustomIcons
                name="checkSwitch"
                type="large"
                viewBox={true}
                className={fieldValue === 'Yes' ? 'text-[#54A361]' : 'text-[#D2D2D2]'}
              />
              <span className="ml-2">{field?.label}</span>
            </div>
          ) : (
            field?.label
          )}
        </div>
        {field.type !== 'switch' && (
          <div
            className={
              customValueStyle ?? 'text-secondary  text-common font-medium truncate '
            }
            title={(field.display_name || fieldValue) ?? undefined}
          >
            {isLoading ? (
              <div className="custom-skelton-gradient w-3/4 h-[14px] rounded-full"></div>
            ) : (
              <div>
                <span>
                  <p
                    className={`${field.type === 'textarea' ? 'overflow-auto text-wrap' : 'truncate'}`}
                    style={{
                      ...(field.type === 'textarea'
                        ? { maxHeight: textAreaHeight, minHeight: 40 }
                        : {})
                    }}
                  >
                    <>
                      {!isNullOrUndefined(field.display_name || fieldValue)
                        ? field.display_name || fieldValue
                        : '-'}
                    </>
                    <>
                      {field.adorement && <span className="pl-1">{field.adorement}</span>}
                    </>
                  </p>
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
  return (
    <>
      {data.map((field: FormBuilderProps) => (
        <React.Fragment key={field.name}>
          {!field?.hidden && (
            <div
              key={field.name}
              className={`${generateSpacing(isTab ? 12 : field.spacing || spacing, hasCustombreakpoint)} ${
                (field.type === 'switch' || field.type === 'checkbox') &&
                'flex items-center'
              }`}
            >
              {loader ? (
                <div className=" h-10 bg-background-skeltonLoader rounded-input flex  items-center gap-1 p-2">
                  <div className="custom-skelton-gradient w-[140px] h-[10px] rounded-[30px]"></div>
                </div>
              ) : (
                <div>{isView ? renderViewFields(field) : renderForm(field)}</div>
              )}
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export default FormBuilder

import { FormBuilder } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { guid } from '@/constants'
import {
  createInspectionFindings,
  getInspectionTypeDetails
} from '@/modules/technical/pages/vesselInspections/details/findings/api'
import { formContainerClass } from '@/styles/variables'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { GeneralSchemaType, generalSchema } from './schema'
import {  typeList } from './store'
import { z, ZodSchema } from 'zod'

type CreateFormProps = {
  data: any
  open: boolean
  onClose: () => void
  view: boolean
  title: string
  refetch: any
}

// type InspectionTypeProps = {
//   InspectionTypeId?: number
//   PSCDeficiencyId?: number
//   PSCActionId?: number
//   RecPSCInfo?: number
//   RecPerfIndicator?: number
//   RecSysArea?: number
//   RecSireChapter?: number
//   RecFindCategory?: number
// }

export const CreateInspectionFindDetails: React.FC<CreateFormProps> = ({
  data,
  view,
  open,
  title,
  onClose,
  refetch
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [inspectType, setInspectType] = useState<any>({})
    const [schema, setSchema] = useState<ZodSchema>(generalSchema)
  const { showSnackBar } = useCustomSnackBar()
  const handlesetInspectTypeProps = (id: number) => {
    getInspectionTypeDetails(id)
      .then((res) => {
        setInspectType(res)
        methods.reset({
          ...methods.watch(),
          RecFindCategory: res?.RecFindCategory,
          RecPerfIndicator: res?.RecPerfIndicator,
          RecPSCInfo: res?.RecPSCInfo,
          RecSysArea: res?.RecSysArea,
          RecSireChapter: res?.RecSireChapter
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  const onSuccessAction = () => {
    handleCloseForm()
    refetch()
  }

  const initData = () => {
    return { ...data }
  }

  const onSubmit = (res: GeneralSchemaType) => {
    const api = createInspectionFindings
    const payload = {
      ...res,
      FindingId: guid,
      InspectionId: data.InspectionId,
      VesselId: data.VesselId,
      TargetDate: res.TargetDate ? dayjs(res.TargetDate).format('YYYY-MM-DD') : null
    }
    setIsSaving(true)
    api(payload)
      .then((res) => {
        setIsSaving(false)
        onSuccessAction()
        showSnackBar(res)
      })
      .catch((err) => {
        setIsSaving(false)
        showSnackBar(err?.response?.data?.error, 'error')
      })
  }

  const handleCloseForm = () => {
    methods.reset(initData())
    clearErrors()
    onClose()
  }

   const updateSchema = () => {
     let updatedSchema = generalSchema

     if (inspectType?.RecPerfIndicator === 2) {
       updatedSchema = updatedSchema.extend({
         PerformanceIndName: z.string().nonempty('Performance Indicator is required')
       })
     } else {
       updatedSchema = updatedSchema.extend({
         PerformanceIndName: z.string().optional()
       })
     }

     if (inspectType?.RecFindCategory === 2) {
       updatedSchema = updatedSchema.extend({
         FindCategoryName: z.string().nonempty('Find Category is required')
       })
     } else {
       updatedSchema = updatedSchema.extend({
         FindCategoryName: z.string().optional()
       })
     }

     if (inspectType?.RecPSCInfo === 2) {
       updatedSchema = updatedSchema.extend({
         PSCDeficiecnyName: z.string().nonempty('PSC Deficiency is required'),
         PSCActionName: z.string().nonempty('PSC Action is required')
       })
     } else {
       updatedSchema = updatedSchema.extend({
         PSCDeficiecnyName: z.string().optional(),
         PSCActionName: z.string().optional()
       })
     }

     if (inspectType?.RecSysArea === 2) {
       updatedSchema = updatedSchema.extend({
         SystemAreaName: z.string().nonempty('System Area is required')
       })
     } else {
       updatedSchema = updatedSchema.extend({
         SystemAreaName: z.string().optional()
       })
     }

     if (inspectType?.RecSireChapter === 2) {
       updatedSchema = updatedSchema.extend({
         SireCodeName: z.string().nonempty('Sire Chapter is required')
       })
     } else {
       updatedSchema = updatedSchema.extend({
         SireCodeName: z.string().optional()
       })
     }

     setSchema(updatedSchema)
   }

  const methods = useForm<GeneralSchemaType>({
    resolver: zodResolver(schema),
    mode: 'onChange'
    // defaultValues: initData()
  })

  useEffect(() => {
    if (data.InspectionTypeId) {
      handlesetInspectTypeProps(data.InspectionTypeId)
    }
  }, [data.InspectionTypeId])

    useEffect(() => {
      updateSchema()
    }, [inspectType])

  const formFields = [
    {
      label: 'Target Date',
      placeholder: 'DD-MMM-YY',
      name: 'TargetDate',
      type: 'date',
      required: true
    },
    {
      label: 'Type',
      placeholder: 'Type',
      required: true,
      id: 'FindingTypeId',
      name: 'FindingTypeName',
      type: 'custom_select',
      data: typeList
    },
    {
      label: 'Category',
      required: inspectType?.RecFindCategory === 2,
      disabled: inspectType?.RecFindCategory === 0,
      name: 'FindCategoryName',
      id: 'FindCatId',
      placeholder: 'Type to search',
      type: 'autocomplete',
      apiParams: { key: 'FINDCATS' }
    },
    {
      label: 'Performance Indicator',
      placeholder: 'Type to search',
      required: inspectType?.RecPerfIndicator === 2,
      disabled: inspectType?.RecPerfIndicator === 0,
      id: 'PerformanceIndId',
      name: 'PerformanceIndName',
      type: 'autocomplete',
      apiParams: { key: 'PERFIND', filter1: inspectType?.InspectionTypeId }
    },
    {
      label: 'PSC Deficiency',
      placeholder: 'Type to search',
      name: 'PSCDeficiecnyName',
      required: inspectType?.RecPSCInfo === 2,
      disabled: inspectType?.RecPSCInfo === 0,
      id: 'PSCDeficiencyId',
      type: 'autocomplete',
      apiParams: { key: 'PSCDEFIC', filter1: 8 }
    },
    {
      label: 'PSC Action',
      placeholder: 'Type to search',
      name: 'PSCActionName',
      id: 'PSCActionId',
      required: inspectType?.RecPSCInfo === 2,
      disabled: inspectType?.RecPSCInfo === 0,
      apiParams: { key: 'PSCACTION', filter1: 8 },
      type: 'autocomplete'
    },
    {
      label: 'System Area',
      required: inspectType?.RecSysArea === 2,
      disabled: inspectType?.RecSysArea === 0,
      placeholder: 'Type to search',
      name: 'SystemAreaName',
      id: 'SystemAreaId',
      type: 'autocomplete',
      apiParams: { key: 'SYSTAREA' }
    },
    {
      label: 'Sire Chapter',
      required: inspectType?.RecSireChapter === 2,
      disabled: inspectType?.RecSireChapter === 0,
      placeholder: 'Type to search',
      name: 'SireCodeName',
      id: 'SireCodeId',
      type: 'autocomplete',
      apiParams: { key: 'SIRECODE' }
    },
    {
      label: 'Focus Area',
      placeholder: 'Focus Area',
      required: true,
      name: 'FocusArea',
      type: 'text'
    },
    {
      label: 'Finding Details',
      placeholder: 'Finding Details',
      required: true,
      name: 'FindingDetails',
      type: 'textarea',
      spacing: 12
    }
  ]
  // const {
  //   FindCategoryName,
  //   PerformanceIndName,
  //   PSCDeficiecnyName,
  //   PSCActionName,
  //   SystemAreaName,
  //   SireCodeName
  // } = methods.watch()

  const {
    //setError,
    clearErrors,
    //formState: { isSubmitting }
  } = methods
  // useEffect(() => {
  //   handleValidate()
  // }, [
  //   FindCategoryName,
  //   PerformanceIndName,
  //   PSCDeficiecnyName,
  //   PSCActionName,
  //   SystemAreaName,
  //   SireCodeName,
  //   isSubmitting
  // ])
 // const result: any = methods.watch()

  // const handleValidate = () => {
  //   customValidationItems.forEach((item: any) => {
  //     if (inspectType[item.validator] === 2 && !result[item.title]) {
  //       setError(item.title, { message: item.message, type: 'custom' })
  //     } else {
  //       clearErrors(item.title)
  //     }
  //   })
  // }

  return (
    <>
      <DialogModal
        isOpen={open}
        small={false}
        type="lg"
        title={title}
        body={
          <div className="h-full relative w-full">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={formContainerClass}>
                  <FormBuilder data={formFields} edit={!view} />
                </div>
              </form>
            </FormProvider>
          </div>
        }
        onClose={handleCloseForm}
        actionLabel={'Save'}
        onSubmit={methods.handleSubmit(onSubmit)}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={view ? 'Close' : 'Cancel'}
        actionLoader={isSaving}
      />
    </>
  )
}

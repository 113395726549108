import { z } from 'zod'

export const FormSchema = z.object({
  vesselRoleId: z
    .object({
      id: z.union([z.number(), z.string()]),
      name: z.string()
    })
    .refine(
      (data) => data.id !== null && data.id !== undefined && data.name !== '',
      'Role is required'
    ),

  userId: z
    .object({
      id: z.union([z.number(), z.string()]),
      name: z.string()
    })
    .refine(
      (data) => data.id !== null && data.id !== undefined && data.name !== '',
      'Name is required'
    ),

  // EffectiveFrom: z.date({ required_error: 'Effective from is required' }),
  effectiveFrom: z.date({
    required_error: 'required',
    invalid_type_error: 'required'
  }),
  archived: z.boolean(),
  vesContactId: z.string().optional().nullable(),
  vesselId: z.string()
})
export type FormDataType = z.infer<typeof FormSchema>

import { CustomTab, CustomTabContainer } from '@/components/common'
import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { useCrewProfileStore } from '@/modules/crewing/pages/crewSearch/details/crewDetails/store'
import React, { useState } from 'react'
import KinAddressDetails from './kinAddressDetails'
import KinDetails from './kinDetails'
type Props = {
  data: any
  dataStore?: any
  pageRouting: boolean
  onResetData?: (data: any) => void
}
const KinDetailsInfo: React.FC<Props> = ({
  data,
  dataStore,
  onResetData,
  pageRouting
}) => {
  const [activeTab, setActiveTab] = useState('details')
  const [section, setSection] = useState<string | null>(null)
  const { isEditMode, resetEdit } = useCrewProfileStore()
  const [isOpen, setIsOpen] = useState(false)
  const tabProps = [
    {
      id: 'details',
      label: 'Kin Details'
    },
    {
      id: 'address',
      label: 'Address'
    },
    {
      id: 'documents',
      label: 'Documents'
    }
  ]
  const handleConfirm = () => {
    setIsOpen(false)
    resetEdit()
    setActiveTab(section as string)
  }
  const onTabClick = (tab: any) => {
    setSection(tab.id)
    if (!isEditMode()) {
      setActiveTab(tab.id)
    } else {
      setIsOpen(true)
    }
  }

  return (
    <div className="w-full border-t mt-3">
      <div className="relative">
        <CustomTabContainer
          data={tabProps}
          activeTab={activeTab}
          onClick={onTabClick}
          tabBGClass="bg-tab-detail rounded-t-sm custom-tab-shadow px-0"
        >
          <CustomTab id={'details'}>
            <KinDetails
              data={data}
              dataStore={dataStore}
              onResetData={onResetData}
              pageRouting={pageRouting}
            />
          </CustomTab>
          <CustomTab id={'address'}>
            <KinAddressDetails data={data} pageRouting={pageRouting} />
          </CustomTab>
        </CustomTabContainer>
      </div>
      <ConfirmModal
        isOpen={isOpen}
        modalId="errorPopup"
        title={'Confirmation'}
        type="warning"
        secondaryActionLabel="No"
        secondaryAction={() => setIsOpen(false)}
        actionLabel="Yes"
        onSubmit={() => handleConfirm()}
        topIcon="warning"
        scrollDisable={true}
        onClose={() => setIsOpen(false)}
        subTitle={'Do you want to discard all the changes made?'}
      />
    </div>
  )
}

export default KinDetailsInfo

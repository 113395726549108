import { Button } from '@/components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const SessionTimeout: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/')
  }
  return (
    <div className="w-full h-screen bg-background flex items-center overflow-auto justify-center">
      <div className="flex flex-col md:flex-row p-3 md:p-7 ">
        <div className="text-center md:text-left  rounded md:py-32 text-grey-medium text-common font-medium bg-white flex flex-col md:flex-row w-full md:w-[997px] py-5">
          <div className=" h-[240px] w-[436.52px] p-3 md:p-0">
            <img src="/assets/401.png" alt="" className="h-full " />
          </div>
          <div className="translate-x-[0] md:translate-x-[-40px] h-full">
            <h1 className="text-[30px] md:text-[45px] text-secondary  leading-[59px]  md:my-0  font-semibold ">
              {t('timeoutHeading')}
            </h1>
            <h1 className="text-[30px] md:text-[45px] text-secondary leading-[59px] md:my-0 font-semibold">
              {t('loggedOut')}
            </h1>
            <p className="mx-auto text-slug-text-errors pt-3 font-normal ">
              {t('timeoutSlogan')}
            </p>
            <div className="flex w-full items-center md:items-start  justify-center   md:justify-start pt-7 pb-1">
              <Button label={t('login')} onClick={handleBack} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionTimeout

import {
  AccordionContextProviderProps,
  AccordionContextTypeProps
} from '@/common/customComponenttypes'
import { createContext, useContext } from 'react'

export const AccordionContext = createContext<AccordionContextTypeProps | null>(null)
AccordionContext.displayName = 'Component.AccordionContext'

export const useAccordion = () => {
  const context = useContext(AccordionContext)
  if (!context) {
    throw new Error(
      'useAccordion() must be used within an Accordion. It happens when you use AccordionHeader or AccordionBody components outside the Accordion component.'
    )
  }

  return context
}

export const AccordionContextProvider = ({
  value,
  children
}: AccordionContextProviderProps) => {
  return <AccordionContext.Provider value={value}>{children}</AccordionContext.Provider>
}

import { z } from 'zod'

export type VesselInspectionContextProps = {
  edit: boolean
  setEdit: (status: boolean) => void
  FindingId: string
  VesselId: string
  refetchData: any
}

export const FindingActionSchema = z.object({
  actionOn: z
    .date({
      invalid_type_error: 'Action Date is Required',
      required_error: 'Action Date is Required'
    })
    .refine((date) => Boolean(date), {
      message: 'Action Date is Required'
    }),
  actionBy: z
    .string({ required_error: 'Action Done By Name is required' })
    .min(1, { message: 'Action Done By Name is Required' }),
  actionDone: z
    .string({ required_error: 'Action is Required' })
    .min(1, { message: 'Action is Required' }),
  vesselId: z.union([z.string(), z.number()]).optional().nullable(),
  findingId: z.union([z.string(), z.number()]).optional().nullable()
})

export type FindingActionSchemaType = z.infer<typeof FindingActionSchema>

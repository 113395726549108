import {
  deleteFavouriteMenu,
  getFavouriteMenus,
  getMenuExplorer,
  setFavouriteMenu
} from '@/apis/common.api'
import { FavouriteMenuProps, MenuExplorerProps } from '@/common/customComponenttypes'
import { useSideMenuStore } from '@/store/sideMenuStore'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useExploreMenu } from '@/store/appStore'
import SideNavDesktop from './sidenavDesktop'
import SideNavSmall from './SideNavMobile'

const SideNav: React.FC = () => {
  const location = useLocation()

  const [favMenuData, setFavMenuData] = useState<FavouriteMenuProps[]>([])
  const [selectedButtonRef, setSelectedButtonRef] = useState<HTMLDivElement>()
  const {
    sectionId,
    setSectionId,
    menuData,
    setMenuData,
    setSubSectionId,
    setIsActiveMenu
  } = useSideMenuStore()
  const [open, setOpen] = useState(false)
  const [subMenuPosition, setSubMenuPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0
  })
  const { t } = useTranslation()
  const { mode } = useScreenBreakPoint()
  const { showSnackBar } = useCustomSnackBar()
  const [loading, setLoading] = useState(false)
  const { seExploreMenu } = useExploreMenu()
  const handleSideMenuSelection = (res: MenuExplorerProps[]) => {
    const currentSection = res.find((item) =>
      item.PathName ? location.pathname.includes(item.PathName) : ''
    )
    const currentSubSection = res.find(
      (item) => item.FunctionId === currentSection?.ParentId
    )
    const topMostParent = currentSubSection?.ParentId ?? currentSection?.ParentId
    const shouldUpdate = currentSection && currentSection.ParentId !== sectionId
    if (shouldUpdate) {
      const sidemenu = res.map((item) => {
        return item.FunctionId === topMostParent
          ? { ...item, childView: !item.childView }
          : { ...item, childView: false }
      })
      setIsActiveMenu('explorer')
      setMenuData(sidemenu)
      setSubSectionId(currentSection.ParentId ?? '')
      setSectionId(topMostParent ?? '')
    }
  }
  const getMenuData = (id?: string) => {
    setLoading(true)
    getMenuExplorer(id ?? null).then((res) => {
      setMenuData(res)
      seExploreMenu(res)
      handleSideMenuSelection(res)
      setLoading(false)
    })
  }

  const getFavMenuData = () => {
    getFavouriteMenus().then((resp) => {
      setFavMenuData(() =>
        resp.map((item: FavouriteMenuProps) => ({ ...item, childView: true }))
      )
    })
  }

  useEffect(() => {
    getMenuData()
    getFavMenuData()
  }, [])
  const handleChildView = (id: string) => {
    const data = menuData.map((item) =>
      item.FunctionId === id
        ? { ...item, childView: !item.childView }
        : { ...item, childView: false }
    )
    setSectionId('')
    setMenuData(data)
    setOpen(false)
  }
  const handleFavChildView = (id: string) => {
    const data = favMenuData.map((item) =>
      item.FunctionId === id ? { ...item, childView: !item.childView } : { ...item }
    )

    setFavMenuData(data)
  }

  const handleButtonClick = (
    buttonRef: HTMLDivElement,
    menu: MenuExplorerProps,
    scrollRef?: HTMLDivElement
  ) => {
    setSectionId(menu.FunctionId)
    if (menu.FunctionType === 2) {
      setSubSectionId(menu.FunctionId)
      const positionsgenerator = () => {
        const buttonRect = buttonRef.getBoundingClientRect()
        const scrollRect = scrollRef?.getBoundingClientRect()
        return {
          top: buttonRect.top,
          left: scrollRect?.right ?? buttonRect.right + 8
        }
      }
      setSubMenuPosition(positionsgenerator)
      setSelectedButtonRef(buttonRef)
      setOpen(true)
    } else {
      setSubSectionId('')
      setOpen(false)
      setSelectedButtonRef(undefined)
    }
  }

  const setMenuFavorites = (menu: MenuExplorerProps) => {
    !menu.Favourite
      ? setFavouriteMenu(menu.FunctionId)
          .then((res) => {
            getFavMenuData()
            showSnackBar(t('messages.menuFavAdded'))
          })
          .catch((err) => {
            showSnackBar(t('messages.menuFavAddedFailed'), 'error')
            resetFavStatus(menu.FunctionId, menu.Favourite)
          })
      : deleteFavouriteMenu(menu.FunctionId)
          .then((res) => {
            getFavMenuData()
            showSnackBar(t('messages.menuFavRemoved'))
          })
          .catch((err) => {
            showSnackBar(t('messages.menuFavRemovedFailed'), 'error')
            resetFavStatus(menu.FunctionId, menu.Favourite)
          })
  }

  const handleSetFav = (menu: MenuExplorerProps) => {
    setMenuFavorites(menu)
    resetFavStatus(menu.FunctionId, !menu.Favourite)
  }

  const resetFavStatus = (id: string, fav: boolean) => {
    setMenuData(
      menuData.map((item) =>
        item.FunctionId === id ? { ...item, Favourite: fav } : { ...item }
      )
    )
  }

  const onSetFavorites = (menu: MenuExplorerProps) => {
    setFavMenuData(favMenuData.filter((item) => item.FunctionId !== menu.FunctionId))
    handleSetFav(menu)
  }

  return (
    <>
      {mode === 'desktop' ? (
        <SideNavDesktop
          menuData={menuData}
          subMenuPosition={subMenuPosition}
          favMenuData={favMenuData}
          open={open}
          sectionId={sectionId}
          handleFavChildView={handleFavChildView}
          handleChildView={handleChildView}
          handleButtonClick={handleButtonClick}
          selectedButtonRef={selectedButtonRef}
          handleSetFav={handleSetFav}
          onSetFavorites={onSetFavorites}
          loading={loading}
        />
      ) : (
        <SideNavSmall
          menuData={menuData}
          subMenuPosition={subMenuPosition}
          favMenuData={favMenuData}
          open={open}
          handleSetFav={handleSetFav}
          sectionId={sectionId}
          handleFavChildView={handleFavChildView}
          handleChildView={handleChildView}
          handleButtonClick={handleButtonClick}
          onSetFavorites={onSetFavorites}
        />
      )}
    </>
  )
}
export default SideNav

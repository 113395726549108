import CustomIcons from '@/components/common/icons/customIcons'
import { menuData } from '@/modules/technical/components/app/vesselParticulars/details/field.store'
import VesselTechnicalForm from '@/modules/technical/components/app/vesselParticulars/details/TechnicalForm'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import React, { useEffect, useRef, useState } from 'react'

import { MenuItemProps } from './schema'

const VesselParticularsTechnical: React.FC = () => {
  const sidemenuRef = useRef<HTMLElement>(null)
  const [activeMenu, setActiveMenu] = useState<string>('technical')
  const [isExpand, setIsExpand] = useState(true)
  const { isMobile, isTab } = useScreenBreakPoint()
  const handleClick = (menu: MenuItemProps) => {
    setActiveMenu(menu.slug)
  }
  const generateClassName = (menu: MenuItemProps): string => {
    let className = ''
    if (menu.slug === activeMenu) {
      className += ' font-bold text-secondary'
    }
    return className
  }

  useEffect(() => {
    if ((isTab || isMobile) && !isExpand) {
      setIsExpand(true)
    }
  }, [isTab, isMobile])

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (sidemenuRef.current && !sidemenuRef.current.contains(event.target)) {
        setIsExpand(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [sidemenuRef])
  return (
    <>
      <div className="flex w-full gap-4">
        <aside
          ref={sidemenuRef}
          className={`${isExpand ? 'w-[172px]  pe-3 ' : ''}border-table-border  border-r-[1px]`}
          aria-label="Sidebar"
        >
          <div className="pe-1">
            <ul className="font-medium">
              {menuData.map((menu: MenuItemProps) => (
                <li className="flex group/item pb-2" key={menu.slug}>
                  <button
                    type="button"
                    className={`flex py-1 gap-1 items-center w-full hover:text-secondary group ${generateClassName(
                      menu
                    )} `}
                    onClick={() => {
                      handleClick(menu)
                    }}
                  >
                    {menu.icon && (
                      <CustomIcons
                        name={menu.icon}
                        type={(isMobile || isTab) && !isExpand ? '' : 'medium'}
                        className={(isMobile || isTab) && !isExpand ? 'grow mr-3' : ''}
                      />
                    )}
                    {!isMobile && !isTab && (
                      <span className="ml-1 mr-3 text-xxs whitespace-nowrap text-left grow">
                        {menu.label}
                      </span>
                    )}
                    {(isMobile || isTab) && isExpand && (
                      <span className="ml-1 mr-3 text-xxs whitespace-nowrap text-left grow">
                        {menu.label}
                      </span>
                    )}
                    <CustomIcons
                      name={'forward-arrow'}
                      type={!isMobile && !isTab ? 'large' : ''}
                      className={`${
                        menu.slug !== activeMenu && 'invisible'
                      } ${!isMobile && !isTab ? 'group-hover/item:visible' : ''}
                       ml-auto -mb-2 align-middle justify-end grow-0`}
                    />
                  </button>
                </li>
              ))}
            </ul>
            {(isMobile || isTab) && (
              <div className={`flex ${isExpand && 'justify-end'} `}>
                <CustomIcons
                  type="large"
                  className=" text-text-primary cursor-pointer bg-text-primary/5 p-1.5 w-7 h-7 rounded-button"
                  onClick={() => setIsExpand(!isExpand)}
                  name={isExpand ? 'previous-most' : 'next-most'}
                />
              </div>
            )}
          </div>
        </aside>
        <div className="flex-1">
          <VesselTechnicalForm
            menuId={activeMenu}
            setActiveMenu={setActiveMenu}
          />
        </div>
      </div>
    </>
  )
}

export default VesselParticularsTechnical

import DialogModal from '@/components/common/modal/DialogModal'
import { guid } from '@/constants'
import {
  createSystemUser,
  getUserDetailsById,
  updateSystemUser,
  updateUserProfilePhoto
} from '@/pages/system/users/api'
import { UserFormDataType, UserFormSchema } from '@/pages/system/users/schema'
import useCustomSnackBar from '@/utilities/customSnackBar'
import formatDate from '@/utilities/usedateformat'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CreateUsersForm } from './UserForm'
import { UserFormLeftSection } from './UserFormLeftSection'

type Props = {
  open: boolean
  view: boolean
  userID?: string
  refetch: any
  handleClose: () => void
  roleDetails?: {
    roleId: string
    roleName: string
    departmentId: any
    departmentName: string
  }
}
const ManageUser: React.FC<Props> = ({
  open,
  handleClose,
  refetch,
  view,
  userID,
  roleDetails
}) => {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState<boolean>(open)
  const [loading, setLoading] = useState<boolean>(open)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [data, setData] = useState<any>()
  const { showSnackBar } = useCustomSnackBar()
  useEffect(() => {
    setModalOpen(open)
  }, [open])
  useEffect(() => {
    if (open && userID) {
      setLoading(true)

      getUserDetailsById(userID)
        .then((res) => {
          methods.reset({
            ...res,
            expiryDate: res?.expiryDate ? new Date(res?.expiryDate) : null
          })
          setData({
            ...data,
            ...res,
            expiryDate: res?.expiryDate ? new Date(res?.expiryDate) : null
          })
        })
        .catch((err: any) => {
          showSnackBar(err?.response?.data?.error, 'error')
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      methods.reset({
        userId: guid,
        inactive: false,
        resetPswd: false,
        validated: false,
        sysRoleId: roleDetails?.roleId ?? '',
        roleName: roleDetails?.roleName ?? '',
        departmentName: roleDetails?.departmentName ?? '',
        departmentId: roleDetails?.departmentId ?? ''
      })
    }
  }, [userID, open])

  const methods: any = useForm<UserFormDataType>({
    resolver: zodResolver(UserFormSchema)
  })
  const { file, userId } = methods.watch()

  const onClose = (flag?: boolean) => {
    methods.reset({})
    setData(undefined)
    setIsSaving(false)
    if (flag) {
      refetch()
    }
    handleClose()
  }
  const setActionLabel = () => {
    return userID ? 'Update' : 'Save'
  }
  const getHeading = () => {
    return view ? 'User Details' : userID ? 'Edit User' : 'Add New User'
  }
  const submitApi = (finaldata: any) =>
    methods.watch().userId === guid
      ? createSystemUser(finaldata)
      : updateSystemUser(finaldata)
  const onSubmit = (finaldata: UserFormDataType) => {
    setIsSaving(true)

    const payload = new FormData()
    Object.keys(finaldata).forEach((key) => {
      if (finaldata[key as keyof UserFormDataType]) {
        if (key !== 'file') {
          if (key === 'expiryDate') {
            if (finaldata?.expiryDate) {
              payload.append(
                'expiryDate',
                formatDate(finaldata?.expiryDate, 'YYYY-MM-DDTHH:mm:ss')
              )
            }
          } else {
            payload.append(key, finaldata[key as keyof UserFormDataType])
          }
        }
      }
    })
    // }

    submitApi(payload)
      .then((res: any) => {
        if (res.userId && userId === guid) {
          handleProfilePhoto(res.userId)
        }
        showSnackBar(res)
        onClose(true)
      })
      .catch((err: any) => {
        showSnackBar(err, 'error')
      })
      .finally(() => {
        setIsSaving(false)
      })
  }
  const handleProfilePhoto = (id?: string) => {
    const payload = new FormData()
    payload.append('File', file)
    payload.append('UserId', id ?? (userId as string))
    updateUserProfilePhoto(payload)
  }

  useEffect(() => {
    if (file && userId && userId !== guid) handleProfilePhoto()
  }, [file])

  return (
    <FormProvider {...methods}>
      <DialogModal
        isOpen={modalOpen}
        type="normal"
        title={getHeading()}
        leftSection={
          <UserFormLeftSection
            edit={!view && userId !== guid}
            title={getHeading()}
            methods={methods}
            onClose={() => onClose()}
            data={data}
            view={view}
            isOpen={open}
            loading={loading}
          />
        }
        headClassName="pb-5"
        body={
          <CreateUsersForm
            edit={!view}
            methods={methods}
            data={data}
            view={view}
            isOpen={open}
            loading={loading}
            roleDetails={roleDetails}
          />
        }
        onClose={() => onClose()}
        actionLabel={view ? undefined : setActionLabel()}
        onSubmit={() => (view ? undefined : methods.handleSubmit(onSubmit)())}
        secondaryAction={() => onClose()}
        secondaryActionLabel={view ? t('Close') : t('cancel')}
        small={false}
        actionLoader={isSaving}
      />
    </FormProvider>
  )
}

export default ManageUser

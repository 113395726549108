import { CommonFilterState, CommonFilterType } from '@/common/commontypes'
import { defaultPageSize } from '@/constants'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const defaultValues = {
  pageNo: 1,
  pageSize: defaultPageSize,
  sortBy: 'assignmentTypeName',

  archived: false
}
const useCrewActivityTypeStore = create<CommonFilterState>()(
  persist(
    (set, get) => ({
      filters: { ...defaultValues } as CommonFilterType,
      setFilters: (newFilters: CommonFilterType) => set({ filters: newFilters }),
      clearFilters: () => set({ filters: defaultValues }),
      isSearchCount: 1,
      setIsSearched: () => set({ isSearchCount: get()?.isSearchCount + 1 })
    }),
    {
      name: '_@crew___assi__gnmt__ty__#p_s__#ch$',
      getStorage: () => sessionStorage
    }
  )
)

export default useCrewActivityTypeStore

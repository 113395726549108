import { CustomKendoTable, DeletePopUp } from '@/components/common'
import { TableHeight, defaultPageSize, isAllow } from '@/constants'
import ManageEuipConfig from '@/modules/technical/components/app/vesselParticulars/equipmentConfig/ManageEquipmentConfig'
import ManageEuipVessel from '@/modules/technical/components/app/vesselParticulars/equipmentConfig/ManageEuipVessel'
import useHasPermission from '@/utilities/checkActionPermission'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import {
  deleteEquipmentConfig,
  getEquipmentConfigDetails,
  useEquipmentConfigDetail
} from '../api'
import { ParticularDetailsContextProps } from '../schema'
import { useAppContext } from '@/store/context/appContext'

const sortFields = ['MaterialTypeName', 'EqpCount', 'Oils', 'ConsTypes', 'Archived']
type PageParams = {
  pageNo: number
  VesselId?: string
  pageSize: number
  VesFiltType: number
  sortBy: string
}

const TechnicalParticularEquipmentConfig: React.FC = () => {
  const { vesselID } = useOutletContext<ParticularDetailsContextProps>()
  const hasPermission = useHasPermission('VesselEquipmentConfig')
  const [total, setTotal] = useState(0)
  const { t } = useTranslation()
  const { showSnackBar } = useCustomSnackBar()
  const [openVesselPopup, setOpenVesselPopup] = useState(false)
  const [openManage, setOpenManage] = useState(false)
  const [openDelete, setOpenDeleete] = useState(false)
  const [rowData, setRowData] = useState<any>(null)
  const [configId, setConfigId] = useState<any>(null)
  const [configType, setConfigType] = useState<any>()
  const [detailData, setDetailData] = useState<any>(null)
  const [subTitle, setSubTitle] = useState<string>('')
  const { setIsActionLoader, isActionLoader } = useAppContext()
  
  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    VesselId: vesselID,
    VesFiltType: 1,
    pageSize: defaultPageSize,
    sortBy: sortFields[0]
  })

  const { data, refetch, isFetching } = useEquipmentConfigDetail(params)

  const columns = [
    {
      title: t('Equipment Type'),
      field: 'MaterialTypeName',
      sort: true,
      action: true,
      width: 250
    },
    {
      title: t('Count'),
      field: 'EqpCount',
      sort: true,
      align: 'right',
      width: 100
    },
    {
      title: t('Applicable Oils'),
      field: 'Oils',
      sort: true,
      width: 250
    },
    {
      title: t('Applicable Activities'),
      sort: true,
      field: 'ConsTypes'
    },
    {
      title: t('Archived'),
      sort: true,
      field: 'Archived',
      type: 'boolean',
      width: 100
    }
  ]

  useEffect(() => {
    setTotal(data?.TotalRecords)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])
  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }
  const handleRemoveVessel = (perm: boolean, key: string) => {
    if (perm) {
      setOpenVesselPopup(true)
      setConfigType(key)
    }
  }
  const handleEdit = (perm: boolean, id: string) => {
    if (perm) {
      getEquipmentConfigDetails({ Id: id }).then((res) => {
        setDetailData(res)
      })
      setOpenManage(true)
    }
  }
  const handleDeleteOpen = (perm: boolean, id?: string) => {
    if (perm) {
      setConfigId(id)
      setOpenDeleete(true)
    }
  }
  const handleDeleteClose = (id?: string) => {
    setConfigId(id)
    setOpenDeleete(false)
  }
  const handleMenuActions = async (slug: string, rowData: any) => {
    setRowData(rowData)
    setSubTitle(rowData?.MaterialTypeName)
    const permissionParams = {
      UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
      AccessKey: '',
      //  ID1: vesselID
      ID1: rowData.ConfigId
    }
    const permission = await hasPermission(permissionParams)
    switch (slug) {
      case 'remove':
        return handleRemoveVessel(permission, 'remove')
      case 'copy':
        return handleRemoveVessel(permission, 'add')
      case 'edit':
        return handleEdit(permission, rowData.ConfigId)
      case 'delete':
        return handleDeleteOpen(permission, rowData.ConfigId)
      default:
        return null
    }
  }
  const handleCreate = async () => {
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: ''
      //  ID2: vesselID
    })
    setRowData({})
    setOpenManage(permission)
  }
  const handleVesselPopupClose = (val?: boolean) => {
    if (val) {
      refetch()
    }
    setOpenVesselPopup(false)
  }
  const handleManagePopupClose = () => {
    setOpenVesselPopup(false)
    setOpenManage(false)
    setRowData(null)
    setDetailData(null)
  }
  const handleDelete = () => {
    setIsActionLoader(true)
    deleteEquipmentConfig({ Id: configId })
      .then((res) => {
        showSnackBar(res)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err.response.data.error, 'error')
      }).finally(() => {
        setIsActionLoader(false)
        setOpenDeleete(false)
      })
  }
  return (
    <div>
      <CustomKendoTable
        columns={columns}
        data={data?.Results}
        pagination={true}
        toolBar={true}
        sortable={true}
        resizable={true}
        isFetching={isFetching}
        defaultSort={sortFields[0]}
        title={t('Equipment Configuration')}
        tableHeight={`calc(100vh - ${TableHeight.WithHeader})`}
        isDownloadable={true}
        handleSort={handleSort}
        actionDropDown={[
          {
            title: t('Remove Vessel'),
            iconName: 'remove',
            slug: 'remove',
            id: '1'
          },
          {
            title: t('Copy to Vessel'),
            iconName: 'copy',
            slug: 'copy',
            id: '2'
          },
          { title: t('edit'), iconName: 'edit', slug: 'edit', id: '3' },
          {
            title: t('delete'),
            iconName: 'delete',
            slug: 'delete',
            id: '4',
            isWarning: true
          }
        ]}
        handleMenuActions={handleMenuActions}
        primaryAction={{
          title: t('New'),
          handlePrimaryAction: handleCreate
        }}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },
          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
      />
      <ManageEuipVessel
        type={configType}
        openVesselPopup={openVesselPopup}
        handleClose={handleVesselPopupClose}
        rowData={rowData}
        vesselId={vesselID}
      />
      <ManageEuipConfig
        open={openManage}
        onClose={handleManagePopupClose}
        refetch={refetch}
        rowData={detailData}
        vesselId={vesselID}
      />
      <DeletePopUp
        isOpen={openDelete}
        onSubmit={handleDelete}
        onClose={() => handleDeleteClose()}
        itemLabels={{ title: 'equipment configuration', subTitle: subTitle }}
        actionLoader={isActionLoader}
      />
    </div>
  )
}

export default TechnicalParticularEquipmentConfig

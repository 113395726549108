export const defaultPageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE
  ? Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE)
  : 20

export const isAllow = { CREATE: 1, READ: 2, UPDATE: 3, DELETE: 4 }

export const guid = '00000000-0000-0000-0000-000000000000'

export const EntityFilterType = {
  None: 0,
  Vessel: 1,
  Type: 2,
  Class: 3,
  Fleet: 4,
  Group: 5,
  Owner: 6
}
export const VesselFilterType = {
  None: 0,
  Vessel: 1,
  Type: 2,
  Class: 3,
  Fleet: 4,
  Group: 5,
  Owner: 6
}
export const EntityType = {
  Root: 0,
  Group: 1,
  BusinessUnit: 2,
  Fleet: 3,
  Vessel: 4,
  Divison: 2,
  CostCenter: 3
}

export const TableHeight = {
  InlineSearch: '230px',
  OneColSearch: '310px',
  OneColSearchNoAdd: '290px',
  TwoColSearch: '370px',
  WithHeader: '470px',
  WithOneColHeader: '410px',
  WithHeaderTab: '570px',
  CardListSingle: '280px',
  CardListDouble: '334px',
  ExpandableBoxHeight: '700px'
}

import {
  createVesselGroup,
  deleteUserVessel,
  deleteUserVesselGroup,
  getUseruservessels,
  getUserVesselGroup,
  getUserVesselGroupDetails
} from '@/apis/common.api'
import { DeletePopUp, Loader, SideDrawer, TextField } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import Tree from '@/components/common/tree/Tree'
import { useAppStore } from '@/store/appStore'
import { useAuthStore } from '@/store/authStore'
import { t } from 'i18next'
import decode from 'jwt-decode'
import { useEffect, useState } from 'react'
import useCustomSnackBar from '@/utilities/customSnackBar'

let groupUpdate = 1
export default function MyVessel() {
  const [currentTab, setCurrentTab] = useState('my_vessel')
  const { isMyVesselOpen, setIsMyVesselOpen } = useAppStore()
  const [groupName, setGroupName] = useState<string>()
  const [error, setError] = useState({})
  const [delObject, setDelOnject] = useState<any>({ parentId: '' })
  const [groupData, setGroupData] = useState([])
  const [vesselData, setVesselData] = useState<any>([])
  const [openCreate, setOPenCreate] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { showSnackBar } = useCustomSnackBar()
  const { accessToken } = useAuthStore()
  let payload = { UserId: '' }

  let userid = ''

  if (accessToken) {
    payload = decode(accessToken)
    userid = payload.UserId
  }
  const tabs = [
    {
      title: 'My Vessels',
      slug: 'my_vessel'
    },
    {
      title: 'My Groups',
      slug: 'my_group'
    }
  ]

  const handleGetUserVessels = async () => {
    setLoading(true)

    const data = await getUseruservessels(userid)
    if (data) {
      setVesselData(consolidateTreeData(createTreeData(data)))
      setLoading(false)
    }
  }
  const handleGetUserVesselGroup = async () => {
    setLoading(true)
    const data = await getUserVesselGroup()
    if (data) {
      setGroupData(
        data?.map((item: any) => ({
          ...item,
          label: item.GroupName,
          id: item.GroupId,
          children: [{}]
        }))
      )
      setLoading(false)
    }
  }
  useEffect(() => {
    handleGetUserVessels()
  }, [])

  useEffect(() => {
    if (currentTab === 'my_group') {
      handleGetUserVesselGroup()
    } else {
      handleGetUserVessels()
    }
  }, [currentTab])
  const createTreeData = (vessels: any) => {
    const tree: any = []

    vessels.forEach((vessel: any) => {
      let group = tree.find((g: any) => g.label === vessel.VesTypeName)

      if (!group) {
        group = { label: vessel.VesTypeName, children: [] }
        tree.push(group)
      }
      group.children.push({
        label: vessel.VesselName,
        id: vessel.VesselId,
        checked: false
      })
    })

    return tree
  }
  const consolidateTreeData = (array: any) => {
    const titleMap = new Map()

    array.forEach((item: any) => {
      if (!titleMap.has(item.label)) {
        titleMap.set(item.label, [])
      }
      titleMap.get(item.label).push(...item.children)
    })
    const consolidatedArray = Array.from(titleMap, ([label, children]) => ({
      label,
      children
    }))

    return consolidatedArray
  }

  const handleNodeSelection = (id: string, treeData: any) => {
    const newTreeData = JSON.parse(JSON.stringify(treeData))

    const toggleCheck = (nodes: any[]) => {
      nodes.forEach((node) => {
        if (node.id === id) {
          node.checked = !node.checked
        }
        if (node.children) {
          toggleCheck(node.children)
        }
      })
    }

    toggleCheck(newTreeData)

    return newTreeData
  }
  const handleNodeCheck = (node: any) => {
    setVesselData(handleNodeSelection(node?.id, vesselData))
  }
  const handleGetIds = (treeData: any) => {
    const getCheckedIds = (nodes: any[], checkedIds: string[]) => {
      nodes.forEach((node) => {
        if (node.checked) {
          checkedIds.push(node.id)
        }
        if (node.children) {
          getCheckedIds(node.children, checkedIds)
        }
      })
    }

    const checkedIds: string[] = []

    getCheckedIds(treeData, checkedIds)

    return checkedIds
  }
  const handleCreateGroup = () => {
    setOPenCreate(true)
  }
  const handleChange = (e: any) => {
    const { value } = e.target
    const trimValue = value.trimStart()
    setGroupName(trimValue)
    handleValidate(trimValue)
  }
  const handleValidate = (name?: string) => {
    if (!name) {
      setError({ GroupName: { message: 'Required' } })
      return { GroupName: { message: 'Required' } }
    } else {
      setError({})
    }
    return error
  }

  const handleSubmit = () => {
    const error = handleValidate(groupName)
    if (Object.keys(error)?.length === 0) {
      createVesselGroup({
        GroupName: groupName,
        VesselIDs: handleGetIds(vesselData)
      })
        .then((res) => {
          showSnackBar(res)
          setOPenCreate(false)
          handleGetUserVessels()
          setGroupName('')
          setCurrentTab('my_group')
        })
        .catch((err) => {
          showSnackBar(err?.response?.data?.error, 'error')
        })
    }
  }

  const handleTreeExpand = (nodeData: any) => {
    setLoading(true)
    getUserVesselGroupDetails(nodeData?.id)
      .then((res) => {
        if (res) {
          setGroupData((currentData: any) => {
            const newData = currentData.map((item: any) => {
              if (item.id === nodeData.id) {
                return {
                  ...item,
                  children: res?.map((re: any) => ({
                    ...re,
                    id: re.UserVesselId,
                    parentId: nodeData?.id,
                    label: re.VesselName
                  }))
                }
              }
              return item
            })
            return newData
          })
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching group details: ', error)
      })
  }
  const handleTreeAction = (nodeItem: any) => {
    setDelOnject(nodeItem)
    setDeleteOpen(true)
  }
  const handleDelete = () => {
    const delAPi = delObject?.parentId ? deleteUserVessel : deleteUserVesselGroup
    delAPi(delObject.id)
      .then((res) => {
        setDeleteOpen(false)
        handleGetUserVesselGroup()
        showSnackBar(res)
        groupUpdate = groupUpdate + 1
      })
      .catch((error) => {
        showSnackBar(error?.response?.data?.error, 'error')
      })
  }
  return (
    <>
      {vesselData?.length > 0 && (
        <SideDrawer
          title={tabs}
          onClose={() => setIsMyVesselOpen(false)}
          isOpen={isMyVesselOpen}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          primaryAction={
            handleGetIds(vesselData)?.length > 0
              ? { label: 'Create Group', action: handleCreateGroup }
              : undefined
          }
        >
          <Loader isActive={loading} />
          {currentTab === 'my_vessel' && (
            <Tree
              key="my_my_vesselgroup"
              data={vesselData}
              treeSelect={true}
              handleNodeCheck={handleNodeCheck}
              nodeIcon="Ship_Icon"
              nodeSelectedIcon="Ship_Icon"
            />
          )}
          {currentTab === 'my_group' && (
            <Tree
              key={`my_group${groupUpdate}`}
              data={groupData}
              handleTreeExpand={handleTreeExpand}
              handleNodeCheck={handleNodeCheck}
              nodeIcon="Ship_Icon"
              treeAction={'delete'}
              handleTreeAction={handleTreeAction}
            />
          )}
        </SideDrawer>
      )}
      <DialogModal
        isOpen={openCreate}
        type="sm"
        title={'User Vessel Group'}
        body={
          <div>
            <TextField
              name="GroupName"
              id="GroupName"
              onChange={handleChange}
              errors={error}
              value={groupName}
              label="Group Name"
            />
          </div>
        }
        onClose={() => setOPenCreate(false)}
        actionLabel={t('Save')}
        onSubmit={() => handleSubmit()}
        secondaryAction={() => setOPenCreate(false)}
        secondaryActionLabel={'Cancel'}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
      />
    </>
  )
}

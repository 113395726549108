const Page = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.25 6.22222C14.25 4.88889 13.2426 4 12 4C10.7574 4 9.75 4.88889 9.75 6.22222M6.375 11.9766H17.625M6.375 9.45029H17.625M6.375 14.5029H17.625M6.33984 16.5556H13.0898M4.125 20H19.875C20.4963 20 21 19.602 21 19.1111V7.4152C21 6.92428 20.4963 6.52632 19.875 6.52632H12H4.125C3.50368 6.52632 3 6.92428 3 7.4152V19.1111C3 19.602 3.50368 20 4.125 20Z"
          stroke="currentColor"
        />
      </svg>
    </>
  )
}

export default Page

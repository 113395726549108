import { z } from 'zod'
// login //
export const LoginSchema = z.object({
  username: z.string().min(1, { message: 'usernameEmptyError' }),
  password: z.string().min(1, { message: 'passwordEmptyError' })
})
export type LoginForm = z.infer<typeof LoginSchema>
export type LoginResponseProps = {
  Idle_Timeout: number
  token: string
  classic_view: boolean
  expires_in: number
  issued_at: string
  refresh_token: string
  theme_file: string
  theme_id: number
  idle_Timeout: number
}
// login //

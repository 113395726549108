import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'

export const KinAddressDetailsSchema = z.object({
  permanentSameAsPresent: z.boolean().optional(),
  permanentAddressLine1: z
    .string({
      required_error: 'Address Line 1 is required',
      invalid_type_error: 'Address Line 1 is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  permanentAddressLine2: z
    .string({
      required_error: 'Address Line 2 is required',
      invalid_type_error: 'Address Line 2 is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  permanentCity: z
    .string({
      required_error: 'City is required',
      invalid_type_error: 'City is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  permanentState: z
    .string({
      required_error: 'State is required',
      invalid_type_error: 'State is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  permanentCountryName: z.string({
    required_error: 'Country is required',
    invalid_type_error: 'Country is required'
  }),
  permanentCountryId: z.number({
    required_error: 'Country is required',
    invalid_type_error: 'Country is required'
  }),
  permanentPostCode: z
    .string({
      required_error: 'Postcode is required',
      invalid_type_error: 'Postcode is required'
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  presentAddressLine1: z.string().optional().nullable(),
  presentAddressLine2: z.string().optional().nullable(),
  presentCity: z.string().optional().nullable(),
  presentState: z.string().optional().nullable(),
  presentCountryName: z.string().optional().nullable(),
  presentCountryId: z.number().optional().nullable(),
  presentPostCode: z.string().optional().nullable(),
  crewNokId: z.string(),
  crewId: z.string(),
  relativeCrewId: z.string().optional().nullable()
})

export type KinAddressDetailsSchemaType = z.infer<typeof KinAddressDetailsSchema>

import { TableProps } from '@/common/customComponenttypes'

import { Debounce } from '../../../utilities'
import Button from '../buttons/Button'
import CustomIcons from '../icons/customIcons'
import SearchInput from '../inputs/SearchInput'
import MenuDropDown from '../menudropdown/MenuDropDown'
import defaultExportCsv from './utils/exportCsv'
import defaultExportPdf from './utils/exportPdf'
// import CustomIcons from '../icons/customIcons'

export default function Toolbar({
  columns,
  data,
  onSearch,

  search,
  searchValue,
  handleSearchValue,
  asyncSearch,
  title = '',
  exportFileName,
  primaryAction,
  closeAction,
  isDownloadable
}: TableProps) {
  const handleSearch = (e?: string) => {
    Debounce(() => {
      onSearch && onSearch(e)
    }, 1000)
  }
  const handleChange = (e?: string) => {
    if (asyncSearch) {
      handleSearch(e)
    } else {
      handleSearchValue && handleSearchValue(e)
    }
  }
  const handleMenu = (slug: string) => {
    if (slug === 'pdf') {
      defaultExportPdf({
        columns: columns,
        data: data,
        title: typeof title === 'string' ? title : 'data',
        exportFileName: exportFileName
      })
    } else {
      defaultExportCsv({
        columns: columns,
        data: data,
        title: typeof title === 'string' ? title : 'data',
        exportFileName: exportFileName
      })
    }
  }
  const actionDropDown = [
    {
      title: 'Export PDF',
      iconName: 'pdf',
      id: '1',
      slug: 'pdf'
    },
    {
      title: 'Export CSV',
      iconName: 'excel',
      id: '1',
      slug: 'csv'
    }
  ]

  return (
    <div className="w-full h-15 bg-white flex justify-between items-center  py-0 pb-3">
      <div>
        <p className="text-dark text-m font-medium">{title}</p>
      </div>

      <div className=" flex gap-2">
        <div className="w-48 ">
          {search && (
            <SearchInput
              placeholder="Search"
              handleChange={(e) => handleChange(e)}
              handleSearch={(e) => handleSearch(e)}
              searchValue={searchValue}
            />
          )}
        </div>
        {primaryAction && (
          <div>
            <Button
              primary={false}
              size="sm"
              label={primaryAction?.title ?? 'New'}
              icon={primaryAction?.icon ?? 'plus'}
              onClick={() => primaryAction?.handlePrimaryAction?.()}
              className=" pr-5 "
            />
          </div>
        )}
        {isDownloadable && (
          <MenuDropDown
            isDownload
            buttonSize="sm"
            iconSize="large"
            iconName="three_dot"
            actionDropDown={actionDropDown}
            handleMenuActions={handleMenu}
          />
        )}
        {closeAction && (
          <div>
            <button
              type="button"
              className="w-10 h-10 items-center"
              onClick={() => closeAction?.handleCloseAction?.()}
            >
              <CustomIcons
                name={primaryAction?.icon ?? 'close'}
                className="flex items-center justify-center pr-1 mt-1"
              />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

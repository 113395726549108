import { z } from 'zod'
export type VesselInspectionContextProps = {
  edit: boolean
  setEdit: (status: boolean) => void
  inspID: string
}
const MAX_FILE_SIZE = 5000000

export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv' // .csv
]

// export const documentSchemaType = z.object({
//   attachment: z
//     .instanceof(FileList)
//     .refine((file) => file.size <= MAX_FILE_SIZE, {
//       message: 'Maximum size 5mb'
//     })
//     .refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), {
//       message: 'File type not allowed'
//     })
//     .nullable()
// })
const fileSchema = z
  .instanceof(File)
  .refine((file) => file.size <= MAX_FILE_SIZE, {
    message: 'Maximum size 5mb'
  })
  .refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), {
    message: 'File type not allowed'
  })

// Define a schema for validating FileList

export const documentSchemaType = z.object({
  attachment: z
    .array(fileSchema)
    .min(1, { message: 'At least one file is required' })
    .refine((files) => files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file.type)), {
      message: 'Invalid file type'
    })
    .refine((files) => files.every((file) => file.size <= MAX_FILE_SIZE), {
      message: 'Maximum size 5mb'
    })
})

export type DocumentSchemaType = z.infer<typeof documentSchemaType>

import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const personalAddress = [
  'AddressLine1',
  'AddressLine2',
  'City',
  'State',
  'CountryName',
  'CountryId',
  'PostCode'
]
type CrewProfileStore = {
  expand: boolean
  setExpand: (val: boolean) => void
  isEditMode: () => boolean
  familyEdit: boolean
  setFamilyEdit: (val: boolean) => void
  personalEdit: boolean
  setPersonalEdit: (val: boolean) => void
  resetEdit: () => void
}
export const useCrewProfileStore = create<CrewProfileStore>()(
  persist(
    (set, get) => ({
      expand: true,

      familyEdit: false,
      personalEdit: false,
      setExpand: (val) => {
        set({ expand: val })
      },

      setFamilyEdit: (val) => {
        set({ familyEdit: val })
      },
      setPersonalEdit: (val) => {
        set({ personalEdit: val })
      },
      isEditMode: () => {
        const { familyEdit, personalEdit } = get() // Use set.getState() to access current state
        return familyEdit || personalEdit
      },
      resetEdit: () => {
        set({ familyEdit: false, personalEdit: false })
      }
    }),
    {
      name: '_@crew___pro_fil_dt__l__#_s__#ch$',
      getStorage: () => sessionStorage
    }
  )
)

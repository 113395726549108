import { LoaderProps } from '@/common/customComponenttypes'
import React, { useEffect, useState } from 'react'

const Icon1 = () => (
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 28C3.5 31.283 4.14664 34.5339 5.40301 37.5671C6.65938 40.6002 8.50087 43.3562 10.8223 45.6777C13.1438 47.9991 15.8998 49.8406 18.9329 51.097C21.9661 52.3534 25.217 53 28.5 53C31.7831 53 35.034 52.3534 38.0671 51.097C41.1002 49.8406 43.8562 47.9991 46.1777 45.6777C48.4991 43.3562 50.3406 40.6002 51.597 37.5671C52.8534 34.5339 53.5 31.283 53.5 28C53.5 24.7169 52.8534 21.466 51.597 18.4329C50.3406 15.3998 48.4991 12.6438 46.1777 10.3223C43.8562 8.00086 41.1002 6.15937 38.0671 4.90301C35.0339 3.64664 31.783 3 28.5 3C25.2169 3 21.966 3.64665 18.9329 4.90302C15.8998 6.15939 13.1438 8.00087 10.8223 10.3223C8.50086 12.6438 6.65937 15.3998 5.40301 18.4329C4.14664 21.4661 3.5 24.717 3.5 28L3.5 28Z"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.1777 10.3223C43.3879 7.53261 39.9776 5.44274 36.2254 4.22359C32.4733 3.00443 28.4858 2.69062 24.5891 3.30779C20.6924 3.92497 16.9971 5.45561 13.8054 7.77458C10.6136 10.0935 8.01594 13.135 6.22483 16.6502C4.43372 20.1655 3.5 24.0547 3.5 28C3.5 31.9453 4.43373 35.8345 6.22484 39.3498C8.01595 42.865 10.6136 45.9065 13.8054 48.2254C16.9972 50.5444 20.6925 52.075 24.5891 52.6922"
      stroke="url(#paint0_linear_1261_19233)"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1261_19233"
        x1="28.5"
        y1="3"
        x2="28.5"
        y2="53"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#003C71" />
        <stop offset="1" stopColor="#003C71" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

const Icon2 = () => (
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    className=" rotate-180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.5 28C53.5 24.717 52.8534 21.4661 51.597 18.4329C50.3406 15.3998 48.4991 12.6438 46.1777 10.3223C43.8562 8.00087 41.1002 6.15938 38.0671 4.90301C35.0339 3.64665 31.783 3 28.5 3C25.217 3 21.9661 3.64665 18.9329 4.90301C15.8998 6.15938 13.1438 8.00087 10.8223 10.3223C8.50087 12.6438 6.65938 15.3998 5.40301 18.4329C4.14664 21.4661 3.5 24.717 3.5 28C3.5 31.2831 4.14665 34.534 5.40302 37.5671C6.65939 40.6002 8.50087 43.3562 10.8223 45.6777C13.1438 47.9991 15.8998 49.8406 18.9329 51.097C21.9661 52.3534 25.217 53 28.5 53C31.7831 53 35.034 52.3534 38.0671 51.097C41.1002 49.8406 43.8562 47.9991 46.1777 45.6777C48.4991 43.3562 50.3406 40.6002 51.597 37.5671C52.8534 34.5339 53.5 31.283 53.5 28L53.5 28Z"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8223 45.6777C13.6121 48.4674 17.0224 50.5573 20.7746 51.7764C24.5267 52.9956 28.5142 53.3094 32.4109 52.6922C36.3076 52.075 40.0029 50.5444 43.1946 48.2254C46.3864 45.9065 48.9841 42.865 50.7752 39.3498C52.5663 35.8345 53.5 31.9453 53.5 28C53.5 24.0547 52.5663 20.1655 50.7752 16.6502C48.9841 13.135 46.3864 10.0935 43.1946 7.77457C40.0028 5.45561 36.3075 3.92497 32.4109 3.30779"
      stroke="url(#paint0_linear_1261_19239)"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1261_19239"
        x1="28.5"
        y1="53"
        x2="28.5"
        y2="3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF681D" />
        <stop offset="1" stopColor="#FF681D" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)
const Loader: React.FC<LoaderProps> = ({ isActive = false }) => {
  const [activeIcon, setActiveIcon] = useState(1)
  const rotationDuration = 700 // Rotation duration in milliseconds

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIcon((prevActiveIcon) => (prevActiveIcon === 1 ? 2 : 1))
    }, rotationDuration)

    return () => clearInterval(interval)
  }, [])
  return (
    <>
      {/* <style>{animationStyles}</style> */}

      {isActive && (
        // <div className="absolute left-0 top-0 h-full w-full flex items-center justify-center">
        //   <div className="loader"></div>
        // </div>
        <div className="absolute left-0 top-0 h-full w-full flex items-center justify-center z-50">
          <div
            className="icon"
            style={{
              position: 'absolute',
              animation: `rotate-cw ${rotationDuration}ms linear infinite`
            }}
          >
            {activeIcon === 1 ? <Icon1 /> : <Icon2 />}
          </div>
        </div>
      )}
    </>
  )
}

export default Loader

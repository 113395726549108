import { getTechnicalMipsData } from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useQuery } from '@tanstack/react-query'

export const useVesselInspectionDetails = (id: string) => {
  const fetchData = async () => {
    const { data } = await getTechnicalMipsData(
      `${apiTechnicalUrl.VESSEL_INSPECTION_FINDINGS_MIPS}/GetFindingDetails?findingId=${id}`
    )
    return data
  }

  return useQuery({
    queryKey: ['technical-inspection-details', id],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

import { CustomTable } from '@/components/common'
import { DatePicker } from '@progress/kendo-react-dateinputs'
import dayjs from 'dayjs'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  methods: any
  option: string
  handleDatePickerChange: (key: string, row: any, value: any) => void
  handleMultiSelect: (data: (string | number)[]) => void
  handleSingleSelect: (data: string | number) => void
  handleBudgetYearChange: (e: any) => void
  selectedBudgetYear?: string
  checkedItems: any
  selectedItem: any
  isFetching: boolean
  yearDropDown?: string[]
  data: any[]
}

const VesselsAndYear: React.FC<Props> = ({
  methods,
  option,
  data,
  yearDropDown,
  isFetching,
  selectedItem,
  checkedItems,
  selectedBudgetYear,
  handleBudgetYearChange,
  handleDatePickerChange,
  handleSingleSelect,
  handleMultiSelect
}) => {
  const { watch } = methods
  const { BudgetYear } = watch()
  const year = Number(BudgetYear ? dayjs(BudgetYear).format('YYYY') : 0)
  const start = new Date(year, 0, 1, 0, 0, 0, 0)
  const end = new Date(year, 11, 31, 0, 0, 0, 0)
  const { t } = useTranslation()
  const { control } = methods

  const isDateValid = (data: any, key: string) => {
    let isValid = true

    if (data.Checked) {
      if (!data[key]) {
        isValid = false
      }
    }

    return isValid
  }
  const columns: any[] = [
    ...(option === '1'
      ? [
          {
            title: t('Budget'),
            field: 'BudgetTitle',

            type: 'text',
            sort: false
          }
        ]
      : []),
    {
      title: t('Vessels'),
      field: 'EntityName',
      width: 300,
      type: 'text',
      sort: false
    },
    ...(option === '1'
      ? [
          {
            title: t('Budget Amount'),
            field: 'Budget',

            align: 'center',
            type: 'amount',
            sort: false
          },
          {
            title: t('Currency'),
            field: 'CurrencyName',

            type: 'text',
            sort: false
          }
        ]
      : [
          {
            title: t('Start Date'),
            field: 'StartDate',
            //  type: 'boolean',
            sort: false,
            render: (data: any) => (
              <>
                {' '}
                <Controller
                  name={`${'StartDate'}`}
                  control={control}
                  render={() => {
                    return (
                      <DatePicker
                        placeholder="DD-MMM-YYYY"
                        format={'dd-MMM-yyyy'}
                        formatPlaceholder={{ year: 'YYYY', month: 'MMM', day: 'DD' }}
                        valid={isDateValid(data, 'StartDate')}
                        min={start} // Change the min and max
                        max={data?.EndDate || end}
                        onChange={(date) => {
                          if (date.value && date.value > start && date.value <= end)
                            handleDatePickerChange('StartDate', data, date)
                        }}
                        value={data?.StartDate}
                      />
                    )
                  }}
                />
              </>
            )
            //  align: 'center'
          },
          {
            title: t('End Date'),
            field: 'EndDate',
            //  type: 'boolean',
            sort: false,
            render: (data: any) => (
              <>
                {' '}
                <Controller
                  name={`${'EndDate'}`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        placeholder="DD-MMM-YYYY"
                        format={'dd-MMM-yyyy'}
                        formatPlaceholder={{ year: 'YYYY', month: 'MMM', day: 'DD' }}
                        valid={isDateValid(data, 'EndDate')}
                        min={data?.StartDate || start} // Change the min and max values according to your requirement
                        max={end}
                        onChange={(date) => {
                          if (date.value && date.value > start && date.value <= end)
                            handleDatePickerChange('EndDate', data, date)
                        }}
                        value={data?.EndDate}
                      />
                    )
                  }}
                />
              </>
            )
          }
        ])
  ]

  return (
    <div className={` w-full `}>
      {' '}
      <CustomTable
        columns={columns}
        data={data}
        // checkedKeys={checkedItems}
        singleSelection={Boolean(option === '1')}
        handleSingleSelect={option === '1' ? handleSingleSelect : undefined}
        multiSelection={Boolean(option !== '1')}
        handleMultiSelect={option === '1' ? undefined : handleMultiSelect}
        selectedKey={option === '1' ? selectedItem?.BudgetId : undefined}
        async={false}
        isLoading={isFetching}
        title={
          <>
            {option === '1' ? (
              <div className="flex gap-2">
                <div className="w-auto text-text-primary flex items-center">
                  {t('Select a budget to copy, from year')}{' '}
                </div>
                <div className="">
                  <div>
                    <select
                      onChange={handleBudgetYearChange}
                      className="dropdown border outline-none border-table-pagination-rowsPerPage-select-border text-table-pagination-rowsPerPage-select-text  min-w-[108px] pl-3 rounded-1 table-title-select"
                      value={selectedBudgetYear}
                    >
                      <option value="" disabled>
                        - Select - 
                      </option>
                      {yearDropDown?.map((item: string) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              t('Select Vessel(s)')
            )}
          </>
        }
        toolBar={true}
        // sortable={true}
        tableKey={option === '1' ? 'BudgetId' : 'EntityId'}
        // adjustWidth={-15}
        checkedKeys={option === '1' ? undefined : checkedItems}
        rowKey={option === '1' ? 'BudgetId' : 'EntityId'}
        // isDownloadable={false}
        // tableHeight={`calc(100vh - 855px)`}
      />
    </div>
  )
}

export default VesselsAndYear

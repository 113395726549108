import DialogModal from '@/components/common/modal/DialogModal'
import { guid } from '@/constants'
import {
  getEntitiesForUser,
  updateSystemUserEntityAccess
} from '@/pages/system/users/api'
import { useAppContext } from '@/store/context/appContext'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UserEntityTreeNode from './UserEntityTreeNode'

export const ManageUserEntity: React.FC<any> = ({
  open,
  userID,
  rowData,
  handleClose
}) => {
  const { t } = useTranslation()
  const [entityData, setEntityData] = useState<any[]>([])
  const { showSnackBar } = useCustomSnackBar()
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  useEffect(() => {
    if (open) {
      try {
        setIsLoading(true)
        getEntitiesForUser({ userId: userID, showAll: true }).then((res: any) => {
          const entData = res || []
          if (entityData.length > 0) {
            setEntityData((prevData: any) => {
              const updatedData = [
                ...entData.map((newData: any) => {
                  const matchingData = prevData.find(
                    (oldData: any) => oldData.entityId === newData.entityId
                  )
                  return matchingData
                    ? { ...matchingData, ...newData }
                    : {
                        ...newData,
                        hasChild: entData.some(
                          (child: any) => child.entityParentId === newData.entityId
                        )
                      }
                })
              ]

              return updatedData
            })
          } else {
            checkHasChild(entData)
          }
        })
      } catch {
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }
  }, [open])

  const onSubmit = () => {
    let entityIDs: string[] = []
    let data = entityData

    data.forEach((item) => {
      if (item.hasAccess) {
        entityIDs.push(item.entityId)
      }
    })
    const payload = {
      users: [userID],
      entities: entityIDs,
      accessType: 1
    }
    setIsSubmitting(true)
    updateSystemUserEntityAccess(userID, payload)
      .then((res) => {
        showSnackBar(res)

        onClose()
      })
      .catch((err) => {
        showSnackBar(err?.response?.data?.error, 'error')
      })
      .finally(() => {
        setIsSubmitting(false)
        setIsLoading(false)
      })
  }
  const checkForIntermediates = (node: any, data: any) => {
    const childs = data.filter((itm: any) => itm.entityParentId === node.entityId)
    const checkedChilds = childs.filter((itm: any) => itm.hasAccess)
    return childs.length !== checkedChilds.length
  }
  const setIntermediate = (data: any) => {
    let final = data || []
    final.forEach((ele: any) => {
      ele['intermediate'] = checkForIntermediates(ele, final)
    })
    return final
  }
  const checkHasChild = (data: any) => {
    const newData = data.map((item: any) => ({
      ...item,
      isOpen: item.entityParentId === guid,
      hasChild: data.some((child: any) => child.entityParentId === item.entityId),
      intermediate: checkForIntermediates(item, data)
    }))
    setEntityData(newData)
  }
  const checkParent = (node: any, checked: boolean, data: any) => {
    let updatedData = data || []
    updatedData.forEach((item: any) => {
      if (checked) {
        if (item.entityId === node.entityParentId && item.entityParentId !== guid) {
          item['hasAccess'] = checked

          checkParent(item, checked, updatedData)
        }
      }
    })

    let finalData = setIntermediate(updatedData) || []
    return finalData
  }
  const getCheckedData = (node: any, checked: boolean, data: any) => {
    let updatedData = data

    updatedData.forEach((item: any) => {
      if (item.entityId === node.entityId) {
        item['hasAccess'] = checked
        if (checked) {
          item['intermediate'] = false
        }
      }
      if (item.entityParentId === node.entityId) {
        getCheckedData(item, checked, updatedData)
      }
    })

    const finalData = checkParent(node, checked, updatedData)
    return finalData
  }
  const onClose = () => {
    setEntityData([])
    handleClose()
  }
  const handleNodeCheck = (node: any, e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    const updatedData = getCheckedData(node, checked, entityData)
    setEntityData(updatedData.map((item: any) => item))
  }
  const handleTreeExpand = (nodeIndex: any) => {
    const data = entityData?.map((item: any, index: number) =>
      index === nodeIndex && item.hasChild ? { ...item, isOpen: !item.isOpen } : item
    )
    setEntityData(data)
  }

  const renderTree = (item: any, level: number = 0): React.ReactNode =>
    entityData?.map((node: any, index: number) => (
      <React.Fragment key={index}>
        {item.entityId === node?.entityParentId && (
          <UserEntityTreeNode
            key={index}
            label={node.entityName}
            node={node}
            level={level}
            data={entityData}
            nodeId={index}
            handleNodeCheck={handleNodeCheck}
            handleTreeExpand={handleTreeExpand}
          >
            {node.isOpen && renderTree(node, level + 1)}
          </UserEntityTreeNode>
        )}
      </React.Fragment>
    ))
  const { setIsCommonLoader } = useAppContext()
  useEffect(() => {
    setIsCommonLoader(isLoading ?? isSubmitting)
  }, [isLoading, isSubmitting])

  return (
    <DialogModal
      isOpen={open}
      type="lg"
      title={'Update User Entity Access'}
      body={
        <div>
          <div className="text-l flex align-middle text-secondary font-semibold px-3 py-2 bg-[#003c711a]">
            {`${rowData?.userName} (${rowData?.loginId})`}
          </div>
          <div className=" flex align-middle text-common font-semibold px-3 py-2">
            Select the entity
          </div>
          <div className="popup-max-height">
            {entityData?.map((node: any, index: number) => (
              <React.Fragment key={index}>
                {(!node?.entityParentId || node?.entityParentId === null) && (
                  <UserEntityTreeNode
                    key={index}
                    label={node.entityName}
                    node={node}
                    level={0}
                    nodeId={index}
                    handleNodeCheck={handleNodeCheck}
                    handleTreeExpand={handleTreeExpand}
                    data={entityData}
                  >
                    {node.isOpen && renderTree(node, 1)}
                  </UserEntityTreeNode>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      }
      onClose={() => onClose()}
      actionLabel={'Update'}
      onSubmit={() => onSubmit()}
      secondaryAction={() => onClose()}
      secondaryActionLabel={t('cancel')}
      small={false}
      actionLoader={isSubmitting}
    />
  )
}

import decode from 'jwt-decode'

interface JwtPayload {
  jti: string
  UserId: string
}

export const getRefreshParams = (
  token?: string,
  browserId?: string,
  refreshToken?: string
) => {
  const payload = token ? decode<JwtPayload>(token) : null

  if (payload?.jti && payload?.UserId && browserId) {
    return {
      // TokenId: payload.jti,
      // UserId: payload.UserId,
      // Token: refreshToken,
      // BrowserId: browserId
      tokenId: payload.jti,
      userId: payload.UserId,
      refreshToken,
      browserId: browserId
    }
  }

  return null
}

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const ResetSearchIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.9 15.725C4.56667 15.125 4.33333 14.5208 4.2 13.9125C4.06667 13.3042 4 12.6833 4 12.05C4 9.86668 4.7875 7.98751 6.3625 6.41251C7.9375 4.83751 9.81667 4.05001 12 4.05001H13.075L11.075 2.05001L12.05 1.07501L15.775 4.80001L12.05 8.52501L11.05 7.52501L13.025 5.55001H12C10.2167 5.55001 8.6875 6.18751 7.4125 7.46251C6.1375 8.73751 5.5 10.2667 5.5 12.05C5.5 12.5333 5.54583 12.9917 5.6375 13.425C5.72917 13.8583 5.84167 14.2667 5.975 14.65L4.9 15.725ZM11.9 23L8.175 19.275L11.9 15.55L12.875 16.525L10.875 18.525H12C13.7833 18.525 15.3125 17.8875 16.5875 16.6125C17.8625 15.3375 18.5 13.8083 18.5 12.025C18.5 11.5417 18.4583 11.0833 18.375 10.65C18.2917 10.2167 18.1667 9.80835 18 9.42501L19.075 8.35001C19.4083 8.95001 19.6458 9.55418 19.7875 10.1625C19.9292 10.7708 20 11.3917 20 12.025C20 14.2083 19.2125 16.0875 17.6375 17.6625C16.0625 19.2375 14.1833 20.025 12 20.025H10.875L12.875 22.025L11.9 23Z"
        fill="#85888E"
      />
    </svg>
  )
}

export default ResetSearchIcon

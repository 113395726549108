import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'

import React from 'react'
import { useTranslation } from 'react-i18next'
type Props = {
  methods: any
}
const ManageBudgetAdditionalDetails: React.FC<Props> = ({ methods }) => {
  const { watch } = methods

  const { Option } = watch()

  const { t } = useTranslation()
  const formFields: FormBuilderProps[] = [
    {
      label: t('New budget name'),
      placeholder: t('Enter budget name'),
      name: 'BudgetName',
      id: 'BudgetName',

      required: true,
      type: 'text',
      spacing: 6
    },
    {
      label: t('Currency'),
      placeholder: t('Currency'),
      name: 'CurrencyName',
      id: 'CurrencyId',
      disabled: Option === '1' ? true : false,
      required: true,
      type: 'autocomplete',
      apiParams: { key: 'CURRENCY' },
      spacing: 6
    },
    {
      label: t('Remarks'),
      placeholder: t('Remarks'),
      name: 'Remarks',
      id: 'Remarks',
      rows: 12,

      type: 'textarea',
      spacing: 12
    }
  ]
  return (
    <div className={`w-full  h-full `}>
      <div className="col-span-12">
        <div className="pb-[30px]">
          <p className="text-m font-bold text-dark pb-2.5">Fill additional details</p>
          <p className="text-grey-secondary text-common font-normal text-wrap ">
            {Option === '1'
              ? 'Please provide a name for the new budget'
              : 'Please provide a name and currency for the new budget'}
          </p>
        </div>
      </div>
      <div className=" grid grid-cols-12 gap-7">
        <FormBuilder data={formFields} edit={true} />
      </div>
    </div>
  )
}

export default ManageBudgetAdditionalDetails

import { TextFieldProps } from '@/common/customComponenttypes'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomIcons from '../icons/customIcons'
import ModernAutoComplete from './ModernAutoComplete'

const TextField: React.FC<TextFieldProps> = ({
  name,
  id,
  label,
  type = 'text',
  disabled = false,
  readOnly = false,
  fullwidth = true,
  step,
  uppercase = false,

  register,
  adorement,
  value,
  placeholder = '',
  min,
  max,
  size = 'md',
  required = false,
  autoComplete = false,
  isValid,
  isTooltip = true,
  maxLength = 200,
  autoFocus = false,
  onChange,

  onBlur,
  errors,
  keyRegexPattern,
  backGround, //added for set background colour according to the form
  /// Provition to add Prefixes
  onPrefixChange,
  prefixValue,
  prefixes
  ///
}) => {
  // const [editState, setEditState] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const adorementRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const onLabelClick = () => {
    if (!isDisabled) {
      inputRef?.current?.focus()
    }
  }

  const checkIsEmptyField = (): boolean => {
    if (value === undefined) return true
    else return (value?.toString().length ?? 0) <= 0 ? true : false
  }
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  const getErrors = (err?: any) => {
    let errMsg = ''
    if (err?.message) {
      errMsg = t(err?.message)
    }
    return errMsg
  }
  useEffect(() => {
    if (autoFocus) {
      onLabelClick()
    }
  }, [autoFocus])

  const getHeight = () => {
    switch (size) {
      case 'xs':
        return ' h-10 '
      case 'xxs':
        return ' h-[25px] '
      case 'sm':
        return ' h-[34px] '
      case 'md':
        return ' h-10 '
      case 'lg':
        return ' h-10 '
      default:
        return ' h-10 '
    }
  }
  const getBg = () => backGround || 'bg-transparent' //added for set background colour according to the form

  const generateClassName = (
    from: 'input' | 'label' | 'message' | 'adorement'
  ): string => {
    let className = ' '

    switch (from) {
      case 'input':
        className += `block ${size === 'xxs' ? ' text-xxs' : 'text-common'}  text-input-text font-normal px-3.5 w-full text-sm text-gray-900 ${getBg()} border appearance-none peer rounded-[4px] disabled:text-input-disabled bg-white disabled:bg-disabled placeholder-transparent focus:placeholder-grey-secondary  ${getHeight()}`

        if (errors && errors[name]) {
          className +=
            ' border-[#FDA29B] focus:border-error-[#FDA29B] focus:ring-[#FDA29B] focus:ring-3 focus:outline-[#FDA29B] input-outline'
        } else {
          className +=
            ' text-grey-dark border-input-light focus:border-blue-navy  focus:outline-none  focus:ring-0'
        }

        break
      case 'label':
        className += `  modern-input-label   peer-focus:modern-input-peer-focus-label-size 
          bg-white  absolute  duration-300 transform -translate-y-4  top-2 z-1 origin-[0]  px-0 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2  peer-focus:-translate-y-4 start-[14px] rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto ${
            isDisabled ? 'cursor-pointer' : 'cursor-text peer-focus:cursor-pointer'
          } ${
            checkIsEmptyField()
              ? 'modern-input-label-size'
              : 'modern-input-peer-focus-label-size'
          }`
        if (errors && errors[name]) {
          className += ' text-error-light '
        } else {
          className += ' text-grey-dark peer-focus:text-blue-navy'
        }
        break
      case 'message':
        className = ' text-error-icon '
        break
      case 'adorement':
        className += '  absolute right-0 adorement gap-1 flex items-center '
        break
      default:
        break
    }
    return className
  }
  const getInnerWidth = () => {
    const innerwidth = adorementRef.current ? adorementRef.current.offsetWidth : 1
    return innerwidth
  }
  const getPosition = () => {
    return 'bottom'
  }
  const handleError = (data: any) => {
    if (getErrors(data[name]) === 'required' || getErrors(data[name]) === 'Required') {
      return `${label} is ${getErrors(data[name])}` ?? ''
    } else {
      return getErrors(data[name]) ?? ''
    }
  }

  // Added handle key press function on textfield to restrict typing letter 'e' on number type
  const handleKeyPress = (e: any) => {
    if (type === 'number' && e.key === 'e') e.preventDefault()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (keyRegexPattern) {
      const { value } = event.target
      if (new RegExp(keyRegexPattern).test(value)) {
        onChange?.(event)
      }
    } else {
      onChange?.(event)
    }
  }
  const renderPrefix = () => {
    if (prefixes) {
      switch (prefixes?.type) {
        case 'custom_select':
          return (
            <ModernAutoComplete
              key={prefixes.name}
              placeholder={prefixes.placeholder}
              onChange={(e) => onPrefixChange?.(e)}
              name={prefixes.name}
              value={prefixValue ?? { id: '', name: '' }}
              type="custom_select"
              isCustomPlaceholder
              fullWidth={false}
              width={prefixes.width}
              fromPrefix
              isClose={false}
              hideClear={prefixes.hideClear}
              data={prefixes.data}
              hasParentError={Boolean(errors && errors[name])}
              errors={errors}
              disabled={prefixes.disabled}
              required={prefixes.required}
              autoFilter={prefixes.autoFilter ? prefixes.autoFilter : false}
            />
          )
        default:
          return <></>
      }
    }
  }
  const getBorderLeft = () => {
    if (prefixes) {
      if (errors && errors[prefixes.name] && Boolean(errors && errors[name])) {
        return undefined
      } else if (errors && errors[prefixes.name]) {
        return 0
      }
    }
    return undefined
  }

  return (
    <div className={`${getPosition()} ${fullwidth ? 'w-full' : 'w-auto'}`}>
      <div className="tooltip-container ">
        {isHovered && errors && errors[name] && (
          <span className="tooltip">{handleError(errors)} </span>
        )}
        <div className={`w-full ${prefixes ? 'flex' : ''}`}>
          {prefixes && renderPrefix()}
          <div className={`flex relative ${fullwidth ? 'w-full' : 'w-auto'}`}>
            <div className="relative w-full ">
              <input
                type={type}
                // id={frmid+id}
                ref={inputRef}
                disabled={isDisabled}
                step={step}
                min={min}
                max={max}
                maxLength={maxLength}
                readOnly={readOnly ?? false}
                {...register?.(name, { required })}
                aria-describedby={id}
                style={{
                  paddingRight: getInnerWidth(),
                  ...(prefixes
                    ? {
                        borderLeft: getBorderLeft(),
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0
                      }
                    : {})
                }}
                className={` ${generateClassName('input')} ${uppercase ? 'uppercase placeholder:normal-case' : ''} ${step ? 'input-number .custom-input' : ''}`}
                placeholder={placeholder}
                onChange={(e) => {
                  handleChange(e)
                }}
                onKeyDown={handleKeyPress}
                autoComplete={autoComplete ? 'on' : 'off'}
                onBlur={(e) => onBlur?.(e)}
                // autoFocus={autoFocus}
                value={value}
              />
              <label
                htmlFor={id}
                onClick={() => onLabelClick()}
                className={generateClassName('label')}
              >
                {label}
                {required ? <span className="text-error"> *</span> : <></>}
              </label>
            </div>
            {(adorement ||
              (!isValid && isTooltip && errors && errors[name]) ||
              isValid) && (
              <div className="flex items-center justify-center gap-1 ">
                <div
                  className={`${generateClassName('adorement')}h-[20px] right-[1px]`} //removed adorment bg color
                  ref={adorementRef}
                >
                  {adorement && (
                    <div
                      className={`  ${isDisabled ? 'text-zinc-500' : 'text-grey-medium'} `} // changed for adorment text blurness while disabled
                    >
                      <>{adorement}</>
                    </div>
                  )}
                  {!isValid && isTooltip && errors && errors[name] && (
                    <div
                      className={` text-error-label relative bg-white cursor-pointer ${generateClassName(
                        'message'
                      )}`}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <CustomIcons name="alert" type="medium" />
                    </div>
                  )}
                  {isValid && (
                    <div className="pl-2">
                      <CustomIcons
                        name="check_mark"
                        type="medium"
                        className="text-[#28B440]"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextField

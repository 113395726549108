type ColProps = {
  col: {
    title: string
    field: string
    type?: string
    align?: 'right' | 'left' | 'center'
    icon?: string
    tooltip?: boolean
    render?: (row: any) => any
  }
  rowData: any
  lastChild?: boolean
  index?: number
}
export default function DataColumnHead({ col, rowData }: ColProps) {
  const handleCol = () => {
    switch (col.type) {
      case 'title':
        return (
          <div key={col.title} className={``}>
            <p className={`text-text-primary font-bold leading-5 text-m capitalize`}>
              {rowData[col.field]}
            </p>
          </div>
        )

      case 'status':
        return (
          <div
            key={col.title}
            className={`text-common text-white text-sm font-medium leading-[14px]`}
          >
            {rowData[col.field] && (
              <div className=" flex items-center capitalize justify-center ">
                <div className="bg-primary p-1.5 rounded-[4px]">
                  <p className="text-common capitalize text-white text-sm font-medium leading-[14px]">
                    {rowData[col.field]}
                  </p>
                </div>
              </div>
            )}
          </div>
        )
      default:
        return (
          <div key={col.title} className={`text-common capitalize`}>
            <p className={`text-dark`}>{rowData[col.field]}</p>
          </div>
        )
    }
  }
  const renderFunc = col.render && col.render
  return (
    <>
      {renderFunc && (
        <div
          key={col.title}
          className="p-cell text-xxs text-table-bodyColor font-normal td-custom capitalize"
        >
          {renderFunc(rowData)}
        </div>
      )}
      {!renderFunc && handleCol()}
    </>
  )
}

import React from 'react'
import ReactApexChart from 'react-apexcharts'

import { dashboardDummyDataGen } from '@/utilities/dataGenerators'
import { ApexOptions } from 'apexcharts'

const Pie: React.FC = () => {
  const series = dashboardDummyDataGen([44, 55, 13, 43])
  const options: ApexOptions = {
    chart: {
      width: 380,
      type: 'pie'
    },
    colors: ['#006DCD', '#003C71', '#FF681D', '#F1B635'],
    labels: ['Alpha Ship', 'Antartic', 'Beta Ship', 'Gamma Ship'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }
  return (
    <div>
      <ReactApexChart options={options} series={series} type="pie" height={200} />
    </div>
  )
}

export default Pie

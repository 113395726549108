import CustomIcons from '@/components/common/icons/customIcons'
import MenuDropDown from '@/components/common/menudropdown/MenuDropDown'
import React from 'react'

type TreeItemProps = {
  label: string
  level?: number
  children?: React.ReactNode
  handleNodeCheck?: (data?: any) => void
  node: any
  treeSelect?: boolean
  nodeIcon?: string
  handleTreeExpand?: (data: any, node?: any) => void
  treeAction?: string
  handleTreeAction?: (data: any) => void
  nodeSelectedIcon?: string
  handleMenuActions?: (slug: string, row: any, node: any) => void
  actionDropDown?: {
    title: string
    slug: string
    iconName: string
    id: string
    isWarning?: boolean
  }[]
  handleTitleActions?: (data: any) => void
  nodeId?: any
  data?: any
  isMouseOut?: boolean
  setIsMouseOut: (data: boolean) => void
}

const TableTreeRow: React.FC<TreeItemProps> = ({
  label,
  level = 0,
  children,
  node,
  nodeIcon,
  handleTreeExpand,
  treeAction,
  handleTreeAction,
  handleMenuActions,
  actionDropDown,
  handleTitleActions,
  nodeId,
  data,
  isMouseOut,
  setIsMouseOut
}) => {
  const onIconClick = (click: boolean) => {
    setIsMouseOut(click)
  }

  const handleTree = () => {
    handleTreeExpand?.(nodeId, node)
  }

  const preparedChildren = node.isOpen ? <>{children}</> : null
  return (
    <div>
      <div className="grid grid-cols-12 gap-3 items-center h-[26px] hover:bg-table-hover">
        <div className="col-span-12 relative">
          <div
            style={{ marginLeft: level > 0 ? level * 10 : 0 }}
            className={` flex  items-center p-1 tree-content gap-2  `}
            // onMouseOver={handleMouseOver}
            // onMouseOut={handleMouseOut}
          >
            {node?.hasChild || node.parentId === null ? (
              <button onClick={() => handleTree()} className="focus:outline-none">
                <span
                  className={` text-common cursor-pointer  ${
                    node?.isActive
                      ? 'text-orange-500 font-normal'
                      : node?.isOpen
                        ? 'text-text-primary font-normal'
                        : 'text-text-secondary font-normal'
                  }`}
                >
                  <CustomIcons
                    name={node?.isOpen ? 'tree_expand' : 'tree_colapse'}
                    type="large"
                    className={`${!node?.hasChild && 'text-gray-300'}`}
                  />
                </span>
              </button>
            ) : (
              <div className="w-5"></div>
            )}
            {node && Object.keys(node)?.length > 0 && nodeIcon && (
              <span
                className={` text-common cursor-pointer  ${
                  node?.isActive
                    ? 'text-orange-500 font-normal'
                    : node?.isOpen
                      ? 'text-text-primary font-normal'
                      : 'text-text-secondary font-normal'
                }   ${nodeIcon ? '' : 'ml-1'} `}
                onClick={(e) => {
                  e.stopPropagation()
                  handleTitleActions?.(node)
                }}
              >
                <CustomIcons name={node?.iconName ?? nodeIcon} type="large" />
              </span>
            )}

            <span
              className={`text-common cursor-pointer  ${
                node?.isActive
                  ? 'text-orange-500 font-normal'
                  : node?.isOpen
                    ? 'text-text-primary font-normal'
                    : 'text-text-secondary font-normal'
              }   ${nodeIcon ? '' : 'ml-1'} `}
              onClick={(e) => {
                e.stopPropagation()
                handleTitleActions?.(node)
              }}
            >
              {label}
            </span>
            {treeAction && node && Object.keys(node)?.length > 0 && (
              <span
                className="text-lg  cursor-pointer  tree-action z-50"
                // onClick={(e) => {
                //   e.stopPropagation()
                //   handleTreeAction?.(node)
                // }}
              >
                <MenuDropDown
                  iconName={treeAction}
                  onIconClick={onIconClick}
                  isClickActive={isMouseOut}
                  position={'left-0'}
                  handleMenuActions={(slug: string, row: any) =>
                    handleMenuActions?.(slug, row, node)
                  }
                  actionDropDown={actionDropDown}
                />
              </span>
            )}
          </div>
        </div>
      </div>

      {preparedChildren}
    </div>
  )
}

export default TableTreeRow

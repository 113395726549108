const SeaRecordIcon = () => {
  return (
    <>
      <g clipPath="url(#clip0_2577_1828)">
        <path
          d="M22.64 20.86H19.85L21.11 15.34C21.34 14.32 20.86 13.26 19.94 12.76L19.08 12.3V7.51999C19.08 6.98999 18.87 6.47999 18.49 6.10999C18.11 5.73999 17.61 5.52999 17.08 5.52999H16.51V3.74999C16.51 2.77999 15.72 1.98999 14.75 1.98999H9.83C8.86 1.98999 8.07 2.77999 8.07 3.74999V5.52999H7.51C6.41 5.52999 5.52 6.42999 5.52 7.51999V12.3L4.66 12.77C3.74 13.27 3.26 14.33 3.49 15.35L4.75 20.86H1.96C1.43 20.86 1 21.29 1 21.82C1 22.35 1.43 22.78 1.96 22.78H22.63C23.16 22.78 23.59 22.35 23.59 21.82C23.59 21.29 23.16 20.86 22.63 20.86H22.64ZM10 3.92999H14.59V5.53999H10V3.92999ZM7.5 7.45999H17.08C17.08 7.45999 17.15 7.48999 17.15 7.52999V11.28L13.42 9.24999C13.08 9.06999 12.69 8.96999 12.31 8.96999C11.93 8.96999 11.54 9.06999 11.2 9.24999L7.45 11.27V7.64999V7.51999C7.45 7.51999 7.45 7.48999 7.47 7.46999L7.49 7.31999C7.49 7.31999 7.5 7.31999 7.52 7.31999V7.45999H7.5ZM13.26 20.86V11.36L19.02 14.47C19.18 14.56 19.26 14.74 19.23 14.92L17.87 20.86H13.26ZM5.37 14.92C5.33 14.74 5.42 14.56 5.58 14.47L11.34 11.36V20.86H6.73L5.37 14.92Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2577_1828">
          <rect width="22.6" height="20.79" fill="white" transform="translate(1 2)" />
        </clipPath>
      </defs>
    </>
  )
}
export default SeaRecordIcon

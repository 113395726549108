import {
  deleteTechnicalData,
  deleteTechnicalMipsData,
  getMasterData,
  getTechnicalData,
  getTechnicalMipsData,
  postTechnicalData,
  postTechnicalMipsData,
  updateTechnicalMipsData
} from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { dataParse } from '@/utilities'
import { useQuery } from '@tanstack/react-query'

export const useTechnicalParticularDetails = (id: string) => {
  const fetchData = async () => {
    return await getMasterData(`${apiTechnicalUrl.GET_VESSEL_DETAILS}${id}`)
  }

  return useQuery({
    queryKey: ['technical-particular-details', id],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}
export const fetchVesselParticularLog = async (filters: any) => {
  const { data } = await postTechnicalMipsData(
    `${apiTechnicalUrl.GET_VESSEL_PARTICULAR_AUDIT_LOG_MIPS}`,
    {
      ...filters
    }
  )
  return data
}
export const useCommunicationDetail = (params: any) => {
  const fetchData = async () => {
    return await postTechnicalMipsData(
      `${apiTechnicalUrl.COMMUNICATION_DETAILS_MIPS}`,
      params
    )
  }
  return useQuery({
    queryKey: ['technical-communication-details'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}
export const deleteCommuication = (id: string) => {
  return deleteTechnicalMipsData(`${apiTechnicalUrl.COMMUNICATION_DELETE_MIPS}${id}`)
}
export const createCommunication = (data: any) => {
  return postTechnicalMipsData(apiTechnicalUrl.CREATE_COMMUNICATION_MIPS, data)
}
export const updateCommunication = (data: any) => {
  return updateTechnicalMipsData(apiTechnicalUrl.COMMUNICATION_UPDATE_MIPS, data)
}

export const getCommunicationInfo = (id?: string) => {
  return getTechnicalMipsData(`${apiTechnicalUrl.GET_COMMUNICATION_MIPS}${id}`).then(
    (resp) => resp.data
  )
}

export const useContactDetails = (params: any) => {
  const fetchData = async () => {
    return await postTechnicalMipsData(`${apiTechnicalUrl.CONTACT_DETAILS_MIPS}`, params)
  }
  return useQuery({
    queryKey: ['technical-contact-details'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}
export const deleteContact = (id: string) => {
  return deleteTechnicalMipsData(`${apiTechnicalUrl.CONTACT_DELETE_MIPS}${id}`)
}
export const createContact = (data: any) => {
  return postTechnicalMipsData(apiTechnicalUrl.CREATE_CONTACT_MIPS, data)
}
export const updateContact = (data: any) => {
  return updateTechnicalMipsData(apiTechnicalUrl.UPDATE_CONTACT_MIPS, data)
}

export const useAntiFoulingDetail = (params: any) => {
  const fetchData = async () => {
    return await postTechnicalMipsData(
      `${apiTechnicalUrl.VESSEL_ANTIFOULING_MIPS}/SearchVesselAntiFouling`,
      params
    )
  }
  return useQuery({
    queryKey: ['technical-anti-fouling-details'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

export const deleteAntiFouling = (id: string) => {
  return deleteTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_ANTIFOULING_MIPS}/Delete/${id}`
  )
}
export const createAntiFouling = (data: any) => {
  return postTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_ANTIFOULING_MIPS}/AddAntiFouling`,
    data
  )
}
export const updateAntiFouling = (data: any) => {
  return updateTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_ANTIFOULING_MIPS}/UpdateAntiFouling`,
    data
  )
}
export const useAntiFoulingPaintDetail = (params: any) => {
  const fetchData = async () => {
    return await postTechnicalMipsData(
      `${apiTechnicalUrl.VESSEL_ANTIFOULING_MIPS}/SearchAntiFoulingPaints`,
      params
    )
  }
  return useQuery({
    queryKey: ['technical-anti-fouling-paint-details'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

export const deleteAntiFoulingPaint = (id: string) => {
  return deleteTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_ANTIFOULING_MIPS}/DeleteAntiFoulingPaint/${id}`
  )
}
export const createAntiFoulingPaint = (data: any) => {
  return postTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_ANTIFOULING_MIPS}/AddAntiFoulingPaint`,
    data
  )
}
export const updateAntiFoulingPaint = (data: any) => {
  return updateTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_ANTIFOULING_MIPS}/UpdateAntiFoulingPaint`,
    data
  )
}
export const getAntiFoulingPaintInfo = (id?: string) => {
  return getTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_ANTIFOULING_MIPS}/GetAntiFoulingPaintById/${id}`
  )
}

export const useEquipmentConfigDetail = (params: any) => {
  const fetchData = async () => {
    return await postTechnicalData(`${apiTechnicalUrl.EQUIPMENT_CONFIG_DETAILS}`, params)
  }
  return useQuery({
    queryKey: ['technical-equipment-config-details'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

export const removeFromVessel = (data: any) => {
  return postTechnicalData(apiTechnicalUrl.EQUIPMENT_REMOVE_VESSEL, data)
}
export const copyToVessel = (data: any) => {
  return postTechnicalData(apiTechnicalUrl.EQUIPMENT_COPY_TO_VESSSEL, data)
}

export const useSeaTrialItemDetail = (params: any) => {
  const fetchData = async () => {
    const { data } = await postTechnicalMipsData(
      `${apiTechnicalUrl.SEA_TRIAL_ITEMS_MIPS}`,
      params
    )
    return data
  }
  return useQuery({
    queryKey: ['technical-sea-trial-items'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

export const useSeaTrialsDetail = (params: any) => {
  const fetchData = async () => {
    const { data } = await postTechnicalMipsData(
      `${apiTechnicalUrl.SEA_TRIAL_DETAILS_MIPS}`,
      params
    )
    return data
  }

  return useQuery({
    queryKey: ['technical-sea-trial-details'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}
export const deleteSeaTrial = (id: string) => {
  return deleteTechnicalMipsData(`${apiTechnicalUrl.SEA_TRIAL_MIPS}/Delete/${id}`)
}
export const createSeaTrial = (data: any) => {
  return postTechnicalMipsData(`${apiTechnicalUrl.SEA_TRIAL_MIPS}/Create`, data)
}
export const updateSeaTrial = (data: any) => {
  return updateTechnicalMipsData(`${apiTechnicalUrl.SEA_TRIAL_MIPS}/Update`, data)
}
export const getSeaTrialItemDetailInfo = (id?: string) => {
  return getTechnicalMipsData(`${apiTechnicalUrl.SEA_TRIAL_MIPS}/GetItemById/${id}`)
}
export const createSeaTrialItemDetailInfo = (data: any) => {
  return postTechnicalMipsData(`${apiTechnicalUrl.SEA_TRIAL_MIPS}/CreateItem`, data)
}
export const updatSeaTrialItemDetailInfo = (data: any) => {
  return updateTechnicalMipsData(`${apiTechnicalUrl.SEA_TRIAL_MIPS}/UpdateItem`, data)
}
export const deleteASeaTrialItemDetailInfo = (id: string) => {
  return deleteTechnicalMipsData(`${apiTechnicalUrl.SEA_TRIAL_MIPS}/DeleteItem/${id}`)
}
export const getVesselEquipmentTypes = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.VESSEL_EQUIPMENT_TYPES}`, data)
}
export const getVesselOilTypesTypes = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.VESSEL_OIL_TYPES}`, data)
}
export const getVesselConsumptionTypes = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.VESSEL_CONSUMPTION_TYPES}`, data)
}
export const addEquipmentConfig = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.ADD_EQUIPMENT_CONFIG}`, data)
}
export const updateEquipmentConfig = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.UPDATE_EQUIPMENT_CONFIG}`, data)
}
export const getEquipmentConfigDetails = (data: any) => {
  return getTechnicalData(`${apiTechnicalUrl.GET_EQUIPMENT_CONFIG}${dataParse(data)}`)
}
export const deleteEquipmentConfig = (data: any) => {
  return deleteTechnicalData(
    `${apiTechnicalUrl.DELETE_EQUIPMENT_CONFIG}${dataParse(data)}`
  )
}

import React, { ReactNode, useContext, useState } from 'react'
import { AppContext } from './appContext'

export const useAppContext = () => useContext(AppContext)

interface ProviderProps {
  children: ReactNode
}

export const AppContextProvider: React.FC<ProviderProps> = ({ children }): any => {
  const [isCommonLoader, setIsCommonLoader] = useState(false)
  const [isActionLoader, setIsActionLoader] = useState(false)

  return (
    <AppContext.Provider
      value={{ isCommonLoader, setIsCommonLoader, isActionLoader, setIsActionLoader }}
    >
      {children}
    </AppContext.Provider>
  )
}

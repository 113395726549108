import { FormBuilder } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import {
  createTank,
  getTankTypeDetails,
  updateTank
} from '@/modules/technical/pages/vesselParticulars/details/tanks/api'
import { generateDynamicTankSchema } from '@/modules/technical/pages/vesselParticulars/details/tanks/schema'
import { formContainerClass } from '@/styles/variables'
// import useHasPermission from '@/utilities/checkActionPermission'
import { zodResolver } from '@hookform/resolvers/zod'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { z } from 'zod'
import { guid } from '@/constants'

// import { useTranslation } from 'react-i18next'
type VesselTankInfoProps = {
  data: any
  open: boolean
  onClose: () => void
  edit: boolean
  view: boolean
  title: string
  refetch: any
}
type TankTypeProps = {
  RecWeight?: number
  RecVol98?: number
  RecVol95?: number
  RecDesnity?: number
  RecSteamCoil?: number
  RecLevelGauge?: number
}
const VesselTankManage: React.FC<VesselTankInfoProps> = ({
  data,
  edit,
  view,
  open,
  title,
  onClose,
  refetch
}) => {
  // const { t } = useTranslation()
const { showSnackBar } = useCustomSnackBar()
  const [tankType, setTankType] = useState<TankTypeProps>({})
  const params = useParams()
  // const hasPermission = useHasPermission('VesselGenInfo')
  const TankSchema: any = generateDynamicTankSchema(tankType)
  type TankDataType = z.infer<typeof TankSchema>
  const onSuccessAction = () => {
    onClose()
    refetch()
  }

  const onSubmit = (res: any) => {
    const api = edit ? updateTank : createTank
    let payload = {
      ...res,
      VesTankId: edit ? data?.VesTankId : guid,
      VesselId: params.vesselID
    }

    Object.keys(payload).forEach((key: string) => {
      payload[key] = payload[key] && payload[key] !== '' ? payload[key] : undefined
    })
    api(payload)
      .then((res) => {
        onSuccessAction()
        showSnackBar(res)
      })
      .catch((err) => {
        showSnackBar(err?.response?.data?.error, 'error')
      })
  }

  const formConfig = [
    {
      label: 'Tank Type',
      placeholder: 'Type to search',
      name: 'TankTypeName',
      type: 'autocomplete',
      required: true,
      disabled: view,
      id: 'TankTypeId',
      maxLength: 4,
      apiParams: { key: 'TANKTYPE' }
    },

    {
      label: 'Compartment',
      placeholder: 'Compartment',
      name: 'Compartment',
      type: 'text',
      required: true,
      disabled: view,
      maxLength: 100
    },
    {
      label: 'Volume 100%',
      placeholder: 'Volume 100%',
      name: 'Volume',
      disabled: view,
      type: 'number',
      adorement: (
        <>
          m<sup>3</sup>
        </>
      )
    },
    {
      label: 'Weight',
      placeholder: 'Weight',
      name: 'Weight',
      type: 'number',
      disabled: tankType?.RecWeight === 0 || view,
      required: tankType?.RecWeight === 2,
      adorement: <>MT</>
    },
    {
      label: 'Volume 98%',
      placeholder: 'Volume 98%',
      disabled: tankType?.RecVol98 === 0 || view,
      required: tankType?.RecVol98 === 2,
      name: 'Volume98',
      type: 'number',
      adorement: (
        <>
          m<sup>3</sup>
        </>
      )
    },
    {
      label: 'Volume 95%',
      placeholder: 'Volume 95%',
      name: 'Volume95',
      disabled: tankType?.RecVol95 === 0 || view,
      required: tankType?.RecVol95 === 2,
      type: 'number',
      adorement: (
        <>
          m<sup>3</sup>
        </>
      )
    },
    {
      label: 'Density',
      placeholder: 'Density',
      disabled: tankType?.RecDesnity === 0 || view,
      required: tankType?.RecDesnity === 2,
      name: 'Density',
      type: 'number',
      adorement: (
        <>
          kg/m<sup>3</sup>
        </>
      )
    },
    {
      label: 'Steam coil',
      placeholder: 'Type to search',
      disabled: tankType?.RecSteamCoil === 0 || view,
      required: tankType?.RecSteamCoil === 2,
      name: 'SteamCoilTypeName',
      type: 'autocomplete',

      id: 'SteamCoilTypeId',
      maxLength: 4,
      apiParams: { key: 'STEAMCOIL' }
    },
    {
      label: 'Level Guaging',
      placeholder: 'Type to search',
      name: 'LevelGaugeTypeName',
      type: 'autocomplete',
      disabled: tankType?.RecLevelGauge === 0 || view,
      required: tankType?.RecLevelGauge === 2,
      id: 'LevelGaugeTypeId',
      maxLength: 4,
      apiParams: { key: 'LEVELGUAGE' }
    },
    {
      label: 'Remarks',
      placeholder: 'Remarks',
      name: 'Remarks',
      type: 'textarea',
      disabled: view,
      spacing: 12,
      maxLength: 100
    }
  ]

  const initData = () => {
    return { ...data }
  }

  const methods = useForm<TankDataType>({
    resolver: zodResolver(TankSchema),
    mode: 'onChange',
    defaultValues: initData()
  })
  const clearFieldsBasedOnTankType = (resp: any) => {
    const formData: TankDataType = methods.watch()

    methods.reset({
      ...formData,
      ...(resp.RecWeight === 0 ? { Weight: null } : {}),
      ...(resp.RecVol98 === 0 ? { Volume98: null } : {}),
      ...(resp.RecVol95 === 0 ? { Volume95: null } : {}),
      ...(resp.RecDesnity === 0 ? { Density: null } : {}),
      ...(resp.RecSteamCoil === 0
        ? { SteamCoilTypeName: null, SteamCoilTypeId: null }
        : {}),
      ...(resp.RecLevelGauge === 0
        ? { LevelGaugeTypeName: null, LevelGaugeTypeId: null }
        : {})
    })
  }
  const handleSetTankTypeProps = (id: number) => {
    getTankTypeDetails(id)
      .then((res) => {
        setTankType(res)
        clearFieldsBasedOnTankType(res)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    methods.reset({ ...initData() })
  }, [data])
  useEffect(() => {
    if (methods.watch().TankTypeId) {
      handleSetTankTypeProps(methods.watch()?.TankTypeId || data.TankTypeId)
    }
  }, [methods.watch().TankTypeId, data.TankTypeId])
  const handleCloseForm = () => {
    onClose()
  }

  return (
    <>
      <DialogModal
        isOpen={open}
        small={false}
        title={title}
        body={
          <div className="h-full relative w-full">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={formContainerClass}>
                  <FormBuilder data={formConfig} edit={true} />
                </div>
              </form>
            </FormProvider>
          </div>
        }
        onClose={handleCloseForm}
        actionLabel={edit ? 'Update' : view ? undefined : 'Save'}
        onSubmit={methods.handleSubmit(onSubmit)}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={view ? 'Close' : 'Cancel'}
      />
    </>
  )
}

export default VesselTankManage

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const ShipIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <path
      d="M19.8333 16.7542L22 12.2145L17.6667 11.2056M17.6667 11.2056L12.25 9.94459M17.6667 11.2056L18.75 5.40482H14.4167M12.25 9.94459V15.6193M12.25 9.94459L6.83333 11.2056M6.83333 11.2056L2.5 12.2145L4.66667 16.7542M6.83333 11.2056L5.75 5.40482H10.0833M10.0833 5.40482V2H14.4167V5.40482M10.0833 5.40482H14.4167M2.5 21.294L3.84865 20.7288C4.69982 20.3722 5.66404 20.4773 6.4268 21.01C7.58928 21.822 9.14612 21.6054 10.0661 20.504L10.1047 20.4578C11.2404 19.098 13.2596 19.098 14.3953 20.4578L14.4339 20.504C15.3539 21.6054 16.9107 21.822 18.0732 21.01C18.836 20.4773 19.8002 20.3722 20.6514 20.7288L22 21.294"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}

export default ShipIcon

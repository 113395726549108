import { noLeadingSpaces, validateNumberOfDigits } from '@/utilities'
import { z } from 'zod'
export const EQConfigSchema = z.object({
  MaterialTypeId: z
    .number({ required_error: 'Equipment Type is required' })
    .min(1, { message: 'Equipment Type is required' }),
  ConfigId: z.string().nullable().optional(),

  MaterialTypeName: z
    .string({ required_error: 'Equipment Type is required' })
    .min(1, { message: 'Equipment Type is required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  EqpCount: z
    .number({ invalid_type_error: 'Equipment Count / No. of Units is required' })
    .min(1, { message: 'Equipment Count / No. of Units should be atleatst 1' })
    .refine(validateNumberOfDigits, {
      message: 'Max length for Equipment Count / No. of Units is 8 '
    }),
  Archived: z.boolean()
})

export type EQConfigDataType = z.infer<typeof EQConfigSchema>

import React from 'react'
type Props = {
  children: React.ReactNode
  search?: boolean
}
const HeaderWrapper: React.FC<Props> = ({ children, search = true }) => {
  return <div className={`${!search}  bg-white `}>{children}</div>
}

export default HeaderWrapper

import { Button } from '@/components/common'
import ConfirmModal from '@/components/common/modal/ConfirmModal'
import DialogModal from '@/components/common/modal/DialogModal'
import { useState } from 'react'
import TextFieldComponent from '../textarea'

type PopupId = 'popup1' | 'popup2' | 'popup3' | 'popup4'
const ModalCompoent = () => {
  const [openPopups, setOpenPopups] = useState<PopupId[]>([])
  const handleClose = (type?: string, popupId?: PopupId | undefined | string) => {
    if (popupId)
      setOpenPopups((prevOpenPopups) => prevOpenPopups.filter((id) => id !== popupId))
  }
  const openPopup = (popupId: PopupId) => {
    setOpenPopups((prevOpenPopups) => [...prevOpenPopups, popupId])
  }
  const handleSubmit = () => {}
  const isPopupOpen = (popupId: PopupId) => openPopups.includes(popupId)
  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      <div className="py-3">
        <div>
          <Button label="Delete Popup" onClick={() => openPopup('popup1')} />
        </div>
      </div>
      <div className="py-3">
        <div>
          <Button
            label="Success Popup"
            primary={false}
            onClick={() => openPopup('popup2')}
          />
        </div>
      </div>
      <div className="py-3">
        <div>
          <Button label="Form Popup" outlined onClick={() => openPopup('popup3')} />
        </div>
      </div>
      <div className="py-3">
        <div>
          <Button
            label="Form Popup With Action"
            primary={false}
            outlined
            onClick={() => openPopup('popup4')}
          />
        </div>
      </div>
      {isPopupOpen('popup1') && (
        <ConfirmModal
          isOpen={true}
          modalId="popup1"
          topIcon="danger"
          actionLabel="Delete"
          onClose={handleClose}
          actionLoader={false}
          title="Are you sure?"
          subTitle="Do you really want to delete these item? This process cannot be undone."
          body={<div></div>}
          secondaryAction={handleClose}
          secondaryActionLabel="Cancel"
          scrollDisable={true}
          isCloseIcon={false}
        />
      )}

      {isPopupOpen('popup2') && (
        <ConfirmModal
          isOpen={true}
          modalId="popup2"
          topIcon="success"
          onClose={handleClose}
          title="Success"
          type="success"
          scrollDisable={false}
          subTitle="Thank you. You have updated successfully"
        />
      )}

      {isPopupOpen('popup3') && (
        <DialogModal
          isOpen={true}
          modalId="popup3"
          onClose={handleClose}
          title="Add DashBoard Tiles"
          secondaryAction={handleClose}
          secondaryActionLabel="Cancel"
          actionLabel="Save"
          actionLoader={false}
          body={
            <div>
              <TextFieldComponent />
            </div>
          }
        />
      )}
      {isPopupOpen('popup4') && (
        <DialogModal
          isOpen={true}
          modalId="popup4"
          onClose={handleClose}
          title="Add DashBoard Tiles With Action Button"
          body={
            <div>
              <TextFieldComponent />
            </div>
          }
          actionBody={
            <div>
              <div className="flex flex-col gap-1 p-1">
                <div
                  className="
                    flex
                    flex-row
                    items-center
                    gap-1
                    w-full
                    justify-center
                  "
                >
                  <Button
                    label="Save"
                    onClick={handleSubmit}
                    className="inline-flex items-center px-5 text-center mr-1"
                  />
                  <Button label="Cancel" onClick={() => handleSubmit()} outlined />
                </div>
              </div>
            </div>
          }
        />
      )}
    </div>
  )
}

export default ModalCompoent

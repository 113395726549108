import React from 'react'

type Props = {
  title: string
  children: React.ReactNode
}
export default function CustomTooltip({ title, children }: Props) {
  return (
    <div className="custom-tooltip-container ">
      <span className="custom-text">{children}</span>
      <span className="custom-tooltip font-medium text-text-secondary rounded-dialog border-2 border-[#BEE0FF] h-[34px]">
        {title}
      </span>
    </div>
  )
}

import { MenuExplorerProps, SideNavProps } from '@/common/customComponenttypes'
import CustomIcons from '@/components/common/icons/customIcons'
import { useAppStore, useHeaderStore } from '@/store/appStore'
import { useSideMenuStore } from '@/store/sideMenuStore'
import { useClearFilter } from '@/utilities/clearStore'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { generateTestId } from '@/utilities/dataGenerators'
import FavouriteSideNav from './favSidenav'
import { sideMenuItems } from './store'

export default function SideNavSmall({
  menuData,
  favMenuData,
  handleChildView,
  handleButtonClick,
  onSetFavorites,
  handleSetFav,
  handleFavChildView,
  open,
  sectionId
}: SideNavProps) {
  const { menuOpened, setMenuOpened } = useAppStore()
  const { isActiveMenu, setIsActiveMenu } = useSideMenuStore()

  const handleClear = useClearFilter()
  const { t } = useTranslation()
  const { setHeaderTitle, setModuleId } = useHeaderStore()
  const history = useNavigate()
  const navigateToPage = (menu: any) => {
    setHeaderTitle(menu.FunctionName)
    setModuleId(menu.FunctionId)
    history(`/${menu.PathName}`)
    setMenuOpened(false)
  }
  const renderSubmenu = (id: string) => {
    return (
      <div className="ml-3  relative flex gap-2 flex-col z-side-nav">
        {menuData.map((menus: MenuExplorerProps) => (
          <React.Fragment key={menus.FunctionId}>
            {menus.ParentId === id && (
              <>
                <div
                  key={menus.FunctionId}
                  data-testid={`${generateTestId(menus.FunctionName)}-${generateTestId(menus.HelpDocPath as string)}`}
                  className={`${
                    sectionId === menus.FunctionId
                      ? ' bg-secondary text-white'
                      : 'text-sidebarText '
                  } flex relative truncate items-center cursor-pointer  justify-between rounded-sm hover:bg-blue-navy`}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleButtonClick(e.currentTarget as HTMLDivElement, menus)
                  }}
                >
                  {menus.FunctionType === 3 ? (
                    <div className="flex justify-between w-full">
                      <div
                        key={menus.FunctionId}
                        className={`${
                          isActiveMenu === menus.FunctionId
                            ? ' bg-secondary text-white'
                            : 'text-sidebarText'
                        } group/item flex   relative rounded-sm truncate items-center cursor-pointer justify-between scroll-smooth hover:bg-blue-navy `}
                      >
                        <div
                          className="flex  items-center cursor-pointer"
                          data-testid={`${generateTestId(menus.FunctionName)}-${generateTestId(menus.HelpDocPath as string)}`}
                          onClick={() => navigateToPage(menus)}
                        >
                          <div className="flex items-center p-2.5">
                            <CustomIcons name={'function'} type="large-m" className="" />
                          </div>
                          <div className="flex items-center">
                            <p className="sidenav-item-label text-sm">
                              {menus.FunctionName}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-center p-2">
                        <div className="flex items-center justify-center">
                          <CustomIcons
                            name={menus.Favourite ? 'favouriteFilled' : 'favourite'}
                            className="visible text-favIconColor"
                            type="medium"
                            onClick={() => {
                              handleSetFav(menus)
                            }}
                          />
                        </div>
                        <div className="flex items-center justify-center pl-2.5">
                          <Link
                            to={`/${menus.PathName}` ?? '/'}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <CustomIcons
                              name={'link'}
                              className="visible "
                              type="medium"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex  items-center ">
                      <div className="flex items-center p-2.5">
                        <CustomIcons
                          name={menus.FunctionType === 2 ? 'folder' : 'function'}
                        />
                      </div>
                      <div className="flex items-center ">
                        <p className="sidenav-item-label text-sm  ">
                          {menus.FunctionName}
                        </p>
                      </div>
                    </div>
                  )}
                  {menus.FunctionType === 2 && (
                    <div className="flex items-center p-2.5">
                      <CustomIcons name={'rightArrow'} className=" " />
                    </div>
                  )}
                </div>
                {menus.FunctionType === 2 && open && sectionId === menus.FunctionId && (
                  <div className=" overflow-y-auto">
                    <div className="p-1 relative ">
                      <>{renderSubmenu(menus.FunctionId)}</>
                    </div>
                  </div>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    )
  }
  const mainMenuHandler = (m: any) => {
    setIsActiveMenu(m.slug)
    if (m.url) {
      history(m.url)
      setMenuOpened(false)
    }
  }
  return (
    <section className={` gap-2 ${menuOpened ? 'flex' : 'hidden'}`}>
      <div className="w-[376px] z-30 h-[100dvh-] bg-background-sidebar text-white overflow-auto fixed flex  flex-col ">
        <div className="flex justify-between p-4 ">
          <div>
            <img src="/assets/logo_with_name.png" alt="" />
          </div>
          <div className="flex items-center">
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={() => {
                setMenuOpened(false)
              }}
            >
              <div>
                <CustomIcons name="close" />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex flex-col gap-2  overflow-auto px-2 py-4 min-h-[calc(100dvh-120px)] max-h-[calc(100dvh-120px)]">
            {sideMenuItems.map((menus) => (
              <div key={menus.id} className="block">
                <div
                  data-testid={`${generateTestId(menus.title)}-${generateTestId(menus.slug as string)}`}
                  className={`${
                    isActiveMenu === menus.slug
                      ? ' bg-secondary text-white rounded-sm '
                      : 'bg-transparent rounded-menu  text-sidebarText hover:bg-background-hover  hover:text-white'
                  } flex   relative truncate items-center  cursor-pointer justify-between`}
                  onClick={(e) => {
                    e.stopPropagation()
                    mainMenuHandler(menus)
                  }}
                >
                  <div className="flex items-center ">
                    <div className="flex items-center p-2.5">
                      <CustomIcons name={menus.iconName} className="" type="large-m" />
                    </div>
                    <div className="flex items-center justify-center">
                      <p className="sidenav-item-label">{t(menus.title)}</p>
                    </div>
                  </div>
                  <div className="flex items-center p-2.5">
                    <CustomIcons
                      name={'rightArrow'}
                      type="large-m"
                      className={`${!menus.child && ' hidden'} flex `}
                    />
                  </div>
                </div>
                {isActiveMenu === 'favourite' && menus.slug === 'favourite' && (
                  <div>
                    <FavouriteSideNav
                      favMenuData={favMenuData}
                      handleFavChildView={handleFavChildView}
                      onSetFavorites={onSetFavorites}
                    />
                  </div>
                )}
                {isActiveMenu === 'explorer' && menus.slug === 'explorer' && (
                  <div className=" h-full  z-40 bg-background-sidebarSecondary p-2 overflow-y-auto transition-transform">
                    <div className="relative gap-1">
                      {menuData.map((menu: MenuExplorerProps) => (
                        <React.Fragment key={menu.FunctionId}>
                          {menu.ParentId == null && (
                            <div>
                              <div
                                data-testid={`${generateTestId(menu.FunctionName)}-${generateTestId(menu.HelpDocPath as string)}`}
                                key={menu.FunctionId}
                                className={`${
                                  menu.childView ? '  text-white' : 'text-sidebarText'
                                } flex  gap-4 relative truncate items-center cursor-pointer justify-between rounded-sm `}
                                onClick={() => {
                                  handleChildView(menu.FunctionId)
                                }}
                              >
                                <div className="flex items-center">
                                  <div className="flex items-center p-2.5">
                                    <CustomIcons
                                      name={'module'}
                                      className=""
                                      type="large-m"
                                    />
                                  </div>
                                  <div
                                    className={` items-center  text-center justify-center flex`}
                                  >
                                    <p className="sidenav-item-label">
                                      {menu.FunctionName}
                                    </p>
                                  </div>
                                </div>

                                <CustomIcons
                                  name={!menu.childView ? 'down_arrow' : 'up_arrow'}
                                  type="large-m"
                                />
                              </div>
                              {menu.childView && <>{renderSubmenu(menu.FunctionId)}</>}
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div
            className="flex items-center p-2.5 cursor-pointer"
            data-testid={`sidebar-logout`}
            onClick={() => {
              handleClear()
            }}
          >
            <div>
              <CustomIcons name="power" type="large-m" />
            </div>
            <div className="pl-2">Logout</div>
          </div>
        </div>
      </div>
    </section>
  )
}

import { generatePopUpTypesFromSymbol } from '@/utilities/dataGenerators'

import { generatePopUpTypes } from '../../../utilities'
import ConfirmModal from '../modal/ConfirmModal'

interface ManageConfirmMessageProps {
  openAction?: boolean
  setCreateOpen?: (open: boolean) => void
  edit?: boolean
  CompanyId?: string
  BranchId?: string
  primaryApi: string
  method: string
  actionData?: any // Define a more specific type if possible
  handleStatusAction?: (primaryApi: string, method: string) => void
  handleconfrimClose?: () => void
  handleConfirm?: () => void
  handleConfirmClose?: () => void
  classes?: any // Use a specific type for Material-UI classes
  actionLoader?: boolean
}
export default function ManageConfirmMessage({
  openAction = false,
  setCreateOpen,
  edit = false,
  CompanyId = '',
  BranchId = '',
  primaryApi,
  method,
  actionData,
  handleStatusAction,
  handleconfrimClose,
  handleConfirm,
  handleConfirmClose,
  actionLoader = false,
  classes
}: ManageConfirmMessageProps) {
  const getDialogActionProperties = () => {
    switch (actionData?.MsgBtnType) {
      case 1:
        return {
          actionLabel: 'Yes',
          secondaryLabel: 'No',
          onSubmit: () => handleStatusAction?.(primaryApi, method),
          onSecondaryAction: handleconfrimClose
        }

      case 2:
        return {
          actionLabel: 'Ok',
          secondaryLabel: 'Cancel',
          onSubmit: () => handleStatusAction?.(primaryApi, method),
          onSecondaryAction: handleconfrimClose
        }

      case 3:
        return {
          actionLabel: 'Ok',
          onSubmit: () => handleStatusAction?.(primaryApi, method)
        }

      case 4:
        return {
          actionLabel: 'Ok',
          secondaryLabel: 'Cancel',
          onSubmit: handleStatusAction?.(primaryApi, method),
          onSecondaryAction: handleConfirmClose
        }

      case 5:
        return {
          actionLabel: 'Yes',
          secondaryLabel: 'No',
          onSubmit: handleStatusAction?.(primaryApi, method),
          onSecondaryAction: handleConfirmClose
        }

      default:
        return {}
    }
  }
  //   const handleSubmit = () => {
  //     return getDialogActionProperties().onSubmit
  //   }

  return (
    <div>
      <ConfirmModal
        isOpen={openAction}
        modalId="errorPopup"
        title={actionData?.Description}
        isCloseIcon={true}
        type={
          generatePopUpTypesFromSymbol(actionData?.Symbol) ||
          generatePopUpTypes(actionData?.MsgType)
        }
        topIcon={actionData?.Symbol ?? 'error'}
        onSubmit={() => getDialogActionProperties().onSubmit?.()}
        secondaryAction={getDialogActionProperties().onSecondaryAction}
        secondaryActionLabel={getDialogActionProperties().secondaryLabel}
        actionLabel={getDialogActionProperties().actionLabel}
        scrollDisable={true}
        onClose={() => setCreateOpen?.(false)}
        subTitle={actionData?.Alert}
        itemLabels={{ subTitle: actionData?.Data?.itemName }}
        actionLoader={actionLoader}
      />
    </div>
  )
}

import { HeaderWrapper, SectionWraper } from '@/components/app/common'
import { Breadcrumb, CustomKendoTable, DeletePopUp } from '@/components/common'
import { TableHeight } from '@/constants'
import AssignmentTypeSearch from '@/modules/crewing/components/app/registers/assignmentType/AssignmentTypeSearch'
import { ManageAssignmentType } from '@/modules/crewing/components/app/registers/assignmentType/ManageAssignmentType'
import { useAppContext } from '@/store/context/appContext'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { getErrorMessage } from '@/utilities/dataGenerators'
import { parseSearchParams } from '@/utilities/dataParse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  deleteCrewingAssignmentType,
  getCrewingAssignmentType,
  useCrewingAssignmentTypeList
} from './api'
import useCrewAssignmentTypeStore from './store'

const initData = {
  assignmentTypeId: 0
}
const globalID = 0
const CrewingAssignmentTypeList: React.FC = () => {
  const { filters, setFilters, isSearchCount, setIsSearched } =
    useCrewAssignmentTypeStore()
  const { showSnackBar } = useCustomSnackBar()
  const { t } = useTranslation()
  // const hasPermission = useHasPermission('CrewAssignmentType', 'crewing')
  const { setIsCommonLoader, setIsActionLoader, isActionLoader } = useAppContext()
  const [rowData, setRowData] = useState<any>(initData)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [assignmentTypeId, setassignmentTypeId] = useState<number>(0)
  const [createOpen, setCreateOpen] = useState(false)
  const [edit, setEdit] = useState(true)
  const [viewable, setViewable] = useState(false)
  const [assignmentTypeName, setAssignmentTypeName] = useState<string>()

  const { data, isFetching, refetch } = useCrewingAssignmentTypeList(
    {
      ...parseSearchParams(filters, [])
    },
    isSearchCount
  )

  const [total, setTotal] = useState(0)

  const breadcrumbConfig = [
    { label: t('Crewing') },
    { label: t('Registers') },
    { label: t('Crew Assignment Types') }
  ]

  useEffect(() => {
    setTotal(data?.totalRecords)
  }, [data])

  useEffect(() => {
    setIsCommonLoader(isFetching)
  }, [isFetching])

  const handleSort = (order: string) => {
    setFiltersAndUpdate({ ...filters, sortBy: order })
  }
  const setFiltersAndUpdate = (newFilters: any) => {
    setFilters(newFilters)
    setIsSearched?.()
  }
  const onSearch = (data: any) => {
    setFiltersAndUpdate({ ...filters, ...data, pageNo: 1 })
  }
  const onPagination = (row: number) => {
    setFiltersAndUpdate({ ...filters, pageNo: row })
  }

  const onRowsPerPage = (row: number) => {
    setFiltersAndUpdate({ ...filters, pageSize: row, pageNo: 1 })
  }
  const columns = [
    {
      title: t('Assignment Type'),
      field: 'assignmentTypeName',
      type: 'text',
      sort: true,
      action: true,
      render: (row: any) => (
        <div
          className="kendo-column-text-link cursor-pointer truncate"
          onClick={() => {
            manageViewEditToogle(row.assignmentTypeId, 'view')
          }}
        >
          {row?.assignmentTypeName}
        </div>
      )
    },

    {
      title: t('Core Activity'),
      field: 'coreActivityTypeName',
      type: 'text',
      sort: true
    },
    {
      title: t('Completion Activity'),
      field: 'completionActivityTypeName',
      type: 'text',
      sort: true
    },
    {
      title: t('Sea Service'),
      field: 'seaService',
      type: 'boolean',
      sort: true
    },
    {
      title: t('Archived'),
      field: 'archived',
      type: 'boolean',
      sort: true
    }
  ]

  const manageViewEditToogle = (id?: number, mode = 'edit') => {
    if (id) {
      setIsCommonLoader(true)
      try {
        getCrewingAssignmentType(id).then((res) => {
          setRowData(res)
          setIsCommonLoader(false)
          setViewable(mode === 'edit' ? false : true)
          setEdit(mode === 'edit' ? true : false)
          setCreateOpen(true)
        })
      } catch {
        setIsCommonLoader(false)
      }
    }
  }
  const handleCloseForm = () => {
    setCreateOpen(false)
    setRowData(initData)
    setEdit(false)
    setViewable(false)
  }
  const handleCreate = async () => {
    setIsCommonLoader(true)
    setViewable(false)
    const permission = true
    //    await hasPermission({
    //    UserAction: isAllow.CREATE,
    //    AccessKey: '',
    //    ID1: ''
    //  })
    setEdit(false)
    setCreateOpen(permission)
    setIsCommonLoader(false)
  }
  const handleMenuActions = async (slug: string, row: any) => {
    setassignmentTypeId(row?.assignmentTypeId)
    setIsCommonLoader(true)
    setAssignmentTypeName(row?.assignmentTypeName)
    // const permissionParams = {
    //   UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
    //   AccessKey: '',
    //   ID1: row?.assignmentTypeId
    // }
    const permission = true
    // await hasPermission(permissionParams)

    if (slug === 'delete') {
      setDeleteOpen(permission)
      setIsCommonLoader(false)
    } else if (slug === 'edit') {
      setIsCommonLoader(false)

      if (permission) {
        manageViewEditToogle(row?.assignmentTypeId)
      }
    }
  }
  const handleDelete = () => {
    setIsActionLoader(true)
    deleteCrewingAssignmentType(assignmentTypeId)
      .then((res) => {
        showSnackBar(res?.message)
        setassignmentTypeId(globalID)
        refetch()
      })
      .catch((err) => {
        showSnackBar(getErrorMessage(err), 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }

  const handleToolActions = (slug: string) => {
    if (slug === 'archived') {
      setFiltersAndUpdate({ ...filters, archived: !filters?.archived, pageNo: 1 })
    }
  }

  const getArchiveText = () => (filters?.archived ? 'Hide Archived' : 'Show Archived')

  return (
    <>
      <Breadcrumb items={breadcrumbConfig} />
      <HeaderWrapper>
        <AssignmentTypeSearch onSearch={onSearch} />
      </HeaderWrapper>
      <SectionWraper>
        <div className="px-kendo-x py-kendo-y bg-background-kendo rounded ">
          <CustomKendoTable
            columns={columns}
            data={data?.results}
            isFetching={isFetching}
            searchCount={isSearchCount}
            pagination={true}
            title="Crew Assignment Types"
            defaultSort="assignmentTypeName"
            // adjustWidth={0}
            toolBar={true}
            sortable={true}
            resizable={true}
            isDownloadable={true}
            tableHeight={`calc(100vh - ${TableHeight.OneColSearch})`}
            handleMenuActions={handleMenuActions}
            primaryAction={{
              title: t('New'),
              handlePrimaryAction: handleCreate
            }}
            handleSort={handleSort}
            actionDropDown={[
              { title: 'Edit', iconName: 'edit', slug: 'edit', id: '1' },
              {
                title: 'Delete',
                iconName: 'delete',
                slug: 'delete',
                id: '2',
                isWarning: true
              }
            ]}
            paginationProps={{
              total: total,
              currentPage: filters.pageNo as number,
              rowsPerPage: filters.pageSize as number,
              onPagination,
              onRowsPerPage
            }}
            handleToolActions={handleToolActions}
            actionToolDropDown={[
              { title: getArchiveText(), iconName: 'archieve', slug: 'archived', id: '1' }
            ]}
          />
        </div>
      </SectionWraper>
      <ManageAssignmentType
        edit={edit}
        data={rowData}
        onClose={handleCloseForm}
        open={createOpen}
        view={viewable}
        title={`${edit ? 'Edit' : viewable ? 'View' : 'Add '} Crew Assignment Type`}
        refetch={refetch}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        actionLoader={isActionLoader}
        onClose={() => setDeleteOpen(false)}
        itemLabels={{ title: 'crew assignment type', subTitle: assignmentTypeName }}
      />
    </>
  )
}

export default CrewingAssignmentTypeList

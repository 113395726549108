// To Do import { Navigate } from "store/authStore";

import { useAuthStore } from '@/store/authStore'
import React from 'react'
import { Navigate } from 'react-router-dom'
type Props = {
  children: React.ReactNode
}
const PublicRoute = ({ children }: Props) => {
  //To DO  const {authenticated}= useAuthStore()
  const { authenticated } = useAuthStore()

  return !authenticated ? <>{children}</> : <Navigate to="/dashboard" replace={true} />
}
export default PublicRoute

import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type AppStoreType = {
  isLoading: boolean | undefined
  menuOpened: boolean | undefined
  isMyVesselOpen: boolean
  setIsMyVesselOpen: (val: boolean) => void
  setIsLoading: (loaderState: boolean | undefined) => void
  setMenuOpened: (menuState: boolean | undefined) => void
}
type HeaderStore = {
  headerTitle: string
  setHeaderTitle: (data: string) => void
  moduleId: string
  setModuleId: (data: string) => void
}

export const useAppStore = create<AppStoreType>()((set) => ({
  isLoading: false,
  menuOpened: false,
  isMyVesselOpen: false,
  setIsMyVesselOpen: (val) => set(() => ({ isMyVesselOpen: val })),
  setIsLoading: (loaderState) => set(() => ({ isLoading: loaderState })),
  setMenuOpened: (menuState) => set(() => ({ menuOpened: menuState }))
}))

export const useHeaderStore = create<HeaderStore>()(
  persist(
    (set) => ({
      headerTitle: '',
      moduleId: '',
      setHeaderTitle: (val: string) => set(() => ({ headerTitle: val })),
      setModuleId: (val: string) => set(() => ({ moduleId: val }))
    }),
    {
      name: 'header-store-session',
      getStorage: () => sessionStorage
    }
  )
)
export const useExploreMenu = create<any>()(
  persist(
    (set) => ({
      exploreMenu: [],
      seExploreMenu: (val: any) => set(() => ({ exploreMenu: val }))
    }),
    {
      name: 'ex_plore__m__e__n__u',
      getStorage: () => localStorage
    }
  )
)

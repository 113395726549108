import { z } from 'zod'

export const companySchema = z.object({
  CompanyId: z.string().optional().nullable(),
  CompanyName: z
    .string({ required_error: 'Company Name is required' })
    .min(1, 'Company Name is required')
    .max(100, 'Company Name must be 100 characters or less'),
  CountryId: z.number().optional().nullable(),
  CountryName: z
    .string({ required_error: 'Country is required' })
    .min(1, 'Country is required'),
  Remarks: z.string().max(200, { message: 'Maximum 200 characters allowed' }).optional(),
  Archived: z.boolean().optional(),
  Blocked: z.boolean().optional()
})

export type CompanySchema = z.infer<typeof companySchema>

export const masterDataRouterStore: { [key: string]: string } = {
  CMS: '/mastereg/contentmanager',
  ENTITY_LIST: '/finance/entitymast',
  VESSEL_LIST: '/mastereg/vesselreg',
  MASTER_DATA_COUNTRIES: '/mastereg/countries',
  MASTER_DATA_DEPARTMENTS: '/mastereg/departments',
  MASTER_DATA_CURRENCIES: '/mastereg/currencies',
  // Companies //
  COMPANY_LIST: '/matereg/companies',
  COMPANY_DETAIL: '/matereg/companies/:companyid',
  COMPANY_DETAILS_HEADER: 'header',
  COMPANY_DETAILS_SERVICE: 'service',
  COMPANY_DETAILS_CURRENCY: 'currency',
  COMPANY_DETAILS_BRANCHES: 'branches',
  COMPANY_DETAILS_AUDIT_LOG: 'audit-log',
  //
  MASTER_DATA_DESIGNATIONS: '/mastereg/designations',
  FLEETS_LIST: '/mastereg/fleets',
  MASTER_DATA_PORTS: '/mastereg/ports',
  MASTER_DATA_WORKFLOW: '/master/workflow',
  MASTER_DATA_MAKER: '/mastereg/maker',
  MASTER_DATA_VESSEL_CLASSES: '/mastereg/vesselclasses',
  MASTER_DATA_VESSEL_STATUSES: '/mastereg/vesselstatuses',
  MASTER_REGIONS_LIST: '/mastereg/regions',
  MASTER_AUTHORITY_LIST: '/mastereg/authority',
  MASTER_DATA_SERVICE_TYPES_LIST: '/mastereg/servicetype',
  MASTER_DATA_CONSUMPTION_TYPES_LIST: '/mastereg/constype',
  MASTER_DATA_UNIT_OF_MEASUREMENTS: '/mastereg/units',
  MASTER_DATA_VESSEL_TYPES: '/mastereg/vesseltypes',
  MASTER_DATA_OIL_TYPES: '/mastereg/oiltype',
  MASTER_DATA_SULPHUR_CONTENT_TYPE: '/mastereg/sulphurconttype'
}

import MenuDropDown from '@/components/common/menudropdown/MenuDropDown'

import { useClearFilter } from '@/utilities/clearStore'

import { profileMenu } from './store'

export default function ProfileMenu() {
  // const { menuOpened } = useAppStore()
  const hadleClear = useClearFilter()

  // console.log(menuOpened)
  const handleLogout = () => {
    hadleClear()
  }
  const handleMenuAction = (slug: string) => {
    switch (slug) {
      case 'logout':
        return handleLogout()
      default:
        return null
    }
  }
  return (
    <div className="absolute right-0 top-[20px] pr-4 flex items-center justify-center">
      <MenuDropDown
        iconName="down_arrow"
        actionDropDown={profileMenu}
        handleMenuActions={handleMenuAction}
      />
    </div>
  )
}

import { CustomKendoTable } from '@/components/common'
import { defaultPageSize, TableHeight } from '@/constants'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import { LogDetails } from '@/components/common/customPopup/logDetails'
import CustomView from '@/components/common/groupedList/CustomView'
import GroupByCategory from '@/components/common/groupedList/GroupByCategory'
import CustomIcons from '@/components/common/icons/customIcons'
import { useCompanyAuditLog } from '../../api'

type PageParams = {
  pageNo: number
  ObjectId?: string
  pageSize: number
}

const CompanyAuditLog: React.FC = () => {
  const { companyId: CompanyId } = useOutletContext<any>()
  const [params, setParams] = useState<PageParams>({
    pageNo: 1,
    ObjectId: CompanyId,
    pageSize: defaultPageSize
  })
  const { data, isFetching, refetch } = useCompanyAuditLog(params)
  // Function to transform the v1 to v2, remove below code while integrating v2 api
  function transformData(data: any): any {
    const transformedResults = data?.Results?.map((log: any) => ({
      logId: log.LogId,
      logDesc: log.LogDesc,
      logDate: log.LogDate,
      userId: log.UserId,
      hostName: log.HostName,
      entityName: log.EntityName
    }))

    return {
      data: {
        pageNo: data?.PageNo,
        pageSize: data?.PageSize,
        totalRecords: data?.TotalRecords,
        noSorting: data?.NoSorting,
        noPaging: data?.NoPaging,
        results: transformedResults,
        columns: data?.Columns
      },
      message: null
    }
  }
  const datas = transformData(data)
  // transform data struture ends

  const [logDetails, setLogDetails] = useState<any>()
  const [logDetailsOpen, setLogDetailsOpen] = useState<any>()
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      ObjectId: CompanyId
    }))
  }, [CompanyId])

  useEffect(() => {
    if (datas?.data && datas?.data?.results) {
      setTotal(datas?.data?.totalRecords)
    }
  }, [datas?.data])

  useEffect(() => {
    refetch()
  }, [params])

  const getLogDetails = (data: any) => {
    setLogDetailsOpen(true)
    setLogDetails(data)
  }

  const closeLogDetails = () => {
    setLogDetailsOpen(false)
    setLogDetails({})
  }

  const columns = [
    {
      title: 'data',
      field: 'data',
      sort: true,
      render: (row: any) =>
        row.isHeader ? (
          <div
            style={{ color: '#3D414A' }}
            className="flex flex-row items-center h-38 font-normal text-sm bg-[#F8F8F8] h-[38px] pl-4"
          >
            <CustomIcons type={'medium'} name={'down_arrow'} />
            <div className="pl-1">{row.logDesc}</div>
          </div>
        ) : (
          <CustomView data={row} getLogDetails={getLogDetails} />
        )
    }
  ]

  return (
    <>
      <div className=" bg-white rounded p-2">
        <GroupByCategory
          data={datas?.data?.results || []}
          render={(groupedData: any) => (
            <div className="auditLogKendo">
              <CustomKendoTable
                key={`auditLog-${new Date().toString()}`}
                pagination={true}
                columns={columns}
                data={groupedData}
                toolBar={false}
                sortable={false}
                resizable={true}
                isFetching={isFetching}
                isDownloadable={false}
                minHeight="0px"
                tableHeight={`calc(100vh - ${TableHeight.WithOneColHeader})`}
                paginationProps={{
                  total: total,
                  currentPage: params.pageNo,
                  rowsPerPage: params.pageSize,
                  onPagination: (page) => {
                    setParams((prev) => ({
                      ...prev,
                      pageNo: page
                    }))
                  },
                  onRowsPerPage: (size) => {
                    setParams((prev) => ({
                      ...prev,
                      pageSize: size,
                      pageNo: 1
                    }))
                  }
                }}
              />
            </div>
          )}
        />
      </div>
      <LogDetails data={logDetails} open={logDetailsOpen} onClose={closeLogDetails} />
    </>
  )
}

export default CompanyAuditLog

import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'
export const generateDynamicTankSchema = (type: any) => {
  const TankSchema = z.object({
    TankTypeId: z
      .number({ required_error: 'Tank Type is required' })
      .min(1, { message: 'Tank Type is required' }),
    VesTankId: z.string().optional(),
    VesselId: z.string().optional(),
    TankTypeName: z
      .string({ required_error: 'Tank Type is required' })
      .min(1, { message: 'Tank Type is required' })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      }),

    Compartment: z
      .string({ required_error: 'Compartment is required' })
      .min(1, { message: 'Compartment is required' })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      }),
    Remarks: z
      .string()
      .max(500, { message: 'Remarks must be 500 characters or less' })
      .optional()
      .nullable(),
    Volume: z
      .number()
      .nullable()

      .optional(),
    ...(type.RecWeight === 2
      ? {
          Weight: z.number({ required_error: 'Weight is required' })
        }
      : {
          Weight: z
            .number()
            .nullable()

            .optional()
        }),
    ...(type.RecVol98 === 2
      ? {
          Volume98: z.number({ required_error: 'Volume 98 is required' })
        }
      : {
          Volume98: z.number().nullable().optional()
        }),
    ...(type.RecVol95 === 2
      ? {
          Volume95: z.number({ required_error: 'Volume 95 is required' })
        }
      : {
          Volume95: z
            .number()
            .nullable()

            .optional()
        }),
    ...(type.RecDesnity === 2
      ? {
          Density: z.number({ required_error: 'Density is required' })
        }
      : {
          Density: z
            .number()
            .nullable()

            .optional()
        }),
    SteamCoilTypeId: z.number().optional().nullable(),
    ...(type.RecSteamCoil === 2
      ? {
          SteamCoilTypeName: z
            .string({ required_error: 'Steam coil type is required' })
            .min(1, { message: 'Steam coil type is required' })
            .transform((value) => (value === '' ? null : value))
            .refine(noLeadingSpaces, {
              message: 'Leading spaces are not allowed'
            })
        }
      : {
          SteamCoilTypeName: z
            .string()
            .nullable()
            .transform((value) => (value === '' ? null : value))
            .refine(noLeadingSpaces, {
              message: 'Leading spaces are not allowed'
            })
            .optional()
        }),
    ...(type.RecLevelGauge === 2
      ? {
          LevelGaugeTypeName: z

            .string({ required_error: 'Level guage type is required' })
            .min(1, { message: 'Level guage type is required' })
            .transform((value) => (value === '' ? null : value))
            .refine(noLeadingSpaces, {
              message: 'Leading spaces are not allowed'
            })
            .optional()
        }
      : {
          LevelGaugeTypeName: z
            .string()
            .nullable()
            .transform((value) => (value === '' ? null : value))
            .refine(noLeadingSpaces, {
              message: 'Leading spaces are not allowed'
            })
            .optional()
        }),
    LevelGaugeTypeId: z.number().optional().nullable()
  })

  return TankSchema
}

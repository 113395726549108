import PresetColorSelect from '@/components/common/inputs/PresetColor'

const PresetColorsComponent = () => {
  return (
    <form>
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="py-3">
          <div className="flex p-2">
            <PresetColorSelect name="color" disabled={false} />
          </div>
        </div>
      </div>
    </form>
  )
}

export default PresetColorsComponent

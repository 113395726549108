import { getRegister } from '@/apis/common.api'
import { useState } from 'react'

interface DataStore {
  [key: string]: any[]
}

const useDataStore = () => {
  const [dataStore, setDataStore] = useState<DataStore>({})

  const getData = async (key: string, apiParams: any, desc?: string): Promise<any> => {
    return await getRegister({ ...apiParams, desc: desc ?? '*' }).then((resps) => {
      const mappedData = resps.map((data: any) => ({
        name: data.Description,
        id: data.Id,
        param1: data.Param1,
        param2: data.Param2
      }))
      setDataStore((prevDataStore) => ({
        ...prevDataStore,
        [key]: mappedData
      }))
      return mappedData
    })
  }

  return { getData, dataStore }
}

export default useDataStore

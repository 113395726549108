import PermissionDialoug from '@/components/app/common/PermissionDialog'
import MyVessel from '@/components/app/Dashboard/MyVessel'
import { Loader, Portal } from '@/components/common'
import Header from '@/layout/components/header'
// import { useAppStore } from '@/store/appStore'
import React from 'react'

import { useAppContext } from '@/store/context/appContext'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import Footer from './components/footer'
import ProfileMenu from './components/ProfileMenu'
import SideNav from './components/sidenav'

type Props = {
  children: React.ReactNode
}
export default function Layout({ children }: Props) {
  const { isMobile, isTab } = useScreenBreakPoint()
  // const { isLoading } = useAppStore()
  const { isCommonLoader } = useAppContext()
  return (
    <div className="flex h-[100dvh]">
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <div className="flex h-full overflow-hidden">
          <SideNav />
          <PermissionDialoug />

          <main className="max-h-[calc(100dvh-54px)] md:max-h-[calc(100dvh-85px)] scroll-smooth mt-[54px] h-full flex flex-col w-full bg-background overflow-y-auto">
            <Portal>
              <Loader isActive={isCommonLoader} />
            </Portal>
            {children}
            <MyVessel />
            {!isMobile && !isTab && <ProfileMenu />}
          </main>
        </div>
        <Footer />
      </div>
    </div>
  )
}

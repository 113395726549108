import { TabBreadcrumbProps } from '@/common/customComponenttypes'
import React from 'react'
import { Link } from 'react-router-dom'
import CustomIcons from '../../icons/customIcons'

const TabBreadcrumb: React.FC<TabBreadcrumbProps> = ({ items, onClose, fontType }) => {
  return (
    <div
      className={`${fontType === 'large' ? 'py-0' : 'py-1.5'} flex justify-between flex-row `}
    >
      <div className="flex">
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="inline-flex items-center ">
            {items.map((item, index) => (
              <li className="inline-flex items-center " key={index}>
                {index === items.length - 1 ? (
                  <span
                    className={`${fontType === 'large' ? ' text-m' : 'text-xxs'}  font-medium text-text-primary leading-5`}
                  >
                    {item.label}
                  </span>
                ) : (
                  <Link
                    className={`${item.link ? 'cursor-pointer' : 'cursor-text'} ${fontType === 'large' ? ' text-m' : 'text-xxs'}  font-normal text-text-secondary  text leading-5`}
                    to={item.link ?? ''}
                  >
                    {item.label}
                  </Link>
                )}
                {index < items.length - 1 && (
                  <span className="mx-1 text-input text-grey-dark md:mx-1">
                    <CustomIcons
                      name={'slashIcon'}
                      type="medium"
                      className="text-[#FF681D]"
                    />
                  </span>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
      {onClose && (
        <div className="flex">
          <button
            type="button"
            className="close-button text-gray-400 hover:bg-gray-200 rounded-full  dark:hover:bg-gray-600 hover:text-gray-900 rounded-lg text-sm w-[34px] h-[34px] ml-auto inline-flex justify-center items-center "
            data-modal-hide="popup-modal"
            onClick={onClose}
            data-testid="close-icon"
          >
            <CustomIcons
              name="close"
              type="large-m"
              className="flex items-center justify-center   "
              data-testid="button-icon-left"
            />
          </button>
        </div>
      )}
    </div>
  )
}
export default TabBreadcrumb

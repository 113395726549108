import { Button } from '@/components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Unauthorized: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/')
  }
  return (
    <div className="w-full h-screen bg-background flex items-center justify-center">
      <div className="flex flex-col md:flex-row p-3 md:p-7">
        <div className="text-center px-2 md:px-10 text-grey-medium text-common font-medium">
          <img src="/assets/401.png" alt="" className="w-3/6 md:w-2/6 mx-auto mb-6" />
          <h1 className="text-[34px] md:text-7xl text-secondary pb-1 leading-10 my-3 md:my-0 md:leading-normal">
            {t('401Error')}
          </h1>
          <p className="mx-auto text-slug-text-errors py-2 font-normal">
            {t('unauthorizedSlogan')}
          </p>
          <div className="flex justify-center p-4">
            <Button label={t('backToHome')} outlined={true} onClick={handleBack} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Unauthorized

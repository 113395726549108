import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const NationalityFlagIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <path
      d="M3 11.25C3 11.25 3.75 10.5 6 10.5C8.25 10.5 9.75 12 12 12C14.25 12 15 11.25 15 11.25V3C15 3 14.25 3.75 12 3.75C9.75 3.75 8.25 2.25 6 2.25C3.75 2.25 3 3 3 3M3 16.5L3 1.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}

export default NationalityFlagIcon

import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'
const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/
//const loginIDRegex = /^[a-zA-Z0-9]*$/
const loginIDRegex = /^[ A-Za-z0-9@.]*$/

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png']
const MAX_FILE_SIZE = 307200
export const UserFormSchema = z.object({
  title: z
    .string({
      required_error: 'Title is required',
      invalid_type_error: 'Title is required'
    })
    .min(1, { message: 'Title is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  firstName: z
    .string({
      required_error: 'First Name is required',
      invalid_type_error: 'First Name is required'
    })
    .min(1, { message: 'First Name is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  lastName: z
    .string({
      required_error: 'Last Name is required',
      invalid_type_error: 'Last Name is required'
    })
    .min(1, { message: 'Last Name is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  loginId: z
    .string({
      required_error: 'Login ID is required',
      invalid_type_error: 'Login ID is required'
    })
    .regex(
      loginIDRegex,
      ' Only alphanumeric characters (A-Z, a-z, 0-9, @, .) are allowed.'
    )
    .min(6, { message: 'Login ID should be atleast 6 characters' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),

  userTypeName: z
    .string({
      required_error: 'User Type is required',
      invalid_type_error: 'User Type is required'
    })
    .min(1, { message: 'User Type is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  userType: z.number().optional().nullable(),
  email: z
    .string({
      required_error: 'Email is required',
      invalid_type_error: 'Email is required'
    })
    .email({ message: 'Enter a vaild email' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  mobile: z
    .string()
    .transform((val) => (val === '' ? undefined : val))
    .refine(
      (val) => val === undefined || val === null || phoneRegex.test(val),
      'Invalid phone Number!'
    )
    .optional()
    .nullable()
    .refine(
      (val) => val === undefined || val === null || (val.length >= 6 && val.length <= 15),
      {
        message: 'Phone number must be between 6 and 15 characters long.'
      }
    ),

  roleName: z
    .string({
      required_error: 'Role is required',
      invalid_type_error: 'Role is required'
    })
    .min(1, { message: 'Role is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  sysRoleId: z
    .string({
      required_error: 'Role is required',
      invalid_type_error: 'Role is required'
    })
    .min(1, { message: 'Role is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),

  departmentId: z.number().optional().nullable(),
  userId: z.string().optional().nullable(),
  inactive: z.boolean().optional(),
  resetPswd: z.boolean().optional(),
  validated: z.boolean().optional(),
  departmentName: z

    .string({
      required_error: 'Department is required',
      invalid_type_error: 'Department is required'
    })
    .min(1, { message: 'Department is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  designationId: z.number().optional().nullable(),
  expiryDate: z.date().nullable().optional(),
  designationName: z

    .string({
      required_error: 'Designation is required',
      invalid_type_error: 'Designation is required'
    })
    .min(1, { message: 'Designation is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  file: z
    .union([z.instanceof(File), z.any()])
    .refine((file) => !(file instanceof File) || file.size <= MAX_FILE_SIZE, {
      message: 'Maximum size 5mb'
    })
    .refine(
      (file) => !(file instanceof File) || ACCEPTED_IMAGE_TYPES.includes(file.type),
      {
        message: 'File type not allowed'
      }
    )
    .nullable()
})

export type UserFormDataType = z.infer<typeof UserFormSchema>

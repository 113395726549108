import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { usePermissionStore } from '@/store/permission'

export default function PermissionDialoug() {
  const { isOpenDialoug, content, setIsOpenDialoug } = usePermissionStore()
  //permission
  return (
    <div>
      <ConfirmModal
        isOpen={isOpenDialoug}
        modalId="errorPopup"
        title={content.title}
        isCloseIcon={true}
        type="error"
        topIcon="error"
        actionLabel="OK"
        onSubmit={() => setIsOpenDialoug(false)}
        scrollDisable={true}
        subTitle={content.subTitle}
      />
    </div>
  )
}

export const operationsRouterStore: { [key: string]: string } = {
  //registers start
  CARGO_TYPE: '/ops/cargotype',
  SPEED_TYPE: '/ops/speedtype',
  VOYAGE_TYPE: '/ops/voytypes',
  HARBOUR_POS: '/ops/harbourpos',
  DISCHARGE_SUB_CAT: 'oprtn/reg/dischrgsubcat',
  PORT_ACTIVITY: '/ops/portact',
  VOYAGE_STATUS: '/ops/voystatus',
  HALOCARBON_TYPES: 'ops/halocarbtype',
  DISCHARGE_METHOD: '/oprtn/reg/dischrgmethod',
  DISCHARGE_ITEMS: 'oprtn/reg/dischrgitem',
  LOAD_CONDITIONS: 'ops/loadcond',
  HALO_CARBONS: 'ops/halocarb',
  DISCHARGE_CATEGORIES: '/oprtn/reg/dischrgcat',
  DATA_POINTS: '/oprtn/maint/vygrptconfg',
  VOYAGE_REPORT_TYPES: '/ops/voyreptype',
  VOYAGE_REPORT_CONFIG: '/operations/maintenance/voyrepconfig',
  VOYAGE_REPORT_CONFIG_VALIDATION: '/operations/maintenance/voyrepconfig/valid'

  //registers end
}

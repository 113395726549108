import { TableProps } from '@/common/customComponenttypes'
import { defaultPageSize } from '@/constants'
import useTableColumnWidth from '@/hooks/useTableColumnWidth'
import { useAppContext } from '@/store/context/appContext'
import { generateKendoAlignment } from '@/utilities'
import { getter, orderBy, SortDescriptor, State } from '@progress/kendo-data-query'
import { getSelectedState } from '@progress/kendo-react-data-tools'
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridHeaderCellProps,
  GridHeaderSelectionChangeEvent,
  GridNoRecords,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Loader from '../loader/PageLoader'
import NoRecordsFound from './NoRecords'
import Toolbar from './Toolbar'
import KendoExport, { ExportRef } from './utils/export'
import useKendoHelper from './utils/helpers'

type PageParams = {
  pageNo: number
  pageSize: number
  sortBy: string
}

export default function CustomKendoTable({
  columns,
  data = [],
  async = true,
  actionDropDown,
  actionDropDownParams,
  singleSelection,
  multiSelection,
  handleSort,
  toolBar,
  pagination,
  paginationProps = {
    total: 0,
    rowsPerPage: 20,
    currentPage: 1,
    onPagination: (row: number, page: number) => {},
    onRowsPerPage: (row: number) => {}
  },
  tableHeight,
  minHeight,
  handleMenuActions,
  handleMultiSelect,
  setCheckedKeys,
  handleSingleSelect,
  handleSearchValue,
  primaryAction,
  closeAction,
  defaultSort,
  staticMenus,
  isFetching,
  tableKey,
  checkedKeys: propCheckedKeys,
  rowKey,
  selectedKey: propSelectedKey,
  adjustWidth = 17,
  workflowParams,
  isDownloadable,
  resizable = false,
  reorderable = false,
  sortable,
  filterable,
  exportFileName,
  title,
  renderSearch,
  renderToolAction,
  version,
  handleToolActions,
  actionToolDropDown,
  exportPaperSize,
  searchCount = 2,
  inlineLoader = false,
  ...rest
}: TableProps) {
  const gridCustomRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<any>(null)
  const gridOuterRef = useRef<any>(null)
  const exportRef = useRef<ExportRef>(null)
  const { CustomRender } = useKendoHelper({
    columns,
    gridRef,
    workflowParams,
    handleMenuActions,
    staticMenus,
    actionDropDown,
    actionDropDownParams,
    version
  })
  const [hasVerticalScroll, setHasVerticalScroll] = useState<boolean>(false)

  const [params, setPrams] = useState<PageParams>({
    pageNo: paginationProps.currentPage,
    pageSize: paginationProps.rowsPerPage,
    sortBy: defaultSort ?? ''
  })
  const [dataState, setDataState] = useState<State>({
    take: paginationProps?.rowsPerPage ?? defaultPageSize,
    skip: (paginationProps.currentPage - 1) * paginationProps?.rowsPerPage ?? 20
  })

  const [sort, setSort] = useState<SortDescriptor[]>(
    defaultSort ? [{ field: defaultSort, dir: 'asc' }] : []
  )
  const idGetter = getter(rowKey ?? '')
  const SELECTED_FIELD = 'selected'
  const [selectedState, setSelectedState] = useState<any>({})

  useEffect(() => {
    const checkForVerticalScroll = () => {
      if (gridOuterRef.current) {
        const gridElement = gridOuterRef.current?.containerRef.current
        const hasScroll =
          gridElement && gridElement.clientHeight < gridElement.scrollHeight
        setHasVerticalScroll(hasScroll)
      }
    }
    checkForVerticalScroll()
    window.addEventListener('resize', checkForVerticalScroll)
    return () => window.removeEventListener('resize', checkForVerticalScroll)
  }, [data])

  const cellWidth = useTableColumnWidth(
    columns,
    adjustWidth,
    gridCustomRef,
    false,
    Boolean(singleSelection || multiSelection)
  )

  useEffect(() => {
    if (propCheckedKeys && multiSelection) {
      const newSelectedState = propCheckedKeys.reduce(
        (acc: any, identifier: string | number) => {
          acc[identifier] = true
          return acc
        },
        {}
      )
      setSelectedState(newSelectedState ?? {})
    } else if (propSelectedKey && singleSelection) {
      const newSelectedState = { [propSelectedKey.toString()]: true }
      setSelectedState(newSelectedState ?? {})
    } else {
      setSelectedState({})
    }
  }, [propCheckedKeys, propSelectedKey])

  useEffect(() => {
    if (!gridOuterRef.current) return
    const gridElement = gridOuterRef.current.element

    const handleMouseEnter = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (!target.querySelector('.k-sort-icon')) {
        const sortIcon = document.createElement('span')
        sortIcon.classList.add('k-sort-icon', 'custom-sort-icon')
        sortIcon.innerHTML =
          '<span class="k-icon k-svg-icon k-svg-i-sort-asc-small" aria-hidden="true"><svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 192v224h-32V192h-96L240 64l112 128z"></path></svg></span>'
        target.querySelector('.k-link')?.appendChild(sortIcon)
      }
    }

    const handleMouseLeaveAndClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const sortIcon = target.querySelector('.custom-sort-icon')
      if (sortIcon) {
        sortIcon.remove()
      }
    }

    gridElement.querySelectorAll('.k-header').forEach((headerCell: HTMLElement) => {
      headerCell.addEventListener('mouseenter', handleMouseEnter)
      headerCell.addEventListener('mouseleave', handleMouseLeaveAndClick)
      headerCell.addEventListener('click', handleMouseLeaveAndClick)
    })

    return () => {
      gridElement.querySelectorAll('.k-header').forEach((headerCell: HTMLElement) => {
        headerCell.removeEventListener('mouseenter', handleMouseEnter)
        headerCell.removeEventListener('mouseleave', handleMouseLeaveAndClick)
        headerCell.removeEventListener('click', handleMouseLeaveAndClick)
      })
    }
  }, [])

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: rowKey ?? ''
      })
      setSelectedState(newSelectedState)
      if (singleSelection && handleSingleSelect) {
        if (event.dataItem) handleSingleSelect(event.dataItem)
      } else if (multiSelection) {
        const selKey = Object.keys(newSelectedState).filter(
          (key) => newSelectedState[key]
        )
        setCheckedKeys?.(selKey)
        handleMultiSelect?.(selKey)
      }
    },
    [selectedState]
  )

  const onHeaderSelectionChange = useCallback((event: GridHeaderSelectionChangeEvent) => {
    const checkboxElement: any = event.syntheticEvent.target
    const checked = checkboxElement.checked
    const newSelectedState: any = {}

    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked
    })
    setSelectedState(newSelectedState)
    const selKey = Object.keys(newSelectedState).filter((key) => newSelectedState[key])
    setCheckedKeys?.(selKey)
    handleMultiSelect?.(selKey)
  }, [])

  useEffect(() => {
    if (async) {
      if (params.pageSize !== paginationProps.rowsPerPage) {
        paginationProps.onRowsPerPage?.(params.pageSize)
      } else if (params.pageNo !== paginationProps.currentPage) {
        paginationProps.onPagination?.(params.pageNo, params.pageSize)
      } else if (params.sortBy !== defaultSort) {
        handleSort?.(params.sortBy)
      }
    }
  }, [params])

  const onDownload = (slug: string) => {
    exportRef?.current?.download(slug)
  }

  const headerCellRender = (td: any, props: GridHeaderCellProps) => {
    if (props.field === SELECTED_FIELD && singleSelection) {
      return undefined
    }
    return td
  }

  const currentTableData = useMemo(() => {
    const firstPageIndex = (paginationProps.currentPage - 1) * paginationProps.rowsPerPage
    const lastPageIndex = firstPageIndex + paginationProps.rowsPerPage
    return data?.slice(firstPageIndex, lastPageIndex)
  }, [data, paginationProps.currentPage, paginationProps.rowsPerPage])

  const mainData = async ? data : currentTableData

  const getSortData = (data: any) => {
    return orderBy(data, sort)
  }

  const handleSortChange = (event: GridSortChangeEvent) => {
    setSort(event.sort)
    setDataState((prev) => ({
      ...prev,
      sort: event.sort
    }))
  }

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState)
    const size = e.dataState?.take ?? paginationProps?.rowsPerPage
    const pgNo = e.dataState?.skip ? (e.dataState?.skip + size) / size : 1
    let sort = '' //params.sortBy
    if (e.dataState?.sort?.[0]) {
      sort = `${e.dataState?.sort[0].field} ${e.dataState?.sort[0].dir}`
    }
    setPrams((prev) => ({
      ...prev,
      pageSize: size,
      pageNo: pgNo,
      sortBy: sort
    }))
  }

  const getData = () => {
    const take = dataState?.take ?? defaultPageSize
    const skip = dataState.skip ?? 20
    const mainData = async ? data : getSortData(data).slice(skip, take + skip)
    const mainDataNew = mainData.map((item: any) => ({
      ...item,
      [SELECTED_FIELD]: selectedState ? selectedState[idGetter(item)] : undefined
    }))
    return { data: mainDataNew, total: paginationProps.total ?? 0 }
  }

  const getHeaderClassName = (align: string) => {
    return generateKendoAlignment(align || 'center')
  }

  const getPagerOptions = () => {
    if (paginationProps.total > 0) {
      return {
        pageSizes: paginationProps?.dropOptions ?? [10, 20, 50, 100, 200],
        pageSizeValue:
          params?.pageSize !== paginationProps?.rowsPerPage && params.pageSize > 0
            ? params.pageSize
            : paginationProps?.rowsPerPage ?? defaultPageSize,
        buttonCount: 5
      }
    }
    return false
  }

  const getColumnOptions = (column: any) => {
    if (column.render || column.action) return CustomRender
    else if (column.type === 'boolean') return CustomRender
    else if (column.type === 'status') return CustomRender
    else if (column.type === 'amount') return CustomRender
    else if (
      column.type === 'date' ||
      column.type === 'dateTime' ||
      column.type === 'dateSec'
    )
      return CustomRender
    return undefined
  }

  const getTotalWidth = () => {
    const width = gridOuterRef?.current?.tableElement?.offsetWidth - 5
    // const height = gridOuterRef?.current?.containerRef?.current?.offsetHeight
    return {
      width: width ? width + 'px' : '100%',
      // height: height ? height + 'px' : '100%',
      clientWidth: `${gridOuterRef?.current?.containerRef?.current?.clientWidth - 5}px`
    }
  }

  const setHeaderSelection = () => {
    return getData().data.findIndex((item: any) => !selectedState[idGetter(item)]) === -1
  }

  const setNotFound = () => {
    return (
      <NoRecordsFound
        style={{ width: getTotalWidth()?.width }}
        viewPortWidth={getTotalWidth()?.clientWidth}
        gridRef={gridOuterRef}
        searchCount={searchCount}
      />
    )
  }
  const { setIsCommonLoader, isCommonLoader } = useAppContext()
  useEffect(() => {
    if (!inlineLoader) setIsCommonLoader(isFetching ?? false)
  }, [isFetching])

  useEffect(() => {
    setDataState((prev) => ({
      ...prev,
      skip: (paginationProps.currentPage - 1) * paginationProps?.rowsPerPage ?? 20
    }))
  }, [paginationProps.currentPage])

  const gridBody = (
    <Grid
      style={{ minHeight: minHeight ?? '300px', height: tableHeight, width: '100%' }}
      className={`custom-kendo-grid ${hasVerticalScroll ? '' : 'no-vertical-scroll'} ${singleSelection || multiSelection ? 'no-border-grid' : ''}`}
      filterable={filterable}
      sortable={sortable}
      sort={sort ?? undefined}
      skip={dataState.skip}
      take={dataState.take}
      total={paginationProps.total ?? 0}
      pageable={getPagerOptions()}
      data={getData()}
      {...dataState}
      onDataStateChange={dataStateChange}
      ref={gridOuterRef}
      resizable={resizable}
      reorderable={reorderable}
      onSortChange={!async ? handleSortChange : undefined}
      dataItemKey={rowKey}
      selectedField={singleSelection || multiSelection ? SELECTED_FIELD : undefined}
      selectable={
        singleSelection || multiSelection
          ? {
              enabled: false,
              drag: false,
              cell: false,
              mode: singleSelection ? 'single' : 'multiple'
            }
          : undefined
      }
      onSelectionChange={
        singleSelection || multiSelection ? onSelectionChange : undefined
      }
      headerCellRender={singleSelection || multiSelection ? headerCellRender : undefined}
      onHeaderSelectionChange={multiSelection ? onHeaderSelectionChange : undefined}
    >
      {toolBar && (
        <GridToolbar className="px-0">
          <Toolbar
            columns={columns}
            title={title}
            primaryAction={primaryAction}
            closeAction={closeAction}
            onDownload={onDownload}
            isDownloadable={isDownloadable}
            handleSearchValue={handleSearchValue}
            renderSearch={renderSearch}
            renderToolAction={renderToolAction}
            handleToolActions={handleToolActions}
            actionToolDropDown={actionToolDropDown}
            {...rest}
            data={mainData}
          />
        </GridToolbar>
      )}
      <GridNoRecords>
        {!isFetching && !isCommonLoader ? (
          setNotFound()
        ) : (
          <>{inlineLoader && <Loader isActive={isFetching} />}</>
        )}
      </GridNoRecords>
      {(singleSelection || multiSelection) && (
        <GridColumn
          field={SELECTED_FIELD}
          width="50px"
          resizable={false}
          sortable={false}
          locked={true}
          headerSelectionValue={isFetching ? false : setHeaderSelection()}
        ></GridColumn>
      )}
      {columns.map((column, index) => {
        return (
          <GridColumn
            field={column.field}
            title={column.title}
            key={index}
            cell={getColumnOptions(column)}
            width={column.width || cellWidth}
            locked={column.locked}
            sortable={column.sort}
            resizable={column.resizable}
            headerClassName={
              column.type === 'boolean' ? getHeaderClassName(column?.align) : undefined
            }
            minResizableWidth={column.width || 150}
          />
        )
      })}
    </Grid>
  )

  return (
    <div className="relative custom-kendo-table" ref={gridCustomRef}>
      {gridBody}
      {isDownloadable && (
        <>
          <KendoExport
            data={getData()}
            columns={columns}
            title={title}
            exportFileName={exportFileName}
            CustomRender={CustomRender}
            ref={exportRef}
            paperSize={exportPaperSize}
          />
        </>
      )}
    </div>
  )
}

import { TabItemProps } from '@/common/customComponenttypes'
import { Tab, TabContainer } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import AdjustBudgetFigure from '@/modules/technical/components/app/vesselBudget/budgetDetails/AdjustBudgetFigure'
import { VesselSummary } from '@/modules/technical/components/app/vesselBudget/budgetDetails/VesselSummary'
import {
  AdjustBudgetSchema,
  adjustBudgetSchema
} from '@/modules/technical/pages/vesselBudget/details/budgetdetails/adjustBudgetSchema'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'

import { AccountSummary } from '../../../../components/app/vesselBudget/budgetDetails/AccountSummary'
import TableTreeRow from '../../../../components/app/vesselBudget/budgetDetails/treeNode'
import { adjustBudgetAmount, getTreeMenu } from './api'

type OutletProps = {
  detailData: any
  BudgetId: string
  VesselID: string
  handleRefresh: () => void
}
const actions = [
  {
    title: 'Adjust Amount by %',
    iconName: 'CalendarIcon',
    id: '1',
    slug: 'percent'
  }
]
const VesselBudgetDetails: React.FC = () => {
  const { BudgetId, detailData } = useOutletContext<OutletProps>()
  const [budgetData, setBudgetData] = useState<any>([])
  const [activeNode, setActiveNode] = useState<any>(null)
  const [BudgetIID, setBudgetIID] = useState<any>()
  const [BudgetPID, setBudgetPID] = useState<any>()
  const [postingType, setPostingTpye] = useState<any>()
  const [TableTitle, setTableTitle] = useState<any>()
  const [openPopup, setOpenPopup] = useState<any>()
  const [isMouseOut, setIsMouseOut] = useState(false)
  const [treeNode, setTreeNode] = useState<any>()
  const [updateBudget, setUpdateBudgetAmount] = useState<any>('')
  const [childValue, setChildValue] = useState<any>()
  const [finalId, setFinalId] = useState<any>()
  const [titleFinal, setTitleFinal] = useState<any>()

  const generateBudgetObj = (newData: any, parentId: any) => ({
    ...newData,
    Name: newData?.accountDescription,
    hasChild: newData?.childCount > 0 ? true : false,
    expand: false
  })

  const handleBudgetData = async () => {
    setBudgetIID(BudgetId)
    const { data } = await getTreeMenu({
      BudgetId: BudgetId
    })
    setTableTitle('Account wise Details')
    return data
  }

  useEffect(() => {
    handleGetData()
  }, [])

  const handleGetData = async (fromAdjust?: boolean) => {
    const data = await handleBudgetData()
    if (budgetData.length > 0) {
      setBudgetData((prevData: any) => {
        const updatedData = [
          ...data.map((newData: any) => {
            const matchingData = prevData.find(
              (oldData: any) => oldData.budgetAccountId === newData.budgetAccountId
            )
            return matchingData
              ? {
                  ...matchingData,
                  ...newData,
                  hasChild: data.some(
                    (child: any) => child.parentId === newData.budgetAccountId
                  )
                }
              : {
                  ...newData,
                  hasChild: data.some(
                    (child: any) => child.parentId === newData.budgetAccountId
                  )
                }
          })
        ]

        return updatedData
      })
    } else {
      checkHasChild(data)
    }
  }

  const checkHasChild = (data: any) => {
    const newData = data.map((item: any) => ({
      ...item,
      hasChild: data.some((child: any) => child.childCount > 0)
    }))
    setBudgetData(newData)
  }

  const handleGetBudgetData = async (node: any, expand?: boolean) => {
    try {
      const budgetChildData = await getTreeMenu({
        BudgetId: BudgetIID,
        parentId: node?.budgetAccountId || null,
        postingType: 1
      })

      if (budgetChildData?.data?.length > 0 && expand) {
        const data = budgetData?.map((item: any) =>
          node?.budgetAccountId === item?.budgetAccountId
            ? { ...item, isOpen: true, expand: expand, isActive: true }
            : { ...item, isActive: false }
        )

        const filteredData = data.filter(
          (oldData: any) => oldData.parentId !== node?.budgetAccountId
        )
        const updatedData = [
          ...filteredData,
          ...budgetChildData.data.map((newData: any) =>
            generateBudgetObj(newData, node?.budgetAccountId)
          )
        ]

        setBudgetData(updatedData)
      } else if (budgetChildData?.data?.length > 0) {
        setBudgetData((prevData: any) => {
          const data = prevData.map((item: any) =>
            node?.budgetAccountId === item?.budgetAccountId
              ? { ...item, hasChild: true, isOpen: true, expand: true, isActive: true }
              : { ...item, isActive: false }
          )

          const filteredData = data.filter(
            (oldData: any) => oldData.parentId !== node?.budgetAccountId && node
          )

          const updatedData = [
            ...filteredData,
            ...budgetChildData.data.map((newData: any) =>
              generateBudgetObj(newData, node?.budgetAccountId)
            )
          ]
          return updatedData
        })
      } else {
        if (!expand) {
          setBudgetData((prevData: any) => {
            const data = prevData.map((item: any) =>
              node?.budgetAccountId === item?.budgetAccountId
                ? {
                    ...item,
                    hasChild: item.childCount > 0,
                    isOpen: false,
                    expand: true
                  }
                : item
            )

            const filteredData = data.filter(
              (oldData: any) => oldData.parentId !== node?.budgetAccountId
            )

            const updatedData = [
              ...filteredData,
              ...budgetChildData.data.map((newData: any) =>
                generateBudgetObj(newData, node?.budgetAccountId)
              )
            ]
            return updatedData
          })
        } else {
          const data = budgetData?.map((item: any) =>
            node?.budgetAccountId === item?.budgetAccountId
              ? { ...item, hasChild: false, isActive: true }
              : { ...item, isActive: false }
          )
          setBudgetData(data)
        }
      }
    } catch (error) {
      console.error('Error fetching vessel budget data: ', error)
      // Handle error if needed
    }
  }
  const handleExternalExpand = (node: any) => {
    if (node?.postingType === 1) {
      const nodIdx = budgetData.findIndex(
        (item: any) => item.budgetAccountId === node.budgetAccountId
      )
      handleTreeExpand(nodIdx, node, true)
    }
  }

  const handleTreeExpand = (nodeIndex: any, node: any, from: boolean = false) => {
    setChildValue('1')
    setActiveNode(node)
    if (!node?.expand) {
      handleGetBudgetData(node, true)
    } else {
      const data = budgetData?.map((item: any, index: number) =>
        index === nodeIndex && item.hasChild
          ? { ...item, isOpen: !item.isOpen, isActive: true }
          : { ...item, isActive: false }
      )
      setBudgetData(data)
    }

    if (from) setTableTitle(node.accountId + ' : ' + node.accountDescription)
    else setTableTitle(node.accountDescription)

    setBudgetPID(node.budgetAccountId)
    setPostingTpye(node.postingType)
  }

  const handleTitleActions = (node: any) => {
    const nodIdx = budgetData.findIndex(
      (item: any) => item.budgetAccountId === node.budgetAccountId
    )
    handleTreeExpand(nodIdx, node, false)
  }

  const limitText = (text: any, max: any) => {
    return text.length > max ? `${text.slice(0, max)}...` : text
  }

  const renderTree = (item: any, level: number = 0): React.ReactNode =>
    budgetData?.map((node: any, index: number) => (
      <React.Fragment key={index}>
        {item.budgetAccountId === node?.parentId && (
          <TableTreeRow
            key={index}
            label={t(limitText(node.accountDescription, 23))}
            node={node}
            level={level}
            data={budgetData}
            treeAction={'three_dot_horizontal'}
            nodeId={index}
            setIsMouseOut={(val) => setIsMouseOut(val)}
            isMouseOut={isMouseOut}
            handleTitleActions={handleTitleActions}
            handleMenuActions={handleMenuActions}
            actionDropDown={actions}
            nodeIcon={'folder'}
            handleTreeExpand={handleTreeExpand}
          >
            {node.isOpen && renderTree(node, level + 1)}
          </TableTreeRow>
        )}
      </React.Fragment>
    ))

  const [active, setActive] = useState<string | number>(1)

  const tabData = [
    { label: 'Account summary', id: 1 },
    { label: 'Vessel wise summary', id: 2 }
  ]

  const onTabClick = (item: TabItemProps) => {
    setActive(item.id)
    if (childValue === 2) {
      setPostingTpye(2)
    }
  }

  const handleChildValue = (value: any) => {
    setChildValue(value.postingType)
    setFinalId(value.budgetAccountId)
    setTitleFinal(value.accountDescription)
  }
  const handleMenuActions = (slug: any, _: any, node: any) => {
    setTreeNode(node)
    setOpenPopup(true)
  }

  const { showSnackBar } = useCustomSnackBar()
  const methods = useForm<AdjustBudgetSchema>({
    resolver: zodResolver(adjustBudgetSchema),
    mode: 'onChange',
    defaultValues: { percentage: 0 }
  })
  const handleClose = () => {
    setOpenPopup(false)
    methods.reset({ percentage: 0 })
  }
  const handleUpdateData = (id: string, percentage: any) => {
    let data = budgetData
    let amount = '0'
    setBudgetPID('')
    data.forEach((item: any) => {
      if (item.budgetAccountId === id) {
        amount = (
          parseFloat(item.budgetAmount) +
          (parseFloat(item.budgetAmount) * parseFloat(percentage)) / 100
        ).toFixed(2)
        item['isActive'] = true
        item['isOpen'] = true
        item['budgetAmount'] = amount
      }
    })
    setBudgetPID(id)
    setBudgetData(data)
    setTimeout(() => {
      const node: any = data?.find((item: any) => item.budgetAccountId === id)
      setActiveNode(node)
      setTableTitle(node.accountDescription)
      setUpdateBudgetAmount(amount)
    }, 1000)
  }

  const handleAdjustBudgetAmount = async (data: any) => {
    const params = {
      budgetId: BudgetIID,
      budgetAccountId: treeNode.budgetAccountId,
      percentage: data.percentage
    }
    adjustBudgetAmount(params)
      .then((res) => {
        showSnackBar(res.message, 'success')
        handleUpdateData(treeNode.budgetAccountId, data.percentage)

        handleClose()
      })
      .catch((err) => {
        showSnackBar(err, 'error')
      })
  }
  return (
    <div className="flex w-full gap-4 bg-white">
      <div className="text-gray-900 text-base font-medium whitespace-nowrap ps-3 mt-[12px]">
        Budget Accounts
        <div className="min-w-[303px] w-[315px] bg-[#EAEFF4] bg-opacity-30 mt-3 overflow-x-scroll overflow-y-auto h-[550px] max-h-full min-h-[350px]">
          <div className="w-100">
            {budgetData?.map((node: any, index: number) => (
              <React.Fragment key={node.budgetAccountId}>
                {node?.parentId === null && (
                  <TableTreeRow
                    key={node.budgetAccountId}
                    label={t(limitText(node.accountDescription, 23))}
                    node={node}
                    level={0}
                    nodeId={index}
                    actionDropDown={actions}
                    treeAction={'three_dot_horizontal'}
                    handleTitleActions={handleTitleActions}
                    nodeIcon={'folder'}
                    handleTreeExpand={handleTreeExpand}
                    handleMenuActions={handleMenuActions}
                    data={budgetData}
                    setIsMouseOut={(val) => setIsMouseOut(val)}
                    isMouseOut={isMouseOut}
                    nodeSelectedIcon={'edit'}
                  >
                    {node.isOpen && renderTree(node, 1)}
                  </TableTreeRow>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className="flex-1">
        <TabContainer
          data={tabData}
          activeTab={active}
          onClick={onTabClick}
          tabBGClass="bg-white px-[1px] !important"
        >
          <Tab id={1}>
            <AccountSummary
              Title={TableTitle}
              activeNode={activeNode}
              key={updateBudget}
              budgetData={detailData}
              ParentBudgetAccountId={BudgetPID}
              BudgetId={BudgetIID}
              postingType={postingType}
              handleEXtExpand={handleExternalExpand}
              handleFetchData={handleGetBudgetData}
              onValueChange={handleChildValue}
              FinalIdEx={finalId}
              TitleFinalEx={titleFinal}
            />
          </Tab>
          <Tab id={2}>
            <VesselSummary
              Title={TableTitle}
              activeNode={activeNode}
              key={updateBudget}
              budgetData={detailData}
              ParentBudgetAccountId={BudgetPID}
              BudgetId={BudgetIID}
            />
          </Tab>
        </TabContainer>
      </div>
      <DialogModal
        isOpen={openPopup}
        small={true}
        actionLoader={methods.formState.isSubmitting}
        type="xs"
        title={'Adjust Amount by %'}
        body={
          <FormProvider {...methods}>
            <AdjustBudgetFigure treeNode={treeNode} />
          </FormProvider>
        }
        onClose={handleClose}
        actionLabel={'Apply'}
        onSubmit={methods.handleSubmit(handleAdjustBudgetAmount)}
        secondaryAction={handleClose}
        secondaryActionLabel={'Cancel'}
      />
    </div>
  )
}

export default VesselBudgetDetails

import { Button, Fab, FormBuilder, Loader } from '@/components/common'
import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { isAllow } from '@/constants'
import { useGeneralInfo } from '@/modules/technical/pages/vesselInspections/details/findings/details/general/api'
import { useAppStore } from '@/store/appStore'
import { formContainerClass } from '@/styles/variables'
import useHasPermission from '@/utilities/checkActionPermission'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'
import { useVesselInspectionDetails } from '.././api'
import { GeneralSchema, ParticularDetailsContextProps, generalSchema } from './schema'

const VIGeneral: React.FC = () => {
  const { t } = useTranslation()
  const params = useParams()
    const { width } = useScreenBreakPoint()
  const { setIsLoading } = useAppStore()
  const { edit, setEdit, vesselID, refetchData } =
    useOutletContext<ParticularDetailsContextProps>()
  const hasPermission = useHasPermission('VesselGenInfo')
  const [isOpen, setPopupOpen] = useState<boolean>(false)
  const { data, isLoading } = useVesselInspectionDetails(params.inspID as string)

  const onSuccessAction = () => {
    setPopupOpen(true)
  }
  const handleClose = () => {
    setPopupOpen(false)
    setEdit(false)
    refetchData?.()
  }

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    methods.reset({ ...initData() })
  }, [data])

  const handleEdit = async () => {
    const permission = await hasPermission({
      UserAction: isAllow.UPDATE,
      AccessKey: '',
      ID1: vesselID
    })
    setEdit(permission)
  }

  const resetEdit = () => {
    methods.reset()
    setEdit(false)
  }
  const { mutate } = useGeneralInfo(onSuccessAction)
  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      targetDate: data.targetDate ? dayjs(data.targetDate).format('YYYY-MM-DD') : null
    }
    mutate(payload)
  }

  const initData = () => {
    return {
      ...data,
      targetDate: data?.targetDate && new Date(data?.targetDate)
      // InspFindingCauses: []
    }
  }

  const methods = useForm<GeneralSchema>({
    resolver: zodResolver(generalSchema),
    mode: 'onChange',
    defaultValues: initData()
  })

   const generateCommonSpacing = () => {
     if (width > 1200) {
       return 3
     } else if (width > 1000) {
       return 4
     } else if (width > 700) {
       return 6
     }
     return 12
   }

  const formConfig = [
    {
      label: 'Target date',
      placeholder: 'DD-MMM-YY',
      name: 'targetDate',
      type: 'date',
      required: true,
      spacing: generateCommonSpacing()
    },
    {
      label: 'Type',
      placeholder: 'Type to search',
      required: true,
      disabled: true,
      name: 'findingTypeName',
      type: 'text',
      spacing: generateCommonSpacing()
    },
    {
      label: 'Category',
      required: true,
      name: 'FindCategoryName',
      id: 'findCatId',
      placeholder: 'Type to search',
      type: 'autocomplete',
      apiParams: { key: 'FINDCATS' },
      spacing: generateCommonSpacing()
    },
    {
      label: 'Performance Indicator',
      placeholder: 'Type to search',
      required: true,
      id: 'performanceIndId',
      name: 'performanceIndName',
      type: 'autocomplete',
      apiParams: { key: 'PERFIND' },
      spacing: generateCommonSpacing()
    },
    {
      label: 'PSC deficiency',
      placeholder: 'PSC deficiency',
      name: 'pscDeficiecnyName',
      disabled: true,
      type: 'text',
      spacing: generateCommonSpacing()
    },
    {
      label: 'PSC action',
      placeholder: 'PSC action',
      name: 'pscActionName',
      disabled: true,
      type: 'text',
      spacing: generateCommonSpacing()
    },
    {
      label: 'System Area',
      required: true,
      placeholder: 'Type to search',
      name: 'systemAreaName',
      id: 'systemAreaId',
      type: 'autocomplete',
      apiParams: { key: 'SYSTAREA' },
      spacing: generateCommonSpacing()
    },
    {
      label: 'Sire Chapter',
      required: true,
      placeholder: 'Type to search',
      name: 'sireCodeName',
      id: 'sireCodeId',
      type: 'autocomplete',
      apiParams: { key: 'SIRECODE' },
      spacing: generateCommonSpacing()
    },
    {
      label: 'Focus Area',
      placeholder: 'Focus Area',
      required: true,
      name: 'focusArea',
      type: 'text',
      spacing: generateCommonSpacing()
    },
    {
      label: 'Finding summary',
      placeholder: 'Finding summary',
      required: true,
      name: 'findingDetails',
      id:'findingDetails',
      type: 'textarea',
      spacing: 12
    }
    // {
    //   label: 'PerformanceIndId',
    //   placeholder: 'performanceIndId',
    //   name: 'performanceIndId',
    //   type: 'hidden',
    //   disabled: true
    // }
  ]
  return (
    <>
      <Loader isActive={isLoading} />
      <div className="h-full relative w-full">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className={formContainerClass}>
              <FormBuilder data={formConfig} edit={edit} isView={!edit} />
            </div>
            <div className="py-7">
              <div className="absolute bottom-0 right-0 p-2 py-1">
                {data?.statusName === 'Draft' && !edit ? (
                  <Fab
                    icontType="large"
                    onClick={() => {
                      handleEdit()
                    }}
                    className="fabShadow"
                  />
                ) : (
                  ''
                )}
                {edit ? (
                  <div className="flex">
                    <Button
                      label={t('update')}
                      className="me-2"
                      isLoading={isLoading}
                      onClick={methods.handleSubmit(onSubmit)}
                    />
                    <Button
                      label={t('cancel')}
                      outlined
                      onClick={() => {
                        resetEdit()
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </form>
        </FormProvider>
        <ConfirmModal
          isOpen={isOpen}
          modalId="successPopup"
          topIcon="success"
          title="Success"
          type="success"
          actionLabel="OK"
          onSubmit={handleClose}
          scrollDisable={false}
          onClose={handleClose}
          subTitle="Thank you. You have updated successfully."
        />
      </div>
    </>
  )
}

export default VIGeneral

import { getTechnicalData, updateTechnicalData } from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useCustomSnackBar from '@/utilities/customSnackBar'
import ManagementSchema from './schema'

 
export const getManagementInfo = (id: string) => {
  return getTechnicalData(`${apiTechnicalUrl.MANAGEMENT_INFO}${id}`)
}

export const useVesselParticularsManagement = (id: string) => {
  return useQuery({
    queryKey: ['vessel-particulars-management', id],
    queryFn: () => getManagementInfo(id),
    staleTime: 500
  })
}

export const updateManagementInfo = (data: ManagementSchema) => {
  return updateTechnicalData(
    `${apiTechnicalUrl.MANAGEMENT_INFO_UPDATE}${data.VesselId}`,
    data
  )
}

export const useManagementInfo = (onSuccessAction: () => void) => {
const { showSnackBar } = useCustomSnackBar()

  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateManagementInfo,
    mutationKey: ['update-management-info'],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
    },
    onSuccess: (response, data) => {
      onSuccessAction()
      queryClient.invalidateQueries(['vessel-particulars-technical', data.VesselId])
    }
  })
}

import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import { formContainerClass } from '@/styles/variables'
import React from 'react'
import { useTranslation } from 'react-i18next'
type SeaTrialFormProps = {}
export const CreateSeaTrialForm: React.FC<SeaTrialFormProps> = () => {
  const { t } = useTranslation()
  const formFields: FormBuilderProps[] = [
    {
      label: t('Sea Trial Date'),
      placeholder: t('Sea Trial Date (DD-MMM-YYYY)'),
      name: 'seaTrialDate',
      id: 'seaTrialDate',
      required: true,
      type: 'date',
      spacing: 12
    },
    {
      label: t('Engine'),
      placeholder: t('typeToSearch'),
      name: 'materialTypeName',
      id: 'materialTypeId',
      type: 'autocomplete',
      required: true,
      apiParams: { key: 'SEATRIALEQPTYPE' },
      spacing: 12
    },
    {
      label: t('Carbon Factor'),
      placeholder: t('Carbon Factor'),
      name: 'carbonFactor',
      id: 'carbonFactor',
      type: 'text',
      spacing: 12,
      maxLength: 16
    },
    {
      label: t('Barred Speed Max (RPM)'),
      placeholder: t('Barred Speed Max (RPM)'),
      name: 'barredSpeedMax',
      id: 'barredSpeedMax',
      required: true,
      type: 'number',
      spacing: 12,
      maxLength: 8
    },
    {
      label: t('Barred Speed Min (RPM)'),
      placeholder: t('Barred Speed Min (RPM)'),
      name: 'barredSpeedMin',
      id: 'barredSpeedMin',
      type: 'number',
      spacing: 12,
      maxLength: 8
    }
  ]
  return (
    <div className={formContainerClass}>
      <FormBuilder data={formFields} edit={true} />
    </div>
  )
}

import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import { getFormErrorMessage } from '@/components/common/formBuilder/utils/helpers'
import { guid } from '@/constants'
import { useAppContext } from '@/store/context/appContext'
import useCustomSnackBar from '@/utilities/customSnackBar'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
type UsersFormProps = {
  edit: boolean
  view: boolean
  methods: any
  data?: any
  isOpen: boolean
  loading: boolean
  roleDetails?: {
    roleId: string
    roleName: string
    departmentId: any
    departmentName: string
  }
}
export const CreateUsersForm: React.FC<UsersFormProps> = ({
  view,
  methods,
  data,
  isOpen,
  loading,
  roleDetails
}) => {
  const [existingUserType, setExistingUserType] = useState(null)
  const { isTab, isMobile } = useScreenBreakPoint()
  const { errors } = methods.formState
  const { userId } = methods.watch()
  const { showSnackBar } = useCustomSnackBar()
  const { t } = useTranslation()
  const namePrefix = [
    { id: 'Mr.', name: 'Mr.' },
    { id: 'Mrs.', name: 'Mrs.' },
    { id: 'Ms.', name: 'Ms.' },
    { id: 'Miss', name: 'Miss' }
  ]
  const userTypes = [
    { id: 0, name: 'Normal User' },
    // { Id: 1, Description: "Crew Member" },
    { id: 2, name: 'Manning Agent' },
    { id: 3, name: 'Vendor' }
  ]

  useEffect(() => {
    if (data?.userType || String(data?.userType) === '0') {
      setExistingUserType(data?.userType)
    }
  }, [data?.userType])
  const firstNamePrefix = {
    name: 'title',
    id: 'Title',
    label: '',
    width: 110,
    placeholder: t('Title'),
    disabled: view,
    type: 'custom_select',
    data: namePrefix
  }
  const formFields: FormBuilderProps[] = [
    {
      label: t('First Name'),
      placeholder: t('First Name'),
      name: 'firstName',
      prefixes: firstNamePrefix,
      id: 'Firstname',
      required: true,
      disabled: view || (userId !== guid && existingUserType === 1),
      display_name: `${data?.title} ${data?.firstName}`,
      type: 'text',
      maxLength: 30,
      spacing: 6
    },
    {
      label: t('Last Name'),
      placeholder: t('Last Name'),
      name: 'lastName',
      id: 'Lastname',
      required: true,
      disabled: view || (userId !== guid && existingUserType === 1),
      type: 'text',
      maxLength: 30,
      spacing: 6
    },
    {
      label: t('Login ID'),
      placeholder: t('Login ID'),
      name: 'loginId',
      id: 'LoginID',
      maxLength: 10,
      type: 'text',
      required: true,
      disabled: view || userId !== guid,
      spacing: 6
    },
    {
      label: t('User Type'),
      placeholder: t('User Type'),
      name: 'userTypeName',
      id: 'UserType',
      type: 'custom_select',
      data: userTypes,
      spacing: 6,
      disabled: view || (userId !== guid && existingUserType === 1),
      required: true
    },
    {
      label: t('User Role'),
      placeholder: t('typeToSearch'),
      name: 'roleName',
      id: 'sysRoleId',
      required: true,
      type: 'autocomplete',
      disabled:
        view ||
        (userId !== guid && existingUserType === 1) ||
        Object.keys(roleDetails ?? {})?.length > 0,
      apiParams: { key: 'ROLES' },
      spacing: 6
    },
    {
      label: t('Department'),
      placeholder: t('typeToSearch'),
      name: 'departmentName',
      id: 'departmentId',
      type: 'autocomplete',
      disabled:
        view || (userId !== guid && existingUserType === 1) || roleDetails?.departmentId,
      required: true,
      apiParams: { key: 'DEPARTMENT' },
      spacing: 6
    },
    {
      label: t('Designation'),
      placeholder: t('typeToSearch'),
      name: 'designationName',
      id: 'designationId',
      disabled: view || (userId !== guid && existingUserType === 1),
      type: 'autocomplete',
      required: true,
      apiParams: { key: 'DESIGNATION' },
      spacing: 6
    },
    {
      label: t('Expiry Date (DD-MMM-YYYY)'),
      placeholder: t('Expiry Date (DD-MMM-YYYY)'),
      name: 'expiryDate',
      id: 'ExpiryDate',
      disabled: view,
      type: 'date',
      minDate: new Date(),
      spacing: 6
    },
    {
      label: t('Phone'),
      placeholder: t('Phone'),
      name: 'mobile',
      disabled: view || (userId !== guid && existingUserType === 1),
      id: 'Mobile',
      type: 'text',
      minLength: 6,
      maxLength: 15,
      spacing: 6
    },
    {
      label: t('Email'),
      placeholder: t('Email'),
      disabled: view || (userId !== guid && existingUserType === 1),
      name: 'email',
      id: 'Email',
      type: 'email',
      required: true,
      maxLength: 50,
      spacing: 6
    }
  ]
  const controllFields: FormBuilderProps[] = [
    {
      label: t('Disable Account'),
      name: 'inactive',
      id: 'inactive',
      type: 'switch',
      disabled: view,
      spacing: isTab || isMobile ? 6 : 4
    },
    {
      label: t('Reset Password'),
      name: 'resetPswd',
      id: 'ResetPswd',
      type: 'switch',
      disabled: view,
      spacing: isTab || isMobile ? 6 : 4
    },
    {
      label: t('Validation Required'),
      name: 'validated',
      id: 'Validated',
      type: 'switch',
      disabled: view,
      spacing: isTab || isMobile ? 6 : 4
    }
  ]
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const errMsg = getFormErrorMessage(errors, [
        ...formFields,
        { ...firstNamePrefix, label: 'Title' }
      ])
      if (errMsg) {
        showSnackBar(`${t('errorToastMessage')} ${errMsg}`, 'error')
      }
    }
  }, [errors])
  const { setIsCommonLoader } = useAppContext()
  useEffect(() => {
    setIsCommonLoader(loading)
  }, [loading])
  return (
    <div className="grid w-full gap-5">
      <div className="grid grid-cols-12 w-full gap-5 items-end">
        <FormBuilder
          data={formFields}
          edit={!view}
          isView={view}
          autoFocus={false}
          showErrors={false}
        />
      </div>
      <div
        className={`grid grid-cols-12 w-full gap-5 ${view ? '  py-7 px-4 bg-[#d9e2ea4a] rounded' : ''}`}
      >
        <FormBuilder data={controllFields} edit={!view} autoFocus={false} isView={view} />
      </div>
    </div>
  )
}

import { CustomKendoTable } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { getVolumePrenentinTankByTankId } from '@/modules/technical/pages/vesselParticulars/details/tanks/api'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type VesselTankInfoProps = {
  searchData: any
  open: boolean
  onClose: () => void
  tankCompartmentname: string
}

const TankOilPresent: React.FC<VesselTankInfoProps> = ({
  searchData,
  open,
  tankCompartmentname,
  onClose
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const { t } = useTranslation()
  const { showSnackBar } = useCustomSnackBar()
  
  const getOilPresentData = () => {
    setIsLoading(true)
    getVolumePrenentinTankByTankId(searchData)
      .then((res) => {
        setData(res.Results)
      })
      .catch((error) => {
        showSnackBar(error.response.data.error || 'Something went wrong', 'error')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    if (searchData.VesTankId) {
      getOilPresentData()
    }
  }, [searchData.VesTankId])
  const columns = [
    {
      title: t('Material Name'),
      field: 'MaterialName',
      type: 'text',
      locked: true,
      width: 200
    },
    {
      title: t('Material Type'),
      field: 'MaterialTypeName',
      type: 'text'
    },
    {
      title: t('Oil Type'),
      field: 'OilTypeName',
      type: 'text'
    },

    {
      title: t('Sulphur Content'),
      field: 'SulphurContTypeName',
      type: 'text'
    },
    {
      title: t('ROB'),
      field: 'Quantity',
      align: 'right'
    },
    {
      title: t('Last Transaction Date'),
      field: 'LastTransDate',
      type: 'date'
    },
    {
      title: t('ROB (m3)'),
      field: 'Quantity_m3',
      align: 'right'
    },
    {
      title: t('Percentage'),
      field: 'Percentage',
      align: 'right'
    }
  ]
  const handleCloseForm = () => {
    onClose()
  }

  return (
    <>
      <DialogModal
        isOpen={open}
        small={false}
        title={`Oil Present / ${tankCompartmentname}`}
        body={
          <div className="h-full relative w-full">
            <CustomKendoTable
              columns={columns}
              data={data}
              isFetching={isLoading}
              pagination={false}

              // tableHeight={`calc(100vh - 445px)`}
            />
          </div>
        }
        onClose={handleCloseForm}
        secondaryAction={handleCloseForm}
        secondaryActionLabel="Close"
      />
    </>
  )
}

export default TankOilPresent

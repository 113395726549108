import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'

export const generalSchema = z
  .object({
    VesselId: z
      .string({ required_error: 'Vessel ID is required' })
      .min(1, { message: 'Vessel ID is required' }),
    VesselName: z
      .string({ required_error: 'Vessel Name is Required' })
      .min(1, { message: 'Vessel Name is Required' }),
    InspectionTypeId: z
      .number({ required_error: 'Inspection Type ID is required' })
      .min(1, { message: 'Inspection Type ID is required' }),
    InspTypeName: z
      .string({ required_error: 'Inspection Type is Required' })
      .min(1, { message: 'Inspection Type is Required' }),
    BranchId: z
      .string({ required_error: 'Company ID is required' })
      .min(1, { message: 'Company ID is required' }),
    CompanyName: z
      .string({ required_error: 'Company Name is Required' })
      .min(1, { message: 'Company Name is Required' }),
    PortFromId: z
      .number({ required_error: 'From Port ID is required' })
      .min(1, { message: 'From Port ID is required' }),
    PortFromName: z
      .string({ required_error: 'From Port is Required' })
      .min(1, { message: 'From Port is Required' }),
    PortToId: z.union([z.string(), z.number()]).optional().nullable(),
    PortToName: z.string().optional().nullable(),
    InspectedById: z
      .number({ required_error: 'Inspector Type ID is required' })
      .min(1, { message: 'Inspector Type ID is required' }),
    InspectedByTypeName: z
      .string({ required_error: 'Inspector Type is Required' })
      .min(1, { message: 'Inspector Type is Required' }),
    StartDate: z
      .date({ invalid_type_error: 'Start Date is required' })
      .refine((date) => Boolean(date), {
        message: 'Start Date is required'
      }),
    EndDate: z.date().nullable().optional(),
    InspectorName: z
      .string({ required_error: 'Inspector is Required' })
      .min(1, { message: 'Inspector is Required' })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      }),
    MasterName: z
      .string({ required_error: 'Master is Required' })
      .min(1, { message: 'Master is Required' })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      }),
    ChengName: z
      .string({ required_error: 'Chief Engineer is Required' })
      .min(1, { message: 'Chief Engineer is Required' })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      }),
    OfficerId: z
      .string({ required_error: 'Officer In Charge ID is required' })
      .min(1, { message: 'Officer In Charge ID is required' }),
    OfficerName: z
      .string({ required_error: 'Officer In Charge is Required' })
      .min(1, { message: 'Officer In Charge is Required' }),
    PSCMouId: z.union([z.string(), z.number()]).optional().nullable(),
    PscMouName: z.string({ required_error: 'MOU is Required' }).optional().nullable(),
    InspectionReport: z
      .string()
      .nullable()
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      })
      .optional(),
    Sailing: z.boolean().optional().nullable(),
    VesselDetained: z.boolean().optional().nullable(),
    InspectionId: z.string().optional().nullable(),
    RecMOU: z.number().optional().nullable()
  })
  .superRefine((data, customError) => {
    if (data.RecMOU === 1 && !data.PSCMouId) {
      customError.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['PscMouName'],
        message: 'MOU is Required'
      })
    }
  })

export default GeneralSchemaType
export type GeneralSchemaType = z.infer<typeof generalSchema>

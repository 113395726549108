// GetSubEntities
import {

postTechnicalData,
postTechnicalMipsData,

} from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'

export const fetchSubEntities = async (filters: any) => {
  return await postTechnicalData(`${apiTechnicalUrl.BUDGET_SUB_ENTITIES}`, {
    ...filters
  })
}
export const fetchBudgetYears = async (filters: any) => {
  return await postTechnicalMipsData(`${apiTechnicalUrl.GET_BUDGET_YEARS}`, {
    ...filters
  })
}
export const fetBudgetsBasedOnYear = async (filters: any) => {
  return await postTechnicalData(`${apiTechnicalUrl.GET_VESSEL_BUDGET}/Search`, {
    ...filters
  })
}
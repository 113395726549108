import { CustomKendoTable } from '@/components/common'
import { TableHeight } from '@/constants'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useBudgEntities } from './api'

type PageParams = {
  pageNo: number
  pageSize: number
  sortBy: string
  BudgetId: string | undefined
  ShowZeroBudgetItems: boolean
  HideMonthlyData: boolean
  NameOrDesc: string
}
const VesselBudgetEntities = () => {
  const params = useParams()
  const [param, setPram] = useState<PageParams>({
    pageNo: 0,
    pageSize: 100,
    BudgetId: params.budgetID,
    ShowZeroBudgetItems: true,
    HideMonthlyData: true,
    NameOrDesc: '',
    sortBy: 'BudgetYear'
  })
  // const [total,setTotal]=useState(0)
  const sortFields = [
    'EntityName',
    'StartDate',
    'EndDate',
    'Budget',
    'Days',
    'DailyActual',
    'Variance',
    'VariancePer',
    'DailyBudget',
    'DailyActual',
    'DailyVariance'
  ]
  const { t } = useTranslation()
  const { data, refetch, isFetching } = useBudgEntities(param)

  //  useEffect(() => {
  //    setTotal(data?.TotalRecords)
  //  }, [data])
  const columns = [
    {
      title: t('Name'),
      field: 'entityName',
      type: 'text',
      sort: true
    },
    {
      title: t('Start Date'),
      field: 'startDate',
      type: 'date',
      sort: true
    },
    {
      title: t('End Date'),
      field: 'endDate',
      type: 'date',
      sort: true
    },

    {
      title: t('Budget Amount'),
      field: 'budget',
      type: 'amount',
      sort: true
    },
    {
      title: t('Days'),
      field: 'days',
      type: 'text',
      sort: true
    },
    {
      title: t('Actual'),
      field: 'dailyActual',
      type: 'amount',
      sort: true
    },
    {
      title: t('Variance'),
      field: 'variance',
      type: 'amount',
      sort: true
    },
    {
      title: t('Var %'),
      field: 'variancePer',
      type: 'amount',
      sort: true
    },
    {
      title: t('Daily Budget'),
      field: 'dailyBudget',
      type: 'amount',
      sort: true
    },
    {
      title: t('Daily Actual'),
      field: 'dailyActual',
      type: 'amount',
      sort: true
    },
    {
      title: t('Daily Variance'),
      field: 'dailyVariance',
      type: 'amount',
      sort: true
    },
    {
      title: t('Daily Var %'),
      field: 'dailyVarPer',
      type: 'amount',
      sort: true
    }
  ]
  useEffect(() => {
    refetch()
  }, [param])

  const handleSort = (order: string) => {
    setPram((prev) => ({
      ...prev,
      sortBy: order
    }))
  }
  // const onPagination = (row: number) => {
  //   setPram((prev) => ({
  //     ...prev,
  //     pageNo: row
  //   }))

  // }
  // const onRowsPerPage = (row: number) => {
  //   setPram((prev) => ({
  //     ...prev,
  //     pageSize: row,
  //     pageNo: 1
  //   }))
  // }

  return (
    <div>
      <div className="p-[10px] bg-white rounded ">
        <CustomKendoTable
          columns={columns}
          data={data}
          isFetching={isFetching}
          // pagination={true}
          defaultSort={sortFields[0]}
          toolBar={true}
          sortable={true}
          async={false}
          resizable={true}
          //   workflowParams={{
          //     isDynamicMenu: true,
          //     handleMenuItems: handleDynamicmenu,
          //     handleRefresh: refetch
          //   }}
          //   handleMenuActions={handleMenuActions}
          //   primaryAction={{
          //     title: t('New Budget'),
          //     handlePrimaryAction: handleCreate
          //   }}
          //   isDownloadable={true}
          handleSort={handleSort}
          tableHeight={`calc(100vh -  ${TableHeight.WithHeader})`}
          // paginationProps={{
          //   total: total,
          //   currentPage: param.pageNo ,
          //   rowsPerPage: param.pageSize,
          //   onPagination,
          //   onRowsPerPage
          // }}
        />
      </div>
    </div>
  )
}

export default VesselBudgetEntities

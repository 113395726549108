import { useAuthStore } from '@/store/authStore'
import { getColorFromName, getInitials } from '@/utilities/dataGenerators'
import decode from 'jwt-decode'
import React from 'react'
type Props = {
  dimension?: number
  fontSize?: number
  value?: string
  bg?: string
  color?: string
  onclick?: (e: any) => void
}
const AvatarCircle: React.FC<Props> = ({
  dimension = 40,
  value,
  color = '#fff',
  bg,
  fontSize = 18,
  onclick
}) => {
  const { accessToken } = useAuthStore()
  let payload = { Name: '' }
  let userData: any = undefined

  if (accessToken) {
    payload = decode(accessToken)
    userData = payload
  }
  const text: string = value || userData?.Name
  const initials = getInitials(text)

  return (
    <div
      className="rounded-full flex items-center justify-center text-[9px] text-center"
      onClick={(e: any) => onclick?.(e)}
      style={{
        width: dimension,
        fontSize,
        height: dimension,
        color,
        backgroundColor: bg || getColorFromName(text),
        lineHeight: dimension
      }}
    >
      <div
        className="rounded-full flex items-center justify-center"
        style={{
          width: dimension,
          height: dimension
        }}
      >
        {initials}
      </div>
    </div>
  )
}
export default AvatarCircle

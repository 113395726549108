import React from 'react'
type TabProps = {
  id?: string | number
  className?: string
  children: React.ReactNode
  activeTab?: string | number
}
const CustomTabs: React.FC<TabProps> = ({
  children,
  className,
  activeTab,
  id = activeTab
}) => {
  return (
    <>
      {activeTab === id && (
        <div className={className} id={id as string}>
          {children}
        </div>
      )}
    </>
  )
}

export default CustomTabs

import { AutoComplete, DatePicker, Switch } from '@/components/common'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

type CreateContactProps = {
  control: any
  errors: any
  setValue: any
  getData: (k: string, value?: string, filter?: string) => Promise<any>
}

export const CreateContact: React.FC<CreateContactProps> = ({
  control,
  errors,
  setValue,
  getData
}) => {
  const [keyVal, setKeyVal] = useState<string>('')

  useEffect(() => {
    setKeyVal(Date.now().toString())
  }, [])

  return (
    <div className="flex flex-col gap-x-2 gap-y-4">
      <Controller
        name="vesselRoleId"
        control={control}
        render={({ field }) => (
          <AutoComplete
            onChange={field.onChange}
            name="vesselRoleId"
            required
            value={field.value}
            type="auto_complete"
            label="Role"
            getData={(v) => getData('VESROLES', v)}
            errors={errors}
            autoFocus={true}
            key={keyVal}
          />
        )}
      />
      <Controller
        name="userId"
        control={control}
        render={({ field }) => (
          <AutoComplete
            onChange={field.onChange}
            name="userId"
            required
            value={field.value}
            type="auto_complete"
            label="Name"
            getData={(v) => getData('USERS', v, 'VESCONTACT')}
            errors={errors}
          />
        )}
      />
      <Controller
        name="effectiveFrom"
        control={control}
        render={({ field: { value } }) => (
          <DatePicker
            onChange={(data) =>
              setValue('effectiveFrom', data.value, { shouldValidate: true })
            }
            placeholder="Effective from (DD-MMM-YYYY)"
            name="effectiveFrom"
            required
            value={value}
            label="Effective From"
            errors={errors}
          />
        )}
      />

      <Controller
        name="archived"
        control={control}
        render={({ field }) => (
          <Switch
            id="archived"
            name="archived"
            value={field.value}
            handleChange={field.onChange}
            label="Archived"
          />
        )}
      />
    </div>
  )
}

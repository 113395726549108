const CustomSuccessIcon = () => {
  return (
    <>
      <g clipPath="url(#clip0_2583_8563)">
        <mask
          id="mask0_2583_8563"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="1"
          width="24"
          height="24"
        >
          <path
            d="M9.0752 16.5C10.0603 16.5013 11.0359 16.3079 11.9461 15.9309C12.8562 15.5539 13.6828 15.0007 14.3785 14.3033C15.0759 13.6076 15.6291 12.781 16.0061 11.8709C16.3831 10.9607 16.5765 9.98511 16.5752 9.00001C16.5765 8.01491 16.383 7.03927 16.006 6.12917C15.629 5.21906 15.0759 4.39242 14.3785 3.69676C13.6828 2.99927 12.8562 2.44613 11.9461 2.06914C11.0359 1.69215 10.0603 1.49873 9.0752 1.50001C8.0901 1.49875 7.11447 1.69218 6.20436 2.06917C5.29425 2.44616 4.46762 2.99928 3.77195 3.69676C3.07448 4.39242 2.52135 5.21906 2.14436 6.12917C1.76737 7.03927 1.57395 8.01491 1.5752 9.00001C1.57393 9.98511 1.76734 10.9607 2.14433 11.8709C2.52133 12.781 3.07446 13.6076 3.77195 14.3033C4.46762 15.0007 5.29425 15.5539 6.20436 15.9308C7.11447 16.3078 8.0901 16.5013 9.0752 16.5Z"
            fill="white"
            stroke="white"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M6.0752 9L8.3252 11.25L12.8252 6.75"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </mask>
        <g mask="url(#mask0_2583_8563)">
          <path d="M0.074707 0H18.0747V18H0.074707V0Z" fill="#17B26A" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2583_8563">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  )
}

export default CustomSuccessIcon

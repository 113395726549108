import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const CopyIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="-2 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.375 15.4812C1.075 15.4812 0.8125 15.3687 0.5875 15.1437C0.3625 14.9187 0.25 14.6562 0.25 14.3562V3.05H1.375V14.3562H10.2625V15.4812H1.375ZM3.625 13.2312C3.325 13.2312 3.0625 13.1187 2.8375 12.8937C2.6125 12.6687 2.5 12.4062 2.5 12.1062V1.60625C2.5 1.30625 2.6125 1.04375 2.8375 0.818747C3.0625 0.593747 3.325 0.481247 3.625 0.481247H11.875C12.175 0.481247 12.4375 0.593747 12.6625 0.818747C12.8875 1.04375 13 1.30625 13 1.60625V12.1062C13 12.4062 12.8875 12.6687 12.6625 12.8937C12.4375 13.1187 12.175 13.2312 11.875 13.2312H3.625ZM3.625 12.1062H11.875V1.60625H3.625V12.1062Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CopyIcon

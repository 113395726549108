import { z } from 'zod'

export const adjustBudgetSchema = z.object({
  percentage: z
    .number({
      required_error: 'Percentage is required',
      invalid_type_error: 'Percentage is required'
    })
    .refine((value) => value !== 0, {
      message: 'Percentage must be non-zero'
    })
})

export type AdjustBudgetSchema = z.infer<typeof adjustBudgetSchema>

import React, { useEffect, useRef, useState } from 'react'
import { PresetColorProps } from '@/common/customComponenttypes'
import CustomIcons from '../icons/customIcons'

const PresetColorSelect: React.FC<PresetColorProps> = ({
  value,
  onChange,
  name,
  required = false,
  onClick = undefined,
  disabled
}) => {
  const [selectedColor, setSelectedColor] = useState<string>(value || '#0000')
  const colorpickerRef = useRef<HTMLInputElement>(null)
  const defaultColorCodes = [
    '#83C5BE',
    '#00B2CA',
    '#E07A5F',
    '#EDAFB8',
    '#AF79B3',
    '#FFC69E',
    '#88AEDB',
    '#99DBF3',
    '#8BC3A8',
    '#D7E2DC',
    '#CDD5AE',
    '#AEC8EB',
    '#B0E5C7',
    '#FFB6E1',
    '#7E9E9D',
    '#A1C3FF',
    '#F39394',
    '#C98475',
    '#DEB6AC',
    '#FE9788',
    '#00FF00'
  ]
  useEffect(() => {
    setSelectedColor(value)
  }, [value])

  const handleDefaultColorSelect = (colorCode?: any) => {
    if (!disabled) {
      ColorSet(colorCode)
    }
  }

  const ColorSet = (colorCode?: any) => {
    setSelectedColor(colorCode)
    onChange?.(colorCode)
  }

  const borderColorGen = () => {
    return selectedColor
  }
  const colorOptions = defaultColorCodes.map((colorCode) => (
    <div
      key={colorCode}
      className={`cursor-pointer flex items-center justify-center w-[30px] h-[30px] rounded-full ${selectedColor === colorCode ? 'selected color-shadow' : 'border-transparent'}`}
      style={{ backgroundColor: colorCode }}
      onClick={() => handleDefaultColorSelect(colorCode)}
    >
      <div className="rounded-full border-white border-[2px] h-full w-full flex items-center justify-center">
        {selectedColor === colorCode && (
          <div className="d-flex items-center justify-center">
            <CustomIcons
              name="check_mark"
              type="large"
              className={`${'text-white-checkbox cursor-pointer'}`}
            />
          </div>
        )}
      </div>
    </div>
  ))
  const handleColorClick = () => {
    if (!disabled) {
      refclick()
    }
  }
  const refclick = () => {
    if (!disabled && colorpickerRef && colorpickerRef.current) {
      colorpickerRef.current.click()
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
      className="flex-wrap w-full"
    >
      <div className="flex flex-wrap gap-[5px]">
        <>
          {colorOptions}
          <div
            onClick={() => {
              handleColorClick()
            }}
            style={{
              borderColor: `${!defaultColorCodes?.some((item) => item === selectedColor) ? borderColorGen() : 'white'}`
            }}
            className={`conic-gradient cursor-pointer flex items-center justify-center w-[30px] h-[30px] rounded-full border-[2px]  `}
          >
            <div className="rounded-full border-white border-[2px] h-full w-full flex items-center justify-center">
              {!defaultColorCodes?.some((item) => item === selectedColor) && (
                <div className="flex items-center justify-center">
                  <CustomIcons
                    name="check_mark"
                    type="large"
                    className={`${'text-white-checkbox cursor-pointer'}`}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={`invisible`}>
            <input
              className={`invisible`}
              onChange={(e) => ColorSet(e.target.value)}
              type={'color'}
              ref={colorpickerRef}
            />
          </div>
        </>
      </div>
    </div>
  )
}

export default PresetColorSelect

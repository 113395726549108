import React from 'react'
const VesselIcon: React.FC = () => {
  return (
    <>
      <path
        d="M22.6333 11.0333H22.1266L21.4945 9.76943C21.4325 9.64513 21.3056 9.56667 21.1667 9.56667H20.6599L20.0278 8.30281C19.9658 8.1785 19.839 8.10004 19.7 8.10004H18.2333V7.18339C18.2333 7.0822 18.1512 7.00004 18.05 7.00004H17.6833C17.5821 7.00004 17.5 7.08215 17.5 7.18339V8.1H15.6666V7.18334C15.6666 7.08215 15.5845 7 15.4832 7H15.1166C15.0155 7 14.9332 7.08211 14.9332 7.18334V8.1H13.1V7.18334C13.1 7.08215 13.0179 7 12.9166 7H12.55C12.4488 7 12.3666 7.08211 12.3666 7.18334V8.1H10.9C10.761 8.1 10.6341 8.17846 10.5722 8.30277L9.94005 9.56665H6.86667C6.7277 9.56665 6.60081 9.64513 6.53851 9.76943L5.90674 11.0333H1.36666C1.22882 11.0333 1.10266 11.1107 1.03997 11.2335C0.97728 11.3564 0.98901 11.5041 1.07039 11.6156L4.00374 15.6489C4.07267 15.7439 4.18232 15.8 4.29962 15.8C6.05524 15.8 19.6461 15.8 20.8055 15.8C20.8594 15.8 20.9107 15.7875 20.9591 15.7637C22.7565 14.8679 22.9996 13.0566 23 11.4026C23 11.1994 22.8365 11.0333 22.6333 11.0333ZM11.1266 8.83334H19.4734L19.8401 9.56667H10.7599L11.1266 8.83334ZM7.09324 10.3H20.9401L21.3067 11.0333H6.72658L7.09324 10.3ZM20.7109 15.0667H4.48662L2.08677 11.7667H22.2612C22.2073 13.5402 21.7456 14.5156 20.7109 15.0667Z"
        fill="currentColor"
      />
      <path
        d="M5.5832 12.5H5.21655C5.11536 12.5 5.0332 12.5821 5.0332 12.6833V13.05C5.0332 13.1512 5.11532 13.2333 5.21655 13.2333H5.5832C5.68439 13.2333 5.76656 13.1512 5.76656 13.05V12.6833C5.76656 12.5821 5.68439 12.5 5.5832 12.5Z"
        fill="currentColor"
      />
      <path
        d="M7.05 12.5H6.68335C6.58215 12.5 6.5 12.5821 6.5 12.6833V13.05C6.5 13.1512 6.58211 13.2333 6.68335 13.2333H7.05C7.15119 13.2333 7.23335 13.1512 7.23335 13.05V12.6833C7.23335 12.5821 7.15119 12.5 7.05 12.5Z"
        fill="currentColor"
      />
      <path
        d="M8.51683 12.5H8.15014C8.04895 12.5 7.9668 12.5821 7.9668 12.6833V13.05C7.9668 13.1512 8.04891 13.2333 8.15014 13.2333H8.5168C8.61799 13.2333 8.70014 13.1512 8.70014 13.05V12.6833C8.70014 12.5821 8.61803 12.5 8.51683 12.5Z"
        fill="currentColor"
      />
      <path
        d="M9.98359 12.5H9.61694C9.51575 12.5 9.43359 12.5821 9.43359 12.6833V13.05C9.43359 13.1512 9.5157 13.2333 9.61694 13.2333H9.98359C10.0848 13.2333 10.1669 13.1512 10.1669 13.05V12.6833C10.1669 12.5821 10.0848 12.5 9.98359 12.5Z"
        fill="currentColor"
      />
      <path
        d="M11.4499 12.5H11.0832C10.982 12.5 10.8999 12.5821 10.8999 12.6833V13.05C10.8999 13.1512 10.982 13.2333 11.0832 13.2333H11.4499C11.5511 13.2333 11.6332 13.1512 11.6332 13.05V12.6833C11.6332 12.5821 11.5511 12.5 11.4499 12.5Z"
        fill="currentColor"
      />
      <path
        d="M12.9168 12.5H12.55C12.4488 12.5 12.3667 12.5821 12.3667 12.6833V13.05C12.3667 13.1512 12.4488 13.2333 12.55 13.2333H12.9167C13.0179 13.2333 13.1 13.1512 13.1 13.05V12.6833C13.1 12.5821 13.0179 12.5 12.9168 12.5Z"
        fill="currentColor"
      />
      <path
        d="M14.3833 12.5H14.0166C13.9154 12.5 13.8333 12.5821 13.8333 12.6833V13.05C13.8333 13.1512 13.9154 13.2333 14.0166 13.2333H14.3833C14.4845 13.2333 14.5666 13.1512 14.5666 13.05V12.6833C14.5666 12.5821 14.4845 12.5 14.3833 12.5Z"
        fill="currentColor"
      />
      <path
        d="M15.85 12.5H15.4834C15.3822 12.5 15.3 12.5821 15.3 12.6833V13.05C15.3 13.1512 15.3822 13.2333 15.4834 13.2333H15.85C15.9512 13.2333 16.0334 13.1512 16.0334 13.05V12.6833C16.0334 12.5821 15.9512 12.5 15.85 12.5Z"
        fill="currentColor"
      />
      <path
        d="M17.3168 12.5H16.9502C16.849 12.5 16.7668 12.5821 16.7668 12.6833V13.05C16.7668 13.1512 16.849 13.2333 16.9502 13.2333H17.3168C17.418 13.2333 17.5002 13.1512 17.5002 13.05V12.6833C17.5002 12.5821 17.418 12.5 17.3168 12.5Z"
        fill="currentColor"
      />
      <path
        d="M18.7832 12.5H18.4165C18.3153 12.5 18.2332 12.5821 18.2332 12.6833V13.05C18.2332 13.1512 18.3153 13.2333 18.4165 13.2333H18.7832C18.8844 13.2333 18.9665 13.1512 18.9665 13.05V12.6833C18.9665 12.5821 18.8844 12.5 18.7832 12.5Z"
        fill="currentColor"
      />
      <path
        d="M20.25 12.5H19.8833C19.7821 12.5 19.7 12.5821 19.7 12.6833V13.05C19.7 13.1512 19.7821 13.2333 19.8833 13.2333H20.25C20.3512 13.2333 20.4333 13.1512 20.4333 13.05V12.6833C20.4333 12.5821 20.3512 12.5 20.25 12.5Z"
        fill="currentColor"
      />
    </>
  )
}
export default VesselIcon

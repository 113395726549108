type ColProps = {
  col: {
    title: string
    field: string
    type?: string
    align?: 'right' | 'left' | 'center'
    render?: (row: any) => any
  }
  rowData: any
  lastChild?: boolean
  index?: number
}
export default function DataColumn({ col, rowData, index }: ColProps) {
  const handleCol = () => {
    if (!rowData[col.field]) return undefined
    switch (col.type) {
      default:
        return (
          <div key={col.title} className={`text-common ${index === 0 ? '' : 'ps-4'}`}>
            {col.title !== '' && <span className={`text-grey-dark`}>{col.title}: </span>}
            <span className={`text-secondary`}>{rowData[col.field]}</span>
          </div>
        )
    }
  }
  const renderFunc = col.render && col.render
  return (
    <>
      {renderFunc && (
        <div
          key={col.title}
          className="p-cell text-xxs text-table-bodyColor font-normal td-custom"
        >
          {renderFunc(rowData)}
        </div>
      )}
      {!renderFunc && handleCol()}
    </>
  )
}

import { RefObject, useEffect, useState } from 'react'

interface ScrollHandlerHook {
  isFetching: boolean
}

const useScrollHandler = (
  containerRef: RefObject<HTMLDivElement>,
  fetchData: () => Promise<void>,
  hasMore: boolean,
  isLoading: boolean
): ScrollHandlerHook => {
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef?.current
      if (!container || !hasMore || isLoading) return

      const { scrollTop, scrollHeight, clientHeight } = container
      const isAtBottom =
        parseInt(scrollTop.toString()) + clientHeight >= scrollHeight - 10
      if (isAtBottom && !isFetching) {
        setIsFetching(true)
        setTimeout(() => {
          fetchData().finally(() => {
            setIsFetching(false)
          })
        }, 100)
      }
    }

    containerRef.current?.addEventListener('scroll', handleScroll)

    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [containerRef, fetchData, hasMore, isFetching, isLoading])

  return { isFetching }
}

export default useScrollHandler

import React from 'react'
type NoDataTypes = {
  searchCount?: number
}
const NoRecordsFound: React.FC<NoDataTypes> = ({ searchCount }) => {
  return (
    <div className="w-full flex flex-col items-center justify-center  p-10 pt-32">
      <div>
        <img src="/assets/no_records_found.svg" alt="No records found" />
      </div>
      <div className="text-text-more text-common leading-5 font-normal">
        {searchCount === undefined || searchCount <= 1
          ? 'Tap Search button to view the records'
          : 'No Records Found'}
      </div>
    </div>
  )
}

export default NoRecordsFound

import { getRegister } from '@/apis/common.api'
import { CustomKendoTable, DeletePopUp } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { TableHeight, defaultPageSize, guid, isAllow } from '@/constants'
import { CreateContact } from '@/modules/technical/components/app/vesselParticulars/contacts/ContactForm'
import { useAppStore } from '@/store/appStore'
import useHasPermission from '@/utilities/checkActionPermission'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'

import { createContact, deleteContact, updateContact, useContactDetails } from '../api'
import { ParticularDetailsContextProps } from '../schema'
import { FormDataType, FormSchema } from './schema'
import { useAppContext } from '@/store/context/appContext'


type PageParams = {
  pageNo: number
  vesselId?: string
  pageSize: number
  vesselFilterType: number
  sortBy: string
  archived:boolean
}
const TechnicalParticularContacts: React.FC = () => {
  const { vesselID } = useOutletContext<ParticularDetailsContextProps>()
  const { setIsLoading } = useAppStore()
  const [total, setTotal] = useState(0)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [subTitle, setSubTitle] = useState<string>('')
  const { setIsActionLoader, isActionLoader } = useAppContext()
  const [contactId, setContactId] = useState<string>('')
const { showSnackBar } = useCustomSnackBar()
  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    vesselId: vesselID,
    vesselFilterType: 1,
    pageSize: defaultPageSize,
    sortBy: 'vesselRoleName',
    archived:false
  })
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset
  } = useForm<FormDataType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      vesselRoleId: { id: '', name: '' },
      userId: { id: '', name: '' },
      archived: false,
      vesContactId: guid,
      vesselId: vesselID
    }
  })
  const { data, refetch, isFetching } = useContactDetails(params)
  const hasPermission = useHasPermission('VesselContact')

  const columns = [
    { title: 'Role', field: 'vesselRoleName', sort: true, action: true },
    {
      title: 'Name',
      field: 'userName',
      sort: true
    },
    {
      title: 'Mobile',
      field: 'mobile',
      sort: true
    },
    {
      title: 'Email',
      field: 'email',
      sort: true
    },
    {
      title: 'Effective From',
      field: 'effectiveFrom',
      type: 'date',
      width: 150,
      sort: true
    },

    {
      title: 'Archived',
      align: 'center',
      type: 'boolean',
      sort: true,
      field: 'archived'
    }
  ]

  useEffect(() => {
    setTotal(data?.data?.totalRecords)
  }, [data])

  useEffect(() => {
    setIsLoading(isFetching)
  }, [isFetching])

  useEffect(() => {
    refetch()
  }, [params])
  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }
  const handleMenuActions = async (slug: string, rowData: any) => {
    setContactId(rowData?.vesContactId)
    setSubTitle(rowData?.vesselRoleName)
    const permissionParams = {
      UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
      AccessKey: '',
      ID1: vesselID
    }
    const permission = await hasPermission(permissionParams)
    if (slug === 'delete') {
      setDeleteOpen(permission)
    } else {
      setCreateOpen(permission)
      setEdit(true)
      reset({
        vesselRoleId: { id: rowData.vesselRoleId, name: rowData.vesselRoleName },
        userId: { id: rowData.userId, name: rowData.userName },
        archived: rowData.archived,
        vesContactId: rowData?.vesContactId,
        vesselId: vesselID,
        effectiveFrom: rowData?.effectiveFrom && new Date(rowData?.effectiveFrom)
      })
    }
  }

  const handleDelete = () => {
    setIsActionLoader(true)
    deleteContact(contactId)
      .then((res) => {
        showSnackBar(res)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err, 'error')
      }).finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }
  const handleCreate = async () => {
    reset({
      vesselRoleId: { id: '', name: '' },
      userId: { id: '', name: '' },
      archived: false,
      vesContactId: guid,
      vesselId: vesselID
    })
    const permissionParams = {
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: vesselID
    }
    const permission = await hasPermission(permissionParams)
    setEdit(false)
    setCreateOpen(permission)
  }
  const handleCloseForm = () => {
    setCreateOpen(false)
    setEdit(false)
    reset({})
  }
  const getData = async (key: string, value?: string, filter1?: string) => {
    let formFilter = { key, value: value, filter1: filter1 }
    const data = await getRegister(formFilter)
    return data?.map((item: any) => ({ ...item, name: item.Description, id: item.Id }))
  }

  const manageApisController = (data: any) =>
    data.vesContactId === guid
      ? createContact(data).then((res) => res)
      : updateContact(data).then((res) => res)

  const onSubmit = (data: FormDataType) => {
    manageApisController({
      ...data,
      vesselRoleId: data.vesselRoleId?.id,
      vesselRoleName: data.vesselRoleId?.name,
      userId: data.userId?.id,
      userName: data.userId?.name,
      effectiveFrom: data.effectiveFrom
        ? dayjs(data?.effectiveFrom).format('YYYY-MM-DD')
        : ''
    })
      .then((res) => {
        showSnackBar(res)
        refetch()
        setCreateOpen(false)
      })
      .catch((err) => {
        showSnackBar(err, 'error')
      })
  }
   const handleToolActions = (slug: string) => {
     if (slug === 'archived') {
       setPrams({ ...params, archived: !params?.archived, pageNo: 1 })
     }
   }

   const getArchiveText = () => (params?.archived ? 'Hide Archived' : 'Show Archived')
  return (
    <div>
      <CustomKendoTable
        columns={columns}
        data={data?.data?.results}
        pagination={true}
        toolBar={true}
        sortable={true}
        resizable={true}
        isFetching={isFetching}
        defaultSort={'vesselRoleName'}
        title="Contacts"
        isDownloadable={true}
        adjustWidth={0}
        handleSort={handleSort}
        tableHeight={`calc(100vh - ${TableHeight.WithHeader})`}
        actionDropDown={[
          { title: 'Edit', iconName: 'edit', slug: 'edit', id: '1' },
          {
            title: 'Delete',
            iconName: 'delete',
            slug: 'delete',
            id: '2',
            isWarning: true
          }
        ]}
        handleMenuActions={handleMenuActions}
        primaryAction={{ title: 'New', handlePrimaryAction: handleCreate }}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },
          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
        handleToolActions={handleToolActions}
        actionToolDropDown={[
          {
            title: getArchiveText(),
            iconName: 'archieve',
            slug: 'archived',
            id: '1'
          }
        ]}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => {
          setDeleteOpen(false)
        }}
        itemLabels={{ title: 'contacts', subTitle: subTitle }}
        actionLoader={isActionLoader}
      />
      <DialogModal
        isOpen={createOpen}
        type="sm"
        title={`${edit ? 'Edit ' : 'Add '} Vessel Contact`}
        body={
          <CreateContact
            control={control}
            errors={errors}
            getData={getData}
            setValue={setValue}
          />
        }
        onClose={handleCloseForm}
        actionLabel={edit ? 'Update' : 'Save'}
        onSubmit={() => handleSubmit(onSubmit)()}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={'Cancel'}
      />
    </div>
  )
}

export default TechnicalParticularContacts

import { defaultPageSize } from '@/constants'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface FilterType {
  [key: string]: string | number | undefined
}
const defaultValues = { pageNo: 1, pageSize: defaultPageSize, sortBy: 'FindingCatName' }

const sireCatData = [
  { id: 1, name: 'A' },
  { id: 2, name: 'B' },
  { id: 3, name: 'C' },
  { id: 4, name: 'D' }
]

interface FindingCategoriesFilterTypeStore {
  filters: FilterType
  setFilters: (newFilters: FilterType) => void
  clearFilters: () => void
  isSearchCount: number
  setIsSearched?: () => void
  sireCatData :  { id: number; name: string }[]
}

const useFindingCategoriesFilterStore = create<FindingCategoriesFilterTypeStore>()(
  persist(
    (set, get) => ({
      filters: { ...defaultValues } as FilterType,
      setFilters: (newFilters: FilterType) => set({ filters: newFilters }),
      clearFilters: () => set({ filters: defaultValues }),
      isSearchCount: 1,
      setIsSearched: () => set({ isSearchCount: get()?.isSearchCount + 1 }),
      sireCatData: sireCatData
    }),
    {
      name: '_@fin___dngcatgry__#rc__#ch$',
      getStorage: () => sessionStorage
    }
  )
)

export default useFindingCategoriesFilterStore

import { getRegister } from '@/apis/common.api'
import { CustomKendoTable, DeletePopUp } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { TableHeight, defaultPageSize, guid, isAllow } from '@/constants'
import { CreateCommunication } from '@/modules/technical/components/app/vesselParticulars/communication/CommunicationForm'
import useHasPermission from '@/utilities/checkActionPermission'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'

import { useAppContext } from '@/store/context/appContext'
import {
  createCommunication,
  deleteCommuication,
  getCommunicationInfo,
  updateCommunication,
  useCommunicationDetail
} from '../api'
import { ParticularDetailsContextProps } from '../schema'
import { FormDataType, FormSchema } from './schema'

type PageParams = {
  pageNo: number
  vesselId?: string
  pageSize: number
  vesselFilterType: number
  sortBy: string
  archived: boolean
}
const TechnicalParticularCommunication: React.FC = () => {
  const { vesselID } = useOutletContext<ParticularDetailsContextProps>()

  const [total, setTotal] = useState(0)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const hasPermission = useHasPermission('VesselCommInfo')

  const [edit, setEdit] = useState(true)
  const [communicationId, setCommunicationId] = useState<string>('')
  const [subTitle, setSubTitle] = useState<string>('')
  const { setIsActionLoader, isActionLoader } = useAppContext()
  const { showSnackBar } = useCustomSnackBar()
  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    vesselId: vesselID,
    vesselFilterType: 1,
    pageSize: defaultPageSize,
    sortBy: 'commNumber',
    archived: false
  })
  const methods = useForm<FormDataType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      commTypeId: { id: '', name: '', param1: '', param2: '', param3: '' },
      archived: false,
      vesCommInfoId: guid,
      vesselId: vesselID,
      commNumber: ''
    }
  })
  const { data, refetch, isFetching } = useCommunicationDetail(params)
  const columns = [
    {
      title: 'Communication Type',
      field: 'commTypeName',
      sort: true,
      action: true
    },
    { title: 'Number', field: 'commNumber', sort: true },

    {
      title: 'Archived',
      align: 'center',
      type: 'boolean',
      sort: true,
      field: 'archived'
    }
  ]

  useEffect(() => {
    setTotal(data?.data?.totalRecords)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])
  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }
  const handleMenuActions = async (slug: string, rowData: any) => {
    setCommunicationId(rowData?.vesCommInfoId)
    setSubTitle(rowData?.commNumber)
    const permissionParams = {
      UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
      AccessKey: '',
      ID1: vesselID
    }
    const permission = await hasPermission(permissionParams)
    if (slug === 'delete') {
      setDeleteOpen(permission)
    } else {
      const data = await getCommunicationInfo(rowData?.vesCommInfoId)
      setEdit(false)
      setCreateOpen(permission)
      setEdit(true)
      methods.reset({
        commTypeId: {
          id: rowData.commTypeId,
          name: data.commTypeName,
          param1: data.fieldTypeId,
          param2: data.minLength,
          param3: data.maxLength
        },
        archived: rowData.archived,
        vesCommInfoId: rowData?.vesCommInfoId,
        vesselId: vesselID,
        commNumber: rowData?.commNumber
      })
    }
  }
  const handleDelete = () => {
    setIsActionLoader(true)
    deleteCommuication(communicationId)
      .then((res) => {
        showSnackBar(res)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err, 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }
  const handleCreate = async () => {
    methods.reset({
      commTypeId: { id: '', name: '', param1: '', param2: '', param3: '' },
      archived: false,
      vesCommInfoId: guid,
      vesselId: vesselID,
      commNumber: ''
    })
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: vesselID
    })
    setEdit(false)
    setCreateOpen(permission)
  }
  const getData = async (value?: string) => {
    let formFilter = { key: 'VESCOMMTYPE', desc: value }
    const data = await getRegister(formFilter)
    return data?.map((item: any) => ({
      ...item,
      name: item.Description,
      id: item.Id,
      param1: item.Param1,
      param2: item.Param2,
      param3: item.Param3
    }))
  }

  const onSubmit = (data: FormDataType) => {
    const api = edit ? updateCommunication : createCommunication

    api({
      ...data,
      commTypeId: data.commTypeId?.id
    })
      .then((res) => {
        showSnackBar(res)
        refetch()
        setCreateOpen(false)
      })
      .catch((err) => {
        showSnackBar(err, 'error')
      })
  }
  const handleToolActions = (slug: string) => {
    if (slug === 'archived') {
      setPrams({ ...params, archived: !params?.archived, pageNo: 1 })
    }
  }

  const getArchiveText = () => (params?.archived ? 'Hide Archived' : 'Show Archived')
  return (
    <div>
      <CustomKendoTable
        columns={columns}
        data={data?.data?.results}
        pagination={true}
        toolBar={true}
        sortable={true}
        resizable={true}
        isFetching={isFetching}
        defaultSort={'commNumber'}
        title="Communication Details"
        isDownloadable={true}
        adjustWidth={0}
        handleSort={handleSort}
        tableHeight={`calc(100vh - ${TableHeight.WithHeader})`}
        actionDropDown={[
          { title: 'Edit', iconName: 'edit', slug: 'edit', id: '1' },
          {
            title: 'Delete',
            iconName: 'delete',
            slug: 'delete',
            id: '2',
            isWarning: true
          }
        ]}
        handleMenuActions={handleMenuActions}
        primaryAction={{ title: 'New', handlePrimaryAction: handleCreate }}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },
          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
        handleToolActions={handleToolActions}
        actionToolDropDown={[
          {
            title: getArchiveText(),
            iconName: 'archieve',
            slug: 'archived',
            id: '1'
          }
        ]}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
        itemLabels={{ title: 'communication details', subTitle: subTitle }}
        actionLoader={isActionLoader}
      />
      <FormProvider {...methods}>
        <DialogModal
          isOpen={createOpen}
          type="sm"
          title={edit ? 'Edit Vessel Communication' : 'Add Vessel Communication'}
          body={<CreateCommunication getData={getData} />}
          onClose={() => setCreateOpen(false)}
          actionLabel={edit ? 'Update' : 'Save'}
          onSubmit={() => methods.handleSubmit(onSubmit)()}
          secondaryAction={() => setCreateOpen(false)}
          secondaryActionLabel={'Cancel'}
        />
      </FormProvider>
    </div>
  )
}

export default TechnicalParticularCommunication

import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'

export const FormSchema = z
  .object({
    yardId: z.string().min(1, { message: 'Dry Dock Location is required' }),
    yardName: z
      .string({
        required_error: 'Dry Dock Location is required',
        invalid_type_error: 'Dry Dock Location is required'
      })
      .min(1, { message: 'Dry Dock Location is Required' })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      }),
    startDate: z.date({
      required_error: 'Start Date is required',
      invalid_type_error: 'Start Date is required'
    }),
    endDate: z.date({
      required_error: 'End Date is required',
      invalid_type_error: 'End Date is required'
    }),
    remarks: z.string().optional().nullable(),
    antiFoulingId: z.string().optional().nullable(),
    vesselId: z.string()
  })
  .superRefine((data, customError) => {
    if (data.endDate < data.startDate) {
      customError.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['endDate'],
        message: 'End Date must be after the Start Date'
      })
    }
  })

export type FormDataType = z.infer<typeof FormSchema>

export const technicalRouterStore: { [key: string]: string } = {
  //particulars start
  PARTICULAR: '/technical/particulars',
  PARTICULAR_DETAILS: '/technical/particulars/:vesselID',
  PARTICULAR_DETAILS_GENERAL_INFO: 'general-info',
  PARTICULAR_DETAILS_TECHNICAL: 'technical-info',
  PARTICULAR_DETAILS_ANTI_FOULINGS: 'anti-foulings',
  PARTICULAR_DETAILS_AUDIT_LOG: 'audit-log',
  PARTICULAR_DETAILS_COMMUNICATION_INFO: 'communication',
  PARTICULAR_DETAILS_CONTACTS_INFO: 'contacts',
  PARTICULAR_DETAILS_EQUIPMENT_CONFIG: 'equipment-config',
  PARTICULAR_DETAILS_MANAGEMENT: 'management',
  PARTICULAR_DETAILS_SEA_TRAILS: 'sea-trails',
  PARTICULAR_DETAILS_TANKS: 'tanks',
  //particulars end

  VESSEL_INSPECTION: '/technical/vessinspection',
  VESSEL_INSPECTION_DETAILS: '/technical/vessinspection/:inspID',
  VESSEL_INSPECTIONS_DETAILS_GENERAL_INFO: 'general-info',
  VESSEL_INSPECTIONS_DETAILS_FINDINGS: 'findings',
  VESSEL_INSPECTIONS_DETAILS_DOCUMENTS: 'documents',
  VESSEL_INSPECTIONS_DETAILS_AUDIT_LOG: 'audit-log',

  VI_DETAILS_FINDINGS_DETAILS_MAIN: '/technical/vessinspection/details',
  VI_DETAILS_FINDINGS_DETAILS: '/technical/vessinspection/details/:inspID/:mainID',
  VI_DETAILS_GENERAL_INFO: 'general-info',
  VI_DETAILS_ACTIONS: 'actions',
  VI_DETAILS_DOCUMENTS: 'documents',
  VI_DETAILS_AUDIT_LOG: 'audit-log',

  //vesselBudget
  VESSEL_BUDGET: '/technical/vesbud',
  VESSEL_BUDGET_CREATE: '/technical/vesbud/create',
  VESSEL_BUDGET_DETAILS: '/technical/vesbud/:budgetID',
  VESSEL_BUDGET_ENTITIES: 'entities',
  VESSEL_BUDGET_ASSUMPTIONS: 'assumptions',
  VESSEL_BUDGET_DETAILS_TAB: 'budgetdetails',
  VESSEL_BUDGET_AUDITLOG: 'auditlog',

  //registers start
  REGISTERS_ENGINE_TYPES: '/technical/engtypes',
  REGISTERS_COMMUNICATION_TYPES: '/technical/commtypes',
  REGISTERS_VESSEL_ROLES: '/technical/vesselrole',
  REGISTER_SEATRIAL_LOAD: '/technical/trialitem',
  REGISTER_SYSTEM_AREA: '/technical/systemarea',
  REGISTERS_HULL_TYPES: '/technical/hulltype',
  REGISTERS_PAINT_TYPES: '/technical/painttype',
  REGISTERS_TANK_HEATING_TYPES: '/technical/tankheatingtype',
  REGISTERS_FINDING_CAUSES: '/technical/findcause',
  REGISTERS_FINDING_CATEGORY: '/technical/findcat',
  REGISTERS_BUDGET_TYPES: '/technical/budgettype',
  REGISTERS_STEAM_COIL_TYPE: '/technical/coiltype',
  REGISTERS_PSC_MOUS: '/technical/pscmou',
  REGISTERS_PSC_ACTIONS: '/technical/pscaction',
  REGISTERS_SFT_INDICATOR: '/technical/safetyind',
  REGISTERS_ASSMENT_AREAS: '/technical/assessarea',
  REGISTERS_INSP_TYPES: '/technical/insptype',
  REGISTERS_PSC_DEFICIENCY: '/technical/pscdeficiency'

  //registers end
}

import React from 'react'
const DivisonIcon: React.FC = () => {
  return (
    <>
      <g clipPath="url(#clip0_2660_4897)">
        <path
          d="M10.85 9.5V9.4H10.75H9.8125C9.00523 9.4 8.35 8.74477 8.35 7.9375V3.5625C8.35 2.75523 9.00523 2.1 9.8125 2.1H14.1875C14.9948 2.1 15.65 2.75523 15.65 3.5625V7.9375C15.65 8.74477 14.9948 9.4 14.1875 9.4H12.625H12.525V9.5V10.75V10.85H12.625H20.75C20.9721 10.85 21.1851 10.9382 21.3422 11.0953C21.4993 11.2524 21.5875 11.4654 21.5875 11.6875C21.5875 11.9096 21.4993 12.1226 21.3422 12.2797C21.1851 12.4368 20.9721 12.525 20.75 12.525H17.625H17.525V12.625V14.5V14.6H17.625H19.1875C19.9948 14.6 20.65 15.2552 20.65 16.0625V20.4375C20.65 21.2448 19.9948 21.9 19.1875 21.9H14.8125C14.0052 21.9 13.35 21.2448 13.35 20.4375V16.0625C13.35 15.2552 14.0052 14.6 14.8125 14.6H15.75H15.85V14.5V12.625V12.525H15.75H8.25H8.15V12.625V14.5V14.6H8.25H9.1875C9.99477 14.6 10.65 15.2552 10.65 16.0625V20.4375C10.65 21.2448 9.99477 21.9 9.1875 21.9H4.8125C4.00523 21.9 3.35 21.2448 3.35 20.4375V16.0625C3.35 15.2552 4.00523 14.6 4.8125 14.6H6.375H6.475V14.5V12.625V12.525H6.375H3.25C3.02788 12.525 2.81486 12.4368 2.6578 12.2797C2.50074 12.1226 2.4125 11.9096 2.4125 11.6875C2.4125 11.4654 2.50074 11.2524 2.6578 11.0953C2.81486 10.9382 3.02788 10.85 3.25 10.85H10.75H10.85V10.75V9.5ZM15.125 16.275H15.025V16.375V20.125V20.225H15.125H18.875H18.975V20.125V16.375V16.275H18.875H15.125ZM5.125 16.275H5.025V16.375V20.125V20.225H5.125H8.875H8.975V20.125V16.375V16.275H8.875H5.125ZM13.875 7.725H13.975V7.625V3.875V3.775H13.875H10.125H10.025V3.875V7.625V7.725H10.125H13.875Z"
          fill="currentColor"
          stroke="white"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2660_4897">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </>
  )
}
export default DivisonIcon

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const DashToggle: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M1.3 3.61111H11.7V1.44444H1.3V3.61111ZM7.8 11.5556V5.05556H5.2V11.5556H7.8ZM9.1 11.5556H11.7V5.05556H9.1V11.5556ZM3.9 11.5556V5.05556H1.3V11.5556H3.9ZM0.65 0H12.35C12.709 0 13 0.323353 13 0.722222V12.2778C13 12.6767 12.709 13 12.35 13H0.65C0.291018 13 0 12.6767 0 12.2778V0.722222C0 0.323353 0.291018 0 0.65 0Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DashToggle

import Area from '@/components/common/chart/Area'
import Bar from '@/components/common/chart/Bar'
import Line from '@/components/common/chart/Line'
import Pie from '@/components/common/chart/Pie'
import React from 'react'
type Props = {
  index: number
}
const DashChartSwitch: React.FC<Props> = ({ index }) => {
  const renderCharts = () => {
    switch (index % 4) {
      case 1:
        return <Area />
      case 2:
        return <Pie />

      case 3:
        return <Line />

      case 0:
        return <Bar />
      default:
        return <></>
    }
  }
  return <div>{renderCharts()}</div>
}

export default DashChartSwitch

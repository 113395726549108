import React from 'react'
import ReactApexChart from 'react-apexcharts'

import { dashboardDummyDataGen } from '@/utilities/dataGenerators'
import { ApexOptions } from 'apexcharts'

const Line: React.FC = () => {
  const series = [
    {
      name: 'Draft',
      color: '#006DCD',
      data: dashboardDummyDataGen([28, 29, 33, 36])
    },
    {
      name: 'Final',
      color: '#003C71',
      data: dashboardDummyDataGen([12, 11, 14, 18])
    },
    {
      name: 'Closed',
      color: '#FF681D',

      data: dashboardDummyDataGen([44, 55, 41, 67])
    },
    {
      name: 'Rejected',
      color: '#F1B635',
      data: dashboardDummyDataGen([13, 23, 20, 8])
    }
  ]
  const options: ApexOptions = {
    chart: {
      height: 200,
      type: 'line',
      // dropShadow: {
      //   enabled: true,
      //   color: '#000',
      //   top: 18,
      //   left: 7,
      //   blur: 10,
      //   opacity: 0.2
      // },
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    //   colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: true
    },
    stroke: {
      curve: 'smooth'
    },
    // title: {
    //   text: ' --',
    //   align: 'left'
    // },
    //   grid: {
    //     borderColor: '#e7e7e7',
    //     row: {
    //       colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
    //       opacity: 0.5
    //     }
    //   },
    //   markers: {
    //     size: 1
    //   },
    xaxis: {
      categories: ['Alpha Ship', 'Antartic', 'Beta Ship', 'Gamma Ship']
      // title: {
      //   text: 'Month'
      // }
    },
    // yaxis: {
    //   title: {
    //     text: 'Temperature'
    //   }
    // min: 5,
    // max: 40
    // },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'right',
      floating: false,
      offsetY: 0,
      offsetX: 0
    }
  }
  return (
    <div>
      <ReactApexChart options={options} series={series} type="line" height={200} />
    </div>
  )
}

export default Line

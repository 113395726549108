import { CommonManageModalProps } from '@/common/commontypes'
import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import {
  addCrewingAssignmentType,
  updateCrewingAssignmentType
} from '@/modules/crewing/pages/registers/assignmentType/api'
import {
  CrewingAssignmentTypeDataType,
  CrewingAssignmentTypeSchema
} from '@/modules/crewing/pages/registers/assignmentType/schema'
import { formContainerClass } from '@/styles/variables'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { getErrorMessage } from '@/utilities/dataGenerators'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const ManageAssignmentType: React.FC<CommonManageModalProps> = ({
  data,
  edit,
  view,
  open,
  title,
  onClose,
  refetch
}) => {
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)
  const { showSnackBar } = useCustomSnackBar()
  const formFields: FormBuilderProps[] = [
    {
      label: t('Assignment Type'),
      placeholder: t('Assignment Type'),
      name: 'assignmentTypeName',
      id: 'assignmentTypeName',
      required: true,
      type: 'text',
      disabled: false,
      spacing: 12
    },
    {
      label: t('Core Activity'),
      placeholder: t('Core Activity'),
      name: 'coreActivityTypeName',
      id: 'coreActivityTypeId',
      required: true,
      type: 'autocomplete',
      apiParams: { key: 'CRWACTTYPES' },
      spacing: 12
    },
    {
      label: t('Completion Activity'),
      placeholder: t('Completion Activity'),
      name: 'completionActivityTypeName',
      id: 'completionActivityTypeId',
      required: true,
      type: 'autocomplete',
      apiParams: { key: 'CRWACTTYPES' },
      spacing: 12
    },

    {
      label: t('Sea Service'),
      name: 'seaService',
      id: 'seaService',
      disabled: view,
      type: 'switch',
      spacing: 6
    },
    {
      label: t('Archived'),
      name: 'archived',
      id: 'archived',
      disabled: view,
      type: 'switch',
      spacing: 6
    }
  ]

  const initData = () => {
    return { ...data }
  }

  const methods = useForm<CrewingAssignmentTypeDataType>({
    resolver: zodResolver(CrewingAssignmentTypeSchema),
    mode: 'onChange',
    defaultValues: initData()
  })
  const onSuccessAction = () => {
    methods.reset(initData())
    onClose()
    refetch()
  }

  const onSubmit = (res: CrewingAssignmentTypeDataType) => {
    setIsSaving(true)
    const api = edit ? updateCrewingAssignmentType : addCrewingAssignmentType
    const payload = {
      ...res,
      archived: res?.archived ?? false
    }

    api(payload)
      .then((res) => {
        onSuccessAction()
        showSnackBar(res?.message)
      })
      .catch((err) => {
        showSnackBar(getErrorMessage(err), 'error')
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const handleCloseForm = () => {
    methods.reset(initData())
    onClose()
  }

  useEffect(() => {
    methods.reset({ ...data })
  }, [data])
  return (
    <DialogModal
      isOpen={open}
      small={true}
      type="sm"
      title={title}
      body={
        <div className="h-full relative w-full">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className={formContainerClass}>
                <FormBuilder data={formFields} edit={!view} />
              </div>
            </form>
          </FormProvider>
        </div>
      }
      onClose={handleCloseForm}
      actionLabel={edit ? 'Update' : view ? undefined : 'Save'}
      onSubmit={methods.handleSubmit(onSubmit)}
      secondaryAction={handleCloseForm}
      secondaryActionLabel={view ? 'Close' : 'Cancel'}
      actionLoader={isSaving}
    />
  )
}

import { useAuthStore } from '@/store/authStore'
import { useIdleTimeoutStore } from '@/store/idleTimeoutStore'
import { useEffect, useRef } from 'react'

interface UseIdleTimeoutProps {
  idleTimeBeforeWarning: number
  totalIdleTimeout: number
  onIdle: () => void
  events?: string[]
}

export const useIdleTimeout = ({
  idleTimeBeforeWarning,
  totalIdleTimeout,
  onIdle,
  events = ['mousemove', 'keydown', 'scroll', 'touchstart']
}: UseIdleTimeoutProps) => {
  const warningTimeoutRef = useRef<number | null>(null)
  const totalTimeoutRef = useRef<number | null>(null)
  const intervalRef = useRef<number | null>(null)
  // const tabId = useRef<string>(`tab_${Date.now()}`)

  const { authenticated } = useAuthStore()
  const { countDown, setCountDown, isIdle, setIsIdle, showPopup } = useIdleTimeoutStore()

  const resetTimeouts = () => {
    if (warningTimeoutRef.current !== null) {
      window.clearTimeout(warningTimeoutRef.current)
    }
    if (totalTimeoutRef.current !== null) {
      window.clearTimeout(totalTimeoutRef.current)
    }

    // setCountDown(0)
    warningTimeoutRef.current = window.setTimeout(() => {
      if (document.visibilityState === 'visible') {
        setIsIdle(true)
      }
      // const visibilityState = document.visibilityState
      // const allTabsState = JSON.parse(localStorage.getItem('tabsVisibilityState') || '{}')
      // const anyTabVisible = Object.values(allTabsState).some(
      //   (state) => state === 'visible'
      // )
      // if (visibilityState === 'visible' || !anyTabVisible) {
      //   setIsIdle(true)
      // }
      const endTime = Date.now() + (totalIdleTimeout - idleTimeBeforeWarning)
      updatecountDown(endTime)
    }, idleTimeBeforeWarning)

    totalTimeoutRef.current = window.setTimeout(() => {
      onIdle()
    }, totalIdleTimeout)
  }
  const clearAll = () => {
    window.clearTimeout(warningTimeoutRef.current!)
    window.clearTimeout(totalTimeoutRef.current!)
  }

  const updatecountDown = (endTime: number) => {
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current)
    }

    setCountDown(endTime - Date.now())

    intervalRef.current = window.setInterval(() => {
      const remaining = endTime - Date.now()
      if (remaining <= 0) {
        window.clearInterval(intervalRef.current!)
        setCountDown(0)
        localStorage.setItem('countDownValue', '0')
      } else {
        // const visibilityState = document.visibilityState
        // const allTabsState = JSON.parse(
        //   localStorage.getItem('tabsVisibilityState') || '{}'
        // )
        // const anyTabVisible = Object.values(allTabsState).some(
        //   (state) => state === 'visible'
        // )
        // if (visibilityState === 'visible' || !anyTabVisible) {
        //   localStorage.setItem('countDownValue', remaining.toString())
        //   setCountDown(remaining)
        // } else {
        //   setCountDown(0)
        // }
        if (document.visibilityState === 'visible') {
          localStorage.setItem('countDownValue', remaining.toString())
          setCountDown(remaining)
        } else {
          setCountDown(0)
        }
      }
    }, 1000)
  }
  const stopCountDown = () => {
    if (intervalRef.current !== undefined) {
      window.clearInterval(intervalRef.current!)
      intervalRef.current = null // Explicitly set to undefined after clearing
    }
  }

  //   const restartCountDown = (newEndTime: number) => {
  //     stopCountDown()
  //     updatecountDown(newEndTime)
  //   }
  useEffect(() => {
    if (authenticated) {
      const handleActivity = () => {
        if (!showPopup) {
          setIsIdle(false)
          resetTimeouts()
        }
      }

      events.forEach((event) => {
        window.addEventListener(event, handleActivity)
      })

      resetTimeouts()

      return () => {
        if (warningTimeoutRef.current !== null) {
          window.clearTimeout(warningTimeoutRef.current)
        }
        if (totalTimeoutRef.current !== null) {
          window.clearTimeout(totalTimeoutRef.current)
        }

        events.forEach((event) => {
          window.removeEventListener(event, handleActivity)
        })
      }
    }
  }, [idleTimeBeforeWarning, totalIdleTimeout, onIdle, events])

  // useEffect(() => {
  //   const updateVisibilityState = () => {
  //     const visibilityState = document.visibilityState
  //     const allTabsState = JSON.parse(localStorage.getItem('tabsVisibilityState') || '{}')
  //     allTabsState[tabId.current] = visibilityState
  //     localStorage.setItem('tabsVisibilityState', JSON.stringify(allTabsState))
  //   }

  //   document.addEventListener('visibilitychange', updateVisibilityState)

  //   // Initial set
  //   updateVisibilityState()

  //   return () => {
  //     document.removeEventListener('visibilitychange', updateVisibilityState)
  //     const allTabsState = JSON.parse(localStorage.getItem('tabsVisibilityState') || '{}')
  //     delete allTabsState[tabId.current]
  //     localStorage.setItem('tabsVisibilityState', JSON.stringify(allTabsState))
  //   }
  // }, [])

  return { isIdle, countDown, clearAll, stopCountDown, resetTimeouts }
}

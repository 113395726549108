import { debounce } from '@/utilities/deepDebounce'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { Portal } from '../../common'
import CustomIcons from '../icons/customIcons'
import Spinner from '../loader/Spinner'

type valueProps = {
  id?: string | number
  name: string
  param1?: string | number | null
  param2?: string | number | null
  param3?: string | number | null
}
interface AutoSuggestionInputProps {
  id?: string
  label?: string
  fullWidth?: boolean

  autoFocus?: boolean
  required?: boolean

  hideClear?: boolean
  value?: valueProps
  onChange: (value?: valueProps) => void
  data?: any[]
  type?: 'custom_select' | 'auto_complete' | 'custom_search_select'
  placeholder?: string
  getData?: ((key?: string) => Promise<any>) | ((key?: string) => any)
  errors?: FieldErrors
  name: string
  readOnly?: boolean
  disabled?: boolean
  autoFilter?: boolean

  insideOpen?: boolean
  isClose?: boolean
  noLocalFilter?: boolean
  isStaticList?: boolean
  isCustomPlaceholder?: boolean
  checkParams?: string[]
  fromPrefix?: boolean
  hasParentError?: boolean
  width?: number
}

const ModernAutoComplete: React.FC<AutoSuggestionInputProps> = ({
  label,
  onChange,

  getData,
  data,
  errors,
  required = false,
  autoFocus,
  name,
  fullWidth,
  placeholder,
  id,

  type = 'custom_select',
  readOnly,
  disabled = false,
  value,
  autoFilter = false,
  insideOpen = true,
  isClose = true,
  noLocalFilter = false,
  isStaticList = false,
  isCustomPlaceholder = false,
  checkParams,
  width,
  hasParentError,
  fromPrefix
}) => {
  const [inputValue, setInputValue] = useState<any>(value?.name ?? '')
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dropOpen, setDropOpen] = useState<boolean>(false)

  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)

  const [suggestions, setSuggestions] = useState<any>([])
  const inputRef = useRef<HTMLInputElement>(null)
  const adorementRef = useRef<HTMLDivElement>(null)
  const dropdownref = useRef<HTMLDivElement>(null)
  const dropBtnRef = useRef<HTMLButtonElement>(null)
  const [dropPosition, setDropPosition] = useState({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  })
  const checkIncludes = (
    mainString: string,
    subString: string,
    param1?: string | number,
    param2?: string | number,
    param3?: string | number
  ): boolean => {
    const checkIncludesIfExists = (mainString?: string | number, substring?: string) => {
      return substring &&
        mainString &&
        typeof mainString === 'string' &&
        typeof subString === 'string'
        ? mainString
            .toString()
            .toLowerCase()
            .startsWith(substring.toString().toLowerCase())
        : false
    }
    const checkArrayContains = (strArray: string[] | undefined, param: string): boolean =>
      strArray?.includes(param) ?? false

    return (
      checkIncludesIfExists(mainString, subString) ||
      (!!checkParams &&
        ((checkArrayContains(checkParams, 'param1') &&
          checkIncludesIfExists(param1, subString)) ||
          (checkArrayContains(checkParams, 'param2') &&
            checkIncludesIfExists(param2, subString)) ||
          (checkArrayContains(checkParams, 'param3') &&
            checkIncludesIfExists(param3, subString))))
    )
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setInputValue(value)
    handleValChange(value)
    if (!value) {
      setInputValue('')
      onChange({ id: undefined, name: '' })
    }
  }

  const handleValChange = useCallback(
    debounce(
      (value: string) => {
        setDropOpen(true)
        onChange({ id: undefined, name: '' })
        if (value.trim() === '' && type === 'auto_complete') {
          setSuggestions([])
          if (autoFilter) {
            handleDropData('*')
          } else {
            setDropOpen(false)
          }
        } else {
          handleDropData(value)
        }
      },
      isStaticList ? 0 : 1000
    ),
    []
  )
  const handleDropData = (value?: string) => {
    if (type === 'auto_complete') {
      if (isStaticList) {
        loadStaticData()
        const filteredData =
          value && value !== '*'
            ? data?.filter((item) =>
                checkIncludes(item.name, value, item.param1, item.param2, item.param3)
              )
            : data
        setSuggestions(filteredData)
      } else handlePickSuggestions(value)
    } else if (
      (type === 'custom_select' || type === 'custom_search_select') &&
      (!data || data?.length === 0)
    ) {
      handlePickSuggestions()
    } else if (type === 'custom_search_select' && data && data?.length > 0) {
      const filteredData =
        value && value !== '*'
          ? data.filter((item) =>
              checkIncludes(item.name, value, item.param1, item.param2, item.param3)
            )
          : data
      setSuggestions(filteredData)
    }
  }
  const handlePickSuggestions = async (value?: string) => {
    if (getData) {
      setIsLoading(true)
      try {
        const fetchedSuggestions = await getData?.(value)
        setSuggestions(fetchedSuggestions)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }
  }

  const onLabelClick = () => {
    if (!isDisabled) {
      inputRef?.current?.focus()
      if (autoFilter && inputValue === '') {
        handleValChange('*')
      } else if (
        (type === 'custom_select' || type === 'custom_search_select') &&
        data &&
        data?.length > 0
      ) {
        handleDropData()
        setDropOpen(true)
      }
    }
  }
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownref.current && !dropdownref.current.contains(event.target as Node)) {
      setDropOpen(false)
    }
  }
  const checkIsEmptyField = (): boolean => {
    if (inputValue === undefined) return true
    else return (inputValue?.toString().length ?? 0) <= 0 ? true : false
  }
  const loadStaticData = async () => {
    if (!data) {
      data = []
      if (getData) {
        try {
          setIsLoading(true)
          handlePickSuggestions('*')
          const fetchedSuggestions = await getData?.('*')
          fetchedSuggestions?.forEach((element: any) => {
            data?.push(element)
          })
          setIsLoading(false)
        } catch (error) {
          setIsLoading(false)
        }
      }
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (autoFocus) {
      onLabelClick()
    }
  }, [autoFocus])
  const onInputFocus = () => {
    if (!isDisabled) {
      if (autoFilter && inputValue === '') {
        handleValChange('*')
      } else if (
        (type === 'custom_select' || type === 'custom_search_select') &&
        data &&
        data?.length > 0
      ) {
        handleDropData()
        //if (insideOpen) setDropOpen(true) // commeted this code bcoz added onclick function inside
      }
    }
  }
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])
  const handleSuggestionClick = (suggestion: valueProps) => {
    onChange({
      id: suggestion?.id,
      name: suggestion.name,
      param1: suggestion.param1,
      param2: suggestion.param2,
      param3: suggestion.param3
    })
    if (type !== 'custom_select') setInputValue('')
    setDropOpen(false)
  }
  const handleOpen = (e: any) => {
    if (!suggestions || suggestions?.length === 0) handleDropData()
    if (!isLoading) {
      setDropOpen(!dropOpen)
      if (type !== 'custom_select') setInputValue('')
    }
    if (!insideOpen) {
      adjustDropdownPoint(e)
    }
  }

  const adjustDropdownPoint = (e: any) => {
    if (inputRef?.current) {
      const rect = inputRef?.current.getBoundingClientRect()
      setDropPosition(rect)
    }
  }

  const handleScroll = () => {
    setDropOpen(false)
  }

  useEffect(() => {
    if (!insideOpen) {
      window.addEventListener('scroll', handleScroll)

      const mainElement = document.querySelector('main')
      mainElement?.addEventListener('scroll', handleScroll)

      const gridElements = document.querySelectorAll('.k-grid-content')
      gridElements.forEach((gridElement: any) => {
        gridElement.addEventListener('scroll', handleScroll)
      })

      return () => {
        window.removeEventListener('scroll', handleScroll)
        const mainElement = document.querySelector('main')
        mainElement?.removeEventListener('scroll', handleScroll)
        const gridElements = document.querySelectorAll('.k-grid-content')
        gridElements.forEach((gridElement: any) => {
          gridElement.removeEventListener('scroll', handleScroll)
        })
      }
    }
  }, [dropOpen])

  const handleClear = () => {
    setDropOpen(false)
    setInputValue('')
    onChange({ id: undefined, name: '' })
    onLabelClick()
  }

  useEffect(() => {
    setInputValue(value?.name ?? '')
  }, [value?.name])

  useEffect(() => {
    setSuggestions(data)
  }, [data])

  const getErrors = (err: any) => {
    let errMsg = ''
    if (err.message) {
      errMsg = err?.message
    } else if (err?.id?.message) {
      errMsg = err?.id?.message
    } else if (err?.name?.message) {
      errMsg = err?.name?.message
    }
    return errMsg
  }
  const generateClassName = (
    type: 'input' | 'label' | 'message' | 'adorement'
  ): string => {
    let className = ' '
    switch (type) {
      case 'input':
        className += `block text-common text-input-text font-normal px-3.5 w-full text-sm text-gray-900 bg-transparent  border  appearance-none    peer h-10 rounded-[4px] disabled:text-input-disabled bg-white disabled:bg-disabled ${label ? 'placeholder-transparent' : 'focus:placeholder-grey-secondary placeholder-input-label'} focus:placeholder-grey-secondary`

        if (errors && errors[name]) {
          className +=
            ' border-[#FDA29B] focus:border-error-[#FDA29B] focus:ring-[#FDA29B] focus:ring-3 focus:outline-[#FDA29B] input-outline'
        } else {
          className +=
            ' text-grey-dark border-input-light focus:border-blue-navy  focus:outline-none  focus:ring-0'
        }

        break
      case 'label':
        className += ` modern-input-label  peer-focus:modern-input-peer-focus-label-size 
          bg-white  absolute   duration-300 transform -translate-y-4  top-2 z-1 origin-[0]  px-0 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2  peer-focus:-translate-y-4 start-[14px] rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto ${
            isDisabled ? 'cursor-pointer' : 'cursor-text peer-focus:cursor-pointer'
          }
           ${
             checkIsEmptyField()
               ? 'modern-input-label-size'
               : 'modern-input-peer-focus-label-size'
           }`
        if (errors && errors[name]) {
          className += ' text-error-light '
        } else {
          className += ' text-grey-dark peer-focus:text-blue-navy'
        }
        break
      case 'message':
        className = ' text-error-icon '
        break
      case 'adorement':
        className += '  absolute right-0 adorement gap-1 flex items-center '
        break
      default:
        break
    }
    return className
  }
  const handleClose = () => {
    setTimeout(() => {
      setDropOpen(false)
    }, 500)
  }
  const handleClearInputValue = () => {
    // setInputValue(value?.name ?? '')
  }
  const getInnerWidth = () => {
    const innerwidth = adorementRef.current ? adorementRef.current.offsetWidth : 0
    return innerwidth
  }
  const getPosition = () => {
    return 'bottom'
  }
  const filteredData =
    inputValue !== '*' && type !== 'custom_select' && !noLocalFilter
      ? suggestions?.filter((item: valueProps) =>
          checkIncludes(
            item.name,
            inputValue,
            item.param1 ?? '',
            item.param2 ?? '',
            item.param3 ?? ''
          )
        )
      : suggestions

  const handleError = (data: any) => {
    if (getErrors(data[name]) === 'required' || getErrors(data[name]) === 'Required') {
      return `${label} is ${getErrors(data[name])}` ?? ''
    } else {
      return getErrors(data[name]) ?? ''
    }
  }

  const getDropPosition = () => {
    if (!insideOpen) {
      const width = inputRef?.current ? inputRef?.current?.clientWidth : 150
      return {
        top: `${dropPosition.top + window.scrollY}px`,

        left: `${dropPosition.left + window.scrollX}px`,
        width: `${width}px`
      }
    }
    return undefined
  }
  const [showNoResults, setShowNoResults] = useState(false)

  useEffect(() => {
    if (inputValue && filteredData?.length === 0 && !isLoading) {
      const timer = setTimeout(() => {
        setShowNoResults(true)
      }, 300) // Delay in milliseconds
      return () => clearTimeout(timer)
    } else {
      setShowNoResults(false)
    }
  }, [inputValue, filteredData, isLoading])

  const setDropDown = () => {
    return (
      (filteredData?.length > 0 || showNoResults) && (
        <ul
          className=" absolute h-auto max-h-40 overflow-auto w-full bg-white shadow-gray-300 shadow-md border border-grey-light py-1.5 z-50  mt-9"
          style={getDropPosition()}
        >
          {filteredData?.length > 0 ? (
            <>
              {filteredData.map((suggestion: any, index: number) => (
                <li
                  className={`${
                    value?.id === suggestion?.id
                      ? 'bg-blue-navy text-white'
                      : 'hover:bg-table-hover'
                  } cursor-pointer p-1  text-xxs ps-3.5`}
                  key={suggestion?.id}
                  data-testid={suggestion.name}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.name}
                </li>
              ))}
            </>
          ) : (
            showNoResults && (
              <li
                className={`$ cursor-pointer p-1 rounded-sm text-xxs`}
                onClick={handleClose}
              >
                No Results Found
              </li>
            )
          )}
        </ul>
      )
    )
  }
  const getBorderRight = () => {
    if (fromPrefix) {
      if ((hasParentError && Boolean(errors && errors[name])) || hasParentError) {
        return 0
      } else if (errors && errors[name]) {
        return undefined
      }
    }
    return 0
  }
  return (
    <div
      className={` flex-grow ${getPosition()} ${fullWidth ? 'w-full' : 'w-auto'}`}
      ref={dropdownref}
    >
      <div className="tooltip-container">
        {isHovered && errors && errors[name] && (
          <span className="tooltip">{handleError(errors)} </span>
        )}
        <div
          className={`flex relative ${fullWidth ? 'w-full' : 'w-auto'}`}
          style={{ width: width }}
        >
          <div className="relative w-full">
            <input
              id={id}
              type="text"
              readOnly={readOnly ?? type === 'custom_select'}
              value={inputValue ? inputValue : ''}
              onBlur={handleClearInputValue}
              disabled={disabled}
              ref={inputRef}
              data-testid="custom-autocomplete"
              // onBlur={() => {
              // setInputValue("")
              // }}
              aria-describedby={id}
              style={{
                paddingRight: getInnerWidth(),
                ...(fromPrefix
                  ? {
                      borderRight: getBorderRight(),
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0
                    }
                  : {})
              }}
              className={` ${generateClassName('input')}`}
              onChange={handleChange}
              placeholder={
                type === 'auto_complete'
                  ? placeholder && isCustomPlaceholder
                    ? placeholder
                    : 'Type to search'
                  : placeholder ?? '--Select--'
              }
              onFocus={onInputFocus}
              onClick={(e) => {
                if (type === 'custom_select') {
                  setDropOpen(!dropOpen)
                  handleOpen(e)
                } else {
                  setDropOpen(!dropOpen)
                }
              }}
            />
            {label && (
              <label
                htmlFor={id}
                onClick={() => onLabelClick()}
                className={generateClassName('label')}
              >
                {label}
                {required && label ? <span className="text-error"> *</span> : <></>}
              </label>
            )}
          </div>
          <div className="flex items-center justify-center ">
            <div
              ref={adorementRef}
              className={`${generateClassName('adorement')} mr-[1px] ${
                isLoading ? 'bg-white' : ''
              }`}
            >
              {value?.name &&
                (value?.id || String(value?.id) === '0') &&
                !disabled &&
                !readOnly &&
                isClose && (
                  <button
                    onClick={() => handleClear()}
                    className=" text-table-bodyColor"
                    aria-label="close"
                    type="button"
                  >
                    <CustomIcons name="close" type="large-m" />
                  </button>
                )}
              {isLoading && <Spinner />}
              {type !== 'auto_complete' && !disabled && !readOnly && (
                <button
                  disabled={disabled ?? readOnly}
                  onClick={(e) => handleOpen(e)}
                  onBlur={handleClose}
                  className=" text-[#667085] focus-visible:outline-slate-100"
                  data-testid="drop-arrow"
                  type="button"
                  ref={dropBtnRef}
                >
                  <CustomIcons
                    onClick={(e) => handleOpen(e)}
                    name={`${dropOpen ? 'menu-up-arrow' : 'menu-down-arrow'}`}
                    type="large"
                    style={{ pointerEvents: 'none' }}
                  />
                </button>
              )}
              {errors && errors[name] && (
                <div
                  className={` text-error-label relative cursor-pointer ${generateClassName(
                    'message'
                  )}`}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CustomIcons name="alert" type="medium" />
                </div>
              )}
            </div>
          </div>
          {dropOpen && (!isLoading || filteredData?.length > 0) && (
            <>{insideOpen ? setDropDown() : <Portal>{setDropDown()}</Portal>}</>
          )}
        </div>
      </div>
    </div>
  )
}

export default ModernAutoComplete

import { FormBuilder } from '@/components/common'
import { formContainerClass } from '@/styles/variables'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type CreateAntiFoulingProps = {
  viewable: boolean
}

export const CreateAntiFoulingPaint: React.FC<CreateAntiFoulingProps> = ({
  viewable
}) => {
  const { t } = useTranslation()
  const { setValue } = useFormContext()
  const formFields = [
    {
      label: t('Paint Type'),
      placeholder: t('Select Paint Type'),
      name: 'paintTypeName',
      id: 'paintTypeId',
      type: 'autocomplete',
      apiParams: { key: 'PAINTTYPE', filter1: '' },
      additionalValue: true,
      required: true,
      spacing: 4
    },
    {
      label: t('Paint Maker'),
      placeholder: t('Paint Maker'),
      name: 'makerName',
      id: 'makerId',
      type: 'text',
      disabled: true,
      spacing: 4
    },
    {
      label: t('Life'),
      placeholder: t('Enter Life'),
      name: 'life',
      type: 'number',
      adorement: 'Months',
      adornment: t('Months'),
      spacing: 4
    },
    {
      label: t('Paint Surface'),
      placeholder: t('Select Paint Surface'),
      name: 'paintingSurfaceName',
      id: 'paintingSurfaceId',
      type: 'autocomplete',
      required: true,
      apiParams: { key: 'SURFACETYPE', filter1: '' },

      spacing: 4
    },
    {
      label: t('Preparation Type'),
      placeholder: t('Select Preparation Type'),
      name: 'prparationTypeName',
      id: 'prparationTypeId',
      apiParams: { key: 'SURFACEPREPTYPE', filter1: '' },

      type: 'autocomplete',
      required: true,
      spacing: 4
    },
    {
      label: t('SPB With Tie Coat'),
      name: 'tiecoat',
      type: 'switch',
      spacing: 4
    },
    {
      label: t('DFT'),
      placeholder: t('Enter DFT'),
      name: 'dft',
      type: 'number',
      spacing: 4
    },
    {
      label: t('Upto Draft'),
      placeholder: t('Enter Upto Draft'),
      name: 'paintUpToDraft',
      type: 'number',
      spacing: 4
    },
    {
      label: t('Remarks'),
      placeholder: t('Enter Remarks'),
      name: 'remarks',
      id: 'remarksId',
      type: 'textarea',
      multiline: true,
      rowsMax: 3,
      rows: 3,
      spacing: 12
    }
  ]
  const handleSetAdditionalValues = (key: string, value: any) => {
    if (key === 'paintTypeId') {
      setValue('makerName', value.param2)
      setValue('makerId', value.param1)
    }
  }
  return (
    <div className={formContainerClass}>
      <FormBuilder
        data={formFields}
        edit={!viewable}
        isView={viewable}
        setAdditionalValues={handleSetAdditionalValues}
      />
    </div>
  )
}

import { Loader } from '@/components/common'

const LoaderComponent = () => {
  return (
    <div className="flex flex-wrap ">
      <div className="relative flex items-center justify-center h-[500px] w-full">
        <Loader />
      </div>
    </div>
  )
}

export default LoaderComponent

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const DashIconBar: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M1.66663 10H3.33329V17.5H1.66663V10ZM4.16663 11.6667H5.83329V17.5H4.16663V11.6667ZM13.3333 6.66666H15V17.5H13.3333V6.66666ZM15.8333 8.33333H17.5V17.5H15.8333V8.33333ZM7.49996 1.66666H9.16663V17.5H7.49996V1.66666ZM9.99996 3.33333H11.6666V17.5H9.99996V3.33333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DashIconBar

const CheckSwitchIcon = () => {
  const t = `${new Date().getMilliseconds()}-${Math.random()}`
  return (
    <>
      <g clipPath={`url(#clip0_1931_4396${t})`}>
        <mask
          id={`mask0_1931_4396${t}`}
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18"
        >
          <path
            d="M9.00001 16.7097C9.98511 16.711 10.9607 16.5176 11.8709 16.1406C12.781 15.7636 13.6076 15.2105 14.3033 14.513C15.0007 13.8173 15.5539 12.9907 15.9309 12.0806C16.3079 11.1705 16.5013 10.1948 16.5 9.20972C16.5013 8.22463 16.3078 7.24899 15.9308 6.33888C15.5539 5.42878 15.0007 4.60214 14.3033 3.90647C13.6076 3.20898 12.781 2.65585 11.8709 2.27886C10.9607 1.90186 9.98511 1.70845 9.00001 1.70972C8.01491 1.70847 7.03927 1.90189 6.12917 2.27888C5.21906 2.65588 4.39242 3.209 3.69676 3.90647C2.99928 4.60214 2.44616 5.42878 2.06917 6.33888C1.69218 7.24899 1.49875 8.22463 1.50001 9.20972C1.49873 10.1948 1.69215 11.1705 2.06914 12.0806C2.44613 12.9907 2.99927 13.8173 3.69676 14.513C4.39242 15.2104 5.21906 15.7636 6.12917 16.1406C7.03927 16.5176 8.01491 16.711 9.00001 16.7097Z"
            fill="white"
            stroke="white"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M6 9.20972L8.25 11.4597L12.75 6.95972"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </mask>
        <g mask={`url(#mask0_1931_4396${t})`}>
          <path d="M0 0.209717H18V18.2097H0V0.209717Z" fill="currentColor" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip0_1931_4396${t}`}>
          <rect width="18" height="18" fill="white" transform="translate(0 0.209961)" />
        </clipPath>
      </defs>
    </>
  )
}
export default CheckSwitchIcon

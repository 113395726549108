const ENTITY_MASTER_DATA_MIPS = 'Entity'
const VESSEL_REGISTER = '/v1/Vessels'
const COUNTRIES = '/v1/Countries'
const DEPARTMENTS_MIPS = 'Department/'
const CURRENCIES = '/v1/Currencies'
const COMPANY = '/v1/Companies'
const VESSEL_CLASSES = '/v1/VesselClasses'
const VESSEL_STATUSES_MIPS = 'VesselStatuses/'
const DESIGNATIONS = '/v1/Designations'
const FLEETS = '/v1/Fleets'
const PORTS = '/v1/Ports'
const COMPANY_BRANCH = '/v1/CompBranch'
const COMPANY_CURRENCY = '/v1/CompCurrency'
const COMPANY_SERVICE = '/v1/CompService'
const COMPANY_SERVICE_MIPS = 'CompanyService'
const WORKFLOWS = '/v1/Workflow'
const WORKFLOWS_MIPS = '/Workflow/GetAllWorkflows'
const UPDATE_WORKFLOW_STATUS = '/v1/workflow/UpdateObjStatus'
const WORKFLOW_STATUS = '/v1/Workflow/GetworkflowStatus?Id='
const CREATE_WORKFLOW_STATUS = '/v1/Workflow/AddWorkflowStatus'
const MASTERS_MAKERS = '/v1/Makers/'
const REGIONS = 'v1/Regions'
const MASTERS_AUTHORITIES = '/v1/Authority'
const SERVICE_TYPES = 'v1/ServiceTypes'
const CONSUMPTION_TYPES = 'v1/OilConsTypes'
const UNIT_OF_MEASUREMENT_MIPS = 'Units'
const VESSEL_TYPES = '/v1/VesselTypes'
const VESSEL_SUB_TYPES = '/v1/VesselSubTypes'
const VESSEL_OIL_TYPES = '/v1/OilTypes'
const SULPHUR_CONTENT_TYPES = '/v1/SulphurContTypes'
const COUNTRIESMIPS = 'Countries'
const CMS = 'ContentManager'
const apiMasterDataUrl = {
  ENTITY_MASTER_DATA_MIPS,
  VESSEL_REGISTER,
  COUNTRIES,
  COUNTRIESMIPS,
  DEPARTMENTS_MIPS,
  CURRENCIES,
  COMPANY,
  DESIGNATIONS,
  FLEETS,
  PORTS,
  COMPANY_BRANCH,
  COMPANY_CURRENCY,
  COMPANY_SERVICE,
  COMPANY_SERVICE_MIPS,
  UPDATE_WORKFLOW_STATUS,
  WORKFLOWS,
  WORKFLOWS_MIPS,
  CREATE_WORKFLOW_STATUS,
  WORKFLOW_STATUS,
  MASTERS_MAKERS,
  VESSEL_CLASSES,
  REGIONS,
  VESSEL_STATUSES_MIPS,
  MASTERS_AUTHORITIES,
  SERVICE_TYPES,
  CONSUMPTION_TYPES,
  UNIT_OF_MEASUREMENT_MIPS,
  VESSEL_TYPES,
  VESSEL_SUB_TYPES,
  VESSEL_OIL_TYPES,
  SULPHUR_CONTENT_TYPES,
  CMS
}

export default apiMasterDataUrl

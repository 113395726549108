import { crewingRouterStore } from '@/modules/crewing/store'
import { operationsRouterStore } from '@/modules/operations/store'
import { payrollRouterStore } from '@/modules/payroll/store'
import { pmsRouterStore } from '@/modules/pms/store'
import { technicalRouterStore } from '../modules/technical/store'
import { masterDataRouterStore } from './routeStore/masterdata'
import { systemRouterStore } from './routeStore/system'

export const coreModules = {
  TECHNICAL: 'TECHNICAL',
  TECHNICALMIPS: 'TECHNICALMIPS',
  SYSTEM: 'SYSTEM',
  SYSTEMMIPS: 'SYSTEMMIPS',
  PURCHASE: 'PURCHASE',
  OPERATIONS: 'OPERATIONS',
  FINANCE: 'FINANCE',
  MASTERDATA: 'MASTER',
  MASTERDATAMIPS: 'MASTERMIPS',
  PMS: 'PMS',
  CREWING: 'CREWING',
  PAYROLL: 'PAYROLL',
  PMSMIPS: 'PMSMIPS'
}

export const routerStore = {
  ...technicalRouterStore,
  ...systemRouterStore,
  ...masterDataRouterStore,
  ...crewingRouterStore,
  ...operationsRouterStore,
  ...payrollRouterStore,
  ...pmsRouterStore
}

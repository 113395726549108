import { Button } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'

import React from 'react'
import { useNavigate } from 'react-router-dom'

const SuccessSection: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className={``}>
      <div className={`mx-auto p-2.5 text-center `}>
        <div className="flex items-center justify-center">
          <CustomIcons
            name={'stepper_success'}
            isWrapper={false}
            className="flex items-center justify-center "
            data-testid="button-icon-left"
          />
        </div>
        <h3 className="text-xl text-dark font-semibold pt-5">{'Success!'}</h3>
        <p className="pt-3 font-normal text-grey-secondary">
          {'  New Budget has been created successfully.'}
        </p>
        <div className="pt-10 flex items-center justify-center">
          <Button
            primary={false}
            label="Done"
            onClick={() => {
              navigate(-1)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default SuccessSection

import { TabItemProps } from '@/common/customComponenttypes'
import { Tab, TabContainer } from '@/components/common'
import { useState } from 'react'

const TabComponent = () => {
  const [active, setActive] = useState<string | number>(1)

  const tabData = [
    { label: 'PMS', id: 1 },
    { label: 'Technical', id: 2, disabled: true },
    { label: 'Operations and voyages', id: 3 }
  ]
  const onTabClick = (item: TabItemProps) => {
    setActive(item.id)
  }
  const onAdd = () => {
    console.log('Add button pressed')
  }
  return (
    <div className="">
      <TabContainer
        data={tabData}
        activeTab={active}
        onClick={onTabClick}
        newTab
        onAdd={onAdd}
      >
        <Tab id={1}>
          <h2>hello</h2>
        </Tab>
        <Tab id={2}>
          <h2>hello1</h2>
        </Tab>
        <Tab id={3}>
          <h2>hello3</h2>
        </Tab>
      </TabContainer>
    </div>
  )
}

export default TabComponent

import {
  deleteCrewData,
  getCrewData,
  postCrewData,
  updateCrewData
} from '@/apis/api.helpers'
import apiCrewingUrl from '@/modules/crewing/apis/api.urls'
import { blobToFile } from '@/utilities'
import { useQuery } from '@tanstack/react-query'

export const fetchCrew = async (filters: any) => {
  const { data } = await postCrewData(`${apiCrewingUrl.CREWING_CREW}/Search`, {
    ...filters
  })
  data?.results.forEach((item: any, i: number) => {
    item['theme'] = item?.rankGroupColor ? `#${item?.rankGroupColor}` : ''
    item['avatar'] = blobToFile(item?.crewPhoto, item?.fileName) ?? '/assets/User.png'
  })
  return data
}
export const useCrewsList = (filters: any, isEnabled: boolean) => {
  return useQuery({
    queryKey: ['crewing-crew-list', filters],
    queryFn: () => fetchCrew(filters),
    // initialData: false,
    staleTime: 500,
    enabled: isEnabled
  })
}

export const addCrew = (data: any) => {
  return postCrewData(`${apiCrewingUrl.CREWING_CREW}/RegisterCrew`, data)
}

export const updateCrew = (data: any) => {
  return updateCrewData(`${apiCrewingUrl.CREWING_CREW_DETAILS}/Update`, data)
}
export const fetchCrewById = (id: string) => {
  return getCrewData(`${apiCrewingUrl.CREWING_CREW_DETAILS}/GetDetails/${id}`)
}
export const fetchCrewHeaderById = (id: string) => {
  return getCrewData(`${apiCrewingUrl.CREWING_CREW_DETAILS}/GetHeaderDetails/${id}`)
}

export const addCrewPhoto = (data: any) => {
  return postCrewData(`${apiCrewingUrl.CREWING_CREW_DETAILS}/UploadCrewPhoto`, data)
}

export const deleteCrew = (id: string) => {
  return deleteCrewData(`${apiCrewingUrl.CREWING_CREW}/${id}`)
}

// Kin API's
export const fetchKins = async (filters: any) => {
  const { data } = await postCrewData(
    `${apiCrewingUrl.CREWING_CREW_DETAILS}/SearchKinDetails`,
    {
      ...filters
    }
  )
  return data
}
export const fetchKinById = (id: string) => {
  return getCrewData(`${apiCrewingUrl.CREWING_CREW_DETAILS}/GetKinDetails/${id}`)
}
export const updateKinDetails = (data: any) => {
  return updateCrewData(`${apiCrewingUrl.CREWING_CREW_DETAILS}/UpdateKinDetails`, data)
}
export const updateKinAddressDetails = (data: any) => {
  return updateCrewData(
    `${apiCrewingUrl.CREWING_CREW_DETAILS}/UpdateKinAddressDetails`,
    data
  )
}
export const addCrewKin = (data: any) => {
  return postCrewData(`${apiCrewingUrl.CREWING_CREW_DETAILS}/AddCrewKinDetails`, data)
}

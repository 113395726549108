export const pmsRouterStore: { [key: string]: string } = {
  //registers start
  MATERIAL_STORE: 'pms/matstore',
  SPECIFICATION_PARAMETERS: 'pms/specitems',
  SPECIFICATION_PARAMS: 'pms/spectemplate',
  MATERIAL_TYPES: 'pms/mattype',
  DEFECT_CAUSE_ITEMS: 'pms/defectcaueitem',
  DEFECT_CAUSE_TYPES: 'pms/defectcausetype',
  DEFECT_CATEGORY: 'pms/defectcategory',
  CERTIFICATE_TYPES: 'pms/certtype',
  CERTIFICATE_AUTHORITIES: 'pms/certauth',
  DOCUMENT_TYPES: 'pms/doctype',
  JOB_TYPES: 'pms/jobtype',
  JOB_ENFORCERS: 'pms/jobenforcer',
  JOB_DEPARTMENTS: 'pms/jobdept',
  ACCESSORY_WORKS: 'pms/accesswork'
  //registers end
}

import React from 'react'
import ReactApexChart from 'react-apexcharts'

import { dashboardDummyDataGen } from '@/utilities/dataGenerators'
import { ApexOptions } from 'apexcharts'

const Area: React.FC = () => {
  const series = [
    {
      name: 'Draft',
      color: '#F1B635',
      data: dashboardDummyDataGen([50, 40, 28, 51])
    },
    {
      name: 'Final',
      color: '#FF681D',
      data: dashboardDummyDataGen([11, 32, 45, 32])
    },
    {
      name: 'Closed',

      color: '#003C71',
      data: dashboardDummyDataGen([21, 40, 18, 11])
    },
    {
      name: 'Rejected',

      color: '#006DCD',
      data: dashboardDummyDataGen([41, 37, 46, 38])
    }
  ]
  const options: ApexOptions = {
    chart: {
      height: 200,
      type: 'area',
      toolbar: {
        show: false
      },

      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },

    xaxis: {
      type: 'category',
      categories: ['Alpha Ship', 'Antartic', 'Beta Ship', 'Gamma Ship']
    }
    // tooltip: {
    //   x: {
    //     format: 'dd/MM/yy HH:mm'
    //   }
    // }
  }

  return (
    <div>
      <ReactApexChart options={options} series={series} type="area" height={200} />
    </div>
  )
}

export default Area

import { Columns } from '@/common/customComponenttypes'
import formatDate from '@/utilities/usedateformat'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { GridPDFExport } from '@progress/kendo-react-pdf'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'

type ColumnDef = Columns

type ExportProps = {
  data: any
  columns: ColumnDef[]
  title: string | React.ReactNode
  exportFileName?: string | (() => string)
  exportDelimiter?: string
  CustomRender: React.FC<any>
  paperSize?: string
}
export interface ExportRef {
  download: (slug: string) => void
}

const KendoExport = forwardRef<ExportRef, ExportProps>(
  ({ data, columns, CustomRender, title, exportFileName, paperSize }, ref) => {
    const gridExcelExport = useRef<ExcelExport>(null)
    const gridPDFExport = useRef<GridPDFExport>(null)

    KendoExport.displayName = 'KendoExport'
    const download = (slug: string) => {
      if (slug === 'pdf') {
        exportPDF()
      } else {
        exportExcel()
      }
    }

    const exportPDF = () => {
      if (gridPDFExport && gridPDFExport.current) {
        gridPDFExport.current.save()
      }
    }

    const exportExcel = () => {
      if (gridExcelExport.current) {
        const updatedColumns: Omit<ColumnDef, 'hidden'>[] = columns.map(
          ({ hidden, ...rest }) => rest
        )

        const formattedData = formatDataForExportExcel(data)
        gridExcelExport.current.save(formattedData, updatedColumns)
      }
    }

    useImperativeHandle(ref, () => ({
      download: download
    }))

    const formatDataForExportExcel = (data: any) => {
      if (data?.data) {
        const formatData = data?.data.map((item: any) => {
          return Object.keys(item).reduce((acc: any, key: string) => {
            const column = columns.find((col: any) => col.field === key)
            if (column && column.type === 'date') {
              acc[key] = item[key] ? formatDate(item[key].toString()) : null
            } else if (column && column.type === 'dateTime') {
              acc[key] = item[key]
                ? formatDate(item[key].toString(), 'DD-MMM-YYYY hh:mm')
                : null
            } else if (column && column.type === 'boolean') {
              acc[key] = item[key] ? 'Yes' : null
            } else {
              acc[key] = item[key]
            }
            return acc
          }, {})
        })
        return formatData
      }
      return data
    }

    const getFileName = (type: string) => {
      const fileName = typeof title === 'string' ? title : 'data'
      return (exportFileName || fileName || 'data') + '.' + type
    }

    const getExportColumnOptions = (column: any) => {
      if (column.type === 'boolean') return CustomRender
      else if (column.type === 'status') return CustomRender
      else if (
        column.type === 'date' ||
        column.type === 'dateTime' ||
        column.type === 'dateSec'
      )
        return CustomRender
      return undefined
    }

    const CustomPdfHeaderCell = (props: any) => {
      const { title, type } = props
      return (
        <th
          className={`font-semibold text-m ${type === 'boolean' ? 'text-center flex justify-center' : ' '}`}
        >
          {title}
        </th>
      )
    }
    return (
      <>
        <ExcelExport ref={gridExcelExport} fileName={getFileName('xlsx')} />
        <GridPDFExport
          key={`grid-export-${new Date().toString()}`}
          scale={0.8}
          ref={gridPDFExport}
          fileName={getFileName('pdf')}
          paperSize={columns.length > 5 ? paperSize ?? 'auto' : paperSize ?? 'A4'}
          repeatHeaders={true}
        >
          <Grid data={data}>
            {columns.map((column, index) => {
              return (
                <GridColumn
                  cell={getExportColumnOptions(column)}
                  field={column.field}
                  title={column.title}
                  width="auto"
                  headerCell={(props) => (
                    <CustomPdfHeaderCell {...props} type={column.type} />
                  )}
                  key={index}
                  className="break-all"
                />
              )
            })}
          </Grid>
        </GridPDFExport>
      </>
    )
  }
)

export default KendoExport

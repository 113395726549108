import {
  getTechnicalData,
  postTechnicalData,
  updateTechnicalData,
  deleteTechnicalData
} from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useQuery } from '@tanstack/react-query'

export const useBudgAssumptions = (params: any) => {
  const fetchData = async () => {
    return await postTechnicalData(`${apiTechnicalUrl.BUDGET_ASSUMPTION}`, params)
  }
  return useQuery({
    queryKey: ['vessel-budget-assumptions'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}
export const updateBudgetRemark = (data: any) => {
  return updateTechnicalData(`${apiTechnicalUrl.BUDGET_REMARKUPDATE}`, data)
}

export const addAssumptionData = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.BUDGET_ASSUMPTION_CREATE}`, data)
}

export const viewAssumptionData = (id: any) => {
  return getTechnicalData(`${apiTechnicalUrl.GET_VESSEL_BUDGET}/GetAssumption?Id=${id}`)
}

export const updateAssumptionData = (data: any) => {
  return updateTechnicalData(
    `${apiTechnicalUrl.GET_VESSEL_BUDGET}/UpdateAssumption`,
    data
  )
}

export const deleteAssumptionData = (id: any) => {
  return deleteTechnicalData(
    `${apiTechnicalUrl.GET_VESSEL_BUDGET}/DeleteAssumption?Id=${id}`
  )
}

import { TabItemProps } from '@/common/customComponenttypes'
import { Tab, TabContainer } from '@/components/common'
import { useState } from 'react'

import Accordion from './accordion'
import BreadcrumbComponent from './breadcrumb'
import ButtonComponent from './button'
import ButtonWithLoaderComponent from './buttonWithLoader'
import CheckboxComponent from './checkbox'
import ColorFieldComponent from './color'
import CustomTableComponent from './customTable'
import FabComponent from './fab'
// import KendoComponent from './kendo'
import LoaderComponent from './loader'
import ModalCompoent from './modal'
import PresetColorsComponent from './presetColor'
import RadioComponent from './radio'
import SelectBox from './selectBox'
import SwitchComponent from './switch'
import TabComponent from './tab'
import TextAreaComponent from './textarea'
import TextFieldComponent from './textfield'
import Mentions from './mentions'

const CustomComponents = () => {
  const [active, setActive] = useState<string | number>(1)
  const components = [
    { id: 17, label: 'Buttons', component: ButtonComponent },
    { id: 20, label: 'Mentions', component: Mentions },
    { id: 2, label: 'Button with loader', component: ButtonWithLoaderComponent },
    { id: 3, label: 'Page loader', component: LoaderComponent },
    { id: 4, label: 'Fab Button', component: FabComponent },
    { id: 5, label: 'Switch Button', component: SwitchComponent },
    { id: 6, label: 'Checkbox', component: CheckboxComponent },
    { id: 7, label: 'Radio', component: RadioComponent },
    // { id: 8, label: 'Icons', component: IconComponent },
    { id: 9, label: 'Textfield', component: TextFieldComponent },
    { id: 10, label: 'Textarea', component: TextAreaComponent },
    { id: 11, label: 'Auto Complete', component: SelectBox },
    { id: 12, label: 'Modal Popups', component: ModalCompoent },
    { id: 13, label: 'Breadcrumbs', component: BreadcrumbComponent },
    { id: 14, label: 'Tabs', component: TabComponent },
    { id: 15, label: 'Table', component: CustomTableComponent },
    { id: 16, label: 'Accordion', component: Accordion },
    { id: 18, label: 'Color', component: ColorFieldComponent },
    { id: 19, label: 'Preset Color', component: PresetColorsComponent }
    // { id: 1, label: 'Kendo', component: KendoComponent }
  ]
  const onTabClick = (item: TabItemProps) => {
    setActive(item.id)
  }
  return (
    <div className="p-3 mx-auto">
      {/* <Headertitle title="Sample Components" /> */}
      <div className="bg-white p-5 rounded">
        {/* {components.map((comp, i) => (
          <div key={comp.id}>
            <div className="p-3 rounded w-full comp-header"> {comp.title}</div>
            <div className="p-5 w-full comp-body">
              <comp.component />
            </div>
          </div>
        ))} */}
        <TabContainer data={components} activeTab={active} onClick={onTabClick}>
          {components.map((comp, i) => (
            <Tab id={comp.id} key={comp.id}>
              <div>
                <div className="p-5 w-full comp-body-tab">
                  <comp.component />
                </div>
              </div>
            </Tab>
          ))}
        </TabContainer>
      </div>
    </div>
  )
}

export default CustomComponents

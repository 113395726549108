import { CustomKendoTable, DeletePopUp } from '@/components/common'
import { TableHeight, defaultPageSize } from '@/constants'
import DocumentUpload from '@/modules/technical/components/app/vesselInspections/inspections/DocumentUpload'
import { useAppContext } from '@/store/context/appContext'
import useHasPermission from '@/utilities/checkActionPermission'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import * as mime from 'react-native-mime-types'
import { Link, useOutletContext } from 'react-router-dom'
import { deleteDocument, getFile, useInspAuditLog } from './api'
import { VesselInspectionContextProps } from './schema'

type PageParams = {
  pageNo: number
  pageSize: number
  FindingId?: string
}

const VIDocuments: React.FC = () => {
  const [createOpen, setCreateOpen] = useState(false)
  const handleUpload = async () => {
    setCreateOpen(true)
  }

  const { FindingId } = useOutletContext<VesselInspectionContextProps>()
  const hasPermission = useHasPermission('Inspection')
  const { showSnackBar } = useCustomSnackBar()
  const dataParams = new FormData()

  const [total, setTotal] = useState(0)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [documentId, setDocId] = useState<string>('')
  const [subTitle, setSubTitle] = useState<string>('')
  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    pageSize: defaultPageSize,
    FindingId: FindingId
  })

  dataParams.append('FindingId', FindingId)
  dataParams.append('pageNo', params?.pageNo.toString())
  dataParams.append('pageSize', params?.pageSize.toString())

  const { data, refetch, isFetching } = useInspAuditLog(dataParams)
  const { setIsCommonLoader,setIsActionLoader,isActionLoader } = useAppContext()

  const handleView = async (row: any) => {
    setIsCommonLoader(true)
    const fileType = mime.lookup(row.FileType)

    const datas = await getFile(row.InspDocId)

    const byteCharacters = atob(datas.FileData)
    const byteArrays = []
    const sliceSize = 512
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    const file = new Blob(byteArrays, { type: fileType.toString() })
    const fileURL = URL.createObjectURL(file)

    if (
      fileType === 'image/jpeg' ||
      fileType === 'image/png' ||
      fileType === 'application/pdf'
    ) {
      window.open(fileURL)
      setIsCommonLoader(false)
    } else {
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(file)
      downloadLink.download = row.FileName
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      setIsCommonLoader(false)
    }

    // if (fileType !== 'text/csv') {
    //   window.open(fileURL)
    // } else {
    //   var blob = new Blob([...byteArrays], { type: fileType.toString() })
    //   const nav = window.navigator as any
    //   if (nav.msSaveOrOpenBlob) {
    //     nav.msSaveBlob(blob, row.FileName)
    //   } else {
    //     var a = window.document.createElement('a')
    //     a.href = window.URL.createObjectURL(blob)
    //     a.download = row.FileName
    //     document.body.appendChild(a)
    //     a.click()
    //   }
    // }
  }

  const columns = [
    {
      title: 'File Name',
      field: 'FileName',
      action: true,
      render: (row: any) => (
        <Link
          onClick={(e) => {
            e.preventDefault()
            handleView(row)
          }}
          className="kendo-column-text-link cursor-pointer truncate"
          to={''}
        >
          {row.FileName}
        </Link>
      )
    },
    {
      title: 'File Type',
      field: 'FileType'
    },
    {
      title: 'Updated By',
      field: 'UpdatedBy'
      // sort: true
    }
  ]

  useEffect(() => {
    setTotal(data?.TotalRecords)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])

  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }
  const handleMenuActions = async (slug: string, rowData: any) => {
    setDocId(rowData?.InspDocId)
    setSubTitle(rowData?.FileName)
    const permissionParams = {
      UserAction: true,
      AccessKey: '',
      ID1: ''
    }
    const permission = await hasPermission(permissionParams)
    setDeleteOpen(permission)
  }

  const handleDelete = () => {
    setIsActionLoader(true)
    deleteDocument(documentId)
      .then((res) => {
        showSnackBar(res)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err.response.data.error, 'error')
      }).finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }

  return (
    <div>
      <CustomKendoTable
        pagination={true}
        columns={columns}
        data={data?.Results}
        toolBar={true}
        sortable={true}
        resizable={true}
        isFetching={isFetching}
        title="Documents"
        isDownloadable={true}
        handleSort={handleSort}
        tableHeight={`calc(100vh - ${TableHeight.WithOneColHeader})`}
        primaryAction={{
          title: 'Upload',
          handlePrimaryAction: handleUpload,
          icon: 'upload_button'
        }}
        handleMenuActions={handleMenuActions}
        actionDropDown={[
          {
            title: 'Delete',
            iconName: 'delete',
            slug: 'delete',
            id: '2',
            isWarning: true
          }
        ]}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },
          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
      />
      <DocumentUpload
        refetch={refetch}
        onClose={() => setCreateOpen(false)}
        parentId={FindingId}
        type=""
        open={createOpen}
        title={'Upload Document'}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
        itemLabels={{ title: 'documents', subTitle: subTitle }}
        actionLoader={isActionLoader}
      />
    </div>
  )
}

export default VIDocuments

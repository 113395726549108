import { AutoCompleteValueProps } from '@/common/customComponenttypes'
import { defaultPageSize } from '@/constants'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type StoreUserFilterTypeStore = {
  search: string
  department: AutoCompleteValueProps
  role: AutoCompleteValueProps
  designation: AutoCompleteValueProps
  pageNo: number
  pageSize: number
  isSearched: boolean
  sortBy: string
  setSortBy: (sort: string) => void
  setPageNo: (page: number) => void
  setPageSize: (size: number) => void
  setSearch: (val: string) => void
  setIsSearched: (searched: boolean) => void
  clearFilters: () => void
  resetFilters: () => void
  setDepartment: (newDepartment: AutoCompleteValueProps) => void
  setDesignation: (newDesignation: AutoCompleteValueProps) => void
  setRole: (newRole: AutoCompleteValueProps) => void
}

export const useRolesUserFilterStore = create<StoreUserFilterTypeStore>()(
  persist(
    (set) => ({
      search: '',
      department: { name: '', id: null },
      role: { name: '', id: null },
      designation: { name: '', id: null },
      sortBy: 'LoginId',
      pageNo: 1,
      isSearched: false,
      pageSize: defaultPageSize,
      setSortBy: (sort: string) => set(() => ({ sortBy: sort })),
      setSearch: (val: string) => set(() => ({ search: val })),
      setPageNo: (page: number) => set(() => ({ pageNo: page })),
      setPageSize: (size: number) => set(() => ({ pageSize: size })),
      setDepartment: (newDepartment: AutoCompleteValueProps) =>
        set(() => ({ department: newDepartment })),
      setRole: (newRole: AutoCompleteValueProps) => set(() => ({ role: newRole })),
      setDesignation: (newDesignation: AutoCompleteValueProps) =>
        set(() => ({ designation: newDesignation })),
      setIsSearched: (searched: boolean) => set(() => ({ isSearched: searched })),
      clearFilters: () =>
        set(() => ({
          search: '',
          department: { name: '', id: null },
          role: { name: '', id: null },
          designation: { name: '', id: null },
          sortBy: 'LoginId',
          pageNo: 1,
          isSearched: false,
          pageSize: defaultPageSize
        })),
      resetFilters: () =>
        set(() => ({
          search: '',
          department: { name: '', id: null },
          sortBy: 'LoginId',
          pageNo: 1,
          pageSize: defaultPageSize
        }))
    }),
    {
      name: '_@r0_l$_us___ers__#rc__#ch$',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
)

import { ModulesRouteProps } from '@/common/commontypes'
import { routerStore } from '@/store'
import { lazy } from 'react'

import VesselParticularDetails from './pages/vesselParticulars/details'

import VesselInspectionDetails from './pages/vesselInspections/details'
import TechnicalInspectionAuditLog from './pages/vesselInspections/details/auditLog'
import TechnicalInspectionDocuments from './pages/vesselInspections/details/documents'
import TechnicalInspectionFindings from './pages/vesselInspections/details/findings'
import TechnicalInspectionGeneral from './pages/vesselInspections/details/general'
import TechnicalParticularAntiFoulings from './pages/vesselParticulars/details/antiFoulings'
import TechnicalParticularAuditLog from './pages/vesselParticulars/details/auditLog'
import TechnicalParticularCommunication from './pages/vesselParticulars/details/communication'
import TechnicalParticularContacts from './pages/vesselParticulars/details/contacts'
import TechnicalParticularEquipmentConfig from './pages/vesselParticulars/details/equipmentConfig'
import TechnicalParticularGeneral from './pages/vesselParticulars/details/general'
import TechnicalParticularManagement from './pages/vesselParticulars/details/management'
import TechnicalParticularSeaTrails from './pages/vesselParticulars/details/seaTrails'
import TechnicalParticularTanks from './pages/vesselParticulars/details/tanks'
import TechnicalParticularTechnical from './pages/vesselParticulars/details/technical'

import VesselBudgetDetails from './pages/vesselBudget/details'
import AssumptionComponent from './pages/vesselBudget/details/assumptions'
import VesselBudgetAuditLog from './pages/vesselBudget/details/auditlog'
import VesselBudgetDetailsTab from './pages/vesselBudget/details/budgetdetails'
import VesselBudgetEntities from './pages/vesselBudget/details/entities'
import VIFindingsDetails from './pages/vesselInspections/details/findings/details'
import VIActions from './pages/vesselInspections/details/findings/details/actions'
import VIAuditLog from './pages/vesselInspections/details/findings/details/auditlog'
import VIDocuments from './pages/vesselInspections/details/findings/details/documents'
import VIGeneral from './pages/vesselInspections/details/findings/details/general'
import FindingCategoriesList from './pages/registers/findingCategories'
import CreateVesselBudget from './pages/vesselBudget/create'

const CommunicationTypeList = lazy(() => import('./pages/registers/communicationTypes'))
const EngineTypeList = lazy(() => import('./pages/registers/engineTypes'))
const VesselParticularList = lazy(() => import('./pages/vesselParticulars'))
const VesselInspectionList = lazy(() => import('./pages/vesselInspections'))
const VesselBudgetList = lazy(() => import('./pages/vesselBudget'))
const SeaTrialLoad = lazy(() => import('./pages/registers/seaTrialLoad'))
const SystemArea = lazy(() => import('./pages/registers/systemAreas'))
const VesselRoles = lazy(() => import('./pages/registers/VesselRoles'))
const HullTypes = lazy(() => import('./pages/registers/HullTypes'))
const TankHeatingTypes = lazy(() => import('./pages/registers/tankHeatingTypes'))
const FindingCausesList = lazy(() => import('./pages/registers/findingCauses'))
const PaintTypeList = lazy(() => import('./pages/registers/paintTypes'))
const BudgetTypeList = lazy(() => import('./pages/registers/budgetTypes'))
const SteamCoilTypeList = lazy(() => import('./pages/registers/steamCoilType'))
const PSCMOUsRegister = lazy(() => import('./pages/registers/pscMOUs'))
const InspTypes = lazy(() => import('./pages/registers/inspTypes'))
const SafetyIndicatorReg = lazy(() => import('./pages/registers/safetyIndicators'))
const AssessmentAreasReg = lazy(() => import('./pages/registers/assessmentAreas'))
const PscActionsRegister = lazy(() => import('./pages/registers/pscActions'))
const PSCDeficiencyList = lazy(() => import('./pages/registers/pscDeficiency'))

const technicalRoutes: ModulesRouteProps[] = [
  {
    slug: 'VESSEL_PARTICULAR_LIST',
    path: routerStore.PARTICULAR,
    component: <VesselParticularList />
  },
  {
    slug: 'VESSEL_PARTICULAR_DETAILS',
    path: routerStore.PARTICULAR_DETAILS,
    component: <VesselParticularDetails />,
    children: [
      {
        slug: 'PARTICULAR_DETAILS_GENERAL_INFO',
        component: <TechnicalParticularGeneral />,
        path: routerStore.PARTICULAR_DETAILS_GENERAL_INFO,
        index: true
      },
      {
        slug: 'PARTICULAR_DETAILS_ANTI_FOULINGS',
        component: <TechnicalParticularAntiFoulings />,
        path: routerStore.PARTICULAR_DETAILS_ANTI_FOULINGS
      },
      {
        slug: 'PARTICULAR_DETAILS_AUDIT_LOG',
        component: <TechnicalParticularAuditLog />,
        path: routerStore.PARTICULAR_DETAILS_AUDIT_LOG
      },
      {
        slug: 'PARTICULAR_DETAILS_COMMUNICATION_INFO',
        component: <TechnicalParticularCommunication />,
        path: routerStore.PARTICULAR_DETAILS_COMMUNICATION_INFO
      },
      {
        slug: 'PARTICULAR_DETAILS_CONTACTS_INFO',
        component: <TechnicalParticularContacts />,
        path: routerStore.PARTICULAR_DETAILS_CONTACTS_INFO
      },
      {
        slug: 'PARTICULAR_DETAILS_TECHNICAL',
        component: <TechnicalParticularTechnical />,
        path: routerStore.PARTICULAR_DETAILS_TECHNICAL
      },
      {
        slug: 'PARTICULAR_DETAILS_TANKS',
        component: <TechnicalParticularTanks />,
        path: routerStore.PARTICULAR_DETAILS_TANKS
      },
      {
        slug: 'PARTICULAR_DETAILS_SEA_TRAILS',
        component: <TechnicalParticularSeaTrails />,
        path: routerStore.PARTICULAR_DETAILS_SEA_TRAILS
      },
      {
        slug: 'PARTICULAR_DETAILS_EQUIPMENT_CONFIG',
        component: <TechnicalParticularEquipmentConfig />,
        path: routerStore.PARTICULAR_DETAILS_EQUIPMENT_CONFIG
      },
      {
        slug: 'PARTICULAR_DETAILS_MANAGEMENT',
        component: <TechnicalParticularManagement />,
        path: routerStore.PARTICULAR_DETAILS_MANAGEMENT
      }
    ]
  },
  {
    slug: 'VESSEL_INSPECTION_LIST',
    path: routerStore.VESSEL_INSPECTION,
    component: <VesselInspectionList />
  },
  {
    slug: 'VESSEL_INSPECTION_DETAILS',
    path: routerStore.VESSEL_INSPECTION_DETAILS,
    component: <VesselInspectionDetails />,
    children: [
      {
        slug: 'VESSEL_INSPECTIONS_DETAILS_GENERAL_INFO',
        path: routerStore.VESSEL_INSPECTIONS_DETAILS_GENERAL_INFO,
        component: <TechnicalInspectionGeneral />,
        index: true
      },
      {
        slug: 'VESSEL_INSPECTIONS_DETAILS_FINDINGS',
        path: routerStore.VESSEL_INSPECTIONS_DETAILS_FINDINGS,
        component: <TechnicalInspectionFindings />
      },
      {
        slug: 'VESSEL_INSPECTIONS_DETAILS_DOCUMENTS',
        path: routerStore.VESSEL_INSPECTIONS_DETAILS_DOCUMENTS,
        component: <TechnicalInspectionDocuments />
      },
      {
        slug: 'VESSEL_INSPECTIONS_DETAILS_AUDIT_LOG',
        path: routerStore.VESSEL_INSPECTIONS_DETAILS_AUDIT_LOG,
        component: <TechnicalInspectionAuditLog />
      }
    ]
  },
  {
    slug: 'VI_DETAILS_FINDINGS_DETAILS_MAIN',
    path: routerStore.VI_DETAILS_FINDINGS_DETAILS_MAIN,
    component: <VIFindingsDetails />
  },
  {
    slug: 'VI_DETAILS_FINDINGS_DETAILS_MAIN',
    path: routerStore.VI_DETAILS_FINDINGS_DETAILS,
    component: <VIFindingsDetails />,
    children: [
      {
        slug: 'VI_DETAILS_GENERAL_INFO',
        path: routerStore.VI_DETAILS_GENERAL_INFO,
        component: <VIGeneral />,
        index: true
      },
      {
        slug: 'VI_DETAILS_ACTIONS',
        path: routerStore.VI_DETAILS_ACTIONS,
        component: <VIActions />
      },
      {
        slug: 'VI_DETAILS_DOCUMENTS',
        path: routerStore.VI_DETAILS_DOCUMENTS,
        component: <VIDocuments />
      },
      {
        slug: 'VI_DETAILS_AUDIT_LOG',
        path: routerStore.VI_DETAILS_AUDIT_LOG,
        component: <VIAuditLog />
      }
    ]
  },
  {
    slug: 'VESSEL_BUDGET_LIST',
    path: routerStore.VESSEL_BUDGET,
    component: <VesselBudgetList />
  },
  {
    slug: 'VESSEL_BUDGET_CREATE',
    path: routerStore.VESSEL_BUDGET_CREATE,
    component: <CreateVesselBudget />
  },
  {
    slug: 'VESSEL_BUDGET_DETAILS',
    path: routerStore.VESSEL_BUDGET_DETAILS,
    component: <VesselBudgetDetails />,
    children: [
      {
        slug: 'VESSEL_BUDGET_ENTITIES',
        path: routerStore.VESSEL_BUDGET_ENTITIES,
        component: <VesselBudgetEntities />,
        index: true
      },
      {
        slug: 'VESSEL_BUDGET_ASSUMPTIONS',
        path: routerStore.VESSEL_BUDGET_ASSUMPTIONS,
        component: <AssumptionComponent />,
        index: true
      },
      {
        slug: 'VESSEL_BUDGET_DETAILS_TAB',
        path: routerStore.VESSEL_BUDGET_DETAILS_TAB,
        component: <VesselBudgetDetailsTab />,
        index: true
      },
      {
        slug: 'VESSEL_BUDGET_AUDITLOG',
        path: routerStore.VESSEL_BUDGET_AUDITLOG,
        component: <VesselBudgetAuditLog />,
        index: true
      }
    ]
  },
  {
    slug: 'REGISTER_SEATRIAL_LOAD',
    path: routerStore.REGISTER_SEATRIAL_LOAD,
    component: <SeaTrialLoad />
  },
  {
    slug: 'REGISTER_SYSTEM_AREA',
    path: routerStore.REGISTER_SYSTEM_AREA,
    component: <SystemArea />
  },
  {
    slug: 'ENGINE_TYPE_LIST',
    path: routerStore.REGISTERS_ENGINE_TYPES,
    component: <EngineTypeList />
  },
  {
    slug: 'REGISTER_VESSEL_ROLES',
    path: routerStore.REGISTERS_VESSEL_ROLES,
    component: <VesselRoles />
  },
  {
    slug: 'COMMUNICATION_TYPE_LIST',
    path: routerStore.REGISTERS_COMMUNICATION_TYPES,
    component: <CommunicationTypeList />
  },
  {
    slug: 'HULL_TYPES_LIST',
    path: routerStore.REGISTERS_HULL_TYPES,
    component: <HullTypes />
  },

  {
    slug: 'REGISTERS_PAINT_TYPES',
    path: routerStore.REGISTERS_PAINT_TYPES,
    component: <PaintTypeList />
  },
  {
    slug: 'TANK_HEATING_TYPE_LIST',
    path: routerStore.REGISTERS_TANK_HEATING_TYPES,
    component: <TankHeatingTypes />
  },
  {
    slug: 'FINDING_CAUSES_LIST',
    path: routerStore.REGISTERS_FINDING_CAUSES,
    component: <FindingCausesList />
  },
  {
    slug: 'FINDING_CATEGORY_LIST',
    path: routerStore.REGISTERS_FINDING_CATEGORY,
    component: <FindingCategoriesList />
  },
  {
    slug: 'BUDGET_TYPE_LIST',
    path: routerStore.REGISTERS_BUDGET_TYPES,
    component: <BudgetTypeList />
  },
  {
    slug: 'STEAM_COIL_LIST',
    path: routerStore.REGISTERS_STEAM_COIL_TYPE,
    component: <SteamCoilTypeList />
  },
  {
    slug: 'PSC_MOUS_LIST',
    path: routerStore.REGISTERS_PSC_MOUS,
    component: <PSCMOUsRegister />
  },
  {
    slug: 'PSC_ACTIONS_LIST',
    path: routerStore.REGISTERS_PSC_ACTIONS,
    component: <PscActionsRegister />
  },
  {
    slug: 'INSP_TYPES_LIST',
    path: routerStore.REGISTERS_INSP_TYPES,
    component: <InspTypes />
  },
  {
    slug: 'SFT_INDICATOR_LIST',
    path: routerStore.REGISTERS_SFT_INDICATOR,
    component: <SafetyIndicatorReg />
  },
  {
    slug: 'ASSESSMENT_AREA_LIST',
    path: routerStore.REGISTERS_ASSMENT_AREAS,
    component: <AssessmentAreasReg />
  },
  {
    slug: 'PSC_DEFICIENCY_LIST',
    path: routerStore.REGISTERS_PSC_DEFICIENCY,
    component: <PSCDeficiencyList />
  }
]

export default technicalRoutes

import { z } from 'zod'

export const FindingCategorySchema = z.object({
  FindingCatId: z.number().int(),
  FindingCatName: z
    .string({ required_error: 'Finding Category is required' })
    .max(100, { message: 'Maximum 100 charactors allowed' })
    .min(1, 'Finding Category is required'),
  NonConfirmity: z.boolean().optional().nullable(),
  SireInspOnly: z.boolean().optional().nullable(),
  SireCatCode:z.string().optional().nullable(),
  Archived: z.boolean().optional().nullable()
})

export type FindingCategoryDataType = z.infer<typeof FindingCategorySchema>

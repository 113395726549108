import { differenceInDays } from 'date-fns'
import React from 'react'

interface LogItem {
  id?: string
  logId?: number
  userId?: string
  logDesc?: string
  logDate: string
  category?: string
  isHeader?: boolean
  color?: string
}

interface GroupByCategoryProps {
  data: LogItem[]
  render: (groupedItems: LogItem[]) => React.ReactNode
}

const categorizeDate = (dateString: string): string => {
  const date = new Date(dateString)
  const today = new Date()
  const daysDifference = differenceInDays(today, date)

  if (daysDifference <= 7) {
    return '1'
  } else if (daysDifference <= 30) {
    return '2'
  } else {
    return '3'
  }
}

const getColorFromName = (name: string): string => {
  let hash = 0
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }
  return color
}

const GroupByCategory: React.FC<GroupByCategoryProps> = ({ data, render }) => {
  const categorizedData = {
    '1': [] as LogItem[],
    '2': [] as LogItem[],
    '3': [] as LogItem[]
  }

  data.forEach((item: LogItem) => {
    const category = categorizeDate(item.logDate)
    const color = getColorFromName(item.userId || '')
    categorizedData[category as '1' | '2' | '3'].push({ ...item, color })
  })

  const groupedItems: LogItem[] = []

  if (categorizedData['1'].length > 0) {
    groupedItems.push({
      logId: -1,
      userId: '',
      logDesc: 'Last seven days',
      logDate: '',
      isHeader: true,
      id: 'header-1'
    })
    groupedItems.push(...categorizedData['1'])
  }

  if (categorizedData['2'].length > 0) {
    groupedItems.push({
      logId: -2,
      userId: '',
      logDesc: 'Last thirty days',
      logDate: '',
      isHeader: true,
      id: 'header-2'
    })
    groupedItems.push(...categorizedData['2'])
  }

  if (categorizedData['3'].length > 0) {
    groupedItems.push({
      logId: -3,
      userId: '',
      logDesc: 'Older',
      logDate: '',
      isHeader: true,
      id: 'header-3'
    })
    groupedItems.push(...categorizedData['3'])
  }

  return <>{render(groupedItems)}</>
}

export default GroupByCategory

import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { isAllow } from '@/constants'
import { ParticularDetailsContextProps } from '@/modules/technical/pages/vesselParticulars/details/schema'
import useHasPermission from '@/utilities/checkActionPermission'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import CapacityUsageForm from './CapacityUsageForm'
import DimensionsForm from './DimensionsForm'
import LoadLineInfoForm from './LoadLineInfoForm'
import OthersForm from './OthersForm'
import RatedPowerForm from './RatedPowerForm'
import TechnicalDetailForm from './TechnicalDetailForm'
import TonnageForm from './TonnageForm'

type VesselTechnicalFormProps = {
  menuId: string
  setActiveMenu: (value: React.SetStateAction<string>) => void
}
const VesselTechnicalForm: React.FC<VesselTechnicalFormProps> = ({
  menuId,
  setActiveMenu,
}) => {
  const { edit, setEdit, vesselID } = useOutletContext<ParticularDetailsContextProps>()
  const [technicalFieldsOpen, setTechnicalFieldsOpen] = useState<boolean>(false)
  const [dimensionFieldOpen, setDimensionFieldOpen] = useState<boolean>(false)
  const [capacityUsageOpen, setCapacityUsageOpen] = useState<boolean>(false)
  const [loadLineInfoOpen, setLoadLineInfoOpen] = useState<boolean>(false)
  const [ratePowerFieldsOpen, setratePowerFieldsOpen] = useState<boolean>(false)
  const [tonnageFieldsOpen, settonnageFieldsOpen] = useState<boolean>(false)
  const [othersFieldsOpen, setothersFieldsOpen] = useState<boolean>(false)
  const [warningOpen, setWarningOpen] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<any>('technical')

  useEffect(() => {
    if (!edit) {
      setCurrentTab(menuId)
    }
    switchFormFields(menuId)
    if (edit) {
      if (menuId !== currentTab) {
        setWarningOpen(true)
        switchFormFields(currentTab)
      }
    }
  }, [menuId, edit, currentTab])

  const switchFormFields = async (menuId: string) => {
    switch (menuId) {
      case 'technical':
        return technicalFields()
      case 'dimenstions':
        return dimensionFields()
      case 'capacityUsage':
        return capacityFields()
      case 'ratedPower':
        return ratePowerFields()
      case 'loadLineInfo':
        return loadLineInfoFields()
      case 'tonnage':
        return tonnageFields()
      case 'others':
        return othersFields()
      default:
        return []
    }
  }
  const hasPermission = useHasPermission('VesselTechInfo')
  const handleEdit = async () => {
    const permission = await hasPermission({
      UserAction: isAllow.UPDATE,
      AccessKey: '',
      ID1: vesselID
    })
    setEdit(permission)
  }
  const technicalFields = () => {
    setTechnicalFieldsOpen(true)
    setDimensionFieldOpen(false)
    setCapacityUsageOpen(false)
    setLoadLineInfoOpen(false)
    setratePowerFieldsOpen(false)
    settonnageFieldsOpen(false)
    setothersFieldsOpen(false)
  }
  const dimensionFields = () => {
    setDimensionFieldOpen(true)
    setTechnicalFieldsOpen(false)
    setCapacityUsageOpen(false)
    setLoadLineInfoOpen(false)
    setratePowerFieldsOpen(false)
    settonnageFieldsOpen(false)
    setothersFieldsOpen(false)
  }
  const capacityFields = () => {
    setCapacityUsageOpen(true)
    setTechnicalFieldsOpen(false)
    setDimensionFieldOpen(false)
    setLoadLineInfoOpen(false)
    setratePowerFieldsOpen(false)
    settonnageFieldsOpen(false)
    setothersFieldsOpen(false)
  }
  const loadLineInfoFields = () => {
    setLoadLineInfoOpen(true)
    setTechnicalFieldsOpen(false)
    setDimensionFieldOpen(false)
    setCapacityUsageOpen(false)
    setratePowerFieldsOpen(false)
    settonnageFieldsOpen(false)
    setothersFieldsOpen(false)
  }

  const ratePowerFields = () => {
    setratePowerFieldsOpen(true)
    setTechnicalFieldsOpen(false)
    setDimensionFieldOpen(false)
    setCapacityUsageOpen(false)
    setLoadLineInfoOpen(false)
    settonnageFieldsOpen(false)
    setothersFieldsOpen(false)
  }

  const tonnageFields = () => {
    settonnageFieldsOpen(true)
    setTechnicalFieldsOpen(false)
    setDimensionFieldOpen(false)
    setCapacityUsageOpen(false)
    setLoadLineInfoOpen(false)
    setratePowerFieldsOpen(false)
    setothersFieldsOpen(false)
  }

  const othersFields = () => {
    setothersFieldsOpen(true)
    setTechnicalFieldsOpen(false)
    setDimensionFieldOpen(false)
    setCapacityUsageOpen(false)
    setLoadLineInfoOpen(false)
    setratePowerFieldsOpen(false)
    settonnageFieldsOpen(false)
  }

  const handleConfirm = () => {
    setEdit(false)
    setWarningOpen(false)
    switchFormFields(menuId)
    setActiveMenu(menuId)
  }
  const handleCloseWarning = () => {
    setWarningOpen(false)
    switchFormFields(currentTab)
    setActiveMenu(currentTab)
  }

  return (
    <div className="h-full relative">
      {technicalFieldsOpen && (
        <TechnicalDetailForm
          vesselID={vesselID}
          setEdit={setEdit}
          edit={edit}
          handleEdit={handleEdit}
          setWarningOpen={setWarningOpen}
        />
      )}
      {dimensionFieldOpen && (
        <DimensionsForm
          vesselID={vesselID}
          setEdit={setEdit}
          edit={edit}
          handleEdit={handleEdit}
          setWarningOpen={setWarningOpen}
        />
      )}
      {capacityUsageOpen && (
        <CapacityUsageForm
          vesselID={vesselID}
          setEdit={setEdit}
          edit={edit}
          handleEdit={handleEdit}
          setWarningOpen={setWarningOpen}
        />
      )}
      {loadLineInfoOpen && (
        <LoadLineInfoForm
          vesselID={vesselID}
          setEdit={setEdit}
          edit={edit}
          handleEdit={handleEdit}
          setWarningOpen={setWarningOpen}
        />
      )}
      {tonnageFieldsOpen && (
        <TonnageForm
          vesselID={vesselID}
          setEdit={setEdit}
          edit={edit}
          handleEdit={handleEdit}
          setWarningOpen={setWarningOpen}
        />
      )}
      {ratePowerFieldsOpen && (
        <RatedPowerForm
          vesselID={vesselID}
          setEdit={setEdit}
          edit={edit}
          handleEdit={handleEdit}
          setWarningOpen={setWarningOpen}
        />
      )}
      {othersFieldsOpen && (
        <OthersForm
          vesselID={vesselID}
          setEdit={setEdit}
          edit={edit}
          handleEdit={handleEdit}
          setWarningOpen={setWarningOpen}
        />
      )}
      <ConfirmModal
        isOpen={warningOpen}
        modalId="errorPopup"
        title={'Confirmation'}
        type="warning"
        secondaryActionLabel="No"
        secondaryAction={() => handleCloseWarning()}
        actionLabel="Yes"
        onSubmit={() => handleConfirm()}
        topIcon="warning"
        scrollDisable={true}
        onClose={() => handleCloseWarning()}
        subTitle={'Do you want to discard all the changes made?'}
      />
    </div>
  )
}

export default VesselTechnicalForm

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const EditPrivilegeIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.4 7.2H4.23L7.23 4.2C7.32 4.11 7.3875 4.0075 7.4325 3.8925C7.4775 3.7775 7.5 3.665 7.5 3.555C7.5 3.445 7.475 3.3375 7.425 3.2325C7.375 3.1275 7.31 3.03 7.23 2.94L6.69 2.37C6.6 2.28 6.5 2.2125 6.39 2.1675C6.28 2.1225 6.165 2.1 6.045 2.1C5.935 2.1 5.8225 2.1225 5.7075 2.1675C5.5925 2.2125 5.49 2.28 5.4 2.37L2.4 5.37V7.2ZM3.3 6.3V5.73L4.815 4.215L5.115 4.485L5.385 4.785L3.87 6.3H3.3ZM5.115 4.485L5.385 4.785L4.815 4.215L5.115 4.485ZM5.505 7.2H9.6V6H6.705L5.505 7.2ZM0 12V1.2C0 0.87 0.1175 0.5875 0.3525 0.3525C0.5875 0.1175 0.87 0 1.2 0H10.8C11.13 0 11.4125 0.1175 11.6475 0.3525C11.8825 0.5875 12 0.87 12 1.2V8.4C12 8.73 11.8825 9.0125 11.6475 9.2475C11.4125 9.4825 11.13 9.6 10.8 9.6H2.4L0 12ZM1.89 8.4H10.8V1.2H1.2V9.075L1.89 8.4Z"
        fill="black"
      />
    </svg>
  )
}

export default EditPrivilegeIcon

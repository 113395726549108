import useScrollData from '@/utilities/useScrollData'
import React, { useEffect, useRef, useState } from 'react'
import { Portal } from '../../common'
import CustomIcons from '../icons/customIcons'

type Props = {
  iconName: string
  isDownload?: boolean
  disabled?: boolean
  fromTable?: boolean
  iconClass?: string
  buttonSize?: string
  updateMenuRef?: (ref: HTMLDivElement | null) => void
  position?: string
  actionDropDown?: {
    title: string
    slug: string
    isWarning?: boolean
    iconName: string
    hidden?: boolean
    id: string
    menuIconClass?: string
  }[]
  isClickActive?: boolean
  handleMenuActions?: (slug: string, rowData?: any) => void
  isMouseOut?: boolean
  onIconClick?: (clicked: boolean) => void
  iconSize?: string
  iconCustDimension?: number
  iconViewBox?: boolean
}

export default function MenuDropDown({
  iconName,
  iconClass,
  actionDropDown,
  fromTable = false,
  disabled = false,
  isDownload = false,
  updateMenuRef,
  handleMenuActions,
  position,
  isMouseOut,
  isClickActive,
  onIconClick,
  iconSize,
  buttonSize = 'md',
  iconCustDimension,
  iconViewBox = false
}: Props) {
  const [openMenu, setOpenMenu] = useState(false)
  // const [menuOrigin, setMenuOrigin] = useState<'top' | 'bottom'>('top')
  const menuButtonRef = useRef<HTMLButtonElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const [dropdownPosition, setDropdownPosition] = useState('bottom-position')
  const { isScrolling } = useScrollData()

  const dropRef = useRef(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpenMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])
  useEffect(() => {
    if (isScrolling) {
      setOpenMenu(false)
    }
  }, [isScrolling])
  useEffect(() => {
    if (onIconClick) {
      if (!isClickActive) {
        setOpenMenu(false)
      }
    }
  }, [isClickActive])

  // const toggleMenu = () => {
  //   setOpenMenu(!openMenu)
  // }
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  })

  const toggleMenu = () => {
    if (!openMenu && menuButtonRef.current) {
      const rect = menuButtonRef.current.getBoundingClientRect()
      const topSpace = rect.top
      const bottomSpace = window.innerHeight - rect.bottom
      const leftSpace = rect.left
      const rightSpace = window.innerWidth - rect.right

      const dropdownHeight = 200
      const dropdownWidth = 200

      let newPosition = 'bottom-right'

      if (bottomSpace < dropdownHeight && topSpace > dropdownHeight) {
        newPosition = 'top-right'
      }

      if (rightSpace < dropdownWidth && leftSpace > dropdownWidth) {
        newPosition = newPosition.replace('right', 'left')
      }

      if (topSpace < dropdownHeight && bottomSpace < dropdownHeight) {
        newPosition = leftSpace > rightSpace ? 'bottom-left' : 'bottom-right'
      }

      setDropdownPosition(newPosition)
      setMenuPosition({
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
        bottom: window.innerHeight - rect.bottom,
        right: window.innerWidth - rect.right
      })
    }
    setTimeout(() => {
      setOpenMenu(!openMenu)
      onIconClick?.(!openMenu)
    }, 200)
  }

  useEffect(() => {
    if (isMouseOut) {
      document.addEventListener('mouseout', handleClickOutside)
      return () => {
        document.removeEventListener('mouseout', handleClickOutside)
      }
    }
  }, [isMouseOut])

  const handleMenuItemClick = (slug: string, rowData?: any) => {
    handleMenuActions?.(slug, rowData)
    setOpenMenu(false)
  }
  // useEffect(() => {
  //   if (menuButtonRef.current) {
  //     const buttonRect = menuButtonRef.current.getBoundingClientRect()
  //     const windowHeight = window.innerHeight

  //     if (buttonRect.bottom > windowHeight - buttonRect.top) {
  //       setMenuOrigin('top')
  //     } else {
  //       setMenuOrigin('bottom')
  //     }
  //   }
  // }, [openMenu])
  useEffect(() => {
    if (updateMenuRef) {
      updateMenuRef(menuRef.current)
    }
  }, [menuRef])
  const buttonWidth = menuButtonRef.current ? menuButtonRef.current.offsetWidth : 0
  const buttonHeight = menuButtonRef.current ? menuButtonRef.current.offsetHeight : 0
  return (
    <div
      className={
        fromTable ? 'absolute dropdown right-2 my-auto' : 'dropdown relative z-10'
      }
      ref={menuRef}
    >
      <button
        className={`${
          isDownload
            ? ` border border-1 border-[#d7d7d7] h-button w-[34px] rounded-menu flex items-center justify-center text-[#909090] dropdown-toggle-download  ${
                buttonSize === 'sm' ? ' h-button-sm ' : ''
              }`
            : `dropdown-toggle ${fromTable ? ' table-row-bg ' : ''}`
        }`}
        disabled={disabled}
        onClick={toggleMenu}
        ref={menuButtonRef}
      >
        <CustomIcons type={iconSize ?? 'large'} name={iconName} className={iconClass} />
      </button>
      {openMenu && (
        <Portal>
          <div
            ref={dropRef}
            style={{
              top: dropdownPosition.startsWith('bottom')
                ? `${menuPosition.top + window.scrollY + buttonHeight + 1}px`
                : 'auto',
              bottom: dropdownPosition.startsWith('top')
                ? `${menuPosition.bottom + buttonHeight + 1}px`
                : 'auto',
              left: dropdownPosition.endsWith('right')
                ? `${menuPosition.left}px`
                : 'auto',
              right: dropdownPosition.endsWith('left')
                ? `${window.innerWidth - menuPosition.left - buttonWidth}px`
                : 'auto'
            }}
            className={`dropdown-menu  ${dropdownPosition}   absolute ${
              position ? position : 'right-0'
            } mt-2 w-max  shadow-lg bg-white focus:outline-none `}
          >
            <div className="py-2 bg-white  w-full shadow-menudropdown">
              {actionDropDown?.map((item) => (
                <React.Fragment key={item.id}>
                  {!item?.hidden && (
                    <a
                      key={item.id}
                      href="#/"
                      className={`px-3 py-2.5 leading-7 hover:bg-table-hover hover:text-secondary`}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        handleMenuItemClick(item.slug, item)
                      }}
                    >
                      <div
                        className={`${
                          item.isWarning ? ' text-error-light' : ''
                        } text-common flex items-center w-full tracking-[0.24px] font-normal leading-4`}
                      >
                        <CustomIcons
                          name={item.iconName}
                          type={iconSize ?? 'large'}
                          custDimension={iconCustDimension}
                          viewBox={iconViewBox}
                          className={`${
                            item.isWarning ? 'text-error-light' : ''
                          } text-center ${item.menuIconClass}`}
                        />
                        <span className="pl-2">{item.title}</span>
                      </div>
                    </a>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </Portal>
      )}
    </div>
  )
}

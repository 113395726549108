import {
  deleteMasterData,
  getMasterData,
  getTechnicalData,
  postMasterData,
  postTechnicalMipsData,
  updateMasterData
} from '@/apis/api.helpers'
import apiMasterDataUrl from '@/apis/masterdata.api.urls'
import { useQuery } from '@tanstack/react-query'
import apiTechnicalUrl from '../../apis/api.urls'

export const fetchInspections = async (filters: any) => {
  const { data } = await postTechnicalMipsData(
    `${apiTechnicalUrl.GET_VESSEL_INSPECTION_MIPS}/SearchInspections`,
    {
      ...filters
    }
  )
  return data
}
export const useInspectionList = (filters: any, searchCount: number) => {
  return useQuery({
    queryKey: ['tech-inspections-list', filters],
    queryFn: () => fetchInspections(filters),
    //initialData: false,
    staleTime: 500,
    enabled: searchCount > 1 ? true : false
  })
}
export const fetchPortsById = (id: number) => {
  return getMasterData(`${apiMasterDataUrl.PORTS}/GetById?id=${id}`)
}
export const deletePortFromRegister = (id: number) => {
  return deleteMasterData(`${apiMasterDataUrl.PORTS}/Delete?Id=${id}`)
}
export const addPortToRegister = (data: any) => {
  return postMasterData(`${apiMasterDataUrl.PORTS}/Create`, data)
}
export const updatePortOnRegister = (data: any) => {
  return updateMasterData(`${apiMasterDataUrl.PORTS}/Update`, data)
}

export const getInspectionFilters = () => {
  return getTechnicalData(`${apiTechnicalUrl.GET_VESSEL_INSPECTION}/GetFilters`)
}
export const getInspDynamicMenu = (id: string, token: string) => {
  return getTechnicalData(
    `${apiTechnicalUrl.GET_VESSEL_INSPECTION}/GetMenu?id=` + id + '&src=' + token
  )
}

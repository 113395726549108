import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const UnCheckedMark: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="1.5"
        fill="white"
        stroke="#D9D9D9"
      />
      <rect
        x="4.36365"
        y="4.36363"
        width="7.27273"
        height="7.27273"
        rx="2"
        fill="#D9D9D9"
      />
    </svg>
  )
}

export default UnCheckedMark

import { CustomKendoTable, DeletePopUp } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { TableHeight, defaultPageSize, guid, isAllow } from '@/constants'
import { CreateAntiFouling } from '@/modules/technical/components/app/vesselParticulars/antiFoulings/AntiFoulingForm'
import { AntiFoulingPaintDetails } from '@/modules/technical/components/app/vesselParticulars/antiFoulings/AntiFoulingPaintDetails'
import { useAppStore } from '@/store/appStore'
import useHasPermission from '@/utilities/checkActionPermission'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useOutletContext } from 'react-router-dom'

import {
  createAntiFouling,
  deleteAntiFouling,
  updateAntiFouling,
  useAntiFoulingDetail
} from '../api'
import { ParticularDetailsContextProps } from '../schema'
import { FormDataType, FormSchema } from './schema'
import { useAppContext } from '@/store/context/appContext'


type PageParams = {
  pageNo: number
  vesselId?: string
  pageSize: number
  vesselFilterType: number
  sortBy: string
}

const TechnicalParticularAntiFoulings: React.FC = () => {
  const { t } = useTranslation()
  const { vesselID } = useOutletContext<ParticularDetailsContextProps>()
  const { setIsLoading } = useAppStore()
  const [total, setTotal] = useState(0)
  const [isSaving, setIsSaving] = useState(false)
  // const paintRef = useRef<HTMLDivElement | null>(null)
 const { showSnackBar } = useCustomSnackBar()

  const [deleteOpen, setDeleteOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [paintOpen, setPaintOpen] = useState(false)
  const [edit, setEdit] = useState(true)
  const [subTitle, setSubTitle] = useState<string>('')
  const { setIsActionLoader, isActionLoader } = useAppContext()
  const [viewable, setViewable] = useState(false)
  const [antiFoulingId, setAntiFoulingId] = useState<string>('')
  const [antiFoulingSelId, setAntiFoulingSelId] = useState<string>('')
  const hasPermission = useHasPermission('VesselAntiFouling')

  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    vesselId: vesselID,
    vesselFilterType: 1,
    pageSize: defaultPageSize,
    sortBy: 'yardName'
  })

  const { data, refetch, isFetching } = useAntiFoulingDetail(params)

  const columns = [
    {
      title: t('Yard Name'),
      field: 'yardName',
      sort: true,
      action: true,
      width: 400,
      render: (row: any) => (
        <Link
          to={'/'}
          onClick={(e) => {
            handleClick(e, row)
          }}
          className="kendo-column-text-link cursor-pointer truncate"
        >
          {row.yardName}
        </Link>
      )
    },
    {
      title: t('Start Date'),
      field: 'startDate',
      sort: true,
      type: 'date'
    },
    {
      title: t('End Date'),
      field: 'endDate',
      sort: true,
      type: 'date'
    },
    {
      title: t('Updated By'),
      sort: true,
      field: 'updatedBy'
    },
    {
      title: t('Updated On'),
      sort: true,
      field: 'updatedOn',
      type: 'dateTime'
    }
  ]

  useEffect(() => {
    setTotal(data?.data?.totalRecords)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])
  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }
  const handleMenuActions = async (slug: string, rowData: any) => {
    setAntiFoulingId(rowData?.antiFoulingId)
    setSubTitle(rowData?.yardName)
    const permissionParams = {
      UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
      AccessKey: '',
      ID1: rowData?.antiFoulingId
    }
    const permission = await hasPermission(permissionParams)

    if (slug === 'delete') {
      setDeleteOpen(permission)
    } else {
      setCreateOpen(permission)
      setEdit(true)
      setViewable(false)
      methods.reset(getDeFaultValues(rowData))
    }
  }

  const handleDelete = () => {
    setIsActionLoader(true)
    deleteAntiFouling(antiFoulingId)
      .then((res) => {
        showSnackBar(res)
        if (antiFoulingSelId === antiFoulingId) setAntiFoulingSelId(guid)

        refetch()
      })
      .catch((err) => {
        showSnackBar(err, 'error')
      }).finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }

  const handleCreate = async () => {
    setIsLoading(true)
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: '',
      ID2: vesselID
    })
    setEdit(false)
    setViewable(false)
    setCreateOpen(permission)
    setIsLoading(false)
    methods.reset(getDeFaultValues({}))
  }

  const handleRadioClick = (rowData: any) => {
    setPaintOpen(true)
    setAntiFoulingSelId(rowData?.antiFoulingId)
  }
  useEffect(() => {
    if (paintOpen && antiFoulingSelId && antiFoulingSelId !== guid) {
      const element = document.getElementById('sublist')
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        })
      }
    }
  }, [paintOpen, antiFoulingSelId, guid])

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    rowData: any
  ) => {
    e.preventDefault()
    setViewable(true)
    setAntiFoulingId(rowData?.antiFoulingId)
    setCreateOpen(true)
    methods.reset(getDeFaultValues(rowData))
  }

  const getDeFaultValues = (rowData: any) => {
    if (rowData && rowData?.yardId) {
      return {
        yardId: rowData?.yardId,
        yardName: rowData?.yardName,
        startDate: rowData?.startDate && new Date(rowData?.startDate),
        endDate: rowData?.endDate && new Date(rowData?.endDate),
        antiFoulingId: rowData?.antiFoulingId,
        vesselId: vesselID,
        remarks: rowData?.remarks
      }
    } else {
      return {
        yardId: '',
        yardName: '',
        antiFoulingId: guid,
        vesselId: vesselID,
        remarks: ''
      }
    }
  }

  const onSubmit = (data: FormDataType) => {
    const payload = {
      ...data,
      startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(data.endDate).format('YYYY-MM-DD')
    }
    const api = edit ? updateAntiFouling : createAntiFouling
    setIsSaving(true)
    api(payload)
      .then((res) => {
        setIsSaving(false)
        showSnackBar(res)
        refetch()
        setCreateOpen(false)
      })
      .catch((err) => {
        setIsSaving(false)
        showSnackBar(err, 'error')
      })
  }

  const methods = useForm<FormDataType>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDeFaultValues({})
  })

  const setActionLabel = () => {
    if (viewable) return ''
    return edit ? t('update') : t('save')
  }

  const getHeading = () => {
    if (viewable) return t('View Anti-Fouling Details')
    return edit ? t('Edit Anti-Fouling') : t('Add Anti-Fouling')
  }

  return (
    <>
      <div className={`bg-white rounded p-[15px]`}>
        <CustomKendoTable
          columns={columns}
          data={data?.data?.results}
          pagination={true}
          toolBar={true}
          sortable={true}
          resizable={true}
          isFetching={isFetching}
          defaultSort={'yardName'}
          rowKey="antiFoulingId"
          selectedKey={antiFoulingSelId}
          singleSelection={true}
          handleSingleSelect={handleRadioClick}
          title={t('Anti Foulings')}
          tableHeight={`calc(100vh - ${TableHeight.WithHeader})`}
          isDownloadable={true}
          adjustWidth={0}
          handleSort={handleSort}
          actionDropDown={[
            { title: t('edit'), iconName: 'edit', slug: 'edit', id: '1' },
            {
              title: t('delete'),
              iconName: 'delete',
              slug: 'delete',
              id: '2',
              isWarning: true
            }
          ]}
          handleMenuActions={handleMenuActions}
          primaryAction={{
            title: t('New'),
            handlePrimaryAction: handleCreate
          }}
          paginationProps={{
            total: total,
            currentPage: params.pageNo,
            rowsPerPage: params.pageSize,
            onPagination(row) {
              setPrams((prev) => ({
                ...prev,
                pageNo: row
              }))
            },
            onRowsPerPage(row) {
              setPrams((prev) => ({
                ...prev,
                pageSize: row,
                pageNo: 1
              }))
            }
          }}
        />
      </div>

      {paintOpen && antiFoulingSelId && antiFoulingSelId !== guid && (
        <div className={`bg-white rounded p-[15px] mt-[15px]`} id="sublist">
          <AntiFoulingPaintDetails vesselID={vesselID} antiFoulingId={antiFoulingSelId} />
        </div>
      )}

      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
        itemLabels={{ title: 'anti foulings', subTitle: subTitle }}
        actionLoader={isActionLoader}
      />

      <FormProvider {...methods}>
        <DialogModal
          isOpen={createOpen}
          type="md"
          title={getHeading()}
          body={<CreateAntiFouling viewable={viewable} />}
          onClose={() => setCreateOpen(false)}
          actionLabel={setActionLabel()}
          onSubmit={() => methods.handleSubmit(onSubmit)()}
          secondaryAction={() => setCreateOpen(false)}
          secondaryActionLabel={viewable ? t('Close') : t('cancel')}
          small={false}
          actionLoader={isSaving}
        />
      </FormProvider>
    </>
  )
}

export default TechnicalParticularAntiFoulings

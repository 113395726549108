import { RadioProps } from '@/common/customComponenttypes'
import React from 'react'

const Radio: React.FC<RadioProps> = ({
  name,
  id,
  label,
  checked,
  value = undefined,
  handleChange,
  disabled = false,
  customLabel
}) => {
  return (
    <div
      className={`labels label-text flex items-center justify-start ${
        !customLabel && 'gap-2.5'
      }  ${disabled ? ' opacity-70' : ''} `}
    >
      <div>
        <label
          className={`radio-button ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          <input
            type="radio"
            checked={checked}
            disabled={disabled}
            name={name}
            data-testid="app-common-radio"
            value={value}
            id={id}
            onChange={handleChange}
          />
          <span className="radio"></span>
        </label>{' '}
      </div>
      <div className="text-common font-normal">{label}</div>
    </div>
  )
}

export default Radio

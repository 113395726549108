import { CommonManageModalProps } from '@/common/commontypes'
import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder, Radio } from '@/components/common'
import { getFormErrorMessage } from '@/components/common/formBuilder/utils/helpers'
import DialogModal from '@/components/common/modal/DialogModal'
import { addCrewKin } from '@/modules/crewing/pages/crewSearch/api'
import { handleSchema } from '@/modules/crewing/pages/crewSearch/details/crewDetails/familyDetails/kinSchema'
import { formContainerClass } from '@/styles/variables'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { getErrorMessage } from '@/utilities/dataGenerators'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

export const CreateCrewKin: React.FC<CommonManageModalProps> = ({
  data,
  edit,
  view,
  open,
  title,
  onClose,
  refetch
}) => {
  const { t } = useTranslation()
  const { showSnackBar } = useCustomSnackBar()
  const [existingCrew, setExistingCrew] = useState<string>('')
  const KinCreateSchema = handleSchema(existingCrew)

  type KinCreateSchemaType = z.infer<typeof KinCreateSchema>

  const onSuccessAction = () => {
    methods.reset(initData())
    onClose()
    refetch()
  }
  const initData = () => {
    return { ...data }
  }

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = (res: KinCreateSchemaType) => {
    setIsSubmitting(true)
    const api = addCrewKin
    const payload = { ...res }

    api(payload)
      .then((res) => {
        onSuccessAction()
        showSnackBar(res?.message)
      })
      .catch((err) => {
        showSnackBar(getErrorMessage(err), 'error')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const handleCloseForm = () => {
    methods.reset(initData())
    onClose()
  }

  const methods = useForm<KinCreateSchemaType>({
    resolver: zodResolver(KinCreateSchema),
    mode: 'onChange',
    defaultValues: initData()
  })

  useEffect(() => {
    methods.reset({ ...data })
    setExistingCrew(data?.existingCrew)
  }, [data])

  const {
    clearErrors,
    formState: { errors },
    setValue
  } = methods

  const formFields: FormBuilderProps[] = [
    {
      label: t('Relative Crew Name'),
      placeholder: t('typeToSearch'),
      name: 'relativeCrewName',
      id: 'relativeCrewId',
      type: 'autocomplete',
      apiParams: { key: 'CRWMASTR' },
      additionalValue: true,
      spacing: 6,
      disabled: existingCrew !== 'existing'
    },
    {
      label: t('Relative Crew Id'),
      placeholder: t('Relative Crew Id'),
      name: 'relativeCrewNum',
      id: 'relativeCrewNum',
      disabled: true,
      type: 'text',
      spacing: 6,
      maxLength: 100
    }
  ]
  const formFieldsNew: FormBuilderProps[] = [
    {
      label: t('First Name'),
      placeholder: t('First Name'),
      name: 'givenName',
      id: 'givenName',
      required: true,
      type: 'text',
      spacing: 6,
      maxLength: 100,
      disabled: existingCrew !== 'new'
    },
    {
      label: t('Surname'),
      placeholder: t('Surname'),
      name: 'surname',
      id: 'surname',
      type: 'text',
      required: true,
      spacing: 6,
      maxLength: 100,
      disabled: existingCrew !== 'new'
    }
  ]
  const formFieldsMain: FormBuilderProps[] = [
    {
      label: t('Relationship'),
      required: true,
      placeholder: t('Relationship'),
      name: 'relationName',
      id: 'relationId',
      type: 'custom_select',
      data: data?.relations,
      spacing: 6,
      disabled: existingCrew === ''
    },
    {
      label: t('NOK'),
      name: 'nok',
      id: 'nok',
      type: 'switch',
      spacing: 3,
      disabled: existingCrew === ''
    },
    {
      label: t('ICE'),
      name: 'ice',
      id: 'ice',
      type: 'switch',
      spacing: 3,
      disabled: existingCrew === ''
    }
  ]

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const errMsg = getFormErrorMessage(errors, [
        ...formFields,
        ...formFieldsNew,
        ...formFieldsMain
      ])
      if (errMsg) {
        showSnackBar(`${t('errorToastMessage')} ${errMsg}`, 'error')
      }
    }
  }, [errors])

  const checkCrewVal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e?.target?.value
    setExistingCrew(val)
    if (val === 'existing') {
      setValue('givenName', '')
      setValue('surname', '')
    } else {
      setValue('relativeCrewNum', null)
      setValue('relativeCrewName', '')
      setValue('relativeCrewId', '')
    }

    clearErrors()
  }

  const handleSetAdditionalValues = (key: string, value: any) => {
    if (key === 'relativeCrewId') {
      setValue('relativeCrewNum', value.param1)
    }
  }

  return (
    <>
      <DialogModal
        isOpen={open}
        small={true}
        type="sm"
        title={title}
        body={
          <div className="h-full relative w-full">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={formContainerClass}>
                  <div className="col-span-12 md:col-span-12 -ml-2">
                    <Radio
                      id={`radio-select-1}`}
                      name={`existingCrewMember`}
                      value={'existing'}
                      checked={existingCrew === 'existing' ? true : false}
                      handleChange={checkCrewVal}
                      label="Link to an Existing Crew Member"
                      customLabel={true}
                    />
                  </div>
                  <FormBuilder
                    data={formFields}
                    edit={!view}
                    showErrors={false}
                    setAdditionalValues={handleSetAdditionalValues}
                  />

                  <div className="col-span-12 md:col-span-12 -ml-2">
                    <Radio
                      id={`radio-select-1}`}
                      name={`existingCrewMember`}
                      value={'new'}
                      checked={existingCrew === 'new' ? true : false}
                      handleChange={checkCrewVal}
                      label="Add New Family Member"
                      customLabel={true}
                    />
                  </div>

                  <FormBuilder data={formFieldsNew} edit={!view} showErrors={false} />
                  <div className="col-span-12 md:col-span-12">
                    <hr />
                  </div>
                  <FormBuilder data={formFieldsMain} edit={!view} showErrors={false} />
                </div>
              </form>
            </FormProvider>
          </div>
        }
        onClose={handleCloseForm}
        actionLabel={edit ? 'Update' : view ? undefined : 'Save'}
        onSubmit={methods.handleSubmit(onSubmit)}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={view ? 'Close' : 'Cancel'}
        actionLoader={isSubmitting}
      />
    </>
  )
}

import {
  deleteTechnicalData,
  getTechnicalData,
  postTechnicalData,
  updateTechnicalData
} from '@/apis/api.helpers'
import { useQuery } from '@tanstack/react-query'
import apiTechnicalUrl from '../../../apis/api.urls'

export const fetchFindingCategories = async (filters: any) => {
  return await postTechnicalData(`${apiTechnicalUrl.FINDING_CATEGORY}/Search`, {
    ...filters
  })
}
export const useFindingCategoriesList = (filters: any, searchCount: number) => {
  return useQuery({
    queryKey: ['tech-reg-finding-categories-list', filters],
    queryFn: () => fetchFindingCategories(filters),
    //initialData: false,
    staleTime: 500,
    enabled: searchCount > 1 ? true : false
  })
}
export const fetchFindingCategoryById = (id: number) => {
  return getTechnicalData(`${apiTechnicalUrl.FINDING_CATEGORY}/GetById?Id=${id}`)
}
export const deleteFindingCategoryFromRegister = (id: number) => {
  return deleteTechnicalData(`${apiTechnicalUrl.FINDING_CATEGORY}/Delete?Id=${id}`)
}
export const addFindingCategoryToRegister = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.FINDING_CATEGORY}/Create`, data)
}
export const updateFindingCategoryOnRegister = (data: any) => {
  return updateTechnicalData(`${apiTechnicalUrl.FINDING_CATEGORY}/Update`, data)
}

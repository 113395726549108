import { SearchBuilderProps } from '@/common/customComponenttypes'
import { CommonSearch } from '@/components/common'
import useCrewDocumentTypeStore from '@/modules/crewing/pages/registers/crewDocumentType/store'
import React from 'react'
import { useTranslation } from 'react-i18next'

type SearchBoxProps = {
  onSearch: (filters: any) => void
  rankCate: any
}
const CrewDocumentTypeSearch: React.FC<SearchBoxProps> = ({ onSearch, rankCate }) => {
  const { t } = useTranslation()

  const { filters } = useCrewDocumentTypeStore()

  const onSubmitSearch = (data: any) => {
    // Todo: For future reference
    // if (

    //   data.crewDocCategoryId === null ||
    //   data.crewDocCategoryId === undefined ||
    //   data.crewDocCategoryId === 0
    // ) {
    //   setError({
    //     crewDocCategoryId: { message: 'Category is required' }
    //   })
    // } else {
    //   setError({})
    onSearch(data)
    // }
  }

  const formFields: SearchBuilderProps[] = [
    {
      label: t('Document category'),
      placeholder: t('Select a category'),
      name: 'crewDocCategoryId',
      id: 'crewDocCategoryId',
      type: 'custom_select',
      data: rankCate,
      spacing: 3
    },
    {
      label: t('Document Type Name'),
      placeholder: t('Document Type'),
      name: 'crewDocTypeName',
      id: 'crewDocTypeName',
      type: 'text',
      spacing: 3
    }
  ]

  return (
    <CommonSearch
      onSearch={onSubmitSearch}
      data={formFields}
      activeFilters={filters}
      // errors={errors}
    />
  )
}

export default CrewDocumentTypeSearch

import { CardListProps } from '@/common/customComponenttypes'
import NormalCard from './Card'
import ColoredCard from './colored/Card'
import ExpandCard from './expand/Card'

const VarientSwitcher = ({
  variant,
  columns,
  columnsHeader,
  avatar,
  rowData,
  index,
  workflowParams,
  rowKey,
  cardKey,
  actionDropDown,
  actionDropDownParams,
  handleMenuActions,
  cardRef,
  staticMenus,
  expansionComponent,
  onCardClick,
  themeKey,
  updatedDate,
  onExpansionDropDown,
  titleFooter,
  defaultBorder
}: CardListProps) => {
  const getThemedCard = () => {
    switch (variant) {
      case 'colored':
        return (
          <ColoredCard
            key={index}
            index={index}
            columns={columns}
            onCardClick={onCardClick}
            updatedDate={updatedDate}
            columnsHeader={columnsHeader}
            titleFooter={titleFooter}
            cardRef={cardRef}
            rowData={rowData}
            themeKey={themeKey}
            handleMenuActions={handleMenuActions}
            actionDropDown={actionDropDown}
            actionDropDownParams={actionDropDownParams}
            cardKey={cardKey}
            workflowParams={workflowParams}
            rowKey={rowKey}
            staticMenus={staticMenus}
          />
        )
      case 'expand':
        return (
          <ExpandCard
            key={index}
            index={index}
            columns={columns}
            onCardClick={onCardClick}
            updatedDate={updatedDate}
            columnsHeader={columnsHeader}
            titleFooter={titleFooter}
            cardRef={cardRef}
            themeKey={themeKey}
            rowData={rowData}
            onExpansionDropDown={onExpansionDropDown}
            handleMenuActions={handleMenuActions}
            actionDropDown={actionDropDown}
            actionDropDownParams={actionDropDownParams}
            cardKey={cardKey}
            workflowParams={workflowParams}
            expansionComponent={expansionComponent}
            rowKey={rowKey}
            staticMenus={staticMenus}
            defaultBorder={defaultBorder}
          />
        )
      default:
        return (
          <NormalCard
            key={index}
            index={index}
            columns={columns}
            columnsHeader={columnsHeader}
            avatar={avatar}
            cardRef={cardRef}
            rowData={rowData}
            handleMenuActions={handleMenuActions}
            actionDropDown={actionDropDown}
            actionDropDownParams={actionDropDownParams}
            cardKey={cardKey}
            workflowParams={workflowParams}
            rowKey={rowKey}
            staticMenus={staticMenus}
          />
        )
    }
  }
  return <>{getThemedCard()}</>
}

export default VarientSwitcher

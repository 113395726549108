import { DialogModalProps } from '@/common/customComponenttypes'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import React, { useCallback, useEffect, useState } from 'react'
import { Portal } from '../../common'
import CustomIcons from '../icons/customIcons'
import { Button } from '../index'

const DialogModal: React.FC<DialogModalProps> = ({
  noPortal = false, //added for enable disable PORTAL component
  isOpen,
  modalId = undefined,
  onClose,
  isCloseIcon = true,
  onSubmit,
  actionLabel,
  disabled = false,
  actionLoader,
  title,
  type,
  body,

  actionBody,
  isFullView,
  secondaryAction,
  secondaryActionLabel,
  small = true,
  backdropCancel,
  scrollDisable,
  fromModal,
  actionDisabled,
  headClassName,
  continueLabel,
  onContinueAction,
  continueLoader,
  leftSection,
  rightSection
}) => {
  const [showModal, showShowModal] = useState(isOpen)
  const { width } = useScreenBreakPoint()
  const enableScroll = useCallback(() => {
    if (scrollDisable) {
      const body: HTMLBodyElement | null = document.querySelector('body')
      if (body) body.style.overflow = isOpen ? 'hidden' : 'auto'
    }
  }, [isOpen, scrollDisable])

  useEffect(() => {
    showShowModal(isOpen)
    enableScroll()
  }, [enableScroll, isOpen, scrollDisable])

  const handleCancel = useCallback(
    (type: string) => {
      if (disabled && type === 'button') {
        return
      }
      showShowModal(false)
      enableScroll()

      if (onClose) onClose(type, modalId)
    },
    [disabled, enableScroll, onClose, modalId]
  )

  const handleOutsideCancel = useCallback(
    (type: string) => {
      if (!backdropCancel) {
        return
      }
      showShowModal(false)
      enableScroll()
      if (onClose) onClose(type, modalId)
    },
    [backdropCancel, enableScroll, onClose, modalId]
  )

  const handleSecondaryAction = useCallback(() => {
    if (disabled) {
      return
    }
    showShowModal(false)
    enableScroll()
    if (secondaryAction) secondaryAction()
    if (onClose) onClose('button', modalId)
  }, [disabled, enableScroll, secondaryAction, onClose, modalId])

  const handleSubmit = useCallback(() => {
    if (disabled) {
      return
    }
    if (onSubmit) onSubmit()
  }, [onSubmit, disabled])

  // extra continueLabel and new button added
  // Purpose : Category list from have 3 button . so added extra save and close button
  const handleContinueSubmit = useCallback(() => {
    if (disabled) {
      return
    }
    if (onContinueAction) onContinueAction()
  }, [onContinueAction, disabled])
  const getStyleClass = () => {
    if (isFullView) {
      return
    }

    if (type === 'md') return 'md:w-4/6 lg:w-2/3 xl:w-2/4'
    else if (type === 'lg') return 'md:w-5/6 lg:w-5/6 xl:w-5/6'
    else if (type === 'full') return 'md:w-6/6 lg:w-6/6 xl:w-6/6'
    else if (type === 'sm') return 'w-[600px] '
    else if (type === 'auto') return 'w-[800px] '
    else if (type === 'xs') return 'w-[400px] ' // Added one more dialog size
    return small ? ' md:w-2/4 lg:w-1/4 xl:w-1/4' : 'md:w-5/6 lg:w-4/6 xl:w-2/3'
  }

  if (!isOpen) {
    return null
  }

  const modalContent = (
    <>
      <div
        className={`justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none ${noPortal ? 'bg-overlay bg-opacity-20' : ''} focus:outline-none overscroll-none ${isFullView ? 'overflow-hidden' : 'overflow-y-auto '} ${
          !showModal ? 'hidden' : ''
        }`}
        onClick={() => handleOutsideCancel('outside')}
        data-testid="modal-outside"
      >
        <div
          className={`relative ${isFullView ? 'w-[calc(100dvw-40px)] h-[calc(100dvh-40px)] ' : `h-auto m-auto lg:h-auto md:h-auto ${getStyleClass()}`}   `}
        >
          <div
            className={`
            translate
            duration-300
            ${isFullView ? ' h-full ' : ' h-auto '}
            ${showModal ? 'translate-y-0' : 'translate-y-full'}
            ${showModal ? 'opacity-100' : 'opacity-0'}
          `}
          >
            <div
              className={`
              translate
             ${isFullView ? ' h-full isFullView ' : ' h-auto lg:h-auto md:h-auto'}
              border-1
              modal-border
              relative
              flex
              flex-col
              w-full
              bg-white
              focus:outline-none
              rounded-dialog 
              dialog
              ${leftSection ? 'hasLeftSection' : ''} 
              ${rightSection ? 'hasRightSection' : ''}
            `}
            >
              <div
                className={`flex ${rightSection || leftSection ? `gap-[15px] ${width <= 1000 ? 'flex-col' : ''}` : 'flex-col'}`}
              >
                {leftSection && (
                  <div className=" rounded-l-dialog h-auto">{leftSection}</div>
                )}
                <div
                  className={`${leftSection || rightSection ? 'py-10 flex-grow' : ''} ${width > 1000 ? (leftSection ? 'pr-10' : '') : 'px-10'}  ${width > 1000 ? (rightSection ? 'pl-10' : '') : 'px-10'}`}
                >
                  <div className={` text-left ${isFullView ? 'overflow-auto' : ''}`}>
                    {!isFullView && (
                      <div
                        className={`flex justify-between items-center pb-[26px] ${headClassName} ${width > 1000 ? '' : `${leftSection || rightSection ? 'hidden' : ''}`}`}
                      >
                        {title && (
                          <div className="">
                            <h3 className="  font-semibold text-xl leading-5 tracking-[0.4px] text-secondary">
                              {title}
                            </h3>
                          </div>
                        )}
                        {isCloseIcon && (
                          <button
                            type="button"
                            className="close-button text-gray-400 hover:bg-gray-200 rounded-full  dark:hover:bg-gray-600 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
                            data-modal-hide="popup-modal"
                            onClick={() => handleCancel('close')}
                            data-testid="close-icon"
                          >
                            <CustomIcons
                              name="close"
                              className="flex items-center justify-center   "
                              data-testid="button-icon-left"
                            />
                          </button>
                        )}
                      </div>
                    )}
                    <div
                      className={`flex flex-col ${isFullView ? 'max-h-[calc(100dvh-140px)]  min-h-[calc(100dvh-140px)] h-[calc(100dvh-140px)]   overflow-auto ' : ''}`}
                    >
                      {body}
                    </div>
                    {actionBody && (
                      <div className="flex flex-col pt-10">{actionBody}</div>
                    )}

                    {(actionLabel || secondaryActionLabel || continueLabel) &&
                      !actionBody && (
                        <div className={`flex flex-col ${isFullView ? 'pt-5' : 'pt-10'}`}>
                          <div
                            className="
                    flex
                    flex-row
                    items-end
                    gap-2.5
                    w-full
                    justify-end
                  "
                          >
                            {actionLabel && (
                              <Button
                                disabled={actionDisabled ?? disabled}
                                size="xs"
                                type="submit"
                                primary={false}
                                isLoading={actionLoader}
                                label={actionLabel}
                                onClick={handleSubmit}
                                className="inline-flex items-center  text-center "
                              />
                            )}
                            {continueLabel && (
                              <Button
                                disabled={actionDisabled ?? disabled}
                                size="xs"
                                type="submit"
                                primary={false}
                                isLoading={continueLoader}
                                label={continueLabel}
                                onClick={handleContinueSubmit}
                                outlined={true}
                                isWarning={true}
                                className="inline-flex items-center text-center border-[#E55353!important] bg-[#fff] hover:bg-[#E5535311]"
                              />
                            )}
                            {secondaryAction && secondaryActionLabel && (
                              <Button
                                size="xs"
                                disabled={disabled}
                                label={secondaryActionLabel}
                                onClick={() =>
                                  secondaryAction
                                    ? secondaryAction?.()
                                    : handleSecondaryAction()
                                }
                                outlined
                              />
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                {rightSection && (
                  <div className=" rounded-r-dialog h-auto">{rightSection}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && !fromModal && (
        <div className="opacity-50 fixed inset-0 z-40 bg-overlay"></div>
      )}
    </>
  )

  return <Portal>{modalContent}</Portal>
}

export default DialogModal

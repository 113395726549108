const NextMost = () => {
  return (
    <path
      d="M4.80005 18L10.8 12L4.80005 6M13.2 18L19.2 12L13.2 6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}

export default NextMost

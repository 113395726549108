import { TextField } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { useDashBoradCreate, useDashBoradRename } from '@/pages/dashboard/api'
import { ManageDashboardForm, ManageDashboardFormSchema } from '@/pages/dashboard/schema'
import { useAppStore } from '@/store/appStore'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type DashboardManageTabsProps = {
  handleClose: () => void
  isOpen: boolean
  dashname?: string
  dashid?: string | number
  afterSubmit?: () => void
  rename?: boolean
}
const DashboardManageTabs: React.FC<DashboardManageTabsProps> = ({
  handleClose,
  isOpen,
  dashname,
  dashid,
  afterSubmit,
  rename
}) => {
  const { setIsLoading } = useAppStore()

  const { t } = useTranslation()
  const {
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm<ManageDashboardForm>({
    resolver: zodResolver(ManageDashboardFormSchema),
    mode: 'onChange',
    defaultValues: {
      name: dashname ?? ''
    }
  })

  const onClose = () => {
    reset()
    handleClose()
  }
  const onSuccessAction = () => {
    onClose()
    if (!dashid) {
      afterSubmit?.()
    }
  }
  const { mutate, isLoading } = useDashBoradCreate(onSuccessAction)
  const { mutate: update, isLoading: isUpdating } = useDashBoradRename(onSuccessAction)
  const onSubmit = (data: any) => {
    if (dashid) {
      update({ DashId: dashid, NewName: data.name })
    } else {
      mutate(data)
    }
  }
  useEffect(() => {
    if (dashname) {
      reset({ name: dashname })
    }
  }, [dashname])
  const DashboardContent = () => {
    return (
      <TextField
        label={t('commonDashboard.labels.dashboard-name')}
        id="name"
        autoFocus={true}
        name="name"
        value={watch()?.name}
        onChange={(e) => setValue('name', e.target.value, { shouldValidate: true })}
        required
        errors={errors}
      />
    )
  }
  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])
  useEffect(() => {
    setIsLoading(isUpdating)
  }, [isUpdating])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogModal
        isOpen={isOpen}
        onClose={onClose}
        type="small"
        title={rename ? 'Rename Dashboard' : 'Add Dashboard'}
        secondaryAction={onClose}
        secondaryActionLabel={t('cancel')}
        actionLabel={t('save')}
        actionLoader={isLoading || isUpdating}
        body={<DashboardContent />}
      />
    </form>
  )
}

export default DashboardManageTabs

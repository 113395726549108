const PreviousMost = () => {
  return (
    <path
      d="M19.2 18L13.2 12L19.2 6M10.8 18L4.80005 12L10.8 6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}

export default PreviousMost

import {
  getTechnicalMipsData,
  updateTechnicalMipsData
} from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'

export const getTechnicalDetailData = (id: string) => {
  return getTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/GetTechnicalDetails/${id}`
  )
}

export const updateTechnicalDetailData = (data: any) => {
  return updateTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/UpdateTechnicalDetails`,
    data
  )
}

export const getDimensionData = (id: string) => {
  return getTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/GetDimensionDetails/${id}`
  )
}

export const updateDimensionData = (data: any) => {
  return updateTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/UpdateDimensionDetails`,
    data
  )
}

export const getCapacityUsageData = (id: string) => {
  return getTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/GetCapacityAndUsage/${id}`
  )
}

export const updateCapacityUsageData = (data: any) => {
  return updateTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/UpdateCapacityAndUsage`,
    data
  )
}

export const getLoadLineInfoData = (id: string) => {
  return getTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/GetLoadLineDetails/${id}`
  )
}

export const updateLoadLineInfoData = (data: any) => {
  return updateTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/UpdateLoadLineDetails`,
    data
  )
}

export const getOthersData = (id: string) => {
  return getTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/GetOtherTechDetails/${id}`
  )
}

export const updateOthersData = (data: any) => {
  return updateTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/UpdateOtherTechDetails`,
    data
  )
}

export const getTonnageData = (id: string) => {
  return getTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/GetTonnageDetails/${id}`
  )
}

export const updateTonnageData = (data: any) => {
  return updateTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/UpdateTonnageDetails`,
    data
  )
}

export const getRatedPowerData = (id: string) => {
  return getTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/GetRatedPowerDetails/${id}`
  )
}

export const updateRatedPowerData = (data: any) => {
  return updateTechnicalMipsData(
    `${apiTechnicalUrl.VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS}/UpdateRatedPowerDetails`,
    data
  )
}

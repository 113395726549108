import { Checkbox, Loader, SearchInput } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import NoRecordsFound from '@/components/common/table/NoRecords'
import {
  getOilTypesInTankByTankId,
  updateOilTypesByTankId
} from '@/modules/technical/pages/vesselParticulars/details/tanks/api'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type VesselTankInfoProps = {
  VesTankId: string
  open: boolean
  onClose: () => void
  tankCompartmentname: string
  refetch: any
}
type VesselTankItemProps = {
  filter: boolean
  Selected: boolean
  OilTypeId: string | number
  OilTypeName: string
}
const TankOilTypes: React.FC<VesselTankInfoProps> = ({
  VesTankId,
  refetch,
  open,
  tankCompartmentname,
  onClose
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [submiting, setSubmitting] = useState<boolean>(false)
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [search, setSearch] = useState<string | undefined>('')
  const [hasResults, setHasResults] = useState<boolean>(false)
  const [data, setData] = useState<VesselTankItemProps[]>([])
  const { t } = useTranslation()
 const { showSnackBar } = useCustomSnackBar()
  
  const oilTypeCompare = (a: VesselTankItemProps, b: VesselTankItemProps) => {
    let comparison = 0
    const itemA = a['OilTypeName'].toUpperCase()
    const itemB = b['OilTypeName'].toUpperCase()
    if (itemA > itemB) {
      comparison = 1
    } else if (itemA < itemB) {
      comparison = -1
    }

    return comparison
  }
  const getOilTypesListData = () => {
    setIsLoading(true)
    getOilTypesInTankByTankId(VesTankId)
      .then((res) => {
        const oilTypeData =
          res.OilTypes && res.OilTypes.length !== 0
            ? res.OilTypes.sort(oilTypeCompare)
            : []
        oilTypeData.forEach((item: VesselTankItemProps) => {
          item['filter'] = true
        })
        setData([...oilTypeData])
        setHasResults(oilTypeData.length ? true : false)
      })
      .catch((error) => {
        showSnackBar(error.response.data.error || 'Something went wrong', 'error')
        setHasResults(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    if (VesTankId) {
      getOilTypesListData()
    }
  }, [VesTankId])
  const handleCloseForm = () => {
    setData([])
    setHasResults(false)
    setAllSelected(false)
    setSearch('')
    onClose()
  }
  const handleChange = (value: string) => {
    setSearch(value)
  }

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAllSelected(e.target.checked)
    setData((items: VesselTankItemProps[]) =>
      items.map((item: VesselTankItemProps) => ({
        ...item,
        Selected: e.target.checked
      }))
    )
  }
  const handleSelectChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ) => {
    setData((items: VesselTankItemProps[]) =>
      items.map((item: VesselTankItemProps) => ({
        ...item,
        ...(item.OilTypeId === id ? { Selected: e.target.checked } : {})
      }))
    )
  }
  const handleCheckParentSelect = () => {
    let selected = true
    let filtered = false
    if (data.length) {
      data.forEach((item: VesselTankItemProps) => {
        if (!item.Selected) {
          selected = false
        }
        if (item.filter) {
          filtered = true
        }
      })
    } else {
      filtered = false
      selected = false
    }
    setAllSelected(selected)
    setHasResults(filtered)
  }
  const handleFilter = () => {
    if (search && search !== '') {
      setData((items: VesselTankItemProps[]) =>
        items.map((item: VesselTankItemProps) => ({
          ...item,
          filter: item['OilTypeName']!.toString()
            .toUpperCase()
            .includes(search.toUpperCase())
            ? true
            : false
        }))
      )
    } else {
      setData((items: VesselTankItemProps[]) =>
        items.map((item: VesselTankItemProps) => ({
          ...item,
          filter: true
        }))
      )
    }
  }
  useEffect(() => {
    handleCheckParentSelect()
  }, [data])
  useEffect(() => {
    handleFilter()
  }, [search])
  const onSubmit = () => {
    setSubmitting(true)
    let Items: { OilTypeId: string | number }[] = []
    data.forEach((item: VesselTankItemProps) => {
      if (item.Selected) {
        Items.push({ OilTypeId: item.OilTypeId })
      }
    })

    const payload = {
      VesTankId: VesTankId,
      OilTypes: Items
    }
    if (Items.length === 0) {
      showSnackBar('Select atleast one item', 'error')
      setSubmitting(false)
    } else {
      updateOilTypesByTankId(payload)
        .then((res) => {
          showSnackBar(res)
          refetch()
          handleCloseForm()
        })
        .catch((error) => {
          showSnackBar(error.response.data.error || 'Something went wrong', 'error')
        })
        .finally(() => {
          setIsLoading(false)
          setSubmitting(false)
        })
    }
  }
  return (
    <>
      <DialogModal
        isOpen={open}
        small={true}
        title={`Oil Types / ${tankCompartmentname}`}
        body={
          <>
            <div className="h-full relative w-full">
              <div className="flex  flex-col gap-4 w-full pb-3 pt-4">
                <div className="w-full">
                  <SearchInput
                    placeholder="Search Oil Type"
                    handleChange={handleChange}
                    searchValue={search}
                    handleSearch={() => handleFilter()}
                  />
                </div>
                <div className="w-40">
                  <Checkbox
                    name="select_all"
                    label="Select All"
                    id="select_all"
                    checked={allSelected}
                    handleChange={handleSelectAllChange}
                  />
                </div>
              </div>

              <div className=" max-h-[400px] overflow-auto grid  grid-cols-12 gap-2 pt-3">
                {data.map(
                  (item: VesselTankItemProps, i: number) =>
                    item.filter && (
                      <div key={`${i}`} className="col-span-6">
                        {item.filter && (
                          <Checkbox
                            name={item.OilTypeName}
                            label={item.OilTypeName}
                            id={item.OilTypeName}
                            checked={item.Selected}
                            handleChange={(e) => handleSelectChange(e, item.OilTypeId)}
                          />
                        )}
                      </div>
                    )
                )}
              </div>
            </div>
            <div>
              {isLoading ? (
                <Loader isActive={true} />
              ) : !hasResults ? (
                <NoRecordsFound />
              ) : null}
            </div>
          </>
        }
        onClose={handleCloseForm}
        onSubmit={onSubmit}
        actionLabel={t('save')}
        actionLoader={submiting}
        disabled={isLoading || submiting}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={t('close')}
      />
    </>
  )
}

export default TankOilTypes

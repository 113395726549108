import { TabItemProps } from '@/common/customComponenttypes'
import { HeaderWrapper, SectionWraper } from '@/components/app/common'
import DetailHeaderComponent, {
  MainContentProps,
  SubContentProps
} from '@/components/app/common/DetailHeaderComponent'
import { Breadcrumb, Tab, TabContainer } from '@/components/common'
import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { routerStore } from '@/store'
import { useAppStore } from '@/store/appStore'
import formatDate from '@/utilities/usedateformat'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import { detailHeaderBackground } from '@/styles/variables'
import { getInspDynamicMenu } from '../api'
import { useVesselInspectionDetails } from './api'

const VesselInspectionDetails: React.FC = () => {
  const params = useParams()

  const navigate = useNavigate()
  const location = useLocation()
  const baseURL = `${routerStore.VESSEL_INSPECTION}/${params.inspID}`
  const items = location?.pathname?.split('/')
  const lastItem = items[items.length - 1]
  const [isOpen, setIsOpen] = useState(false)
  const [section, setSection] = useState<string | number>('')

  const { t } = useTranslation()
  const { setIsLoading } = useAppStore()
  const { data, isLoading, refetch } = useVesselInspectionDetails(params.inspID as string)
  const [activeTab, setActiveTab] = useState<number | string>(lastItem)
  const [edit, setEdit] = useState<boolean>(false)
  const breadcrumbConfig = [
    { label: t('technical.technical'), link: routerStore.VESSEL_INSPECTION },
    {
      label: t('Vessel Inspections'),
      link: routerStore.VESSEL_INSPECTION
    },
    { label: 'Inspection Details' }
  ]
  useEffect(() => {
    setActiveTab(lastItem)
  }, [lastItem])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  const onTabClick = (selection: TabItemProps) => {
    if (edit) {
      setIsOpen(true)
      setSection(selection.id)
    } else {
      setActiveTab(selection.id)
      navigate(`${baseURL}/${selection.id as string}`)
    }
  }
  const handleConfirm = () => {
    setEdit(false)
    setIsOpen(false)
    setActiveTab(section)
    navigate(`${baseURL}/${section as string}`)
  }
  const tabData: TabItemProps[] = [
    {
      label: 'Details',
      id: routerStore.VESSEL_INSPECTIONS_DETAILS_GENERAL_INFO
    },
    {
      label: 'Findings',
      id: routerStore.VESSEL_INSPECTIONS_DETAILS_FINDINGS
    },
    {
      label: 'Documents',
      id: routerStore.VESSEL_INSPECTIONS_DETAILS_DOCUMENTS
    },
    {
      label: 'Audit Log',
      id: routerStore.VESSEL_INSPECTIONS_DETAILS_AUDIT_LOG
    }
  ]

  const handleDynamicmenu = async (row?: any) => {
    const data = await getInspDynamicMenu(params.inspID as string, 'det')
    return data
  }

  const detailHeaders: MainContentProps[] = [
    { data: data?.VesselName, type: 'title' },
    { data: data?.InspTypeName, type: 'desc' },
    { data: data?.MasterName, type: 'status' }
  ]
  const detailContents: SubContentProps[] = [
    {
      label: t('Company'),
      value: data?.CompanyName,
      spacing: 4,
      hasDivider: true
    },
    {
      label: t('Start Date'),
      value: formatDate(data?.StartDate),
      spacing: 2
    },
    {
      label: t('End Date'),
      value: formatDate(data?.EndDate),
      spacing: 2
    },
    {
      label: t('Inspector Type'),
      value: data?.InspTypeName,
      spacing: 2
    },
    {
      label: t('Inspector'),
      value: data?.InspectorName,
      spacing: 2
    },
    {
      label: t('Officer'),
      value: data?.OfficerName,
      spacing: 4
    },
    {
      label: t('Chief Engineer'),
      value: data?.ChengName,
      spacing: 2
    },
    {
      label: t('Status'),
      value: data?.StatusName,
      spacing: 2
    },
    {
      label: t('Updated By'),
      value: data?.UpdatedBy,
      spacing: 2
    },
    {
      label: t('Updated On'),
      value: formatDate(data?.UpdatedOn),
      spacing: 2
    }
  ]
  const refetchData = () => {
    refetch()
  }
  const contextProps = {
    edit: edit,
    setEdit: setEdit,
    inspID: params.inspID,
    VesselId: data?.VesselId,
    InspectionTypeId: data?.InspectionTypeId,
    data: data,
    refetchData: refetchData
  }
  return (
    <>
      {/* <Headertitle title={t('Vessel Inspections')} /> */}
      <Breadcrumb items={breadcrumbConfig} />
      <div className={detailHeaderBackground}>
        <HeaderWrapper search={false}>
          <DetailHeaderComponent
            detailId={params.inspID}
            isLoading={isLoading}
            detailHeaders={detailHeaders}
            detailContents={detailContents}
            avatar="/assets/ship_avatar.png"
            // actionDropDown={headerActions}
            // handleMenuActions={handleMenuActions}
            workflowParams={{
              isDynamicMenu: true,
              handleMenuItems: handleDynamicmenu,
              handleRefresh: refetch
            }}
          />
        </HeaderWrapper>
      </div>
      <SectionWraper>
        <TabContainer
          data={tabData}
          activeTab={activeTab}
          isContainer={true}
          onClick={onTabClick}
          tabBGClass="bg-tab-detail  px-[15px]  rounded-sm"
        >
          <Tab>
            <Outlet context={contextProps} />
          </Tab>
        </TabContainer>
      </SectionWraper>
      <ConfirmModal
        isOpen={isOpen}
        modalId="errorPopup"
        title={'Confirmation'}
        type="error"
        secondaryActionLabel="No"
        secondaryAction={() => setIsOpen(false)}
        actionLabel="Yes"
        onSubmit={() => handleConfirm()}
        topIcon="error"
        scrollDisable={true}
        onClose={() => setIsOpen(false)}
        subTitle={'Do you want to discard all the changes made?'}
      />
    </>
  )
}

export default VesselInspectionDetails

import DialogModal from '@/components/common/modal/DialogModal'
import { useCreateCompanyService } from '@/pages/masterData/company/api'
import { FormSchema, formSchema } from '@/pages/masterData/company/details/service/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ServiceForm } from './ServiceForm'
import { guid } from '@/constants'
type ManageCurrencyProps = {
  open: boolean
  handleClose: () => void
  refetch?: any
  CompanyId: string
}
const ManageCompanyService: React.FC<ManageCurrencyProps> = ({
  open,
  handleClose,
  refetch,
  CompanyId
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const onSuccess = () => {
    refetch?.()
    handleCloseForm()
  }
  const { mutate } = useCreateCompanyService(onSuccess, setIsSubmitting)

  const methods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      CompanyId: CompanyId,
      CompServiceId: guid
    }
  })
  const [openForm, SetOpenForm] = useState(open)
  useEffect(() => {
    methods.reset({
      CompServiceId: guid,

      CompanyId: CompanyId
    })
    SetOpenForm(open)
  }, [open])
  const handleCloseForm = () => {
    methods.reset({
      CompServiceId: guid,

      CompanyId: CompanyId
    })
    handleClose()
    SetOpenForm(false)
  }
  const onSubmit = (data: FormSchema) => {
    mutate(data)
    setIsSubmitting(true)
  }

  return (
    <div>
      <FormProvider {...methods}>
        <DialogModal
          isOpen={openForm}
          type="sm"
          title={'Add Service'}
          body={<ServiceForm edit={true} editMode={false} />}
          onClose={() => handleCloseForm()}
          actionLabel={'Save'}
          onSubmit={() => methods.handleSubmit(onSubmit)()}
          secondaryAction={() => handleCloseForm()}
          secondaryActionLabel={'Cancel'}
          actionLoader={isSubmitting}
        />
      </FormProvider>
    </div>
  )
}

export default ManageCompanyService

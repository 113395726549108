// import { CustomTable } from '@/apis/common.api'
import { CustomKendoTable, DeletePopUp } from '@/components/common'
import VesselTankManage from '@/modules/technical/components/app/vesselParticulars/tanks/TankForm'
// import dayjs from 'dayjs'
import TankOilPresent from '@/modules/technical/components/app/vesselParticulars/tanks/TankOilPresent'
import TankOilTypes from '@/modules/technical/components/app/vesselParticulars/tanks/TankOilTypes'
import useHasPermission from '@/utilities/checkActionPermission'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import { TableHeight, guid, isAllow } from '@/constants'
import { ParticularDetailsContextProps } from '../schema'
import { deleteTank, useTankListing } from './api'
import { useAppContext } from '@/store/context/appContext'

const sortFields = [
  'TankTypeName',
  'Compartment',
  'Oils',
  'ROB_m3',
  'ROBPer_m3',
  'Volume',
  'Volume98',
  'Volume95',
  'Density',
  'Weight',
  'SteamCoilTypeName',
  'LevelGaugeTypeName'
]
type PageParams = {
  pageNo: number
  VesselId?: string
  pageSize: number
  VesFiltType: number
  sortBy: string
}
let updatekey = 1
const TechnicalParticularTanks: React.FC = () => {
  const { vesselID } = useOutletContext<ParticularDetailsContextProps>()
  const initData = {
    VesTankId: guid,
    VesselId: vesselID
  }
  const initialSelectedTankData = {
    VesselId: vesselID,
    VesTankId: null,
    VesFiltType: 1
  }
  const [total, setTotal] = useState(0)
  const [rowData, setRowData] = useState<any>(initData)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [view, setView] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [oilPresentOpen, setOilPresentOpen] = useState(false)
  const [oilTypeOpen, setOilTypeOpen] = useState(false)
  const [selectedTankData, setSelectedTankData] = useState(initialSelectedTankData)
  const [subTitle, setSubTitle] = useState<string>('')
  const {setIsActionLoader, isActionLoader } = useAppContext()
  const [tankId, setTankId] = useState<string>('')
  const { showSnackBar } = useCustomSnackBar()
  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    VesselId: vesselID,
    VesFiltType: 1,
    pageSize: 20,
    sortBy: sortFields[0]
  })

  const hasPermission = useHasPermission('VesselTank')
  const { t } = useTranslation()
  const { data, refetch, isFetching } = useTankListing(params)
  const handleView = (row: any) => {
    setRowData(row)
    setView(true)
    setCreateOpen(true)
  }
  const columns = [
    {
      title: t('type'),
      field: 'TankTypeName',
      action: true,
      sort: true,
      width: 250,
      locked: true,
      render: (row: any) => (
        <span
          onClick={() => handleView(row)}
          className="kendo-column-text-link cursor-pointer truncate"
        >
          {row?.TankTypeName}
        </span>
      )
    },

    { title: t('Compartment'), field: 'Compartment', sort: true },
    { title: 'Oils', field: 'Oils', sort: true },
    {
      title: t('ROB (m3)'),
      align: 'right',
      width: 150,
      field: 'ROB_m3',
      sort: true
    },
    {
      title: t('ROB %'),
      align: 'right',
      width: 100,
      field: 'Percentage',
      sort: true
    },
    {
      title: t('Volume 100% (m3)'),
      align: 'right',
      width: 150,
      field: 'Volume',
      sort: true
    },

    {
      title: t('Volume 98% (m3)'),
      align: 'right',
      width: 150,
      field: 'Volume98',
      sort: true
    },
    {
      title: t('Volume 95% (m3)'),
      align: 'right',
      width: 150,
      field: 'Volume95',
      sort: true
    },
    {
      title: t('Density (kg/m3)'),
      align: 'right',
      width: 150,
      field: 'Density',
      sort: true
    },
    {
      title: t('Weight (MT)'),
      align: 'right',
      width: 120,
      field: 'Weight',
      sort: true
    },
    { title: t('Steam Coil'), field: 'SteamCoilTypeName', sort: true, width: 150 },
    { title: t('Level Gauging'), field: 'LevelGaugeTypeName', sort: true, width: 150 }
  ]

  useEffect(() => {
    setTotal(data?.TotalRecords)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])
  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }
  const generatePermisiionUserActions = (slug: string) => {
    switch (slug) {
      case 'delete':
        return isAllow.DELETE
      case 'edit':
        return isAllow.UPDATE
      case 'oil_types':
        return isAllow.UPDATE
      default:
        return null
    }
  }
  const handleMenuActions = async (slug: string, row: any) => {
    setTankId(row?.VesTankId)
    setSubTitle(row?.TankTypeName)
    const userAction = generatePermisiionUserActions(slug)
    let permission = false

    const permissionParams = {
      UserAction: userAction,
      AccessKey: '',
      ID1: vesselID
    }

    if (userAction) {
      permission = await hasPermission(permissionParams)
    }

    switch (slug) {
      case 'delete':
        setDeleteOpen(permission)
        setRowData({
          ...row
        })
        break
      case 'edit':
        setRowData({
          ...row
        })
        setCreateOpen(permission)
        setEdit(true)
        break
      case 'oil_present':
        setRowData(row)
        setSelectedTankData((current) => ({ ...current, VesTankId: row.VesTankId }))
        setOilPresentOpen(true)

        break
      case 'oil_types':
        setRowData(row)
        setSelectedTankData((current) => ({ ...current, VesTankId: row.VesTankId }))
        setOilTypeOpen(true)

        break
      default:
        break
    }
  }

  const handleDelete = () => {
    setIsActionLoader(true)
    deleteTank(tankId)
      .then((res) => {
        showSnackBar(res)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err.response.data.error, 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }
  const handleCreate = async () => {
    const permissionParams = {
      UserAction: 1,
      AccessKey: '',
      ID1: vesselID
    }
    setRowData({})
    const permission = await hasPermission(permissionParams)
    setEdit(false)
    setCreateOpen(permission)
    updatekey = updatekey + 1
  }
  const handleCloseForm = () => {
    setCreateOpen(false)
    setRowData(initData)
    setEdit(false)
    setView(false)
  }
  const handleCloseOilPresent = () => {
    setOilPresentOpen(false)
    setSelectedTankData(initialSelectedTankData)
  }
  const handleCloseOilTypes = () => {
    setOilTypeOpen(false)
    setSelectedTankData(initialSelectedTankData)
  }
  return (
    <div>
      <CustomKendoTable
        columns={columns}
        data={data?.Results}
        pagination={true}
        toolBar={true}
        sortable={true}
        resizable={true}
        isFetching={isFetching}
        defaultSort={sortFields[0]}
        title={t('Tank Details')}
        isDownloadable={true}
        handleSort={handleSort}
        tableHeight={`calc(100vh - ${TableHeight.WithHeader})`}
        actionDropDown={[
          { title: 'Show Oil Types', iconName: 'eye', slug: 'oil_types', id: '1' },
          { title: 'Oil Present', iconName: 'Ok', slug: 'oil_present', id: '2' },
          { title: 'Edit', iconName: 'edit', slug: 'edit', id: '3' },
          {
            title: 'Delete',
            iconName: 'delete',
            slug: 'delete',
            id: '4',
            isWarning: true
          }
        ]}
        handleMenuActions={handleMenuActions}
        primaryAction={{ title: 'New', handlePrimaryAction: handleCreate }}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },
          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onSubmit={handleDelete}
        itemLabels={{ title: 'tank details', subTitle: subTitle }}
        actionLoader={isActionLoader}
      />

      <VesselTankManage
        key={updatekey}
        data={rowData}
        view={view}
        refetch={refetch}
        onClose={handleCloseForm}
        edit={edit}
        open={createOpen}
        title={`${edit ? 'Edit ' : view ? 'View' : 'Add '} Vessel Tank`}
      />
      <TankOilPresent
        searchData={selectedTankData}
        onClose={handleCloseOilPresent}
        open={oilPresentOpen}
        tankCompartmentname={rowData.Compartment}
      />
      <TankOilTypes
        VesTankId={selectedTankData.VesTankId!}
        onClose={handleCloseOilTypes}
        open={oilTypeOpen}
        refetch={refetch}
        tankCompartmentname={rowData.Compartment}
      />
    </div>
  )
}

export default TechnicalParticularTanks

import { Loader } from '@/components/common'
import crewingRoutes from '@/modules/crewing/routes'
import technicalRoutes from '@/modules/technical/routes'
import Login from '@/pages/auth/login'
import CustomComponents from '@/pages/components'
import Dashboard from '@/pages/dashboard'
import {
  Forbidden,
  Maintenance,
  NotFound,
  SessionTimeout,
  Unauthorized
} from '@/pages/errors'
import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import operationsRoutes from '@/modules/operations/routes'
import payrollRoutes from '@/modules/payroll/routes'
import pmsRoutes from '@/modules/pms/routes'
import CommonRoute from './commonroute'
import PrivateRoute from './privateroute'
import PublicRoute from './publicroute'
import masterDataRoutes from './subroutes/masterData'
import systemRoutes from './subroutes/system'

export default function MainRoutes() {
  return (
    <Routes>
      <Route
        element={
          <PublicRoute>
            <div>
              <Login />
            </div>
          </PublicRoute>
        }
        path="/"
        key="/"
      />
      <Route
        element={
          <PublicRoute>
            <div>
              <Login />
            </div>
          </PublicRoute>
        }
        path="/login"
        key="/login"
      />

      <Route
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
        path="/dashboard"
        key="/dashboard"
      />
      <Route
        element={
          <PrivateRoute>
            <div>
              <CustomComponents />
            </div>
          </PrivateRoute>
        }
        path="/samples"
        key="/samples"
      />

      <Route
        path="/access-denied"
        key="/access-denied"
        element={
          <CommonRoute>
            <Forbidden />
          </CommonRoute>
        }
      />
      <Route
        path="/unauthorized"
        key="/unauthorized"
        element={
          <CommonRoute>
            <Unauthorized />
          </CommonRoute>
        }
      />
      <Route
        path="/session-timeout"
        key="/session-timeout"
        element={
          <PublicRoute>
            <SessionTimeout />
          </PublicRoute>
        }
      />
      <Route
        path="/maintenance"
        key="/maintenance"
        element={
          <CommonRoute>
            <Maintenance />
          </CommonRoute>
        }
      />

      {[
        ...technicalRoutes,
        ...systemRoutes,
        ...masterDataRoutes,
        ...crewingRoutes,
        ...operationsRoutes,
        ...payrollRoutes,
        ...pmsRoutes
      ].map((route: any) => (
        <Route
          element={<PrivateRoute>{route.component}</PrivateRoute>}
          path={route.path}
          key={route.path}
        >
          {route.children?.length && (
            <>
              {route.children.map((child: any) => (
                <Route
                  element={
                    <Suspense fallback={<Loader isActive />}>{child.component}</Suspense>
                  }
                  path={child.path}
                  key={child.path}
                  index={child.index}
                />
              ))}
            </>
          )}
        </Route>
      ))}
      <Route
        path="/*"
        key="/notfound"
        element={
          <CommonRoute>
            <NotFound />
          </CommonRoute>
        }
      />
    </Routes>
  )
}

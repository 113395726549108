import { CustomTable } from '@/components/common'
import testJson from '@/components/common/table/test.json'

const CustomTableComponent = () => {
  const conlumns = [
    {
      title: 'Name',
      field: 'name',
      type: 'text',
      sort: true
    },
    {
      title: 'Age',
      field: 'age',
      sort: true
    },
    {
      title: 'Date of Birth',
      field: 'dateOfBirth'
    },

    {
      title: 'City',
      field: 'city'
    },
    {
      title: 'Country',
      field: 'country'
    },
    {
      title: 'Gender',
      field: 'gender',
      type: 'boolean'
    }
  ]
  return (
    <div className="flex flex-wrap ">
      <div className="w-full">
        <CustomTable
          columns={conlumns}
          data={testJson}
          search={true}
          toolBar={true}
          actionDropDown={[
            { title: 'Edit', slug: 'edit', iconName: 'table-edit', id: '1' },
            {
              title: 'Delete',
              slug: 'delete',
              iconName: 'table-delete',
              id: '2',
              isWarning: true
            }
          ]}
        />
      </div>
    </div>
  )
}

export default CustomTableComponent

import dayjs from 'dayjs'
// const useDateFormat = (
//   date: Date,
//   timeOnly: boolean = false,
//   format: string = 'DD-MM-YYYY'
// ) => {
//   const [formattedDate, setFormattedDate] = useState('')
//   if (timeOnly) {
//     format = 'hh:mm a'
//   }
//   useEffect(() => {
//     if (date) {
//       const formatted = dayjs(date).format(format)
//       setFormattedDate(formatted)
//     }
//   }, [date, format])

//   return formattedDate
// }

// export default useDateFormat

const formatDate = (
  date: Date,
  format: string = 'DD-MMM-YYYY',
  timeOnly: boolean = false,
  type: number = 12
): string => {
  if (timeOnly && type === 12) {
    format = 'hh:mm a'
  }
  if (timeOnly && type === 24) {
    format = 'hh:mm'
  }
  if (date) {
    return dayjs(date).format(format)
  }
  return ''
}

export default formatDate

const SortDesc = () => {
  return (
    <path
      d="M11.1272 14.7273L11.1272 2H12.9454L12.9454 14.7273H18.3999L12.0363 22L5.67263 14.7273L11.1272 14.7273Z"
      fill="currentColor"
    />
  )
}

export default SortDesc

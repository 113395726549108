import { getTechnicalMipsData, updateTechnicalMipsData } from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import GeneralSchema from './schema'

export const getGeneralInfo = (id: string) => {
  return getTechnicalMipsData(`${apiTechnicalUrl.GENERAL_INFO_MIPS}/Get/${id}`)
}

export const useVesselParticularsGeneral = (id: string) => {
  return useQuery({
    queryKey: ['vessel-particulars-general', id],
    queryFn: () => getGeneralInfo(id),
    staleTime: 500
  })
}

export const updateGeneralInfo = (data: GeneralSchema) => {
  return updateTechnicalMipsData(`${apiTechnicalUrl.GENERAL_INFO_MIPS}/Update`, data)
}

export const useGeneralInfo = () => {
  const { showSnackBar } = useCustomSnackBar()

  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateGeneralInfo,
    mutationKey: ['update-general-info'],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
    },
    onSuccess: (res, data) => {
      showSnackBar(res, 'success')
      queryClient.invalidateQueries(['vessel-particulars-technical', data.vesselId])
    }
  })
}

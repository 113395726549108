const DashFolderIcon = () => {
  return (
    <path
      d="M4.12222 10.8888V10.9888H4.22222H8.66667H8.76667V10.8888V4.2221V4.1221H8.66667H4.22222H4.12222V4.2221V10.8888ZM15.2333 19.7777V19.8777H15.3333H19.7778H19.8778V19.7777V13.111V13.011H19.7778H15.3333H15.2333V13.111V19.7777ZM15.2333 6.44432V6.54432H15.3333H19.7778H19.8778V6.44432V4.2221V4.1221H19.7778H15.3333H15.2333V4.2221V6.44432ZM4.12222 19.7777V19.8777H4.22222H8.66667H8.76667V19.7777V17.5554V17.4554H8.66667H4.22222H4.12222V17.5554V19.7777ZM13.2111 8.56654V2.09988H21.9V8.56654H13.2111ZM2.1 13.011V2.09988H10.7889V13.011H2.1ZM13.2111 21.8999V10.9888H21.9V21.8999H13.2111ZM2.1 21.8999V15.4332H10.7889V21.8999H2.1Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.2"
    />
  )
}
export default DashFolderIcon

import React, { useEffect, useRef, useState } from 'react'

import { Portal } from '../../../common'
import CustomIcons from '../../icons/customIcons'

type Props = {
  defaultSort?: string
  updateMenuRef?: (ref: HTMLDivElement | null) => void
  position?: string
  sortDropDown?: {
    id: string
    title: string
    slug: string
    iconName?: string
  }[]
  handleSort?: (order: string) => void
  iconName?: string
  iconSize?: string
  iconCustDimension?: number
  iconViewBox?: boolean
  isMouseOut?: boolean
}
type SortCriteria = {
  field: string
  direction: 'ASC' | 'DESC'
}

export default function SortDropDown({
  defaultSort,
  sortDropDown,
  updateMenuRef,
  handleSort,
  position,
  isMouseOut,
  iconSize,
  iconCustDimension
}: Props) {
  const [openMenu, setOpenMenu] = useState(false)
  // const [menuOrigin, setMenuOrigin] = useState<'top' | 'bottom'>('top')
  const menuButtonRef = useRef<HTMLButtonElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const [dropdownPosition, setDropdownPosition] = useState('bottom-position')
  const dropRef = useRef(null)
  const [selectedColumn, setSelectedColumn] = useState(defaultSort)
  const [sorted, setSorted] = useState('')

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpenMenu(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (defaultSort) {
      const sortCriteria = parseSortCriteria(defaultSort)
      setSorted(sortCriteria.direction)
      setSelectedColumn(sortCriteria.field)
    }
  }, [defaultSort])

  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  })

  const toggleMenu = () => {
    if (!openMenu && menuButtonRef.current) {
      const rect = menuButtonRef.current.getBoundingClientRect()
      const topSpace = rect.top
      const bottomSpace = window.innerHeight - rect.bottom
      const leftSpace = rect.left
      const rightSpace = window.innerWidth - rect.right

      const dropdownHeight = 200
      const dropdownWidth = 200

      let newPosition = 'bottom-right'

      if (bottomSpace < dropdownHeight && topSpace > dropdownHeight) {
        newPosition = 'top-right'
      }

      if (rightSpace < dropdownWidth && leftSpace > dropdownWidth) {
        newPosition = newPosition.replace('right', 'left')
      }

      if (topSpace < dropdownHeight && bottomSpace < dropdownHeight) {
        newPosition = leftSpace > rightSpace ? 'bottom-left' : 'bottom-right'
      }

      setDropdownPosition(newPosition)
      setMenuPosition({
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
        bottom: window.innerHeight - rect.bottom,
        right: window.innerWidth - rect.right
      })
    }
    setTimeout(() => {
      setOpenMenu(!openMenu)
    }, 200)
  }

  useEffect(() => {
    if (isMouseOut) {
      document.addEventListener('mouseout', handleClickOutside)
      return () => {
        document.removeEventListener('mouseout', handleClickOutside)
      }
    }
  }, [isMouseOut])

  const parseSortCriteria = (sort: string): SortCriteria => {
    const [field, direction] = sort.split(' ')
    return {
      field,
      direction: direction === 'DESC' ? 'DESC' : 'ASC'
    }
  }

  const handleMenuItemClick = (title: string) => {
    const sortable = (sorted === 'ASC' || sorted === '') && selectedColumn === title
    const order = sortable ? `${title} DESC` : `${title}`
    setSorted(sortable ? 'DESC' : 'ASC')
    setSelectedColumn(title)
    handleSort?.(order)
    setOpenMenu(false)
  }

  useEffect(() => {
    if (updateMenuRef) {
      updateMenuRef(menuRef.current)
    }
  }, [menuRef])
  const buttonWidth = menuButtonRef.current ? menuButtonRef.current.offsetWidth : 0
  const buttonHeight = menuButtonRef.current ? menuButtonRef.current.offsetHeight : 0
  return (
    <div className={'dropdown relative z-10'} ref={menuRef}>
      <button
        className={`border border-1 border-[#d7d7d7] rounded-menu flex items-center justify-center text-[#98A2B3] h-button-sm`}
        onClick={toggleMenu}
        ref={menuButtonRef}
      >
        <CustomIcons type={'large-m'} name={'sortArrow'} className="p-2 pl-3 mt-1" />
        <span className="text-text-secondary text-common">Sort By</span>
        <CustomIcons
          type={'large-m'}
          name={'menu-down-arrow'}
          className={`p-2 pl-3 mt-1 ml-1 ${openMenu ? 'transform scale-y-[-1]' : ''}`}
        />
      </button>
      {openMenu && (
        <Portal>
          <div
            ref={dropRef}
            style={{
              top: dropdownPosition.startsWith('bottom')
                ? `${menuPosition.top + window.scrollY + buttonHeight + 5}px`
                : 'auto',
              bottom: dropdownPosition.startsWith('top')
                ? `${menuPosition.bottom + buttonHeight + 5}px`
                : 'auto',
              left: dropdownPosition.endsWith('right')
                ? `${menuPosition.left}px`
                : 'auto',
              right: dropdownPosition.endsWith('left')
                ? `${window.innerWidth - menuPosition.left - buttonWidth}px`
                : 'auto'
            }}
            className={`dropdown-menu  ${dropdownPosition}   absolute ${
              position ? position : 'right-0'
            } mt-2 w-max  shadow-lg bg-white focus:outline-none `}
          >
            <div className="py-2 bg-white  w-full shadow-menudropdown">
              {sortDropDown?.map((item) => (
                <React.Fragment key={item.id}>
                  <a
                    key={item.id}
                    href="#/"
                    className={`px-3 py-2.5 leading-7 hover:bg-table-hover hover:text-secondary ${selectedColumn === item.slug ? 'bg-table-hover text-secondary' : ''} group/item`}
                    onClick={(e) => {
                      e.preventDefault()
                      handleMenuItemClick(item.slug)
                    }}
                  >
                    <div
                      className={`text-common flex items-center w-full tracking-[0.24px] font-normal leading-4`}
                    >
                      {item.iconName && (
                        <CustomIcons
                          name={item.iconName}
                          type={iconSize ?? 'large'}
                          custDimension={iconCustDimension}
                          className={''}
                        />
                      )}
                      <span className="pl-2 flex-auto">{item.title}</span>
                      <CustomIcons
                        name={'sortDown'}
                        type={'large'}
                        viewBox={true}
                        className={`right-0  ${selectedColumn === item.slug ? `text-primary ${sorted === 'DESC' ? 'transform scale-y-[-1]' : ''}` : 'text-text-more invisible'} group-hover/item:visible`}
                      />
                    </div>
                  </a>
                </React.Fragment>
              ))}
            </div>
          </div>
        </Portal>
      )}
    </div>
  )
}

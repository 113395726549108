import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
  isAxiosError
} from 'axios'

import { coreModules } from '../store'
import { useAuthStore } from '../store/authStore'

// let refreshTokenPromise: Promise<any> | null
interface ApiRespose extends AxiosResponse {}
type HttpError = AxiosError | Error
// interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
//   _retry?: boolean
// }

const coreAPi = (module?: string) => {
  const muduleSwitch = () => {
    switch (module) {
      case coreModules.MASTERDATA:
        return process.env.REACT_APP_MASTER_URL
      case coreModules.MASTERDATAMIPS:
        return process.env.REACT_APP_MASTER_MIPS_URL
      case coreModules.PMS:
        return process.env.REACT_APP_PMS_URL
      case coreModules.PMSMIPS:
        return process.env.REACT_APP_PMS_MIPS_URL
      case coreModules.SYSTEM:
        return process.env.REACT_APP_BASE_URL
      case coreModules.SYSTEMMIPS:
        return process.env.REACT_APP_BASE_MIPS_URL
      case coreModules.PURCHASE:
        return process.env.REACT_APP_PURCHASE_URL
      case coreModules.TECHNICAL:
        return process.env.REACT_APP_TECHNICAL_URL
      case coreModules.TECHNICALMIPS:
        return process.env.REACT_APP_TECHNICAL_MIPS_URL
      case coreModules.FINANCE:
        return process.env.REACT_APP_FINANCE_URL
      case coreModules.OPERATIONS:
        return process.env.REACT_APP_OPERATIONS_URL
      case coreModules.CREWING:
        return process.env.REACT_APP_CREWING_URL
      case coreModules.PAYROLL:
        return process.env.REACT_APP_PAYROLL_URL
      default:
        return process.env.REACT_APP_BASE_URL
    }
  }
  const serverApi = axios.create({
    baseURL: muduleSwitch(),
    headers: {
      'Content-Type': 'application/json'
    }
  })

  serverApi.interceptors.response.use(
    (res: ApiRespose) => {
      return res
    },
    async (err: HttpError) => {
      if (isAxiosError(err)) {
        console.log(err) // for testing purpose will be removed
        if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
          if (
            err.response?.headers['token-expired'] ||
            err.response?.headers['Token-Expired']
          ) {
            useAuthStore.getState().clearAuthenticated()
            localStorage.clear()
            sessionStorage.clear()
            localStorage.setItem('should_reload', new Date().toString())
            window.open('/session-timeout', '_self')
          } else {
            const event = new CustomEvent('UNAUTHORIZED', {
              detail: { message: 'Unauthorized access to this page' }
            })
            window.dispatchEvent(event)
            if (!err?.response?.data?.error)
              return Promise.reject({ response: { data: { error: 'UNAUTHORIZED' } } })
          }
        }
      }
      return Promise.reject(err)
    }
  )

  // serverApi.interceptors.response.use(
  //   (res: ApiRespose) => {
  //     return res
  //   },
  //   async (err: HttpError) => {
  //     if (isAxiosError(err) && err.response) {
  //       const originalConfig: ExtendedAxiosRequestConfig = err.config || {}
  //       originalConfig._retry = originalConfig._retry || false
  //       if (
  //         err.response.status === 401 &&
  //         originalConfig.url === `${process.env.REACT_APP_BASE_MIPS_URL}/Login/RenewToken`
  //       ) {
  //         window.open('/session-timeout', '_self')
  //         return false
  //       }
  //       if (err.response?.status === 401 && !originalConfig._retry) {
  //         originalConfig._retry = true

  //         const credentials = getRefreshParams(
  //           useAuthStore.getState().accessToken,
  //           useAuthStore.getState().browserId,
  //           useAuthStore.getState().refreshToken
  //         )
  //         try {
  //           if (!refreshTokenPromise) {
  //             refreshTokenPromise = axios
  //               .post(
  //                 // `${process.env.REACT_APP_BASE_URL}/login/GetNewToken`,

  //                 `${process.env.REACT_APP_BASE_MIPS_URL}/Login/RenewToken`,
  //                 credentials,
  //                 {
  //                   headers: {
  //                     'Content-Type': 'application/json',
  //                     Authorization: useAuthStore.getState().accessToken
  //                       ? `Bearer ${useAuthStore.getState().accessToken}`
  //                       : undefined
  //                   }
  //                 }
  //               )

  //               .then((rs: ApiRespose) => {
  //                 refreshTokenPromise = null // clear state
  //                 useAuthStore.setState({ accessToken: rs.data.access_token || '' })
  //                 serverApi.defaults.headers.common.Authorization = `Bearer ${rs.data.access_token}`
  //                 useAuthStore.setState({ refreshToken: rs.data.refresh_token || '' })
  //                 useAuthStore.setState({ authenticated: true })
  //                 originalConfig.headers = originalConfig.headers || {}
  //                 originalConfig.headers.Authorization = `Bearer ${rs.data.access_token}`
  //                 return serverApi(originalConfig)
  //               })
  //               .catch((err: HttpError) => {
  //                 refreshTokenPromise = null // clear state
  //                 if (isAxiosError(err)) {
  //                   if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
  //                     useAuthStore.getState().clearAuthenticated()
  //                     window.open('/session-timeout', '_self')
  //                   }
  //                 }
  //                 window.open('/session-timeout', '_self')
  //               })
  //           }

  //           return refreshTokenPromise.then(() => {
  //             if (err.config) {
  //               err.config.headers['Authorization'] = `Bearer ${
  //                 useAuthStore.getState().accessToken
  //               }`
  //               return serverApi(err.config)
  //             } else {
  //               return Promise.reject(err)
  //             }
  //           })
  //         } catch (_error) {
  //           if (isAxiosError(_error)) {
  //             if (_error.response && _error.response.data) {
  //               return Promise.reject(_error.response.data)
  //             }
  //             return Promise.reject(_error)
  //           }
  //         }
  //       }
  //       if (err.response.status === 401) {
  //         Cookies.remove('authenticated')
  //         localStorage.clear()
  //         useAuthStore.setState({ authenticated: undefined })
  //         window.open('/session-timeout', '_self')
  //         return Promise.reject(err)
  //       } else if (err.response.status === 403 && err.response.data) {
  //         if (err.response.config.method === 'get') window.open('/access-denied', '_self')
  //       }
  //       return Promise.reject(err)
  //     }
  //   }
  // )

  serverApi.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      config.headers = config?.headers ?? {}
      config.headers['Content-Type'] =
        config.data instanceof FormData ? 'multipart/form-data' : 'application/json'
      config.headers['Authorization'] = useAuthStore.getState().accessToken
        ? `Bearer ${useAuthStore.getState().accessToken}`
        : undefined
      config.headers['Version'] = '1.1'
      return config
    },
    (error: any) => {
      return Promise.reject(error)
    }
  )
  return { serverApi }
}

export default coreAPi

import CustomIcons from '../../icons/customIcons'

type ColProps = {
  col: {
    title: string
    field: string
    type?: string
    align?: 'right' | 'left' | 'center'
    icon?: string
    tooltip?: boolean
    render?: (row: any) => any
  }
  rowData: any
  lastChild?: boolean
  index?: number
}
export default function DataColumnFooter({ col, rowData }: ColProps) {
  const renderFunc = col.render && col.render
  return (
    <>
      {renderFunc && (
        <div
          key={col.title}
          className="p-cell text-xxs text-table-bodyColor font-normal td-custom"
        >
          {renderFunc(rowData)}
        </div>
      )}
      {!renderFunc && (
        <div
          key={col.title}
          className={`flex gap-1 items-center text-text-secondary font-medium leading-5 text-m`}
        >
          {col.icon && (
            <div>
              <CustomIcons name={col.icon} type="large-m" />
            </div>
          )}
          <div className={`text-text-secondary font-medium leading-5 text-m`}>
            {rowData[col.field] ?? '-'}
          </div>
        </div>
      )}
    </>
  )
}

import { AccordionProps } from '@/common/customComponenttypes'
import React, { useMemo } from 'react'
import { AccordionContextProvider } from './AccordionContext'

const Accordion: React.FC<AccordionProps> = ({
  id,
  open,
  children,
  className,
  icon,
  disabled = false
}) => {
  const contextValue = useMemo(() => ({ open, icon, disabled }), [open, icon, disabled])
  return (
    <>
      <AccordionContextProvider value={contextValue}>
        <div className={className} id={id as string} key={id as string}>
          {children}
        </div>
      </AccordionContextProvider>
    </>
  )
}

export default Accordion

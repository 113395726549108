import { updateTechnicalData } from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import useCustomSnackBar from '@/utilities/customSnackBar'
import GeneralSchema from './schema'


export const updateGeneralInfo = (data: GeneralSchema) => {
  return updateTechnicalData(`${apiTechnicalUrl.VESSEL_INSPECTION_FINDING_UPDATE}`, data)
}

export const useGeneralInfo = (onSuccessAction: () => void) => {
  const { showSnackBar } = useCustomSnackBar()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateGeneralInfo,
    mutationKey: ['update-general-infos'],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
    },
    onSuccess: (response, data) => {
      onSuccessAction()
      queryClient.invalidateQueries(['vessel-particulars-technical', data.vesselName])
    }
  })
}

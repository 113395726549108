import { SearchBuilderProps } from '@/common/customComponenttypes'
import { CommonSearch } from '@/components/common'
import useFindingCategoriesFilterStore from '@/modules/technical/pages/registers/findingCategories/store'
import React from 'react'
import { useTranslation } from 'react-i18next'
type SearchBoxProps = {
  onSearch: (filters: any) => void
}
const FindingCategoriesSearch: React.FC<SearchBoxProps> = ({ onSearch }) => {
  const { t } = useTranslation()
  const { filters, sireCatData } = useFindingCategoriesFilterStore()

  const formFields: SearchBuilderProps[] = [
    {
      label: t('Finding Category'),
      placeholder: t('Finding Category'),
      name: 'NameOrDesc',
      id: 'NameOrDesc',
      type: 'text',
      spacing: 4
    },
    {
      label: t('Sire Cat. Code'),
      placeholder: t('Sire Cat. Code'),
      name: 'SireCatCode',
      id: 'SireCat',
      type: 'custom_select',
      spacing: 4,
      data: sireCatData
    }
  ]

  return <CommonSearch onSearch={onSearch} data={formFields} activeFilters={filters} />
}

export default FindingCategoriesSearch

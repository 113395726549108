import { CustomKendoTable, DeletePopUp } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'
import { TableHeight } from '@/constants'
import {
  addAccount,
  deleteAccount,
  getBudgetAccountDetails,
  updateAccount,
  useBudgetList
} from '@/modules/technical/pages/vesselBudget/details/budgetdetails/api'
import { useAppContext } from '@/store/context/appContext'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'

import { ManageAccountWithChart } from '../manageAccount/ManageAccountWithChart'
import { ManageAccountWithoutChart } from '../manageAccount/ManageAccountWithoutChart'
import { AsummarySub } from './aSummarySubTable'

export interface outLet {
  BudgetId?: any
  ParentBudgetAccountId?: any
  activeNode?: any
  Title?: any
  postingType?: any
  budgetData?: any
  handleEXtExpand?: (node?: any) => void
  handleFetchData: (
    node: any,
    flag: boolean,
    action: 'create' | 'delete',
    id: string
  ) => void

  onValueChange?: any
  FinalIdEx?: any
  TitleFinalEx?: any
}

type PageParams = {
  ShowZeroBudgetItems: true
  ImmediateChildOnly: true
  HideMonthlyData: false
  NameOrDesc: ''
  pageNo: number
  pageSize: number
  sortBy: string
}

export interface columnInterface {
  title?: string
  field?: string
  show?: boolean
  filter?: 'boolean' | 'numeric' | 'text' | 'date' | undefined
  minWidth?: number
  minGridWidth?: number
  locked?: boolean
  width?: string | number
  cell?: any
}

export const AccountSummary: React.FC<outLet> = ({
  BudgetId,
  ParentBudgetAccountId,
  Title,
  postingType,
  activeNode,
  budgetData,
  handleEXtExpand,
  handleFetchData,

  onValueChange,
  FinalIdEx,
  TitleFinalEx
}) => {
  const { showSnackBar } = useCustomSnackBar()
  const [prntId, setprntId] = useState<any>(ParentBudgetAccountId)
  const [titleNode, setTitleNode] = useState<any>()
  const [finalId, setFinalId] = useState<any>()
  const [titleFinal, setTitleFinal] = useState<any>()
  const [selectedNode, setSelectedNode] = useState<any>()
  const [postingTypeId, setPostingTypeId] = useState<any>(postingType)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [viewable, setViewable] = useState(false)
  const [edit, setEdit] = useState(false)
  const [rowData, setRowData] = useState<any>(null)
  const [subTitle, setSubTitle] = useState<string>('')
  const { setIsActionLoader, isActionLoader } = useAppContext()
  const [activeRow, setActiveRow] = useState<any>(null)
  // const [budgetData, setBudgetData] = useState(null)
  const [createOpen, setCreateOpen] = useState(false)
  //const { showSnackBar } = useCustomSnackBar()

  const [params, setPrams] = useState<PageParams>({
    ShowZeroBudgetItems: true,
    ImmediateChildOnly: true,
    HideMonthlyData: false,
    NameOrDesc: '',
    pageNo: 0,
    pageSize: 100,
    sortBy: 'AccountId'
  })

  const { data, refetch, isFetching } = useBudgetList({
    ...params,
    ...{ ParentBudgetAccountId: prntId, BudgetId: BudgetId }
  })
  // console.log(activeNode, 'activeNode', activeRow, 'activeRow', rowData, 'watch this')
  useEffect(() => {
    setTitleNode(Title)
  }, [Title])
  useEffect(() => {
    setSelectedNode(activeNode)
  }, [activeNode])

  useEffect(() => {
    refetch()
    //setTotal(data?.TotalRecords)
  }, [prntId])

  useEffect(() => {
    setPostingTypeId(postingType)
    setprntId(ParentBudgetAccountId)
  }, [ParentBudgetAccountId, postingType])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])
  const handleCloseForm = () => {
    setRowData(null)
    setEdit(false)
    setCreateOpen(false)
    setViewable(false)
  }
  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }

  const onHandleBack = (row: any) => {
    setPostingTypeId(1)
    refetch()
  }

  const handleView = (row: any) => {
    onValueChange(row)
    setPostingTypeId(row.PostingType)
    if (row.PostingType === 1) {
      setprntId(row.BudgetAccountId)
    } else {
      setFinalId(row.BudgetAccountId)
      setTitleFinal(row.AccountDescription)
    }
    handleEXtExpand?.(row)
    refetch()
  }

  const handleCreate = async () => {
    setEdit(false)
    setViewable(false)
    const permission = true
    setCreateOpen(permission)
  }
  const onRefetch = (mode: 'create' | 'delete' = 'create') => {
    handleFetchData?.(
      activeNode,
      activeNode?.expand || false,
      mode,
      rowData?.BudgetAccountId
    )
    refetch()
  }
  const handleDelete = () => {
    setIsActionLoader(true)
    deleteAccount(activeRow.BudgetAccountId)
      .then((res) => {
        onRefetch('delete')
        showSnackBar(res, 'success')
        setActiveRow(null)
      })
      .catch((err) => {
        showSnackBar(err.response.data.error, 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }

  const handleMenuActions = async (slug: string, row: any) => {
    setSubTitle(row?.AccountId)
    if (slug === 'delete') {
      setActiveRow(row)
      setDeleteOpen(true)
    } else if (slug === 'edit') {
      setEdit(true)
      setViewable(false)
      const permission = true
      setCreateOpen(permission)
    }
    handleEdit(row)
  }
  const handleEdit = (row: any) => {
    getBudgetAccountDetails(row.BudgetAccountId).then((res: any) => {
      setRowData(res)
    })
  }
  if (postingTypeId === 1 || postingTypeId === undefined) {
    const formatAmount = (data: any) => {
      return parseFloat(data)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const columns = [
      {
        field: 'AccountId',
        title: 'Code',
        width: 150,
        action: true,
        icon: 'folder',
        sort: true,
        render: (row: any) => (
          <div
            onClick={() => handleView(row)}
            className="kendo-column-text-link cursor-pointer truncate"
          >
            {row?.AccountId}
          </div>
        )
      },
      {
        field: 'AccountDescription',
        title: 'Account Description',
        width: 250,
        sort: true
      },
      { field: 'PostingTypeName', title: 'Type', width: 150, sort: true },
      {
        field: 'Budget',
        title: 'Budget Amount',
        width: 150,
        sort: true,
        render: (rowData: any) => formatAmount(rowData.Budget)
      },
      {
        field: 'Actual',
        title: 'Actual',
        width: 100,
        sort: true,
        render: (rowData: any) => formatAmount(rowData.Actual)
      },
      {
        field: 'Variance',
        title: 'Variance',
        width: 100,
        sort: true,
        render: (rowData: any) => formatAmount(rowData.Variance)
      },
      {
        field: 'VariancePer',
        title: 'Var %',
        width: 100,
        sort: true,
        render: (rowData: any) => formatAmount(rowData.VariancePer)
      },
      {
        field: 'DailyBudget',
        title: 'Daily Budget',
        width: 150,
        sort: true,
        render: (rowData: any) => formatAmount(rowData.DailyBudget)
      },
      {
        field: 'DailyActual',
        title: 'Daily Actual',
        width: 150,
        sort: true,
        render: (rowData: any) => formatAmount(rowData.DailyActual)
      },
      {
        field: 'DailyVariance',
        title: 'Daily Variance',
        width: 150,
        sort: true,
        render: (rowData: any) => formatAmount(rowData.DailyVariance)
      },
      {
        field: 'DailyVarPer',
        title: 'Daily Var%',
        width: 150,
        sort: true,
        render: (rowData: any) => formatAmount(rowData.DailyVarPer)
      }
    ]
    const handleCloseDelete = () => {
      setDeleteOpen(false)
      setActiveRow(null)
    }
    const tableHeaderFirst = (
      <div className=" flex gap-2">
        <CustomIcons name={'budget_item'} type="large" viewBox />
        <span className="text-text-secondary font-normal text-[14px]">
          {`${titleNode ?? '--'} ${activeNode?.budgetAmount ? '- Total Amount :' : ''}  `}
          {activeNode?.budgetAmount && (
            <span className=" text-button-primary">
              {parseFloat(activeNode?.budgetAmount)
                ? parseFloat(activeNode?.budgetAmount)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                : 0.0}
            </span>
          )}
        </span>
      </div>
    )
    const onSubmit = (acntData: any) => {
      const payload = {
        BudAccounts: acntData,
        BudgetId: budgetData.budgetId,
        ParentId: selectedNode?.budgetAccountId || ''
      }

      return edit ? updateAccount(acntData) : addAccount(payload)
    }
    const popupTitle = `${edit ? 'Edit ' : viewable ? 'View' : 'Add '} Account`

    return (
      <div className="grid grid-cols-1 md:grid-cols-1">
        <CustomKendoTable
          columns={columns}
          data={data?.Results}
          isFetching={isFetching}
          pagination={false}
          defaultSort={'AccountId'}
          title={tableHeaderFirst}
          async={false}
          toolBar={true}
          sortable={true}
          resizable={true}
          isDownloadable={false}
          handleSort={handleSort}
          tableHeight={`calc(100vh - ${TableHeight.OneColSearch})`}
          actionDropDown={[
            ...(budgetData?.chartBased
              ? []
              : [{ title: t('edit'), iconName: 'edit', slug: 'edit', id: '1' }]),
            {
              title: t('delete'),
              iconName: 'delete',
              slug: 'delete',
              id: '2',
              isWarning: true
            }
          ]}
          handleMenuActions={handleMenuActions}
          primaryAction={{
            title: t('New'),
            handlePrimaryAction: handleCreate
          }}
        />

        {budgetData?.ChartBased ? (
          <ManageAccountWithChart
            data={rowData}
            open={createOpen}
            onClose={handleCloseForm}
            selectedNode={selectedNode}
            title={popupTitle}
            onSubmit={onSubmit}
            refetch={onRefetch}
            budgetData={budgetData}
          />
        ) : (
          <ManageAccountWithoutChart
            data={rowData}
            open={createOpen}
            onClose={handleCloseForm}
            edit={edit}
            view={viewable}
            onSubmit={onSubmit}
            title={popupTitle}
            selectedNode={selectedNode}
            refetch={onRefetch}
            budgetData={budgetData}
          />
        )}
        <DeletePopUp
          isOpen={deleteOpen}
          onSubmit={handleDelete}
          onClose={handleCloseDelete}
          actionLoader={isActionLoader}
          itemLabels={{ title: 'account summary', subTitle: subTitle }}
        />
      </div>
    )
  } else {
    return (
      <AsummarySub
        FinalId={finalId ?? FinalIdEx}
        TitleFinal={titleFinal ?? TitleFinalEx}
        BudgetId={BudgetId}
        onHandleBack={onHandleBack}
      />
    )
  }
}

import { ActionDropDown } from '@/common/commontypes'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useRef, useState } from 'react'

import {
  dyanmicActionGet,
  dynamicActionDelete,
  dynamicUpdateAction
} from '../../../apis/common.api'
import CustomIcons from '../icons/customIcons'
import SwitchComponent from './SwitchComponent'

type Props = {
  iconName?: string
  isDownload?: boolean
  disabled?: boolean
  rowData?: any
  fromTable?: boolean
  staticMenus?: {
    title: string
    slug: string
    isWarning?: boolean
    iconName: string
    hidden?: boolean
    id: string
    menuIconClass?: string
  }[]
  iconClass?: string
  iconSize?: string
  updateMenuRef?: (ref: HTMLDivElement | null) => void
  position?: string
  handleMenuActions?: (slug: string, rowData?: any) => void
  handleRefresh?: (id?: string) => void
  handleMenuItems: () => any
}

export default function DynamicActionMenu({
  iconName,
  iconClass,
  fromTable = false,
  disabled = false,
  isDownload = false,
  updateMenuRef,
  staticMenus,
  handleMenuActions,
  position,
  rowData,
  handleMenuItems,
  handleRefresh,
  iconSize
}: Props) {
  const [openMenu, setOpenMenu] = useState(false)
  // const [menuOrigin, setMenuOrigin] = useState<'top' | 'bottom'>('top')
  const menuButtonRef = useRef<HTMLButtonElement | null>(null)
  const [confirmData, setConfirmData] = useState<any>({
    isOpen: false,
    primaryApi: '',
    method: ''
  })
  const [actionDropDown, setActionDropDown] = useState<ActionDropDown[]>()
  const menuRef = useRef<HTMLDivElement | null>(null)
  const [dropdownPosition, setDropdownPosition] = useState('bottom-position')
  const dropRef = useRef(null)
  const addUniqueIdToArray = (array?: ActionDropDown[]) => {
    return array?.map((item: ActionDropDown) => ({
      ...item,
      id: `id_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`
    }))
  }
  const adjustDropdownPosition = () => {
    if (menuButtonRef.current && dropRef.current) {
      const inputBoxRect = menuButtonRef.current?.getBoundingClientRect()
      const viewportHeight = window.innerHeight

      const spaceAbove = inputBoxRect.top
      const spaceBelow = viewportHeight - inputBoxRect.bottom
      if (spaceAbove > spaceBelow) {
        setDropdownPosition('top-position')
      } else {
        setDropdownPosition('bottom-position')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', adjustDropdownPosition)
    adjustDropdownPosition()

    return () => {
      window.removeEventListener('resize', adjustDropdownPosition)
    }
  }, [openMenu])
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpenMenu(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const toggleMenu = async () => {
    const data = await handleMenuItems()
    setConfirmData({
      isOpen: false,
      primaryApi: '',
      method: ''
    })
    setActionDropDown(data)
    setOpenMenu(!openMenu)
  }

  //   const handleMenuItemClick = (slug: string) => {
  //     handleMenuActions?.(slug)
  //     setOpenMenu(false)
  //   }
  // useEffect(() => {
  //   if (menuButtonRef.current) {
  //     const buttonRect = menuButtonRef.current.getBoundingClientRect()
  //     const windowHeight = window.innerHeight

  //     if (buttonRect.bottom > windowHeight - buttonRect.top) {
  //       setMenuOrigin('top')
  //     } else {
  //       setMenuOrigin('bottom')
  //     }
  //   }
  // }, [openMenu])

  useEffect(() => {
    if (updateMenuRef) {
      updateMenuRef(menuRef.current)
    }
  }, [menuRef])
  const { showSnackBar } = useCustomSnackBar()
  const modifiedArray = addUniqueIdToArray(actionDropDown)

  const handleConfrimMessage = (
    api: string,
    method: string,
    actionData: ActionDropDown
  ) => {
    setConfirmData({
      isOpen: true,
      primaryApi: api,
      method: method,
      actionData: actionData
    })
    setOpenMenu(false)
  }

  const handleconfrimClose = () => {
    setConfirmData({ isOpen: false })
  }
  const handleStaticMenuItemClick = (slug: string, rowData: any) => {
    handleMenuActions?.(slug)
    setOpenMenu(false)
  }
  const handleStatusAction = (
    api: string,
    method: string,
    actionData: ActionDropDown
  ) => {
    let param = {}

    if (api) {
      const urlParts = api.split('/')
      const modifiedUrl = '/' + urlParts.slice(2).join('/')
      if (method === 'DELETE') {
        dynamicActionDelete(modifiedUrl, actionData.Module)
          .then((res) => {
            showSnackBar(res)
            handleRefresh?.()
          })
          .catch((err) => {
            showSnackBar(err.response.data.error, 'error')
          })
      } else if (method === 'GET') {
        dyanmicActionGet(modifiedUrl, actionData.Module)
          .then((res) => {
            showSnackBar(res)
            handleRefresh?.()
          })
          .catch((err) => {
            showSnackBar(err.response.data.error, 'error')
          })
      } else {
        let data = []
        data = JSON.parse(actionData.Data)
        actionData.FromBody
          ? dynamicUpdateAction(modifiedUrl, data, actionData.Module)
              .then((res) => {
                if (typeof res === 'string') {
                  showSnackBar(res)
                  handleRefresh?.()
                } else {
                  showSnackBar(res)
                  handleRefresh?.(res.Id)
                }
              })
              .catch((err) => {
                showSnackBar(err.response.data.error, 'error')
              })
          : dynamicUpdateAction(modifiedUrl, param, actionData.Module)
              .then((res) => {
                showSnackBar(res)
                handleRefresh?.()
              })
              .catch((err) => {
                showSnackBar(err.response.data.error, 'error')
              })
      }
    }

    handleconfrimClose()
  }
  return (
    <div
      className={
        fromTable ? 'absolute dropdown right-2 my-auto' : 'dropdown relative z-50'
      }
      ref={menuRef}
    >
      <button
        className={`${
          isDownload
            ? ' border border-1 border-[#d7d7d7] h-[34px] w-[34px] rounded-menu flex items-center justify-center text-[#909090] dropdown-toggle-download'
            : `dropdown-toggle ${fromTable ? 'table-row-bg ' : ''}`
        }`}
        disabled={disabled}
        onClick={toggleMenu}
        ref={menuButtonRef}
      >
        <CustomIcons type={iconSize ?? 'large'} name={iconName} className={iconClass} />
      </button>
      {openMenu && (
        <div
          ref={dropRef}
          className={`dropdown-menu  ${dropdownPosition} absolute ${
            position ? position : 'right-0'
          } mt-2 w-max  shadow-lg bg-white focus:outline-none `}
        >
          <div className="py-2 bg-white  w-full shadow-menudropdown">
            {modifiedArray?.map((item) => (
              <React.Fragment key={item.id}>
                {/* {item.Name === 'div' && (
                  <div className="ml-2 mr-2 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50"></div>
                )} */}
                {item?.Name?.toLowerCase()?.startsWith('no action') && (
                  <a
                    key={item.id}
                    href="#/"
                    className={`px-3 py-2.5 leading-7 hover:bg-table-hover hover:text-secondary  `}
                  >
                    <div
                      className={`${
                        item.isWarning || item.Method === 'DELETE'
                          ? 'text-error-light'
                          : ''
                      } text-common flex items-center w-full tracking-[0.24px] font-normal leading-4 `}
                    >
                      <CustomIcons
                        name={item.Symbol}
                        type="large"
                        className={`${
                          item.isWarning || item.Method === 'DELETE'
                            ? 'text-error-light'
                            : ''
                        } text-center `}
                      />

                      <span className="pl-2">{item.Name}</span>
                    </div>
                  </a>
                )}
                {item.Method !== 'NONE' && (
                  <a
                    key={item.id}
                    href="#/"
                    className={`px-3 py-2.5 leading-7 hover:bg-table-hover hover:text-secondary `}
                    onClick={(e) => {
                      e.preventDefault()
                      item?.Name?.startsWith('Print')
                        ? handleStatusAction(item.API as string, item.Method, item)
                        : handleConfrimMessage(item.API as string, item.Method, item)
                    }}
                  >
                    <div
                      className={`${
                        item.isWarning || item.Method === 'DELETE'
                          ? 'text-error-light'
                          : ''
                      } text-common flex items-center w-full tracking-[0.24px] font-normal leading-4`}
                    >
                      <CustomIcons
                        name={item.Symbol}
                        type="large"
                        className={`${
                          item.isWarning || item.Method === 'DELETE'
                            ? 'text-error-light'
                            : ''
                        } text-center `}
                      />

                      <span className="pl-2">{item.Name}</span>
                    </div>
                  </a>
                )}
              </React.Fragment>
            ))}
            {staticMenus?.map((item) => (
              <React.Fragment key={item.id}>
                {!item?.hidden && (
                  <a
                    key={item.id}
                    href="#/"
                    className={`px-3 py-2.5 leading-7 hover:bg-table-hover hover:text-secondary `}
                    onClick={(e) => {
                      e.preventDefault()
                      handleStaticMenuItemClick?.(item.slug, item)
                    }}
                  >
                    <div
                      className={`${
                        item.isWarning ? 'text-error-light' : ''
                      } text-common flex items-center w-full tracking-[0.24px] font-normal leading-4`}
                    >
                      <CustomIcons
                        name={item.iconName}
                        type="large"
                        className={`${
                          item.isWarning ? 'text-error-light' : ''
                        } text-center ${item.menuIconClass}`}
                      />
                      <span className="pl-2">{item.title}</span>
                    </div>
                  </a>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}

      {confirmData.isOpen && (
        <SwitchComponent
          handleRefresh={handleRefresh}
          confirmData={confirmData}
          rowData={rowData}
          handleStatusAction={handleStatusAction}
          handleconfrimClose={handleconfrimClose}
        />
      )}
    </div>
  )
}

import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import { formContainerClass } from '@/styles/variables'
import React from 'react'
import { useTranslation } from 'react-i18next'

type CreateAntiFoulingProps = {
  viewable: boolean
}

export const CreateAntiFouling: React.FC<CreateAntiFoulingProps> = ({ viewable }) => {
  const { t } = useTranslation()

  const formFields: FormBuilderProps[] = [
    {
      label: t('Dry Dock Location'),
      placeholder: t('typeToSearch'),
      name: 'yardName',
      id: 'yardId',
      type: 'autocomplete',
      required: true,
      apiParams: { key: 'COMPANY', filter1: 'YARD' },
      spacing: 12
    },
    {
      label: t('Start Date'),
      placeholder: t('Start Date'),
      name: 'startDate',
      id: 'startDate',
      type: 'date',
      required: true,
      spacing: 6
    },
    {
      label: t('End Date'),
      placeholder: t('End Date'),
      name: 'endDate',
      id: 'endDate',
      type: 'date',
      required: true,
      spacing: 6
    },
    {
      label: t('Remarks'),
      placeholder: t('Remarks'),
      name: 'remarks',
      id: 'remarks',
      type: 'textarea',
      spacing: 12
    }
  ]
  return (
    <div className={formContainerClass}>
      <FormBuilder data={formFields} edit={!viewable} isView={viewable} />
    </div>
  )
}

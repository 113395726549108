import React from 'react'
import DashChartSwitch from '../../chart'
type Props = {
  data: any
  index: number
  handleClose: (id: string) => void
}
const DashboardTiles: React.FC<Props> = ({ data, index }) => {
  // const tileDropDown = [
  //   {
  //     title: 'Toggle',
  //     slug: 'toggle',

  //     iconName: 'dash-toggle',
  //     id: 'toggle'
  //   },
  //   {
  //     title: 'Reload',
  //     slug: 'reload',
  //     iconName: 'dash-reload',
  //     id: 'reload'
  //   },
  //   {
  //     title: 'Expand',
  //     slug: 'expand',

  //     iconName: 'dash-expand',
  //     id: 'expand'
  //   },
  //   {
  //     title: 'Close',
  //     slug: 'close',
  //     iconName: 'dash-close',
  //     id: 'close'
  //   }
  // ]
  // const handleMenuActions = (slug: string) => {
  //   switch (slug) {
  //     case 'close':
  //       handleClose(data.DashTileId)
  //       break
  //   }
  // }

  return (
    <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col h-[280px] rounded-dialog bg-white py-4 px-5">
      <div className="flex items-center justify-between w-full py-2">
        <div className="font-semibold text-shadow-light font-common">{data.TileName}</div>
        <div>
          {/* <MenuDropDown
            iconName="three_dot"
            iconClass="rotate-90"
            actionDropDown={tileDropDown}
            handleMenuActions={handleMenuActions}
          /> */}
        </div>
      </div>
      <div className="py-3.5  ">
        {/* <div className="py-10">
          <img src={'/assets/empty_tile.jpg'} alt="" className="h-[200px]" />
        </div> */}
        <DashChartSwitch index={index} />
      </div>
    </div>
  )
}

export default DashboardTiles

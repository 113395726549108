import React from 'react'

type CustomViewProps = {
  data: any
  getLogDetails: (data: any) => void // Add the getLogDetails prop
}

const getInitials = (name: any) => {
  if (!name) return ''
  const cleanedName = name.trim()
  if (cleanedName.includes(' - ')) {
    const parts = cleanedName.split(' - ')
    return parts.map((part: any) => part[0].toUpperCase()).join('')
  }
  const parts = cleanedName.split(' ')
  if (parts.length === 2) {
    return parts.map((part: any) => part[0].toUpperCase()).join('')
  }
  return cleanedName.substring(0, 2).toUpperCase()
}

const CustomView: React.FC<CustomViewProps> = ({ data, getLogDetails }) => {
  const date = new Date(data.logDate)
  const formattedDate = date
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    })
    .replace(/ /g, '-')
  const splitTime = date.toLocaleTimeString('en-US', {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
  const ampm = date.toLocaleTimeString('en-US', { hour12: true }).split(' ')[1]
  const trimAMPM = splitTime.replace(/\s[ap]m$/i, '')
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0')
  const formattedTime = `${trimAMPM}.${milliseconds} ${ampm}`

  return (
    <div className="flex flex-row mt-[5px] mb-[5px] items-center justify-between pl-4">
      <div
        style={{
          display: 'inline-block',
          width: '36px',
          height: '36px',
          borderRadius: '50%',
          backgroundColor: data.color,
          color: 'white',
          textAlign: 'center',
          lineHeight: '36px',
          fontSize: '14px'
        }}
      >
        {getInitials(data?.userId)}
      </div>

      <div
        className="cursor-pointer truncate flex-1"
        style={{ marginLeft: '10px', lineHeight: '36px', fontSize: '14px' }}
        onClick={() => getLogDetails(data)}
      >
        <span className="text-gray-900">{data?.userId}</span>{' '}
        <span className="text-slate-600">{data?.logDesc}</span>
      </div>

      <div className="w-[130px] text-right pr-2">
        <div>{formattedDate}</div>
        <div>{formattedTime}</div>
      </div>
    </div>
  )
}

export default CustomView

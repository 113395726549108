const CheckCircleIcon = () => {
  return (
    <path
      d="M11.6666 3.49902L5.24998 9.91569L2.33331 6.99902"
      stroke="white"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}
export default CheckCircleIcon

import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import { formContainerClass } from '@/styles/variables'
import React from 'react'
import { useTranslation } from 'react-i18next'

type RolesFormProps = {
  edit?: boolean
  editMode?: boolean
}
export const ServiceForm: React.FC<RolesFormProps> = ({ edit, editMode }) => {
  const { t } = useTranslation()
  const formFields: FormBuilderProps[] = [
    {
      label: t('Service'),
      placeholder: t('Select Service'),
      name: 'ServiceTypeDesc',
      id: 'ServiceTypeId',
      required: true,
      type: 'autocomplete',
      apiParams: { key: 'SERVICETYPE' },
      disabled: editMode,
      spacing: 12
    }
  ]

  return (
    <div className={formContainerClass}>
      <FormBuilder data={formFields} edit={edit} spacing={6} />
    </div>
  )
}

import { FavouriteMenuProps, FavouriteSideNavProps } from '@/common/customComponenttypes'
import CustomIcons from '@/components/common/icons/customIcons'
import { useHeaderStore } from '@/store/appStore'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const FavouriteSideNav: React.FC<FavouriteSideNavProps> = ({
  favMenuData,
  onSetFavorites,
  handleFavChildView,
  setIsHovered
}) => {
  const navigate = useNavigate()
  const { mode } = useScreenBreakPoint()
  const handleSetFav = (menu: FavouriteMenuProps) => {
    menu.Favourite = true
    onSetFavorites(menu)
  }
  const { setHeaderTitle, setModuleId } = useHeaderStore()
  const renderSubmenu = (id: string) => {
    return (
      <div className="ml-5 mt-1 mb-1 relative ">
        {favMenuData.map((menus: FavouriteMenuProps) => (
          <>
            {menus.ParentId === id && (
              <>
                {menus.FunctionType !== 2 ? (
                  <div
                    key={menus.FunctionId}
                    className={`group/item flex p-2 gap-4 relative truncate items-center cursor-pointer  justify-between
                        bg-transparent rounded-sm text-sidebarText hover:bg-background-hover  hover:text-white '
               
                    
                    `}
                  >
                    <div className="flex items-center">
                      <div
                        className="flex gap-4 items-center"
                        title={menus.FunctionName}
                        onClick={(e) => {
                          e.stopPropagation()
                          navigate(`/${menus.PathName}` ?? '/')
                          setHeaderTitle(menus.FunctionName)
                          setModuleId(menus.FunctionId)
                          setIsHovered?.(false)
                        }}
                      >
                        <CustomIcons
                          name={
                            menus.FunctionType === 2 ? 'menu-section' : 'side-menu-list'
                          }
                          className=""
                        />
                        <p className="sidenav-item-label text-sm  ">
                          <p className=" truncate group-hover/item:w-[100px]">
                            {menus.FunctionName}
                          </p>
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <CustomIcons
                        name={!menus.Favourite ? 'favouriteFilled' : 'favourite'}
                        className={`${mode === 'desktop' ? 'invisible group-hover/item:visible' : 'visible'}  mt-1 text-favIconColor`}
                        type="medium"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleSetFav(menus)
                        }}
                      />
                      <CustomIcons
                        name={'external-link'}
                        className="invisible mt-1 pl-2.5 group-hover/item:visible"
                        onClick={(e) => {
                          e.stopPropagation()
                          window.open(menus.PathName as string, '_blank')
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-4 items-center  text-sidebarText">
                    <CustomIcons
                      name={menus.FunctionType === 2 ? 'menu-section' : 'side-menu-list'}
                      className=""
                    />
                    <p className="sidenav-item-label text-sm ">{menus.FunctionName}</p>
                  </div>
                )}
                {menus.FunctionType === 2 && <>{renderSubmenu(menus.FunctionId)}</>}
              </>
            )}
          </>
        ))}
      </div>
    )
  }

  return (
    <>
      <div
        className={
          mode === 'desktop'
            ? 'sidnav-sub-menu absolute py-2 px-[11px] left-[183px] top-0 bg-background-sidebarSecondary h-full z-40 overflow-y-auto transition-transform '
            : ' h-full  z-40 bg-background-sidebarSecondary p-4 overflow-y-auto transition-transform '
        }
      >
        <div className="relative h-full">
          {favMenuData?.length === 0 || !favMenuData ? (
            <div className=" w-full h-full flex justify-center items-center font-normal text-common text-white/75 ">
              No Favourites Found
            </div>
          ) : (
            <>
              {' '}
              {favMenuData.map((menus: FavouriteMenuProps) => (
                <>
                  {menus.ParentId === null && (
                    <div className="mb-[2px]">
                      <div
                        onClick={() => handleFavChildView(menus.FunctionId)}
                        key={`fav-${menus.FunctionId}`}
                        className={`${
                          menus.childView ? ' text-white' : 'bg-transparent rounded-sm '
                        } flex p-2 gap-4 relative truncate items-center cursor-pointer justify-between rounded-sm hover:bg-background-hover text-sidebarText hover:text-white`}
                      >
                        <div className="flex gap-4 items-center justify-center">
                          <CustomIcons name={'menu-list'} className="" />
                          <p
                            className={`sidenav-item-label  ${menus.childView ? 'text-white ' : ''}`}
                          >
                            {menus.FunctionName}
                          </p>
                        </div>
                        {menus.ParentId === null && (
                          <CustomIcons
                            name={!menus.childView ? 'menu-down-arrow' : 'menu-up-arrow'}
                          />
                        )}
                      </div>
                      {menus.ParentId === null && menus.childView && (
                        <>{renderSubmenu(menus.FunctionId)}</>
                      )}
                    </div>
                  )}
                </>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  )
}
export default FavouriteSideNav

import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'

export const CrewingCrewSchema = z.object({
  givenName: z
    .string({ required_error: 'First Name is required' })
    .min(1, 'First name is required')
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  middleName: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional()
    .nullable(),
  surName: z
    .string({ required_error: 'Surname is required' })
    .min(1, 'Surname is required')
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  dob: z
    .date({ invalid_type_error: 'Date of Birth is required' })
    .refine((date) => Boolean(date), {
      message: 'Date of Birth is required'
    }),
  // as per discussion with anukumar removing this section
  // .refine((value) => {
  //   const ageInMilliseconds = Date.now() - new Date(value).getTime()
  //   const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365)
  //   return ageInYears >= 18
  // }, 'Only allow above 18 years old'),
  nationality: z
    .string({ required_error: 'Nationality is required' })
    .min(1, 'Nationality is required'),
  nationalityId: z
    .number({ required_error: 'Nationality is required' })
    .min(1, 'Nationality is required'),
  crewRank: z
    .string({ required_error: 'Crew Rank is required' })
    .min(1, 'Crew Rank is required'),
  crewRankId: z
    .number({ required_error: 'Crew Rank is required' })
    .min(1, 'Crew Rank is required'),
  emailId: z
    .string({ required_error: 'Email is required' })
    .min(1, { message: 'Email is required' })
    .email('Invalid email address')
    .max(100, 'Email must be 100 characters or less')
})

export type CrewingCrewDataType = z.infer<typeof CrewingCrewSchema>

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const SubModuleIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M16 8.5C16 12.6422 12.6422 16 8.5 16C4.35787 16 1 12.6422 1 8.5C1 4.35787 4.35787 1 8.5 1C12.6422 1 16 4.35787 16 8.5Z"
        stroke="#003C71"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6.83333C6 6.3731 6.3731 6 6.83333 6H10.1667C10.6269 6 11 6.3731 11 6.83333V10.1667C11 10.6269 10.6269 11 10.1667 11H6.83333C6.3731 11 6 10.6269 6 10.1667V6.83333Z"
        stroke="#003C71"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SubModuleIcon

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const ViewLargeIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="21.3333" fill="white" />
      <path
        d="M15.3536 11.3333C15.3536 13.0691 13.8521 14.4761 12.0001 14.4761C10.1479 14.4761 8.64648 13.0691 8.64648 11.3333C8.64648 9.59749 10.1479 8.19043 12.0001 8.19043C13.8521 8.19043 15.3536 9.59749 15.3536 11.3333Z"
        stroke="#3D414A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33337 11.3333C2.75781 7.08302 6.99475 4 12 4C17.0053 4 21.2422 7.08305 22.6667 11.3333C21.2422 15.5836 17.0053 18.6667 12 18.6667C6.99474 18.6667 2.75778 15.5836 1.33337 11.3333Z"
        stroke="#3D414A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ViewLargeIcon

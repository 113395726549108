import { CardListProps } from '@/common/customComponenttypes'
import { useEffect, useRef, useState } from 'react'
import NoRecordsFound from './NoRecords'
import Toolbar from './Toolbar'
import useScrollHandler from './utils/useScrollHandler'
import VarientSwitcher from './varientSwitcher'

export default function CardsList({
  columns,
  columnsHeader,
  avatar,
  data = [],
  toolBar,
  onCardClick,
  async,
  primaryAction,
  handleMenuActions,
  actionDropDown,
  actionDropDownParams,
  expansionComponent,
  handleToolActions,
  actionToolDropDown,
  workflowParams,
  cardKey,
  rowKey,
  isFetching,
  staticMenus,
  variant = 'normal',
  themeKey,
  pagination,
  paginationProps = {
    total: 0,
    rowsPerPage: 20,
    currentPage: 1,
    onPagination: (row: number, page: number) => {},
    onRowsPerPage: (row: number) => {}
  },
  cardHeight,
  defaultSort,
  defaultBorder,
  handleSort,
  sortDropDown,
  updatedDate,
  titleFooter,
  toolbarMiddleRender,
  toolbarBottomRender,
  exportPaperSize,
  searchCount = 2,
  ...rest
}: CardListProps) {
  const cardRef = useRef<HTMLDivElement>(null)
  const [hasMore, setHasMore] = useState<boolean>(true)

  useEffect(() => {
    const isMore = paginationProps.total > data?.length
    setHasMore(isMore)
  }, [data])

  useEffect(() => {
    if (paginationProps.currentPage === 1) {
      if (cardRef.current && variant !== 'expand') {
        cardRef.current.scrollTop = 0
      }
    }
  }, [paginationProps])

  const fetchData = async () => {
    if (!isFetching) {
      paginationProps?.onPagination &&
        paginationProps?.onPagination(
          paginationProps?.rowsPerPage,
          paginationProps?.currentPage + 1
        )
    }
  }

  useScrollHandler(cardRef, fetchData, hasMore, isFetching ?? false)
  // const mainData = async ? data : currentTableData
  const generateVarientClasses = () => {
    switch (variant) {
      case 'colored':
        return 'p-5 overflow-auto'
      default:
        return 'cards-list p-5   overflow-auto'
    }
  }
  const mainData = data
  return (
    <div className="relative cards-outer border-t border-grey-borderLight">
      {toolBar && (
        <Toolbar
          columns={columns}
          columnsHeader={columnsHeader}
          primaryAction={primaryAction}
          paginationProps={paginationProps}
          handleToolActions={handleToolActions}
          actionToolDropDown={actionToolDropDown}
          data={mainData}
          isFetching={isFetching}
          defaultSort={defaultSort}
          handleSort={handleSort}
          sortDropDown={sortDropDown}
          toolbarMiddleRender={toolbarMiddleRender}
          toolbarBottomRender={toolbarBottomRender}
          exportPaperSize={exportPaperSize}
          {...rest}
        />
      )}
      <div
        ref={cardRef}
        className={generateVarientClasses()}
        style={{ maxHeight: cardHeight, minHeight: cardHeight, overflowY: 'auto' }}
      >
        <div className="grid grid-cols-12 gap-5">
          {mainData &&
            mainData.length > 0 &&
            mainData.map((rowData, index) => (
              <VarientSwitcher
                key={index}
                index={index}
                variant={variant}
                columns={columns}
                columnsHeader={columnsHeader}
                avatar={avatar}
                themeKey={themeKey}
                cardRef={cardRef}
                rowData={rowData}
                handleMenuActions={handleMenuActions}
                actionDropDown={actionDropDown}
                actionDropDownParams={actionDropDownParams}
                cardKey={cardKey}
                workflowParams={workflowParams}
                rowKey={rowKey}
                staticMenus={staticMenus}
                //colored
                expansionComponent={expansionComponent}
                onCardClick={onCardClick}
                updatedDate={updatedDate}
                titleFooter={titleFooter}
                defaultBorder={defaultBorder}
                {...rest}
              />
            ))}
        </div>
        {!isFetching &&
          (!mainData ||
            (mainData?.length === 0 && <NoRecordsFound searchCount={searchCount} />))}
      </div>
    </div>
  )
}

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'
const ExternalLink: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M5.875 2.625H2.625C1.72754 2.625 1 3.35254 1 4.25V12.375C1 13.2725 1.72754 14 2.625 14H10.75C11.6475 14 12.375 13.2725 12.375 12.375V9.125M9.125 1H14M14 1V5.875M14 1L5.875 9.125"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ExternalLink

import { validateNumberOfDigits } from '@/utilities'
import { z } from 'zod'
export const seaTrialItemFormSchema = z.object({
  loadConditionId: z.union([z.number(), z.string()], {
    required_error: 'Load is required',
    invalid_type_error: 'Load is Required'
  }),
 
  tier: z.union([z.number(), z.string()], {
    required_error: 'Tier is required',
    invalid_type_error: 'Tier is Required'
  }),

  vesTrialItemId: z.string().optional(),
  power: z
    .number({
      required_error: 'Power is required',
      invalid_type_error: 'required'
    })
    .min(0, { message: 'Power must be a positive number' })
    .refine(validateNumberOfDigits, { message: 'Max length for Power is 8 ' }),
  //.nullable(),
  rpm: z
    .number()
    .min(0, { message: 'RPM must be a positive number' })
    .refine(validateNumberOfDigits, { message: 'Max length for RPM is 8 ' })
    .optional()
    .nullable(),
  speed: z
    .number()
    .min(0, { message: 'Speed must be a positive number' })
    .refine(validateNumberOfDigits, { message: 'Max length for Speed is 8 ' })
    .optional()
    .nullable(),
  sfoc: z
    .number()
    .min(0, { message: 'Speed must be a positive number' })
    .refine(validateNumberOfDigits, { message: 'Max length for SFOC is 8 ' })
    .optional()
    .nullable(),
  fuelConsHourly: z
    .string()
    .refine((value) => value === '' || !isNaN(Number(value)), {
      message: 'Must be a valid number'
    })
    .transform((value) => (value === '' ? undefined : Number(value)))
    .optional()
    .nullable(),
  fuelConsDaily: z
    .string()
    .refine((value) => value === '' || !isNaN(Number(value)), {
      message: 'Must be a valid number'
    })
    .transform((value) => (value === '' ? undefined : Number(value)))
    .optional()
    .nullable(),
  draftFWD: z
    .string()
    .refine((value) => value === '' || !isNaN(Number(value)), {
      message: 'Must be a valid number'
    })
    .transform((value) => (value === '' ? undefined : Number(value)))
    .optional()
    .nullable(),
  draftAFT: z
    .string()
    .refine((value) => value === '' || !isNaN(Number(value)), {
      message: 'Must be a valid number'
    })
    .transform((value) => (value === '' ? undefined : Number(value)))
    .optional()
    .nullable(),
  deadWeight: z
    .string()
    .refine((value) => value === '' || !isNaN(Number(value)), {
      message: 'Must be a valid number'
    })
    .transform((value) => (value === '' ? undefined : Number(value)))
    .optional()
    .nullable(),
  remarks: z
    .string()
    .max(500, { message: 'Remarks must be 500 characters or less' })
    .optional()
    .nullable(),
  vesselId: z.string().optional()
})

export type SeaTrialItemFormSchema = z.infer<typeof seaTrialItemFormSchema>

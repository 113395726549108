import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const PlusLargeIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16657 9.16657V4.16656H10.8332V9.16657H15.8332V10.8332H10.8332V15.8332H9.16657V10.8332H4.16656V9.16657H9.16657Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PlusLargeIcon

import React from 'react'
import CustomIcons from '../icons/customIcons'
import CustomTooltip from '../tooptip/CustomTooltip'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
type Props = {
  activeStep: number
  data: {
    step: number
    title: string
    icon: string
    description?: string
  }[]
}
export default function StepperComponent({ activeStep, data }: Props) {
  const { isTab, isMobile } = useScreenBreakPoint()
  const calculateWidth = () => {
    let itemCount = data.length
    switch (itemCount) {
      case 3:
        return 200
      case 4:
        if (isMobile || isTab) {
          return 100
        }
        return 140
      case 5:
        return 80
      default:
        return 140
    }
  }
  return (
    <div className="pb-[30px] flex flex-col justify-center">
      <div className=" flex  items-center justify-center ">
        {data?.map((item, index) => (
          <React.Fragment key={item.step}>
            <div className="flex flex-col justify-center">
              <div className="flex  items-center">
                <div
                  className={`${
                    activeStep === item.step
                      ? 'border-primary border-[2px] text-text-primary'
                      : activeStep > item.step
                        ? ' bg-secondary text-white '
                        : 'bg-[#EAEFF4] text-[#85888E]'
                  } flex justify-center items-center   border-gray-500 rounded-full w-[56px] h-[56px]`}
                >
                  <CustomIcons name={item.icon} />
                </div>
                {index < data.length - 1 && (
                  <div
                    style={{ width: calculateWidth() }}
                    className=" flex-auto border w-[200px] border-[#EAECF0] mx-[5px] "
                  ></div> // Vertical separator for a horizontal layout
                )}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div
        className="flex   items-center justify-center"
        style={{ gap: calculateWidth() }}
      >
        {data?.map((item, index) => (
          <span
            key={index}
            className={`mt-2 text-common flex w-[65px] whitespace-nowrap justify-center items-center gap-[4px] text-text-secondary`}
          >
            <CustomTooltip title={item.description as string}>
              <CustomIcons name={'info'} type="common" />
            </CustomTooltip>
            {item.title}
          </span>
        ))}
      </div>
    </div>
  )
}

import { HeaderWrapper, SectionWraper } from '@/components/app/common'
import {
  MainContentProps,
  SubContentProps
} from '@/components/app/common/DetailHeaderComponent'
import { Breadcrumb, CustomDetailHeaderComponent } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'
import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { routerStore } from '@/store'
import { detailHeaderBackground } from '@/styles/variables'
import { blobToFile } from '@/utilities'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { addCrewPhoto, fetchCrewHeaderById } from '../api'
import Apprisals from './apprisals'
import AuditLog from './auditLog'
import BankDetails from './bankDetails'
import CorrespondenceOrNotes from './correspondenceOrNotes'
import CrewBasicInfoDetails from './crewDetails/crewBasicInfoDetails'
import { useCrewProfileStore } from './crewDetails/store'
import EmployementAndPayroll from './employmentAndPayroll'
import Overview from './overview'
import SeaServiceRecord from './seaServiceRecord'
import TrainingAndCertifications from './trainingAndCertifications'
// import { blobToFile } from '@/utilities'
type NavigationSidebarDataProps = {
  id: string | number
  label: string
  icon?: string
  link?: string
  disabled?: boolean
}
type Props = {
  crewId: string
  pageRouting: boolean
}
const CrewDetailsComponent: React.FC<Props> = ({ crewId, pageRouting = false }) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [section, setSection] = useState<string | null>(null)
  const [expandInPopUp, setExpandInPopUp] = useState(false)
  const { expand, setExpand, resetEdit, isEditMode } = useCrewProfileStore()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [crewData, setCrewData] = useState<any>({})

  const [activeTab, setActiveTab] = useState<number | string | undefined>('2')

  const [preview, setPreview] = useState<any>({})
  const { showSnackBar } = useCustomSnackBar()

  const detailHeaders: MainContentProps[] = [
    {
      data: `${crewData?.fullName ?? ''}`,
      type: 'title'
    },
    {
      data: `${crewData?.crewRankName ?? ''}  `,
      type: 'mode1'
    },
    { data: `${crewData?.crewStatusName ?? ''}`, type: 'desc' },
    {
      data: 'Alpha Ship',
      type: 'status'
    }
  ]

  const detailContents: SubContentProps[] = [
    {
      label: t('Employee ID'),
      spacing: 3,
      value: crewData?.employeeId ?? '----',

      hasDivider: true
    },
    {
      label: t('Nationlity'),
      value: crewData?.countryName ?? '----',
      spacing: 3,
      hasDivider: true
    },
    {
      label: t('Sign on Date'),
      value: '--',
      spacing: 3,
      hasDivider: true
    },

    {
      label: t('Sign off Date'),
      value: '--',
      spacing: 3
    }
  ]
  const tabData: NavigationSidebarDataProps[] = [
    {
      id: '1',
      label: 'Overview',
      icon: 'overview',
      link: routerStore.CREW_DETAILS_OVERVIEW
    },
    {
      id: '2',
      label: 'Crew Details',
      icon: 'person',
      link: routerStore.CREW_DETAILS_BASIC_INFO
    },

    {
      id: '3',
      label: 'Documents and Certificates',
      icon: 'certificate',
      link: routerStore.CREW_DETAILS_TRAINING_AND_CERTIFICATIONS
    },
    {
      id: '4',
      label: 'Sea Service Record',
      icon: 'sea_record',
      link: routerStore.CREW_DETAILS_SEA_SERVICE_RECORD
    },
    {
      id: '5',
      label: 'Correspondence / Notes',
      icon: 'note',
      link: routerStore.CREW_DETAILS_CORRESPONDENCE_OR_NOTES
    },
    {
      id: '6',
      label: 'Appraisals',
      icon: 'award',
      link: routerStore.CREW_DETAILS_APPRAISALS
    },
    {
      id: '7',
      label: 'Bank Details',
      icon: 'bank',
      link: routerStore.CREW_DETAILS_BANK_DETAILS
    },
    {
      id: '8',
      label: 'Employment and Payroll Details',
      icon: 'payroll',
      link: routerStore.CREW_DETAILS_EMPLOYMENT_AND_PAYROLL
    },
    {
      id: '9',
      label: 'Audit Log',
      icon: 'audit',
      link: routerStore.CREW_DETAILS_AUDIT_LOG
    }
  ]
  const getDetails = () => {
    if (crewId) {
      setLoading(true)
      fetchCrewHeaderById(crewId)
        .then((res) => {
          setCrewData(res?.data || {})
        })
        .catch((err) => {
          showSnackBar(err, 'error')
          navigate(`${routerStore.CREW_SEARCH}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    getDetails()
  }, [])
  useEffect(() => {
    if (pageRouting) {
      if (location.pathname) {
        setActiveTab(
          tabData.filter((item: NavigationSidebarDataProps) =>
            location.pathname?.includes(item.link as string)
          )[0]?.id
        )
      }
    }
  }, [location.pathname])
  const manageRoute = (link: string) => {
    navigate(`${routerStore.CREW_SEARCH}/${crewId}/${link}`)
  }
  const handleConfirm = () => {
    setIsOpen(false)
    resetEdit()
    manageRoute(section as string)
  }
  const handleNavigation = (tab: NavigationSidebarDataProps) => {
    setSection(tab.link as string)
    setActiveTab(tab.id)
    if (pageRouting) {
      if (!isEditMode()) {
        manageRoute(tab.link as string)
      } else {
        setIsOpen(true)
      }
    }
  }
  const handleExpansion = () => {
    setExpand(!expand)
    setExpandInPopUp((p) => !p)
  }
  const outLetContext = { crewId: crewId, expand: pageRouting ? expand : expandInPopUp }

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files?.length) {
      const file = files[0]
      if (file) {
        let img = new Image()
        img.src = window.URL.createObjectURL(file)

        img.onload = () => {
          if (file?.size > 307200) {
            showSnackBar('Maximum file size is 300 kB', 'error')
          } else {
            // methods.reset({ ...methods.watch(), File: file })

            const api = addCrewPhoto

            const payload = new FormData()
            payload.append('CrewId', crewData?.crewId)
            payload.append('FileData', file)

            api(payload)
              .then((res) => {
                // onSuccessAction()
                setPreview(res)

                showSnackBar(res?.message)
              })
              .catch((err) => {
                showSnackBar(err, 'error')
              })
              .finally(() => {
                // setIsSubmitting(false)
              })
          }
        }
      }
    }
  }

  const generateAvatar = () => {
    if (preview?.fileData && preview?.fileName) {
      return blobToFile(preview?.fileData, preview?.fileName) as string
    } else if (crewData?.crewPhoto && crewData?.fileName) {
      return blobToFile(crewData?.crewPhoto, crewData?.fileName) as string
    }
    return '/assets/User.png'
  }
  const renderTabs = () => {
    switch (activeTab) {
      case '1':
        return <Overview />
      case '2':
        return <CrewBasicInfoDetails crewId={crewId} pageRouting={false} />
      case '3':
        return <TrainingAndCertifications />
      case '4':
        return <SeaServiceRecord />
      case '5':
        return <CorrespondenceOrNotes />
      case '6':
        return <Apprisals />
      case '7':
        return <BankDetails />
      case '8':
        return <EmployementAndPayroll />
      case '9':
        return <AuditLog />
      default:
        return <></>
    }
  }
  const breadcrumbConfig = [
    {
      label: t('Crewing')
    },
    {
      label: t('Crew Search'),
      link: routerStore.CREW_SEARCH
    },
    {
      label: `${crewData?.fullName ?? ''}`
    }
  ]
  return (
    <>
      {pageRouting && <Breadcrumb items={breadcrumbConfig} />}
      <div>
        <div
          className={
            pageRouting
              ? detailHeaderBackground
              : 'p-[15px] bg-white border-[1px] border-grey-borderLight rounded-[6px]'
          }
        >
          <HeaderWrapper search={false}>
            <CustomDetailHeaderComponent
              isLoading={loading}
              detailHeaders={detailHeaders}
              detailContents={detailContents}
              avatar={generateAvatar()}
              onchange={pageRouting ? handleChangeFile : undefined}
            />
          </HeaderWrapper>
        </div>
      </div>
      <SectionWraper className={pageRouting ? '' : 'px-0'}>
        <div className="flex gap-[15px]">
          <div>
            {/* /* sidebar component */}
            <div
              className={`gap-3.5 flex flex-col bg-white rounded ${pageRouting ? '' : 'border-[1px] border-grey-borderLight '} ${(pageRouting ? expand : expandInPopUp) ? 'p-5' : 'p-3.5'}`}
            >
              {tabData.map((tab: NavigationSidebarDataProps) => (
                <button
                  className={`flex items-center  ${(pageRouting ? expand : expandInPopUp) ? '' : 'justify-center'} ${tab.id === activeTab ? 'text-text-primary font-medium hover:.bg-secondary-6' : 'text-text-secondary hover:bg-secondary-6 font-normal'}`}
                  key={tab.id}
                  disabled={tab.disabled}
                  onClick={() => {
                    handleNavigation(tab)
                  }}
                >
                  {tab.icon && (
                    <div
                      className={`p-1.5 ${(pageRouting ? !expand : !expandInPopUp) && tab.id === activeTab ? 'bg-secondary-6 rounded-sm' : ''}`}
                    >
                      <CustomIcons name={tab.icon} type="large" />
                    </div>
                  )}
                  <div
                    className={` ${
                      (pageRouting ? expand : expandInPopUp)
                        ? 'flex items-center text-left justify-center text-common pl-2 pr-3 leading-[18px]'
                        : 'hidden'
                    }`}
                  >
                    {tab.label}
                  </div>
                  <div
                    className={
                      (pageRouting ? expand : expandInPopUp)
                        ? 'w-5 flex items-center justify-center'
                        : 'hidden'
                    }
                  >
                    {tab.id === activeTab && (
                      <CustomIcons name={'rightArrow'} type="large" />
                    )}
                  </div>
                </button>
              ))}

              <div
                className={`w-full ${(pageRouting ? expand : expandInPopUp) ? 'h-10' : 'h-[52px]'}`}
              />
              <div className="flex flex-row-reverse">
                <button
                  className="w-[30px] h-[30px] rounded-[4px] bg-secondary-6 flex items-center justify-center text-text-primary"
                  onClick={() => handleExpansion()}
                >
                  <CustomIcons
                    name={
                      (pageRouting ? expand : expandInPopUp)
                        ? 'leftDouble'
                        : 'rightDouble'
                    }
                    type="large"
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            className={`${activeTab !== '2' ? 'p-5' : ''} ${pageRouting ? 'rounded' : 'rounded-t-sm border-[1px] border-grey-borderLight '} bg-white  flex-grow`}
          >
            {pageRouting ? <Outlet context={outLetContext} /> : <>{renderTabs()}</>}
          </div>
        </div>
      </SectionWraper>
      <ConfirmModal
        isOpen={isOpen}
        modalId="errorPopup"
        title={'Confirmation'}
        type="warning"
        secondaryActionLabel="No"
        secondaryAction={() => setIsOpen(false)}
        actionLabel="Yes"
        onSubmit={() => handleConfirm()}
        topIcon="warning"
        scrollDisable={true}
        onClose={() => setIsOpen(false)}
        subTitle={'Do you want to discard all the changes made?'}
      />
    </>
  )
}

export default CrewDetailsComponent

import { z } from 'zod'

export const formSchema = z.object({
  CompServiceId: z.string(),
  ServiceTypeDesc: z
    .string({ required_error: 'Service is required' })
    .min(1, 'Service is required'),
  ServiceTypeId: z.number(),
  CompanyId: z.string().optional()
})

export type FormSchema = z.infer<typeof formSchema>

import { Button, TextField } from '@/components/common'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { useState } from 'react'
import { z } from 'zod'

const TextFieldComponent = () => {
  const [edited, setEdited] = useState(false)
  const TextFieldSchema = z.object({
    textfield: z.string(),
    textfieldrequired: z.string().min(1, { message: 'Enter textfieldrequired' }),
    textfieldDisabled: z.string().min(1, { message: 'Enter textfieldDisabled' }),
    textfieldEditable: z.string().min(1, { message: 'Enter textfieldDisabled' })
  })
  type FormInput = z.infer<typeof TextFieldSchema>

  const {
    register,
    formState: { errors },
    setFocus,
    handleSubmit
  } = useForm<FormInput>({
    resolver: zodResolver(TextFieldSchema),
    mode: 'onChange',
    defaultValues: {
      textfield: '',
      textfieldrequired: '',
      textfieldDisabled: 'Rext field disabled'
    }
  })
  const onSubmit = (d: any) => {
    console.log(d)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="py-3">
          <div className="flex p-2">
            <TextField
              id="textfield"
              name="textfield"
              setFocus={setFocus}
              label="Text Field"
              placeholder="Sample text field"
              disabled={false}
              register={register}
              errors={errors}
            />
          </div>
        </div>
        <div className="py-3">
          <div className="flex p-2">
            <TextField
              id="textfieldrequired"
              name="textfieldrequired"
              label="Text field"
              setFocus={setFocus}
              placeholder="Sample text field"
              disabled={false}
              register={register}
              errors={errors}
              required
            />
          </div>
        </div>
        <div className="py-3">
          <div className="flex p-2">
            <TextField
              id="textfieldDisabled"
              name="textfieldDisabled"
              label="Text Field"
              setFocus={setFocus}
              placeholder="Sample text field"
              disabled
              register={register}
              errors={errors}
              required
            />
          </div>
        </div>
        <div className="py-3">
          <div className="flex p-2">
            <TextField
              id="textfieldEditable"
              name="textfieldEditable"
              label="Text Field"
              fieldEdit
              edited={edited}
              onEditComplete={() => {
                setEdited(true)
              }}
              disabled
              placeholder="Sample text field"
              register={register}
              setFocus={setFocus}
              errors={errors}
              required
            />
          </div>
        </div>
        <div className="py-3">
          <div className="flex p-2">
            <Button label="validate" type="submit" />
          </div>
        </div>
      </div>

      {/* -------------------------------------------------------------------------- */}
    </form>
  )
}

export default TextFieldComponent

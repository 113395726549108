import { Button } from '@/components/common'

const ButtonWithLoaderComponent = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      <div className="py-3">
        <div className="comp-label py-2">Primary</div>

        <div>
          <Button isLoading label="Create" />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary</div>
        <div>
          <Button isLoading label="Create" primary={false} />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary Outlined</div>
        <div>
          {' '}
          <Button isLoading label="Create" outlined />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary Outlined</div>
        <div>
          <Button isLoading label="Create" outlined primary={false} />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary with left Icon alignment</div>
        <div>
          {' '}
          <Button isLoading label="Create" primary icon="plus" />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary with left Icon alignment</div>
        <div>
          {' '}
          <Button isLoading label="Create" primary={false} icon="plus" />
        </div>
      </div>

      <div className="py-3">
        <div className="comp-label py-2">Primary Outlined with left Icon alignment</div>
        <div>
          <Button isLoading label="Create" primary icon="plus" outlined />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary Outlined with left Icon alignment</div>
        <div>
          <Button isLoading label="Create" primary={false} icon="plus" outlined />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary with right icon alignment</div>
        <div>
          <Button isLoading label="Create" primary icon="plus" iconAlignment="right" />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary with right icon alignment</div>
        <div>
          <Button
            isLoading
            label="Create"
            primary={false}
            icon="plus"
            iconAlignment="right"
          />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary Outlined with right icon alignment</div>
        <div>
          <Button
            isLoading
            label="Create"
            primary
            icon="plus"
            iconAlignment="right"
            outlined
          />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">
          Secondary Outlined with right icon alignment
        </div>
        <div>
          <Button
            isLoading
            label="Create"
            primary={false}
            icon="plus"
            iconAlignment="right"
            outlined
          />
        </div>
      </div>
    </div>
  )
}

export default ButtonWithLoaderComponent

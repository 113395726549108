import { BreadcrumbProps } from '@/common/customComponenttypes'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import CustomIcons from '../icons/customIcons'

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, className }) => {
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    setLoader(true)

    timer = setTimeout(() => {
      setLoader(false)
    }, 1200)

    return () => clearTimeout(timer)
  }, [])
  return (
    <div
      className={`${
        className ? className : 'p-[10px] '
      } bg-white border-b border-grey-borderLight`}
    >
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="inline-flex items-center ml-2.5">
          {loader ? (
            <>
              <div className="custom-skelton-gradient w-[14px] h-[14px] mt-2 rounded-full"></div>

              <span className="mx-2.5 text-input  text-[#EAEAEA] md:mx-3">
                <CustomIcons name={'division'} type="medium" viewBox={true} />
              </span>
              {items.map((item, index) => (
                <li className="inline-flex items-center " key={index}>
                  <div className="custom-skelton-gradient w-[85px] h-[14px] mt-2 rounded-[30px]"></div>

                  {index < items.length - 1 && (
                    <span className="mx-2.5 text-input  text-[#EAEAEA] md:mx-3">
                      <CustomIcons name={'division'} type="medium" viewBox={true} />
                    </span>
                  )}
                </li>
              ))}
            </>
          ) : (
            <>
              <Link to="/">
                <CustomIcons
                  name={'home'}
                  className=" text-[#667085]"
                  type="large"
                  viewBox={false}
                />
              </Link>
              <span className="mx-2.5 text-input text-primary md:mx-3">
                <CustomIcons name={'division'} type="medium" viewBox={true} />
              </span>
              {items.map((item, index) => (
                <li className="inline-flex items-center " key={index}>
                  {item.icon && (
                    <CustomIcons
                      name={item.icon}
                      className="flex items-center justify-center pr-1 w-5"
                      data-testid="button-icon-left"
                    />
                  )}

                  {!item.link || index === items.length - 1 ? (
                    <span
                      className={`text-input font-medium text-text-${index === items.length - 1 ? 'primary' : 'secondary'}`}
                    >
                      {item.label}
                    </span>
                  ) : (
                    <Link
                      className="text-input font-normal text-text-secondary hover:text-shadow-dark"
                      to={item.link ?? ''}
                    >
                      {item.label}
                    </Link>
                  )}

                  {index < items.length - 1 && (
                    <span className="mx-2.5 text-input text-primary md:mx-3">
                      <CustomIcons name={'division'} type="medium" viewBox={true} />
                    </span>
                  )}
                </li>
              ))}
            </>
          )}
        </ol>
      </nav>
    </div>
  )
}
export default Breadcrumb

import { lazy } from 'react'

import { payrollRouterStore } from './store'

//register
const DummyList = lazy(() => import('./pages/registers/dummy'))
const CrewWageList = lazy(() => import('./pages/registers/CrewWageItem'))

//CREW_WAGE_SCALE_

//Main-Pages
const CrewWageScale = lazy(() => import('./pages/crewWageScale'))

//CHILDREN_PAGES
const CrewWageScaleDetail = lazy(
  () => import('./pages/crewWageScale/crewWageScaleDetail')
)
const RivisionPage = lazy(
  () => import('./pages/crewWageScale/crewWageScaleDetail/rivisions')
)
const ApplicablePage = lazy(
  () => import('./pages/crewWageScale/crewWageScaleDetail/applicable')
)

const RivisionEarningDetails = lazy(
  () =>
    import('./pages/crewWageScale/crewWageScaleDetail/rivisions/detail/Earnings/details')
)

const payrollRoutes = [
  {
    slug: 'DUMMYY_LIST',
    path: payrollRouterStore.DUMMY_LIST,
    component: <DummyList />
  },
  {
    slug: 'CREW_WAGES_LIST',
    path: payrollRouterStore.CREW_WAGES_LIST,
    component: <CrewWageList />
  },
  {
    slug: 'CREW_WAGE_SCALE',
    path: payrollRouterStore.CREW_WAGE_SCALE,
    component: <CrewWageScale />
  },
  {
    slug: 'CREW_WAGE_SCALE_DETAILS',
    path: payrollRouterStore.CREW_WAGE_SCALE_DETAILS,
    component: <CrewWageScaleDetail />,
    children: [
      {
        slug: 'CREW_WAGE_SCALE_RIVISION',
        path: `${payrollRouterStore.CREW_WAGE_SCALE_RIVISION}${payrollRouterStore.CREW_WAGE_SCALE_RIVISION_INDEX}`,
        component: <RivisionPage />
      },
      {
        slug: 'CREW_WAGE_SCALE_APPLICABLE',
        path: payrollRouterStore.CREW_WAGE_SCALE_APPLICABLE,
        component: <ApplicablePage />
      }
    ]
  },
  {
    slug: 'CREW_WAGE_SCALE_RIVISION_EARNING',
    path: payrollRouterStore.CREW_WAGE_SCALE_RIVISION_EARNING,
    component: <RivisionEarningDetails />
  }
]

export default payrollRoutes

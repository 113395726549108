import { ButtonProps } from '@/common/customComponenttypes'
import React from 'react'

import { useAppContext } from '@/store/context/appContext'
import { generateTestId } from '@/utilities/dataGenerators'
import debounce from '../../../utilities/debounce'
import CustomIcons from '../icons/customIcons'
import { ButtonSpinner } from '../index'

const Button: React.FC<ButtonProps> = ({
  label,
  icon,
  iconAlignment = 'left',
  type = 'button',
  onClick = undefined,
  size = 'md',
  className = '',
  outlined = false,
  primary = true,
  iconSize,
  fullwidth = false,
  theme,
  isLoading = false,
  disabled = false,
  isWarning,
  hasCustomIconSize,
  iconDimension
}) => {
  const { isActionLoader } = useAppContext()

  const generateClassName = () => {
    let genclass: string = `btn relative  px-4 rounded-button `

    switch (size) {
      case 'xs':
        genclass += ' h-button '
        break
      case 'sm':
        genclass += ' h-button-sm '
        break
      case 'md':
        genclass += ' h-button '
        break
      case 'lg':
        genclass += ' h-button '
        break
      default:
        genclass += ' h-button '
    }
    if (theme) {
      switch (theme) {
        case 'green':
          genclass += ` ${outlined ? 'btn-green-outlined ' : 'btn-green'}`
          break
        case 'red':
          genclass += ` ${outlined ? 'btn-red-outlined ' : 'btn-red'}`
          break
        case 'orange':
          genclass += ` ${outlined ? 'btn-orange-outlined ' : 'btn-orange'}`
          break
        case 'blue':
          genclass += ` ${outlined ? 'btn-blue-outlined ' : 'btn-blue'}`
          break
        default:
          break
      }
    } else {
      if (primary) {
        genclass += ` ${outlined ? 'btn-primary-outlined ' : 'btn-primary'}`
      } else {
        genclass += `${outlined ? 'btn-secondary-outlined ' : ' btn-secondary'}`
      }
    }

    if (fullwidth) {
      genclass += ` w-full`
    }
    if (className) {
      genclass += ` ${className}`
    }
    return genclass
  }
  const handleClick = debounce((e: any) => {
    onClick?.(e)
  }, 300)

  const getIconSize = () => {
    return iconSize ? iconSize : size === 'sm' ? 'large-m' : 'normal'
  }

  const handleLoader = () => {
    return (!icon && label === 'Yes' && isActionLoader) || (!icon && isLoading)
  }

  return (
    <>
      <button
        type={type}
        disabled={disabled || isLoading || (label === 'Yes' && isActionLoader)}
        onClick={primary && !outlined ? handleClick : onClick}
        data-testid={`app-common-button-${iconAlignment}-${generateTestId(icon ?? (label as string))}`}
        className={` ${generateClassName()}`}
      >
        <div className="flex items-center justify-center ">
          {handleLoader() && (
            <div className="absolute w-full h-full flex items-center justify-center">
              <ButtonSpinner
                position="center"
                data-testid={`app-common-button-spinner${iconAlignment}-${generateTestId(icon ?? (label as string))}`}
              />
            </div>
          )}
          {icon && iconAlignment === 'left' && (
            <div className="flex items-center justify-center">
              {isLoading ? (
                <ButtonSpinner
                  data-testid={`app-common-button-spinner-${iconAlignment}-${generateTestId(icon)}`}
                />
              ) : (
                <CustomIcons
                  name={icon}
                  viewBox={true}
                  custDimension={iconDimension}
                  hasCustomSize={hasCustomIconSize}
                  type={getIconSize()}
                  className={`flex items-center justify-center ${label ? 'pr-1' : ''}`}
                  data-testid={`button-icon-${iconAlignment}-${generateTestId(icon)}`}
                />
              )}
            </div>
          )}
          {label ? (
            <div
              className={`whitespace-nowrap leading-[14px] ${
                isWarning ? 'text-status' : ''
              } ${handleLoader() ? 'opacity-25' : ''}`}
            >
              {label}
            </div>
          ) : (
            ''
          )}
          {icon && iconAlignment === 'right' && (
            <div className="flex items-center justify-center">
              {isLoading ? (
                <React.Fragment
                  data-testid={`app-common-button-spinner-${iconAlignment}-${generateTestId(icon)}`}
                >
                  <ButtonSpinner
                    position="right"
                    data-testid={`app-common-button-spinner-${iconAlignment}-${generateTestId(icon)}`}
                  />
                </React.Fragment>
              ) : (
                <CustomIcons
                  name={icon}
                  type={getIconSize()}
                  hasCustomSize={hasCustomIconSize}
                  custDimension={iconDimension}
                  className={`flex items-center justify-center  pl-1 ${
                    outlined ? '' : 'text-white'
                  }`}
                  data-testid={`button-icon-${iconAlignment}-${generateTestId(icon)}`}
                />
              )}
            </div>
          )}
        </div>
      </button>
    </>
  )
}

export default Button

import { z } from 'zod'

export const ManageDashboardFormSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Dashboard name is required ' })
    .max(50, { message: 'Maximum 50 characters allowed' })
})

export type ManageDashboardForm = z.infer<typeof ManageDashboardFormSchema>

export type DashboardListProps = {
  DashName: string
  label: string
  DashId: number
  id: string | number
  SeqNo: number
}

export type DashboardFormSubmitType = {
  name: string
}

import { AutoComplete, TextField } from '@/components/common'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const TableBreakUp = [
  { id: 1, name: 'By Month' },
  { id: 2, name: 'By Days' },
  { id: 3, name: 'Manually' }
]
type BudgetItemColumnProps = {
  handleChange: (
    key: string,
    row: any,
    e: React.ChangeEvent<HTMLInputElement>,
    focus: boolean
  ) => void
  handleDropChange: (key: string, id: string, row: any, e: any) => void
  BudgetDetails: any
  viewable: boolean
  SplitEqually: boolean
}

export const useGetBudgetItemColumns = ({
  BudgetDetails,
  handleChange,
  handleDropChange,
  viewable,
  SplitEqually
}: BudgetItemColumnProps) => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors }
  } = useFormContext()

  const getErrors = (errors: any, index: number) => {
    return errors?.BudgetDetails?.[index]
  }
  const rendorMonths = (key: string, disableKey: string, data: any) => {
    const index = BudgetDetails?.findIndex(
      (item: any) => item.BudgetEntityId === data.BudgetEntityId
    )
    return (
      <>
        <Controller
          name={key}
          control={control}
          render={() => (
            <TextField
              label={''}
              placeholder={''}
              id={key}
              name={key}
              value={`${data?.[key]}`}
              type="number"
              isTooltip={false}
              autoFocus={data?.focus?.[key]}
              onChange={(e) => handleChange(key, data, e, true)}
              onBlur={(e) => handleChange(key, data, e, false)}
              maxLength={10}
              errors={getErrors(errors, index)}
              disabled={viewable || data?.[disableKey]}
            />
          )}
        />
      </>
    )
  }
  const handleReturnValue = (key: string, id: string, data: any) => {
    return { id: data[id] ?? '', name: data[key] ?? '' }
  }
  return [
    {
      title: t('Vessels'),
      field: 'EntityName',
      type: 'text',
      locked: true
    },
    {
      title: t('Start Date'),
      field: 'StartDate',
      type: 'date',
      width: 110,
      locked: true
    },
    {
      title: t('End Date'),
      field: 'EndDate',
      type: 'date',
      width: 110,
      locked: true
    },
    {
      title: t('Days'),
      field: 'Days',
      width: 75,
      type: 'text',
      locked: true
    },
    {
      title: t('Budget'),
      field: 'Budget',
      type: 'text',
      render: (data: any) => {
        const index = BudgetDetails?.findIndex(
          (item: any) => item.BudgetEntityId === data.BudgetEntityId
        )
        return (
          <>
            <Controller
              name={'Budget'}
              control={control}
              render={() => (
                <TextField
                  label={''}
                  placeholder={''}
                  id={'Budget'}
                  name={'Budget'}
                  value={`${data?.Budget}`}
                  type="number"
                  isTooltip={false}
                  autoFocus={data?.focus?.Budget}
                  onChange={(e) => handleChange('Budget', data, e, true)}
                  onBlur={(e) => handleChange('Budget', data, e, false)}
                  maxLength={10}
                  errors={getErrors(errors, index)}
                  disabled={viewable || SplitEqually}
                />
              )}
            />
          </>
        )
      }
    },
    {
      title: t('Distribution'),
      field: 'SplitTypeName',
      type: 'text',
      width: 180,
      render: (data: any) => (
        <>
          <Controller
            name={'SplitTypeName'}
            control={control}
            render={() => (
              <AutoComplete
                key={'SplitTypeName'}
                placeholder={''}
                onChange={(e) =>
                  handleDropChange('SplitTypeName', 'SplitTypeId', data, e)
                }
                name={'SplitTypeName'}
                value={
                  handleReturnValue('SplitTypeName', 'SplitTypeId', data) ?? {
                    id: TableBreakUp?.[0]?.id,
                    name: TableBreakUp?.[0]?.name
                  }
                }
                type="custom_select"
                label={''}
                data={TableBreakUp}
                disabled={viewable}
                insideOpen={false}
                isClose={false}
              />
            )}
          />
        </>
      )
    },
    {
      title: t('Jan'),
      field: 'Amount_01',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_01', 'Disable_1', data)
    },
    {
      title: t('Feb'),
      field: 'Amount_02',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_02', 'Disable_2', data)
    },
    {
      title: t('Mar'),
      field: 'Amount_03',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_03', 'Disable_3', data)
    },
    {
      title: t('Apr'),
      field: 'Amount_04',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_04', 'Disable_4', data)
    },
    {
      title: t('May'),
      field: 'Amount_05',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_05', 'Disable_5', data)
    },
    {
      title: t('Jun'),
      field: 'Amount_06',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_06', 'Disable_6', data)
    },
    {
      title: t('Jul'),
      field: 'Amount_07',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_07', 'Disable_7', data)
    },
    {
      title: t('Aug'),
      field: 'Amount_08',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_08', 'Disable_8', data)
    },
    {
      title: t('Sep'),
      field: 'Amount_09',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_09', 'Disable_9', data)
    },
    {
      title: t('Oct'),
      field: 'Amount_10',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_10', 'Disable_10', data)
    },
    {
      title: t('Nov'),
      field: 'Amount_11',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_11', 'Disable_11', data)
    },
    {
      title: t('Dec'),
      field: 'Amount_12',
      type: 'text',
      render: (data: any) => rendorMonths('Amount_12', 'Disable_12', data)
    }
  ]
}

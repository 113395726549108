import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const DashClose: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M1 13L0 12L5.5 6.5L0 1L1 0L6.5 5.5L12 0L13 1L7.5 6.5L13 12L12 13L6.5 7.5L1 13Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DashClose

export const sideMenuItems = [
  {
    id: '1',
    title: 'home',
    slug: 'home',
    iconName: 'home',
    child: false,
    url: '/dashboard'
  },
  {
    id: '2',
    title: 'favourite',
    slug: 'favourite',
    iconName: 'favourite',
    child: true
  },
  {
    id: '3',
    title: 'explorer',
    slug: 'explorer',
    iconName: 'explorer',
    child: true
  },
  {
    id: '4',
    title: 'livefleet',
    slug: 'livefleet',
    iconName: 'LiveFleet',
    url: '/samples',
    child: false
  }
]
export const loaderData = [
  { id: 1, w: '85px' },
  { id: 2, w: '122px' },
  { id: 3, w: '105px' },
  { id: 4, w: '116px' },
  { id: 5, w: '143px' },
  { id: 6, w: '85px' },
  { id: 7, w: '122px' },
  { id: 8, w: '105px' },
  { id: 9, w: '116px' },
  { id: 10, w: '143px' },
  { id: 11, w: '143px' },
  { id: 12, w: '85px' },
  { id: 13, w: '122px' },
  { id: 14, w: '105px' },
  { id: 15, w: '116px' },
  { id: 16, w: '143px' }
]
export const profileMenu = [
  {
    id: '1',
    title: 'Profile',
    iconName: 'profile-icon',
    slug: 'profile'
  },
  {
    id: '2',
    title: 'Change Password',
    iconName: 'view-large',

    slug: 'change_password'
  },
  {
    id: '3',
    title: 'Logout',
    iconName: 'logout',
    slug: 'logout'
  }
]

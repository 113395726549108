import { Button, Fab } from '@/components/common'
import FormBuilder from '@/components/common/formBuilder'
import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { isAllow } from '@/constants'
import { useManagementInfo } from '@/modules/technical/pages/vesselParticulars/details/management/api'
import {
  ManagementSchema,
  managementSchema
} from '@/modules/technical/pages/vesselParticulars/details/management/schema'
import { ParticularDetailsContextProps } from '@/modules/technical/pages/vesselParticulars/details/schema'
import { formContainerClass } from '@/styles/variables'
import useHasPermission from '@/utilities/checkActionPermission'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

type VesselManagementForms = {
  data: any
  vesselId?: string
}
const VesselManagementForm: React.FC<VesselManagementForms> = ({ data, vesselId }) => {
  const { t } = useTranslation()
  const { edit, setEdit, vesselID } = useOutletContext<ParticularDetailsContextProps>()
  const [isOpen, setPopupOpen] = useState<boolean>(false)

  const hasPermission = useHasPermission('VesselMgmtInfo')
  const onSuccessAction = () => {
    setPopupOpen(true)
  }
  const handleClose = () => {
    setPopupOpen(false)
    setEdit(false)
  }

  const initData = () => {
    return {
      VesselName: data?.VesselName,
      VesselId: data?.VesselId,
      PrevName: data?.PrevName,
      PrevFlagName: data?.PrevFlagName,
      PrevFlagId: data?.PrevFlagId,
      PrevOwnerName: data?.PrevOwnerName,
      PrevManagerName: data?.PrevManagerName,
      DateOfMngrChange: data?.DateOfMngrChange && new Date(data?.DateOfMngrChange),
      MgmtEndDate: data?.MgmtEndDate && new Date(data?.MgmtEndDate),
      MgmtStartDate: data?.MgmtStartDate && new Date(data?.MgmtStartDate),
      DateOfOwnrChange: data?.DateOfOwnrChange && new Date(data?.DateOfOwnrChange),
      DateOfFlagChange: data?.DateOfFlagChange && new Date(data?.DateOfFlagChange),
      DateOfNameChange: data?.DateOfNameChange && new Date(data?.DateOfNameChange)
    }
  }

  const methods = useForm<ManagementSchema>({
    resolver: zodResolver(managementSchema),
    mode: 'onChange',

    defaultValues: initData()
  })
  const { mutate, isLoading } = useManagementInfo(onSuccessAction)
  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      MgmtStartDate: data.MgmtStartDate
        ? dayjs(data.MgmtStartDate).format('YYYY-MM-DD')
        : null,
      MgmtEndDate: data.MgmtEndDate ? dayjs(data.MgmtEndDate).format('YYYY-MM-DD') : null,
      DateOfNameChange: data.DateOfNameChange
        ? dayjs(data.DateOfNameChange).format('YYYY-MM-DD')
        : null,
      DateOfFlagChange: data.DateOfFlagChange
        ? dayjs(data.DateOfFlagChange).format('YYYY-MM-DD')
        : null,
      DateOfOwnrChange: data.DateOfOwnrChange
        ? dayjs(data.DateOfOwnrChange).format('YYYY-MM-DD')
        : null,
      DateOfMngrChange: data.DateOfMngrChange
        ? dayjs(data.DateOfMngrChange).format('YYYY-MM-DD')
        : null
    }
    mutate(payload)
  }
  const { MgmtStartDate, MgmtEndDate } = methods.watch()

  const formConfig = [
    {
      label: 'Management Start Date',
      name: 'MgmtStartDate',
      placeholder: 'Management Start Date (DD-MMM-YYYY)',
      maxDate: MgmtEndDate ? new Date(MgmtEndDate) : '',
      type: 'date'
    },
    {
      label: 'Management End Date',
      name: 'MgmtEndDate',
      placeholder: 'Management End Date (DD-MMM-YYYY)',
      minDate: MgmtStartDate ? new Date(MgmtStartDate) : '',
      type: 'date'
    },
    {
      label: 'Previous Name',
      placeholder: 'Previous Name',
      name: 'PrevName',
      type: 'text',
      maxLength: 100
    },
    {
      label: 'Date Of Name Change',
      name: 'DateOfNameChange',
      placeholder: 'Date Of Name Change (DD-MMM-YYYY)',
      type: 'date'
    },
    {
      label: 'Previous Flag',
      placeholder: 'Type to search',
      name: 'PrevFlagName',
      type: 'autocomplete',
      id: 'PrevFlagId',
      maxLength: 4,
      apiParams: { key: 'COUNTRY' },
      checkParams: ['param1', 'param2']
    },
    {
      label: 'Date Of Flag Change',
      name: 'DateOfFlagChange',
      placeholder: 'Date Of Flag Change (DD-MMM-YYYY)',
      type: 'date'
    },
    {
      label: 'Previous Owner',
      placeholder: 'Previous Owner',
      name: 'PrevOwnerName',
      type: 'text',
      maxLength: 100
    },
    {
      label: 'Date Of Owner Change',
      name: 'DateOfOwnrChange',
      placeholder: 'Date Of Owner Change (DD-MMM-YYYY)',
      type: 'date'
    },
    {
      label: 'Previous Manager',
      placeholder: 'Previous Manager',
      name: 'PrevManagerName',
      type: 'text',
      maxLength: 100
    },
    {
      label: 'Date Of Manager Change',
      name: 'DateOfMngrChange',
      placeholder: 'Date Of Manager Change (DD-MMM-YYYY)',
      type: 'date'
    }
  ]
  useEffect(() => {
    methods.reset({ ...initData() })
  }, [data])
  const resetEdit = () => {
    methods.reset()
    setEdit(false)
  }
  const handleEdit = async () => {
    const permission = await hasPermission({
      UserAction: isAllow.UPDATE,
      AccessKey: '',
      ID1: vesselID
    })
    setEdit(permission)
  }
  return (
    <div className="h-full relative w-full">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={formContainerClass}>
            <FormBuilder data={formConfig} edit={edit} />

            <div className="py-7">
              <div className="absolute bottom-0 right-0 p-2 py-1">
                {!edit ? (
                  <Fab
                    icontType="large"
                    onClick={() => {
                      handleEdit()
                    }}
                    className="fabShadow"
                  />
                ) : (
                  <div className="flex">
                    <Button
                      label={t('update')}
                      className="me-2"
                      isLoading={isLoading}
                      onClick={methods.handleSubmit(onSubmit)}
                    />
                    <Button
                      label={t('cancel')}
                      outlined
                      onClick={() => {
                        resetEdit()
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <ConfirmModal
        isOpen={isOpen}
        modalId="successPopup"
        topIcon="success"
        title="Success"
        type="success"
        actionLabel="OK"
        onSubmit={handleClose}
        scrollDisable={false}
        onClose={handleClose}
        subTitle="Thank you. You have updated successfully"
      />
    </div>
  )
}

export default VesselManagementForm

import React, { useCallback, useEffect, useState } from 'react'

import { getRegister } from '../../../apis/common.api'
import { SearchBuilderProps } from '../../../common/customComponenttypes'
import { formContainerClass, formContainerKendoClass } from '../../../styles/variables'
import { generateSpacing } from '../../../utilities'
import useScreenBreakPoint from '../../../utilities/useScreenBreakpoint'
import CustomIcons from '../icons/customIcons'
import { AutoComplete, Button, DatePicker, Switch, TextArea, TextField } from '../index'

type Props = {
  data?: any
  activeFilters?: any
  errors?: any
  gridType?: string
  spacing?: number
  edit?: boolean
  fromTable?: boolean
  title?: any
  onSearch: (params: any) => void
  setAdditionalValues?: (key: string, value: any) => void
  isExpanded?: boolean
  handleDataCallBack?: (data: any) => void
  handleClear?: () => void
}
let updateKey = 1

const CommonSearch: React.FC<Props> = (props) => {
  const {
    data,
    spacing = 4,
    setAdditionalValues,
    errors,
    onSearch,
    activeFilters,

    // title,
    fromTable = false,
    isExpanded,
    handleDataCallBack,
    handleClear
  } = props
  const [filters, setFilters] = useState<any>({ ...activeFilters })
  const [showAll, setShowAll] = useState(false)
  const setValue = (id: string, value?: any) => {
    setFilters((fil: any) => ({ ...fil, [id]: value }))
  }

  const handleChange = useCallback((e: any, field: SearchBuilderProps) => {
    if (e?.id && e?.id !== '') {
      setValue(field.name, e)
    } else {
      setValue(field.name, undefined)
    }

    if (field.additionalValue) {
      setAdditionalValues?.(field.id, e)
    }
  }, [])
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: SearchBuilderProps
  ) => {
    const { value } = e.target
    setValue(field.name, value && value !== '' ? value.trimStart() : undefined)
  }

  const getData = async (value?: string, apiParams?: any) => {
    return getRegister({ ...apiParams, desc: value }).then((resps) => {
      return resps.map((data: any) => {
        return {
          name: data.Description,
          id: data.Id,
          param1: data.Param1,
          param2: data.Param2
        }
      })
    })
  }

  const handleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: SearchBuilderProps
  ) => {
    const { value } = e.target

    setValue(field.name, value && value !== '' ? parseInt(value) : null)
  }
  const handleSearch = (e: any) => {
    e.preventDefault()
    let searchParams: any = filters
    onSearch(searchParams)
  }
  useEffect(() => {
    handleDataCallBack?.(filters)
  }, [filters])
  const renderForm = (field: SearchBuilderProps) => {
    switch (field.type) {
      case 'text':
      case 'email':
        return (
          <TextField
            label={field.label}
            placeholder={field.placeholder}
            id={field.name}
            name={field.name}
            size={fromTable ? 'sm' : 'md'}
            type={field.type}
            value={filters[field.name]}
            onChange={(e) => handleInputChange(e, field)}
            required={field.required}
            adorement={field.adorement}
            errors={errors}
            maxLength={field.maxLength ?? 200}
            minLength={field.minLength}
            disabled={field.disabled}
          />
        )

      case 'number':
        return (
          <TextField
            label={field.label}
            placeholder={field.placeholder}
            id={field.name}
            name={field.name}
            size={fromTable ? 'sm' : 'md'}
            value={filters[field.name]}
            type={field.type}
            onChange={(e) => handleNumberChange(e, field)}
            required={field.required}
            adorement={field.adorement}
            errors={errors}
            maxLength={field.maxLength ?? 200}
            minLength={field.minLength}
            disabled={field.disabled}
          />
        )
      case 'textarea':
        return (
          <TextArea
            label={field.label}
            placeholder={field.placeholder}
            id={field.name}
            name={field.name}
            value={filters[field.name]}
            onChange={(e) => handleInputChange(e, field)}
            required={field.required}
            errors={errors}
            maxLength={field.maxLength ?? 200}
            minLength={field.minLength}
            disabled={field.disabled}
          />
        )
      case 'autocomplete':
        return (
          <AutoComplete
            key={field.name + updateKey}
            placeholder={field.placeholder}
            onChange={(e) => handleChange(e, field)}
            name={field.name}
            value={filters[field.name] ?? { id: '', name: '' }}
            type="auto_complete"
            label={field.label}
            getData={
              field.apiParams
                ? (key) => getData(key, field.apiParams)
                : (key) => field.data // to avoid api call if api params not present
            }
            disabled={field.disabled}
            required={field.required}
            autoFilter={field.autoFilter ? field.autoFilter : false}
            noLocalFilter={field.isStaticList ? false : true}
            isCustomPlaceholder={field.isCustomPlaceholder ?? false}
            isStaticList={field.isStaticList ?? false}
            checkParams={field.checkParams}
          />
        )
      case 'custom_search_select':
        return (
          <AutoComplete
            key={field.name + updateKey}
            placeholder={field.placeholder}
            onChange={(e) => handleChange(e, field)}
            name={field.name}
            value={filters[field.name] ?? { id: '', name: '' }}
            type="custom_search_select"
            label={field.label}
            data={field.data}
            getData={(key) => getData(key, field.apiParams)}
            errors={errors}
            disabled={field.disabled}
            required={field.required}
            autoFilter={field.autoFilter ? field.autoFilter : false}
          />
        )
      case 'custom_select':
        return (
          <AutoComplete
            key={field.name + updateKey}
            placeholder={field.placeholder}
            onChange={(e) => handleChange(e, field)}
            name={field.name}
            value={filters[field.name] ?? { id: '', name: '' }}
            type="custom_select"
            label={field.label}
            data={field.data}
            getData={(key) => getData(key, field.apiParams)}
            errors={errors}
            disabled={field.disabled}
            required={field.required}
            autoFilter={field.autoFilter ? field.autoFilter : false}
          />
        )
      case 'date':
        return (
          <DatePicker
            onChange={(data) => {
              setValue(field.name, data.value)
            }}
            name={field.name}
            value={filters[field.name]}
            placeholder={field.placeholder || 'DD-MMM-YYYY'}
            label={field.label}
            minDate={field.minDate}
            maxDate={field.maxDate}
            errors={errors}
            key={updateKey}
            showYearPicker={field.showYearPicker}
            disabled={field.disabled}
            required={field.required}
            minYearRange={field.minYearRange}
            maxYearRange={field.maxYearRange}
          />
        )
      case 'switch':
        return (
          <Switch
            id={field.name}
            name={field.name}
            value={filters[field.name]}
            handleChange={(e) => {
              setValue(field.name, e?.target.checked)
            }}
            disabled={field.disabled}
            label={field.label}
          />
        )
      default:
        return null
    }
  }
  const { isMobile, isTab } = useScreenBreakPoint()
  useEffect(() => {
    setFilters({ ...activeFilters })
  }, [activeFilters])
  const handleClearVisibility = () => {
    return handleClear && ((!isMobile && !isTab) || showAll)
  }
  const clearValues = () => {
    handleClear?.()
    updateKey += 1
  }
  return (
    <div className={`${!fromTable ? 'py-5 px-[15px]' : ''}`}>
      {/* <p className="text-dark text-m font-medium py-3 px-3">{title}</p> */}
      <form onSubmit={handleSearch}>
        <div
          className={
            fromTable
              ? (isMobile || isTab) && !showAll
                ? ''
                : formContainerKendoClass
              : formContainerClass
          }
        >
          {data
            .slice(0, (isMobile || isTab) && !showAll ? (fromTable ? 0 : 2) : data.length)
            .map((field: SearchBuilderProps) => (
              <div
                key={field.name}
                className={`col-start-1 ${generateSpacing(
                  isTab && showAll
                    ? fromTable
                      ? field.spacing
                      : 'search'
                    : isTab && !showAll
                      ? fromTable
                        ? field.spacing
                        : 'search'
                      : field.spacing || spacing
                )} ${field.type === 'switch' ? 'flex items-center  ' : ''} ${
                  fromTable ? ` sm:col-span-${field.spacing} ` : ''
                }`}
              >
                {!field.isSecondary ? (
                  <>{renderForm(field)}</>
                ) : (
                  isExpanded && <>{renderForm(field)}</>
                )}
              </div>
            ))}

          <div
            className={
              handleClearVisibility()
                ? `${
                    (isMobile || isTab) && showAll ? 'col-span-5' : 'col-span-3'
                  } flex justify-between items-center gap-10`
                : 'col-span-2  md:col-span-2 flex justify-between items-center gap-4 '
            }
          >
            {handleClearVisibility() && (
              <div className="  pl-4 flex items-center justify-center text-[#344054] cursor-pointer text-[14px] font-medium ">
                <span className="border-b-[1px] border-[#344054]" onClick={clearValues}>
                  Clear All
                </span>
              </div>
            )}

            <div className="flex gap-4 h-full">
              {(isMobile || isTab) && !showAll && fromTable ? (
                <>
                  <Button
                    size={'sm'}
                    label={''}
                    type="button"
                    onClick={() => {
                      setTimeout(() => {
                        setShowAll(!showAll)
                      }, 200)
                    }}
                    icon="search"
                    iconSize={fromTable ? 'large' : 'large-m'}
                    outlined={true}
                    className={`h-button h-button-icon-search w-[34px] btn-primary-outlined-gray`}
                  />
                </>
              ) : (
                <>
                  <Button
                    size={fromTable ? 'sm' : 'md'}
                    label={!fromTable ? 'Search' : ''}
                    type="submit"
                    iconSize={fromTable ? 'large-m' : 'large-m'}
                    icon="search"
                    className={`${fromTable ? 'h-button-icon-search w-[34px]' : ''}`}
                  />
                </>
              )}
            </div>
            {(isMobile || isTab) && data.length > 2 && (
              <div className="flex">
                <span
                  className="p-2 border h-[40px] rounded-input border-grey-borderLight text-[#667085]"
                  onClick={() => setShowAll(!showAll)}
                >
                  <CustomIcons
                    type={fromTable ? 'small' : ''}
                    name={!showAll ? 'down_arrow' : 'up_arrow'}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default CommonSearch

import { AccordionHeaderProps } from '@/common/customComponenttypes'
import React, { useRef } from 'react'
import { useAccordion } from './AccordionContext'

const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  onClick = undefined,
  type = 'button',
  className,
  children
}) => {
  const { open, disabled } = useAccordion()
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const divRef = useRef<HTMLDivElement | null>(null)
  const buttonStyles = `${className} ${open ?? 'active'}`
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) => {
    if (!disabled) {
      if (onClick) onClick(e)
    }
  }

  const buttonBlock = () => {
    return (
      <button
        type="button"
        disabled={disabled}
        className={buttonStyles}
        onClick={(e) => {
          handleClick(e)
        }}
        ref={buttonRef}
      >
        {children}
      </button>
    )
  }

  const divBlock = () => {
    return (
      <div
        className={buttonStyles}
        onClick={(e) => {
          handleClick(e)
        }}
        ref={divRef}
      >
        {children}
      </div>
    )
  }

  const getHeader = () => {
    switch (type) {
      case 'block':
        return divBlock()
      default:
        return buttonBlock()
    }
  }

  return getHeader()
}

export default AccordionHeader

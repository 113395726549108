import { Checkbox } from '@/components/common'

const CheckboxComponent = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="py-3">
          <div className="flex p-2">
            <Checkbox id="switch" name="switch" label="Checkbox" />
          </div>
        </div>
        <div className="py-3">
          <div className="flex p-2">
            <Checkbox
              id="switchDisabled"
              disabled
              name="switchDisabled"
              label="Checkbox Disabled"
            />
          </div>
        </div>
      </div>

      {/* -------------------------------------------------------------------------- */}
    </>
  )
}

export default CheckboxComponent

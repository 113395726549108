import { validateNumberOfDigits } from '@/utilities'
import { z } from 'zod'
export const paintFormSchema = z.object({
  paintTypeId: z.union([z.number(), z.string()]),
  paintTypeName: z.string().min(1, { message: 'Paint Type is required' }),
  makerName: z.string().optional(),
  makerId: z.union([z.number(), z.string()]).optional(),
  antiFoulingPaintId: z.string().optional(),
  life: z
    .number()
    .min(0, { message: 'Life must be a positive number' })
    .refine(validateNumberOfDigits, { message: 'Max length for Life is 8 ' })
    .optional()
    .nullable(),
  paintingSurfaceId: z.union([z.number(), z.string()]),
  paintingSurfaceName: z.string().min(1, { message: 'Paint Surface is required' }),
  prparationTypeId: z.union([z.number(), z.string()]),
  prparationTypeName: z.string().min(1, { message: 'Preparation Type is required' }),
  tiecoat: z.boolean().optional(),
  dft: z
    .number()
    .min(0, { message: 'DFT must be a positive number' })
    .refine(validateNumberOfDigits, { message: 'Max length for DFT is 8 ' })
    .optional()
    .nullable(),
  paintUpToDraft: z
    .number()
    .min(0, { message: 'Upto Draft must be a positive number' })
    .refine(validateNumberOfDigits, { message: 'Max length for Up to Draft is 8 ' })
    .optional()
    .nullable(),
  remarks: z
    .string()
    .max(500, { message: 'Remarks must be 500 characters or less' })
    .optional()
    .nullable(),
  vesselId: z.string().optional()
  // Uncomment and add validations for any additional fields you need
  // SPFullBlast: z.boolean().optional(),
})

export type PaintFormSchema = z.infer<typeof paintFormSchema>


import { defaultPageSize } from '@/constants'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type AuditlogFilterTypeStore = {
  pageNo: number
  pageSize: number
  sortBy: string
  setSortBy: (sort: string) => void
  setPageNo: (page: number) => void
  setPageSize: (size: number) => void
}

export const useAuditlogFilterStore = create<AuditlogFilterTypeStore>()(
  persist(
    (set) => ({
      search: '',
      sortBy: 'UserId',
      pageNo: 1,
      isSearched: false,
      pageSize: defaultPageSize,
      setSortBy: (sort: string) => set(() => ({ sortBy: sort })),
      setPageNo: (page: number) => set(() => ({ pageNo: page })),
      setPageSize: (size: number) => set(() => ({ pageSize: size })),
      clearFilters: () =>
        set(() => ({
          search: '',
          sortBy: 'UserId',
          pageNo: 1,
          isSearched: false,
          pageSize: defaultPageSize
        })),
      resetFilters: () =>
        set(() => ({
          search: '',
          department: { name: '', id: null },
          sortBy: 'UserId',
          pageNo: 1,
          pageSize: defaultPageSize
        }))
    }),
    {
      name: '!&22_@aud___log__#rc__#ch$',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
)

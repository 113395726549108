import { z } from 'zod'

export const CrewDocumentTypeSchema = z

  .object({
    crewDocCategoryName: z
      .string({ required_error: 'Document Category is required' })
      .min(1, 'Document Category is required'),
    crewDocCategoryId: z.number(),
    crewDocTypeName: z
      .string({ required_error: 'Document Type Name is required' })
      .min(1, 'Document Type Name is required')
      .max(100, 'Document Type Name must be 100 characters or less'),

    renewalDays: z.union([z.number(), z.string()]).optional().nullable(),

    reportToRankName: z.string().optional().nullable(),
    renewalRequired: z.boolean().optional().nullable(),
    documentNumberRequired: z.boolean().optional().nullable(),
    archived: z.boolean().optional().nullable()
  })
  .superRefine((data, ctx) => {
    if (data.renewalRequired) {
      if (!data.renewalDays) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['renewalDays'],
          message: 'Renewal Days is required when renewal is required'
        })
      }
    }
  })

export type CrewingDocumentDataType = z.infer<typeof CrewDocumentTypeSchema>

export const CrewDocumentTypeConfigSchema = z
  .object({
    crewDocTypeId: z.number(),

    countryId: z
      .number({ required_error: 'Nationality is required' })
      .min(1, 'Nationality is required'),

    countryName: z
      .string({ required_error: 'Country Name is required' })
      .min(1, 'Country Name is required'),

    renewalDays: z.union([z.number(), z.string()]).optional().nullable(),

    documentNumberRequired: z.boolean().optional().nullable(),
    renewalRequired: z.boolean().optional().nullable(),
    archived: z.boolean().optional().nullable()
  })
  .superRefine((data, ctx) => {
    if (data.renewalRequired) {
      if (!data.renewalDays) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['renewalDays'],
          message: 'Renewal Days is required when renewal is required'
        })
      }
    }
  })

export type CrewingDocumentConfigDataType = z.infer<typeof CrewDocumentTypeConfigSchema>

import { ExpandableToolTip } from 'react-core-ts'
import CustomIcons from '../../icons/customIcons'

type ColProps = {
  col: {
    title: string
    field: string
    type?: string
    info?: string
    align?: 'right' | 'left' | 'center'
    icon?: string
    tooltip?: boolean
    render?: (row: any) => any
  }
  rowData: any
  lastChild?: boolean
  index?: number
}
export default function DataColumnHead({ col, rowData }: ColProps) {
  const handleCol = () => {
    switch (col.type) {
      case 'boolean':
        return rowData[col.field] ? 'Yes' : 'No'
      case 'archive':
        return rowData[col.field] ? 'Yes' : ''
      default:
        return rowData[col.field]
    }
  }

  return (
    <div className="flex flex-col gap-3  text-left">
      <div>
        <p className="leading-[18px] flex">
          {col.type !== 'archive' ? col.title : rowData[col.field] ? col.title : ''}
          {col.info && (
            <div className="bottom h-auto">
              <div className="pl-1 text-[#000] tooltip-container">
                <ExpandableToolTip title={col.info} enabled>
                  <CustomIcons name="alert" type="medium" />
                </ExpandableToolTip>
                {/* <span className="tooltip tooltip-card">{col.info}</span> */}
              </div>
            </div>
          )}
        </p>
      </div>

      <div key={col.title} className={``}>
        <p
          title={handleCol()}
          className={`text-text-primary font-medium  text-common capitalize truncate leading-5 w-[170px]`}
        >
          <span>{handleCol() ? handleCol() : '-'}</span>
        </p>
      </div>
    </div>
  )
}

const GET_VESSEL_LIST_MIPS = '/Vessel/SearchVessel'
const GET_VESSEL_DETAILS = '/v1/Vessels/GetById/'
const VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS = '/VesselTechInfo'
const COMMUNICATION_DETAILS_MIPS = '/VesselCommInfo/SearchVesselCommInfo'
const COMMUNICATION_DELETE_MIPS = '/VesselCommInfo/'
const CREATE_COMMUNICATION_MIPS = '/VesselCommInfo/Create'
const COMMUNICATION_UPDATE_MIPS = '/VesselCommInfo/Update'
const GET_COMMUNICATION = 'VesselCommInfo/GetById/'
const GET_COMMUNICATION_MIPS = '/VesselCommInfo/GetById/'
const CONTACT_DETAILS_MIPS = '/VesselContactInfo/Search'
const CONTACT_DELETE_MIPS = '/VesselContactInfo/'
const CREATE_CONTACT_MIPS = '/VesselContactInfo/Create'
const UPDATE_CONTACT_MIPS = '/VesselContactInfo/Update'
const GENERAL_INFO = '/v1/VesselGenInfo/GetGeneralInfo/'
const GENERAL_INFO_MIPS = 'VesselGeneralInfo'
const MANAGEMENT_INFO_UPDATE = '/v1/VesselMgmtInfo/Update/'
const MANAGEMENT_INFO = '/v1/VesselMgmtInfo/GetManagementInfo/'
const VESSEL_ANTIFOULING_MIPS = 'VesselAntiFouling'
const CREATE_TANK = '/v1/VesselTank/Create'
const UPDATE_TANK = '/v1/VesselTank/Update'
const DELETE_TANK = '/v1/VesselTank/Delete/'
const GET_TANK_TYPE_DETAILS = '/v1/VesselTank/GetTankType/'
const LIST_TANKS = '/v1/VesselTank/Search'
const GET_OIL_PRESENT_IN_TANK = '/v1/VesselTank/GetTankOilVolume'
const GET_OIL_TYPES_IN_TANK = '/v1/VesselTank/GetTankOilTypes'
const UPDATE_OIL_TYPES_IN_TANK = '/v1/VesselTank/UpdateTankOilTypes'
const EQUIPMENT_CONFIG_DETAILS = '/v1/VesselEquipmentConfig/SearchEquipConfig/'
const EQUIPMENT_REMOVE_VESSEL = '/v1/VesselEquipmentConfig/DeleteManyEquipConfig'
const EQUIPMENT_COPY_TO_VESSSEL = '/v1/VesselEquipmentConfig/CopyEquipConfig'
const SEA_TRIAL_MIPS = 'VesselSeaTrial'
const SEA_TRIAL_DETAILS_MIPS = 'VesselSeaTrial/GetSeaTrialInfo/'
const SEA_TRIAL_ITEMS_MIPS = 'VesselSeaTrial/GetSeaTrialItems/'
const VESSEL_EQUIPMENT_TYPES = '/v1/VesselEquipmentConfig/SearchEquipTypes'
const VESSEL_OIL_TYPES = '/v1/VesselTechInfo/SearchOilTypes'
const VESSEL_CONSUMPTION_TYPES = '/v1/VesselTechInfo/SearchConsTypes'
const ADD_EQUIPMENT_CONFIG = '/v1/VesselEquipmentConfig/AddEquipConfig'
const UPDATE_EQUIPMENT_CONFIG = '/v1/VesselEquipmentConfig/UpdateEquipConfig'
const GET_EQUIPMENT_CONFIG = '/v1/VesselEquipmentConfig/GetEquipConfig/'
const DELETE_EQUIPMENT_CONFIG = '/v1/VesselEquipmentConfig/DeleteEquipConfig'
const SEA_TRIAL_ITEM_INFO_MIPS = 'VesselSeaTrial/GetItemById/'


const GET_VESSEL_INSPECTION = '/v1/Inspection'
const GET_VESSEL_INSPECTION_MIPS = 'VesselInspection'
const VESSEL_INSPECTION_FINDINGS_MIPS = 'InspectionFindings'
const VESSEL_INSPECTION_DOCUMENTS_DETAILS = '/v1/Inspection/GetDocs'
const VESSEL_INSPECTION_AUDIT_LOG = '/v1/Inspection/GetAuditLog'
const GET_VESSEL_PARTICULAR_AUDIT_LOG = '/v1/VesselGenInfo/GetAuditLog'
const GET_VESSEL_PARTICULAR_AUDIT_LOG_MIPS = 'Vessel/GetAuditLog'
const GET_INSPECTION_TYPE_DETAILS = '/v1/Inspection/GetInspectionType?id='
const INSPECTION_GENERAL_INFO_UPDATE = '/v1/Inspection/Update'
const GET_VI_FDETAILS = 'v1/InspFinding'
const VESSEL_INSPECTION_FINDING_UPDATE = '/v1/InspFinding/Update'
const VESSEL_INSPECTION_FINDING_CREATE = '/v1/InspFinding/Create'
const VESSEL_INSPECTION_FINDINGACTION_SEARCH_MIPS = 'InspectionFindingAction/Search'
const VESSEL_INSPECTION_FINDINGACTION_CREATE_MIPS = 'InspectionFindingAction/Create'
const VESSEL_INSPECTION_FINDING_AUDITLOG = '/v1/InspFinding/GetAuditLog'
const VESSEL_INSPECTION_FINDING_DOCUMENTS = '/v1/Inspection/GetDocs'
const VESSEL_INSPECTION_FINDING_DOCUMENTS_DELETE = 'v1/Inspection/DeleteDoc?DocId='
const VESSEL_INSPECTION_FINDING_ACTION = '/v1/InspFindingAction'
const VESSEL_INSPECTION_GET_DOCS = 'v1/Inspection/GetDocFile?DocId='

const GET_VESSEL_BUDGET = '/v1/Budget'
const GET_BUDGET = '/VesselBudgets'
const GET_PENDING_BUDGET_ACCOUNTS = '/v1/Budget/GetAccountsToAdd'
const PERMISSION_URL = '/v1/isAllowed'
const GET_BUDGET_STATUS_HISTORY = '/V1/Budget/GetStatusLog?id='
const BUDGET_ASSUMPTION = '/v1/Budget/SearchAssumption'
const BUDGET_REMARKUPDATE = '/v1/Budget/UpdateRemark'
const GET_VESSEL_BUDGET_DETAILS = 'v1/Budget/GetSummary'
const GET_VESSEL_BUDGET_ENTITY_DETAILS = 'VesselBudgets/GetEntitySummary'
const GET_VESSEL_BUDGET_TREE = '/VesselBudgets/ListBudgetAccounts'
const ADJUST_BUDGET_AMOUNT = '/VesselBudgets/AdjustBUdgetAmount'

const GET_VESSEL_BUDGET_FINAL = 'v1/Budget/ListBudgetItems?Id='
const GET_VESSEL_BUDGET_AUDITLOG = 'v1/Budget/GetAuditLog'
const BUDGET_ASSUMPTION_CREATE = 'v1/Budget/CreateAssumption'
const BUDGET_SUB_ENTITIES = '/v1/Budget/GetSubEntities'
const GET_BUDGET_YEARS = 'VesselBudgets/GetBudgetYears'
const CREATE_BUDGET = 'v1/Budget/Create'
const ENGIN_TYPES = 'v1/EngineTypes'
const COMMN_TYPES = 'v1/CommTypes'
const COMMN_TYPES_MIPS = 'CommunicationTypes'
const VESSEL_ROLES = 'v1/VesselRoles'
const HULL_TYPES = 'v1/HullTypes'
const TANK_HEATING_TYPES = 'v1/TankHeatingType'
const FINDING_CAUSES = 'v1/FindingCause'
const FINDING_CATEGORY = 'v1/FindCategories'
const PAINT_TYPE = 'v1/PaintType'
const BUDGET_TYPE = 'v1/BudgetTypes'
const STEAM_COIL_TYPE = 'v1/SteamCoilType'
const PSC_MOUS = 'v1/PscMou'
const SAFETY_INDICATOR = 'v1/SafetyIndicator'
const ASSESMENT_AREAS = 'v1/AssessmentAreas'
const PSC_ACTIONS = 'v1/PscAction'
const PSC_DEFICIENCY = 'v1/PscDeficiency'

const GET_REG_SEATRIAL_LOAD = '/v1/SeaTrialItem/Search'
const GET_REG_SEATRIAL_LOAD_BYID = 'v1/SeaTrialItem/GetById?Id='
const DELETE_REG_SEATRIAL_LOAD = 'v1/SeaTrialItem/Delete?Id='
const CREATE_REG_SEATRIAL_LOAD = 'v1/SeaTrialItem/Create'
const UPDATE_REG_SEATRIAL_LOAD = 'v1/SeaTrialItem/Update'

const GET_SYSTEM_AREA = '/v1/SystemArea/Search'
const GET_SYSTEM_AREA_BYID = 'v1/SystemArea/GetById?Id='
const DELETE_SYSTEM_AREA = 'v1/SystemArea/Delete?Id='
const CREATE_SYSTEM_AREA = '/v1/SystemArea/Create'
const UPDATE_SYSTEM_AREA = 'v1/SystemArea/Update'

const GET_INSP_TYPE = 'v1/InspectionType/Search'
const GET_INSP_TYPE_BYID = 'v1/InspectionType/GetById?Id='
const DELETE_INSP_TYPE = 'v1/InspectionType/Delete?Id='
const CREATE_INSP_TYPE = 'v1/InspectionType/Create'
const UPDATE_INSP_TYPE = 'v1/InspectionType/Update'

const apiTechnicalUrl = {
  GET_VESSEL_LIST_MIPS,
  GET_VESSEL_DETAILS,
  VESSEL_PARTICULAR_TECHNICAL_TAB_MIPS,
  COMMUNICATION_DETAILS_MIPS,
  COMMUNICATION_DELETE_MIPS,
  CREATE_COMMUNICATION_MIPS,
  COMMUNICATION_UPDATE_MIPS,
  GET_COMMUNICATION,
  GET_COMMUNICATION_MIPS,
  GENERAL_INFO,
  GENERAL_INFO_MIPS,
  MANAGEMENT_INFO,
  MANAGEMENT_INFO_UPDATE,
  CONTACT_DETAILS_MIPS,
  CONTACT_DELETE_MIPS,
  CREATE_CONTACT_MIPS,
  UPDATE_CONTACT_MIPS,
  VESSEL_ANTIFOULING_MIPS,
  CREATE_TANK,
  LIST_TANKS,
  UPDATE_TANK,
  DELETE_TANK,
  PERMISSION_URL,
  GET_TANK_TYPE_DETAILS,
  GET_OIL_PRESENT_IN_TANK,
  GET_OIL_TYPES_IN_TANK,
  UPDATE_OIL_TYPES_IN_TANK,
  EQUIPMENT_CONFIG_DETAILS,
  EQUIPMENT_REMOVE_VESSEL,
  EQUIPMENT_COPY_TO_VESSSEL,
  VESSEL_EQUIPMENT_TYPES,
  SEA_TRIAL_DETAILS_MIPS,
  SEA_TRIAL_ITEMS_MIPS,
  SEA_TRIAL_MIPS,
  VESSEL_OIL_TYPES,
  VESSEL_CONSUMPTION_TYPES,
  ADD_EQUIPMENT_CONFIG,
  UPDATE_EQUIPMENT_CONFIG,
  GET_EQUIPMENT_CONFIG,
  DELETE_EQUIPMENT_CONFIG,
  SEA_TRIAL_ITEM_INFO_MIPS,
  GET_VESSEL_INSPECTION,
  GET_VESSEL_INSPECTION_MIPS,
  VESSEL_INSPECTION_DOCUMENTS_DETAILS,
  VESSEL_INSPECTION_AUDIT_LOG,
  GET_VESSEL_PARTICULAR_AUDIT_LOG,
  GET_VESSEL_PARTICULAR_AUDIT_LOG_MIPS,
  GET_INSPECTION_TYPE_DETAILS,
  INSPECTION_GENERAL_INFO_UPDATE,
  GET_VI_FDETAILS,
  VESSEL_INSPECTION_FINDINGS_MIPS,
  VESSEL_INSPECTION_FINDING_UPDATE,
  VESSEL_INSPECTION_FINDING_CREATE,
  VESSEL_INSPECTION_FINDINGACTION_SEARCH_MIPS,
  VESSEL_INSPECTION_FINDINGACTION_CREATE_MIPS,
  VESSEL_INSPECTION_FINDING_AUDITLOG,
  VESSEL_INSPECTION_FINDING_DOCUMENTS,
  VESSEL_INSPECTION_FINDING_DOCUMENTS_DELETE,
  VESSEL_INSPECTION_FINDING_ACTION,
  VESSEL_INSPECTION_GET_DOCS,
  GET_VESSEL_BUDGET,
  GET_BUDGET,
  GET_BUDGET_STATUS_HISTORY,
  BUDGET_ASSUMPTION,
  BUDGET_REMARKUPDATE,
  GET_VESSEL_BUDGET_DETAILS,
  GET_VESSEL_BUDGET_TREE,
  GET_VESSEL_BUDGET_FINAL,
  GET_VESSEL_BUDGET_AUDITLOG,
  BUDGET_ASSUMPTION_CREATE,
  BUDGET_SUB_ENTITIES,
  GET_BUDGET_YEARS,
  ADJUST_BUDGET_AMOUNT,

  ENGIN_TYPES,
  COMMN_TYPES,
  COMMN_TYPES_MIPS,
  VESSEL_ROLES,
  HULL_TYPES,
  GET_REG_SEATRIAL_LOAD,
  GET_REG_SEATRIAL_LOAD_BYID,
  DELETE_REG_SEATRIAL_LOAD,
  CREATE_REG_SEATRIAL_LOAD,
  UPDATE_REG_SEATRIAL_LOAD,
  GET_SYSTEM_AREA,
  GET_SYSTEM_AREA_BYID,
  DELETE_SYSTEM_AREA,
  CREATE_SYSTEM_AREA,
  UPDATE_SYSTEM_AREA,
  CREATE_BUDGET,
  PAINT_TYPE,
  TANK_HEATING_TYPES,
  FINDING_CAUSES,
  FINDING_CATEGORY,
  BUDGET_TYPE,
  STEAM_COIL_TYPE,
  PSC_MOUS,
  GET_INSP_TYPE,
  GET_INSP_TYPE_BYID,
  DELETE_INSP_TYPE,
  CREATE_INSP_TYPE,
  UPDATE_INSP_TYPE,
  GET_PENDING_BUDGET_ACCOUNTS,
  SAFETY_INDICATOR,
  ASSESMENT_AREAS,
  GET_VESSEL_BUDGET_ENTITY_DETAILS,
  PSC_ACTIONS,
  PSC_DEFICIENCY
}

export default apiTechnicalUrl

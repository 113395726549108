import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { formContainerClass } from '@/styles/variables'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
//import { useTranslation } from 'react-i18next'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { guid } from '@/constants'
import {
  AccountDataType,
  AccountSchema
} from '../../../../pages/vesselBudget/details/budgetdetails/schema'
type FormProps = {
  data: any
  open: boolean
  onClose: () => void
  edit: boolean
  view: boolean
  onSubmit: (data: any) => Promise<any>
  title: string
  refetch: any
  budgetData: any
  selectedNode: any
}
export const ManageAccountWithoutChart: React.FC<FormProps> = ({
  data,
  edit,
  view,
  onSubmit,
  open,
  title,
  onClose,
  refetch
}) => {
  //const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const { showSnackBar } = useCustomSnackBar()
  const initData = () => {
    return {
      ...data,
      Posting: data?.PostingType === 2
    }
  }

  const handleCloseForm = () => {
    setIsSaving(false)
    methods.reset(initData())
    onClose()
  }

  const methods = useForm<AccountDataType>({
    resolver: zodResolver(AccountSchema),
    mode: 'onChange'
    //defaultValues: initData()
  })

  useEffect(() => {
    methods.reset({
      ...data,
      Posting: data?.PostingType === 2
    })
  }, [data])

  const formFields: FormBuilderProps[] = [
    {
      label: 'Account Code',
      placeholder: 'Account Code',
      id: 'AccountId',
      name: 'AccountId',
      type: 'text',
      maxLength: 20,
      required: true,
      spacing: 12
    },
    {
      label: 'Account Description',
      placeholder: 'Account Description',
      id: 'AccountDescription',
      required: true,
      name: 'AccountDescription',
      maxLength: 100,
      type: 'text',
      spacing: 12
    },
    {
      label: 'Posting',
      id: 'Posting',
      name: 'Posting',
      disabled: edit,
      type: 'switch'
    }
  ]
  const manageSubmit = (val: AccountDataType) => {
    setIsSaving(true)
    const submissionData = [
      {
        BudgetAccountId: guid,
        AccountDescription: val?.AccountDescription,
        AccountId: val?.AccountId,
        PostingType: val?.Posting ? 2 : 1,
        ChartDetailId: ''
      }
    ]

    onSubmit(edit ? val : submissionData)
      .then((res: any) => {
        handleCloseForm()
        showSnackBar(res)
        refetch()
      })
      .catch((err: any) => {
        showSnackBar(err?.response?.data?.error, 'error')
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  return (
    <DialogModal
      isOpen={open}
      small={true}
      type="sm"
      title={title}
      body={
        <div className="h-full relative w-full">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(manageSubmit)}>
              <div className={formContainerClass}>
                <FormBuilder data={formFields} edit={!view} />
              </div>
            </form>
          </FormProvider>
        </div>
      }
      actionLoader={isSaving}
      onClose={handleCloseForm}
      actionLabel={edit ? 'Update' : view ? undefined : 'Save'}
      onSubmit={methods.handleSubmit(manageSubmit)}
      secondaryAction={handleCloseForm}
      secondaryActionLabel={view ? 'Close' : 'Cancel'}
    />
  )
}

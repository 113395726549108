import { noLeadingSpaces, FindNumbersLength } from '@/utilities'
import { z } from 'zod'

export const generalSchema = z.object({
  yardName: z
    .string({ required_error: 'Yard is Required' })
    .min(1, { message: 'Yard is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  yardId: z
    .string({ required_error: 'Yard is Required' })
    .min(1, { message: 'Yard is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  pandIClubName: z
    .string()
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  pandIClubId: z
    .string()
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  buildOn: z
    .date({ invalid_type_error: 'Date Built is required' })
    .refine((date) => Boolean(date), {
      message: 'Date Built is required'
    }),
  className: z
    .string()
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  classId: z
    .string()
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  callSign: z
    .string()
    .max(10, { message: 'Must contain at most 10 character(s)' })
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  classNo: z
    .string()
    .max(15, { message: 'Must contain at most 15 character(s)' })
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  classNotation: z
    .string()
    .max(150, { message: 'Must contain at most 150 character(s)' })
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  hullNo: z
    .string()
    .max(10, { message: 'Must contain at most 10 character(s)' })
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  officialNo: z
    .union([
      z.string().max(15, { message: 'Official number does not exceed 15 characters' }),
      z.number().refine((value) => FindNumbersLength(value, 15), {
        message: 'Official number does not exceed 15 characters'
      })
    ])
    .nullable(),
  ismStatus: z
    .string()
    .max(15, { message: 'Must contain at most 15 character(s)' })
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  ismStatusRemark: z
    .string()
    .max(150, { message: 'Must contain at most 150 character(s)' })
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  keelLaidOn: z.date().nullable().optional(),
  email: z
    .string()
    .email()
    .max(100, { message: 'Must contain at most 100 character(s)' })
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  smcExpiry: z.date().nullable().optional(),
  docExpiry: z.date().nullable().optional(),
  vesselId: z
    .string()
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  vesselName: z
    .string()
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional()
})

export default GeneralSchema
export type GeneralSchema = z.infer<typeof generalSchema>

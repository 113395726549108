import {
  deleteSystemMipsData,
  getSystemMipsData,
  postSystemMipsData,
  updateSystemMipsData
} from '@/apis/api.helpers'
import apiSystemUrl from '@/apis/system.api.urls'
import { useQuery } from '@tanstack/react-query'

export const fetchRolesData = async (filters: any) => {
  return await postSystemMipsData(`${apiSystemUrl.GET_ROLES_LIST}`, {
    ...filters
  })
}
export const useSystemRolesList = (filters: any, isEnable: boolean) => {
  return useQuery({
    queryKey: ['system-roles-list', filters],
    queryFn: () => fetchRolesData(filters),
    //initialData: false,
    staleTime: 500,
    enabled: isEnable
  })
}

export const deleteSystemRole = (id: string) => {
  return deleteSystemMipsData(`${apiSystemUrl.DELETE_ROLE}${id}`)
}
export const createSystemRole = (data: any) => {
  return postSystemMipsData(apiSystemUrl.CREATE_ROLE, data)
}
export const updateSystemRole = (id: string, data: any) => {
  return updateSystemMipsData(`${apiSystemUrl.UPDATE_ROLE}`, data)
}

export const getRole = (id: string) => {
  return getSystemMipsData(`${apiSystemUrl.GET_ROLE}${id}`)
}

export const getRolePrivilegesReviewData = async (id: string) => {
  return await getSystemMipsData(`${apiSystemUrl.GET_ROLE_PRIVILEGES_REVIEW}${id}`)
}

export const getRolePrivilegeData = async (id: string) => {
  return await getSystemMipsData(`${apiSystemUrl.GET_ROLE_PRIVILEGES_ACCESS}${id}`)
}
export const resetRolePrivileges = (data: any) => {
  return postSystemMipsData(apiSystemUrl.GET_ROLE_PRIVILEGES_RESET, data)
}
export const updateRolePrivilege = (data: any) => {
  return updateSystemMipsData(apiSystemUrl.UPDATE_ROLE_PRIVILEGE, data)
}
export const setRolesPrivileges = (data: any) => {
  return updateSystemMipsData(apiSystemUrl.SET_ROLE_PRIVILEGES, data)
}

export const payrollRouterStore: { [key: string]: string } = {
  //registers start
  DUMMY_LIST: '/payroll/list',
  CREW_WAGES_LIST: '/payroll/wageitems',
  //registers end

  //Main-Page start
  CREW_WAGE_SCALE: '/payroll/wagescale/crewwagescale',
  //Main-Page end

  //details page
  CREW_WAGE_SCALE_DETAILS: '/payroll/wagescale/crewwagescale/:id',
  CREW_WAGE_SCALE_RIVISION: 'rivision',
  CREW_WAGE_SCALE_RIVISION_INDEX: '/:revisionIndex?',
  CREW_WAGE_SCALE_APPLICABLE: 'applicable',
  CREW_WAGE_SCALE_RIVISION_EARNING:
    '/payroll/wagescale/crewwagescale/:id/:revisionNum/:tabId/:itemId'
}

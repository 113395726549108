export const tabData = [
  {
    FunctionName: 'General',
    PathName: 'general-info',
    id: '1'
  },
  {
    FunctionName: 'Technical',
    PathName: 'technical-info',
    id: '2'
  },
  {
    FunctionName: 'Management',
    PathName: 'management',
    id: '3'
  },
  {
    FunctionName: 'Communication',
    PathName: 'communication',
    id: '4'
  },
  {
    FunctionName: 'Contacts',
    PathName: 'contacts',
    id: '5'
  },
  {
    FunctionName: 'Tanks',
    PathName: 'tanks',
    id: '6'
  },
  {
    FunctionName: 'Sea Trials',
    PathName: 'sea-trails',
    id: '7'
  },
  {
    FunctionName: 'Anti-foulings',
    PathName: 'anti-foulings',
    id: '8'
  },
  {
    FunctionName: 'Equipment Config',
    PathName: 'equipment-config',
    id: '9'
  },
  {
    FunctionName: 'Audit Log',
    PathName: 'audit-log',
    id: '10'
  }
]

import { CustomKendoTable } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'
import { defaultPageSize, TableHeight } from '@/constants'
import { LogDetails } from '@/modules/technical/components/app/vesselParticulars/auditLog/LogDetails'
import { useAppContext } from '@/store/context/appContext'
import { differenceInDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { fetchVesselParticularLog } from '../api'

type PageParams = {
  pageNo: number
  ObjectId?: string
  pageSize: number
  sortBy: string
}

interface LogItem {
  id?: string
  logId?: number
  userId?: string
  logDesc?: string
  logDate: string
  category?: string
  isHeader?: boolean
  color?: string
}
const TechnicalParticularAuditLog: React.FC = () => {
  const { vesselID } = useOutletContext<any>()
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<any>()
  const [logDetailsOpen, setLogDetailsOpen] = useState<any>()
  const { setIsCommonLoader } = useAppContext()
  const [logDetails, setLogDetails] = useState<any>()
  const [groupedData, setGroupedData] = useState<LogItem[]>([])
  const groupedItems: LogItem[] = []

  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    pageSize: defaultPageSize,
    sortBy: '',
    ObjectId: vesselID
  })

  const getAuditLog = () => {
    setIsCommonLoader(true)
    fetchVesselParticularLog(params)
      .then((res) => {
        setData(res)
        setIsCommonLoader(false)
      })
      .catch((err) => {
        setIsCommonLoader(false)
      })
  }

  useEffect(() => {
    getAuditLog()
  }, [params])

  useEffect(() => {
    if (data && data?.results) {
      const grouped = groupByCategory(data?.results)
      setGroupedData(grouped)
      setTotal(data.totalRecords)
    }
  }, [data])

  const getLogDetails = (data: any) => {
    setLogDetailsOpen(true)
    setLogDetails(data)
  }

  const closeLogDetails = () => {
    setLogDetailsOpen(false)
    setLogDetails({})
  }

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  const capitalizeAMPM = (timeString: any) => {
    return timeString.replace(/\b(am|pm)\b/i, (match: any) => match.toUpperCase())
  }

  const categorizeDate = (dateString: string): string => {
    const date = new Date(dateString)
    const today = new Date()
    const daysDifference = differenceInDays(today, date)
    if (daysDifference <= 7) {
      return '1'
    } else if (daysDifference <= 30) {
      return '2'
    } else {
      return '3'
    }
  }

  const getInitials = (name: any) => {
    if (!name) return ''
    const cleanedName = name.trim()
    if (cleanedName.includes(' - ')) {
      const parts = cleanedName.split(' - ')
      return parts.map((part: any) => part[0].toUpperCase()).join('')
    }
    const parts = cleanedName.split(' ')
    if (parts.length === 2) {
      return parts.map((part: any) => part[0].toUpperCase()).join('')
    }
    return cleanedName.substring(0, 2).toUpperCase()
  }

  const UserCircleCell: React.FC<{ data: any }> = ({ data }) => {
    const date = new Date(data.logDate)
    const formattedDate = date
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      })
      .replace(/ /g, '-')
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    })

    const finalFormattedTime = capitalizeAMPM(formattedTime)
    return (
      <div className="flex flex-row mt-[5px] mb-[5px] items-center justify-between pl-4">
        <div
          style={{
            display: 'inline-block',
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            backgroundColor: data.color,
            color: 'white',
            textAlign: 'center',
            lineHeight: '36px',
            fontSize: '14px'
          }}
        >
          {getInitials(data?.userId)}
        </div>

        <div
          className="cursor-pointer truncate flex-1"
          style={{ marginLeft: '10px', lineHeight: '36px', fontSize: '14px' }}
          onClick={() => getLogDetails(data)}
        >
          <span className="text-gray-900">{data?.userId}</span>{' '}
          <span className="text-slate-600">{data?.logDesc}</span>
        </div>
        <div className="w-[130px] text-right pr-2">
          <div>{formattedDate}</div>
          <div>{finalFormattedTime}</div>
        </div>
      </div>
    )
  }

  const groupByCategory = (data: LogItem[]): LogItem[] => {
    const categorizedData = {
      '1': [] as LogItem[],
      '2': [] as LogItem[],
      '3': [] as LogItem[]
    }

    data.forEach((item: LogItem) => {
      const category = categorizeDate(item.logDate)
      const color = getRandomColor()
      categorizedData[category as '1' | '2' | '3'].push({ ...item, color })
    })

    if (categorizedData['1'].length > 0) {
      groupedItems.push({
        logId: -1,
        userId: '',
        logDesc: 'Last seven days',
        logDate: '',
        isHeader: true,
        id: 'header-1'
      })
      groupedItems.push(...categorizedData['1'])
    }

    if (categorizedData['2'].length > 0) {
      groupedItems.push({
        logId: -2,
        userId: '',
        logDesc: 'Last thirty days',
        logDate: '',
        isHeader: true,
        id: 'header-2'
      })
      groupedItems.push(...categorizedData['2'])
    }

    if (categorizedData['3'].length > 0) {
      groupedItems.push({
        logId: -3,
        userId: '',
        logDesc: 'Older',
        logDate: '',
        isHeader: true,
        id: 'header-3'
      })
      groupedItems.push(...categorizedData['3'])
    }

    return groupedItems
  }

  const columns = [
    {
      title: 'User',
      field: 'userId',
      sort: true,
      render: (row: LogItem) =>
        row.isHeader ? (
          <div className=" border-none  text-[#3D414A]  flex flex-row items-center h-38 font-normal text-sm bg-[#F8F8F8] h-[38px] pl-4">
            <CustomIcons type={'medium'} name={'down_arrow'} />
            <div className="pl-1">{row.logDesc}</div>
          </div>
        ) : (
          row?.userId && <UserCircleCell data={row} />
        )
    }
  ]

  const createPage = () => {
    return (
      <React.Fragment>
        <div className="auditLogKendo">
          <CustomKendoTable
            key={`auditLog-${new Date().toString()}`}
            pagination={true}
            columns={columns}
            data={groupedData}
            toolBar={true}
            title={'Audit Log'}
            sortable={false}
            resizable={false}
            minHeight="0px"
            tableHeight={`calc(100vh - ${TableHeight.WithHeader})`}
            paginationProps={{
              total: total,
              currentPage: params.pageNo,
              rowsPerPage: params.pageSize,
              onPagination: (row) => {
                setPrams((prev) => ({
                  ...prev,
                  pageNo: row
                }))
              },
              onRowsPerPage: (row) => {
                setPrams((prev) => ({
                  ...prev,
                  pageSize: row,
                  pageNo: 1
                }))
              }
            }}
          />
        </div>
      </React.Fragment>
    )
  }

  return (
    <>
      <div className="auditLogKendoModel">{createPage()}</div>
      {logDetailsOpen && <div className="blur-overlay"></div>}
      <div className="childPop">
        <LogDetails data={logDetails} open={logDetailsOpen} onClose={closeLogDetails} />
      </div>
    </>
  )
}

export default TechnicalParticularAuditLog

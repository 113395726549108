import { SwitchButtonProps } from '@/common/customComponenttypes'
import React, { useState } from 'react'
import CustomIcons from '../icons/customIcons'

const Switch: React.FC<SwitchButtonProps> = ({
  name,
  id,
  label,
  handleChange = undefined,
  disabled = false,
  value,
  isLabelWrap,
  info
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const generateClassName = (type: 'adorement' | 'message'): string => {
    let className = ' '
    switch (type) {
      case 'message':
        className = ' bg-white   text-icon '
        break
      case 'adorement':
        className += 'absolute right-0 py-1 adorement gap-1 flex items-center'
        break
      default:
        break
    }
    return className
  }

  const getPosition = () => {
    return 'bottom'
  }

  return (
    <>
      <div className={`${getPosition()} 'w-full'`}>
        <div className="tooltip-container relative">
          {isHovered && info && (
            <span className="info tooltip absolute px-2 py-3">{info}</span>
          )}
          <div
            className={`${value ? 'labels-switch' : 'labels'} flex items-center label-text ${
              disabled ? 'opacity-70' : ''
            }`}
          >
            <div className="flex items-center justify-center">
              <label className="switch">
                <input
                  type="checkbox"
                  disabled={disabled}
                  name={name}
                  checked={value}
                  data-testid="app-common-switch"
                  id={id}
                  onChange={handleChange}
                />
                <span className="slider"></span>
              </label>
            </div>
            <div className={`${isLabelWrap ? 'contents' : 'truncate'}`}>
              <span
                className={`ml-2 text-[14px] font-[500] ${!isLabelWrap && 'truncate switchLabel'}`}
                title={label}
              >
                {label}
              </span>
            </div>
            {info && (
              <div className={'px-2 '}>
                <div
                  className={`text-label text-icon cursor-pointer relative ${generateClassName(
                    'message'
                  )}`}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CustomIcons name="alert" type="medium" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Switch

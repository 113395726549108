import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import {
  addAssumptionData,
  updateAssumptionData
} from '@/modules/technical/pages/vesselBudget/details/assumptions/api'
import { formContainerClass } from '@/styles/variables'
import { zodResolver } from '@hookform/resolvers/zod'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { guid } from '@/constants'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  BudgetAssemptionDataType,
  BudgetAssemptionSchema
} from '@/modules/technical/pages/vesselBudget/details/assumptions/schema'

type CreateFormProps = {
  open: boolean
  onClose: () => void
  refetch: any
  view: boolean
  data: any
  edit: boolean
  title: string
}

const AddAssumptionForm: React.FC<CreateFormProps> = ({
  open,
  onClose,
  refetch,
  view,
  data,
  edit,
  title
}) => {
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)
  const param = useParams()
 const { showSnackBar } = useCustomSnackBar()
  const initData = () => {
    return { ...data }
  }
  const onSuccessAction = () => {
    onClose()
    refetch()
    methods.reset({})
  }

  const methods = useForm<BudgetAssemptionDataType>({
    resolver: zodResolver(BudgetAssemptionSchema),
    mode: 'onChange',
    defaultValues: initData()
  })

  useEffect(() => {
    methods.reset({ ...initData() })
  }, [data])

  const handleCloseForm = () => {
    onClose()
    methods.reset({})
  }
  const onSubmit = (res: BudgetAssemptionDataType) => {
    const api = edit ? updateAssumptionData : addAssumptionData
    const payload = {
      ...res,
      BudgetId: param.budgetID,
      BudAssumptionId: edit ? data.BudAssumptionId : guid
    }
    api(payload)
      .then((res) => {
        setIsSaving(false)
        onSuccessAction()
        showSnackBar(res)
      })
      .catch((err) => {
        setIsSaving(false)
        showSnackBar(err?.response?.data?.error, 'error')
      })
    setIsSaving(true)
  }

  const formFields: FormBuilderProps[] = [
    {
      label: t('Title'),
      placeholder: t('Title'),
      name: 'Title',
      id: 'Title',
      type: 'text',
      required: true,
      maxLength: 50,
      spacing: 12
    },
    {
      label: t('Assumption'),
      placeholder: t('Assumption'),
      name: 'Assumption',
      id: 'Assumption',
      type: 'textarea',
      required: true,
      maxLength: 10,
      spacing: 12
    }
  ]

  return (
    <>
      <DialogModal
        isOpen={open}
        small={false}
        type="sm"
        title={title}
        body={
          <div className="h-full popup-body-height relative w-full ">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={formContainerClass}>
                  <FormBuilder data={formFields} edit={!view} />
                </div>
              </form>
            </FormProvider>
          </div>
        }
        onClose={handleCloseForm}
        actionLabel={view ? undefined : edit ? 'Update' : 'Save'}
        onSubmit={methods.handleSubmit(onSubmit)}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={view ? 'Close' : 'Cancel'}
        actionLoader={isSaving}
      />
    </>
  )
}

export default AddAssumptionForm

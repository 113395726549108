import { PaginationProps, TableProps } from '@/common/customComponenttypes'
import { useEffect, useMemo, useRef, useState } from 'react'

// import useTableColumnWidth from '../../../hooks/useTableColumnWidth'
import Loader from '../loader/PageLoader'
import TableCell from './TableCell'
import TableHead from './TableHead'
import TablePagination from './TablePagination'
import Toolbar from './Toolbar'

export default function CustomTable({
  columns,
  data = [],
  async = true,
  actionDropDown,
  actionDropDownParams,
  singleSelection,
  multiSelection,
  handleSort,
  toolBar,
  pagination,
  paginationProps = {
    total: 0,
    rowsPerPage: 20,
    currentPage: 1
  },
  tableHeight,
  handleMenuActions,
  handleMultiSelect,
  handleSingleSelect,
  primaryAction,
  closeAction,
  defaultSort,
  staticMenus,
  isFetching,
  isLoading = true,
  tableKey,
  checkedKeys: propCheckedKeys,
  rowKey,
  selectedKey,
  adjustWidth = 10,
  workflowParams,
  ...rest
}: TableProps) {
  const tableRef = useRef<HTMLTableElement>(null)
  const [checkedKeys, setCheckedKeys] = useState<any>([])
  // const cellWidth = useTableColumnWidth(
  //   columns,
  //   adjustWidth,
  //   tableRef,
  //   Boolean(actionDropDown),
  //   Boolean(singleSelection || multiSelection)
  // )
  useEffect(() => {
    if (propCheckedKeys) {
      setCheckedKeys(propCheckedKeys ?? ([] as (number | string)[]))
    } else {
      setCheckedKeys([])
    }
  }, [propCheckedKeys])

  const currentTableData = useMemo(() => {
    const firstPageIndex = (paginationProps.currentPage - 1) * paginationProps.rowsPerPage
    const lastPageIndex = firstPageIndex + paginationProps.rowsPerPage
    return data?.slice(firstPageIndex, lastPageIndex)
  }, [data, paginationProps.currentPage, paginationProps.rowsPerPage])
  const mainData = async ? data : currentTableData

  return (
    <div className="relative simple-table">
      {toolBar && (
        <Toolbar
          columns={columns}
          primaryAction={primaryAction}
          closeAction={closeAction}
          {...rest}
          data={mainData}
        />
      )}
      <div
        ref={tableRef}
        className=" shadow-sm  bg-table rounded-sm table-container overflow-x-auto overflow-y-hidden "
        style={{ maxHeight: tableHeight, minHeight: tableHeight, overflowY: 'auto' }}
      >
        <table className="w-full border-0 h-auto inline-table  bg-table min-w-full">
          <TableHead
            defaultSort={defaultSort}
            setCheckedKeys={setCheckedKeys}
            checkedKeys={checkedKeys}
            isFetching={isFetching}
            data={mainData}
            columns={columns}
            tableKey={tableKey}
            handleMultiSelect={handleMultiSelect}
            actionDropDown={actionDropDown}
            handleSort={handleSort}
            // cellWidth={cellWidth}
            singleSelection={singleSelection}
            multiSelection={multiSelection}
            rowKey={rowKey}
          />
          <tbody className="w-full overflow-auto">
            {mainData &&
              mainData.length !== 0 &&
              mainData.map((rowData, index) => (
                <TableCell
                  key={index}
                  staticMenus={staticMenus}
                  index={index}
                  columns={columns}
                  tableRef={tableRef}
                  handleMultiSelect={handleMultiSelect}
                  rowData={rowData}
                  // cellWidth={cellWidth}
                  handleMenuActions={handleMenuActions}
                  actionDropDown={actionDropDown}
                  actionDropDownParams={actionDropDownParams}
                  singleSelection={singleSelection}
                  multiSelection={multiSelection}
                  tableKey={tableKey}
                  workflowParams={workflowParams}
                  setCheckedKeys={setCheckedKeys}
                  checkedKeys={checkedKeys}
                  handleSingleSelect={handleSingleSelect}
                  rowKey={rowKey}
                  selectedKey={selectedKey}
                />
              ))}
          </tbody>
        </table>
        <Loader isActive={isFetching} />
        {!isFetching &&
          !isLoading &&
          (!mainData ||
            (mainData?.length === 0 && (
              <div className="w-full flex flex-col items-center justify-center  p-10 pt-32">
                <div>
                  <img src="/assets/no_records_found.svg" alt="No records found" />
                </div>
                <div className="text-text-more text-common leading-5 font-normal">
                  No Records Found
                </div>
              </div>
            )))}
      </div>
      {pagination && paginationProps?.total !== 0 && (
        <TablePagination paginationProps={paginationProps as PaginationProps} />
      )}
    </div>
  )
}

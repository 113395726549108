import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'

const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/
export const handleSchema = (
  relativeCrewId?: string | null,
  maritalStatusId?: number
) => {
  const KinDetailsSchema = z.object({
    givenName: z
      .string({
        required_error: 'First Name is required',
        invalid_type_error: 'First Name is required'
      })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      }),
    middleName: z
      .string()
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      })
      .optional()
      .nullable(),
    surname: z
      .string({
        required_error: 'Surname is required',
        invalid_type_error: 'Surname is required'
      })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      }),
    relationName: z.string({
      required_error: 'Relationship is required',
      invalid_type_error: 'Relationship is required'
    }),
    relationId: z.number({
      required_error: 'Relationship is required',
      invalid_type_error: 'Relationship is required'
    }),
    nationalityName: z.string({
      required_error: 'Nationality is required',
      invalid_type_error: 'Nationality is required'
    }),
    nationalityId: z.number({
      required_error: 'Nationality is required',
      invalid_type_error: 'Nationality is required'
    }),
    dateOfBirth: z
      .date({
        required_error: 'Date of Birth is required',
        invalid_type_error: 'Date of Birth is required'
      })
      .refine((date) => Boolean(date), {
        message: 'Date of Birth is required'
      }),
    placeOfBirth: z
      .string({
        required_error: 'Place of Birth is required',
        invalid_type_error: 'Place of Birth is required'
      })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      }),
    genderName: z.string({
      required_error: 'Gender is required',
      invalid_type_error: 'Gender is required'
    }),
    genderId: z.number({
      required_error: 'Gender is required',
      invalid_type_error: 'Gender is required'
    }),
    maritalStatusName: z.string({
      required_error: 'Marital Status is required',
      invalid_type_error: 'Marital Status is required'
    }),
    maritalStatusId: z.number({
      required_error: 'Marital Status is required',
      invalid_type_error: 'Marital Status is required'
    }),
    ...(maritalStatusId === 1
      ? {
          weddingDate: z
            .date({
              required_error: 'Wedding Date is required',
              invalid_type_error: 'Wedding Date is required'
            })
            .refine((date) => Boolean(date), {
              message: 'Wedding Date is required'
            })
        }
      : {
          weddingDate: z
            .date({
              required_error: 'Wedding Date is required',
              invalid_type_error: 'Wedding Date is required'
            })
            .refine((date) => Boolean(date), {
              message: 'Wedding Date is required'
            })
            .optional()
            .nullable()
        }),
    mobile: z
      .string({
        required_error: 'Contact Number is required',
        invalid_type_error: 'Contact Number is required'
      })
      .regex(phoneRegex, 'Invalid phone Number!')
      .min(6, 'Phone number should be atleast 6 characters.')
      .max(15, 'The phone number should be a maximum of 15 characters.')
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      }),
    phone: z
      .string()
      .max(15, 'The phone number should be a maximum of 15 characters.')
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed'
      })
      .refine((value) => !value || phoneRegex.test(value), {
        message: 'Invalid phone number!'
      })
      .optional()
      .nullable(),
    emailId: z
      .string({
        required_error: 'Email is required',
        invalid_type_error: 'Email is required'
      })
      .email('Invalid email address')
      .max(100, 'Email must be 100 characters or less'),
    nearestAirportName: z.string({
      required_error: 'Nearest Airport is required',
      invalid_type_error: 'Nearest Airport is required'
    }),
    nearestAirportId: z.number({
      required_error: 'Nearest Airport is required',
      invalid_type_error: 'Nearest Airport is required'
    }),
    alternateAirportName: z.string().optional().nullable(),
    alternateAirportId: z.number().optional().nullable(),
    nok: z.boolean().optional().nullable(),
    ice: z.boolean().optional().nullable(),
    crewNokId: z.string(),
    crewId: z.string(),
    relativeCrewId: z.string().optional().nullable()
  })

  if (relativeCrewId) {
    return KinDetailsSchema.omit({
      surname: true,
      nationalityName: true,
      nationalityId: true,
      dateOfBirth: true,
      placeOfBirth: true,
      genderName: true,
      genderId: true,
      maritalStatusName: true,
      maritalStatusId: true,
      weddingDate: true,
      mobile: true,
      phone: true,
      emailId: true,
      nearestAirportName: true,
      nearestAirportId: true
    })
  } else {
    return KinDetailsSchema.omit({}).superRefine((data, customError) => {
      if (data.maritalStatusId === 1 && data.weddingDate === null) {
        customError.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['weddingDate'],
          message: 'Wedding Date is required'
        })
      }
    })
  }
}

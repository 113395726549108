import { NoRecordsFoundProps } from '@/common/customComponenttypes'
import React from 'react'

const NoRecordsFound: React.FC<NoRecordsFoundProps> = ({ title }) => {
  return (
    <div className="flex-grow flex items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="mb-2.5">
          <img src="/assets/no_records_found.svg" alt="No records found" />
        </div>
        <div className="text-text-more text-common leading-5 font-normal">{title}</div>
      </div>
    </div>
  )
}

export default NoRecordsFound

import { CreateCompany } from '@/components/app/masterData/company/CompanyForm'
import { Button, Fab } from '@/components/common'
import useHasPermission from '@/utilities/checkActionPermission'
import { zodResolver } from '@hookform/resolvers/zod'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'

import { isAllow } from '@/constants'
import { updateCompany } from '../../api'
import { companySchema, CompanySchema } from '../../schema'

const CompanyHeaders: React.FC = () => {
  const { edit, setEdit, data, handleRefresh } = useOutletContext<any>()
  const hasPermission = useHasPermission('Companies', 'master')
  const [isSaving, setIsSaving] = useState(false)
  const methods = useForm<CompanySchema>({
    resolver: zodResolver(companySchema),
    defaultValues: {}
  })
  const handleEdit = async () => {
    const permissionParams = {
      UserAction: isAllow.UPDATE,
      AccessKey: '',
      ID1: ''
    }
    const permission = await hasPermission(permissionParams)
    setEdit(permission)
  }
  const { showSnackBar } = useCustomSnackBar()
  const onSubmit = (data: CompanySchema) => {
    setIsSaving(true)
    updateCompany(data)
      .then((res) => {
        setIsSaving(false)
        showSnackBar(res)
        handleRefresh()
        setEdit(false)
      })
      .catch((err) => {
        setIsSaving(false)
        showSnackBar(err?.response?.data?.error, 'error')
      })
  }
  const resetEdit = () => {
    setEdit(false)
    handleRefresh()
  }
  const getDeFaultValues = (rowData: any) => {
    if (rowData && rowData.CompanyId) {
      return {
        ...rowData
      }
    }
  }
  const getData = (data: any) => {
    methods.reset(getDeFaultValues(data))
  }
  useEffect(() => {
    getData(data)
  }, [data])
  return (
    <div className="flex w-full relative ">
      <FormProvider {...methods}>
        <CreateCompany edit={edit} detail={true} editMode={true} />
      </FormProvider>
      <div className=" absolute bottom-0 right-0 ">
        {!edit ? (
          <Fab
            icontType="large"
            onClick={() => {
              handleEdit()
            }}
            className="fabShadow"
          />
        ) : (
          <div className="flex mt-1">
            <Button
              label={'Update'}
              className="me-2"
              isLoading={isSaving}
              onClick={methods.handleSubmit(onSubmit)}
            />
            <Button
              label={'Cancel'}
              outlined
              onClick={() => {
                resetEdit()
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CompanyHeaders

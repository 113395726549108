import { Columns } from '@/common/customComponenttypes'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import KendoExport, { ExportRef } from '../kendoTable/utils/export'
import useKendoHelper from '../kendoTable/utils/helpers'

type ColumnDef = Columns

type ExportProps = {
  data: any
  columns: ColumnDef[]
  title: string | React.ReactNode
  exportFileName?: string | (() => string)
  paperSize?: string
}

const CustomExport = forwardRef<ExportRef, ExportProps>(
  ({ data, title, columns, exportFileName, paperSize }, ref) => {
    CustomExport.displayName = 'CustomExport'

    const gridRef = useRef<any>(null)
    const exportRef = useRef<ExportRef>(null)
    const { CustomRender } = useKendoHelper({
      columns,
      gridRef
    })

    const download = (slug: string) => {
      exportRef?.current?.download(slug)
    }

    useImperativeHandle(ref, () => ({
      download: download
    }))

    return (
      <>
        <KendoExport
          key={`kendo-export-${new Date().toString()}`}
          data={data}
          columns={columns}
          exportFileName={exportFileName}
          title={title}
          CustomRender={CustomRender}
          ref={exportRef}
          paperSize={paperSize}
        />
      </>
    )
  }
)

export default CustomExport

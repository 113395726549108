import React from 'react'
import { useOutletContext } from 'react-router-dom'

import VesselInspectionGeneralForm from '@/modules/technical/components/app/vesselInspections/general/generalForm'
import { InspectionDetailsContextProps } from '../schema'

const TechnicalInspectionGeneral: React.FC = () => {
  const { data } = useOutletContext<InspectionDetailsContextProps>()
  return (
    <div className="flex w-full gap-4">
      <VesselInspectionGeneralForm data={data} />
    </div>
  )
}

export default TechnicalInspectionGeneral

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const ViewIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9.00221 11.8125C9.88824 11.8125 10.6406 11.5024 11.2594 10.8822C11.8781 10.262 12.1875 9.50882 12.1875 8.62279C12.1875 7.73676 11.8774 6.98438 11.2572 6.36563C10.637 5.74688 9.88383 5.4375 8.99779 5.4375C8.11176 5.4375 7.35938 5.74761 6.74063 6.36784C6.12188 6.98805 5.8125 7.74118 5.8125 8.62721C5.8125 9.51324 6.12261 10.2656 6.74284 10.8844C7.36305 11.5031 8.11618 11.8125 9.00221 11.8125ZM8.99559 10.725C8.41103 10.725 7.91563 10.5204 7.50938 10.1112C7.10313 9.70202 6.9 9.20514 6.9 8.62059C6.9 8.03603 7.10459 7.54063 7.51378 7.13438C7.92298 6.72813 8.41986 6.525 9.00441 6.525C9.58897 6.525 10.0844 6.72959 10.4906 7.13878C10.8969 7.54798 11.1 8.04486 11.1 8.62941C11.1 9.21397 10.8954 9.70938 10.4862 10.1156C10.077 10.5219 9.58014 10.725 8.99559 10.725ZM9 14.25C7.175 14.25 5.525 13.7313 4.05 12.6938C2.575 11.6562 1.475 10.3 0.75 8.625C1.475 6.95 2.575 5.59375 4.05 4.55625C5.525 3.51875 7.175 3 9 3C10.825 3 12.475 3.51875 13.95 4.55625C15.425 5.59375 16.525 6.95 17.25 8.625C16.525 10.3 15.425 11.6562 13.95 12.6938C12.475 13.7313 10.825 14.25 9 14.25ZM8.99683 13.125C10.5114 13.125 11.9031 12.7156 13.1719 11.8969C14.4406 11.0781 15.4063 9.9875 16.0688 8.625C15.4063 7.2625 14.4417 6.17188 13.175 5.35313C11.9084 4.53438 10.5178 4.125 9.00317 4.125C7.48856 4.125 6.09688 4.53438 4.82812 5.35313C3.55938 6.17188 2.5875 7.2625 1.9125 8.625C2.5875 9.9875 3.55832 11.0781 4.82496 11.8969C6.09161 12.7156 7.48223 13.125 8.99683 13.125Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ViewIcon

import Layout from '@/layout'
import { useAuthStore } from '@/store/authStore'
import React from 'react'
type Props = {
  children: React.ReactNode
}
const CommonRoute = ({ children }: Props) => {
  const { authenticated } = useAuthStore()

  return authenticated ? <Layout>{children}</Layout> : <>{children}</>
}
export default CommonRoute

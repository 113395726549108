import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const VesselClassFlagIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <path
      d="M1.5 15C1.67038 15.2374 1.89598 15.4297 2.15732 15.5604C2.41866 15.691 2.70788 15.7561 3 15.75C3.29212 15.7561 3.58134 15.691 3.84268 15.5604C4.10402 15.4297 4.32962 15.2374 4.5 15C4.67038 14.7626 4.89598 14.5703 5.15732 14.4396C5.41866 14.309 5.70788 14.2439 6 14.25C6.29212 14.2439 6.58134 14.309 6.84268 14.4396C7.10402 14.5703 7.32962 14.7626 7.5 15C7.67038 15.2374 7.89598 15.4297 8.15732 15.5604C8.41866 15.691 8.70788 15.7561 9 15.75C9.29212 15.7561 9.58134 15.691 9.84268 15.5604C10.104 15.4297 10.3296 15.2374 10.5 15C10.6704 14.7626 10.896 14.5703 11.1573 14.4396C11.4187 14.309 11.7079 14.2439 12 14.25C12.2921 14.2439 12.5813 14.309 12.8427 14.4396C13.104 14.5703 13.3296 14.7626 13.5 15C13.6704 15.2374 13.896 15.4297 14.1573 15.5604C14.4187 15.691 14.7079 15.7561 15 15.75C15.2921 15.7561 15.5813 15.691 15.8427 15.5604C16.104 15.4297 16.3296 15.2374 16.5 15M3 13.5L2.25 9.75H15.75L14.25 12.75M3.75 9.75V5.25H9.75L12.75 9.75M5.25 5.25V2.25H4.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}

export default VesselClassFlagIcon

import {
  deleteTechnicalData,
  getTechnicalData,
  postTechnicalData,
  postTechnicalMipsData,
  updateTechnicalData,
  updateTechnicalMipsData
} from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useQuery } from '@tanstack/react-query'

export const fetchBudgetData = async (filters: any) => {
  return await postTechnicalData(`${apiTechnicalUrl.GET_VESSEL_BUDGET_DETAILS}`, {
    ...filters
  })
}
export const fetchBudgeEntitytData = async (filters: any) => {
  return await postTechnicalMipsData(
    `${apiTechnicalUrl.GET_VESSEL_BUDGET_ENTITY_DETAILS}`,
    {
      ...filters
    }
  )
}
export const useBudgetList = (filters: any) => {
  return useQuery({
    queryKey: ['vessel-budget-details-new'],
    queryFn: () => fetchBudgetData(filters),
    initialData: false,
    staleTime: 500
  })
}
export const useBudgetEntityList = (filters: any) => {
  return useQuery({
    queryKey: ['vessel-budget-entity-list'],
    queryFn: () => fetchBudgeEntitytData(filters),
    initialData: false,
    staleTime: 500
  })
}

export const getTreeMenu = (func: any) =>
  postTechnicalMipsData(`${apiTechnicalUrl.GET_VESSEL_BUDGET_TREE}`, func)

export const UseVesselBudgetDetailsFinal = (id: string) => {
  const fetchData = async () => {
    return await getTechnicalData(`${apiTechnicalUrl.GET_VESSEL_BUDGET_FINAL}${id}`)
  }

  return useQuery({
    queryKey: ['technical-vessel-budget-detailss', id],
    queryFn: () => fetchData(),
    staleTime: 500,
    enabled: !!id
  })
}
export const adjustBudgetAmount = (data: any) => {
 return updateTechnicalMipsData(`${apiTechnicalUrl.ADJUST_BUDGET_AMOUNT}`, data)
}

export const getAccount = (func: any) =>
  postTechnicalData(`${apiTechnicalUrl.GET_VESSEL_BUDGET}/GetAccountsToAdd`, func)
export const getBudgetAccountDetails = (id: string) =>
  getTechnicalData(
    `${apiTechnicalUrl.GET_VESSEL_BUDGET}/GetBudgetAccount?BudgetAccountId=${id}`
  )

export const deleteAccount = (id: number) =>
  deleteTechnicalData(`${apiTechnicalUrl.GET_VESSEL_BUDGET}/DeleteAccount?Id=${id}`)

export const addAccount = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.GET_VESSEL_BUDGET}/AddAccount`, data)
}

export const updateAccount = (data: any) => {
  return updateTechnicalData(`${apiTechnicalUrl.GET_VESSEL_BUDGET}/UpdateAccount`, data)
}

export const fetchPendingAccountsToAdd = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.GET_PENDING_BUDGET_ACCOUNTS}`, data)
}

import { Radio } from '@/components/common'
import { useState } from 'react'

const RadioComponent = () => {
  const [fruits, setFruits] = useState('Apple')

  const handleChange = (event: any) => {
    setFruits(event.target.value)
  }
  const isChecked = (value: string) => fruits === value
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="py-3">
          <div className="flex p-2">
            <span className="pr-5">
              <Radio
                id="option1"
                name="option1"
                value="Apple"
                handleChange={handleChange}
                label="Apple"
                checked={isChecked('Apple')}
              />
            </span>

            <Radio
              id="option2"
              value="Berries"
              checked={isChecked('Berries')}
              handleChange={handleChange}
              name="option2"
              label="Berries"
            />
          </div>
        </div>
        <div className="py-3">
          <div className="flex p-2">
            <Radio
              id="switchDisabled"
              disabled
              checked
              name="switchDisabled"
              label="Radio Disabled"
            />
          </div>
        </div>
        <div className="py-3">
          <div className="flex p-2">
            <Radio
              id="switchDisabled"
              disabled
              name="switchDisabled"
              label="Radio  Disabled"
            />
          </div>
        </div>
      </div>

      {/* -------------------------------------------------------------------------- */}
    </>
  )
}

export default RadioComponent

import { Checkbox, Radio } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'
import StepperComponent from '@/components/common/stepper'
import { getDashTileByModule, useDashBoradModules } from '@/pages/dashboard/api'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

type Props = {
  currentTiles: any
  activeStep: number
  isOpen: boolean
}
export type TileItemProps = {
  ParentId?: string
  TileId: string
  TileName: string
  Type?: number
  defaultTileType: number
  checked?: boolean
}
export interface ManageTilesRef {
  getSelectedTiles: () => TileItemProps[]
  selectedModule: any
  handlValidation: () => boolean
}
const stepperData = [
  {
    step: 1,
    title: 'Select a module',
    icon: 'module',
    description: 'Select a module to list tiles.'
  },
  {
    step: 2,
    title: 'Select tiles',
    icon: 'layer',
    description: 'Select a Tile to continue'
  },
  { step: 3, title: 'Summary', icon: 'stepperCheck', description: 'Verify and submit' }
]
const ManageTiles = forwardRef<ManageTilesRef, Props>(
  ({ currentTiles, activeStep, isOpen }, ref) => {
    const [selectedModule, setSelectedModule] = useState<any>({ id: '', name: '' })
    const [moduleTiles, setModuleTiles] = useState<TileItemProps[]>([])
    const [moduleTabs, setModuleTabs] = useState<TileItemProps[]>([])
    const [defaultModule, setDefaultModule] = useState<any>()
    // const [selectedTab, setSelectedTab] = useState<string>('')
    // const [activeTiles, setActiveTiles] = useState<TileItemProps[]>([])
    const [selectedTiles, setSelectedTiles] = useState<string[]>([...currentTiles])
    const { data } = useDashBoradModules(isOpen)
    const handleSelectChange = (e: any, item: any) => {
      setSelectedModule(item)
    }
    useEffect(() => {
      const tileIds = currentTiles.map((tile: any) => tile.TileId)
      setSelectedTiles(tileIds)
    }, [currentTiles])

    const fetchTileByModule = (id: string) => {
      if (id && id !== '') {
        getDashTileByModule(id).then((res: TileItemProps[]) => {
          setModuleTiles([
            ...currentTiles,
            ...res.filter((item: TileItemProps) => item.Type === 7)
          ])
          setModuleTabs(res.filter((item: TileItemProps) => item.Type === 6))
        })
      }
    }

    useEffect(() => {
      if (selectedModule?.id && selectedModule?.id !== '') {
        fetchTileByModule(selectedModule?.id)
      }
    }, [selectedModule?.id])

    useEffect(() => {
      if (moduleTabs?.length > 0) {
        setDefaultModule(moduleTabs[0]?.TileId)
      } else {
        setDefaultModule(undefined)
      }
    }, [moduleTabs])

    ManageTiles.displayName = 'ManageTiles'

    const handleSelectionChange = (val: any, id: string) => {
      let selection = [...selectedTiles]

      if (val) {
        if (!selection.includes(id)) {
          selection.push(id)
        }
      } else {
        selection = selection.filter((item) => item !== id)
      }
      setSelectedTiles(selection)
    }

    const { isMobile, isTab } = useScreenBreakPoint()
    const prevTiles = currentTiles?.map((item: any) => item.TileId)

    const handlValidation = () => {
      return (
        moduleTabs?.some((tabs) => {
          const hasMatchingTile = moduleTiles?.some((filt) => {
            const isSelected = selectedTiles?.includes(filt.TileId)
            const isPrevSelected = prevTiles?.includes(filt.TileId)
            return filt.ParentId === tabs.TileId && isSelected !== isPrevSelected
          })
          return hasMatchingTile
        }) ?? false
      )
    }
    useImperativeHandle(ref, () => ({
      selectedModule: selectedModule,
      selectedTiles: selectedTiles,
      handlValidation: handlValidation,
      getSelectedTiles: () => {
        const uniqueIds = new Set()

        const filteredAndDedupedTiles = moduleTiles?.filter((item) => {
          if (selectedTiles?.includes(item.TileId) && !uniqueIds.has(item.TileId)) {
            uniqueIds.add(item.TileId)
            return true
          }
          return false
        })

        return filteredAndDedupedTiles
      }
    }))
    return (
      <div className="flex flex-col gap-2">
        <StepperComponent data={stepperData} activeStep={activeStep} />
        <div
          style={{ minHeight: 'calc(100vh - 600px)' }}
          className={`  ${activeStep === 1 && ' pt-[30px] '} ${
            activeStep !== 3 && 'border-t-2'
          } `}
        >
          {activeStep === 1 && (
            <div
              className={` ${
                isMobile || isTab ? 'grid grid-cols-2' : 'grid grid-cols-4'
              } gap-2.5 text-common `}
            >
              {data?.map((item: any, index: number) => (
                <div
                  key={index}
                  className={`${
                    item?.id === selectedModule?.id &&
                    'bg-text-primary/5 text-text-primary'
                  } flex items-center h-[40px] hover:bg-text-primary/5 `}
                  // onClick={handleSelectChange}
                >
                  <Radio
                    id={item.id}
                    name={item.name}
                    value={item.id}
                    checked={item?.id === selectedModule?.id}
                    customLabel
                    handleChange={(e) => handleSelectChange(e, item)}
                  />

                  <CustomIcons
                    name="module"
                    type="large-m"
                    className=" text-text-primary mr-[2px]"
                  />
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          )}
          {activeStep === 2 && (
            <div className={`flex justify-between w-full`}>
              <div className="w-2/6 text-common">
                <div className="flex gap-0.5 h-[40px] p-[13px] text-white bg-secondary items-center">
                  <CustomIcons name="module" type="medium" className=" " />
                  <span>{selectedModule?.name}</span>
                </div>
                {moduleTabs?.map((item: any) => (
                  <div
                    className={`h-[40px]  flex  justify-between cursor-pointer gap-0.5 border border-[#EAECF0] items-center p-[12px] pl-[22px] border-l-0 ${
                      defaultModule === item.TileId
                        ? 'text-text-primary'
                        : ' text-text-secondary'
                    } `}
                    key={item?.TileId}
                    onClick={() => setDefaultModule(item.TileId)}
                  >
                    <div className="flex items-center gap-0.5 ">
                      <CustomIcons name="side-menu-list" type="medium" />
                      <span> {item.TileName}</span>
                    </div>
                    <CustomIcons name="next-arrow" type="large-m" className="" />
                  </div>
                ))}
              </div>
              <div
                className={`w-4/6 p-2.5 grid gap-[11px] bg-[#EAEFF4] bg-opacity-30 ${
                  isMobile || isTab ? 'grid-cols-1' : 'grid-cols-2'
                } `}
              >
                {defaultModule &&
                  moduleTiles
                    ?.filter((filt) => filt.ParentId === defaultModule)
                    ?.map((item: any, index: number) => (
                      <div
                        key={index}
                        className={`${
                          selectedTiles?.includes(item.TileId) &&
                          'bg-[#EBF0F4] text-text-primary'
                        } flex items-center text-common h-[40px] cursor-pointer hover:bg-[#EBF0F4] p-2.5 `}
                      >
                        <Checkbox
                          id={item.TileId}
                          name={item.TileName}
                          checked={selectedTiles?.includes(item.TileId)}
                          handleChange={(e) =>
                            handleSelectionChange(e.target.checked, item?.TileId)
                          }
                        />

                        <CustomIcons
                          name="layer"
                          type="large-m"
                          className=" text-text-primary mr-[2px]"
                        />
                        <span>{item.TileName}</span>
                      </div>
                    ))}
              </div>
            </div>
          )}
          {activeStep === 3 && (
            <div className={`  text-common flex flex-col gap-[18px] `}>
              {moduleTabs?.map(
                (modules: any) =>
                  moduleTiles?.filter(
                    (filt) =>
                      (filt.ParentId === modules.TileId &&
                        selectedTiles?.includes(filt.TileId)) ||
                      prevTiles?.includes(filt.TileId)
                  ).length > 0 && (
                    <>
                      <div key={modules?.TileId} className="flex items-center">
                        <span className=" whitespace-nowrap p-2 font-medium text-text-primary">
                          {modules?.TileName}
                        </span>
                        <span className=" border-t-2 w-full"></span>
                      </div>
                      <div className={'flex flex-wrap gap-2.5'}>
                        {moduleTiles
                          ?.filter(
                            (filt) =>
                              filt.ParentId === modules.TileId &&
                              selectedTiles?.includes(filt.TileId)
                          )
                          ?.map((item: any, index: number) => (
                            <div
                              key={index}
                              className=" inline-flex items-center p-[5px] pl-2.5 hover:bg-text-primary/5 justify-between rounded-sm gap-1 border-2 border-[#077A63] "
                              // onClick={handleSelectChange}
                            >
                              <div className=" flex items-center gap-0.5">
                                <CustomIcons
                                  name="checkCircle"
                                  type="common"
                                  viewBox
                                  className=" mr-[2px] bg-[#077A63] rounded-full p-0.5 "
                                />

                                <span>{item.TileName}</span>
                              </div>
                              <span className="bg-[#EAEFF4] rounded-full p-1">
                                <CustomIcons
                                  name="close"
                                  onClick={() =>
                                    handleSelectionChange(false, item.TileId)
                                  }
                                  type="common"
                                  className=" bg-[#EAEFF4] rounded-full cursor-pointer"
                                />
                              </span>
                            </div>
                          ))}

                        {moduleTiles
                          ?.filter(
                            (filt: any) =>
                              filt.ParentId === modules.TileId &&
                              !selectedTiles?.includes(filt.TileId) &&
                              prevTiles?.includes(filt.TileId)
                          )
                          ?.map((item: any, index: number) => (
                            <div
                              key={index}
                              className=" inline-flex items-center p-[5px] pl-2.5 hover:bg-text-primary/5 justify-between rounded-sm gap-1 border-2 border-[#E55353] "
                              // onClick={handleSelectChange}
                            >
                              <div className=" flex items-center gap-0.5">
                                <CustomIcons
                                  name="indeterminate"
                                  type="common"
                                  className=" mr-[2px] bg-[#E55353] text-white rounded-full p-0.5 "
                                />

                                <span>{item.TileName}</span>
                              </div>
                              <span className="bg-[#EAEFF4] text-[#D0D5DD] rounded-full p-1">
                                <CustomIcons
                                  name="close"
                                  type="common"
                                  className=" bg-[#EAEFF4] rounded-full cursor-pointer"
                                />
                              </span>
                            </div>
                          ))}
                      </div>
                    </>
                  )
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
)

export default ManageTiles

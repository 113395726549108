import React from 'react'
import Button from '../buttons/Button'
import CustomIcons from '../icons/customIcons'

// Assuming you have a separate CSS file for styles

type DrawerProps = {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
  title: string | any[]
  currentTab?: string
  setCurrentTab: any
  primaryAction?: {
    label: string
    action: () => void
  }
}

const SideDrawer: React.FC<DrawerProps> = ({
  isOpen,
  title,
  onClose,
  children,
  currentTab,
  setCurrentTab,
  primaryAction
}) => {
  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={onClose}></div>
      <div className="drawer z-50">
        <div className="drawer-header">
          {typeof title !== 'string' ? (
            <div className="drawer-tabs">
              {title?.map((item) => (
                <div
                  key={item.slug}
                  onClick={() => setCurrentTab(item.slug)}
                  className={`drawer-tab ${
                    item?.slug === currentTab ? 'drawer-active tab-border' : ''
                  } `}
                >
                  {item.title}
                </div>
              ))}
            </div>
          ) : (
            <div className="drawer-tab">{title}</div>
          )}

          <button
            type="button"
            className="close-button text-gray-400 hover:bg-gray-200 rounded-full  dark:hover:bg-gray-600 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
            data-modal-hide="popup-modal"
            onClick={onClose}
            data-testid="close-icon"
          >
            <CustomIcons
              name="close"
              className="flex items-center justify-center   "
              data-testid="button-icon-left"
            />
          </button>
        </div>
        <div className="drawer-content overflow-y-auto ">{children}</div>
        {primaryAction && (
          <div className="absolute bottom-4">
            <Button
              label={primaryAction.label}
              onClick={primaryAction.action}
              primary={false}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default SideDrawer

import { FormBuilderProps } from '@/common/customComponenttypes'
import { Button, Fab, FormBuilder } from '@/components/common'
import {
  getDimensionData,
  updateDimensionData
} from '@/modules/technical/pages/vesselParticulars/details/technical/api'
import {
  dimensionFormSchema,
  dimensionFormSchemaType
} from '@/modules/technical/pages/vesselParticulars/details/technical/schema'
import { formContainerClass } from '@/styles/variables'
import useCustomSnackBar from '@/utilities/customSnackBar'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type DimensionsFormProps = {
  vesselID: string
  setEdit: (status: boolean) => void
  edit: boolean
  handleEdit: () => Promise<void>
  setWarningOpen: (value: React.SetStateAction<boolean>) => void
}

const DimensionsForm: React.FC<DimensionsFormProps> = ({
  vesselID,
  setEdit,
  edit,
  handleEdit,
  setWarningOpen
}) => {
  const { t } = useTranslation()
  const [actionLoader, setActionLoader] = useState<boolean>(false)
  const [rowData, setRowData] = useState<any>({})
  const { showSnackBar } = useCustomSnackBar()
  const { width } = useScreenBreakPoint()

  const getData = async () => {
    const data = await getDimensionData(vesselID)
    setRowData(data?.data)
  }
    const generateCommonSpacing = () => {
      if (width > 1200) {
        return 3
      } else if (width > 1000) {
        return 4
      } else if (width > 700) {
        return 6
      }
      return 12
    }
  useEffect(() => {
    getData()
  }, [])

  const initData = () => {
    return {
      ...rowData
    }
  }
  const formFields: FormBuilderProps[] = [
    {
      label: t('Breadth Moulded'),
      name: 'breadthMoulded',
      id: 'breadthMoulded',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Breadth Extreme'),
      name: 'breadthExtreme',
      id: 'breadthExtreme',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Depth Extreme'),
      name: 'depthExtreme',
      id: 'depthExtreme',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Length Over All (LOA)'),
      name: 'loa',
      id: 'loa',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Length Between Perpendiculars(LBP)'),
      name: 'lbp',
      id: 'lbp',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Keel To MastHead'),
      name: 'keelToMastHead',
      id: 'keelToMastHead',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    },
    {
      label: t('Distance Bow to Bridge'),
      name: 'dbToBridge',
      id: 'dbToBridge',
      spacing: generateCommonSpacing(),
      type: 'text',
      adorement: 'm'
    }
  ]

  const onSuccessAction = () => {
    setWarningOpen(false)
    setEdit(false)
  }

  const onSubmit = (res: dimensionFormSchemaType) => {
    setActionLoader(true)
    const api = updateDimensionData
    const payload = {
      ...res,
      vesselId: vesselID
    }

    api(payload)
      .then((res) => {
        showSnackBar(res)
        setRowData(payload)
        onSuccessAction()
      })
      .catch((err) => {
        showSnackBar(err, 'error')
      })
      .finally(() => {
        setActionLoader(false)
      })
  }
  const methods = useForm<dimensionFormSchemaType>({
    resolver: zodResolver(dimensionFormSchema),
    mode: 'onChange',
    defaultValues: initData()
  })
  useEffect(() => {
    methods.reset({ ...rowData })
  }, [rowData])

  const resetEdit = () => {
    setEdit(false)
        methods.reset({ ...rowData })
  }
  return (
    <div className="h-full relative">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={formContainerClass}>
            <FormBuilder data={formFields} edit={edit}  isView={!edit} />
            <div className="py-7">
              <div className="absolute bottom-0 right-0 p-2 py-1">
                {!edit ? (
                  <Fab
                    icontType="large"
                    onClick={() => {
                      handleEdit()
                    }}
                    className="fabShadow"
                  />
                ) : (
                  <div className="flex">
                    <Button
                      label={t('update')}
                      className="me-2"
                      isLoading={actionLoader}
                      onClick={methods.handleSubmit(onSubmit)}
                    />
                    <Button
                      label={t('cancel')}
                      outlined
                      onClick={() => {
                        resetEdit()
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default DimensionsForm

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const HeaderDropArrow: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M0 0H24V24H0V0Z" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_80_52" transform="scale(0.0416667)" />
        </pattern>
        <image
          id="image0_80_52"
          width="24"
          height="24"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAZklEQVR4nO2RQQ6AIAwEh5fxnB79itx4rTUmcDGIoiVeOufNTAngOM6vBCA+2MWyHZYnYAOWzk7KJo9GjvEKaCdS5fomcBeRr/JeRKzk5//QIq7yZCFvvcTs8qtIniGvhJlyx6HJDg+fH94Sm7UyAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  )
}

export default HeaderDropArrow

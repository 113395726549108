import React from 'react'

const Others = () => {
  return (
    <>
      <rect width="24" height="24" />
      <path
        d="M14.25 17.75C14.45 17.75 14.625 17.675 14.775 17.525C14.925 17.375 15 17.2 15 17C15 16.8 14.925 16.625 14.775 16.475C14.625 16.325 14.45 16.25 14.25 16.25C14.05 16.25 13.875 16.325 13.725 16.475C13.575 16.625 13.5 16.8 13.5 17C13.5 17.2 13.575 17.375 13.725 17.525C13.875 17.675 14.05 17.75 14.25 17.75ZM17 17.75C17.2 17.75 17.375 17.675 17.525 17.525C17.675 17.375 17.75 17.2 17.75 17C17.75 16.8 17.675 16.625 17.525 16.475C17.375 16.325 17.2 16.25 17 16.25C16.8 16.25 16.625 16.325 16.475 16.475C16.325 16.625 16.25 16.8 16.25 17C16.25 17.2 16.325 17.375 16.475 17.525C16.625 17.675 16.8 17.75 17 17.75ZM19.75 17.75C19.95 17.75 20.125 17.675 20.275 17.525C20.425 17.375 20.5 17.2 20.5 17C20.5 16.8 20.425 16.625 20.275 16.475C20.125 16.325 19.95 16.25 19.75 16.25C19.55 16.25 19.375 16.325 19.225 16.475C19.075 16.625 19 16.8 19 17C19 17.2 19.075 17.375 19.225 17.525C19.375 17.675 19.55 17.75 19.75 17.75ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V10.7C19.6833 10.55 19.3583 10.4208 19.025 10.3125C18.6917 10.2042 18.35 10.125 18 10.075V4H4V18H10.05C10.1 18.3667 10.1792 18.7167 10.2875 19.05C10.3958 19.3833 10.525 19.7 10.675 20H4ZM4 17V18V4V10.075V10V17ZM6 16H10.075C10.125 15.65 10.2042 15.3083 10.3125 14.975C10.4208 14.6417 10.5417 14.3167 10.675 14H6V16ZM6 12H12.1C12.6333 11.5 13.2292 11.0833 13.8875 10.75C14.5458 10.4167 15.25 10.1917 16 10.075V10H6V12ZM6 8H16V6H6V8ZM17 22C15.6167 22 14.4375 21.5125 13.4625 20.5375C12.4875 19.5625 12 18.3833 12 17C12 15.6167 12.4875 14.4375 13.4625 13.4625C14.4375 12.4875 15.6167 12 17 12C18.3833 12 19.5625 12.4875 20.5375 13.4625C21.5125 14.4375 22 15.6167 22 17C22 18.3833 21.5125 19.5625 20.5375 20.5375C19.5625 21.5125 18.3833 22 17 22Z"
        fill="currentColor"
      />
    </>
  )
}

export default Others

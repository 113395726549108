import { FormBuilderProps } from '@/common/customComponenttypes'
import { AutoComplete, FormBuilder } from '@/components/common'
import {
  revertStatus,
  revertStatusSchema
} from '@/pages/masterData/workflow/status/schema'
import useDataStore from '@/store/dynamicStore/useDataStore'
import { zodResolver } from '@hookform/resolvers/zod'
// import { useAuthStore } from '@/store/authStore'
import { useEffect, useState } from 'react'
import { ExpandableAutoComplete } from 'react-core-ts'
import { FormProvider, useForm } from 'react-hook-form'
export default function SelectBox() {
  // const { accessToken } = useAuthStore()
  const [value, setValue] = useState<any>()
  const { getData, dataStore } = useDataStore()
  const [selectValue, setSelectValue] = useState<any>()
  const [selectMultiValue, setSelectMultiValue] = useState<any>([])
  const [customSelectValue, setCustomSelectvalue] = useState<any>()
  //! Do not revert this section - for testing purpose ========================
  /*const getData = async (key?: string) => {
    const { data } = await axios.post(`http://localhost:8080/v1/graphql`, {
      query: 'query MyQuery {\n  Countries {\n    CountryId\n    CountryName\n  }\n}\n',
      variables: null,
      operationName: 'MyQuery'
    })

    return data?.data?.Countries?.map((item: any) => {
      return { ...item, name: item.CountryName, id: item.CountryId }
    })
  }*/

  const getCountryList = async () => {
    getData('Countries', { key: 'COUNTRY' }, '*')
  }

  const getCountry = async () => {
    return dataStore.Countries ?? []
  }

  useEffect(() => {
    getCountryList()
  }, [])

  const getVessels = async () => {
    return getData('Vessels', { key: 'VESSELS' }, '*')
  }

  //!! ================================================
  const handleChange = (value: any) => {
    setValue(value)
  }
  const handleSelectChange = (value: any) => {
    setSelectValue(value)
  }
  const handleCustomSelectChange = (value: any) => {
    setCustomSelectvalue(value)
    setSelectMultiValue(value)
  }

  const handleMultiCustomSelectChange = (value: any) => {
    console.log(value)
    //setSelectMultiValue(value)
  }

  const methods = useForm<revertStatus>({
    resolver: zodResolver(revertStatusSchema),
    mode: 'onChange',
    defaultValues: { RevertStatusName: [] }
  })

  const formFields: FormBuilderProps[] = [
    {
      label: 'Select Revert Status',
      name: 'RevertStatusName',
      id: 'RevertStatusId',
      placeholder: 'Select Revert Status',
      type: 'multi_select_expandable',
      apiParams: { key: 'CRWMASTR' },
      /*data: [
        { id: 1, name: 'ttt' },
        { id: 1, name: 'eeee' },
        { id: 1, name: 'rrrr' }
      ],*/
      isMultiple: true,
      spacing: 12,
      desc: 'name',
      descId: 'id'
    }
  ]

  const onSubmit = (data: revertStatus) => {
    console.log(data)
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      <div className="py-3">
        <div className=" p-2">
          <AutoComplete
            onChange={handleChange}
            name="Autocomplete"
            value={value}
            type="auto_complete"
            label="Auto Complete"
            getData={getCountry}
          />
        </div>
      </div>
      <div className="py-3">
        <div className=" p-2">
          <AutoComplete
            onChange={handleChange}
            name="Autocomplete"
            value={value}
            type="auto_complete"
            label="Auto Complete(disabled)"
            disabled
            getData={getCountry}
          />
        </div>
      </div>
      <div className="py-3">
        <div className=" p-2">
          <AutoComplete
            onChange={handleSelectChange}
            name="Search Select"
            value={selectValue}
            type="custom_select"
            label="Select Static Data"
            data={[
              { id: '1', name: 'name' },
              { id: '2', name: 'rajesh' }
            ]}
            placeholder="vessel Name"
            getData={getCountry}
          />
        </div>
      </div>
      <div className="py-3">
        <div className=" p-2">
          <AutoComplete
            onChange={handleCustomSelectChange}
            name="Auto Complete"
            value={customSelectValue}
            type="custom_search_select"
            label="Search and Select Box"
            data={[
              { id: '1', name: 'name' },
              { id: '2', name: 'rajesh' }
            ]}
            placeholder="vessel Name"
            getData={getCountry}
          />
        </div>
      </div>

      <div className="py-3">
        <div className=" p-2">
          <ExpandableAutoComplete
            onChange={handleMultiCustomSelectChange}
            name="Multiselect"
            desc="name"
            descId="id"
            isMultiple={true}
            expandable={true}
            itemCount={5}
            textCount={20}
            initialLoad={true}
            async={false}
            paginationEnabled={false}
            label=""
            type="auto_suggestion"
            selectedItems={selectMultiValue}
            placeholder="vessels"
            getData={getVessels}
          />
        </div>
      </div>

      <div className="py-3">
        <div className=" p-2">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FormBuilder data={formFields} edit={true} />
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  )
}

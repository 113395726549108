import { generateAlignment } from '../../../utilities'
import formatDate from '../../../utilities/usedateformat'
import CustomIcons from '../icons/customIcons'

type ColProps = {
  col: {
    title: string
    field: string
    sort?: boolean
    action?: boolean
    type?: string
    width?: number
    align?: 'right' | 'left' | 'center'
    render?: (row: any, hovered?: boolean) => any
    handleChange?: (e: any) => void
  }
  rowData: any
  isHovered: boolean
  cellWidth?: number | string
}
export default function DataCell({ col, rowData, cellWidth, isHovered }: ColProps) {
  const handleCell = () => {
    switch (col.type) {
      case 'number':
        return (
          <td
            key={col.title}
            style={{ width: col?.width ?? cellWidth, maxWidth: col?.width ?? cellWidth }}
            className={`p-cell text-common text-shadow-light font-normal td-custom `}
          >
            <span className={`${generateAlignment(col.align || 'right')}`}>
              {rowData[col.field]}
            </span>
          </td>
        )
      case 'boolean':
        return (
          <td
            key={col.title}
            style={{ width: col?.width ?? cellWidth, maxWidth: col?.width ?? cellWidth }}
            className={`p-cell text-common text-shadow-light font-normal  td-custom `}
          >
            <span className={`${generateAlignment(col.align || 'center')}`}>
              {rowData[col.field] ? (
                <CustomIcons name="check_mark" type="large" className="text-link" />
              ) : (
                ''
              )}
            </span>
          </td>
        )
      case 'date':
        return (
          <td
            key={col.title}
            style={{ width: col?.width ?? cellWidth }}
            className={`p-cell text-common text-shadow-light font-normal `}
          >
            <span className={`${generateAlignment(col.align || 'center')}`}>
              {formatDate(rowData[col.field])}
            </span>
          </td>
        )
      case 'dateTime':
        return (
          <td
            key={col.title}
            style={{ width: col?.width ?? cellWidth }}
            className={`p-cell text-common text-shadow-light font-normal `}
          >
            <span className={`${generateAlignment(col.align || 'center')}`}>
              {formatDate(rowData[col.field], 'DD-MMM-YYYY hh:mm')}
            </span>
          </td>
        )
      // case 'radio':
      //   return (
      //     <td
      //       key={col.title}
      //       style={{ width: col?.width ?? cellWidth }}
      //       className="p-cell text-xxs text-table-bodyColor font-normal flex items-center justify-center"
      //     >
      //       {rowData[col.field] ? (
      //         <Radio
      //           id={`${col.field}-${rowData[col.field]}`}
      //           name={`${col.field}`}
      //           value={rowData[col.field]}
      //           handleChange={handleRadioChange}
      //         />
      //       ) : (
      //         ''
      //       )}
      //     </td>
      //   )
      // case 'currency':
      //   return (
      //     <td
      //       key={col.title}
      //       className="p-cell text-xxs text-table-bodyColor font-normal"
      //     >
      //       {rowData[col.field]}
      //     </td>
      //   )
      case 'status':
        return (
          <td
            key={col.title}
            style={{ width: col?.width ?? cellWidth, maxWidth: col?.width ?? cellWidth }}
            className={`p-cell text-common text-shadow-light font-normal td-custom `}
          >
            <span
              className={`${generateAlignment(
                col.align
              )} text-status bg-statusBg p-[7px] max-w-fit rounded-[3px]`}
            >
              {rowData[col.field]}
            </span>
          </td>
        )
      default:
        return (
          <td
            key={col.title}
            style={{ width: col?.width ?? cellWidth, maxWidth: col?.width ?? cellWidth }}
            className={`p-cell text-common text-shadow-light font-normal  `}
          >
            <span
              className={`${generateAlignment(
                col.align
              )} whitespace-nowrap overflow-hidden truncate `}
              style={{ maxWidth: col?.width ?? cellWidth }}
            >
              {rowData[col.field]}
            </span>
          </td>
        )
    }
  }
  const renderFunc = col.render && col.render
  return (
    <>
      {renderFunc && (
        <td
          key={col.title}
          style={{ width: col?.width ?? cellWidth, maxWidth: col?.width ?? cellWidth }}
          className="p-cell text-xxs text-table-bodyColor font-normal td-custom"
        >
          {renderFunc(rowData, isHovered)}
        </td>
      )}
      {!renderFunc && handleCell()}
    </>
  )
}

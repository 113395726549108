import React from 'react'

export const DummyComponent: React.FC = () => {
  return (
    <div className="w-full h-full">
      <iframe
        src="https://www.cloudnautical.com/"
        height={'100%'}
        width={'100%'}
        className="block"
        title="Cloud nautical"
      />
    </div>
  )
}

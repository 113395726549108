import { Button, CustomKendoTable, DeletePopUp, Loader } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'
import { TableHeight, isAllow } from '@/constants'
import VesselBudgetItems from '@/modules/technical/components/app/vesselBudget/manageItems/BudgetItems'
import { deleteBudgetItem } from '@/modules/technical/pages/vesselBudget/details/api'
import { UseVesselBudgetDetailsFinal } from '@/modules/technical/pages/vesselBudget/details/budgetdetails/api'
import { useAppContext } from '@/store/context/appContext'
import useHasPermission from '@/utilities/checkActionPermission'
import useCustomSnackBar from '@/utilities/customSnackBar'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'

export interface outLet {
  TitleFinal?: any
  FinalId?: any
  BudgetId?: any
  onHandleBack?: (node?: any) => void
}

export const AsummarySub: React.FC<outLet> = ({
  FinalId,
  TitleFinal,
  BudgetId,
  onHandleBack
}) => {
  const [isItemOpen, setItemOpen] = useState(false)
  const [itemMode, setItemMode] = useState<string>('add')
  const [budgetItem, setBudgetItem] = useState<any>()
  const { isTab } = useScreenBreakPoint()
  const [isLoading, setIsLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
const [subTitle,setSubTitle] = useState<string>('')
  const hasPermission = useHasPermission('Budget')
  const { setIsCommonLoader,setIsActionLoader,isActionLoader } = useAppContext()
  const { showSnackBar } = useCustomSnackBar()

  const { data, isFetching, refetch } = UseVesselBudgetDetailsFinal(FinalId)

  const getTotalAmount = () => {
    if (data) {
      let total = 0
      data.forEach((item: any) => {
        total = total + (item.Budget !== null ? item.Budget : 0)
      })
      return total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      return 0
    }
  }
  const totalAmount = getTotalAmount()

  useEffect(() => {
    setIsCommonLoader(isFetching)
  }, [isFetching])

  const columnSub = [
    {
      field: 'ItemDescription',
      title: 'Item Description',
      action: true,
      // actionPos: '1/2',
      render: (row: any) => (
        <div
          className="kendo-column-text-link cursor-pointer truncate"
          onClick={() => {
            manageItemPopup(row, 'view')
          }}
        >
          {row?.ItemDescription}
        </div>
      )
    },
    {
      field: 'Budget',
      title: 'Amount',
      width: 200,
      render: (rowData: any) =>
        rowData.Budget !== null &&
        parseFloat(rowData.Budget)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
  ]

  const backButton = () => {
    return (
      <Button
        label="Back"
        outlined
        size="sm"
        onClick={onHandleBack}
        className="text-light"
      />
    )
  }

  const tableHeader = (
    <>
      <div className={isTab ? 'grid' : 'flex'}>
        <div className="flex text-text-secondary font-normal text-[14px]">
          <CustomIcons name="wallet" type="large" className="pr-2 pt-[1px]" />
          {TitleFinal}
        </div>

        <div className="flex text-text-secondary font-normal text-[14px]">
          - Total Amount :
          <span className="text-[#FF681D] font-normal text-[14px] pl-1">
            {' '}
            {totalAmount}
          </span>{' '}
        </div>
      </div>
    </>
  )

  const handleItemCreate = async () => {
    setIsLoading(true)
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: '',
      ID2: BudgetId
    })
    if (permission) {
      setItemMode('add')
      setBudgetItem([])
      setItemOpen(true)
    }
    setIsLoading(false)
  }

  const manageItemPopup = (row: any, mode = 'edit') => {
    if (row) {
      setBudgetItem(row)
      setItemMode(mode)
      setItemOpen(true)
      setIsLoading(false)
    }
  }

  const handleItemDelete = () => {
    setIsActionLoader(true)
    deleteBudgetItem(budgetItem)
      .then((res) => {
        showSnackBar(res, 'success')
        refetch()
      })
      .catch((err) => {
        showSnackBar(err.response.data.error, 'error')
      }).finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }

  const handleMenuActions = async (slug: string, row: any) => {
    setBudgetItem(row)
    setIsLoading(true)
    setSubTitle(row?.ItemDescription)
    const permissionParams = {
      UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
      AccessKey: '',
      ID1: BudgetId
    }
    const permission = await hasPermission(permissionParams)

    if (slug === 'delete') {
      setDeleteOpen(permission)
    } else if (slug === 'edit') {
      if (permission) {
        setItemMode(slug)
        setItemOpen(true)
        setIsLoading(false)
      }
    }
    setIsLoading(false)
  }

  return (
    <>
      <Loader isActive={isLoading} />
      <div className="grid grid-cols-1 md:grid-cols-1">
        <CustomKendoTable
          columns={columnSub}
          data={data}
          isFetching={isFetching}
          pagination={false}
          //defaultSort={sortFields[0]}
          title={tableHeader}
          toolBar={true}
          sortable={false}
          resizable={true}
          isDownloadable={false}
          //handleSort={handleSort}
          tableHeight={`calc(100vh - ${TableHeight.OneColSearch})`}
          primaryAction={{
            title: t('New'),
            handlePrimaryAction: handleItemCreate
          }}
          handleMenuActions={handleMenuActions}
          actionDropDown={[
            { title: 'Edit', iconName: 'edit', slug: 'edit', id: '1' },
            {
              title: 'Delete',
              iconName: 'delete',
              slug: 'delete',
              id: '2',
              isWarning: true
            }
          ]}
          renderToolAction={backButton}
        />
      </div>

      {isItemOpen && (
        <VesselBudgetItems
          onHandleClose={() => {
            setItemOpen(false)
          }}
          onRefetch={() => {
            refetch()
          }}
          budgetAccId={FinalId}
          budgetItem={budgetItem}
          mode={itemMode}
        />
      )}

      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleItemDelete}
        onClose={() => setDeleteOpen(false)}
        actionLoader={isActionLoader}
        itemLabels={{ title: 'item description', subTitle: subTitle }}
      />
    </>
  )
}

export default AsummarySub

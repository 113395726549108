export function convertKeysInArrayToLowercase(array: any) {
  return array?.map((item: any) => {
    const newItem: any = {}
    Object.keys(item).forEach((key) => {
      const newKey = key.charAt(0).toUpperCase() + key.slice(1)
      newItem[newKey] = item[key]
    })
    return newItem
  })
}

export const getInitials = (str: string, len: number = 2): string => {
  if (!str) return ''

  const words = str.split(' ')
  const initials = words.map((word) => word.charAt(0).toUpperCase()).join('')
  return initials.slice(0, len)
}

export const stringToColor = (str: string): string => {
  let hue = 0
  for (let i = 0; i < str.length; i++) {
    hue += str.charCodeAt(i)
  }
  hue %= 360
  return `hsl(${hue}, 70%, 50%)`
}

export const getFullName = (names: (string | null | undefined)[]): string => {
  if (!Array.isArray(names)) {
    return ''
  }
  return names.filter((name) => name && name.trim() !== '').join(' ')
}

export const getNameFromArray = (
  source: { id: number; name: string }[],
  id: number
): string | undefined => {
  const activeENUM = source.filter((item) => item.id === id)
  return activeENUM.length ? activeENUM[0].name : undefined
}

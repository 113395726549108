import { z } from 'zod'

export const CrewingAssignmentTypeSchema = z.object({
  assignmentTypeId: z.number().int(),
  assignmentTypeName: z
    .string({ required_error: 'Assignment Type Name is required' })
    .max(100, { message: 'Maximum 100 characters allowed' })
    .min(1, 'Assignment Type Name is required'),
  archived: z.boolean().optional().nullable(),
  coreActivityTypeId: z.number().int(),
  coreActivityTypeName: z
    .string({ required_error: 'Core Activity is required' })
    .max(100, { message: 'Maximum 100 characters allowed' })
    .min(1, 'Core Activity is required'),
  completionActivityTypeId: z.number().int(),
  completionActivityTypeName: z
    .string({ required_error: 'Completion Activity is required' })
    .max(100, { message: 'Maximum 100 characters allowed' })
    .min(1, 'Completion Activity is required'),
  seaService: z.boolean().optional().nullable()
})

export type CrewingAssignmentTypeDataType = z.infer<typeof CrewingAssignmentTypeSchema>

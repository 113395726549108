import { z } from 'zod'

export const workFlowStatusFormSchema = z.object({
  StatusName: z
    .string({ required_error: 'Status is required' })
    .min(1, { message: 'Status is required' }),
  TextColor: z.string().optional(),
  BackgroundColor: z.string().optional(),
  StatusDesc: z
    .string({ required_error: 'Description is required' })
    .min(1, { message: 'Description is required' }),
  ActionMessage: z
    .string({ required_error: 'Action Message is required' })
    .min(1, { message: 'Action Message is required' }),
  ActionBtnText: z
    .string({ required_error: 'Action Button Text is required' })
    .min(1, { message: 'Action Button Text  is required' }),
  OnSuccessAPI: z.string().optional().nullable(),
  RevertAPI: z.string().optional().nullable(),
  SeqNo: z
    .number({
      required_error: 'Sequence is required',
      invalid_type_error: 'Sequence is required'
    })
    .min(1, { message: 'Minimum sequence number is 1' })
    .max(1000, { message: 'Maximum sequence number is 1000' }),
  WorkflowId: z.number().optional(),
  StatusId: z.number().optional(),
  Delete: z.boolean(),
  Edit: z.boolean(),
  NoComments: z.boolean(),
  Reject: z.boolean()
})

export const workFlowRoleSchema = z.object({
  sysRoleName: z
    .string({ required_error: 'Role is required' })
    .min(1, { message: 'Role is required' }),
  WorkflowStatusId: z.number().optional(),
  Override: z.boolean(),
  LevelName: z
    .string({ required_error: 'Level is required' })
    .min(1, { message: 'Level is required' }),
  LevelId: z.number().optional(),
  EntityRespOnly: z.boolean(),
  AllUsers: z.boolean(),
  Mandatory: z.boolean(),
  SysRoleID: z.string().optional(),
  WorkflowRoleId: z.number().optional()
})

export const revertStatusSchema = z.object({
  WorkflowRevertId: z.number().optional(),
  WorkflowStatusId: z.number().optional(),
  RevertStatusId: z.number().optional(),
  RevertStatusName: z
    .object({
      id: z.number().min(1, { message: 'Id is Required' }),
      name: z.string().min(1, { message: 'Name is Required' })
    })
    .array()
    .nonempty('Revert status is required')
    .refine((data) => data.length > 0, {
      message: 'Revert status required'
    })
})

export type revertStatus = z.infer<typeof revertStatusSchema>

export type workFlowRole = z.infer<typeof workFlowRoleSchema>

export type workFlowStatusForm = z.infer<typeof workFlowStatusFormSchema>

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const LoginUserIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
    >
      <path
        d="M10 9C12.4853 9 14.5 7.20914 14.5 5C14.5 2.79086 12.4853 1 10 1C7.51472 1 5.5 2.79086 5.5 5C5.5 7.20914 7.51472 9 10 9Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M19 16.5C19 18.985 19 21 10 21C1 21 1 18.985 1 16.5C1 14.015 5.02975 12 10 12C14.9703 12 19 14.015 19 16.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default LoginUserIcon

import { lazy } from 'react'
import CrewsList from './pages/crewSearch'
import CrewDetails from './pages/crewSearch/details'
import Apprisals from './pages/crewSearch/details/apprisals'
import AuditLog from './pages/crewSearch/details/auditLog'
import BankDetails from './pages/crewSearch/details/bankDetails'
import CorrespondenceOrNotes from './pages/crewSearch/details/correspondenceOrNotes'
import CrewBasicInfo from './pages/crewSearch/details/crewDetails'
import EmployementAndPayroll from './pages/crewSearch/details/employmentAndPayroll'
import Overview from './pages/crewSearch/details/overview'
import SeaServiceRecord from './pages/crewSearch/details/seaServiceRecord'
import TrainingAndCertifications from './pages/crewSearch/details/trainingAndCertifications'

import CrewingAssignmentTypeList from './pages/registers/assignmentType'
import { crewingRouterStore } from './store'
import CrewDocumentCategoryTypeList from './pages/registers/crewDocumentType'

// Technical

const CrewingRankList = lazy(() => import('./pages/registers/ranks'))
const CrewingActivityTypeList = lazy(() => import('./pages/registers/activityType'))
const CrewStatusList = lazy(() => import('./pages/registers/crewStatus'))
const TourOfDuty = lazy(() => import('./pages/registers/tourOfDuty'))

const CrewDocumentCategoryList = lazy(
  () => import('./pages/registers/crewDocumentCategory')
)
const CrewDocumentComplianceList = lazy(
  () => import('./pages/registers/documentCompliance')
)

const crewingRoutes = [
  {
    slug: 'CREWING_RANK_LIST',
    path: crewingRouterStore.REGISTERS_RANK,
    component: <CrewingRankList />
  },
  {
    slug: 'CREW_ACTIVITY_LIST',
    path: crewingRouterStore.REGISTER_ACTIVITY_TYPE,
    component: <CrewingActivityTypeList />
  },
  {
    slug: 'CREW_STATUS_LIST',
    path: crewingRouterStore.REGISTER_CREW_STATUS,
    component: <CrewStatusList />
  },
  {
    slug: 'REGISTER_ASSIGNMENT_TYPE',
    path: crewingRouterStore.REGISTER_ASSIGNMENT_TYPE,
    component: <CrewingAssignmentTypeList />
  },
  {
    slug: 'REGISTER_TOUR_OF_DUTY',
    path: crewingRouterStore.REGISTER_TOUR_OF_DUTY,
    component: <TourOfDuty />
  },
  {
    slug: 'CREW_SEARCH',
    path: crewingRouterStore.CREW_SEARCH,
    component: <CrewsList />
  },
  {
    slug: 'REGISTER_CREW_DOCUMENT_CATEGORIES',
    path: crewingRouterStore.REGISTER_CREW_DOCUMENT_CATEGORIES,
    component: <CrewDocumentCategoryList />
  },
  {
    slug: 'REGISTER_CREW_DOCUMENT_CATEGORY_TYPES',
    path: crewingRouterStore.REGISTER_CREW_DOCUMENT_CATEGORY_TYPES,
    component: <CrewDocumentCategoryTypeList />
  },
  {
    slug: 'REGISTER_CREW_DOCUMENT_COMPLIANCE',
    path: crewingRouterStore.REGISTER_CREW_DOCUMENT_COMPLIANCE,
    component: <CrewDocumentComplianceList />
  },
  {
    slug: 'CREW_DETAILS',
    path: crewingRouterStore.CREW_DETAILS,
    component: <CrewDetails />,
    children: [
      {
        slug: 'CREW_DETAILS_OVERVIEW',
        path: crewingRouterStore.CREW_DETAILS_OVERVIEW,
        component: <Overview />
      },
      {
        slug: 'CREW_DETAILS_BASIC_INFO',
        path: crewingRouterStore.CREW_DETAILS_BASIC_INFO,
        component: <CrewBasicInfo />
      },

      {
        slug: 'CREW_DETAILS_TRAINING_AND_CERTIFICATIONS',
        path: crewingRouterStore.CREW_DETAILS_TRAINING_AND_CERTIFICATIONS,
        component: <TrainingAndCertifications />
      },
      {
        slug: 'CREW_DETAILS_SEA_SERVICE_RECORD',
        path: crewingRouterStore.CREW_DETAILS_SEA_SERVICE_RECORD,
        component: <SeaServiceRecord />
      },
      {
        slug: 'CREW_DETAILS_CORRESPONDENCE_OR_NOTES',
        path: crewingRouterStore.CREW_DETAILS_CORRESPONDENCE_OR_NOTES,
        component: <CorrespondenceOrNotes />
      },
      {
        slug: 'CREW_DETAILS_APPRAISALS',
        path: crewingRouterStore.CREW_DETAILS_APPRAISALS,
        component: <Apprisals />
      },
      {
        slug: 'CREW_DETAILS_BANK_DETAILS',
        path: crewingRouterStore.CREW_DETAILS_BANK_DETAILS,
        component: <BankDetails />
      },
      {
        slug: 'CREW_DETAILS_EMPLOYMENT_AND_PAYROLL',
        path: crewingRouterStore.CREW_DETAILS_EMPLOYMENT_AND_PAYROLL,
        component: <EmployementAndPayroll />
      },
      {
        slug: 'CREW_DETAILS_AUDIT_LOG',
        path: crewingRouterStore.CREW_DETAILS_AUDIT_LOG,
        component: <AuditLog />
      }
    ]
  }
]

export default crewingRoutes

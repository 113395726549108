import ManageCompanyService from '@/components/app/masterData/company/service/ManageCompanyService'
import { CustomKendoTable, DeletePopUp } from '@/components/common'
import { TableHeight, defaultPageSize, guid, isAllow } from '@/constants'
import useHasPermission from '@/utilities/checkActionPermission'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'

import { deleteService, getServiceMenu, useServiceList } from '../../api'
import { FormSchema, formSchema } from './schema'

const sortFields = ['ServiceTypeDesc']
type PageParams = {
  pageNo: number
  CompanyId?: string
  pageSize: number
  sortBy: string
}
const CompanyServiceList: React.FC = () => {
  const { companyId: CompanyId } = useOutletContext<any>()

  const [total, setTotal] = useState(0)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const hasPermission = useHasPermission('CompService', 'master')

  const [ServiceId, setServiceId] = useState<string>('')
  const { showSnackBar } = useCustomSnackBar()
  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    CompanyId: CompanyId,
    pageSize: defaultPageSize,
    sortBy: sortFields[0]
  })
  const methods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      CompanyId: CompanyId,
      CompServiceId: guid
    }
  })
  const { data, refetch, isFetching } = useServiceList(params)
  const columns = [
    {
      title: 'Service',
      field: 'ServiceTypeDesc',
      sort: true,
      action: true
    },

    {
      title: 'Archived',
      align: 'center',
      field: 'Archived',
      type: 'boolean',
      sort: true
    },
    {
      title: 'Authorised',

      align: 'center',
      field: 'Authorised',
      type: 'boolean',
      sort: true
    }
  ]

  useEffect(() => {
    setTotal(data?.length)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])
  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }

  const handleMenuActions = async (slug: string, rowData: any) => {
    setServiceId(rowData?.CompServiceId)
    const permissionParams = {
      UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
      AccessKey: '',
      ID1: CompanyId
    }

    const permission = await hasPermission(permissionParams)
    if (slug === 'delete') {
      setDeleteOpen(permission)
    }
  }

  const handleDelete = () => {
    deleteService(ServiceId)
      .then((res) => {
        showSnackBar(res)
        setDeleteOpen(false)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err.response.data.error.message, 'error')
      })
  }
  const handleCreate = async () => {
    methods.reset({
      CompServiceId: guid,

      CompanyId: CompanyId
    })
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: CompanyId
    })

    setCreateOpen(permission)
  }
  const handleMenuItems = async (row: any) => {
    const data = await getServiceMenu(row.CompServiceId)
    return data
  }
  return (
    <div className="px-kendo-simple-x py-kendo-simple-y bg-background-kendo">
      <CustomKendoTable
        columns={columns}
        data={data ? data : []}
        pagination={true}
        toolBar={true}
        sortable={true}
        resizable={true}
        async={false}
        isFetching={isFetching}
        defaultSort={sortFields[0]}
        title="Service Details"
        isDownloadable={true}
        handleSort={handleSort}
        tableHeight={`calc(100vh - ${TableHeight.WithOneColHeader})`}
        actionDropDown={[
          {
            title: 'Delete',
            iconName: 'delete',
            slug: 'delete',
            id: '2',
            isWarning: true
          }
        ]}
        workflowParams={{
          isDynamicMenu: true,
          handleMenuItems: handleMenuItems,
          handleRefresh: refetch
        }}
        handleMenuActions={handleMenuActions}
        primaryAction={{ title: 'New', handlePrimaryAction: handleCreate }}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },
          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
        version="v2"
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
      />
      <ManageCompanyService
        open={createOpen}
        refetch={refetch}
        handleClose={() => setCreateOpen(false)}
        CompanyId={CompanyId}
      />
    </div>
  )
}

export default CompanyServiceList

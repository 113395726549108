import { TextArea } from '@/components/common'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { useState } from 'react'
import { z } from 'zod'

const TextAreaComponent = () => {
  const [edited, setEdited] = useState(false)
  const TextAreaSchema = z.object({
    TextArea: z.string(),
    TextArearequired: z.string().min(1, { message: 'Enter TextArearequired' }),
    TextAreaDisabled: z.string().min(1, { message: 'Enter TextAreaDisabled' }),
    TextAreaEditable: z.string().min(1, { message: 'Enter TextAreaDisabled' })
  })
  type FormInput = z.infer<typeof TextAreaSchema>

  const {
    register,
    setFocus,
    formState: { errors }
  } = useForm<FormInput>({
    resolver: zodResolver(TextAreaSchema),
    mode: 'onChange',
    defaultValues: {
      TextArea: '',
      TextArearequired: '',
      TextAreaDisabled: 'Rext field disabled'
    }
  })

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="py-3">
          <div className="flex p-2">
            <TextArea
              id="TextArea"
              name="TextArea"
              label="Text Field"
              placeholder="Sample text field"
              disabled={false}
              register={register}
              errors={errors}
            />
          </div>
        </div>
        <div className="py-3">
          <div className="flex p-2">
            <TextArea
              id="TextArearequired"
              name="TextArearequired"
              label="Text field"
              placeholder="Sample text field"
              disabled={false}
              register={register}
              errors={errors}
              required
            />
          </div>
        </div>
        <div className="py-3">
          <div className="flex p-2">
            <TextArea
              id="TextAreaDisabled"
              name="TextAreaDisabled"
              label="Text Field"
              placeholder="Sample text field"
              disabled
              register={register}
              errors={errors}
              required
            />
          </div>
        </div>
        <div className="py-3">
          <div className="flex p-2">
            <TextArea
              id="TextAreaEditable"
              name="TextAreaEditable"
              label="Text Field"
              fieldEdit
              edited={edited}
              onEditComplete={() => {
                setEdited(true)
              }}
              disabled
              placeholder="Sample text field"
              register={register}
              setFocus={setFocus}
              errors={errors}
              required
            />
          </div>
        </div>
      </div>

      {/* -------------------------------------------------------------------------- */}
    </>
  )
}

export default TextAreaComponent

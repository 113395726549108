import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const LoadlineIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" />
      <path
        d="M4.13823 2V19.7778H21.244V22H2V2H4.13823ZM20.4881 5.65877L22 7.23012L15.8985 13.5713L12.6911 10.2389L8.10154 15.0079L6.58958 13.4366L12.6911 7.09532L15.8985 10.4278L20.4881 5.65877Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LoadlineIcon

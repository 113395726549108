import React, { useState } from 'react'
import { Checkbox } from '@/components/common'
import CustomIcons from '../icons/customIcons'

type TreeNode = {
  label: string
  children?: TreeNode[]
  id: string
  checked?: boolean
}
type TreeItemProps = {
  label: string
  level?: number
  children?: React.ReactNode
  handleNodeCheck?: (data?: any) => void
  node: TreeNode
  treeSelect?: boolean
  nodeIcon?: string
  handleTreeExpand?: (data: any) => void
  treeAction?: string
  handleTreeAction?: (data: any) => void
  nodeSelectedIcon?: string
}

const TreeItem: React.FC<TreeItemProps> = ({
  label,
  level = 0,
  children,
  handleNodeCheck,
  node,
  treeSelect,
  nodeIcon,
  handleTreeExpand,
  treeAction,
  handleTreeAction,
  nodeSelectedIcon
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasChildren = Array.isArray(children)
  const handleTree = () => {
    if (!isOpen) {
      handleTreeExpand?.(node)
    }
    setIsOpen(!isOpen)
  }
  return (
    <div>
      <div
        className={` flex relative items-center p-1 tree-content ${
          level > 0 ? `pl-${level * 4}` : ''
        }`}
      >
        {hasChildren ? (
          <button onClick={() => handleTree()} className="focus:outline-none mr-1">
            <span className={`font-semibold text-l  text-secondary`}>
              <CustomIcons
                name={isOpen ? 'tree_expand' : 'tree_colapse'}
                type={'large-m'}
              />
            </span>
          </button>
        ) : (
          <div className="flex items-center justify-center">
            {treeSelect && (
              <span className="text-lg w-6 cursor-pointer">
                <Checkbox
                  id={node?.id}
                  name={node?.id}
                  checked={node?.checked}
                  handleChange={() => handleNodeCheck?.(node)}
                />
                {/* <CustomIcons name={node?.checked ? 'input_checked' : 'input_unchecked'} isWrapper /> */}
              </span>
            )}

            {node && Object.keys(node)?.length > 0 && nodeIcon && (
              <span className="text-lg w-6 cursor-pointer">
                <CustomIcons
                  className={node.checked ? 'text-secondary' : 'text-table-headColor'}
                  name={node.checked ? (nodeIcon as string) : nodeIcon}
                  type={'medium'}
                />
              </span>
            )}
          </div>
        )}
        <span
          className={` text-common font-medium ${
            node?.checked || (isOpen && hasChildren)
              ? 'text-secondary '
              : 'text-table-headColor'
          }   ${nodeIcon ? '' : 'ml-1'} `}
        >
          {label}
        </span>
        {treeAction && node && Object.keys(node)?.length > 0 && (
          <span
            className="text-lg  cursor-pointer absolute right-0 tree-action"
            onClick={(e) => {
              e.stopPropagation()
              handleTreeAction?.(node)
            }}
          >
            <CustomIcons
              name={treeAction ?? ''}
              type="medium"
              className="text-error-icon"
            />
          </span>
        )}
      </div>
      {hasChildren && isOpen && <div className="ml-4 ">{children}</div>}
    </div>
  )
}

export default TreeItem

import { useEffect, useState } from 'react'

export interface breakpontProps {
  isScrolling?: boolean
}

const useScrollData = (): breakpontProps => {
  const [isScrolling, setIsScrolling] = useState(false)
  const handleScroll = () => {
    setIsScrolling(true)
  }
  const handleScrollend = () => {
    setIsScrolling(true)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('scrollend', handleScrollend)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('scrollend', handleScrollend)
    }
  }, [])

  return {
    isScrolling: isScrolling
  }
}

export default useScrollData

// import { Radio } from '@/components/common'

import React from 'react'

import CustomIcons from '@/components/common/icons/customIcons'
import { OptionValueProps } from '.'
export type OptionProps = {
  id: OptionValueProps
  name: string
  icon?: string
  description?: string
}
export const options: OptionProps[] = [
  {
    id: '1',
    name: 'Based on Existing Budget',
    icon: 'BudgetExisting',
    description: 'The selected budget will be copied to a new budget'
  },
  {
    id: '2',
    name: 'Based on Chart',
    icon: 'BudgetChart',
    description: 'A new budget will be created based on the chart selected. '
  },
  {
    id: '3',
    name: 'Customized Budget',
    icon: 'BudgetCustom',
    description:
      'An empty Budget will be created and the accounts need to be added manually.'
  }
]
type Props = {
  clearDatas: any
  option: OptionValueProps
  methods: any
}

const SelectOptions: React.FC<Props> = ({ clearDatas, methods }) => {
  const { setValue, watch } = methods
  const { Option } = watch()

  const handleSelectChange = (item: OptionProps) => {
    setValue('Option', item.id)
    clearDatas()
  }

  // ;<Radio
  //   key={item.id}
  //   id={item.id}
  //   name={item.id}
  //   label={item.name}
  //   value={item.id}
  //   checked={item?.id === Option}
  //   customLabel
  //   handleChange={() => handleSelectChange(item)}
  // />

  return (
    <div className={`flex h-full w-full items-center`}>
      <div className={`flex w-full items-stretch justify-center gap-x-4`}>
        {options.map((item: OptionProps) => (
          <div
            className={`w-[235px]  p-5 cardComponentbg relative flex flex-col ${item?.id === Option ? 'active' : ''}`}
            key={item.id}
            role="button"
            onClick={() => handleSelectChange(item)}
          >
            {item?.id === Option && (
              <div className="absolute right-3 top-3">
                <CustomIcons name="cardCheck" isWrapper />
              </div>
            )}
            {item.icon && (
              <div className="pb-3.5">
                <div
                  className={`w-[30px] h-[30px] rounded-[4.5px]  flex items-center justify-center ${
                    item?.id === Option
                      ? 'text-white bg-[#ffffff14]'
                      : 'text-primary bg-[#ff681d0f]'
                  }
                `}
                >
                  <div className="flex items-center justify-center">
                    <CustomIcons name={item.icon} isWrapper={false} />
                  </div>
                </div>
              </div>
            )}
            <div className="pb-[22px] text-m leading-[18px] font-medium">
              {' '}
              {item.name}
            </div>
            <div className="cardComponentseparator" />
            <div className="cardcomponentdesc text-xxs font-normal py-4 mb-5">
              {item.description}
            </div>
            <div className="w-full mt-auto">
              <button
                className="w-full flex py-3 px-5 font-medium text-common items-center justify-center cardcomponentbutton"
                disabled={item?.id === Option}
                onClick={() => {
                  handleSelectChange(item)
                }}
              >
                {item?.id === Option ? 'Selected' : 'Select'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SelectOptions

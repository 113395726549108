import { HeaderWrapper } from '@/components/app/common'
import { Breadcrumb, DeletePopUp } from '@/components/common'
import CardsList from '@/components/common/cardsList'
import { TableHeight } from '@/constants'
import { CrewDocumentTypeForm } from '@/modules/crewing/components/app/registers/crewDocumentType/CrewDocumentTypeForm'
import CrewDocumentTypeSearch from '@/modules/crewing/components/app/registers/crewDocumentType/CrewDocumentTypeSearch'
import CrewDocumentExpansionComponent from '@/modules/crewing/components/app/registers/crewDocumentType/ExpansionComponent'
import { useAppContext } from '@/store/context/appContext'
import useDataStore from '@/store/dynamicStore/useDataStore'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { getErrorMessage } from '@/utilities/dataGenerators'
import { parseSearchParams } from '@/utilities/dataParse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CrewDocumentTypeConfigForm } from '@/modules/crewing/components/app/registers/crewDocumentType/DocumentTypeConfigForm'
import {
  deleteCrewDocumentType,
  fetchCrewDocumentTypeById,
  useCrewDocumentType
} from './api'
import useCrewDocumentTypeStore from './store'

const initData = {
  crewDocCategoryId: 0,
  crewDocTypeName: '',
  crewDocTypeId: 0,

  Archived: false
}

const globalID = 0

const CrewDocumentCategoryTypeList: React.FC = () => {
  const { filters, setFilters, isSearchCount, setIsSearched } = useCrewDocumentTypeStore()
  const [mainData, setData] = useState<any>([])
  const { t } = useTranslation()
  const { getData, dataStore } = useDataStore()
  const { setIsCommonLoader, setIsActionLoader, isActionLoader } = useAppContext()
  const [edit, setEdit] = useState(true)
  const [rowData, setRowData] = useState<any>(initData)
  const [rowConfigData, setrowConfigData] = useState<any>(initData)
  const [createOpen, setCreateOpen] = useState(false)
  const [createOpenConfig, SetCreateOpenConfig] = useState(false)
  const [viewable, setViewable] = useState(false)
  const [crewDocTypeId, setCrewDocTypeId] = useState<number>(0)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { showSnackBar } = useCustomSnackBar()
  const [documentCategory, setDocumentCategory] = useState<string>()

  const { data, isFetching, refetch } = useCrewDocumentType(
    {
      ...parseSearchParams(filters, [])
    },
    isSearchCount
  )

  const [totalProps, setTotalProps] = useState<any>({ total: 0 })

  const breadcrumbConfig = [
    { label: t('Crewing') },
    { label: t('Registers') },
    { label: t('Crew Document Type') }
  ]

  const getCategory = async () => {
    getData('docCate', { key: 'CRWDOCCAT' }, '*')
  }

  useEffect(() => {
    setFilters({ ...filters, pageNo: 1 })
    getCategory()
  }, [])

  useEffect(() => {
    if (data?.totalRecords) setTotalProps({ total: data?.totalRecords })
    else setTotalProps({ total: 0 })
    const newData = data?.results ?? []
    if (filters?.pageNo && (filters?.pageNo as number) > 1)
      setData((prevData: any) => [...prevData, ...newData])
    else setData(newData)
  }, [data])

  useEffect(() => {
    setIsCommonLoader(isFetching)
  }, [isFetching])

  const onSearch = (data: any) => {
    setFiltersAndUpdate({ ...filters, ...data, pageNo: 1 })
  }

  const setFiltersAndUpdate = (newFilters: any) => {
    setFilters(newFilters)
    setIsSearched?.()
  }

  const onPagination = (row: number, pageNo: number) => {
    setFiltersAndUpdate({ ...filters, pageNo: pageNo, pageSize: row })
  }

  const onRowsPerPage = (row: number) => {
    setFiltersAndUpdate({ ...filters, pageSize: row, pageNo: 1 })
  }

  const handleToolActions = (slug: string) => {
    if (slug === 'archived')
      setFiltersAndUpdate({ ...filters, archived: !filters?.archived, pageNo: 1 })
  }

  const getArchiveText = () => (filters?.archived ? 'Hide Archived' : 'Show Archived')

  const handleSort = (order: string) => {
    setFiltersAndUpdate({ ...filters, sortBy: order, pageNo: 1 })
  }

  const columnsHeader = [
    {
      title: 'Document Category',
      field: 'crewDocCategoryName'
    },
    {
      title: 'Document Type Name',
      field: 'crewDocTypeName'
    },
    {
      title: t('Renewable'),
      field: 'renewalRequired',
      type: 'boolean'
    },
    {
      title: t('Archived'),
      field: 'archived',
      type: 'archive'
    }
  ]

  const manageViewEditToogle = (id?: number, mode = 'edit') => {
    if (id) {
      setIsCommonLoader(true)
      try {
        fetchCrewDocumentTypeById(id).then((res) => {
          setRowData(res?.data)
          setIsCommonLoader(false)
          setViewable(mode === 'edit' ? false : true)
          setEdit(mode === 'edit' ? true : false)
          setCreateOpen(true)
        })
      } catch {
        setIsCommonLoader(false)
      }
    }
  }

  const handleMenuActions = async (slug: string, row: any) => {
    setCrewDocTypeId(row?.crewDocTypeId)
    setIsCommonLoader(true)
    setDocumentCategory(row?.crewDocCategoryName)

    // const permissionParams = {
    //   UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
    //   AccessKey: '',
    //   ID1: row?.VesClassId
    // }
    // const permission = await hasPermission(permissionParams)
    const permission = true
    setIsCommonLoader(false)
    if (slug === 'delete') {
      setDeleteOpen(permission)
    } else if (slug === 'edit') {
      if (permission) {
        manageViewEditToogle(row?.crewDocTypeId)
      }
    }
    if (slug === 'settings') {
      if (permission) {
        setrowConfigData(row)
        SetCreateOpenConfig(true)
      }
    }
  }

  const handleCloseForm = () => {
    setCreateOpen(false)
    setRowData(initData)
    setEdit(false)
    setViewable(false)
  }
  const handleCloseConfigForm = () => {
    SetCreateOpenConfig(false)
  }

  const handleDelete = () => {
    setIsActionLoader(true)
    deleteCrewDocumentType(crewDocTypeId)
      .then((res) => {
        showSnackBar(res?.message)
        setCrewDocTypeId(globalID)
        // refetch()
        setData((prevData: any) =>
          prevData?.filter((item: any) => item.crewDocTypeId !== crewDocTypeId)
        )
      })
      .catch((err) => {
        showSnackBar(getErrorMessage(err), 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }

  const handleCreate = async () => {
    setIsCommonLoader(true)
    setViewable(false)
    const permission = true
    //    await hasPermission({
    //    UserAction: isAllow.CREATE,
    //    AccessKey: '',
    //    ID1: ''
    //  })
    setEdit(false)
    setCreateOpen(permission)

    setIsCommonLoader(false)
  }

  return (
    <>
      <Breadcrumb items={breadcrumbConfig} />
      <HeaderWrapper>
        <CrewDocumentTypeSearch onSearch={onSearch} rankCate={dataStore?.docCate ?? []} />
      </HeaderWrapper>

      <CardsList
        avatar="rank"
        variant="expand"
        themeKey="backgroundColor"
        columns={columnsHeader}
        data={mainData}
        isFetching={isFetching}
        searchCount={isSearchCount}
        expansionComponent={CrewDocumentExpansionComponent}
        pagination={true}
        defaultSort={'crewDocTypeName'}
        title="Crew Ranks"
        toolBar={true}
        cardHeight={`calc(100vh - ${TableHeight.CardListSingle})`}
        // sortable={true}
        isDownloadable={true}
        handleSort={handleSort}
        sortDropDown={[
          // { title: 'Document Category', slug: 'crewDocCategoryName', id: '1' },
          { title: 'Document Type', slug: 'crewDocTypeName', id: '1' }
        ]}
        handleMenuActions={handleMenuActions}
        primaryAction={{
          title: t('New'),
          handlePrimaryAction: handleCreate
        }}
        onExpansionDropDown={[
          {
            title: 'New Configuration',
            iconName: 'settings_spanner',
            slug: 'settings',
            id: '1'
          }
        ]}
        actionDropDown={[
          { title: 'Edit', iconName: 'edit', slug: 'edit', id: '1' },
          {
            title: 'Delete',
            iconName: 'delete',
            slug: 'delete',
            id: '2',
            isWarning: true
          }
        ]}
        paginationProps={{
          total: totalProps.total,
          currentPage: filters.pageNo as number,
          rowsPerPage: filters.pageSize as number,
          onPagination,
          onRowsPerPage
        }}
        handleToolActions={handleToolActions}
        actionToolDropDown={[
          { title: getArchiveText(), iconName: 'archieve', slug: 'archived', id: '1' }
        ]}
      />
      <CrewDocumentTypeForm
        edit={edit}
        data={rowData}
        onClose={handleCloseForm}
        open={createOpen}
        title={`${edit ? 'Edit ' : viewable ? 'View' : 'Add '} Document Type`}
        refetch={refetch}
        view={viewable}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
        actionLoader={isActionLoader}
        itemLabels={{ title: 'crew Document Type Master', subTitle: documentCategory }}
      />
      <CrewDocumentTypeConfigForm
        edit={false}
        data={rowConfigData}
        onClose={handleCloseConfigForm}
        open={createOpenConfig}
        title={`${'Add '} Document Configuration`}
        refetch={refetch}
        view={false}
      />
    </>
  )
}

export default CrewDocumentCategoryTypeList

const BudgetExisting = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
    >
      <g clipPath="url(#clip0_2372_5770)">
        <path
          d="M11.427 13.533C11.427 12.4341 10.5305 11.5472 9.44125 11.5472H8.55439C8.18808 11.5472 7.88925 11.2484 7.88925 10.8821C7.88925 10.5157 8.18808 10.2169 8.55439 10.2169H8.98818H9.42197C9.59549 10.2169 9.769 10.2844 9.89432 10.4097C10.1546 10.67 10.5787 10.67 10.8294 10.4097C11.0897 10.1494 11.0897 9.72528 10.8294 9.47465C10.5113 9.14689 10.0968 8.96374 9.64368 8.91554V8.67454C9.64368 8.30823 9.34485 8.0094 8.97854 8.0094C8.61223 8.0094 8.3134 8.30823 8.3134 8.67454V8.91554C7.3205 9.03122 6.54932 9.86024 6.54932 10.8821C6.54932 11.981 7.44582 12.8678 8.53511 12.8678H9.42197C9.78828 12.8678 10.0871 13.1667 10.0871 13.533C10.0871 13.8993 9.78828 14.1981 9.42197 14.1981H8.53511C8.35195 14.1981 8.1688 14.1307 8.05312 13.9861C7.80249 13.7161 7.37834 13.6969 7.11806 13.9379C6.84815 14.1885 6.82887 14.603 7.06986 14.8729C7.38798 15.2296 7.84105 15.4417 8.3134 15.4995V15.7405C8.3134 16.1068 8.61223 16.4056 8.97854 16.4056C9.34485 16.4056 9.64368 16.1068 9.64368 15.7405V15.4995C10.6366 15.3838 11.4078 14.5548 11.4078 13.533H11.427Z"
          fill="currentColor"
        />
        <path
          d="M10.2992 4.59691L11.7548 3.15094C12.2079 2.70751 12.3428 2.04236 12.1019 1.46398C11.8609 0.885591 11.3018 0.5 10.6655 0.5H7.01205C6.38546 0.5 5.81672 0.875951 5.58536 1.46398C5.35401 2.052 5.47933 2.70751 5.93239 3.15094L7.44584 4.65474C3.69597 5.72476 0.379883 10.5157 0.379883 15.3935C0.379883 18.9505 2.32712 19.4904 8.99784 19.4904C15.6686 19.4904 17.6158 18.9505 17.6158 15.3935C17.6158 10.4097 14.1648 5.53196 10.3089 4.59691H10.2992ZM6.80961 1.96525C6.82889 1.91705 6.87709 1.82065 7.01205 1.82065H10.6655C10.7908 1.82065 10.8487 1.90741 10.868 1.96525C10.8872 2.01345 10.9065 2.11948 10.8198 2.20624L8.99784 4.00888C8.91109 4.09564 8.77613 4.09564 8.68937 4.00888L6.86745 2.20624C6.77105 2.11948 6.79997 2.02308 6.81925 1.96525H6.80961ZM8.99784 18.1697C2.04756 18.1697 1.71017 17.4949 1.71017 15.3935C1.71017 10.6121 5.38293 5.74404 8.99784 5.74404C12.6128 5.74404 16.2855 10.6121 16.2855 15.3935C16.2855 17.5046 15.9481 18.1697 8.99784 18.1697Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2372_5770">
          <rect
            width="17.2359"
            height="19"
            fill="white"
            transform="translate(0.379883 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default BudgetExisting

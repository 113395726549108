const PERMISSION_URL = '/v1/isAllowed'
const CREWING_RANK = 'v1/CrewRanks'
const CREWING_ACTIVITY_TYPE = 'v1/CrewActivityType'
const CREWING_ASSIGNMENT_TYPE = 'v1/CrewAssignmentType'
const CREWING_STATUS = 'v1/CrewStatus'
const CREWING_CREW = 'v1/CrewMaster'
const CREWING_TOUR_OF_DUTY = 'v1/CrewTourOfDuty'
const CREWING_CREW_DETAILS = 'v1/CrewPersonalInfo'
const CREWING_CREW_DOCUMENT_CATEGORIES = 'v1/CrewDocumentCategory'
const CREWING_CREW_DOCUMENT_CATEGORY_TYPE = 'v1/CrewDocumentType'
const CREWING_CREW_DOCUMENT_CATEGORY_TYPE_CONFIG = 'v1/CrewDocumentConfiguration'
const CREWING_CREW_DOCUMENT_COMPLIANCE = 'v1/CrewDocCompliance'
const apiCrewingUrl = {
  PERMISSION_URL,
  CREWING_RANK,
  CREWING_ACTIVITY_TYPE,
  CREWING_STATUS,
  CREWING_ASSIGNMENT_TYPE,
  CREWING_CREW,
  CREWING_TOUR_OF_DUTY,
  CREWING_CREW_DETAILS,
  CREWING_CREW_DOCUMENT_CATEGORIES,
  CREWING_CREW_DOCUMENT_CATEGORY_TYPE,
  CREWING_CREW_DOCUMENT_CATEGORY_TYPE_CONFIG,
  CREWING_CREW_DOCUMENT_COMPLIANCE
}

export default apiCrewingUrl

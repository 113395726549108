import { Button, Fab, FormBuilder } from '@/components/common'
import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { isAllow } from '@/constants'
import { useGeneralInfo } from '@/modules/technical/pages/vesselParticulars/details/general/api'
import {
  GeneralSchema,
  generalSchema
} from '@/modules/technical/pages/vesselParticulars/details/general/schema'
import { ParticularDetailsContextProps } from '@/modules/technical/pages/vesselParticulars/details/schema'
import { formContainerClass } from '@/styles/variables'
import useHasPermission from '@/utilities/checkActionPermission'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

type VesselGeneralForms = {
  data: any
}
const VesselGeneralForm: React.FC<VesselGeneralForms> = ({ data }) => {
  const { t } = useTranslation()
  const { edit, setEdit, vesselID } = useOutletContext<ParticularDetailsContextProps>()
  const [isOpen, setPopupOpen] = useState<boolean>(false)
  const hasPermission = useHasPermission('VesselGenInfo')


  const handleClose = () => {
    setPopupOpen(false)
    setEdit(false)
  }
  const { mutate, isLoading } = useGeneralInfo()
  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      builtOn: data.buildOn ? dayjs(data.buildOn).format('YYYY-MM-DD') : null,
      keelLaidOn: data.keelLaidOn ? dayjs(data.keelLaidOn).format('YYYY-MM-DD') : null,
      smcExpiry: data.smcExpiry ? dayjs(data.smcExpiry).format('YYYY-MM-DD') : null,
      docExpiry: data.docExpiry ? dayjs(data.docExpiry).format('YYYY-MM-DD') : null
    }
    mutate(payload)
  }

  const resetEdit = () => {
    methods.reset()
    setEdit(false)
  }

  const formConfig = [
    {
      label: 'Yard',
      placeholder: 'Type to search',
      name: 'yardName',
      type: 'autocomplete',
      required: true,
      id: 'yardId',
      maxLength: 4,
      apiParams: { key: 'COMPANY', filter1: 'YARD' }
    },
    {
      label: 'P & I Club',
      placeholder: 'Type to search',
      name: 'pandIClubName',
      type: 'autocomplete',
      id: 'pandIClubId',
      apiParams: { key: 'COMPANY', filter1: 'PICLUB' },
      maxLength: 4
    },
    {
      label: 'Date Built',
      name: 'buildOn',
      placeholder: 'Date Built (DD-MM-YYYY)',
      type: 'date',
      required: true
    },
    {
      label: 'Classification Society',
      placeholder: 'Type to search',
      name: 'className',
      id: 'classId',
      type: 'autocomplete',
      apiParams: { key: 'COMPANY', filter1: 'CLASSSOC' }
    },
    {
      label: 'Call Sign',
      placeholder: 'Call Sign',
      name: 'callSign',
      type: 'text',
      maxLength: 10
    },
    {
      label: 'Class Number',
      placeholder: 'Class Number',
      name: 'classNo',
      type: 'text',
      maxLength: 30
    },
    {
      label: 'Class Notation',
      placeholder: 'Class Notation',
      name: 'classNotation',
      type: 'text',
      maxLength: 150
    },
    {
      label: 'Hull No',
      placeholder: 'Hull No',
      name: 'hullNo',
      type: 'text',
      maxLength: 10
    },
    {
      label: 'Official Number',
      placeholder: 'Official Number',
      name: 'officialNo',
      type: 'number'
    },
    {
      label: 'ISM Status',
      placeholder: 'ISM Status',
      name: 'ismStatus',
      type: 'text',
      maxLength: 15
    },
    {
      label: 'ISM Status Remark',
      placeholder: 'ISM Status Remark',
      name: 'ismStatusRemark',
      type: 'text',
      maxLength: 150
    },
    {
      label: 'Keel Laid On',
      name: 'keelLaidOn',
      placeholder: 'Keel laid on (DD-MM-YYYY)',
      type: 'date'
    },
    {
      label: 'Email',
      placeholder: 'example@email.com',
      name: 'email',
      type: 'email',
      maxLength: 100
    },
    {
      label: 'SMC Expiry',
      name: 'smcExpiry',
      placeholder: 'SMS expiry (DD-MM-YYYY)',
      type: 'date'
    },
    {
      label: 'DOC Expiry',
      name: 'docExpiry',
      placeholder: 'DOC expiry (DD-MM-YYYY)',
      type: 'date'
    }
  ]
  const initData = () => {
    return {
      ...data,
      keelLaidOn: data?.keelLaidOn && new Date(data?.keelLaidOn),
      smcExpiry: data?.smcExpiry && new Date(data?.smcExpiry),
      docExpiry: data?.docExpiry && new Date(data?.docExpiry),
      buildOn: data?.buildOn && new Date(data?.buildOn),
      yardId: data?.yardId ?? '',
      yardName: data?.yardName ?? ''
    }
  }

  const methods = useForm<GeneralSchema>({
    resolver: zodResolver(generalSchema),
    mode: 'onChange',
    defaultValues: initData()
  })

  useEffect(() => {
    methods.reset({ ...initData() })
  }, [data])
  const handleEdit = async () => {
    const permission = await hasPermission({
      UserAction: isAllow.UPDATE,
      AccessKey: '',
      ID1: vesselID
    })
    setEdit(permission)
  }
  return (
    <div className="h-full relative w-full">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={formContainerClass}>
            <FormBuilder data={formConfig} edit={edit} />

            <div className="py-7">
              <div className="absolute bottom-0 right-0 p-2 py-1">
                {!edit ? (
                  <Fab
                    icontType="large"
                    onClick={() => {
                      handleEdit()
                    }}
                    className="fabShadow"
                  />
                ) : (
                  <div className="flex">
                    <Button
                      label={t('update')}
                      className="me-2"
                      isLoading={isLoading}
                      onClick={methods.handleSubmit(onSubmit)}
                    />
                    <Button
                      label={t('cancel')}
                      outlined
                      onClick={() => {
                        resetEdit()
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <ConfirmModal
        isOpen={isOpen}
        modalId="successPopup"
        topIcon="success"
        title="Success"
        actionLabel="OK"
        onSubmit={handleClose}
        type="success"
        scrollDisable={false}
        onClose={handleClose}
        subTitle="Thank you. You have updated successfully."
      />
    </div>
  )
}

export default VesselGeneralForm

import { NoRecordsFound } from '@/components/app/common'
import { Accordion, AccordionBody, AccordionHeader } from '@/components/common/accordion'
import CustomIcons from '@/components/common/icons/customIcons'
import DialogModal from '@/components/common/modal/DialogModal'
import KinDetailsInfo from '@/modules/crewing/components/app/crewSearch/details/family'
import { CreateCrewKin } from '@/modules/crewing/components/app/crewSearch/details/family/CrewKinForm'
import { useAppContext } from '@/store/context/appContext'
import { getFullName, getInitials, stringToColor } from '@/utilities/formatString'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import CrewDetailsComponent from '../../DetailPageContents'
import { useCrewProfileStore } from '../store'
import ConfirmModal from '@/components/common/modal/ConfirmModal'

type Props = {
  crewId: string
  data: any
  dataStore?: any
  loading: boolean
  pageRouting: boolean
  getDetails: () => void
}

export type CommonFamilyRefProps = {
  onCreate: () => void
}

const FamilyDetails = forwardRef<CommonFamilyRefProps, Props>(
  ({ crewId, data, loading, dataStore, getDetails, pageRouting }, ref) => {
    FamilyDetails.displayName = 'Family Details'
    const { isEditMode, resetEdit } = useCrewProfileStore()
    const [kinId, setKinId] = useState<string>('')
    const [selectedKin, setSelectedKin] = useState<string>('')
    const [open, setOpen] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState<string>('')
    const [kinData, setKinData] = useState<any>()
    const [edit, setEdit] = useState(true)
    const [employeeOpen, setEmployeeOpen] = useState(false)
    const [rowData, setRowData] = useState<any>({})
    const [createOpen, setCreateOpen] = useState(false)
    const [viewable, setViewable] = useState(false)
    const { setIsCommonLoader } = useAppContext()

    useImperativeHandle(
      ref,
      () => ({
        onCreate: () => {
          handleCreate()
        }
      }),
      []
    )
    const handleWarningOff = () => {
      setSelectedKin('')
      setOpen(false)
    }
    const handleConfirm = () => {
      resetEdit()
      setKinId(selectedKin)
      setSelectedKin('')
      setOpen(false)
    }

    const showDetails = (id: string) => {
      if (isEditMode()) {
        setSelectedKin(id)
        setOpen(true)
      } else {
        setKinId(id !== kinId ? id : '')
        const mainData = kinData?.map((item: any) =>
          id === item?.crewNokId ? { ...item, isOpen: true } : item
        )
        setKinData(mainData)
      }
    }

    const openDeletePopup = (e: any) => {
      e.stopPropagation()
      e.preventDefault()
    }

    const handleEmployeIdClick = (e: any, id: string) => {
      e.stopPropagation()
      e.preventDefault()

      setSelectedEmployee(id)
      setEmployeeOpen(true)
    }
    useEffect(() => {
      const newRecords = data.filter((item: any) => {
        if (!kinData) return true
        return !kinData.some((kinItem: any) => kinItem.crewNokId === item.crewNokId)
      })

      if (newRecords.length > 0) {
        const updatedData = newRecords.map((item: any) => ({
          ...item,
          isOpen: false
        }))
        if (!kinData) setKinData(updatedData)
        else setKinData((prevKinData: any[]) => [...prevKinData, ...updatedData])
      }
    }, [data])

    const onResetData = (data: any) => {
      const mainData = kinData?.map((item: any) =>
        data?.crewNokId === item?.crewNokId
          ? {
              ...item,
              nok: data?.nok,
              ice: data?.ice,
              relationship: data?.relationName,
              fullName: getFullName([data?.givenName, data?.middleName, data?.surname])
            }
          : item
      )
      setKinData(mainData)
    }

    const handleCloseForm = () => {
      setCreateOpen(false)
      setRowData({ relations: dataStore?.relations, crewId: crewId })
      setEdit(false)
      setViewable(false)
    }

    const getData = () => {
      getDetails()
    }

    const handleCreate = async () => {
      setRowData({
        relations: dataStore?.relations,
        crewId: crewId,
        existingCrew: ''
      })
      setIsCommonLoader(true)
      setViewable(false)
      const permission = true
      //    await hasPermission({
      //    UserAction: isAllow.CREATE,
      //    AccessKey: '',
      //    ID1: ''
      //  })
      setEdit(false)
      setCreateOpen(permission)
      setIsCommonLoader(false)
    }
    const onCloseEmployee = () => {
      setEmployeeOpen(false)
      setSelectedEmployee('')
    }
    return (
      <>
        {loading ? (
          <>
            {[...Array(2)].map((_, index) => (
              <div
                className="py-2 px-3.5 flex gap-[22px] bg-white rounded border border-grey-borderLight justify-between"
                key={index}
              >
                <div className="flex gap-3">
                  <div className="custom-skelton-gradient h-[30px] w-[30px] rounded-full"></div>

                  <div className="flex divide-x divide-grey-line gap-3 mt-2">
                    <div className="custom-skelton-gradient w-[120px] h-[15px] rounded-[30px]"></div>
                    <div className="custom-skelton-gradient w-[70px] h-[15px] rounded-[30px]"></div>
                  </div>
                </div>
                <div className="flex gap-x-3">
                  <div className="mt-1.5">
                    <div className="custom-skelton-gradient w-[25px] h-[20px] rounded-[30px]"></div>
                  </div>
                  <div className="mt-1.5">
                    <div className="custom-skelton-gradient w-[25px] h-[20px] rounded-[30px]"></div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {kinData?.length > 0 ? (
              <>
                {kinData?.map((rowData: any) => (
                  <Accordion
                    open={kinId === rowData.crewNokId}
                    id={rowData.crewNokId}
                    key={rowData.crewNokId}
                    className="rounded outline outline-[1px] outline-grey-borderLight py-2 px-3.5"
                  >
                    <AccordionHeader
                      type="block"
                      onClick={() => showDetails(rowData.crewNokId)}
                      className=" flex gap-[22px] bg-white justify-between cursor-pointer"
                    >
                      <div className="flex gap-3">
                        <div
                          className="h-[30px] w-[30px] rounded-full flex items-center justify-center text-white text-xxs"
                          style={{
                            backgroundColor: stringToColor(getInitials(rowData.fullName))
                          }}
                        >
                          {getInitials(rowData.fullName)}
                        </div>
                        <div className="flex gap-3 flex-col lg:flex-row">
                          <div className="flex items-center justify-center">
                            <div className="flex gap-3 text-common  font-medium pe-4 ">
                              <div className="text-text-primary mt-1">
                                {rowData.fullName}
                              </div>
                            </div>
                            <div className="w-[1px] h-[22px] bg-grey-line lg:flex none mt-1" />
                          </div>
                          <div className="flex gap-3 p-0 right">
                            {rowData?.employeeId && (
                              <div className="flex items-center justify-center">
                                <div className="text-text-primary flex text-common ps-0 lg:ps-1 gap-3 ">
                                  <div
                                    className="mt-1.5 cursor-pointer block text-link underline text-xxs"
                                    onClick={(e) => {
                                      handleEmployeIdClick(e, rowData.relativeCrewId)
                                    }}
                                  >
                                    {rowData.employeeId}
                                  </div>
                                  <div className="w-[1px] h-[22px] bg-grey-line lg:flex none mt-1" />
                                </div>
                              </div>
                            )}
                            <div
                              className={`text-text-secondary  text-common ${rowData?.employeeId ? 'ps-1' : 'ps-0'} lg:ps-1 mt-1.5`}
                            >
                              {rowData.relationship}
                            </div>

                            <div className="mt-1.5 flex gap-3">
                              {rowData.nok && (
                                <div className="text-white text-xxs font-medium rounded-[2px] h-[20px] px-1 bg-light flex items-center justify-center leading-[14px] tooltip-container">
                                  NOK
                                  <span className="tooltip whitespace-nowrap tooltip-card">
                                    Next of Kin
                                  </span>
                                </div>
                              )}
                              {rowData.ice && (
                                <div className="text-white text-xxs font-medium rounded-[2px] h-[20px] px-1 bg-button-yellow flex items-center justify-center leading-[14px] tooltip-container">
                                  <span className="text-[#1F2026]">ICE</span>
                                  <span className="tooltip whitespace-nowrap tooltip-card">
                                    Emergency Contact
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-center gap-x-3">
                        {pageRouting && (
                          <div className="">
                            <CustomIcons
                              type="medium"
                              name={'delete'}
                              className={` text-delete opacity-50`}
                              onClick={(e) => {
                                openDeletePopup(e)
                              }}
                            />
                          </div>
                        )}
                        <div className="">
                          <CustomIcons
                            type="large"
                            className={` text-text-primary`}
                            name={kinId === rowData.crewNokId ? 'up_arrow' : 'down_arrow'}
                          />
                        </div>
                      </div>
                    </AccordionHeader>
                    <AccordionBody className="w-full flex">
                      {(kinId === rowData.crewNokId || rowData.isOpen) && (
                        <KinDetailsInfo
                          data={rowData}
                          pageRouting={pageRouting}
                          dataStore={dataStore}
                          onResetData={onResetData}
                        />
                      )}
                    </AccordionBody>
                  </Accordion>
                ))}
              </>
            ) : (
              <NoRecordsFound title="No Family Details Found" />
            )}
          </>
        )}

        <CreateCrewKin
          edit={edit}
          data={rowData}
          onClose={handleCloseForm}
          open={createOpen}
          title={`${edit ? 'Edit ' : viewable ? 'View' : 'Add '} Kin Details`}
          refetch={getData}
          view={viewable}
        />
        <DialogModal
          isOpen={employeeOpen}
          onClose={onCloseEmployee}
          isFullView
          secondaryAction={onCloseEmployee}
          secondaryActionLabel={'Close'}
          body={<CrewDetailsComponent crewId={selectedEmployee} pageRouting={false} />}
        />
        <ConfirmModal
          isOpen={open}
          modalId="errorPopup"
          title={'Confirmation'}
          type="warning"
          secondaryActionLabel="No"
          secondaryAction={handleWarningOff}
          actionLabel="Yes"
          onSubmit={() => handleConfirm()}
          topIcon="warning"
          scrollDisable={true}
          onClose={handleWarningOff}
          subTitle={'Do you want to discard all the changes made?'}
        />
      </>
    )
  }
)

export default FamilyDetails

export const generateSpacing = (
  spacing: number | string = 4,
  hasCustombreakpoint: boolean = false
) => {
  switch (spacing) {
    case 1:
      return hasCustombreakpoint ? 'col-span-1' : 'col-span-12 md:col-span-1'
    case 2:
      return hasCustombreakpoint ? 'col-span-2' : 'col-span-12 md:col-span-2'
    case 3:
      return hasCustombreakpoint ? 'col-span-3' : 'col-span-12 md:col-span-3'
    case 4:
      return hasCustombreakpoint
        ? 'col-span-4'
        : 'col-span-12 md:col-span-6 lg:col-span-4 '
    case 5:
      return hasCustombreakpoint ? 'col-span-5' : 'col-span-12 md:col-span-5'
    case 6:
      return hasCustombreakpoint ? 'col-span-6' : 'col-span-12 md:col-span-6'
    case 7:
      return hasCustombreakpoint ? 'col-span-7' : 'col-span-12 md:col-span-7'
    case 8:
      return hasCustombreakpoint ? 'col-span-8' : 'col-span-12 md:col-span-8'
    case 9:
      return hasCustombreakpoint ? 'col-span-9' : 'col-span-12 md:col-span-9'
    case 10:
      return hasCustombreakpoint ? 'col-span-10' : 'col-span-12 md:col-span-10'
    case 11:
      return hasCustombreakpoint ? 'col-span-11' : 'col-span-12 md:col-span-11'
    case 12:
      return hasCustombreakpoint ? 'col-span-12' : 'col-span-12 md:col-span-12'
    case 'search':
      return hasCustombreakpoint ? 'col-span-4' : 'col-span-12 md:col-span-4'
    default:
      return hasCustombreakpoint ? 'col-span-4' : 'col-span-12 md:col-span-4'
  }
}

export const generateAlignment = (alignment?: string, type?: string): string => {
  let className = 'flex items-center w-full '
  switch (alignment) {
    case 'right':
      className += ' justify-end'
      break
    case 'center':
      className += ' justify-center'
      break
    case 'left':
      className += ' justify-start'
      break
    default:
      if (type === 'number') {
        className += ' justify-end'
      } else if (type === 'boolean' || type === 'date' || type === 'dateTime') {
        className += ' justify-center'
      } else {
        className += ' justify-start'
      }
      break
  }
  return className
}

export const generateKendoAlignment = (alignment?: string, type?: string): string => {
  let className = ' '
  switch (alignment) {
    case 'right':
      className += ' justify-end'
      break
    case 'center':
      className += ' justify-center'
      break
    case 'left':
      className += ' justify-start'
      break
    default:
      if (type === 'number') {
        className += ' justify-end'
      } else if (type === 'boolean' || type === 'date' || type === 'dateTime') {
        className += ' justify-center'
      } else {
        className += ' justify-start'
      }
      break
  }
  return className
}

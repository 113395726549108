import { RefObject, useEffect, useState } from 'react'

const useTableColumnWidth = (
  columns: any[],
  adjustWidth: number,
  elementRef: RefObject<HTMLDivElement>,
  hasAction: boolean,
  selection: boolean
) => {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (elementRef.current) {
        let screenWidth = elementRef.current
          ? elementRef.current.offsetWidth - adjustWidth
          : window.innerWidth

        let columnCount = columns.length

        // screenWidth = screenWidth - columnCount * 22 //padding 40px 2px border
        // screenWidth = screenWidth + 2 // border not in first and last column
        let totalReservedSize = hasAction ? 20 : 0
        totalReservedSize = selection ? totalReservedSize + 65 : totalReservedSize

        columns.forEach((col) => {
          if (col?.width) {
            totalReservedSize += col.width
            columnCount--
          }
        })

        screenWidth -= totalReservedSize
        const cellWidth = screenWidth / columnCount

        setWidth(cellWidth)
      }
    }

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Call the handler initially
    handleResize()

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [elementRef, columns, hasAction])

  return width < 150 ? 150 : width
}

export default useTableColumnWidth

const CheckIndeterMinateIcon = () => {
  return (
    // <path
    //   d="M3.55991 8.11328H12.8932"
    //   stroke="white"
    //   strokeWidth="1.33333"
    //   strokeLinecap="round"
    //   strokeLinejoin="round"
    // />
    <path
      d="M5 12H19"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}
export default CheckIndeterMinateIcon

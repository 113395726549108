const Default = () => {
  return (
    <path
      d="M8.37682 15.6163L2.71997 21.2732M11.6945 6.64181L10.1336 8.2027C10.0063 8.33003 9.94264 8.39369 9.87011 8.44427C9.80573 8.48917 9.73628 8.52634 9.66321 8.555C9.58089 8.58729 9.49261 8.60495 9.31604 8.64026L5.65157 9.37315C4.69927 9.56361 4.22312 9.65884 4.00036 9.9099C3.80629 10.1286 3.71767 10.4213 3.75783 10.7109C3.80392 11.0434 4.14727 11.3867 4.83399 12.0735L11.9197 19.1592C12.6064 19.8459 12.9498 20.1893 13.2822 20.2354C13.5719 20.2755 13.8646 20.1869 14.0833 19.9928C14.3343 19.7701 14.4295 19.2939 14.62 18.3416L15.3529 14.6771C15.3882 14.5006 15.4059 14.4123 15.4382 14.33C15.4668 14.2569 15.504 14.1875 15.5489 14.1231C15.5995 14.0505 15.6631 13.9869 15.7905 13.8596L17.3513 12.2987C17.4327 12.2173 17.4735 12.1766 17.5182 12.141C17.5579 12.1095 17.6 12.081 17.6441 12.0558C17.6937 12.0274 17.7466 12.0048 17.8524 11.9594L20.3468 10.8904C21.0745 10.5785 21.4384 10.4226 21.6036 10.1706C21.7482 9.95025 21.7999 9.68175 21.7475 9.42348C21.6876 9.12813 21.4077 8.84822 20.8479 8.28839L15.7048 3.14526C15.1449 2.58543 14.865 2.30552 14.5697 2.24565C14.3114 2.19329 14.0429 2.245 13.8226 2.38953C13.5706 2.55481 13.4146 2.91866 13.1028 3.64636L12.0338 6.14071C11.9884 6.24653 11.9657 6.29944 11.9374 6.34905C11.9122 6.39313 11.8837 6.43522 11.8521 6.47496C11.8166 6.51971 11.7759 6.56041 11.6945 6.64181Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}

export default Default

import { TableProps } from '@/common/customComponenttypes'
import { useState } from 'react'

import { Checkbox } from '../../common'
import HeaderCell from './HeaderCell'

export default function TableHead({
  columns,
  actionDropDown,
  handleSort,
  defaultSort,
  cellWidth,
  singleSelection,
  multiSelection,
  checkedKeys = [],
  setCheckedKeys,
  tableKey,
  data,
  handleMultiSelect
}: TableProps) {
  const [selectedColumn, setSelectedColumn] = useState(defaultSort)

  const handleSelectAll = (e: any) => {
    const { checked } = e.target
    const keys: any = checked ? data?.map((item) => item[tableKey as string]) : []
    const updatedKeys = [...keys]
    setCheckedKeys?.(updatedKeys)

    handleMultiSelect?.(updatedKeys)
  }
  return (
    <thead className="bg-table-headBG text-table-headColor text-xxs font-semibold w-full sticky top-0 z-5">
      <tr>
        {singleSelection && <th className="w-1 border-0"></th>}
        {multiSelection && (
          // <th className=" text-left w-2 border-0 z-50">
          <td className="p-cell text-m text-dark font-medium flex items-center justify-center ">
            <Checkbox
              id={`check-select-`}
              name={`check-select`}
              checked={
                data &&
                data?.length > 0 &&
                data?.every((item) => checkedKeys?.includes(item[tableKey as string]))
              }
              handleChange={(e) => handleSelectAll(e)}
            />
          </td>
          // </th>
        )}

        {columns.map((col, index) => (
          <HeaderCell
            column={col}
            key={index}
            selectedColumn={selectedColumn}
            setSelectedColumn={setSelectedColumn}
            handleSort={handleSort}
            cellWidth={cellWidth}
          />
        ))}
        {/* {actionDropDown && (
          <th className=" sticky right-0 w-[20px] bg-table-headBG">
            <div className=" w-[12px] h-full"></div>
          </th>
        )} */}
      </tr>
    </thead>
  )
}

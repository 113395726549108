import { DeletePopUp, Fab } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'
import DialogModal from '@/components/common/modal/DialogModal'
import {
  deleteDashboard,
  deleteDashboardTile,
  useDashBoradTileUpdate,
  useDashBoradTiles
} from '@/pages/dashboard/api'
import { useAppContext } from '@/store/context/appContext'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DashboardManageTabs from '../ManageTabs'
import NoData from '../NoData'
import DashboardTiles from './list'
import ManageTiles, { ManageTilesRef } from './manage/ManageTiles'

type Props = {
  dashboardId: string | number
  dashboardName: string
  resetDashboard: () => void
  setRenameTab: React.Dispatch<React.SetStateAction<boolean>>
}
type DashTilePayloadProps = {
  TileId: string
  DashId: string | number
}
let updateKey = 1

const DashboardTileList: React.FC<Props> = ({
  dashboardId,
  dashboardName,
  resetDashboard,
  setRenameTab
}) => {
  const manageTileRef = useRef<ManageTilesRef>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const fabRef = useRef<HTMLDivElement | null>(null)

  const { data, isLoading, refetch } = useDashBoradTiles(dashboardId)
  const [openMenu, setOpenMenu] = useState(false)
  const [openManageTab, setOpenManageTab] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState(1)
  const { showSnackBar } = useCustomSnackBar()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [rename, setRename] = useState<boolean>(false)
  const { t } = useTranslation()
  const { setIsCommonLoader } = useAppContext()
  useEffect(() => {
    setIsCommonLoader(isLoading)
  }, [isLoading])
  const onClose = () => {
    setActiveStep(1)
    setIsOpen(false)
    updateKey = updateKey + 1
  }
  const onOpen = () => {
    setActiveStep(1)
    setIsOpen(true)
    updateKey = updateKey + 1
  }
  const onSuccess = () => {
    setIsOpen(false)
  }
  const { mutate, isLoading: updating } = useDashBoradTileUpdate(onSuccess, dashboardId)
  const handleUpdate = () => {
    if (!manageTileRef.current?.handlValidation()) {
      showSnackBar('There is no changes to save ', 'error')
      return false
    }
    const selectedTiles = manageTileRef?.current?.getSelectedTiles()
    if (selectedTiles && selectedTiles.length > 0) {
      let payload: DashTilePayloadProps[] = []
      selectedTiles.forEach((item) => {
        payload.push({
          TileId: item.TileId,
          DashId: dashboardId
        })
      })
      mutate(payload)
    } else {
      showSnackBar('Select atleast on tile', 'error')
    }
  }
  const closeTile = (id: string) => {
    const payload = { DashTileId: id, DashId: dashboardId }

    deleteDashboardTile(payload)
      .then((res) => {
        showSnackBar(res || 'Tile removed successfully')
        refetch()
      })
      .catch((err: any) => {
        showSnackBar(err.response.data.error || 'Something went wrong', 'error')
      })
  }
  const handleDelete = () => {
    const payload = { DashId: dashboardId }

    deleteDashboard(payload)
      .then((res) => {
        showSnackBar(res || 'Tile removed successfully')
        resetDashboard()
      })
      .catch((err: any) => {
        showSnackBar(err.response.data.error || 'Something went wrong', 'error')
      })
  }
  const actionDropDown = [
    {
      title: 'Add Tile',
      slug: 'add',

      iconName: 'plus',
      id: 'add'
    },
    {
      title: 'Rename',
      slug: 'rename',
      iconName: 'Revert',
      id: 'rename'
    },
    {
      title: 'Delete',
      slug: 'delete',
      isWarning: true,
      iconName: 'delete',
      id: 'delete'
    }
  ]
  const handleMenuItemClick = (slug: string) => {
    setOpenMenu(false)
    switch (slug) {
      case 'add':
        onOpen()
        break
      case 'rename':
        setOpenManageTab(true)
        setRename(true)
        setRenameTab(true)
        break
      case 'delete':
        setDeleteOpen(true)
        break
    }
  }
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) && // &&
        (event.target as Node).nodeName !== 'path'
      ) {
        setOpenMenu(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])
  const handlePopupClose = () => {
    setOpenManageTab(false)
  }

  const handleNext = () => {
    if (activeStep === 1 && manageTileRef?.current?.selectedModule?.id === '') {
      showSnackBar(
        data.length ? 'Select a module to list the Tiles.' : 'No Modules are configured',
        'error'
      )
      return false
    }

    if (activeStep === 2 && !manageTileRef.current?.handlValidation()) {
      showSnackBar('Select a Tile to continue ', 'error')
      return false
    }
    setActiveStep(activeStep + 1)
  }
  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }
  return (
    <div className=" w-full pb-[15px] px-[15px] gap-3 ">
      <div
        className="flex flex-col overflow-auto "
        style={{ height: 'calc(100vh - 170px)' }}
      >
        {!isLoading && (
          <>
            {data?.length > 0 ? (
              <>
                <div className="grid grid-cols-12 gap-3 ">
                  {data.map((item: any, i: number) => (
                    <DashboardTiles
                      data={item}
                      index={i}
                      key={item.TileId}
                      handleClose={closeTile}
                    />
                  ))}
                </div>
              </>
            ) : (
              <NoData
                title={t('dashboardEmptyTileHead')}
                subtitle={t('You have not added a Tile yet')}
                button={{
                  label: t('createTile'),
                  icon: 'plus',
                  primary: false,
                  onClick: onOpen
                }}
              />
            )}
            <div
              ref={menuRef}
              className="absolute  dropdown right-0 bottom-[41px] p-[15px]  "
            >
              {openMenu && (
                <div
                  className={`dropdown-menu-dash rounded-button  absolute right-0 mb-2 w-max  shadow-lg bg-white focus:outline-none `}
                >
                  <div className="py-2 bg-white rounded-button w-full shadow-menudropdown">
                    {actionDropDown?.map((item) => (
                      <a
                        key={item.id}
                        href="#/"
                        className={`px-3 py-2.5 leading-7 hover:bg-table-hover hover:text-secondary`}
                        onClick={(e) => {
                          e.preventDefault()
                          handleMenuItemClick(item.slug)
                        }}
                      >
                        <div
                          className={`${
                            item.isWarning ? ' text-error-light' : ''
                          } text-common flex items-center w-full tracking-[0.24px] font-normal leading-4`}
                        >
                          <CustomIcons
                            name={item.iconName}
                            type="large"
                            className={` ${
                              item.isWarning ? ' text-error-light' : ''
                            }  text-center`}
                          />
                          <span
                            className={`pl-1.5 ${
                              item.isWarning
                                ? ' text-error-light hover:text-error-light'
                                : ''
                            }`}
                          >
                            {item.title}
                          </span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}
              <div ref={fabRef}>
                <Fab
                  icon={openMenu ? 'close' : 'three_dot'}
                  icontType={''}
                  size="lg"
                  onClick={() => {
                    setOpenMenu((prev) => !prev)
                  }}
                  className="fabShadow"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => {
          setDeleteOpen(false)
        }}
      />
      <DialogModal
        isOpen={isOpen}
        onClose={onClose}
        key={updateKey}
        title={t('Add Dashboard Tiles')}
        secondaryAction={activeStep !== 1 ? handleBack : onClose}
        secondaryActionLabel={activeStep !== 1 ? 'Back' : ''}
        type="lg"
        onSubmit={activeStep < 3 ? handleNext : handleUpdate}
        actionLabel={activeStep < 3 ? t('Next') : 'Save'}
        actionLoader={updating}
        body={
          <ManageTiles
            currentTiles={data}
            isOpen={isOpen}
            ref={manageTileRef}
            activeStep={activeStep}
            key={updateKey}
          />
        }
      />
      <DashboardManageTabs
        handleClose={handlePopupClose}
        isOpen={openManageTab}
        dashname={dashboardName}
        dashid={dashboardId}
        rename={rename}
      />
    </div>
  )
}

export default DashboardTileList

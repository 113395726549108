import {
  deleteTechnicalData,
  getTechnicalData,
  getTechnicalMipsData,
  postTechnicalData,
  updateTechnicalData
} from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'

export const getbudgetDetails = async (id: string) => {
  return await getTechnicalMipsData(
    `${apiTechnicalUrl.GET_BUDGET}/GetBudgetDetails/${id}`
  )
}

export const getBudgetItems = async (id: string) => {
  return await getTechnicalData(
    `${apiTechnicalUrl.GET_VESSEL_BUDGET}/GetBudgetItem?BudAccId=${id}`
  )
}

export const createBudgetItem = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.GET_VESSEL_BUDGET}/AddBudgetItem`, data)
}

export const updateBudgetItem = (data: any) => {
  return updateTechnicalData(
    `${apiTechnicalUrl.GET_VESSEL_BUDGET}/UpdateBudgetItem`,
    data
  )
}
export const fetchBudgetItemById = (row: any) => {
  return getTechnicalData(
    `${apiTechnicalUrl.GET_VESSEL_BUDGET}/GetBudgetItem?id=${row.BudgetItemId}&BudAccId=${row.BudgetAccountId}`
  )
}

export const deleteBudgetItem = (row: any) => {
  return deleteTechnicalData(
    `${apiTechnicalUrl.GET_VESSEL_BUDGET}/DeleteBudgetItem?id=${row.BudgetItemId}&BudAccId=${row.BudgetAccountId}`
  )
}

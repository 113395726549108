import { SectionWraper } from '@/components/app/common'
import { Button, CustomTab, CustomTabContainer, Fab } from '@/components/common'
import useDataStore from '@/store/dynamicStore/useDataStore'
import React, { useEffect, useState } from 'react'

import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { fetchCrewById, fetchKins } from '../../api'
import FamilyDetails, { CommonFamilyRefProps } from './familyDetails'
import PersonalDetails, { CommonUpdateRefProps } from './personalDetails'
import { useCrewProfileStore } from './store'

type Props = {
  crewId: string
  pageRouting: boolean
}
const CrewBasicInfoDetails: React.FC<Props> = ({
  crewId,
  pageRouting
}: {
  crewId: string
  pageRouting: boolean
}) => {
  // const params = useParams()
  const [section, setSection] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const profileRef = React.useRef<CommonUpdateRefProps>(null)
  const familyRef = React.useRef<CommonFamilyRefProps>(null)
  const [crewData, setCrewData] = useState<any>({})
  const [kinsData, setKinsData] = useState<any>([])
  const { personalEdit, setPersonalEdit, resetEdit, isEditMode } = useCrewProfileStore()
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activeTab, setActiveTab] = useState('personal')
  const { getData, dataStore } = useDataStore()

  const tabProps = [
    {
      id: 'personal',
      label: 'Personal Details'
    },
    {
      id: 'family',
      label: 'Family Details'
    }
  ]
  // const navigate=useNavigate()
  const getDetails = () => {
    setLoading(true)
    fetchCrewById(crewId)
      .then((res) => {
        setCrewData(res?.data || {})
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getKinList = () => {
    setLoading(true)
    fetchKins({ crewId: crewId })
      .then((res) => {
        setKinsData(res || {})
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const onTabClick = (tab: any) => {
    setSection(tab.id)
    if (!isEditMode()) {
      setActiveTab(tab.id)
    } else {
      setIsOpen(true)
    }
  }
  const handleConfirm = () => {
    setIsOpen(false)

    resetEdit()
    setActiveTab(section as string)
  }
  const getListDatas = async () => {
    getData('gender', { key: 'GENDER' }, '*')
    getData('maritals', { key: 'MARITALSTS' }, '*')
    getData('relations', { key: 'RELATIONS' }, '*')
  }

  useEffect(() => {
    getDetails()
  }, [crewId])
  useEffect(() => {
    setActiveTab('personal')
    getListDatas()
  }, [])

  useEffect(() => {
    if (activeTab === 'family') {
      getKinList()
    }
  }, [activeTab])

  const handleEdit = () => {
    setPersonalEdit(true)
    if (activeTab === 'personal') {
      profileRef?.current?.onEdit()
    }
  }
  const cancelEdit = () => {
    resetEdit()
    if (activeTab === 'personal') {
      profileRef?.current?.onCancel()
    }
  }
  const handleSubmit = () => {
    if (activeTab === 'personal') {
      profileRef?.current?.onSubmit()
    }
  }
  const handleSuccessSubmit = () => {
    resetEdit()
    getDetails()
  }

  const handleKinCreate = () => {
    familyRef?.current?.onCreate()
  }

  return (
    <div className="h-full">
      <div className="relative">
        {' '}
        <CustomTabContainer
          data={tabProps}
          // isLoading={loading}

          activeTab={activeTab}
          onClick={onTabClick}
          tabBGClass="bg-tab-detail rounded-t-sm custom-tab-shadow px-5"
        >
          <CustomTab id={'personal'}>
            <SectionWraper>
              <PersonalDetails
                getDetails={getDetails}
                onSubmiting={setIsSubmitting}
                onSuccessSubmit={handleSuccessSubmit}
                data={crewData}
                loading={loading}
                ref={profileRef}
              />
            </SectionWraper>
          </CustomTab>
          <CustomTab id={'family'} className="h-full">
            <SectionWraper className="h-full">
              <FamilyDetails
                crewId={crewId}
                data={kinsData}
                pageRouting={pageRouting}
                dataStore={dataStore}
                getDetails={getKinList}
                loading={loading}
                ref={familyRef}
              />
            </SectionWraper>
          </CustomTab>
        </CustomTabContainer>
        {pageRouting && (
          <div className="py-7">
            <div className="fixed bottom-10 right-10 p-3">
              {activeTab === 'personal' && (
                <>
                  {!personalEdit ? (
                    <Fab
                      icontType="large"
                      onClick={() => {
                        handleEdit()
                      }}
                      className="fabShadow"
                    />
                  ) : (
                    <div className="flex">
                      <Button
                        label={'Save'}
                        className="me-2"
                        primary={false}
                        isLoading={isSubmitting}
                        onClick={handleSubmit}
                      />
                      <Button
                        label={'Cancel'}
                        outlined
                        onClick={() => {
                          cancelEdit()
                        }}
                      />
                    </div>
                  )}
                </>
              )}

              {activeTab === 'family' && !pageRouting && (
                <Fab
                  icon="plus"
                  icontType="large"
                  onClick={() => {
                    handleKinCreate()
                  }}
                  className="fabShadow"
                />
              )}
            </div>
          </div>
        )}
      </div>
      <ConfirmModal
        isOpen={isOpen}
        modalId="errorPopup"
        title={'Confirmation'}
        type="warning"
        secondaryActionLabel="No"
        secondaryAction={() => setIsOpen(false)}
        actionLabel="Yes"
        onSubmit={() => handleConfirm()}
        topIcon="warning"
        scrollDisable={true}
        onClose={() => setIsOpen(false)}
        subTitle={'Do you want to discard all the changes made?'}
      />
    </div>
  )
}

export default CrewBasicInfoDetails

const SortAscIcon = () => {
  return (
    <path
      d="M12.8728 9.27273V22H11.0546V9.27273H5.6001L11.9637 2L18.3274 9.27273H12.8728Z"
      fill="currentColor"
    />
  )
}

export default SortAscIcon

import { create } from 'zustand'
type Content = { title?: string; subTitle?: string }
type PermissionStore = {
  isOpenDialoug: boolean
  setIsOpenDialoug: (value: boolean) => void
  content: Content
  setContent: (mesg?: Content) => void
}

export const usePermissionStore = create<PermissionStore>()((set) => ({
  isOpenDialoug: false,
  setIsOpenDialoug: (isOPen) => set(() => ({ isOpenDialoug: isOPen })),
  content: { title: '', subTitle: '' },
  setContent: (msg) => set(() => ({ content: msg }))
}))

import { createContext, useContext } from 'react'

interface ContextType {
  isCommonLoader: boolean
  isActionLoader: boolean
  setIsCommonLoader: (value: boolean) => void
  setIsActionLoader: (value: boolean) => void
}

const defaultValue: ContextType = {
  isCommonLoader: false,
  isActionLoader: false,
  setIsCommonLoader: () => {},
  setIsActionLoader: () => {}
}

export const AppContext = createContext<ContextType>(defaultValue)

export function useAppContext() {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('AppContext must be used within a MyProvider')
  }
  return context
}

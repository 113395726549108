import { ActionDropDown } from '@/common/commontypes'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { convertKeysInArrayToLowercase } from '@/utilities/formatString'
import React, { useEffect, useRef, useState } from 'react'

import {
  dyanmicActionGet,
  dynamicActionDelete,
  dynamicUpdateAction
} from '../../../apis/common.api'
import { Portal } from '../../common'
import CustomIcons from '../icons/customIcons'
import Loader from '../loader/PageLoader'
import SwitchComponent from './SwitchComponent'

type Props = {
  iconName?: string
  disabled?: boolean
  rowData?: any
  scrollRef?: any
  staticMenus?: {
    title: string
    slug: string
    isWarning?: boolean
    iconName: string
    hidden?: boolean
    id: string
    menuIconClass?: string
  }[]
  iconClass?: string
  iconSize?: string
  isMouseOut?: boolean
  updateMenuRef?: (ref: HTMLDivElement | null) => void
  position?: string
  handleMenuActions?: (slug: string, rowData?: any) => void
  handleRefresh?: (id?: string) => void
  handleMenuItems: () => any
  version?: string
}

export default function KendoDynamicDropDown({
  iconName,
  iconClass,
  scrollRef,
  disabled = false,
  updateMenuRef,
  staticMenus,
  handleMenuActions,
  position,
  isMouseOut,
  rowData,
  handleMenuItems,
  handleRefresh,
  iconSize,
  version
}: Props) {
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const [menuOrigin, setMenuOrigin] = useState<'top' | 'bottom'>('top')
  const menuButtonRef = useRef<HTMLButtonElement | null>(null)
  const [actionLoader, setActionLoader] = useState<boolean>(false)

  const [confirmData, setConfirmData] = useState<any>({
    isOpen: false,
    primaryApi: '',
    method: ''
  })
  const [actionDropDown, setActionDropDown] = useState<ActionDropDown[]>()
  const menuRef = useRef<HTMLDivElement | null>(null)
  const dropRef = useRef<HTMLDivElement>(null)
  //const [dropdownPoint, setDropdownPoint] = useState({ x: 0, y: 0 })
  const [dropdownPosition, setDropdownPosition] = useState('bottom-position')
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  })
  const addUniqueIdToArray = (array?: ActionDropDown[]) => {
    return array?.map((item: ActionDropDown) => ({
      ...item,
      id: `id_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`
    }))
  }
  const adjustDropdownPoint = (e: any) => {
    if (!openMenu && menuButtonRef.current) {
      const rect = menuButtonRef.current.getBoundingClientRect()
      const topSpace = rect.top
      const bottomSpace = window.innerHeight - rect.bottom
      const leftSpace = rect.left
      const rightSpace = window.innerWidth - rect.right
      const dynamicMenuHeight: number =
        modifiedArray?.length && modifiedArray?.length > 0
          ? modifiedArray?.length * 50
          : 0
      const staticMenuHeight: number =
        staticMenus?.length && staticMenus?.length > 0 ? staticMenus?.length * 50 : 0
      const totalMenuHeight = dynamicMenuHeight + staticMenuHeight
      const dropdownHeight = totalMenuHeight > 300 ? totalMenuHeight : 300
      const dropdownWidth = 200

      let newPosition = 'bottom-right'

      if (bottomSpace < dropdownHeight && topSpace > dropdownHeight) {
        newPosition = 'top-right'
      }

      if (rightSpace < dropdownWidth && leftSpace > dropdownWidth) {
        newPosition = newPosition.replace('right', 'left')
      }

      if (topSpace < dropdownHeight && bottomSpace < dropdownHeight) {
        newPosition = leftSpace > rightSpace ? 'bottom-left' : 'bottom-right'
      }

      setDropdownPosition(newPosition)
      setMenuPosition({
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
        bottom: window.innerHeight - rect.bottom,
        right: window.innerWidth - rect.right
      })
    }
    /*setTimeout(() => {
      setOpenMenu(!openMenu)
    }, 200)*/
    /*
    const position = e.target.getBoundingClientRect()
    const x = position.left - 10
    const y = position.bottom + 10


    let adjustedX = x
    let adjustedY = y
  
    setDropdownPoint({ x: adjustedX, y: adjustedY })*/
  }

  const handleScroll = () => {
    setOpenMenu(false)
    removeHoverClass()
  }

  const removeHoverClass = () => {
    const rows = scrollRef.current?.querySelectorAll('tr')
    if (rows) {
      rows.forEach((row: any) => {
        if (row.classList) row.classList.remove('k-hover')
      })
    }
  }

  const addHoverClassDisable = () => {
    const rows = scrollRef.current?.querySelectorAll('tr')
    if (rows) {
      rows.forEach((row: any) => {
        if (row.classList) row.classList.add('k-disabled-hover')
      })
    }

    setTimeout(() => {
      removeHoverClassDisable()
    }, 5000)
  }

  const removeHoverClassDisable = () => {
    const rows = scrollRef.current?.querySelectorAll('tr')
    if (rows) {
      rows.forEach((row: any) => {
        if (row.classList) row.classList.remove('k-disabled-hover')
      })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    if (scrollRef && scrollRef.current && scrollRef.current !== null) {
      scrollRef.current.addEventListener('scroll', handleScroll)
    }
    const mainElement = document.querySelector('main')
    mainElement?.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (scrollRef && scrollRef.current) {
        scrollRef.current?.removeEventListener('scroll', handleScroll)
      }

      const mainElement = document.querySelector('main')
      mainElement?.removeEventListener('scroll', handleScroll)
    }
  }, [openMenu])

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpenMenu(false)
      removeHoverClass()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (isMouseOut) {
      document.addEventListener('mouseout', handleClickOutside)
      return () => {
        document.removeEventListener('mouseout', handleClickOutside)
      }
    }
  }, [isMouseOut])

  useEffect(() => {
    window.addEventListener('mousemove', removeHoverClassDisable)
    return () => {
      window.removeEventListener('mousemove', removeHoverClassDisable)
    }
  }, [])

  const toggleMenu = async (e: any) => {
    const ele = e.currentTarget
    adjustDropdownPoint(e)
    removeHoverClass()
    setTimeout(() => {
      if (ele && ele.closest('tr')) ele.closest('tr').classList.toggle('k-hover')
      setIsLoading(true)
    }, 100)

    const data = await handleMenuItems()
    setConfirmData({})
    setIsLoading(false)
    if (version === 'v2') {
      //Response structure in v2 is defferent from v1
      const final = data?.data ? data?.data : data
      setActionDropDown(convertKeysInArrayToLowercase(final))
    } else {
      setActionDropDown(data)
    }
    setOpenMenu(true)
    adjustDropdownPoint(e)
  }

  useEffect(() => {
    if (updateMenuRef) {
      updateMenuRef(menuRef.current)
    }
  }, [menuRef])
  const { showSnackBar } = useCustomSnackBar()
  const modifiedArray = addUniqueIdToArray(actionDropDown)

  const handleConfrimMessage = (
    api: string,
    method: string,
    actionData: ActionDropDown
  ) => {
    setConfirmData({
      isOpen: true,
      primaryApi: api,
      method: method,
      actionData: actionData
    })
    addHoverClassDisable()
    setOpenMenu(false)
  }

  const handleconfrimClose = () => {
    setConfirmData({ isOpen: false })
  }
  const handleStaticMenuItemClick = (slug: string, dropData: any) => {
    const data = { ...rowData, dropData: dropData }
    handleMenuActions?.(slug, data)
    addHoverClassDisable()
    setOpenMenu(false)
  }
  const handleStatusAction = (
    api: string,
    method: string,
    actionData: ActionDropDown
  ) => {
    let param = {}
    const primeApi = api || actionData.Api
    const module: any = !version ? actionData.Module : `${actionData.Module}_v2`
    if (primeApi) {
      setActionLoader(true)
      const urlParts = primeApi.split('/')
      //There is a url format difference in v1 and v2, So we should use two different condition
      const modifiedUrl =
        version === 'v2' ? urlParts.slice(2).join('/') : '/' + urlParts.slice(2).join('/')
      if (method === 'DELETE') {
        setActionLoader(true)
        dynamicActionDelete(modifiedUrl, module)
          .then((res) => {
            showSnackBar(res)
            handleRefresh?.()
          })
          .catch((err) => {
            showSnackBar(err, 'error')
          })
          .finally(() => {
            setActionLoader(false)
          })
      } else if (method === 'GET') {
        dyanmicActionGet(modifiedUrl, module)
          .then((res) => {
            showSnackBar(res)
            handleRefresh?.()
          })
          .catch((err) => {
            showSnackBar(err, 'error')
          })
          .finally(() => {
            setActionLoader(false)
          })
      } else {
        let data = []
        data = actionData.FromBody ? JSON.parse(actionData.Data) : null
        actionData.FromBody
          ? dynamicUpdateAction(modifiedUrl, data, module)
              .then((res) => {
                if (typeof res === 'string') {
                  showSnackBar(res)
                  handleRefresh?.()
                } else {
                  showSnackBar(res)
                  handleRefresh?.(res.Id)
                }
              })
              .catch((err) => {
                showSnackBar(err, 'error')
              })
              .finally(() => {
                setActionLoader(false)
              })
          : dynamicUpdateAction(modifiedUrl, param, module)
              .then((res) => {
                showSnackBar(res)
                handleRefresh?.()
              })
              .catch((err) => {
                showSnackBar(err, 'error')
              })
              .finally(() => {
                setActionLoader(false)
              })
      }
    }

    // handleconfrimClose()
  }

  const buttonWidth = menuButtonRef.current ? menuButtonRef.current.offsetWidth : 0

  return (
    <>
      <div className="" ref={menuRef}>
        <button
          className={'dropdown relative'}
          disabled={disabled}
          onClick={(e) => toggleMenu(e)}
          ref={menuButtonRef}
        >
          <CustomIcons type="large" name={iconName} className={iconClass} />
        </button>
        {openMenu && (
          <Portal>
            <div
              ref={dropRef}
              className={`custom-dropdown-menu dropdown-menu ${dropdownPosition}  absolute ${
                position ? position : 'right-0'
              } mt-2 w-max  shadow-lg bg-white focus:outline-none `}
              style={{
                top: dropdownPosition.startsWith('bottom')
                  ? `${menuPosition.top + window.scrollY + 30}px`
                  : 'auto',
                bottom: dropdownPosition.startsWith('top')
                  ? `${menuPosition.bottom + 30}px`
                  : 'auto',
                left: dropdownPosition.endsWith('right')
                  ? `${menuPosition.left + 0}px`
                  : 'auto',
                right: dropdownPosition.endsWith('left')
                  ? `${window.innerWidth - menuPosition.left - buttonWidth + 10}px`
                  : 'auto'
              }}
            >
              <div className="py-2 bg-white  w-full shadow-menudropdown">
                {modifiedArray?.map((item) => (
                  <React.Fragment key={item.id}>
                    {item?.Name?.toLowerCase()?.startsWith('no action') && (
                      <a
                        key={item.id}
                        href="#/"
                        className={`px-3 py-2.5 leading-7 hover:bg-table-hover hover:text-secondary `}
                      >
                        <div
                          className={`${
                            item.isWarning || item.Method === 'DELETE'
                              ? 'text-error-light'
                              : ''
                          } text-common flex items-center w-full tracking-[0.24px] font-normal leading-4 `}
                        >
                          <CustomIcons
                            name={
                              item.Symbol === 'confirmSuccess'
                                ? 'confirmSuccessIcon'
                                : item.Symbol
                            }
                            viewBox={item.Symbol === 'confirmSuccess'}
                            type={iconSize ?? 'large'}
                            className={`${
                              item.isWarning || item.Method === 'DELETE'
                                ? 'text-error-light'
                                : ''
                            } text-center `}
                          />
                          <span className="pl-2">{item.Name}</span>
                        </div>
                      </a>
                    )}
                    {item.Method !== 'NONE' && (
                      <a
                        key={item.id}
                        href="#/"
                        className={`px-3 py-2.5 leading-7 hover:bg-table-hover hover:text-secondary `}
                        onClick={(e) => {
                          e.preventDefault()
                          item?.Name?.startsWith('Print')
                            ? handleStatusAction(item.API as string, item.Method, item)
                            : handleConfrimMessage(item.API as string, item.Method, item)
                        }}
                      >
                        <div
                          className={`${
                            item.isWarning || item.Method === 'DELETE'
                              ? 'text-error-light'
                              : ''
                          } text-common flex items-center w-full tracking-[0.24px] font-normal leading-4 `}
                        >
                          <CustomIcons
                            name={
                              item.Symbol === 'confirmSuccess'
                                ? 'confirmSuccessIcon'
                                : item.Symbol
                            }
                            viewBox={item.Symbol === 'confirmSuccess'}
                            type={iconSize ?? 'large'}
                            className={`${
                              item.isWarning || item.Method === 'DELETE'
                                ? 'text-error-light'
                                : ''
                            } text-center `}
                          />
                          <span className="pl-2">{item.Name}</span>
                        </div>
                      </a>
                    )}
                  </React.Fragment>
                ))}
                {staticMenus?.map((item) => (
                  <React.Fragment key={item.id}>
                    {!item?.hidden && (
                      <a
                        key={item.id}
                        href="#/"
                        className={`px-3 py-2.5 leading-7 hover:bg-table-hover hover:text-secondary `}
                        onClick={(e) => {
                          e.preventDefault()
                          handleStaticMenuItemClick?.(item.slug, item)
                        }}
                      >
                        <div
                          className={`${
                            item.isWarning ? 'text-error-light' : ''
                          } text-common flex items-center w-full tracking-[0.24px] font-normal leading-4 `}
                        >
                          <CustomIcons
                            name={item.iconName}
                            type={iconSize ?? 'large'}
                            className={`${
                              item.isWarning ? 'text-error-light' : ''
                            } text-center ${item.menuIconClass}`}
                          />
                          <span className="pl-2">{item.title}</span>
                        </div>
                      </a>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Portal>
        )}
      </div>
      {confirmData.isOpen && (
        <Portal>
          <SwitchComponent
            handleRefresh={handleRefresh}
            confirmData={confirmData}
            rowData={rowData}
            handleStatusAction={handleStatusAction}
            handleconfrimClose={handleconfrimClose}
            actionLoader={actionLoader}
          />
        </Portal>
      )}
      {isLoading && (
        <Portal>
          <div className="fixed top-0 left-0 w-full h-full z-50">
            <Loader isActive={isLoading} />
          </div>
        </Portal>
      )}
    </>
  )
}

import { CustomKendoTable } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'

import { fetchPendingAccountsToAdd } from '../../../../pages/vesselBudget/details/budgetdetails/api'

import { formContainerClass } from '@/styles/variables'

import { guid } from '@/constants'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'

type FormProps = {
  data: any
  budgetData: any
  open: boolean
  onClose: () => void
  onSubmit: (data: any) => Promise<any>


  title: string
  refetch: any
  selectedNode: any
}
export const ManageAccountWithChart: React.FC<FormProps> = ({
  budgetData,
  open,
  title,
  onClose,
  onSubmit,
  selectedNode,
  refetch
}) => {
  const [formData, setFormData] = useState<any>(null)
const [isFetching,setIsFetching]=useState(false)
  const [accountData, setAccountData] = useState<any>([])
  //const { t } = useTranslation()
  const { showSnackBar } = useCustomSnackBar()
  useEffect(() => {
    setIsFetching(true)
    fetchPendingAccountsToAdd({
      pageNo: 0,
      pageSize: 0,
      sortBy: '',
      BudgetId: budgetData.budgetId,
      ParentId: selectedNode?.budgetAccountId || ''
    }).then((res: any) => {
      setAccountData(res.Results)
    }).finally(() => {
      setIsFetching(false)
    })
  }, [open])


  const handleSubmit = () => {
    const selAccDta: any = []
    accountData.forEach((item: any) => {
   
      if (formData.some((fd: string) => fd === item.AccountId)) {
        selAccDta.push({
          BudgetAccountId: guid,
          AccountDescription: item?.AccountDescription,
          AccountId: item?.AccountId,
          PostingType: item.PostingType,
          ChartDetailId: item.ChartDetailId
        })
      }
    })
    onSubmit(selAccDta)
      .then((res: any) => {
        handleCloseForm()
        showSnackBar(res)
        refetch()
      })
      .catch((err: any) => {
 
        showSnackBar(err?.response?.data?.error, 'error')
      })
  }

  const handleCloseForm = () => {
    setFormData(null)
    setAccountData(null)
    onClose()
  }

  const handleMultiSelect = (d: any) => {
    setFormData(d)
  }

  const formFields: any[] = [
    {
      title: 'Account Code',

      field: 'AccountId'
    },
    {
      title: 'Account Description',
      field: 'AccountDescription'
    },
    {
      title: 'Posting Type',
      field: 'PostingTypeName'
    }
  ]

  return (

      <DialogModal
        isOpen={open}
        small={true}
        type="sm"
        title={title}
        body={
          <div className="h-full relative w-full">
            <div className={formContainerClass}>
              <div className="col-span-12">
                <CustomKendoTable
                  columns={formFields}
                  data={accountData}
                  async={true}
                  pagination={false}
                  title=""
                  isFetching={isFetching}
                  multiSelection
                  checkedKeys={formData}
                  handleMultiSelect={handleMultiSelect}
                  rowKey={'AccountId'}
                  toolBar={false}
                  sortable={false}
                  resizable={true}
                />
              </div>
            </div>
          </div>
        }
        onClose={handleCloseForm}
        actionLabel={'Save'}
        onSubmit={handleSubmit}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={ 'Cancel'}
      />
   
  )
}

import { z } from 'zod'
export const getMaxValue = (val: any, defaultVal: number) => {
  if (typeof val === 'string') {
    return parseInt(val) > 0 ? parseInt(val) : defaultVal
  } else if (typeof val === 'number') {
    return val > 0 ? val : defaultVal
  }

  return defaultVal
}
export const getMinValue = (val: any, defaultVal: number) => {
  if (typeof val === 'string') {
    return parseInt(val) > 1 ? parseInt(val) : defaultVal
  } else if (typeof val === 'number') {
    return val > 1 ? val : defaultVal
  }

  return defaultVal
}
export const FormSchema = z
  .object({
    commTypeId: z
      .object({
        id: z.union([z.number(), z.string()]),
        name: z.string(),
        param1: z.union([z.number(), z.string()]).nullable(),
        param2: z.union([z.number(), z.string()]).nullable(),
        param3: z.union([z.number(), z.string()]).nullable()
      })
      .refine(
        (data) => data.id !== null && data.id !== undefined && data.name !== '',
        'Communication Type is required'
      ),
    commNumber: z.string({ required_error: 'Communication Details are required' }),
    archived: z.boolean(),
    vesCommInfoId: z.string().optional().nullable(),
    vesselId: z.string()
  })
  .superRefine((data, ctx) => {
    const maxLen = getMaxValue(data.commTypeId.param3, 150)
    const minLen = getMinValue(data.commTypeId.param2, 1)
    if (data.commTypeId.param1 === 2 || data.commTypeId.param1 === '2') {
      const emailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/
      if (!emailPattern.test(data.commNumber)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Should be a valid email',
          path: ['commNumber']
        })
      }
    } else if (data.commTypeId.param1 === 3 || data.commTypeId.param1 === '3') {
      if (isNaN(Number(data.commNumber))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Communication Number should be a number',
          path: ['commNumber']
        })
      }
    }
    if (data.commNumber.length === 0) {
      let msg = 'Communication Details are'
      if (data.commTypeId.param1 === 2 || data.commTypeId.param1 === '2') msg = 'Email is'
      else if (data.commTypeId.param1 === 3 || data.commTypeId.param1 === '3')
        msg = 'Communication Number is'
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `${msg} required`,
        path: ['commNumber']
      })
    } else if (data.commNumber.length > maxLen) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Max length is ${maxLen} characters`,
        path: ['commNumber']
      })
    } else if (data.commNumber.length < minLen) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Min length is ${minLen} characters`,
        path: ['commNumber']
      })
    }
  })

export type FormDataType = z.infer<typeof FormSchema>

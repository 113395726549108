import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const DashReload: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M6.5 13C4.69896 13 3.16536 12.3669 1.89922 11.1008C0.633073 9.83463 0 8.30104 0 6.5C0 4.69896 0.633073 3.16536 1.89922 1.89922C3.16536 0.633073 4.69896 0 6.5 0C7.65104 0 8.6599 0.233594 9.52656 0.700781C10.3932 1.16797 11.1448 1.80781 11.7812 2.62031V0H13V5.15937H7.84062V3.94062H11.2531C10.7385 3.12812 10.0818 2.47135 9.28281 1.97031C8.48385 1.46927 7.55625 1.21875 6.5 1.21875C5.02396 1.21875 3.77474 1.72995 2.75234 2.75234C1.72995 3.77474 1.21875 5.02396 1.21875 6.5C1.21875 7.97604 1.72995 9.22526 2.75234 10.2477C3.77474 11.2701 5.02396 11.7812 6.5 11.7812C7.62396 11.7812 8.65312 11.4596 9.5875 10.8164C10.5219 10.1732 11.1719 9.32344 11.5375 8.26719H12.7969C12.4042 9.68906 11.6255 10.8333 10.4609 11.7C9.29635 12.5667 7.97604 13 6.5 13Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DashReload

import { getProfilePhoto } from '@/apis/common.api'
import AvatarCircle from '@/components/app/common/AvatarCircle'
import CustomIcons from '@/components/common/icons/customIcons'
import { useAppStore, useExploreMenu, useHeaderStore } from '@/store/appStore'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ProfileMenu from './ProfileMenu'
export default function Header() {
  const { menuOpened, setMenuOpened, setIsMyVesselOpen, isMyVesselOpen } = useAppStore()
  const { headerTitle, setHeaderTitle, setModuleId } = useHeaderStore()
  const { exploreMenu } = useExploreMenu()
  const { isMobile, isTab } = useScreenBreakPoint()

  const location = useLocation()

  useEffect(() => {
    const localPath = location.pathname
    const normalizedPath = localPath.startsWith('/') ? localPath.slice(1) : `${localPath}`
    let pathParts = normalizedPath.split('/')

    if (pathParts.length > 3) {
      pathParts = pathParts.slice(0, 2)
    }
    const trimmedPath = pathParts.join('/')
    const header = exploreMenu?.find(
      (item: any) =>
        item.PathName &&
        (item.PathName === trimmedPath || item.PathName.includes(trimmedPath))
    )

    setHeaderTitle(header?.FunctionName || 'Dashboard')
    setModuleId(header?.FunctionId ?? '')
  }, [location.pathname])

  const getPhoto = async () => {
    getProfilePhoto({ file: true })
  }
  useEffect(() => {
    getPhoto()
  }, [])

  return (
    <div
      className={`${
        isTab || isMobile ? '' : 'ml-[54px]'
      } p-[18px] fixed top-0 bg-white w-full h-[54px] border-b border-grey-borderLight flex justify-between items-center `}
    >
      <div className="flex items-center justify-center gap-5 cursor-pointer">
        <div className=" flex  items-center justify-center gap-[11px]">
          {(isMobile || isTab) && (
            <CustomIcons
              name="hamburger"
              className="cursor-pointer"
              onClick={() => {
                setMenuOpened(!menuOpened)
              }}
            />
          )}
          <h1
            className={` text-text-primary text-xl font-medium leading-6 tracking-wide`}
          >
            {headerTitle}
          </h1>
        </div>
      </div>

      <div
        className={`${
          isTab || isMobile ? 'mr-[10px]' : 'mr-[67px]'
        } flex  cursor-pointer justify-center items-center gap-1 pr-2 `}
        onClick={() => {
          setMenuOpened(false)
        }}
      >
        <CustomIcons
          name="Ship_Icon"
          className="mr-2"
          type="large"
          onClick={() => setIsMyVesselOpen(!isMyVesselOpen)}
        />
        <CustomIcons name="help" className="mr-2" type="large" />
        <CustomIcons name="Settings_Icon" className="mr-2" type="large" />
        <CustomIcons name="Bell_Icon" className="mr-2" type="large" />
        <span className=" rounded-full m-0 p-0">
          {/* <img
            src="/assets/profile.png"
            className="w-[40px] h-[40px] bg-cover rounded-full "
            alt="user"
          />  */}
          <AvatarCircle />
        </span>

        {/* <Icons name="header-drop-arrow" /> */}
        {(isMobile || isTab) && <ProfileMenu />}
      </div>
      {/* <MenuComponent
        openMenu={openMenu}
        handleClose={() => setOpenMenu(false)}
        actionDropDown={profileMenu}
      /> */}
    </div>
  )
}

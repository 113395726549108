import { ActionDropDown } from '@/common/commontypes'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { useEffect, useState } from 'react'
import { getWorkFlowStatus, updateWOrkFlowStatus } from '../../../apis/common.api'

import useScreenBreakPoint from '../../../utilities/useScreenBreakpoint'
import { Button } from '../index'
import TextArea from '../inputs/TextArea'
import DialogModal from '../modal/DialogModal'

type Props = {
  actionData: ActionDropDown
  handleConfirmClose?: () => void
  openAction: boolean
  actionLoader?: boolean
  handleRefresh?: (id?: string) => void
}
export default function StatusUpdate({
  actionData,
  actionLoader,
  handleConfirmClose,
  openAction,
  handleRefresh
}: Props) {
  const [remarks, setRemarks] = useState<string>('')
  const [workFlowData, setWorkFlowData] = useState<any>()
  const [actionItemData, setactionItemData] = useState(JSON.parse(actionData.Data))
  const [loading, setLoading] = useState(false)
  const { showSnackBar } = useCustomSnackBar()
  const getWorkflowStatus = () => {
    setLoading(true)
    getWorkFlowStatus(actionItemData?.StatusId)
      .then((res) => res)
      .then((res) => {
        setWorkFlowData(res)
        setLoading(false)
      })
      .catch((err) => {
        showSnackBar(err.response.data.error, 'error')
      })
  }
  useEffect(() => {
    let data = JSON.parse(actionData.Data)
    setactionItemData(JSON.parse(actionData.Data))
    if (!data?.Revert) {
      getWorkflowStatus()
    }
  }, [actionData.Data])

  const handleSubmit = (flag: boolean) => {
    const formData = {
      ObjId: actionItemData.ObjId,
      Remarks: remarks,
      WorkflowCode: actionItemData.WorkflowCode,
      StatusId: actionItemData.StatusId,
      RoleId: '00000000-0000-0000-0000-000000000000',
      Reverted: actionItemData.Revert ?? false,
      Rejected: flag
    }
    updateWOrkFlowStatus(formData)
      .then((res: any) => {
        showSnackBar(res)
        handleConfirmClose?.()
        handleRefresh?.()
      })
      .catch((err) => {
        showSnackBar(err.response.data.error, 'error')
      })
  }
  const { isMobile, isTab } = useScreenBreakPoint()

  return (
    <DialogModal
      isOpen={openAction}
      title={'Confirmation'}
      type="sm"
      actionBody={
        <div
          className={`flex gap-2.5 justify-end ${isMobile && 'flex-col gap-4'}  ${
            isTab && 'justify-center'
          }`}
        >
          {loading ? (
            <>
              <Button
                label="Reject"
                className=" bg-background-skeltonLoader text-background-skeltonLoader border-0"
                disabled
                primary={false}
                outlined={false}
              />
              <Button
                label="Reject"
                className=" bg-background-skeltonLoader  text-background-skeltonLoader"
                disabled
                isLoading={!loading}
                primary={false}
              />
            </>
          ) : (
            <>
              {workFlowData?.Reject && (
                <Button
                  label="Reject"
                  isWarning
                  outlined
                  onClick={() => handleSubmit(true)}
                />
              )}
              {(actionItemData?.Revert || workFlowData?.ActionBtnText) && (
                <Button
                  label={actionItemData?.Revert ? 'Revert' : workFlowData?.ActionBtnText}
                  onClick={() => handleSubmit(false)}
                  primary={false}
                />
              )}
            </>
          )}

          <Button primary={false} label="Cancel" outlined onClick={handleConfirmClose} />
        </div>
      }
      body={
        <div className="flex flex-col gap-2">
          {loading ? (
            <div className=" bg-background-skeltonLoader h-4 w-[120px] rounded-button"></div>
          ) : (
            <div className="font-semibold text-common">
              {actionItemData.Revert
                ? actionData.Description ?? ''
                : workFlowData?.ActionMessage ?? ''}
            </div>
          )}

          <TextArea
            id="remarks"
            name="remarks"
            onChange={(e) => setRemarks(e.target.value)}
            label={``}
            placeholder={`Comments (Optional)`}
          />
        </div>
      }
    ></DialogModal>
  )
}

import { CsvBuilder } from 'filefy'
import { Columns } from '../../../../common/customComponenttypes'
import formatDate from '../../../../utilities/usedateformat'

type ColumnDef = Columns

type ExportProps = {
  columns: ColumnDef[]
  data?: any[]
  title: string
  exportFileName?: string | (() => string)
  exportDelimiter?: string // Assuming delimiter can be string type
}

const byString = (data: any, field: string): any => {
  // Implementation of byString function
  // ...
}

const defaultExportCsv = (props: ExportProps): void => {
  const dateFormat = (type: string, val: Date) => {
    switch (type) {
      case 'date':
        return formatDate(val)
      case 'dateTime':
        return formatDate(val, 'DD-MMM-YYYY hh:mm')
    }
    return val
  }

  const columns: ColumnDef[] = props.columns.filter(
    (columnDef) =>
      (!columnDef.hidden || columnDef.export) &&
      columnDef.export !== false &&
      columnDef.field
  )

  const formattedData = props.data
    ? props.data.map((rowData) =>
        columns.map(
          (columnDef) => rowData[columnDef.field] ?? byString(rowData, columnDef.field)
        )
      )
    : []

  columns.forEach((item, colIndex) => {
    formattedData.forEach((dataRow, rowIndex) => {
      if (item.type === 'date' || item.type === 'dateTime') {
        //const format = item.type === 'date' ? 'TabledateFormat' : 'TabledateTimeFormat'
        formattedData[rowIndex][colIndex] = dataRow[colIndex]
          ? dateFormat(item.type, dataRow[colIndex])
          : ''
      } else if (dataRow[colIndex] === true) {
        formattedData[rowIndex][colIndex] = 'Yes'
      } else if (dataRow[colIndex] === false) {
        formattedData[rowIndex][colIndex] = 'No'
      }
    })
  })

  let fileName = props.title || 'data'
  if (props.exportFileName) {
    fileName =
      typeof props.exportFileName === 'function'
        ? props.exportFileName()
        : props.exportFileName
  }

  // Assuming you've defined or imported CsvBuilder type
  const builder = new CsvBuilder(fileName + '.csv')
  builder
    .setDelimeter(props.exportDelimiter || ',') // default to comma if no delimiter provided
    .setColumns(columns.map((columnDef) => columnDef.title))
    .addRows(formattedData)
    .exportFile()
}

export default defaultExportCsv

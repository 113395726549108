import { TabItemProps, TabProps } from '@/common/customComponenttypes'
import React from 'react'

import Fab from '../buttons/Fab'

interface WrapperProps {
  children: React.ReactNode
  activeTab: string | number
  fromDashboard?: boolean
}
interface ActiveTabProps {
  activeTab: string | number
}
const Wrapper: React.FC<WrapperProps> = ({ children, activeTab }) => {
  return (
    <div className="wrapper h-full">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            activeTab: activeTab
          } as Partial<ActiveTabProps>)
        }
        return child
      })}
    </div>
  )
}

const CustomTabContainer: React.FC<TabProps> = ({
  data,
  onClick,
  children,
  tabBGClass,
  newTab = false,
  onAdd = () => {},
  activeTab,
  isLoading
}) => {
  const generateClassName = (tab: TabItemProps): string => {
    let generatedClassName = 'w-max text-common leading-6  '
    if (tab.id === activeTab) {
      generatedClassName += ' text-[#0C121D] font-medium cursor-default'
    } else {
      if (tab.disabled) {
        generatedClassName += ' text-grey-dark opacity-70 font-medium cursor-not-allowed'
      } else {
        generatedClassName +=
          ' text-text-secondary hover:text-primary font-medium cursor-pointer'
      }
    }
    return generatedClassName
  }
  const handleClick = (item: TabItemProps) => {
    if (!item.disabled) {
      onClick(item)
    }
  }
  return (
    <div className="w-full flex flex-col h-full gap-[15px] ">
      <div
        className={`w-full overflow-auto flex gap-6 bg-white ${
          tabBGClass ? tabBGClass : ''
        }`}
      >
        {isLoading && (
          <div className=" flex gap-2 py-3">
            {[...Array(6)]?.map((item: any) => (
              <div
                key={item}
                className="custom-skelton-gradient py-3 w-[85px] h-[14px]  rounded-[30px]"
              ></div>
            ))}
          </div>
        )}
        {!isLoading &&
          data.map((tab: TabItemProps) => (
            <div
              key={tab.id}
              className="relative py-3 flex flex-col items-center justify-center"
            >
              <div
                className={` transition-all ${generateClassName(tab)}`}
                onClick={() => {
                  handleClick(tab)
                }}
              >
                <p className="w-max ">{tab.label}</p>
              </div>
              <div
                className={`w-full h-[2px] transition-all duration-300  ease-in ${
                  tab.id === activeTab ? 'bg-primary' : 'bg-transparent'
                } absolute bottom-0 `}
              />
            </div>
          ))}
        {newTab && (
          <div className="flex items-center justify-center">
            <Fab
              icon="plus"
              size="xs"
              icontType="medium"
              primary={false}
              onClick={() => onAdd()}
            />
          </div>
        )}
      </div>
      <div className={`tab-section h-full`}>
        <Wrapper activeTab={activeTab}>{children}</Wrapper>
      </div>
    </div>
  )
}

export default CustomTabContainer

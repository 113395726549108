import { MenuExplorerProps, SideNavProps } from '@/common/customComponenttypes'
import CustomIcons from '@/components/common/icons/customIcons'
import { useHeaderStore } from '@/store/appStore'
import { useSideMenuStore } from '@/store/sideMenuStore'
import { useClearFilter } from '@/utilities/clearStore'
import { areBasePathsSame } from '@/utilities/pathChecker'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { generateTestId } from '@/utilities/dataGenerators'
import FavouriteSideNav from './favSidenav'
import { loaderData, sideMenuItems } from './store'

export default function SideNav({
  favMenuData,
  subMenuPosition,
  handleChildView,
  handleFavChildView,
  handleButtonClick,
  onSetFavorites,
  handleSetFav,
  open,
  sectionId,
  loading
}: SideNavProps) {
  const navigate = useNavigate()
  const { isActiveMenu, setIsActiveMenu, subSectionId } = useSideMenuStore()
  const { setHeaderTitle, setModuleId } = useHeaderStore()

  const hadleClear = useClearFilter()
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const { t } = useTranslation()
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const parentDiv = document.querySelector('.sidenav-container') as HTMLElement
    parentDiv?.addEventListener('transitionend', () => {
      if (parentDiv.offsetWidth === 183) {
        parentDiv.classList.add('width-183')
      } else {
        parentDiv.classList.remove('width-183')
      }
    })
  }, [isActiveMenu])
  const manageFavs = (id: string) => {
    handleFavChildView(id)
  }
  const location = useLocation()
  const currentPath = location.pathname.slice(1)
  const renderSubmenu = (id: string) => {
    return (
      <div className="ml-5 my-1 relative flex gap-1 flex-col z-side-nav">
        {useSideMenuStore.getState().menuData.map((menus: MenuExplorerProps) => (
          <React.Fragment key={menus.FunctionId}>
            {menus.ParentId === id && (
              <div
                key={menus.FunctionId}
                data-testid={`${generateTestId(menus.FunctionName)}-${generateTestId(menus.HelpDocPath as string)}`}
                className={`${
                  areBasePathsSame(menus?.PathName, currentPath) ||
                  subSectionId === menus.FunctionId
                    ? ' bg-secondary text-white rounded-sm '
                    : 'bg-transparent rounded-sm text-sidebarText hover:bg-background-hover  hover:text-white '
                } group/item flex p-2.5 gap-4 relative truncate items-center cursor-pointer justify-between `}
                onClick={(e) => {
                  e.stopPropagation()
                  handleButtonClick(
                    e.currentTarget as HTMLDivElement,
                    menus,
                    scrollRef.current as HTMLDivElement
                  )
                }}
              >
                {menus.FunctionType !== 2 ? (
                  <div className="flex items-center justify-between w-full">
                    <div>
                      <Link
                        to={`/${menus.PathName}`}
                        title={menus.FunctionName}
                        className="flex gap-3 items-center"
                        onClick={(e) => {
                          // e.stopPropagation()
                          setHeaderTitle(menus.FunctionName)
                          setModuleId(menus.FunctionId)
                          setIsHovered(false)
                        }}
                      >
                        <CustomIcons name={'function'} type="large-m" />
                        <div className="flex items-center justify-center">
                          <p className="sidenav-item-label   ">
                            <span className=" truncate group-hover/item:w-[140px] block">
                              {menus.FunctionName}
                            </span>
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <div className="flex items-center justify-center gap-1">
                        <CustomIcons
                          type="medium"
                          data-testid={`${generateTestId(menus.FunctionName)}-${generateTestId(menus.HelpDocPath as string)}-fav`}
                          name={menus.Favourite ? 'favouriteFilled' : 'favourite'}
                          className="invisible group-hover/item:visible text-favIconColor"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleSetFav(menus)
                          }}
                        />
                        <CustomIcons
                          name={'link'}
                          type="medium"
                          className="invisible  group-hover/item:visible "
                          data-testid={`${generateTestId(menus.FunctionName)}-${generateTestId(menus.HelpDocPath as string)}-new`}
                          onClick={(e) => {
                            e.stopPropagation()
                            window.open(`/${menus.PathName}`, '_blank')
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-4 items-center">
                    <CustomIcons name={'folder'} type="large-m" />
                    <div className="flex items-center justify-center">
                      <p className="sidenav-item-label  truncate">{menus.FunctionName}</p>
                    </div>
                  </div>
                )}
                {menus.FunctionType === 2 && (
                  <CustomIcons name={'rightArrow'} type="large-m" />
                )}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    )
  }
  const mainMenuHandler = (m: any) => {
    setIsActiveMenu(m.slug)
    if (m.url) {
      navigate(m.url)
      setIsHovered(false)
    } else {
      setIsHovered(true)
    }
  }

  return (
    <>
      {isHovered && (
        <div
          className="absolute w-full h-screen overflow-hidden bg-black opacity-40 top-0 left-0 z-20"
          onClick={() => setIsHovered(false)}
        />
      )}
      <section className="flex gap-2 group  z-1300">
        <div
          // onMouseEnter={() => {
          //   setIsHovered(true)
          // }}
          id="sidenav-container"
          // onClick={() => {
          //   setIsHovered((h) => !h)
          // }}
          className={`sidenav-container bg-background-sidebar min-h-screen  z-30  py-3.5 relative ${
            isHovered ? 'hovered' : ''
          }`}
        >
          <div className=" flex flex-col justify-center items-center logo-container">
            <img
              src="/assets/logo.png"
              alt=""
              className="logo-short"
              // width={22}
              height={30}
            />
            <img
              // width={'73.171px'}
              height={30}
              src="/assets/logo_with_name.png"
              alt=""
              className="logo-desc"
            />
          </div>
          <div className="flex flex-col gap-1 py-5 px-[7px]">
            {sideMenuItems.map((menus) => (
              <div
                key={menus.id}
                data-testid={`${generateTestId(menus.title)}-${generateTestId(menus.slug as string)}`}
                className={`${
                  isActiveMenu === menus.slug
                    ? ' bg-secondary text-white rounded-sm '
                    : 'bg-transparent rounded-menu  text-sidebarText hover:bg-background-hover  hover:text-white'
                } flex   relative truncate items-center  cursor-pointer ${
                  isHovered ? 'justify-between' : 'justify-center'
                }`}
                onClick={(e) => {
                  // e.stopPropagation()
                  mainMenuHandler(menus)
                }}
              >
                <div className="flex items-center">
                  <div className="flex items-center p-2.5">
                    <CustomIcons name={menus.iconName} className="" type="large-m" />
                  </div>
                  <div
                    className={` items-center  text-center justify-center ${
                      isHovered ? 'flex' : 'hidden'
                    }`}
                  >
                    <p className="sidenav-item-label">{t(menus.title)}</p>
                  </div>
                </div>

                <CustomIcons
                  name={'rightArrow'}
                  className={`p-2.5 ${
                    !menus.child
                      ? ' hidden'
                      : `${isHovered ? 'justify-between flex' : 'justify-center hidden'} `
                  }`}
                />
              </div>
            ))}

            <>
              {isActiveMenu === 'explorer' && (
                <div
                  className={` h-full left-[183px] absolute top-0 bg-transparent ${
                    isHovered ? 'block' : 'hidden'
                  }`}
                >
                  <div
                    ref={scrollRef}
                    className="sidnav-sub-menu absolute  top-0 bg-background-sidebarSecondary h-full  z-40    transition-transform"
                  >
                    <div className="relative h-full overflow-y-auto py-2 px-[11px] gap-1 flex flex-col">
                      {loading ? (
                        <div className=" flex flex-col gap-4 p-3">
                          {loaderData?.map((indexload) => (
                            <div key={indexload.id} className=" flex gap-[14px]">
                              <span className="bg-[#203141] w-[14px] h-[14px] rounded-full"></span>
                              <div
                                style={{ width: indexload.w }}
                                className={`bg-[#203141]  h-[14px] rounded-[30px]`}
                              ></div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <>
                          {useSideMenuStore
                            .getState()
                            .menuData.map((menus: MenuExplorerProps) => (
                              <React.Fragment key={menus.FunctionId}>
                                {menus.ParentId == null && (
                                  <div className="">
                                    <div
                                      key={menus.FunctionId}
                                      data-testid={`${generateTestId(menus.FunctionName)}-${generateTestId(menus.HelpDocPath as string)}`}
                                      className={`${
                                        menus.childView && !sectionId
                                          ? ' bg-secondary text-white rounded-sm'
                                          : 'bg-transparent rounded-sm text-sidebarText hover:bg-background-hover  hover:text-white'
                                      } ${
                                        menus.childView && sectionId && 'text-white'
                                      } flex p-2 gap-4 relative truncate items-center cursor-pointer justify-between`}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleChildView(menus.FunctionId)
                                      }}
                                    >
                                      <div className="flex gap-3">
                                        <CustomIcons
                                          name={'module'}
                                          className=""
                                          type="large-m"
                                        />
                                        <div className="flex items-center justify-center text-center">
                                          <p className="sidenav-item-label">
                                            {menus.FunctionName}
                                          </p>
                                        </div>
                                      </div>

                                      <CustomIcons
                                        name={
                                          !menus.childView
                                            ? 'menu-down-arrow'
                                            : 'menu-up-arrow'
                                        }
                                      />
                                    </div>
                                    {menus.childView && (
                                      <>{renderSubmenu(menus.FunctionId)}</>
                                    )}
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {isActiveMenu === 'favourite' && (
                <FavouriteSideNav
                  setIsHovered={setIsHovered}
                  favMenuData={favMenuData}
                  onSetFavorites={onSetFavorites}
                  handleFavChildView={manageFavs}
                />
              )}
            </>

            {open && subMenuPosition.left > 10 && isActiveMenu === 'explorer' && (
              <div>
                <div
                  style={{
                    position: 'absolute',
                    top: subMenuPosition.top + 'px',
                    left: subMenuPosition.left + 'px'
                  }}
                  className="sub-nav-container  bg-dark h-auto  overflow-y-auto rounded-[8px]"
                >
                  <div
                    style={{
                      maxHeight: `calc(100vh - ${subMenuPosition.top}px)`,
                      minHeight: `fit-content`
                    }}
                    className={`px-3 pb-3 mt-3 relative overflow-auto `}
                  >
                    {useSideMenuStore
                      .getState()
                      .menuData.map((menus: MenuExplorerProps) => (
                        <React.Fragment key={menus.FunctionId}>
                          {menus.ParentId === sectionId && menus.FunctionType === 3 && (
                            <div
                              key={menus.FunctionId}
                              title={menus.FunctionName}
                              className={`${
                                areBasePathsSame(menus?.PathName, currentPath)
                                  ? ' bg-secondary text-white '
                                  : 'bg-transparent  text-sidebarText hover:bg-background-hover hover:text-white'
                              } group/item flex p-2 gap-4  relative  truncate items-center cursor-pointer justify-between scroll-smooth h-[44px] rounded-sm text-sidebarText hover:bg-background-hover mb-[4px]`}
                            >
                              <Link
                                className="flex gap-4 items-center "
                                to={`/${menus.PathName}`}
                                data-testid={`${generateTestId(menus.FunctionName)}-${generateTestId(menus.HelpDocPath as string)}`}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setIsHovered(false)
                                  setModuleId(menus.FunctionId)
                                  setHeaderTitle(menus.FunctionName)
                                }}
                              >
                                <CustomIcons name={'function'} type="large-m" />
                                <div className="flex items-center justify-center ">
                                  <p className="sidenav-item-label text-sm  flex">
                                    <span className=" truncate group-hover/item:w-[140px]">
                                      {menus.FunctionName}
                                    </span>
                                  </p>
                                </div>
                              </Link>
                              <div className="flex gap-1 items-center justify-center">
                                <CustomIcons
                                  type="medium"
                                  data-testid={`set-fav`}
                                  name={menus.Favourite ? 'favouriteFilled' : 'favourite'}
                                  className="invisible  group-hover/item:visible  text-favIconColor"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleSetFav(menus)
                                  }}
                                />
                                <CustomIcons
                                  name={'link'}
                                  type="medium"
                                  data-testid={`new-window`}
                                  className="invisible group-hover/item:visible"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    window.open(`/${menus.PathName}`, '_blank')
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
            )}
            <div className={`absolute bottom-0 ${isHovered ? 'w-[173px]' : ''}`}>
              <div className="py-5 w-full text-white gap-2.5 flex flex-col   cursor-pointer">
                <div className="flex items-center p-2.5">
                  <CustomIcons
                    name={'rightDouble'}
                    data-testid={`expand-double-icon`}
                    className={isHovered ? 'hidden' : ''}
                    type="large-m"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsHovered((h) => !h)
                    }}
                  />
                </div>

                <div className={`flex ${isHovered ? 'justify-between' : 'items-center'}`}>
                  <div
                    className="flex items-center cursor-pointer"
                    data-testid={`logout-poweroff`}
                    onClick={(e) => {
                      e.stopPropagation()
                      hadleClear()
                    }}
                  >
                    <div className="flex items-center p-2.5 cursor-pointer">
                      <CustomIcons name="power" className="text-white" type="large-m" />
                    </div>
                    <div
                      className={`flex items-center bottom-text  text-center justify-center ${
                        isHovered ? ' visible-text' : ''
                      }`}
                    >
                      <p
                        className={`${isHovered ? 'flex' : 'hidden'} sidenav-item-label`}
                      >
                        {t('Logout')}
                      </p>
                    </div>
                  </div>
                  <div
                    className={` flex bottom-text ${
                      isHovered ? 'justify-end  visible-text' : 'justify-center '
                    }`}
                  >
                    <CustomIcons
                      name={'leftDouble'}
                      type="large-m"
                      className={`p-2.5   ${
                        isHovered ? 'flex' : 'hidden justify-center '
                      }`}
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsHovered((h) => !h)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

import { ModulesRouteProps } from '@/common/commontypes'
import CompanyAuditLog from '@/pages/masterData/company/details/auditLog'
import CompanyBranchesList from '@/pages/masterData/company/details/branches'
import CompanyCurrencyList from '@/pages/masterData/company/details/currency'
import CompanyHeaders from '@/pages/masterData/company/details/header'
import CompanyServicesList from '@/pages/masterData/company/details/service'
import { routerStore } from '@/store'
import { lazy } from 'react'

const VesselRegister = lazy(() => import('@/pages/masterData/vesselRegister'))
const EntityMasterData = lazy(() => import('@/pages/masterData/entityMaster'))
const Countries = lazy(() => import('@/pages/masterData/countries'))
const Departments = lazy(() => import('@/pages/masterData/departments'))
const Currencies = lazy(() => import('@/pages/masterData/currencies'))
const Designations = lazy(() => import('@/pages/masterData/designations'))
const FleetsList = lazy(() => import('@/pages/masterData/fleets'))
const Company = lazy(() => import('@/pages/masterData/company'))
const CompanyDetails = lazy(() => import('@/pages/masterData/company/details'))
const Ports = lazy(() => import('@/pages/masterData/ports'))
const WorkflowMasterData = lazy(() => import('@/pages/masterData/workflow'))
const MasterMarker = lazy(() => import('@/pages/masterData/maker'))
const VesselClasses = lazy(() => import('@/pages/masterData/vesselClass'))
const RegionMasterData = lazy(() => import('@/pages/masterData/regions'))
const VesselStatusesMasterData = lazy(() => import('@/pages/masterData/vesselStatuses'))
const MasterAuthority = lazy(() => import('@/pages/masterData/authorities'))
const ServiceTypeMasterData = lazy(() => import('@/pages/masterData/serviceTypes'))
const ConsumptionTypeMasterData = lazy(
  () => import('@/pages/masterData/consumptionTypes')
)
const UnitOfMeasurement = lazy(() => import('@/pages/masterData/unitOfMeasurements'))
const VesselTypes = lazy(() => import('@/pages/masterData/vesselTypes'))
const OilTypes = lazy(() => import('@/pages/masterData/oilTypes'))
const SulphurContentType = lazy(() => import('@/pages/masterData/sulphurContentType'))
const Cms = lazy(() => import('@/pages/masterData/cms'))

const masterDataRoutes: ModulesRouteProps[] = [
  {
    slug: 'CMS',
    path: routerStore.CMS,
    component: <Cms />
  },
  {
    slug: 'ENTITY_MASTER_LIST',
    path: routerStore.ENTITY_LIST,
    component: <EntityMasterData />
  },
  {
    slug: 'VESSEL_LIST',
    path: routerStore.VESSEL_LIST,
    component: <VesselRegister />
  },
  {
    slug: 'MASTER_DATA_COUNTRIES',
    path: routerStore.MASTER_DATA_COUNTRIES,
    component: <Countries />
  },
  {
    slug: 'MASTER_DATA_DEPARTMENTS',
    path: routerStore.MASTER_DATA_DEPARTMENTS,
    component: <Departments />
  },
  {
    slug: 'MASTER_DATA_CURRENCIES',
    path: routerStore.MASTER_DATA_CURRENCIES,
    component: <Currencies />
  },
  {
    slug: 'COMPANY_LIST',
    path: routerStore.COMPANY_LIST,
    component: <Company />
  },
  {
    slug: 'COMPANY_DETAIL',
    path: routerStore.COMPANY_DETAIL,
    component: <CompanyDetails />,
    children: [
      {
        slug: 'COMPANY_DETAILS_HEADER',
        path: routerStore.COMPANY_DETAILS_HEADER,
        component: <CompanyHeaders />
      },
      {
        slug: 'COMPANY_DETAILS_SERVICE',
        path: routerStore.COMPANY_DETAILS_SERVICE,
        component: <CompanyServicesList />
      },
      {
        slug: 'COMPANY_DETAILS_CURRENCY',
        path: routerStore.COMPANY_DETAILS_CURRENCY,
        component: <CompanyCurrencyList />
      },
      {
        slug: 'COMPANY_DETAILS_BRANCHES',
        path: routerStore.COMPANY_DETAILS_BRANCHES,
        component: <CompanyBranchesList />
      },
      {
        slug: 'COMPANY_DETAILS_AUDIT_LOG',
        path: routerStore.COMPANY_DETAILS_AUDIT_LOG,
        component: <CompanyAuditLog />
      }
    ]
  },
  {
    slug: 'MASTER_DATA_DESIGNATIONS',
    path: routerStore.MASTER_DATA_DESIGNATIONS,
    component: <Designations />
  },
  {
    slug: 'FLEETS_LIST',
    path: routerStore.FLEETS_LIST,
    component: <FleetsList />
  },
  {
    slug: 'MASTER_DATA_PORTS',
    path: routerStore.MASTER_DATA_PORTS,
    component: <Ports />
  },
  {
    slug: 'MASTER_DATA_WORKFLOW',
    path: routerStore.MASTER_DATA_WORKFLOW,
    component: <WorkflowMasterData />
  },
  {
    slug: 'MASTER_DATA_MAKER',
    path: routerStore.MASTER_DATA_MAKER,
    component: <MasterMarker />
  },
  {
    slug: 'MASTER_DATA_VESSEL_CLASSES',
    path: routerStore.MASTER_DATA_VESSEL_CLASSES,
    component: <VesselClasses />
  },
  {
    slug: 'MASTER_REGIONS_LIST',
    path: routerStore.MASTER_REGIONS_LIST,
    component: <RegionMasterData />
  },
  {
    slug: 'MASTER_DATA_VESSEL_STATUSES',
    path: routerStore.MASTER_DATA_VESSEL_STATUSES,
    component: <VesselStatusesMasterData />
  },
  {
    slug: 'MASTER_DATA_AUTHORITIES',
    path: routerStore.MASTER_AUTHORITY_LIST,
    component: <MasterAuthority />
  },
  {
    slug: 'MASTER_DATA_SERVICE_TYPES',
    path: routerStore.MASTER_DATA_SERVICE_TYPES_LIST,
    component: <ServiceTypeMasterData />
  },
  {
    slug: 'MASTER_DATA_CONSUMPTION_TYPES',
    path: routerStore.MASTER_DATA_CONSUMPTION_TYPES_LIST,
    component: <ConsumptionTypeMasterData />
  },
  {
    slug: 'MASTER_DATA_UNIT_OF_MEASUREMENTS',
    path: routerStore.MASTER_DATA_UNIT_OF_MEASUREMENTS,
    component: <UnitOfMeasurement />
  },
  {
    slug: 'MASTER_DATA_VESSEL_TYPES',
    path: routerStore.MASTER_DATA_VESSEL_TYPES,
    component: <VesselTypes />
  },
  {
    slug: 'MASTER_DATA_OIL_TYPES',
    path: routerStore.MASTER_DATA_OIL_TYPES,
    component: <OilTypes />
  },
  {
    slug: 'MASTER_DATA_SULPHUR_CONTENT_TYPE',
    path: routerStore.MASTER_DATA_SULPHUR_CONTENT_TYPE,
    component: <SulphurContentType />
  }
]

export default masterDataRoutes

import { Button, CustomKendoTable, DeletePopUp } from '@/components/common'
import ModernTextArea from '@/components/common/inputs/ModernTextArea'
import { TableHeight, isAllow } from '@/constants'
import AddAssumptionForm from '@/modules/technical/components/app/vesselBudget/AddAssumptionForm'
import useHasPermission from '@/utilities/checkActionPermission'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'
import {
  deleteAssumptionData,
  updateBudgetRemark,
  useBudgAssumptions,
  viewAssumptionData
} from './api'
import { useAppContext } from '@/store/context/appContext'

type PageParams = {
  pageNo: number
  pageSize: number
  sortBy: string
  BudgetId: string | undefined
  ShowZeroBudgetItems: boolean
  HideMonthlyData: boolean
  NameOrDesc: string
}
const AssumptionComponent = () => {
  const params = useParams()
  const { detailData, handleRefresh } = useOutletContext<any>()
 const { showSnackBar } = useCustomSnackBar()
  const [param, setPram] = useState<PageParams>({
    pageNo: 0,
    pageSize: 100,
    BudgetId: params.budgetID,
    ShowZeroBudgetItems: true,
    HideMonthlyData: true,
    NameOrDesc: '',
    sortBy: 'BudgetYear'
  })

  const { t } = useTranslation()
  const { data, refetch, isFetching } = useBudgAssumptions(param)
  const [disabled, setDisabled] = useState(true)
  const [remarks, setRemarks] = useState<string>(detailData?.remarks)
  const [createOpen, setCreateOpen] = useState<boolean>(false)
  const [viewData, setViewData] = useState<boolean>(false)
  const [rowData, setRowData] = useState<any>({})
  const [editData, setEditData] = useState<boolean>(false)
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
  const [budAssumptionId, setBudAssumptionId] = useState<string>('')
  const [subTitle, setSubTitle] = useState<string>('')
  const { setIsActionLoader, isActionLoader } = useAppContext()

  const hasPermission = useHasPermission('Budget')

  useEffect(() => {
    setRemarks(detailData?.remarks)
  }, [detailData?.remarks])

  const columns = [
    {
      title: 'Assumptions',
      field: 'Title',
      type: 'text',
      sort: true,
      action: true,
      render: (row: any) => (
        <span
          onClick={() => handleView(row)}
          className="kendo-column-text-link cursor-pointer"
        >
          {row?.Title}
        </span>
      )
    }
  ]

  const handleView = async (row: any) => {
    setViewData(true)
    const data = await viewAssumptionData(row.BudAssumptionId)
    setRowData(data)
    setCreateOpen(true)
  }
  useEffect(() => {
    refetch()
  }, [param])

  const handleSort = (order: string) => {
    setPram((prev) => ({
      ...prev,
      sortBy: order
    }))
  }

  const { isMobile, isTab } = useScreenBreakPoint()
  const handleCreate = async () => {
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: params.budgetID
    })
    setCreateOpen(permission)
    setViewData(false)
    setEditData(false)
  }
  const handleClose = () => {
    setCreateOpen(false)
    setRowData({})
    setViewData(false)
  }
  const handleUpdate = () => {
    const data = {
      BudgetId: params.budgetID,
      Remarks: remarks
    }
    updateBudgetRemark(data).then((res: any) => {
      showSnackBar(res)
      setDisabled(true)
      handleRefresh()
    })
  }
  const handleCancel = () => {
    handleRefresh()
    setDisabled(true)
  }
  const handleMenuActions = async (slug: string, rowData: any) => {
    setSubTitle(rowData?.Title)
    const permission = await hasPermission({
      UserAction: isAllow.UPDATE,
      AccessKey: '',
      ID1: params.budgetID
    })
    switch (slug) {
      case 'edit':
        if (permission) {
          handleEdit(rowData)
        }
        break
      case 'delete':
        setDeleteOpen(true)
        setBudAssumptionId(rowData.BudAssumptionId)
    }
  }
  const handleEdit = async (rowdata: any) => {
    const data = await viewAssumptionData(rowdata.BudAssumptionId)
    setRowData(data)
    setEditData(true)
    setViewData(false)
    setCreateOpen(true)
  }
  const handleCloseDelete = () => {
    setDeleteOpen(false)
    setRowData({})
  }
  const handleDelete = () => {
    setIsActionLoader(true)
    deleteAssumptionData(budAssumptionId)
      .then((res) => {
        showSnackBar(res)
        setRowData({})
        refetch()
      })
      .catch((err) => {
        showSnackBar(err.response.data.error, 'error')
      }).finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }
  return (
    <div
      className={`grid ${isMobile || isTab ? 'grid-cols-1' : 'grid-cols-2'}  gap-[15px]`}
    >
      <div className=" p-[27px] pt-[19px] bg-white rounded ">
        <CustomKendoTable
          columns={columns}
          data={data?.Results}
          isFetching={isFetching}
          // pagination={true}
          sortable={true}
          defaultSort={'Title'}
          toolBar
          primaryAction={{
            title: t('New'),
            handlePrimaryAction: handleCreate
          }}
          actionDropDown={[
            { title: t('edit'), iconName: 'edit', slug: 'edit', id: '1' },
            {
              title: t('delete'),
              iconName: 'delete',
              slug: 'delete',
              id: '2',
              isWarning: true
            }
          ]}
          handleMenuActions={handleMenuActions}
          title="Assumptions"
          isDownloadable={true}
          handleSort={handleSort}
          tableHeight={`calc(100vh -  ${TableHeight.WithHeader})`}
        />
      </div>
      <div className="p-[27px] bg-white rounded ">
        <p className=" text-m font-medium text-input-text">Remarks</p>
        <div className="pt-[20px]">
          <ModernTextArea
            id="Remarks"
            name="remarks"
            placeholder="Enter remarks"
            autoFocus
            boxHeight="calc(100vh - 669px)"
            boxMinHeight={225}
            disabled={disabled}
            rows={10}
            value={remarks}
            onChange={(e) => {
              setRemarks(e.target.value.trimStart())
            }}
          />
        </div>
        <div className="flex justify-end gap-2 pt-[30px]">
          <Button
            primary={false}
            label={disabled ? 'Edit' : 'Save'}
            onClick={() => (disabled ? setDisabled(false) : handleUpdate())}
          />
          <Button
            outlined
            label="Cancel"
            onClick={() => handleCancel()}
            disabled={disabled}
          />
        </div>
      </div>
      <AddAssumptionForm
        open={createOpen}
        onClose={handleClose}
        refetch={refetch}
        view={viewData}
        data={rowData}
        edit={editData}
        title={`${viewData ? 'View' : editData ? 'Edit' : 'Add'} Assumption`}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={handleCloseDelete}
        itemLabels={{ title: 'assumption', subTitle: subTitle }}
        actionLoader={isActionLoader}
      />
    </div>
  )
}

export default AssumptionComponent

import { postTechnicalMipsData } from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useQuery } from '@tanstack/react-query'

export const fetchVesselData = async (filters: any) => {
  return await postTechnicalMipsData(`${apiTechnicalUrl.GET_VESSEL_LIST_MIPS}`, {
    ...filters
  })
}

export const useTechnicalParticularList = (filters: any, isEnable: boolean) => {
  return useQuery({
    queryKey: ['technical-particular-list', filters],
    queryFn: () => fetchVesselData(filters),
    //initialData: false,
    staleTime: 500,
    enabled: isEnable
  })
}

import CustomIcons from '@/components/common/icons/customIcons'
import React, { useState } from 'react'

type TreeItemProps = {
  label: string
  level?: number
  children?: React.ReactNode
  handleNodeCheck?: (data?: any) => void
  node: any
  treeSelect?: boolean
  nodeIcon?: string
  handleTreeExpand?: (data: any) => void
  treeAction?: string
  handleTreeAction?: (data: any) => void
  nodeSelectedIcon?: string
  handleCheckClick?: (data: any) => void
  editable?: boolean
}

const TableTreeRow: React.FC<TreeItemProps> = React.memo(
  ({
    label,
    level = 0,
    children,
    handleNodeCheck,
    node,
    treeSelect,
    nodeIcon,
    handleTreeExpand,
    nodeSelectedIcon,
    handleCheckClick,
    editable
  }) => {
    const [isOpen, setIsOpen] = useState(false)
    const hasChildren = Array.isArray(children)
    const handleTree = () => {
      if (!isOpen) {
        handleTreeExpand?.(node)
      }
      setIsOpen(!isOpen)
    }

    const handleActionClick = (type: string) => {
      const curNode = { ...node }
      if (type === 'read') {
        curNode[type] = !node[type]
        if (curNode[type] === false) {
          curNode.modify = false
          curNode.create = false
          curNode.delete = false
        }
      } else {
        curNode[type] = !node[type]
        if (!node?.read) curNode.read = true
      }
      handleCheckClick?.(curNode)
    }
    const getIconName = (nodeValue: any, editable?: boolean) => {
      if (nodeValue) {
        return editable ? 'input_checked' : 'input_checked_disabled'
      }
      return editable ? 'input_unchecked' : 'input_unchecked_disabled'
    }
    const generateIcon = (type: string) => {
      return (
        <CustomIcons
          name={getIconName(node?.[type], editable)}
          type="medium"
          viewBox={true}
          className={`${
            node?.[type] ? `text-link  ` : `text-grey-checkbox  `
          }${editable && ' cursor-pointer'}`}
          onClick={() => (editable ? handleActionClick(type) : '')}
        />
      )
    }
    const preparedChildren = hasChildren && isOpen ? <>{children}</> : null
    console.log(node)
    return (
      <div key={node?.sysFunctionId}>
        <div className="grid grid-cols-8 gap-3 items-center border-b-[1px] border-[#E7E7E8] h-[42px] hover:bg-table-hover">
          <div className=" col-span-4 relative">
            <div
              style={{
                marginLeft: level > 0 ? level * 10 + (!node?.hasChildren ? 25 : 0) : 0
              }}
              className={` flex  items-center p-1 tree-content gap-2`}
            >
              {hasChildren ? (
                <>
                  {node?.hasChildren && (
                    <>
                      <button onClick={() => handleTree()} className="focus:outline-none">
                        <span className={`font-semibold text-l text-secondary`}>
                          <CustomIcons
                            name={isOpen ? 'tree_expand' : 'tree_colapse'}
                            type="large"
                          />
                        </span>
                      </button>
                    </>
                  )}
                  {node && Object.keys(node)?.length > 0 && nodeIcon && (
                    <span className="text-lg cursor-pointer text-secondary">
                      <CustomIcons
                        name={node.checked ? (nodeSelectedIcon as string) : nodeIcon}
                        type="large"
                      />
                    </span>
                  )}
                </>
              ) : (
                <>
                  {treeSelect && (
                    <span
                      className="text-lg  cursor-pointer"
                      onClick={() => handleNodeCheck?.(node)}
                    >
                      <CustomIcons
                        name={node?.checked ? 'checked-icon' : 'un-checked-icon'}
                      />
                    </span>
                  )}

                  {node && Object.keys(node)?.length > 0 && nodeIcon && (
                    <span className="text-lg w-6 cursor-pointer">
                      <CustomIcons
                        name={node.checked ? (nodeSelectedIcon as string) : nodeIcon}
                        type="large"
                      />
                    </span>
                  )}
                </>
              )}
              <span
                className={` text-common capitalize  ${
                  node?.checked || (isOpen && hasChildren)
                    ? 'text-secondary font-semibold'
                    : 'text-table-headColor'
                }   ${nodeIcon ? '' : 'ml-1'} `}
              >
                {label}
              </span>
            </div>
          </div>
          <div className="col-span-1 flex items-center justify-center">
            {node?.functionType >= 3 && generateIcon('read')}
          </div>
          <div className="col-span-1 flex items-center justify-center">
            {
              // node?.create &&
              (node?.functionType === 3 || node?.functionType === 5) &&
                generateIcon('create')
            }
          </div>
          <div className="col-span-1 flex items-center justify-center">
            {
              // node?.modify &&
              (node?.functionType === 3 || node?.functionType === 5) &&
                generateIcon('modify')
            }
          </div>
          <div className="col-span-1 flex items-center justify-center">
            {
              // node?.delete &&
              (node?.functionType === 3 || node?.functionType === 5) &&
                generateIcon('delete')
            }
          </div>
        </div>

        {preparedChildren}
      </div>
    )
  }
)
TableTreeRow.displayName = 'RolePrivilegeTree'

export default TableTreeRow

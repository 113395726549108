import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'

export interface MenuItemProps {
  label: string
  slug: string
  disabled?: boolean
  icon?: string
}

const regex = /^\d+(?:\.\d{0,2})?$/

export const technicalDetailFormSchema = z.object({
  engTypeId: z
    .number({ required_error: 'Engine Type is Required' })
    .min(1, { message: 'Engine Type is Required' }),
  engTypeName: z
    .string({
      required_error: 'Engine Type is Required',
      invalid_type_error: 'Engine Type is Required'
    })
    .min(1, { message: 'Engine Type is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  hullTypeId: z
    .number({
      required_error: 'Hull Type is Required',
      invalid_type_error: 'Hull Type is Required'
    })
    .min(1, { message: 'Hull Type is Required' }),
  hullTypeName: z
    .string({
      required_error: 'Hull Type is Required',
      invalid_type_error: 'Hull Type is Required'
    })
    .min(1, { message: 'Hull Type is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  pitchTypeId: z
    .number({ required_error: 'Pitch Type is Required' })
    .min(1, { message: 'Pitch Type is Required' }),
  pitchTypeName: z
    .string({
      required_error: 'Pitch Type is Required',
      invalid_type_error: 'Pitch Type is Required'
    })
    .min(1, { message: 'Pitch Type is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  meMaker: z
    .string()
    .max(100)
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .nullable()
    .optional(),
  meModel: z
    .string()
    .max(100)
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .nullable()
    .optional(),
  mePower: z
    .union([
      z
        .string({ required_error: 'Main Engine Power is Required' })
        .min(1, { message: 'Main Engine Power is Required' }),
      z.number()
    ])
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    ),
  speed: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  meanPitch: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  measMethId: z
    .number({ required_error: 'Oil Measurement Method is Required' })
    .min(1, { message: 'Oil Measurement is Required' }),
  measMethName: z
    .string({
      required_error: 'Oil Measurement Method is Required',
      invalid_type_error: 'Oil Measurement Method is Required'
    })
    .min(1, { message: 'Oil Measurement is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  vesselId: z.string().optional()
})

export type technicalDetailFormSchemaType = z.infer<typeof technicalDetailFormSchema>

export const dimensionFormSchema = z.object({
  breadthMoulded: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  breadthExtreme: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  depthExtreme: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dbToBridge: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  loa: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  lbp: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  keelToMastHead: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional()
})

export type dimensionFormSchemaType = z.infer<typeof dimensionFormSchema>

export const capacityUsageFormSchema = z.object({
  fwPortable: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  fwFeed: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  fwDistilled: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional()
})

export type capacityUsageFormSchemaType = z.infer<typeof capacityUsageFormSchema>

export const loadLineInfoFormSchema = z.object({
  dwWinter: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dwSummer: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dwTropical: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dwLightship: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dwnbc: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dwsbc: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  drafWinter: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  drafSummer: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  drafTropical: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  drafLightship: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  draftNBC: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  draftSBC: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  fbWinter: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  fbSummer: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  fbTropical: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  fbLightship: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  fbnbc: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  fbsbc: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dpWinter: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dpSummer: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dpTropical: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dpLightship: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dpnbc: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  dpsbc: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional()
})
export type loadLineInfoFormSchemaType = z.infer<typeof loadLineInfoFormSchema>

export const othersFormSchema = z.object({
  eedi: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  evi: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  capRating: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : value.toString()))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        return (/^\d+$/.test(value) && value.length <= 4) || value === ''
      },
      {
        message: 'messages.errorNumber4Digit'
      }
    )
    .optional(),
  teu: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  cbm: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  lifeBoatCapacity: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : value.toString()))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        return (/^\d+$/.test(value) && value.length <= 4) || value === ''
      },
      {
        message: 'messages.errorNumber4Digit'
      }
    )
    .optional(),
  majorHullChange: z.boolean().optional().nullable(),
  ums: z.boolean().optional().nullable(),
  gmdss: z.boolean().optional().nullable(),
  isCounterFitted: z.boolean().optional().nullable()
})
export type othersFormSchemaType = z.infer<typeof othersFormSchema>

export const TonnageFormSchema = z.object({
  nrt: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  grt: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  tgSuez: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  tnSuez: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  tgPanama: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  tnPanama: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  tgInternational: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  tnInternational: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional()
})

export type TonnageFormSchemaType = z.infer<typeof TonnageFormSchema>

export const RatedPowerFormSchema = z.object({
  rpMainEngine: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  rpAuxEngine1: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  rpAuxEngine2: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  rpAuxEngine3: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  rpAuxEngine4: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional(),
  rpEmHarbGen: z
    .union([z.string(), z.number()])
    .nullable()
    .refine((value) => value === null || regex.test(value.toString()), {
      message: 'It allows only an Integer and Decimal values'
    })
    .transform((value) => (value === null ? null : parseFloat(value.toString())))
    .refine(
      (value) => {
        if (value === null) return true // allow null values
        const [integerPart, decimalPart] = value.toString().split('.')
        return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2)
      },
      {
        message: 'messages.errorFloat8Digit'
      }
    )
    .optional()
})

export type RatedPowerFormSchemaType = z.infer<typeof RatedPowerFormSchema>

import TableTreeRow from '@/components/app/system/roles/treeNode'
import { Button } from '@/components/common'
// import CustomIcons from '@/components/common/icons/customIcons'
import { getRolePrivilegeData, setRolesPrivileges } from '@/pages/system/roles/api'
import { useAppContext } from '@/store/context/appContext'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

export const RoleEditPrivileges: React.FC<any> = () => {
  const params = useParams()
  const { showSnackBar } = useCustomSnackBar()
  const { edit, setEdit } = useOutletContext<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [privilegeData, setPrivilegeData] = useState<any>([])
  const [isSaving, setIsSaving] = useState(false)

  const { setIsCommonLoader } = useAppContext()
  useEffect(() => {
    setIsCommonLoader(isLoading)
  }, [isLoading])
  const handleGetData = async () => {
    setIsLoading(true)
    const { data } = await getRolePrivilegeData(params?.id as string)

    const privData = data.map((item: any) => ({
      ...item,
      hasChildren: data.some((childItem: any) => childItem.parentId === item.functionId)
    }))
    setPrivilegeData(privData)
    setIsLoading(false)
  }
  useEffect(() => {
    handleGetData()
  }, [])

  const handleNodeCheck = () => {}
  const handleTreeExpand = () => {}
  const handleTreeAction = () => {}

  const handleCheckClick = (node: any) => {
    const updatedData = resetPrivilegeData(node)
    setPrivilegeData(updatedData)
  }

  const resetPrivilegeData = (node: any) => {
    return privilegeData?.map((item: any) =>
      item.functionId === node.functionId ? { ...item, ...node } : item
    )
  }

  const handleSaveClick = () => {
    const privilages = privilegeData
      .filter((node: any) => node.read)
      .map((node: any) => ({
        sysRoleId: params?.id,
        privilegeId: node.privilegeId,
        sysFunctionId: node.functionId,
        create: node.create,
        modify: node.modify,
        delete: node.delete,
        read: node.read
      }))

    setIsSaving(true)
    setRolesPrivileges({ roles: [params?.id], privileges: privilages, grantAccess: true })
      .then((res) => {
        setIsSaving(false)
        showSnackBar(res)
        setEdit(false)
      })
      .catch((err) => {
        setIsSaving(false)
        showSnackBar(err, 'error')
      })
  }

  const renderTree = (item: any, level: number = 0): React.ReactNode =>
    privilegeData?.map((node: any, index: number) => (
      <>
        {item.functionId === node?.parentId && (
          <TableTreeRow
            key={index}
            label={node.functionName}
            node={node}
            level={level}
            handleNodeCheck={handleNodeCheck}
            nodeIcon={'function'}
            editable={edit}
            handleTreeExpand={handleTreeExpand}
            treeAction={'three_dot'}
            handleTreeAction={handleTreeAction}
            handleCheckClick={handleCheckClick}
            nodeSelectedIcon={'edit'}
          >
            {renderTree(node, level + 1)}
          </TableTreeRow>
        )}
      </>
    ))
  const handleEdit = () => {
    setEdit(true)
  }
  const handleCancel = () => {
    setEdit(false)
    handleGetData()
  }
  return (
    <div className="bg-white rounded p-3 pt-[3px] gap-[15px] relative">
      <div className=" pb-[10px] flex justify-between items-center">
        <p className=" font-medium text-m text-input-text  ">Privileges</p>
        <div className=" flex gap-2">
          {!edit ? (
            <Button label="Edit" primary={false} onClick={handleEdit} />
          ) : (
            <>
              <Button label="Save" isLoading={isSaving} onClick={handleSaveClick} />
              <Button
                label="Cancel"
                disabled={isSaving}
                outlined
                primary={false}
                onClick={handleCancel}
              />
            </>
          )}
        </div>
      </div>

      <div className="grid grid-cols-8 gap-1 bg-table-headBG font-semibold items-center text-common p-input text-table-headColor">
        <div className="col-span-4">Function Name</div>
        <div className="col-span-1 text-center">Read</div>
        <div className="col-span-1 text-center">Create</div>
        <div className="col-span-1 text-center">Modify</div>
        <div className="col-span-1 text-center">Delete</div>
      </div>
      <div
        className="tree-container overflow-auto"
        style={{
          maxHeight: `calc(100vh - 390px)`,
          minHeight: `calc(100vh - 390px)`
        }}
      >
        {privilegeData?.map((node: any, index: number) => (
          <>
            {node?.parentId === null && (
              <TableTreeRow
                key={index}
                label={node.functionName}
                node={node}
                level={0}
                editable={edit}
                handleNodeCheck={handleNodeCheck}
                nodeIcon={'module'}
                handleTreeExpand={handleTreeExpand}
                treeAction={'three_dot'}
                handleTreeAction={handleTreeAction}
                nodeSelectedIcon={'edit'}
              >
                {renderTree(node, 1)}
              </TableTreeRow>
            )}
          </>
        ))}
      </div>
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { CustomAutoComplete } from 'react-core-ts'

type Props = {
  label?: string
  onChange: (e: any) => void
  value?: any
  disabled?: boolean
  autoFocus?: boolean
  fullWidth?: boolean
  required?: boolean
  errors?: any
  placeholder?: string
  name: string
  type: any
  desc: string
  descId: string
  selectedItems: any[]
  data: any[]
  isMultiple?: boolean
  handleAction?: () => void
  handleMultiChange?: (data: any) => void
}

const QbsAutocompleteComponent = (props: Props) => {
  const {
    label,
    errors,
    onChange,
    value,
    autoFocus,
    disabled = false,
    required,
    fullWidth = false,
    placeholder,
    name,
    type,
    desc,
    descId,
    data,
    selectedItems,
    isMultiple = true,
    handleAction
  } = props
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  const generateClassName = (
    type: 'input' | 'label' | 'message' | 'adorement'
  ): string => {
    let className = 'text-input-label '
    switch (type) {
      case 'input':
        className +=
          'block font-medium placeholder:text-[12px] px-[11px] w-full text-sm text-gray-900 bg-transparent  border  appearance-none   focus:outline-none focus:ring-0   h-10 rounded-[3px] disabled:text-input-disabled disabled:bg-disabled placeholder-transparent focus:placeholder-grey-dark'
        if (errors && errors[name]) {
          className += ' border-error-light focus:border-error-light'
        } else {
          className += ' text-grey-dark border-input-light focus:border-blue-navy'
        }
        break
      case 'label':
        className += ` ${
          isFocused || value || selectedItems.length > 0
            ? 'font-semibold placeholder-shown:-translate-y-1/2 placeholder-shown:top-1/2 top-2 -translate-y-4'
            : 'font-medium  top-6'
        }
         bg-white  absolute text-sm  duration-300 transform -translate-y-4  z-1 origin-[0]  px-2 start-[4px] ${
           isDisabled ? 'cursor-pointer' : 'cursor-text peer-focus:cursor-pointer'
         }`
        if (errors && errors[name]) {
          className += ' text-error-light '
        } else {
          className += ` ${isFocused ? 'text-blue-navy' : ' text-grey-dark'}`
        }
        break
      default:
        break
    }
    return className
  }
  const onLabelClick = () => {
    if (!isDisabled) {
      enableFocus()
    }
  }
  useEffect(() => {
    const handleClickOutside = (event: React.MouseEvent) => {
      if (
        containerRef.current &&
        event.target instanceof Node &&
        !containerRef.current.contains(event.target)
      ) {
        disableFocus()
      }
    }
    document.addEventListener('mousedown', handleClickOutside as any)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside as any)
    }
  }, [])
  const enableFocus = () => {
    setIsFocused(true)
    inputRef?.current?.focus()
    inputRef?.current?.click()
  }
  const disableFocus = () => {
    setIsFocused(false)
  }
  useEffect(() => {
    if (autoFocus) {
      onLabelClick()
    }
  }, [autoFocus])

  const getErrors = () => {
    return errors ? errors[name] : errors
  }

  return (
    <div className={fullWidth ? 'w-full' : 'w-auto'} ref={containerRef}>
      <div className={`flex relative ${fullWidth ? 'w-full' : 'w-auto'}`}>
        <div className="relative w-full modern-theme">
          <CustomAutoComplete
            key={name}
            name={name}
            type={type}
            ref={inputRef}
            desc={desc}
            descId={descId}
            onChange={(e: any) => onChange(e)}
            className={`${generateClassName('input')}`}
            selectedItems={selectedItems}
            label={''}
            errors={getErrors()}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            handleAction={handleAction}
            isMultiple={isMultiple}
            data={data}
            onFocus={() => setIsFocused(true)}
          />

          <label
            htmlFor="id"
            onClick={(e) => {
              e.stopPropagation()
              onLabelClick()
            }}
            className={generateClassName('label')}
          >
            {label}
            {required ? <span className="text-error"> *</span> : <></>}
          </label>
        </div>
      </div>
    </div>
  )
}

export default QbsAutocompleteComponent

export function createRange(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index)
}
export function blobToFile(filedata: string, name: string) {
  if (filedata && name) {
    let file_name = name ? name.slice(1).toLowerCase() : 'png'
    let file = `data:image/${file_name};base64,${filedata}`
    return file
  }
  return null
  // return URL.createObjectURL(file);
  // File: file,
}
export function getFileExtension(filename: string): string {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)?.toLocaleLowerCase()
}
export function blobToActualFile(blobdata: Blob, name: string) {
  if (blobdata && name) {
    let file = new File([blobdata], name, {
      type: blobdata.type
    })
    return file
  }
}
export function generatePopUpTypes(type: number = 0) {
  switch (type) {
    case 1:
      return 'info'
    case 2:
      return 'warning'
    case 3:
      return 'error'
    case 4:
      return 'success'
    default:
      return 'warning'
  }
}
export function generatePopUpTypesFromSymbol(type: string = '') {
  switch (type) {
    case 'Activate':
      return 'info'
    case 'confirmSuccess':
      return 'success'
    case 'NoActionForYou':
      return 'warning'
    default:
      return ''
  }
}

export const getInitials = (name: any) => {
  if (!name) return ''
  const cleanedName = name.trim()
  if (cleanedName.includes(' - ')) {
    const parts = cleanedName.split(' - ')
    return parts.map((part: any) => part[0].toUpperCase()).join('')
  }
  const parts = cleanedName.split(' ')
  if (parts.length === 2) {
    return parts.map((part: any) => part[0].toUpperCase()).join('')
  }
  return cleanedName.substring(0, 2).toUpperCase()
}
export const getColorFromName = (name: string): string => {
  let hash = 0
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }
  return color
}

export const getErrorMessage = (error: any): string => {
  const getErrorString = (errorObject: any): string => {
    if (typeof errorObject === 'string' || Array.isArray(errorObject)) {
      return Array.isArray(errorObject) ? errorObject.join(', ') : errorObject
    } else if (errorObject && errorObject.message) {
      return Array.isArray(errorObject.message)
        ? errorObject.message.join(', ')
        : errorObject.message
    }
    return ''
  }

  const validationErrors = error?.response?.data?.ValidationErrors
  const errorData =
    error?.response?.data?.error ||
    error?.response?.data?.Error ||
    error?.message ||
    error
  const errorMessage =
    typeof error === 'string'
      ? error
      : getErrorString(validationErrors) ||
        getErrorString(errorData) ||
        'An unexpected error occurred'

  return errorMessage
}

export const generateTestId = (value: string) => {
  return value
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-')
    ?.toLowerCase()
}

export const dashboardDummyDataGen = (data: number[]) => {
  const randomValue = Math.floor(Math.random() * 10) + 1
  return data.map((item: number) => item * randomValue)
}

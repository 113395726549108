import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import { formContainerClass } from '@/styles/variables'
import React from 'react'
import { useTranslation } from 'react-i18next'

type RolesFormProps = {
  edit?: boolean
  editMode?: boolean
  detail?: boolean
}
export const BranchForm: React.FC<RolesFormProps> = ({
  edit,
  editMode,
  detail = true
}) => {
  const { t } = useTranslation()
  const formFields: FormBuilderProps[] = [
    {
      label: t('City'),
      placeholder: t('Enter City'),
      name: 'City',
      id: 'City',
      required: true,
      type: 'text',
      disabled: editMode,
      spacing: detail ? 6 : 12
    },
    {
      label: t('Town'),
      placeholder: t('Enter Town'),
      name: 'Town',
      id: 'Town',
      required: true,
      type: 'text',
      disabled: editMode,
      spacing: detail ? 6 : 12
    },
    {
      label: t('Postcode'),
      placeholder: t('Enter Postcode'),
      name: 'Postcode',
      id: 'Postcode',
      required: true,
      type: 'text',
      disabled: editMode,
      spacing: detail ? 6 : 12
    },
    {
      label: t('State'),
      placeholder: t('Enter State'),
      name: 'State',
      id: 'State',
      required: true,
      type: 'text',
      disabled: editMode,
      spacing: detail ? 6 : 12
    },
    {
      label: t('Telephone'),
      placeholder: t('Enter Telephone'),
      name: 'Telephone',
      id: 'Telephone',
      required: true,
      type: 'text',
      disabled: editMode,
      spacing: detail ? 6 : 12
    },
    {
      label: t('Mobile'),
      placeholder: t('Enter Mobile'),
      name: 'Mobile',
      id: 'Mobile',
      required: false,
      type: 'text',
      disabled: editMode,
      spacing: detail ? 6 : 12
    },
    {
      label: t('Email'),
      placeholder: t('Enter Email'),
      name: 'Email',
      id: 'Email',
      required: true,
      type: 'email',
      disabled: editMode,
      spacing: detail ? 6 : 12
    },
    {
      label: t('Nearest Port'),
      placeholder: t('Select Nearest Port'),
      name: 'PortName',
      id: 'PortId',
      required: false,
      type: 'autocomplete',
      apiParams: { key: 'PORT' },
      disabled: editMode,
      spacing: detail ? 6 : 12
    },
    {
      label: t('Address'),
      placeholder: t('Enter Address'),
      name: 'Address',
      id: 'Address',
      required: false,
      type: 'textarea',
      disabled: editMode,
      spacing: 12
    },
    {
      label: t('Archived'),
      placeholder: t('Archived'),
      name: 'Archived',
      id: 'Archived',
      required: false,
      type: 'switch',
      disabled: false,
      spacing: 12
    }
  ]

  return (
    <div className={formContainerClass}>
      <FormBuilder data={formFields} edit={edit} spacing={6} />
    </div>
  )
}

import { TextFieldProps } from '@/common/customComponenttypes'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomIcons from '../icons/customIcons'

const CustomColorSelect: React.FC<TextFieldProps> = ({
  name,
  id,
  label,
  readOnly = false,
  disabled = false,
  fullwidth = true,
  fieldEdit = false,
  register,
  setFocus,
  value,
  adorement,
  edited = false,
  required = false,
  onEditComplete,
  onEditCancel,
  onChange,
  onBlur,
  errors,
  //!! Variable is used to adjust the field level set to other fields.
  adjustFieldLabel = ''
}) => {
  const fieldRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const [editState, setEditState] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)
  const adorementRef = useRef<HTMLDivElement>(null)

  const onEditIconClick = () => {
    setEditState(true)
    setIsDisabled(false)
  }

  const onCancelIconClick = () => {
    setEditState(false)
    setIsDisabled(true)
    if (onEditCancel) {
      onEditCancel()
    }
  }
  const onSaveIconClick = () => {
    setEditState(false)
    setIsDisabled(true)
    if (onEditComplete) {
      onEditComplete()
    }
  }

  useEffect(() => {
    if (editState) {
      setFocus(name)
    }
  }, [editState, name, setFocus])
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  const getErrors = (err: any) => {
    let errMsg = ''
    if (err.message) {
      errMsg = t(err?.message)
    }
    return errMsg
  }

  const generateClassName = (from: string) => {
    let className = ''
    switch (from) {
      case 'input':
        className = ` w-full input ${fieldEdit || adorement ? 'pr-[75px] ' : 'pr-input '}`
        if (errors && errors[name]) {
          className += ' textfield-error'
        } else {
          if (edited) {
            className += ' textfield-success '
          } else {
            if (fieldEdit) {
              className += ' textfield-editable'
            } else {
              className += ' textfield'
            }
          }
        }
        break
      case 'adorement':
        className += '  absolute right-0 adorement mr-[1px]'
        break
      default:
        break
    }
    return className
  }
  return (
    <div className={fullwidth ? 'w-full' : 'w-auto'}>
      {label && (
        <div className={`${adjustFieldLabel === '' ? 'mb-1' : 'adjustFieldLabel'}`}>
          <label className={`text-[#667085] block text-common font-normal w-full`}>
            {label}
            {required ? <span className="text-error"> *</span> : <></>}
          </label>
        </div>
      )}
      <div className={`flex relative ${fullwidth ? 'w-full' : 'w-auto'}`}>
        <div className="flex items-center border-gray-300 border  rounded-[4px] h-10 w-full">
          <input
            id={id}
            disabled={isDisabled}
            ref={fieldRef}
            readOnly={readOnly ?? false}
            {...register?.(name, { required })}
            onChange={(e) => onChange?.(e)}
            type={'color'}
            value={value}
            className="w-10 h-10 bg-transparent"
            onBlur={(e) => onBlur?.(e)}
          />
          <input
            type="text"
            value={value}
            className={`block text-common outline-none font-normal px-4 w-full text-sm text-[#667085] bg-transparent  appearance-none peer rounded-[4px] disabled:text-input-disabled disabled:bg-disabled placeholder-transparent  focus:border-none`}
            readOnly
            disabled={isDisabled}
          />
        </div>
      </div>

      <div className={generateClassName('adorement')} ref={adorementRef}>
        {fieldEdit && editState && (
          <>
            <CustomIcons
              name="check_mark"
              className="pr-2 cursor-pointer"
              onClick={() => onSaveIconClick()}
            />
            <CustomIcons
              name="textfield-cancel"
              className="cursor-pointer"
              onClick={() => onCancelIconClick()}
            />
          </>
        )}
        {fieldEdit && !editState && isDisabled && (
          <CustomIcons
            name="edit"
            className="cursor-pointer"
            onClick={() => {
              onEditIconClick()
            }}
          />
        )}
      </div>

      {errors && errors[name] && (
        <div className="text-error text-error-label mt-[1px]">
          {getErrors(errors[name])}
        </div>
      )}
    </div>
  )
}

export default CustomColorSelect

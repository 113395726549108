import { FormBuilderProps } from '@/common/customComponenttypes'
import { Button, FormBuilder } from '@/components/common'
import { getFormErrorMessage } from '@/components/common/formBuilder/utils/helpers'
import CustomIcons from '@/components/common/icons/customIcons'
import {
  fetchKinById,
  updateKinAddressDetails
} from '@/modules/crewing/pages/crewSearch/api'
import {
  KinAddressDetailsSchema,
  KinAddressDetailsSchemaType
} from '@/modules/crewing/pages/crewSearch/details/crewDetails/familyDetails/addressSchema'
import {
  personalAddress,
  useCrewProfileStore
} from '@/modules/crewing/pages/crewSearch/details/crewDetails/store'
import { generateAddress } from '@/modules/crewing/pages/crewSearch/utils'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { getErrorMessage } from '@/utilities/dataGenerators'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  data?: any
  pageRouting: boolean
}

const KinAddressDetails: React.FC<Props> = ({ data, pageRouting }) => {
  const { expand, familyEdit, setFamilyEdit } = useCrewProfileStore()
  const { width } = useScreenBreakPoint()
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [relativeCrewId, setRelativeCrewId] = useState<string | null>(null)
  const [kinData, setKinData] = useState<any>()
  const { t } = useTranslation()
  const { showSnackBar } = useCustomSnackBar()

  const initData = () => {
    return {
      ...kinData
    }
  }

  const getDetails = () => {
    setLoading(true)
    fetchKinById(data?.crewNokId)
      .then((res) => {
        setKinData(res?.data || {})
        setRelativeCrewId(res?.data?.relativeCrewId)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    methods.reset({ ...initData() })
  }, [kinData])

  useEffect(() => {
    getDetails()
  }, [data.crewNokId])

  const methods = useForm<KinAddressDetailsSchemaType>({
    resolver: zodResolver(KinAddressDetailsSchema),
    mode: 'onChange',
    defaultValues: initData()
  })

  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = methods

  const getAddressFields = (prefix: string) => {
    return personalAddress.map((field) => `${prefix}${field}`)
  }

  const { permanentSameAsPresent } = watch()
  const permanentAddress = useWatch<any>({
    control,
    name: getAddressFields('permanent')
  })

  useEffect(() => {
    if (permanentSameAsPresent) {
      getAddressFields('present').forEach((field: string, index: number) => {
        setValue(field as keyof KinAddressDetailsSchemaType, permanentAddress[index])
      })
    }
  }, [permanentSameAsPresent, permanentAddress, setValue])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const errMsg = getFormErrorMessage(errors, [
        ...primaryAddressFormFields,
        ...secondaryAddressFormFields
      ])
      if (errMsg) {
        showSnackBar(`${t('errorToastMessage')} ${errMsg}`, 'error')
      }
    }
  }, [errors])

  const primaryAddressFormFields: FormBuilderProps[] = [
    ...(familyEdit
      ? [
          {
            label: t('Address line 1'),
            placeholder: t('Address line 1'),
            name: 'permanentAddressLine1',
            id: 'permanentAddressLine1',
            required: true,
            display_name: kinData?.permanentAddressLine1,
            type: 'text',
            maxLength: 100,
            spacing: 12
          },
          {
            label: t('Address line 2'),
            placeholder: t('Address line 2'),
            name: 'permanentAddressLine2',
            id: 'permanentAddressLine2',
            required: true,
            display_name: kinData?.permanentAddressLine2,
            type: 'text',
            maxLength: 100,
            spacing: 12
          }
        ]
      : [
          {
            label: t('Address'),
            placeholder: t('Address'),
            name: 'permanentAddressLine1',
            id: 'permanentAddressLine1',
            display_name: generateAddress(
              kinData?.permanentAddressLine1,
              kinData?.permanentAddressLine2
            ),
            type: 'text',
            spacing: 12
          }
        ]),

    {
      label: t('City'),
      placeholder: t('City'),
      name: 'permanentCity',
      id: 'permanentCity',
      required: true,
      display_name: kinData?.permanentCity,
      type: 'text',
      maxLength: 100,
      spacing: 6
    },
    {
      label: t('State'),
      placeholder: t('State'),
      name: 'permanentState',
      id: 'permanentState',
      required: true,
      display_name: kinData?.permanentState,
      type: 'text',
      maxLength: 100,
      spacing: 6
    },

    {
      label: t('Country'),
      required: true,
      placeholder: t('typeToSearch'),
      name: 'permanentCountryName',
      display_name: kinData?.permanentCountryName,
      noLocalFilter: true,
      id: 'permanentCountryId',
      type: 'autocomplete',
      apiParams: { key: 'COUNTRY' },
      isStaticList: true,
      spacing: 6
    },
    {
      label: t('Postcode'),
      required: true,
      placeholder: t('typeToSearch'),
      name: 'permanentPostCode',
      display_name: kinData?.permanentPostCode,
      id: 'permanentPostCode',
      type: 'text',
      maxLength: 50,
      spacing: 6
    },
    ...(familyEdit
      ? [
          {
            label: t('Alternate address is same as permanent address.'),
            name: 'permanentSameAsPresent',
            id: 'permanentSameAsPresent',
            type: 'checkbox',
            spacing: 12
          }
        ]
      : [])
  ]
  const secondaryAddressFormFields: FormBuilderProps[] = [
    ...(familyEdit
      ? [
          {
            label: t('Address line 1'),
            placeholder: t('Address line 1'),
            name: 'presentAddressLine1',
            id: 'presentAddressLine1',
            display_name: kinData?.presentAddressLine1,
            type: 'text',
            maxLength: 100,
            disabled: watch()?.permanentSameAsPresent,
            spacing: 12
          },
          {
            label: t('Address line 2'),
            placeholder: t('Address line 2'),
            name: 'presentAddressLine2',
            id: 'presentAddressLine2',
            display_name: kinData?.presentAddressLine2,
            type: 'text',
            maxLength: 100,
            disabled: watch()?.permanentSameAsPresent,
            spacing: 12
          }
        ]
      : [
          {
            label: t('Address'),
            placeholder: t('Address'),
            name: 'presentAddressLine1',
            id: 'presentAddressLine1',
            display_name: generateAddress(
              kinData?.presentAddressLine1,
              kinData?.presentAddressLine2
            ),
            type: 'text',
            spacing: 12
          }
        ]),

    {
      label: t('City'),
      placeholder: t('City'),
      name: 'presentCity',
      id: 'presentCity',
      display_name: kinData?.presentCity,
      type: 'text',
      maxLength: 100,
      disabled: watch()?.permanentSameAsPresent,
      spacing: 6
    },
    {
      label: t('State'),
      placeholder: t('State'),
      name: 'presentState',
      id: 'presentState',
      display_name: kinData?.presentState,
      type: 'text',
      maxLength: 100,
      disabled: watch()?.permanentSameAsPresent,
      spacing: 6
    },
    {
      label: t('Country'),
      placeholder: t('typeToSearch'),
      name: 'presentCountryName',
      display_name: kinData?.presentCountryName,
      noLocalFilter: true,
      id: 'presentCountryId',
      type: 'autocomplete',
      apiParams: { key: 'COUNTRY' },
      isStaticList: true,
      disabled: watch()?.permanentSameAsPresent,
      spacing: 6
    },
    {
      label: t('Postcode'),
      placeholder: t('typeToSearch'),
      name: 'presentPostCode',
      display_name: kinData?.presentPostCode,
      id: 'presentPostCode',
      type: 'text',
      maxLength: 50,
      disabled: watch()?.permanentSameAsPresent,
      spacing: 6
    }
  ]

  const onSubmit = (res: KinAddressDetailsSchemaType) => {
    setIsSubmitting(true)
    const payload = {
      ...res
    }

    updateKinAddressDetails(payload)
      .then((res) => {
        showSnackBar(res?.message)
        getDetails()
        setFamilyEdit(false)
      })
      .catch((err) => {
        showSnackBar(getErrorMessage(err), 'error')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="h-full relative w-full pt-4">
          <div className="grid grid-cols-12 gap-5 w-full">
            <div className={width > 1000 || !expand ? 'col-span-6' : 'col-span-12'}>
              <div className="grid grid-cols-12 gap-5 w-full">
                <div className="col-span-12 text-dark font-medium text-common">
                  Permanent Address
                </div>
                <FormBuilder
                  data={primaryAddressFormFields}
                  edit={true}
                  isLoading={loading}
                  hasCustombreakpoint
                  isView={!familyEdit}
                  showErrors={false}
                />
              </div>
            </div>
            <div className={width > 1000 || !expand ? 'col-span-6' : 'col-span-12'}>
              <div className="grid grid-cols-12 gap-5 w-full">
                <div className="col-span-12 text-[#0C121D] font-medium text-common">
                  Alternate Address
                </div>
                <FormBuilder
                  data={secondaryAddressFormFields}
                  edit={true}
                  isLoading={loading}
                  hasCustombreakpoint
                  isView={!familyEdit}
                  showErrors={false}
                  autoFocus={false}
                />
              </div>
            </div>
          </div>
        </div>
        {pageRouting && (
          <div className="w-full">
            <div className="float-right pb-6">
              {!familyEdit ? (
                <>
                  {relativeCrewId === null && (
                    <div
                      className="flex items-start text-common leading-4 cursor-pointer mr-4"
                      onClick={() => {
                        setFamilyEdit(true)
                      }}
                    >
                      <CustomIcons name="edit" type="large" className="mr-1" />
                      <span>Edit</span>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex">
                  <Button
                    label={'Save'}
                    className="me-2"
                    primary={false}
                    isLoading={isSubmitting}
                    type="submit"
                  />
                  <Button
                    label={'Cancel'}
                    outlined
                    onClick={() => {
                      setFamilyEdit(false)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </form>
    </FormProvider>
  )
}

export default KinAddressDetails

import { TabItemProps } from '@/common/customComponenttypes'
import { HeaderWrapper, SectionWraper } from '@/components/app/common'
import DetailHeaderComponent, {
  SubContentProps
} from '@/components/app/common/DetailHeaderComponent'
import { Breadcrumb, Tab, TabContainer } from '@/components/common'
import ConfirmModal from '@/components/common/modal/ConfirmModal'
import { routerStore } from '@/store'
import { detailHeaderBackground } from '@/styles/variables'
import { useDateFormat } from '@/utilities'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useVesselInspectionDetails } from './api'

const VesselInspectionDetails: React.FC = () => {
  const { t } = useTranslation()
  const params = useParams()

  const { data, refetch, isLoading } = useVesselInspectionDetails(params.inspID as string)

  // const detailHeaders: MainContentProps[] = [{ data: 'Findings', type: 'title' }]

  const detailContents: SubContentProps[] = [
    {
      label: t('Category'),
      value: data?.findCategoryName,
      spacing: 2,
      hasDivider: true
    },
    {
      label: t('Type'),
      value: data?.findingTypeName,
      spacing: 2
    },
    {
      label: t('Target Date'),
      value: useDateFormat(data?.targetDate, 'DD-MMM-YYYY'),
      spacing: 2
    },
    {
      label: t('Status'),
      value: data?.statusName,
      spacing: 6
    }
  ]

  const navigate = useNavigate()
  const location = useLocation()
  const baseURL = `${routerStore.VI_DETAILS_FINDINGS_DETAILS_MAIN}/${params.inspID}/${params.mainID}`
  const items = location?.pathname?.split('/')
  const lastItem = items[items.length - 1]
  const [isOpen, setIsOpen] = useState(false)
  const [section, setSection] = useState<string | number>('')
  const [activeTab, setActiveTab] = useState<number | string>(lastItem)
  const [edit, setEdit] = useState<boolean>(false)
  const backURL = `${routerStore.VESSEL_INSPECTION}/${params.mainID}/findings`
  const breadcrumbConfig = [
    { label: t('technical.technical') },
    {
      label: t('Vessel Inspections'),
      link: routerStore.VESSEL_INSPECTION
    },
    {
      label: t('Inspection Details'),
      link: backURL
    },
    { label: t('Finding Action') }
  ]
  useEffect(() => {
    setActiveTab(lastItem)
  }, [lastItem])

  const onTabClick = (selection: TabItemProps) => {
    if (edit) {
      setIsOpen(true)
      setSection(selection.id)
    } else {
      setActiveTab(selection.id)
      navigate(`${baseURL}/${selection.id as string}`)
    }
  }
  const handleConfirm = () => {
    setEdit(false)
    setIsOpen(false)
    setActiveTab(section)
    navigate(`${baseURL}/${section as string}`)
  }
  const tabData: TabItemProps[] = [
    {
      label: 'Details',
      id: routerStore.VI_DETAILS_GENERAL_INFO
    },
    {
      label: 'Documents',
      id: routerStore.VI_DETAILS_DOCUMENTS
    },
    {
      label: 'Actions',
      id: routerStore.VI_DETAILS_ACTIONS
    },
    {
      label: 'Audit Log',
      id: routerStore.VI_DETAILS_AUDIT_LOG
    }
  ]

  const refetchData = () => {
    refetch()
  }
  const contextProps = {
    edit: edit,
    setEdit: setEdit,
    inspID: data?.InspectionId,
    VesselId: data?.VesselId,
    FindingId: params.inspID,
    refetchData: refetchData
  }
  return (
    <>
      {/* <Headertitle title={t('Vessel Inspections')} /> */}
      <Breadcrumb items={breadcrumbConfig} />
      <div className={detailHeaderBackground}>
        <HeaderWrapper search={false}>
          {!isLoading && (
            <div className="grid grid-flow-col auto-cols-max place-items-center bg-text-primary pl-4 h-10">
              <div className="flex flex-row ">
                <div className="pr-[6px] pl-[11px] pt-[8px] ">
                  <a href={backURL}>
                    <svg
                      width="18"
                      height="10"
                      viewBox="0 0 18 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 5H17M1 5L5 1M1 5L5 9" stroke="white" />
                    </svg>
                  </a>
                </div>
                <div className="px-1 text-white truncate font-semibold">Findings</div>
              </div>
            </div>
          )}
          <DetailHeaderComponent
            isLoading={isLoading}
            // detailHeaders={detailHeaders}
            detailContents={detailContents}
          />
        </HeaderWrapper>
      </div>
      <SectionWraper>
        <TabContainer
          data={tabData}
          activeTab={activeTab}
          isContainer={true}
          onClick={onTabClick}
          tabBGClass="bg-tab-detail  px-[15px]  rounded-sm"
        >
          <Tab>
            <Outlet context={contextProps} />
          </Tab>
        </TabContainer>
      </SectionWraper>
      <ConfirmModal
        isOpen={isOpen}
        modalId="errorPopup"
        title={'Confirmation'}
        type="error"
        secondaryActionLabel="No"
        secondaryAction={() => setIsOpen(false)}
        actionLabel="Yes"
        onSubmit={() => handleConfirm()}
        topIcon="error"
        scrollDisable={true}
        onClose={() => setIsOpen(false)}
        subTitle={'Do you want to discard all the changes made?'}
      />
    </>
  )
}

export default VesselInspectionDetails

const CsvIcon = () => {
  return (
    <>
      <path
        d="M4 9.77778V7.46C4.00006 7.16534 4.12052 6.88277 4.33486 6.67444L8.808 2.32556C9.02228 2.11717 9.31292 2.00006 9.616 2H18.8571C19.1602 2 19.4509 2.11706 19.6653 2.32544C19.8796 2.53381 20 2.81643 20 3.11111V9.77778M4 19.7778V20.8889C4 21.1836 4.12041 21.4662 4.33474 21.6746C4.54906 21.8829 4.83975 22 5.14286 22H18.8571C19.1602 22 19.4509 21.8829 19.6653 21.6746C19.8796 21.4662 20 21.1836 20 20.8889V19.7778M9.71429 2V6.44444C9.71429 6.73913 9.59388 7.02174 9.37955 7.23012C9.16522 7.43849 8.87453 7.55556 8.57143 7.55556H4M7.04571 17.5556H5.88229C5.38307 17.5556 4.90431 17.3628 4.55131 17.0196C4.19831 16.6764 4 16.2109 4 15.7256V13.83C4 13.3447 4.19831 12.8792 4.55131 12.536C4.90431 12.1928 5.38307 12 5.88229 12H7.04571M16.5714 12L18.2206 17.3033L20 12M13.0777 11.8311L12 11.8089C11.6128 11.802 11.2373 11.9381 10.9491 12.1896C10.6609 12.4411 10.4815 12.7894 10.4469 13.1644C10.4144 13.5454 10.5363 13.9237 10.7865 14.2186C11.0367 14.5135 11.3954 14.7018 11.7863 14.7433L12.3989 14.8089C12.6369 14.8028 12.8729 14.8536 13.0861 14.9569C13.2992 15.0602 13.483 15.2128 13.6214 15.4012C13.7597 15.5897 13.8483 15.8083 13.8795 16.0379C13.9106 16.2674 13.8833 16.5009 13.8 16.7178C13.5714 17.3522 13.0731 17.5556 12.272 17.5556H10.9806"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export default CsvIcon

import { HeaderWrapper } from '@/components/app/common'
import { Breadcrumb, DeletePopUp } from '@/components/common'
import { TableHeight, guid } from '@/constants'
import { useAppContext } from '@/store/context/appContext'
import useDataStore from '@/store/dynamicStore/useDataStore'
import { parseSearchParams } from '@/utilities/dataParse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CardsList from '@/components/common/cardsList'
import { routerStore } from '@/store'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { getErrorMessage } from '@/utilities/dataGenerators'
import { useNavigate } from 'react-router-dom'
import { CreateCrew } from '../../components/app/registers/crew/CrewForm'
import CrewSearch from '../../components/app/registers/crew/CrewSearch'
import { deleteCrew, fetchCrewById, useCrewsList } from './api'
import useCrewStore from './store'

const initData = {}

const globalID = guid

const CrewsList: React.FC = () => {
  const { filters, setFilters, isSearchCount, setIsSearched } = useCrewStore()
  const [mainData, setMainData] = useState<any>([])
  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getData, dataStore } = useDataStore()
  const { setIsCommonLoader, setIsActionLoader } = useAppContext()
  const [edit, setEdit] = useState(true)
  const [rowData, setRowData] = useState<any>(initData)
  const [createOpen, setCreateOpen] = useState(false)
  const [viewable, setViewable] = useState(false)
  const [crewId, setCrewId] = useState<string>()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { showSnackBar } = useCustomSnackBar()

  const { data, isFetching, isFetched, refetch } = useCrewsList(
    {
      ...parseSearchParams(filters, [])
    },
    isEnabled
  )
  const [totalProps, setTotalProps] = useState<any>({ total: 0 })

  const breadcrumbConfig = [{ label: t('Crewing') }, { label: t('Crew Search') }]

  const getCategory = async () => {
    getData('rankCate', { key: 'RANKGROUP' }, '*')
  }

  useEffect(() => {
    setFilters({ ...filters, pageNo: 1 })
    getCategory()
    setIsEnabled(true)
  }, [])

  useEffect(() => {
    if (data?.totalRecords) setTotalProps({ total: data?.totalRecords })
    const newData = data?.results ?? []
    if (filters?.pageNo && (filters?.pageNo as number) > 1)
      setMainData((prevData: any) => [...prevData, ...newData])
    else setMainData(newData)

    if (isFetched) setIsLoaded(true)
  }, [data])

  // useEffect(() => {
  //   if (isSearchCount > 1) {
  //     refetch()
  //   }
  // }, [isSearchCount])

  useEffect(() => {
    setIsCommonLoader(isFetching)
  }, [isFetching])

  const onSearch = (data: any) => {
    setFiltersAndUpdate({ ...filters, ...data, pageNo: 1 })
  }

  const setFiltersAndUpdate = (newFilters: any) => {
    setFilters(newFilters)
    setIsSearched?.()
  }

  const onPagination = (row: number, pageNo: number) => {
    setFiltersAndUpdate({ ...filters, pageNo: pageNo, pageSize: row })
  }

  const onRowsPerPage = (row: number) => {
    setFiltersAndUpdate({ ...filters, pageSize: row, pageNo: 1 })
  }

  const handleToolActions = (slug: string) => {
    if (slug === 'archived')
      setFiltersAndUpdate({ ...filters, archived: !filters?.archived, pageNo: 1 })
  }

  const getArchiveText = () => (filters?.archived ? 'Hide Archived' : 'Show Archived')

  const handleSort = (order: string) => {
    setFiltersAndUpdate({ ...filters, sortBy: order, pageNo: 1 })
  }

  const columnsHeader = [
    {
      title: '',
      field: 'fullName',
      type: 'title'
    },
    {
      title: '',
      field: 'crewStatus',
      type: 'status'
    }
  ]

  const columns = [
    {
      title: 'Name',
      field: 'fullName',
      type: 'title',
      hidden: true
    },
    {
      title: 'Status',
      field: 'crewStatus',
      type: 'status',
      hidden: true
    },
    {
      title: 'Employee ID',
      field: 'employeeId'
    },
    {
      title: t('Nationality'),
      field: 'nationalityName',
      type: 'text',
      icon: '/assets/US.png'
    }
  ]

  const manageViewEditToogle = async (id?: string, mode = 'edit') => {
    if (id) {
      setIsCommonLoader(true)
      try {
        await fetchCrewById(id).then((res) => {
          setRowData(res?.data)
          setIsCommonLoader(false)
          setViewable(mode !== 'edit')
          setEdit(mode === 'edit')
          setCreateOpen(true)
        })
      } catch {
        setIsCommonLoader(false)
      }
    }
  }

  const handleMenuActions = async (slug: string, row: any) => {
    setCrewId(row?.crewId)
    setIsCommonLoader(true)
    // const permissionParams = {
    //   UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
    //   AccessKey: '',
    //   ID1: row?.VesClassId
    // }
    // const permission = await hasPermission(permissionParams)
    const permission = true
    setIsCommonLoader(false)
    if (slug === 'delete') {
      setDeleteOpen(permission)
    } else if (slug === 'edit') {
      if (permission) {
        manageViewEditToogle(row?.crewId)
      }
    }
  }

  const handleCloseForm = () => {
    setCreateOpen(false)
    setRowData(initData)
    setEdit(false)
    setViewable(false)
  }
  const onCardClick = (row: any) => {
    if (row?.crewId) {
      navigate(
        `${routerStore.CREW_SEARCH}/${row?.crewId}/${routerStore.CREW_DETAILS_BASIC_INFO}`
      )
    }
  }
  const handleDelete = () => {
    setIsActionLoader(true)
    deleteCrew(crewId as string)
      .then((res) => {
        showSnackBar(res?.message)
        setDeleteOpen(false)
        setCrewId(globalID)
        // refetch()
        setMainData((prevData: any) =>
          prevData?.filter((item: any) => item.crewId !== crewId)
        )
      })
      .catch((err) => {
        showSnackBar(getErrorMessage(err), 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
      })
  }

  const toolbarRender = (from: number = 1) => {
    return (
      <>
        {(!isFetching || isLoaded) && (
          <div
            className={`${from === 1 ? 'hidden lg:block flex-grow ml-10' : 'block lg:hidden pt-3 mt-3 border-t border-grey-borderLight'}`}
          >
            <div className={`${from === 1 ? '' : 'px-5'}`}>
              <ul className="list-none ul-marker flex items-center justify-start text-text-secondary text-xxs">
                <li className="before:bg-[#68D8D6]">Senior officers</li>
                <li className="before:bg-[#FC9292]">Junior officers</li>
                <li className="before:bg-[#94A6E8]">Ratings</li>
              </ul>
            </div>
          </div>
        )}
      </>
    )
  }

  const handleCreate = async () => {
    setIsCommonLoader(true)
    setViewable(false)
    const permission = true
    //    await hasPermission({
    //    UserAction: isAllow.CREATE,
    //    AccessKey: '',
    //    ID1: ''
    //  })
    setEdit(false)
    setCreateOpen(permission)
    setIsCommonLoader(false)
  }
  const titleFooter: any = [
    {
      icon: 'caption',
      field: 'rankName'
    },
    {
      icon: 'fleet',
      field: 'vesselName'
    }
  ]
  return (
    <>
      <Breadcrumb items={breadcrumbConfig} />
      <HeaderWrapper>
        <CrewSearch onSearch={onSearch} rankCate={dataStore?.rankCate ?? []} />
      </HeaderWrapper>

      {isSearchCount > 0 && (
        <CardsList
          variant="colored"
          avatar="avatar"
          columns={columns}
          onCardClick={onCardClick}
          updatedDate={'updatedOn'}
          columnsHeader={columnsHeader}
          titleFooter={titleFooter}
          data={mainData}
          isFetching={isFetching}
          pagination={true}
          defaultSort={'rankName'}
          title="Crew Master"
          toolBar={true}
          cardHeight={`calc(100vh - ${TableHeight.CardListDouble})`}
          // sortable={true}
          isDownloadable={true}
          handleSort={handleSort}
          sortDropDown={[
            { title: 'First Name', slug: 'givenName', id: '1' },
            { title: 'Surname', slug: 'surname', id: '2' },
            { title: 'Employee ID', slug: 'employeeId', id: '3' },
            { title: 'Rank', slug: 'crewRankId', id: '4' },
            { title: 'Vessel', slug: 'vesselId', id: '5' },
            { title: 'Status', slug: 'crewStatusId', id: '6' },

            { title: 'Nationality', slug: 'nationalityId', id: '7' }
          ]}
          handleMenuActions={handleMenuActions}
          primaryAction={{
            title: t('New'),
            handlePrimaryAction: handleCreate
          }}
          actionDropDown={[
            // { title: 'Edit', iconName: 'edit', slug: 'edit', id: '1' },
            {
              title: 'Delete',
              iconName: 'delete',
              slug: 'delete-old',
              // disabled:true,
              id: '2',
              isWarning: true
            }
          ]}
          paginationProps={{
            total: totalProps.total,
            currentPage: filters.pageNo as number,
            rowsPerPage: filters.pageSize as number,
            onPagination,
            onRowsPerPage
          }}
          handleToolActions={handleToolActions}
          actionToolDropDown={[
            { title: getArchiveText(), iconName: 'archieve', slug: 'archived', id: '1' }
          ]}
          toolbarMiddleRender={toolbarRender}
          toolbarBottomRender={() => {
            return toolbarRender(2)
          }}
        />
      )}
      <CreateCrew
        edit={edit}
        data={rowData}
        onClose={handleCloseForm}
        open={createOpen}
        title={`${edit ? 'Edit ' : viewable ? 'View' : 'Add '} Crew`}
        refetch={refetch}
        view={viewable}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
      />
    </>
  )
}

export default CrewsList

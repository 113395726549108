import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import {
  addFindingCategoryToRegister,
  updateFindingCategoryOnRegister
} from '@/modules/technical/pages/registers/findingCategories/api'
import {
  FindingCategoryDataType,
  FindingCategorySchema
} from '@/modules/technical/pages/registers/findingCategories/schema'

import { formContainerClass } from '@/styles/variables'
import { zodResolver } from '@hookform/resolvers/zod'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useFindingCategoriesFilterStore from '@/modules/technical/pages/registers/findingCategories/store'
type FindingCategoryFormProps = {
  data: any
  open: boolean
  onClose: () => void
  edit: boolean
  view: boolean
  title: string
  refetch: any
}
export const CreateFindingCategory: React.FC<FindingCategoryFormProps> = ({
  data,
  edit,
  view,
  open,
  title,
  onClose,
  refetch
}) => {
  const { t } = useTranslation()
  const { showSnackBar } = useCustomSnackBar()
  const { sireCatData } = useFindingCategoriesFilterStore()
  const [actionLoader, setActionLoader] = useState(false)

  const onSuccessAction = () => {
    methods.reset(initData())
    onClose()
    refetch()
  }

  const initData = () => {
    return { ...data }
  }

  const onSubmit = (res: FindingCategoryDataType) => {
    setActionLoader(true)
    const api = edit ? updateFindingCategoryOnRegister : addFindingCategoryToRegister
    const payload = {
      ...res
    }

    api(payload)
      .then((res) => {
        onSuccessAction()
        showSnackBar(res)
      })
      .catch((err) => {
        showSnackBar(err?.response?.data?.error, 'error')
      })
      .finally(() => setActionLoader(false))
  }

  const handleCloseForm = () => {
    methods.reset(initData())
    onClose()
  }

  const methods = useForm<FindingCategoryDataType>({
    resolver: zodResolver(FindingCategorySchema),
    mode: 'onChange',
    defaultValues: initData()
  })

  useEffect(() => {
    methods.reset({ ...data })
  }, [data])

  const formFields: FormBuilderProps[] = [
    {
      label: t('Finding Category Name'),
      placeholder: t('Finding Category Name'),
      name: 'FindingCatName',
      id: 'FindingCatName',
      required: true,
      type: 'text',
      spacing: 12
    },
    {
      label: t('Sire Cat. Code'),
      placeholder: t('Sire Cat. Code'),
      name: 'SireCatCode',
      id: 'SireCatCode',
      type: 'custom_select',
      data: sireCatData,
      spacing: 12
    },
    {
      label: t('Non-Confirmity'),
      name: 'NonConfirmity',
      id: 'NonConfirmity',
      type: 'switch',
      spacing: 4
    },
    {
      label: t('Sire Insp. only'),
      name: 'SireInspOnly',
      id: 'SireInspOnly',
      type: 'switch',
      spacing: 4
    },
    {
      label: t('Archived'),
      name: 'Archived',
      id: 'Archived',
      type: 'switch',
      spacing: 4
    }
  ]

  return (
    <>
      <DialogModal
        isOpen={open}
        small={true}
        type="sm"
        title={title}
        body={
          <div className="h-full relative w-full">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={formContainerClass}>
                  <FormBuilder data={formFields} edit={!view} />
                </div>
              </form>
            </FormProvider>
          </div>
        }
        onClose={handleCloseForm}
        actionLabel={edit ? 'Update' : view ? undefined : 'Save'}
        onSubmit={methods.handleSubmit(onSubmit)}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={view ? 'Close' : 'Cancel'}
        actionLoader={actionLoader}
      />
    </>
  )
}

import { lazy } from 'react'
import { operationsRouterStore } from './store'

// OPERATIONS
const CargoTypeList = lazy(() => import('./pages/registers/cargoType'))
const SpeedType = lazy(() => import('./pages/registers/speedType'))
const HarbourPos = lazy(() => import('./pages/registers/harbourPos'))
const DischargeSubCat = lazy(() => import('./pages/registers/dischargeSubCat'))
const VoyageTypeList = lazy(() => import('./pages/registers/voyageType'))
const PortActivity = lazy(() => import('./pages/registers/portActivities'))
const VoyageStatusList = lazy(() => import('./pages/registers/voyageStatus'))
const HalocarbonTypes = lazy(() => import('./pages/registers/halocarbonTypes'))
const DischargeMethodList = lazy(() => import('./pages/registers/dischargeMethod'))
const DischargeItems = lazy(() => import('./pages/registers/dischargeItems'))
const LoadConditions = lazy(() => import('./pages/registers/loadConditions'))
const HaloCarbons = lazy(() => import('./pages/registers/haloCarbons'))
const DischargeCategoryList = lazy(() => import('./pages/registers/dischargeCategories'))
const DataPoints = lazy(() => import('./pages/maintenance/datapoints'))
const VoyageReportTypes = lazy(() => import('./pages/maintenance/voyagereporttypes'))
const VoyageReportConfig = lazy(() => import('./pages/maintenance/voyagereportconfig'))
const ValidationRule = lazy(
  () => import('./pages/maintenance/voyagereportconfig/validationRule')
)

const operationsRoutes = [
  {
    slug: 'CARGO_TYPES',
    path: operationsRouterStore.CARGO_TYPE,
    component: <CargoTypeList />
  },
  {
    slug: 'SPEED_TYPE',
    path: operationsRouterStore.SPEED_TYPE,
    component: <SpeedType />
  },
  {
    slug: 'HARBOUR_POS',
    path: operationsRouterStore.HARBOUR_POS,
    component: <HarbourPos />
  },
  {
    slug: 'DISCHARGE_SUB_CAT',
    path: operationsRouterStore.DISCHARGE_SUB_CAT,
    component: <DischargeSubCat />
  },
  {
    slug: 'VOYAGE_TYPE',
    path: operationsRouterStore.VOYAGE_TYPE,
    component: <VoyageTypeList />
  },
  {
    slug: 'PORT_ACTIVITY',
    path: operationsRouterStore.PORT_ACTIVITY,
    component: <PortActivity />
  },
  {
    slug: 'VOYAGE_STATUSES',
    path: operationsRouterStore.VOYAGE_STATUS,
    component: <VoyageStatusList />
  },
  {
    slug: 'HALOCARBON_TYPES',
    path: operationsRouterStore.HALOCARBON_TYPES,
    component: <HalocarbonTypes />
  },
  {
    slug: 'DISCHARGE_METHOD',
    path: operationsRouterStore.DISCHARGE_METHOD,
    component: <DischargeMethodList />
  },
  {
    slug: 'DISCHARGE_ITEMS',
    path: operationsRouterStore.DISCHARGE_ITEMS,
    component: <DischargeItems />
  },
  {
    slug: 'LOAD_CONDITIONS',
    path: operationsRouterStore.LOAD_CONDITIONS,
    component: <LoadConditions />
  },
  {
    slug: 'HALO_CARBON',
    path: operationsRouterStore.HALO_CARBONS,
    component: <HaloCarbons />
  },
  {
    slug: 'DISCHARGE_CATEGORIES',
    path: operationsRouterStore.DISCHARGE_CATEGORIES,
    component: <DischargeCategoryList />
  },
  {
    slug: 'DATA_POINTS',
    path: operationsRouterStore.DATA_POINTS,
    component: <DataPoints />
  },
  {
    slug: 'VOYAGE_REPORT_TYPES',
    path: operationsRouterStore.VOYAGE_REPORT_TYPES,
    component: <VoyageReportTypes />
  },
  {
    slug: 'VOYAGE_REPORT_CONFIG',
    path: operationsRouterStore.VOYAGE_REPORT_CONFIG,
    component: <VoyageReportConfig />
  },
  {
    slug: 'VOYAGE_REPORT_CONFIG_VALIDATION',
    path: operationsRouterStore.VOYAGE_REPORT_CONFIG_VALIDATION,
    component: <ValidationRule />
  }
]

export default operationsRoutes

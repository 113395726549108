import { Accordion, AccordionBody, AccordionHeader } from '@/components/common/accordion'
import { useState } from 'react'

const AccoridonComponent = () => {
  const [open, setOpen] = useState<number>(1)
  const handleOpen = (value: number) => setOpen(open === value ? 0 : value)

  return (
    <div className="">
      <Accordion open={open === 1} id="1">
        <AccordionHeader onClick={() => handleOpen(1)}>Test 1</AccordionHeader>
        <AccordionBody>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet
          malesuada purus. Etiam elementum odio id leo tempus, in posuere orci commodo.
          Nunc sodales at massa eget tempor. Nam massa urna, dignissim sed arcu sit amet,
          suscipit gravida eros. Pellentesque placerat rutrum sodales. Proin ac tortor
          elementum, faucibus massa sit amet, vehicula leo. Nam iaculis feugiat
          pellentesque. Fusce eget finibus augue, ut dapibus mauris. Ut ante quam,
          vehicula a egestas sed, dignissim in nunc. Nulla accumsan enim vel eros lacinia,
          eu venenatis turpis luctus. Pellentesque habitant morbi tristique senectus et
          netus et malesuada fames ac turpis egestas. Ut posuere lectus velit, quis
          tristique ex varius eget. Etiam tristique lorem id purus pulvinar tincidunt.
        </AccordionBody>
      </Accordion>

      <Accordion open={open === 2} id="2">
        <AccordionHeader onClick={() => handleOpen(2)}>Test 1</AccordionHeader>
        <AccordionBody>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet
          malesuada purus. Etiam elementum odio id leo tempus, in posuere orci commodo.
          Nunc sodales at massa eget tempor. Nam massa urna, dignissim sed arcu sit amet,
          suscipit gravida eros. Pellentesque placerat rutrum sodales. Proin ac tortor
          elementum, faucibus massa sit amet, vehicula leo. Nam iaculis feugiat
          pellentesque. Fusce eget finibus augue, ut dapibus mauris. Ut ante quam,
          vehicula a egestas sed, dignissim in nunc. Nulla accumsan enim vel eros lacinia,
          eu venenatis turpis luctus. Pellentesque habitant morbi tristique senectus et
          netus et malesuada fames ac turpis egestas. Ut posuere lectus velit, quis
          tristique ex varius eget. Etiam tristique lorem id purus pulvinar tincidunt.
        </AccordionBody>
      </Accordion>
    </div>
  )
}

export default AccoridonComponent

import { lazy } from 'react'

import { pmsRouterStore } from './store'

// Technical

const MatStoreList = lazy(() => import('./pages/registers/materialStore'))
const SpecParameter = lazy(() => import('./pages/registers/specificationParameters'))
const SpecTempList = lazy(() => import('./pages/registers/specificationTemplates'))
const MaterialType = lazy(() => import('./pages/registers/materialTypes'))
const DefectCauseItems = lazy(() => import('./pages/registers/defectCauseItems'))
const DefectCauseTypes = lazy(() => import('./pages/registers/defectCauseTypes'))
const DefectCategory = lazy(() => import('./pages/registers/defectCategory'))
const CertificateTypeList = lazy(() => import('./pages/registers/certificateTypes'))
const DocumentType = lazy(() => import('./pages/registers/documentType'))
const JobTypes = lazy(() => import('./pages/registers/jobTypes'))
const JobDepartmentList = lazy(() => import('./pages/registers/jobDepartments'))

const CertificateAuthorityList = lazy(
  () => import('./pages/registers/certificateAuthorities')
)
const JobEnforcer = lazy(() => import('./pages/registers/jobEnforcers'))
const AccessoryWork = lazy(() => import('./pages/registers/accessoryWorks'))
const pmsRoutes = [
  {
    slug: 'MATERIAL_STORE',
    path: pmsRouterStore.MATERIAL_STORE,
    component: <MatStoreList />
  },
  {
    slug: 'SPECIFICATION_PARAMETERS',
    path: pmsRouterStore.SPECIFICATION_PARAMETERS,
    component: <SpecParameter />
  },
  {
    slug: 'SPECIFICATION_TEMPLATES',
    path: pmsRouterStore.SPECIFICATION_PARAMS,
    component: <SpecTempList />
  },
  {
    slug: 'MATERIAL_TYPES',
    path: pmsRouterStore.MATERIAL_TYPES,
    component: <MaterialType />
  },
  {
    slug: 'DEFECT_CAUSE_ITEMS',
    path: pmsRouterStore.DEFECT_CAUSE_ITEMS,
    component: <DefectCauseItems />
  },
  {
    slug: 'DEFECT_CAUSE_TYPES',
    path: pmsRouterStore.DEFECT_CAUSE_TYPES,
    component: <DefectCauseTypes />
  },
  {
    slug: 'DEFECT_CATEGORY',
    path: pmsRouterStore.DEFECT_CATEGORY,
    component: <DefectCategory />
  },
  {
    slug: 'CERTIFICATE_TYPES',
    path: pmsRouterStore.CERTIFICATE_TYPES,
    component: <CertificateTypeList />
  },
  {
    slug: 'JOB_TYPES',
    path: pmsRouterStore.JOB_TYPES,
    component: <JobTypes />
  },
  {
    slug: 'CERTIFICATE_AUTHORITIES',
    path: pmsRouterStore.CERTIFICATE_AUTHORITIES,
    component: <CertificateAuthorityList />
  },
  {
    slug: 'DOCUMENT_TYPES',
    path: pmsRouterStore.DOCUMENT_TYPES,
    component: <DocumentType />
  },
  {
    slug: 'JOB_ENFORCERS',
    path: pmsRouterStore.JOB_ENFORCERS,
    component: <JobEnforcer />
  },
  {
    slug: 'ACCESSORY_WORKS',
    path: pmsRouterStore.ACCESSORY_WORKS,
    component: <AccessoryWork />
  },
  {
    slug: 'JOB_DEPARTMENTS',
    path: pmsRouterStore.JOB_DEPARTMENTS,
    component: <JobDepartmentList />
  }
]
export default pmsRoutes

import React from 'react'
import { useOutletContext } from 'react-router-dom'

import VesselGeneralForm from '@/modules/technical/components/app/vesselParticulars/general/generalForm'
import { ParticularDetailsContextProps } from '../schema'
import { useVesselParticularsGeneral } from './api'

const TechnicalParticularGeneral: React.FC = () => {
  const { vesselID } = useOutletContext<ParticularDetailsContextProps>()
  const { data } = useVesselParticularsGeneral(vesselID)
  
  return (
    <div className="flex w-full gap-4">
      <VesselGeneralForm data={data?.data} />
    </div>
  )
}

export default TechnicalParticularGeneral

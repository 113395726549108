import { z } from 'zod'

export const BudgetAssemptionSchema = z.object({
  Title: z.string({ required_error: 'Title is required' }).min(1, 'Title is required'),
  Assumption: z
    .string({ required_error: 'Assumption is required' })
    .min(1, 'Assumption is required'),
  BudgetId: z.string().optional(),
  BudAssumptionId: z.string().optional()
})

export type BudgetAssemptionDataType = z.infer<typeof BudgetAssemptionSchema>

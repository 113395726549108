export const systemRouterStore: { [key: string]: string } = {
  //roles start
  ROLES_LIST: '/system/roles',
  ROLES_DETAILS: '/system/roles/:id',
  ROLES_DETAIL_USERS: 'users',
  ROLES_DETAIL_PRIVILEGE: 'privilege',
  SYS_FUNTION_LIST: '/system/func',
  SYS_USER_LIST: '/system/users',
  SYS_CONF_LIST: '/system/configs',
  SYS_AUDIT_LOGS: '/system/auditlog',
  SYSTEM_USER_DELIGATIONS: '/system/userdel'

  //roles end
}

import { SearchBuilderProps } from '@/common/customComponenttypes'
import { CommonSearch } from '@/components/common'
import useCrewStore from '@/modules/crewing/pages/crewSearch/store'

import React from 'react'
import { useTranslation } from 'react-i18next'
type SearchBoxProps = {
  onSearch: (filters: any) => void
  rankCate: any
}
const CrewSearch: React.FC<SearchBoxProps> = ({ onSearch, rankCate }) => {
  const { t } = useTranslation()
  const { filters } = useCrewStore()

  const formFields: SearchBuilderProps[] = [
    {
      label: t('First Name'),
      placeholder: t('First Name'),
      name: 'givenName',
      id: 'givenName',
      type: 'text',
      spacing: 3
    },
    {
      label: t('Surname'),
      placeholder: t('Surname'),
      name: 'surname',
      id: 'surname',
      type: 'text',
      spacing: 3
    },
    {
      label: t('Employee ID'),
      placeholder: t('Employee ID'),
      name: 'employeeId',
      id: 'employeeId',
      type: 'text',
      spacing: 3
    },
    {
      label: t('Rank'),
      placeholder: t('Type to Search'),
      name: 'crewRankId',
      id: 'crewRankId',
      type: 'autocomplete',
      apiParams: { key: 'CREWRANKS' },
      spacing: 3
    },
    {
      label: t('Vessel'),
      placeholder: t('Type to Search'),
      name: 'manningAgencyId',
      id: 'manningAgencyId',
      type: 'autocomplete',
      apiParams: { key: 'COMPBRANCH' },
      spacing: 3
    },
    {
      label: t('Status'),
      placeholder: t('Type to Search'),
      name: 'crewStatusId',
      id: 'crewStatusId',
      type: 'autocomplete',
      apiParams: { key: 'CREWSTATUS' },
      spacing: 3
    }
  ]

  return <CommonSearch onSearch={onSearch} data={formFields} activeFilters={filters} />
}

export default CrewSearch

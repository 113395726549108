const validateNumberOfDigits = (num: any, len: number = 8) => {
  console.log(num, len)
  // Convert number to string and remove any decimals to check the length of digits only.
  if (num.toString().includes('e')) {
    return false
  }
  const digits = num.toString().includes('.')
    ? num.toString().split('.')[0].length
    : num.toString().length
  return digits <= len
}

export default validateNumberOfDigits

const RatedPowerIcon = () => {
  return (
    <>
      <rect width="24" height="24" fill="white" />
      <path
        d="M11 11V1H13V11H11ZM12 18C9.76667 18 7.875 17.225 6.325 15.675C4.775 14.125 4 12.2333 4 10C4 8.68333 4.30417 7.45 4.9125 6.3C5.52083 5.15 6.36667 4.2 7.45 3.45L8.9 4.9C8 5.43333 7.29167 6.15417 6.775 7.0625C6.25833 7.97083 6 8.95 6 10C6 11.6667 6.58333 13.0833 7.75 14.25C8.91667 15.4167 10.3333 16 12 16C13.6667 16 15.0833 15.4167 16.25 14.25C17.4167 13.0833 18 11.6667 18 10C18 8.95 17.7417 7.97083 17.225 7.0625C16.7083 6.15417 16 5.43333 15.1 4.9L16.55 3.45C17.6333 4.2 18.4792 5.15 19.0875 6.3C19.6958 7.45 20 8.68333 20 10C20 12.2333 19.225 14.125 17.675 15.675C16.125 17.225 14.2333 18 12 18ZM8 23C7.71667 23 7.47917 22.9042 7.2875 22.7125C7.09583 22.5208 7 22.2833 7 22C7 21.7167 7.09583 21.4792 7.2875 21.2875C7.47917 21.0958 7.71667 21 8 21C8.28333 21 8.52083 21.0958 8.7125 21.2875C8.90417 21.4792 9 21.7167 9 22C9 22.2833 8.90417 22.5208 8.7125 22.7125C8.52083 22.9042 8.28333 23 8 23ZM12 23C11.7167 23 11.4792 22.9042 11.2875 22.7125C11.0958 22.5208 11 22.2833 11 22C11 21.7167 11.0958 21.4792 11.2875 21.2875C11.4792 21.0958 11.7167 21 12 21C12.2833 21 12.5208 21.0958 12.7125 21.2875C12.9042 21.4792 13 21.7167 13 22C13 22.2833 12.9042 22.5208 12.7125 22.7125C12.5208 22.9042 12.2833 23 12 23ZM16 23C15.7167 23 15.4792 22.9042 15.2875 22.7125C15.0958 22.5208 15 22.2833 15 22C15 21.7167 15.0958 21.4792 15.2875 21.2875C15.4792 21.0958 15.7167 21 16 21C16.2833 21 16.5208 21.0958 16.7125 21.2875C16.9042 21.4792 17 21.7167 17 22C17 22.2833 16.9042 22.5208 16.7125 22.7125C16.5208 22.9042 16.2833 23 16 23Z"
        fill="currentColor"
      />
    </>
  )
}

export default RatedPowerIcon

import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'

export const generalSchema = z.object({
  findingId: z.string().nullable(),
  vesselName: z.string().nullable(),
  vesselId: z.string().nullable(),
  performanceIndId: z.number().nullable(),
  assessmentAreaId: z.number().nullable(),
  assessmentAreaName: z.string().nullable(),
  // FindCategoryName: z.string().nullable(),
  findReferenceNo: z.number().nullable(),
  otherCause: z.string().nullable(),
  pscActionId: z.number().nullable(),
  pscActionName: z.string().nullable(),
  pscDeficiecnyName: z.string().nullable(),
  pscDeficiencyId: z.number().nullable(),
  //PerformanceIndName: z.string().nullable(),
  preventiveAction: z.string().nullable(),
  sireCode: z.string().nullable(),
  sireCodeId: z.number().nullable(),
  //SireCodeName: z.string().nullable(),
  // Status: z.string().nullable(),
  findCatId: z.number().nullable(),
  findingTypeId: z.number().nullable(),
  // InspFindingCauses: z.any().nullable(),
  inspectionId: z.string().nullable(),
  statusId: z.number().nullable(),
  statusName: z.string().nullable(),
  systemAreaId: z.number().nullable(),
  //SystemAreaName: z.string().optional(),
  //Updated: z.date().optional(),
  //UpdatedBy: z.string().nullable().optional(),
  //UpdatedOn: z.date().optional(),

  targetDate: z
    .date({ invalid_type_error: 'Target Date is required' })
    .refine((date: any) => Boolean(date), {
      message: 'Target Date is required'
    }),
  findingTypeName: z
    .string()
    .min(1, { message: 'Finding Type Name is required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  findCategoryName: z
    .string({ required_error: 'Category Name is required' })
    .min(1, { message: 'Category Name is required' }),
  performanceIndName: z
    .string({ required_error: 'Performance Indicator is required' })
    .min(1, { message: 'Performance Indicator is required' }),
  systemAreaName: z
    .string({ required_error: 'System Area is required' })
    .min(1, { message: 'System Area is required' }),
  sireCodeName: z
    .string({ required_error: 'Sire Chapter is required' })
    .min(1, { message: 'Sire Chapter is required' }),
  focusArea: z
    .string({ required_error: 'Focus Area Name is required' })
    .min(1, { message: 'Focus Area Name is required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  findingDetails: z
    .string({ required_error: 'Finding summary is required' })
    .min(5, { message: 'Finding summary is required' })
})

export type ParticularDetailsContextProps = {
  edit: boolean
  setEdit: (status: boolean) => void
  vesselID: string
  inspID: string
  refetchData?: () => void
}

export default GeneralSchema
export type GeneralSchema = z.infer<typeof generalSchema>

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const DashIconPie: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_760_421)">
        <path
          d="M9.30233 0C15.2105 0 20 4.78952 20 10.6977C20 11.0111 19.9865 11.3213 19.9601 11.6279H18.5587C18.092 16.3287 14.1259 20 9.30233 20C4.16479 20 0 15.8352 0 10.6977C0 5.87409 3.67133 1.908 8.37209 1.44128V0.0398847C8.6787 0.0134772 8.98893 0 9.30233 0ZM8.37209 3.31339C4.70111 3.77115 1.86047 6.90268 1.86047 10.6977C1.86047 14.8077 5.1923 18.1395 9.30233 18.1395C13.0973 18.1395 16.2288 15.2989 16.6866 11.6279H8.37209V3.31339ZM10.2326 1.90884V9.76744H18.0912C17.6583 5.62974 14.3702 2.34169 10.2326 1.90884Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_760_421">
          <rect width="20" height="20" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DashIconPie

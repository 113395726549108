import { useAuthStore } from '@/store/authStore'
import { getRefreshParams } from '@/store/authStore/refreshToken'
import { useExpireTimeStore } from '@/store/idleTimeoutStore'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { debounce } from './deepDebounce'

export const useTokenRefresh = () => {
  const { tokenExpire } = useExpireTimeStore()

  useEffect(() => {
    const tokenTime = tokenExpire - 5
    const tokenInterval = tokenTime * 60 * 1000 // 10 minute
    let lastCallTime = 0
    let initiated = false
    let timeoutDelay: number | null = null

    const handleRefreshToken = debounce(async () => {
      if (document.visibilityState === 'visible') {
        const current = Date.now()
        if (current - lastCallTime < tokenInterval) {
          return
        }
        lastCallTime = current
        const lastRefresh = parseInt(localStorage.getItem('lastTokenRefresh') ?? '0')
        const now = Date.now()
        if (now - lastRefresh > tokenInterval && !initiated) {
          initiated = true
          setTimeoutInterval()
          const isValid = await refreshToken()
          if (isValid) {
            const nextRefreshTime = now + tokenInterval
            localStorage.setItem('nextRefresh', nextRefreshTime.toString())
            localStorage.setItem('lastTokenRefresh', now.toString())
          }
          initiated = false
        }
      }
    }, 1000)

    const checkAndSetInterval = () => {
      const nextRefresh = parseInt(localStorage.getItem('nextRefresh') ?? '0')
      const now = Date.now()
      const delay = nextRefresh - now + 1000
      setTimeoutInterval(false)

      if (delay > 0) {
        timeoutDelay = window.setTimeout(() => {
          handleRefreshToken()
        }, delay)
      } else {
        handleRefreshToken()
      }
    }

    const setTimeoutInterval = (forceCreate: boolean = true) => {
      if (!forceCreate) forceCreate = window.tokenRefreshInterval ? false : true
      if (forceCreate) {
        clearTokenTimeout()
        window.tokenRefreshInterval = setInterval(
          checkAndSetInterval,
          tokenInterval
        ) as unknown as number
      }
    }

    const clearTokenTimeout = () => {
      if (timeoutDelay !== null) clearTimeout(timeoutDelay)
      clearInterval(window.tokenRefreshInterval)
    }

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        checkAndSetInterval() // Only check and set interval when tab is visible
      }
    })

    checkAndSetInterval() // Initialize on mount

    return () => {
      clearTokenTimeout()
      document.removeEventListener('visibilitychange', checkAndSetInterval)
    }
  }, [])

  return null // This hook does not render anything
}

export default useTokenRefresh

// Asynchronously refreshes the token and returns a boolean indicating succes
export const refreshToken = async () => {
  const source = axios.CancelToken.source()
  let isValid = false
  const {
    setAuthenticated,
    accessToken,
    browserId,
    refreshToken: currentRefreshToken,
    setAccessToken,
    setRefreshToken,
    clearAuthenticated
  } = useAuthStore.getState()
  if (accessToken && currentRefreshToken) {
    const credentials = getRefreshParams(accessToken, browserId, currentRefreshToken)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_MIPS_URL}/Login/RenewToken`,
        credentials,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined
          },
          cancelToken: source.token // Attach the cancel token to the request
        }
      )
      //setAccessToken('')
      setAccessToken(response.data.token || '')
      setRefreshToken(response.data.refresh_token || '')
      setAuthenticated(true)
      localStorage.setItem('lastTokenRefresh', Date.now().toString())
      isValid = true
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('Request was cancelled', err.message)
      } else {
        clearAuthenticated()
        localStorage.clear()
        sessionStorage.clear()
        localStorage.setItem('should_reload', new Date().toString())
        window.location.href = '/session-timeout'
        isValid = false
      }
    }
  }
  return isValid
}

export function useCookiePolling(cookieName: string, interval = 30000) {
  const [cookieValue, setCookieValue] = useState(
    () =>
      document.cookie
        .split('; ')
        .find((row) => row.startsWith(cookieName + '='))
        ?.split('=')[1]
  )
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newCookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith(cookieName + '='))
        ?.split('=')[1]
      if (newCookieValue !== cookieValue) {
        setCookieValue(newCookieValue)
      }
    }, interval)

    return () => clearInterval(intervalId)
  }, [cookieName, cookieValue, interval])
}

import { ConfirmModalProps } from '@/common/customComponenttypes'
import React, { useCallback, useEffect, useState } from 'react'
import { Portal } from '../../common'
import CustomIcons from '../icons/customIcons'
import { Button } from '../index'

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  modalId = undefined,
  onClose,
  itemLabels,
  topIcon = 'confirmWarning',
  onSubmit,
  actionLabel = 'Yes',
  disabled = false,
  actionLoader = false,
  title,
  type = 'orange',
  subTitle,
  body,

  secondaryAction,
  secondaryActionLabel = 'No',
  buttonSize = 'xs',
  backdropCancel,
  scrollDisable,
  fromModal,
  isPrimaryActionButton
}) => {
  const [showModal, showShowModal] = useState(isOpen)

  const enableScroll = useCallback(() => {
    if (scrollDisable) {
      const body: HTMLBodyElement | null = document.querySelector('body')
      if (body) body.style.overflow = isOpen ? 'hidden' : 'auto'
    }
  }, [isOpen, scrollDisable])

  useEffect(() => {
    showShowModal(isOpen)
    enableScroll()
  }, [enableScroll, isOpen, scrollDisable])

  // const handleCancel = useCallback(
  //   (type: string) => {
  //     if (disabled && type === 'button') {
  //       return
  //     }
  //     showShowModal(false)
  //     enableScroll()

  //     if (onClose) onClose(type, modalId)
  //   },
  //   [disabled, enableScroll, onClose, modalId]
  // )

  const handleOutsideCancel = useCallback(
    (type: string) => {
      if (!backdropCancel) {
        return
      }
      showShowModal(false)
      enableScroll()
      if (onClose) onClose(type, modalId)
    },
    [backdropCancel, enableScroll, onClose, modalId]
  )

  const handleSecondaryAction = useCallback(
    (e: any) => {
      e.stopPropagation()
      if (disabled) {
        return
      }
      showShowModal(false)
      enableScroll()
      if (secondaryAction) secondaryAction()
      if (onClose) onClose('button', modalId)
    },
    [disabled, enableScroll, secondaryAction, onClose, modalId]
  )

  const generateIcon = useCallback(() => {
    switch (type) {
      case 'success':
        return 'confirmSuccess'
      case 'error':
        return 'confirmCancel'
      case 'warning':
        return 'confirmWarning'
      case 'info':
        return 'confirmInfo'
      default:
        return topIcon
    }
  }, [type])
  const generateTheme = useCallback(() => {
    switch (type) {
      case 'success':
        return 'green'
      case 'error':
        return 'red'
      case 'warning':
        return 'orange'
      case 'info':
        return 'blue'
      case 'orange':
        return 'orange'
      default:
        return ''
    }
  }, [type])
  const getConirmationStyle = (from: string) => {
    switch (from) {
      case 'title':
        return ' text-xl text-dark leading-[22px] pt-6 pb-3 font-semibold'
      case 'subtitle':
        return 'text-grey-secondary text-m leading-[20px] font-normal'
      case 'sublabel':
        return 'text-error-gold text-m pt-2 leading-[20px] font-normal text-wrap break-all'
    }
  }

  const handleSubmit = useCallback(
    (e: any) => {
      e.stopPropagation()
      if (disabled) {
        return
      }
      if (onSubmit) onSubmit()
    },
    [onSubmit, disabled]
  )

  if (!isOpen) {
    return null
  }
  return (
    <Portal>
      <div
        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none overscroll-none ${
          !showModal ? 'hidden' : ''
        }`}
        style={{ zIndex: 999 }}
        data-testid="modal-backdrop"
        onClick={() => handleOutsideCancel('outside')}
      >
        <div className={`relative w-[500px] h-auto mx-auto lg:h-auto md:h-auto `}>
          <div
            className={`
            translate
            duration-300
            h-full
            ${showModal ? 'translate-y-0' : 'translate-y-full'}
            ${showModal ? 'opacity-100' : 'opacity-0'}
          `}
          >
            <div
              className="
              translate
              h-full
              lg:h-auto
              md:h-auto
              border-1
              modal-border
          
              relative
              flex
              flex-col
              w-full
              bg-white
              focus:outline-none
                     rounded-dialog 
              dialog
            "
            >
              <div className={`mx-auto p-2.5 text-center `}>
                <div className="flex items-center justify-center">
                  <CustomIcons
                    name={generateIcon()}
                    isWrapper={false}
                    className="flex items-center justify-center "
                    data-testid="button-icon-left"
                  />
                </div>
                <h3 className={getConirmationStyle('title')}>{title}</h3>
                <p className={getConirmationStyle('subtitle')}>{subTitle}</p>
                {itemLabels?.subTitle && (
                  <p className={getConirmationStyle('sublabel')}>
                    {itemLabels?.subTitle}
                  </p>
                )}
                {body}

                <div
                  className="
                    flex
                    flex-row
                    items-center
                    gap-2.5 pt-10
                    w-full
                    justify-center
                  "
                >
                  {actionLabel && (
                    <Button
                      primary={isPrimaryActionButton}
                      disabled={disabled}
                      size={buttonSize}
                      isLoading={actionLoader}
                      label={actionLabel}
                      theme={generateTheme()}
                      onClick={handleSubmit}
                      className="inline-flex items-center text-center px-6 "
                    />
                  )}
                  {secondaryAction && secondaryActionLabel && (
                    <Button
                      disabled={disabled}
                      size={buttonSize}
                      label={secondaryActionLabel}
                      onClick={handleSecondaryAction}
                      className=" px-6 "
                      outlined
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && !fromModal && (
        <div className="opacity-50 fixed inset-0 z-40 bg-overlay"></div>
      )}
    </Portal>
  )
}

export default ConfirmModal

import { CardListProps } from '@/common/customComponenttypes'
import { useRef } from 'react'

import Button from '../buttons/Button'
import CustomExport from '../export'
import { ExportRef } from '../kendoTable/utils/export'
import MenuDropDown from '../menudropdown/MenuDropDown'
import SortDropDown from './utils/SortDropDown'

export default function Toolbar({
  columns,
  columnsHeader,
  data,
  title = '',
  exportFileName,
  primaryAction,
  isDownloadable,
  paginationProps,
  isFetching,
  handleToolActions,
  actionToolDropDown,
  defaultSort,
  handleSort,
  sortDropDown,
  toolbarMiddleRender,
  toolbarBottomRender,
  exportPaperSize
}: CardListProps) {
  const exportRef = useRef<ExportRef>(null)
  const handleMenu = (slug: string) => {
    if (slug === 'pdf' || slug === 'csv') {
      exportRef?.current?.download(slug)
    } else if (handleToolActions) {
      handleToolActions(slug)
    }
  }
  const actionDropDown = [
    {
      title: 'Export PDF',
      iconName: 'pdf',
      id: '1',
      slug: 'pdf'
    },
    {
      title: 'Export CSV',
      iconName: 'csvIcon',
      id: '2',
      slug: 'csv'
    }
  ]

  const allActionDropDown = actionToolDropDown
    ? actionToolDropDown.concat(actionDropDown)
    : isDownloadable
      ? actionDropDown
      : []
  return (
    <>
      <div className="w-full bg-white py-3">
        <div className="flex justify-between  px-5 items-center">
          <div>
            {(!isFetching || (paginationProps?.total as number) > 0) && (
              <p className="text-secondary text-common">
                Showing {data?.length ?? 0} of {paginationProps?.total ?? 0} records
              </p>
            )}
          </div>
          <>{toolbarMiddleRender && toolbarMiddleRender()}</>
          <div className=" flex gap-2 items-center justify-center">
            {primaryAction && (
              <div>
                <Button
                  primary={false}
                  size="sm"
                  label={primaryAction?.title ?? 'Add New'}
                  icon={primaryAction?.icon ?? 'plus'}
                  onClick={() => primaryAction?.handlePrimaryAction?.()}
                />
              </div>
            )}
            {sortDropDown && sortDropDown.length > 0 && (
              <SortDropDown
                defaultSort={defaultSort}
                sortDropDown={sortDropDown}
                handleSort={handleSort}
              />
            )}
            {allActionDropDown.length > 0 && (
              <MenuDropDown
                isDownload={true}
                buttonSize="sm"
                iconName="three_dot"
                iconCustDimension={18}
                iconViewBox={false}
                actionDropDown={allActionDropDown}
                handleMenuActions={handleMenu}
              />
            )}
          </div>
        </div>
        <>{toolbarBottomRender && toolbarBottomRender()}</>
      </div>
      <CustomExport
        key={`custom-export-${new Date().toString()}`}
        columns={columns}
        data={data}
        title={title}
        exportFileName={exportFileName}
        ref={exportRef}
        paperSize={exportPaperSize}
      />
    </>
  )
}

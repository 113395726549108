import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import { formContainerClass } from '@/styles/variables'
import React from 'react'
import { useTranslation } from 'react-i18next'

type RolesFormProps = {
  edit?: boolean
  editMode?: boolean
  detail?: boolean
}
export const CreateCompany: React.FC<RolesFormProps> = ({ edit, editMode, detail }) => {
  const { t } = useTranslation()
  const formFields: FormBuilderProps[] = [
    {
      label: t('Company Name'),
      placeholder: t('Company Name'),
      name: 'CompanyName',
      id: 'CompanyName',
      required: true,
      disabled: editMode,
      type: 'text',
      spacing: detail ? 6 : 12
    },
    {
      label: t('Country'),
      placeholder: t('Enter Country'),
      name: 'CountryName',
      id: 'CountryId',
      disabled: editMode,
      required: true,
      type: 'autocomplete',
      apiParams: { key: 'COUNTRY' },
      checkParams: ['param1', 'param2'],
      isStaticList: true,
      spacing: detail ? 6 : 12
    },

    {
      label: t('Remarks'),
      placeholder: t('Remarks'),
      name: 'Remarks',
      id: 'Remarks',
      required: false,
      type: 'textarea',
      disabled: false,
      spacing: 12
    },
    {
      label: t('Archived'),
      placeholder: t('Archived'),
      name: 'Archived',
      id: 'Archived',
      required: false,
      type: 'switch',
      disabled: false,
      spacing: detail ? 2 : 12
    }
  ]
  if (detail) {
    formFields.push({
      label: t('Blocked'),
      placeholder: t('Blocked'),
      name: 'Blocked',
      id: 'Blocked',
      required: false,
      type: 'switch',
      disabled: false,
      spacing: 2
    })
  }

  return (
    <>
      <div className={formContainerClass}>
        <FormBuilder data={formFields} edit={edit} spacing={6} />
      </div>
    </>
  )
}

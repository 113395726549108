import ConfirmModal from '@/components/common/modal/ConfirmModal'
import React from 'react'

interface CountdownPopupProps {
  countdown: number
  onDismiss: () => void
  isOpen: boolean
  handleLogout: () => void
}

const CountdownPopup: React.FC<CountdownPopupProps> = ({
  countdown,
  onDismiss,
  isOpen,
  handleLogout
}) => {
  const minutes = Math.floor(countdown / 60000)
  const seconds = Math.floor((countdown % 60000) / 1000)
  const handleReturnTitle = () => {
    // if (minutes > 0) {
    //   return `Your session will expire in ${minutes} minute${minutes > 1 ? 's' : ''}`
    // } else {
    //   return `Your session will expire in ${seconds} second${seconds > 1 ? 's' : ''}`
    // }
    const formatTime = (time: number) => (time < 10 ? `0${time}` : time)

    const formattedMinutes = formatTime(minutes)
    const formattedSeconds = formatTime(seconds)

    if (minutes >= 1) {
      return `Your session will expire in ${formattedMinutes}:${formattedSeconds} minutes`
    } else {
      return `Your session will expire in ${formattedMinutes}:${formattedSeconds} seconds`
    }
  }
  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        modalId="popup1"
        topIcon="idletimeout"
        type=""
        isPrimaryActionButton={false}
        actionLabel="Yes, Continue"
        onClose={() => onDismiss()}
        onSubmit={() => onDismiss()}
        actionLoader={false}
        title={handleReturnTitle()}
        subTitle=" Click 'Continue' to proceed with your session"
        body={<div></div>}
        secondaryAction={() => handleLogout()}
        secondaryActionLabel="Logout"
        scrollDisable={true}
        isCloseIcon={false}
      />
    </>
  )
}

export default CountdownPopup

import { CustomKendoTable } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import {
  copyToVessel,
  removeFromVessel
} from '@/modules/technical/pages/vesselParticulars/details/api'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchVesselData } from '../../../../pages/vesselParticulars/api'
type Props = {
  openVesselPopup: boolean
  handleClose: (bool?: boolean) => void
  rowData?: any
  vesselId: string
  type?: string
}
export default function ManageEuipVessel({
  openVesselPopup,
  handleClose,
  rowData,
  vesselId,
  type
}: Props) {
  const { t } = useTranslation()

  const [vesselData, setVesselData] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [checkedKeys, setCheckedKeys] = useState<any>([])
  const colufmns = [
    {
      title: t('Vessel'),
      field: 'VesselName',
      width: 125,
      type: 'text'
    },
    {
      title: t('imoNumber'),
      field: 'Imonumber',
      width: 120,

      type: 'text'
    },
    {
      title: t('code'),
      field: 'VesselCode',
      type: 'text',
      width: 80
    },

    {
      title: t('status'),
      field: 'StatusName',
      width: 140,

      type: 'text'
    },
    {
      title: t('type'),
      field: 'TypeName',
      width: 120,

      type: 'text'
    },
    {
      title: t('flag'),
      field: 'FlagName',
      width: 160,

      type: 'text'
    }
  ]

  const handleMultiSelect = (value: any) => {
    setCheckedKeys(value)
  }
const { showSnackBar } = useCustomSnackBar()
  const handleSubmit = () => {
    if (checkedKeys?.length > 0) {
      const data = {
        Vessels: checkedKeys,
        VesselId: vesselId,
        MaterialTypes: [rowData.MaterialTypeId]
      }
      const apiFunc = type === 'add' ? copyToVessel : removeFromVessel
      apiFunc(data).then((res) => {
        showSnackBar(res)
        handleClose(true)
      })
    }
  }

  const handleGetVesselData = () => {
    fetchVesselData({ pagesize: 10000, pageNo:1 })
      .then((res) => {
        setVesselData(res?.Results)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (openVesselPopup) {
      handleGetVesselData()
      setCheckedKeys([])
    }
  }, [openVesselPopup])
  return (
    <DialogModal
      isOpen={openVesselPopup}
      type={'md'}
      title={type === 'add' ? 'Copy to vessel' : 'Remove from Vessel'}
      onClose={() => handleClose()}
      actionLabel={'Save'}
      onSubmit={() => handleSubmit()}
      actionDisabled={checkedKeys?.length === 0}
      secondaryAction={() => handleClose()}
      secondaryActionLabel={'Cancel'}
      body={
        <div>
          <CustomKendoTable
            columns={colufmns}
            isFetching={loading}
            handleMultiSelect={handleMultiSelect}
            checkedKeys={checkedKeys}
            data={vesselData}
            multiSelection={true}
            toolBar={true}
            sortable={false}
            resizable={false}
            tableKey="VesselId"
            rowKey="VesselId"
            tableHeight={`calc(100vh - 500px)`}
          />
        </div>
      }
    />
  )
}

import { z } from 'zod'

export const AccountSchema = z.object({
  AccountDescription: z
    .string({
      required_error: 'Account Description is required',
      invalid_type_error: 'Account Description is required'
    })
    .min(1, 'Account Description is required'),

  AccountId: z
    .string({
      required_error: 'Account Code is required',
      invalid_type_error: 'Account Code is required'
    })
    .min(1, 'Account Code is required'),
  BudgetAccountId: z.string().nullable().optional(),
  ChartDetailId: z.string().nullable().optional(),
  ParentId: z.string().nullable().optional(),
  BudgetId: z.string().nullable().optional(),
  PostingType: z.number().nullable().optional(),
  Posting: z.boolean().nullable().optional()
})

export type AccountDataType = z.infer<typeof AccountSchema>

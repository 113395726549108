import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const PlusMenuIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35714 12V6.64286H0V5.35714H5.35714V0H6.64286V5.35714H12V6.64286H6.64286V12H5.35714Z"
        fill="#3D414A"
      />
    </svg>
  )
}

export default PlusMenuIcon

import { z } from 'zod'

export const formSchema = z.object({
  CompCurrId: z.string(),
  CurrencyName: z
    .string({ required_error: 'Currency is required' })
    .min(1, 'Currency is required'),
  CurrencyId: z.string().optional(),
  CompanyId: z.string().optional()
})

export type FormSchema = z.infer<typeof formSchema>

import create from 'zustand'
import { persist } from 'zustand/middleware'

interface IdleTimeoutState {
  countDown: number
  setCountDown: (countDown: number) => void
  isIdle: boolean
  setIsIdle: (isIdle: boolean) => void
  showPopup: boolean
  setShowPopup: (showPopup: boolean) => void
}

export const useIdleTimeoutStore = create<IdleTimeoutState>()(
  persist(
    (set, get) => ({
      countDown: 0,
      setCountDown: (countDown: number) => {
        if (countDown !== get().countDown) {
          set({ countDown })
        }
      },
      isIdle: false,
      setIsIdle: (isIdle: boolean) => {
        if (isIdle !== get().isIdle) {
          set({ isIdle })
        }
      },
      showPopup: false,
      setShowPopup: (showPopup: boolean) => {
        if (showPopup !== get().showPopup) {
          set({ showPopup })
        }
      }
    }),
    {
      name: 'idleTimeoutStore' // Name of the item in storage
    }
  )
)
interface ExpireTimeStore {
  tokenExpire: number
  setTokenExpire: (tokenExpire: number) => void
  idleExpire: number
  setIdleExpire: (idleExpire: number) => void
}
export const useExpireTimeStore = create<ExpireTimeStore>()(
  persist(
    (set) => ({
      tokenExpire: 0,
      idleExpire: 0,
      setTokenExpire: (val: number) => set(() => ({ tokenExpire: val })),
      setIdleExpire: (val: number) => set(() => ({ idleExpire: val }))
    }),
    {
      name: 'idleTime-store-session'
    }
  )
)

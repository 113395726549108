import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { Columns } from '../../../../common/customComponenttypes'
import formatDate from '../../../../utilities/usedateformat'

type ColumnDef = Columns

type Props = {
  columns: ColumnDef[]
  data?: any[]
  exportFileName?: string
  title: string
}

const byString = (data: any, field: string): any => {
  // Implementation of byString function
  // ...
}

const defaultExportPdf = (props: Props): void => {
  if (!jsPDF) return

  const dateFormat = (type: string, val: Date) => {
    switch (type) {
      case 'date':
        return formatDate(val)
      case 'dateTime':
        return formatDate(val, 'DD-MMM-YYYY hh:mm')
    }
    return val
  }

  const columns: ColumnDef[] = props.columns.filter(
    (columnDef) =>
      (!columnDef.hidden || columnDef.export) &&
      columnDef.export !== false &&
      columnDef.field
  )

  const formattedData = props?.data
    ? props?.data.map((rowData) =>
        columns.map(
          (columnDef) => rowData[columnDef.field] ?? byString(rowData, columnDef.field)
        )
      )
    : []

  columns.forEach((item, colIndex) => {
    formattedData.forEach((dataRow, rowIndex) => {
      if (item.type === 'date' || item.type === 'dateTime') {
        //const format = item.type === 'date' ? 'TabledateFormat' : 'TabledateTimeFormat'
        formattedData[rowIndex][colIndex] = dataRow[colIndex]
          ? dateFormat(item.type, dataRow[colIndex])
          : ''
      } else if (dataRow[colIndex] === true) {
        formattedData[rowIndex][colIndex] = 'Yes'
      } else if (dataRow[colIndex] === false) {
        formattedData[rowIndex][colIndex] = 'No'
      }
    })
  })

  const content = {
    startY: 50,
    head: [columns.map((columnDef) => columnDef.title)],
    body: formattedData
  }

  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'pt',
    format: 'A4'
  })

  doc.setFontSize(15)
  doc.text(props.exportFileName || props.title, 40, 40)
  doc.autoTable(content)
  doc.save((props.exportFileName || props.title || 'data') + '.pdf')
}

export default defaultExportPdf

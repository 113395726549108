type ColProps = {
  col: {
    title: string
    field: string
    type?: string
    icon?: string
    align?: 'right' | 'left' | 'center'
    render?: (row: any) => any
  }
  rowData: any
  lastChild?: boolean
  index?: number
  childrens?: number
}
export default function DataColumn({ col, rowData, index, childrens = 0 }: ColProps) {
  const renderFunc = col.render && col.render
  return (
    <div className={`grid ${childrens > 2 ? 'col-span-4' : 'col-span-6'}  p-5`}>
      {renderFunc && (
        <div
          key={col.title}
          className="p-cell text-xxs text-table-bodyColor font-normal td-custom"
        >
          {renderFunc(rowData)}
        </div>
      )}
      {!renderFunc && (
        <div key={col.title} className="flex flex-col gap-y-3">
          <div className={`text-grey-secondary text-xxs font-normal`}>{col.title} </div>
          <div
            className={`text-secondary font-medium text-common flex ${col?.icon ? 'gap-2' : ''}`}
          >
            <div className="flex items-center justify-center">
              {col?.icon && (
                <div className="flex items-center justify-center">
                  <div className="w-[18px] h-[18px] rounded-full">
                    <img
                      className="w-[18px] h-[18px] rounded-full"
                      alt="icon"
                      src={col.icon}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>{rowData[col.field] ?? '-'}</div>
          </div>
        </div>
      )}
    </div>
  )
}

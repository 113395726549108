import Cookies from 'js-cookie'
import { create } from 'zustand'

type AuthStoreType = {
  authenticated: boolean | undefined
  accessToken: string | undefined
  refreshToken: string | undefined
  browserId: string | undefined
  userData: any
  setAuthenticated: (authenticated: boolean | undefined) => void
  clearAuthenticated: () => void
  setAccessToken: (data: any) => void
  setBrowserId: (data: any) => void
  setRefreshToken: (data: any) => void
  setUserData: (data: any) => void
}

const setCookie = (name: string, data: any, expires: number = 1) => {
  Cookies.set(name, data, { expires, sameSite: 'strict' })
}

const removeCookie = (name: string) => {
  Cookies.remove(name)
}

export const useAuthStore = create<AuthStoreType>((set) => ({
  authenticated: Boolean(Cookies.get('authenticated')),
  accessToken: Cookies.get('accessToken'),
  refreshToken: Cookies.get('refreshToken'),
  browserId: Cookies.get('browserId'),
  userData: Cookies.get('userData') || {},
  setAuthenticated: (is_authenticated) => {
    setCookie('authenticated', is_authenticated)
    set({ authenticated: is_authenticated })
  },
  clearAuthenticated: () => {
    const cookieNames = [
      'authenticated',
      'accessToken',
      'refreshToken',
      'browserId',
      'userData'
    ]
    cookieNames.forEach(removeCookie)
    set({
      authenticated: undefined,
      accessToken: undefined,
      refreshToken: undefined,
      browserId: undefined,
      userData: {}
    })
  },
  setAccessToken: (data) => {
    setCookie('accessToken', data)
    set({ accessToken: data })
  },
  setRefreshToken: (data) => {
    setCookie('refreshToken', data)
    set({ refreshToken: data })
  },
  setBrowserId: (data) => {
    setCookie('browserId', data)
    set({ browserId: data })
  },
  setUserData: (data) => {
    setCookie('userData', data)
    set({ userData: data })
  }
}))

import { FormBuilderProps } from '@/common/customComponenttypes'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { adminResetUserPassword } from '../../../pages/system/users/api'
import FormBuilder from '../formBuilder'
import CustomIcons from '../icons/customIcons'
import DialogModal from '../modal/DialogModal'

interface ManageResetProps {
  openAction?: boolean

  rowData?: any // Define a more specific type if possible

  handleRefresh: any

  handleConfirmClose?: () => void
  classes?: any // Use a specific type for Material-UI classes
}
const UserPasswordResetSchema = z.object({
  NewPswd: z.string().optional().nullable(),
  ResetPswd: z.boolean()
})
type UserPasswordResetSchemaType = z.infer<typeof UserPasswordResetSchema>
const validation = [
  /^(?=.*[A-Z])/, // At least one uppercase letter
  /^(?=.*[!@#$%^&*])/, // At least one special character from !@#$%^&*
  /^(?=.*[0-9])/, // At least one digit
  /.{8,}/, // At least 8 characters long
  /^(?=.{0,15}$)/ // No more than 15 characters long
]
export default function ResetPassword({
  openAction = false,
  rowData,
  handleConfirmClose,
  handleRefresh
}: ManageResetProps) {
  const [showPassword, setShowPassword] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [progress, setProgress] = useState(0)
  const { showSnackBar } = useCustomSnackBar()
  // const [valid, isValid] = useState(false)
  const methods = useForm<UserPasswordResetSchemaType>({
    resolver: zodResolver(UserPasswordResetSchema),
    defaultValues: { NewPswd: '', ResetPswd: false }
  })
  const { NewPswd } = methods.watch()
  const setProgressValue = () => {
    let progreVal = 0

    if (NewPswd && NewPswd !== '') {
      validation.forEach((item) => {
        if (item.test(NewPswd)) {
          progreVal = progreVal + 20
        }
      })
    } else {
      progreVal = 0
    }
    setProgress(progreVal)
  }

  const generateProgressClass = () => {
    switch (progress) {
      case 0:
        return 'w-full'
      case 20:
        return 'bg-error-light w-1/5'
      case 40:
        return 'bg-primary w-1/5'
      case 60:
        return 'w-3/5 bg-[#ffe12b]'
      case 80:
        return 'w-4/5 bg-[#ffe12b]'
      case 100:
        return 'w-full bg-[#1F8B24]'
      default:
        return 'w-full'
    }
  }
  const generateHealperTextClass = () => {
    let classname = 'text-xxs font-medium'
    if (progress === 100) {
      classname += ' text-[#1F8B24]'
    } else {
      classname += ' text-error'
    }
    return classname
  }
  useEffect(() => {
    setProgressValue()
  }, [NewPswd])
  const formFields: FormBuilderProps[] = [
    {
      label: 'New Password',
      placeholder: 'New Password',
      name: 'NewPswd',
      id: 'NewPswd',
      isValid: progress === 100,
      showValidityCheck: true,
      adorement: (
        <CustomIcons
          name={showPassword ? 'eye' : 'eye_close'}
          className="cursor-pointer  z-50"
          type="large-xl"
          onClick={() => {
            setShowPassword((s) => !s)
          }}
        />
      ),
      type: showPassword ? 'text' : 'password',
      spacing: 12
    }
  ]
  const controlFields: FormBuilderProps[] = [
    {
      label: 'Reset Password after next login',
      name: 'ResetPswd',
      id: 'ResetPswd',
      type: 'switch',
      spacing: 12
    }
  ]
  const onClose = (flag?: boolean) => {
    methods.reset({ NewPswd: '', ResetPswd: false })
    setShowPassword(false)
    setProgress(0)
    setIsSaving(false)
    handleConfirmClose?.()
    if (flag) {
      handleRefresh()
    }
  }
  const onSubmit = (data: UserPasswordResetSchemaType) => {
    let params = {
      newPassword: data.NewPswd,
      resetAfterLogin: data.ResetPswd
    }
    if (progress === 100) {
      adminResetUserPassword({ ...params, userId: rowData?.userId })
        .then((res: any) => {
          showSnackBar(res)
          onClose(true)
        })
        .catch((err: any) => {
          showSnackBar(err?.response?.data?.error, 'error')
        })
        .finally(() => {
          setIsSaving(false)
        })
    } else {
      showSnackBar('Enter a secure password!!', 'error')
    }
  }
  return (
    <FormProvider {...methods}>
      <DialogModal
        isOpen={openAction}
        type="sm"
        title={'Reset Password'}
        body={
          <>
            <div className="grid grid-cols-12 w-full gap-4">
              <FormBuilder data={formFields} edit={true} />

              <div className={`col-span-12  border-2 border-[#E6E6E6] rounded`}>
                <div className={` h-1  rounded ${generateProgressClass()}`}></div>
              </div>
              <div className="col-span-12">
                <p className={`${generateHealperTextClass()}`}>
                  {progress === 100
                    ? 'Awesome! You have a secure password'
                    : '8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'}
                </p>
              </div>
              <FormBuilder data={controlFields} edit={true} />
            </div>
          </>
        }
        onClose={() => onClose()}
        actionLabel={'Reset'}
        onSubmit={() => methods.handleSubmit(onSubmit)()}
        secondaryAction={() => onClose()}
        secondaryActionLabel={'Cancel'}
        small={false}
        actionLoader={isSaving}
      />
    </FormProvider>
  )
}

const CapacityIcon = () => {
  return (
    <>
      <rect width="24" height="24" fill="white" />
      <g clipPath="url(#clip0_3976_8794)">
        <path
          d="M11.3023 2C17.2105 2 22 6.78952 22 12.6977C22 13.0111 21.9865 13.3213 21.9601 13.6279H20.5587C20.092 18.3287 16.1259 22 11.3023 22C6.16479 22 2 17.8352 2 12.6977C2 7.87409 5.67133 3.908 10.3721 3.44128V2.03988C10.6787 2.01348 10.9889 2 11.3023 2ZM10.3721 5.31339C6.70111 5.77115 3.86047 8.90268 3.86047 12.6977C3.86047 16.8077 7.1923 20.1395 11.3023 20.1395C15.0973 20.1395 18.2288 17.2989 18.6866 13.6279H10.3721V5.31339ZM12.2326 3.90884V11.7674H20.0912C19.6583 7.62974 16.3702 4.34169 12.2326 3.90884Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3976_8794">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </>
  )
}

export default CapacityIcon

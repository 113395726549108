import { FormBuilder, Loader } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { guid } from '@/constants'
import {
  addEquipmentConfig,
  getVesselEquipmentTypes,
  updateEquipmentConfig
} from '@/modules/technical/pages/vesselParticulars/details/api'
import {
  EQConfigDataType,
  EQConfigSchema
} from '@/modules/technical/pages/vesselParticulars/details/equipmentConfig/schema'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import EquipmentApplicableActivities, {
  ManageActivityRef
} from './EquipmentApplicableActivities'
import EquipmentApplicableOils, { ManageOilRef } from './EquipmentApplicableOils'
type VesselTankInfoProps = {
  open: boolean
  onClose: () => void
  rowData: any

  vesselId: string
  refetch: () => void
  view?: boolean
}
type VesselTankItemProps = {
  filter: boolean
  Selected: boolean
  OilTypeId: string | number
  OilTypeName: string
}
export interface ManageConsumptionRef {
  getSelectedData: () => any[]
}

const ManageEQConfig: React.FC<VesselTankInfoProps> = ({
  vesselId,
  open,
  rowData,
  view = false,
  onClose,
  refetch
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [submiting, setSubmitting] = useState<boolean>(false)
  const { showSnackBar } = useCustomSnackBar()
  const [equipmentData, setEquipmentData] = useState<VesselTankItemProps[]>([])
  const activityRef = useRef<ManageActivityRef>(null)
  const oilRef = useRef<ManageOilRef>(null)
  const { t } = useTranslation()

  const methods = useForm<EQConfigDataType>({
    resolver: zodResolver(EQConfigSchema),
    mode: 'onChange'
  })
  const formConfig = [
    {
      label: 'Equipment Type',
      placeholder: 'Type to search',
      name: 'MaterialTypeName',
      type: 'autocomplete',
      required: true,
      data: equipmentData,
      isStaticList: true,
      spacing: 6,
      disabled: view,
      id: 'MaterialTypeId',
      maxLength: 4
    },

    {
      label: 'Eq. count / No. of units',
      placeholder: 'Eq. count / No. of units',
      name: 'EqpCount',
      disabled: view,
      required: true,
      spacing: 4,
      type: 'number'
    },
    {
      label: 'Archived',
      name: 'Archived',
      disabled: view,
      spacing: 2,
      type: 'switch'
    }
  ]

  const handleCloseForm = () => {
    methods.reset({ ConfigId: guid, Archived: false })
    onClose()
  }
  const getEquipmentType = () => {
    setIsLoading(true)
    getVesselEquipmentTypes({ VesselId: vesselId })
      .then((res) => {
        const eqData = res.Results && res.Results.length !== 0 ? res.Results : []

        setEquipmentData(
          eqData.map((item: any) => ({
            id: item.MaterialTypeId,
            name: item.MaterialTypeName
          }))
        )
      })
      .catch((error) => {
        showSnackBar(error.response.data.error || 'Something went wrong', 'error')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    if (vesselId && vesselId !== '') {
      getEquipmentType()
    }
  }, [vesselId, open])
  const onSubmit = (res: any) => {
    setSubmitting(true)
    const oilArray = oilRef?.current?.getSelectedData()
      ? oilRef?.current?.getSelectedData()?.map((item: any) => ({
          OilTypeId: item.OilTypeId,
          OilTypeName: item.OilTypeName
        }))
      : []
    const activityArray = activityRef?.current?.getSelectedData()
      ? activityRef?.current?.getSelectedData()?.map((item: any) => ({
          ConsTypeId: item.ConsTypeId,
          ConsTypeName: item.ConsTypeName
        }))
      : []
    const payload = {
      VesselId: vesselId,
      MaterialTypeName: res.MaterialTypeName,
      EqpCount: res.EqpCount,
      MaterialTypeId: res.MaterialTypeId,
      Archived: Boolean(res.Archived),
      Oils: oilArray,
      ConsTypes: activityArray,
      ConfigId: res.ConfigId
    }
    if (oilArray.length && activityArray.length) {
      manageSubmition(payload)
        .then((resp) => {
          showSnackBar(resp)
          handleCloseForm()
          refetch()
        })

        .catch((error) => {
          showSnackBar(error.response.data.error || 'Something went wrong', 'error')
        })
        .finally(() => {
          setSubmitting(false)
        })
    } else {
      showSnackBar(
        'Select atleast one for applicable oil and applicble activities ',
        'error'
      )

      setSubmitting(false)
    }

    // {"VesselId":"82ffd9ab-5975-4758-b7cb-6ae056f3b220",
    // "ConsTypes": [{ "ConsTypeId": 1, "ConsTypeName": "Consumption", "checked": true, "filter": true }, { "ConsTypeId": 2, "ConsTypeName": "Inerting", "checked": true, "filter": true }],
    //   "Oils": [{ "OilTypeId": 39, "OilTypeCode": "oil23", "OilTypeName": "ffg", "checked": true, "filter": true }, { "OilTypeId": 2, "OilTypeCode": "LFO", "OilTypeName": "Light Fuel Oil", "checked": true, "filter": true }], "EqpCount": "12", "MaterialTypeId": 98, "Archived": false
  }
  const manageSubmition = (payload: any) =>
    payload.ConfigId === guid
      ? addEquipmentConfig(payload).then((resp) => resp)
      : updateEquipmentConfig(payload).then((resp) => resp)
  useEffect(() => {
    if (rowData) {
      methods.reset({ ...rowData })
    } else {
      methods.reset({ ConfigId: guid, Archived: false })
    }
  }, [rowData])

  return (
    <FormProvider {...methods}>
      <DialogModal
        isOpen={open}
        title={
          methods.watch().ConfigId !== guid
            ? 'Update Equipment Configuration'
            : 'Add Equipment Configuration'
        }
        body={
          <div className="h-full relative w-full">
            <div className="grid grid-cols-12 gap-3 w-full">
              <FormBuilder data={formConfig} edit={true} />
            </div>

            <div className=" gap-3 grid grid-cols md:grid-cols-2 w-full pb-3 pt-4">
              <EquipmentApplicableOils
                VesselId={vesselId}
                loading={isFetching}
                ref={oilRef}
                ConfigId={rowData?.ConfigId ?? guid}
                selections={rowData?.Oils ?? []}
                setIsLoading={setIsFetching}
              />
              <EquipmentApplicableActivities
                VesselId={vesselId}
                ref={activityRef}
                loading={isFetching}
                ConfigId={rowData?.ConfigId ?? guid}
                selections={rowData?.ConsTypes ?? []}
                setIsLoading={setIsFetching}
              />
            </div>
            <div className=" overflow-auto flex flex-col gap-2">
              {isLoading ? <Loader isActive={true} /> : null}
            </div>
          </div>
        }
        onClose={handleCloseForm}
        onSubmit={methods.handleSubmit(onSubmit)}
        type={'md'}
        actionLabel={methods.watch().ConfigId !== guid ? t('Update') : t('save')}
        actionLoader={submiting}
        disabled={isLoading || submiting}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={t('close')}
      />
    </FormProvider>
  )
}

export default ManageEQConfig

import { CustomKendoTable, DeletePopUp } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { defaultPageSize, guid, isAllow } from '@/constants'
import {
  paintFormSchema,
  PaintFormSchema
} from '@/modules/technical/pages/vesselParticulars/details/antiFoulings/paintSchema'
import {
  createAntiFoulingPaint,
  deleteAntiFoulingPaint,
  getAntiFoulingPaintInfo,
  updateAntiFoulingPaint,
  useAntiFoulingPaintDetail
} from '@/modules/technical/pages/vesselParticulars/details/api'
import { useAppStore } from '@/store/appStore'
import useHasPermission from '@/utilities/checkActionPermission'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { CreateAntiFoulingPaint } from './AntiFoulingPaintForm'
import { useAppContext } from '@/store/context/appContext'

type AntiFoulingPaintDetailsProps = {
  vesselID: string
  antiFoulingId: string
}
type PageParams = {
  pageNo: number
  antiFoulingId?: string
  pageSize: number
  sortBy: string
}

export const AntiFoulingPaintDetails: React.FC<AntiFoulingPaintDetailsProps> = ({
  antiFoulingId,
  vesselID
}) => {
  const { t } = useTranslation()
  const { setIsLoading } = useAppStore()
  const [total, setTotal] = useState(0)
  const [createOpen, setCreateOpen] = useState(false)
  const [paintId, setPaintId] = useState<string>('')
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [subTitle, setSubTitle] = useState<string>('')
  const [edit, setEdit] = useState(true)
  const [viewable, setViewable] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const hasPermission = useHasPermission('VesselAntiFouling')

  const sortFields = ['MakerName']
  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    antiFoulingId: antiFoulingId,
    pageSize: defaultPageSize,
    sortBy: sortFields[0]
  })
  const { showSnackBar } = useCustomSnackBar()
  const {  setIsActionLoader, isActionLoader } = useAppContext()

  const { data, refetch, isFetching } = useAntiFoulingPaintDetail(params)
  const handleClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    rowData: any
  ) => {
    e.preventDefault()
    setViewable(true)
    setPaintId(rowData?.antiFoulingPaintId)
    const data = await getAntiFoulingPaintInfo(rowData?.antiFoulingPaintId)
    methods.reset(getDeFaultValues(data?.data))
    setCreateOpen(true)
  }
  const columns = [
    {
      title: t('Maker'),
      field: 'makerName',
      render: (row: any) => (
        <Link
          to={'/'}
          onClick={(e) => {
            handleClick(e, row)
          }}
          className="kendo-column-text-link cursor-pointer truncate"
        >
          {row.makerName}
        </Link>
      ),
      sort: true,
      action: true
    },
    {
      title: t('Paint Type'),
      field: 'paintTypeName',
      sort: true
    },
    {
      title: t('Surface'),
      field: 'paintingSurfaceName',
      sort: true
    },
    {
      title: t('Preparations'),
      sort: true,
      field: 'prparationTypeName'
    },
    {
      title: t('Updated By'),
      sort: true,
      field: 'updatedBy'
    },
    {
      title: t('Updated On'),
      sort: true,
      field: 'updatedOn',
      type: 'dateTime'
    }
  ]

  useEffect(() => {
    setTotal(data?.data?.totalRecords)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])

  useEffect(() => {
    setPrams({
      pageNo: 1,
      antiFoulingId: antiFoulingId,
      pageSize: defaultPageSize,
      sortBy: sortFields[0]
    })
  }, [antiFoulingId])

  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }
  const getDeFaultValues = (rowData: any) => {
    if (Object.keys(rowData)?.length > 0) {
      return {
        vesselId: vesselID,
        antiFoulingPaintId: rowData.antiFoulingPaintId,
        paintTypeId: rowData.paintTypeId,
        paintTypeName: rowData.paintTypeName,
        makerName: rowData.makerName,
        makerId: rowData.makerId,
        life: rowData.life,
        paintingSurfaceId: rowData.paintingSurfaceId,
        paintingSurfaceName: rowData.paintingSurfaceName,
        prparationTypeId: rowData.prparationTypeId,
        prparationTypeName: rowData.prparationTypeName,
        tiecoat: rowData.tiecoat,
        dft: rowData.dft,
        paintUpToDraft: rowData.paintUpToDraft,
        remarks: rowData.remarks
      }
    } else {
      return {
        antiFoulingPaintId: guid,
        vesselId: vesselID,
        paintTypeId: '',
        paintTypeName: '',
        makerName: '',
        life: undefined,
        paintingSurfaceId: '',
        paintingSurfaceName: '',
        prparationTypeId: '',
        prparationTypeName: '',
        tiecoat: false,
        dft: undefined,
        paintUpToDraft: undefined,
        remarks: ''
      }
    }
  }

  const handleMenuActions = async (slug: string, rowData: any) => {
    setPaintId(rowData?.antiFoulingPaintId)
    setSubTitle(rowData.makerName)
    const permissionParams = {
      UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
      AccessKey: '',
      ID1: antiFoulingId
    }
    const permission = await hasPermission(permissionParams)

    if (slug === 'delete') {
      setDeleteOpen(permission)
    } else {
      const data = await getAntiFoulingPaintInfo(rowData?.antiFoulingPaintId)
      methods.reset(getDeFaultValues(data?.data))
      setCreateOpen(permission)
      setEdit(true)
      setViewable(false)
    }
  }
  const handleCreate = async () => {
    setIsLoading(true)
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: '',
      ID2: antiFoulingId
    })
    setEdit(false)
    setViewable(false)
    setCreateOpen(permission)
    setIsLoading(false)
    methods.reset(getDeFaultValues({}))
  }
  const methods = useForm<PaintFormSchema>({
    resolver: zodResolver(paintFormSchema),
    defaultValues: { ...getDeFaultValues({}) }
  })
  const handleDelete = () => {
    setIsActionLoader(true)
    deleteAntiFoulingPaint(paintId)
      .then((res) => {
        showSnackBar(res)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err, 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }
  const setActionLabel = () => {
    if (viewable) return ''
    return edit ? t('update') : t('save')
  }

  const onSubmit = (res: PaintFormSchema) => {
    const api = edit ? updateAntiFoulingPaint : createAntiFoulingPaint
    setIsSaving(true)
    api({ ...res, antiFoulingId: antiFoulingId })
      .then((res) => {
        setIsSaving(false)
        showSnackBar(res)
        refetch()
        setCreateOpen(false)
      })
      .catch((err) => {
        setIsSaving(false)
        showSnackBar(err, 'error')
      })
  }
  return (
    <div>
      <CustomKendoTable
        columns={columns}
        data={data?.data?.results}
        pagination={true}
        toolBar={true}
        sortable={true}
        resizable={true}
        isFetching={isFetching}
        title={t('Paint Details')}
        isDownloadable={true}
        adjustWidth={0}
        defaultSort={sortFields[0]}
        //  tableHeight={`calc(100vh - 500px)`}
        handleSort={handleSort}
        actionDropDown={[
          { title: t('edit'), iconName: 'edit', slug: 'edit', id: '1' },
          {
            title: t('delete'),
            iconName: 'delete',
            slug: 'delete',
            id: '2',
            isWarning: true
          }
        ]}
        handleMenuActions={handleMenuActions}
        primaryAction={{ title: t('New'), handlePrimaryAction: handleCreate }}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },
          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
        itemLabels={{ title: 'paint details', subTitle: subTitle }}
        actionLoader={isActionLoader}
      />
      <FormProvider {...methods}>
        <DialogModal
          isOpen={createOpen}
          type="md"
          title={
            edit && !viewable
              ? t('Edit Paint')
              : viewable
                ? t('View Paint Details')
                : t('Add Paint')
          }
          body={<CreateAntiFoulingPaint viewable={viewable} />}
          onClose={() => setCreateOpen(false)}
          actionLabel={setActionLabel()}
          onSubmit={() => methods.handleSubmit(onSubmit)()}
          secondaryAction={() => setCreateOpen(false)}
          secondaryActionLabel={viewable ? t('Close') : t('cancel')}
          small={false}
          actionLoader={isSaving}
        />
      </FormProvider>
    </div>
  )
}

import { Fab } from '@/components/common'

const FabComponent = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 ">
      <div className="py-3">
        <div className="comp-label py-2">Primary</div>

        <div>
          <Fab />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary</div>

        <div>
          <Fab primary={false} />
        </div>
      </div>

      <div className="py-3">
        <div className="comp-label py-2">Primary outlined</div>

        <div>
          <Fab outlined />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary outlined</div>

        <div>
          <Fab primary={false} outlined />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary disabled</div>

        <div>
          <Fab disabled />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary disabled</div>

        <div>
          <Fab primary={false} disabled />
        </div>
      </div>

      <div className="py-3">
        <div className="comp-label py-2">Primary outlined disabled</div>

        <div>
          <Fab outlined disabled />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary outlined disabled</div>

        <div>
          <Fab primary={false} outlined disabled />
        </div>
      </div>
    </div>
  )
}

export default FabComponent

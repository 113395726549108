import { Button } from '@/components/common'
import { useHeaderStore } from '@/store/appStore'
import { useAuthStore } from '@/store/authStore'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const NotFound: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { authenticated } = useAuthStore()

  const handleBack = () => {
    navigate('/')
  }
  const { setHeaderTitle } = useHeaderStore()
  useEffect(() => {
    if (authenticated) {
      setHeaderTitle(t('notfoundHeading'))
    } else {
      setHeaderTitle('')
    }
  }, [])
  return (
    <>
      <div className="w-full h-screen bg-background flex items-center justify-center">
        <div className="flex flex-col md:flex-row p-3 md:p-7 md:w-3/6 items-center justify-center">
          <div className="text-center px-2 md:px-10 text-grey-medium text-common font-medium">
            <div className="w-4/6 md:w-3/6 mx-auto mb-2 h-[135.66px]">
              <img src="/assets/404.png" alt="" className="h-full" />
            </div>
            <h1 className="text-[34px] md:text-7xl text-secondary pb-1">
              {t('404Error')}
            </h1>
            <h5 className="text-xxxl text-black font-medium pb-1 md:break-all">
              {t('notfoundHeading')}
            </h5>
            <p className="mx-auto text-slug-text-errors py-2 md:w-9/12 font-normal">
              {t('notfoundSlogan')}
            </p>
            <div className="flex justify-center p-3">
              <Button label={t('backToHome')} outlined={true} onClick={handleBack} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound

import React from 'react'
import { useOutletContext } from 'react-router-dom'

import VesselManagementForm from '@/modules/technical/components/app/vesselParticulars/management/managementForm'
import { ParticularDetailsContextProps } from '../schema'
import { useVesselParticularsManagement } from './api'

const TechnicalParticularManagement: React.FC = () => {
  const { vesselID } = useOutletContext<ParticularDetailsContextProps>()

  const { data } = useVesselParticularsManagement(vesselID)
  return (
    <div className="flex w-full gap-4">
      <VesselManagementForm data={data} />
    </div>
  )
}

export default TechnicalParticularManagement

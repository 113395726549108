import { TextAreaProps } from '@/common/customComponenttypes'
import React, { useEffect, useRef, useState } from 'react'

import { getInnerWidth } from '.././../../utilities'
import CustomIcons from '../icons/customIcons'

const TextArea: React.FC<TextAreaProps> = ({
  name,
  id,
  label,
  rows = 5,
  maxLength = 500,
  disabled = false,
  fullwidth = true,
  placeholder,
  value,
  register,
  setFocus,
  edited = false,
  fieldEdit = false,
  required = false,
  autoComplete = false,
  autoFocus = false,
  onEditComplete,
  onEditCancel,
  onChange,
  errors
}) => {
  const [editState, setEditState] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)
  const fieldRef = useRef<HTMLTextAreaElement>()
  const adorementRef = useRef<HTMLDivElement>(null)
  const onEditIconClick = () => {
    setEditState(true)
    setIsDisabled(false)
  }
  const onCancelIconClick = () => {
    setEditState(false)
    setIsDisabled(true)
    if (onEditCancel) {
      onEditCancel()
    }
  }
  const onSaveIconClick = () => {
    setEditState(false)
    setIsDisabled(true)
    if (onEditComplete) {
      onEditComplete()
    }
  }
  useEffect(() => {
    if (editState) {
      setFocus(name)
    }
  }, [editState, name, setFocus])
  const getErrors = (err: any) => {
    let errMsg = ''
    if (err.message) {
      errMsg = err?.message
    }
    return errMsg
  }
  const generateClassName = (from: string) => {
    let className = ' '
    switch (from) {
      case 'input':
        className = ` w-full input-area resize-none ${
          fieldEdit ? 'pr-[25px] ' : 'pr-input '
        }`
        if (errors && errors[name]) {
          className += ' textfield-error'
        } else {
          if (edited) {
            className += ' textfield-success '
          } else {
            if (fieldEdit) {
              className += ' textfield-editable'
            } else {
              className += ' textfield'
            }
          }
        }
        break
      case 'adorement':
        className += '  absolute right-[0px] bottom-[10px] adorement mr-[1px]'
        break
      default:
        break
    }

    return className
  }
  return (
    <div className={fullwidth ? 'w-full' : 'w-auto'}>
      {label && (
        <div className="mb-[4px] ">
          <label className={`labels label-text}`}>
            {label}
            {required ? <span className="text-error"> *</span> : <></>}
          </label>
        </div>
      )}
      <div className="relative flex flex-col justify-end">
        <textarea
          id={id}
          ref={fieldRef}
          disabled={isDisabled}
          data-testid="textarea"
          {...register?.(name, { required })}
          placeholder={placeholder || label}
          rows={rows}
          onChange={(e: any) => onChange?.(e)}
          style={{ paddingRight: getInnerWidth(adorementRef, 11) }}
          autoComplete={autoComplete ? 'on' : 'off'}
          autoFocus={autoFocus}
          className={generateClassName('input')}
          maxLength={maxLength}
          value={value}
        />
        <div className={generateClassName('adorement')} ref={adorementRef}>
          {fieldEdit && editState && (
            <>
              <CustomIcons
                name="check_mark"
                className="pr-2 cursor-pointer"
                onClick={() => onSaveIconClick()}
              />
              <CustomIcons
                name="textfield-cancel"
                className="cursor-pointer"
                onClick={() => onCancelIconClick()}
              />
            </>
          )}
          {fieldEdit && !editState && isDisabled && (
            <div className={generateClassName('adorement')}>
              <CustomIcons
                name="edit"
                className="cursor-pointer"
                onClick={() => {
                  onEditIconClick()
                }}
              />
            </div>
          )}
        </div>
      </div>
      {errors && errors[name] && (
        <div className="text-error text-error-label mt-[1px]">
          {getErrors(errors[name])}
        </div>
      )}
    </div>
  )
}

export default TextArea

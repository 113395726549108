import React from 'react'
import TreeItem from './TreeItem'

type TreeNode = {
  label: string
  children?: TreeNode[]
  id: string
}

type TreeProps = {
  data: TreeNode[]
  handleNodeCheck?: (data: any) => void
  treeSelect?: boolean
  nodeIcon?: string
  handleTreeExpand?: (data: any) => void
  treeAction?: string
  handleTreeAction?: (data: any) => void
  nodeSelectedIcon?: string
}

const Tree: React.FC<TreeProps> = ({
  data,
  handleNodeCheck,
  treeSelect,
  nodeIcon,
  handleTreeExpand,
  treeAction,
  handleTreeAction,
  nodeSelectedIcon
}) => {
  const renderTree = (nodes: TreeNode[], level: number = 0): React.ReactNode =>
    nodes.map((node, index) => (
      <TreeItem
        key={index}
        label={node.label}
        node={node}
        level={level}
        handleNodeCheck={handleNodeCheck}
        treeSelect={treeSelect}
        nodeIcon={nodeIcon}
        handleTreeExpand={handleTreeExpand}
        treeAction={treeAction}
        handleTreeAction={handleTreeAction}
        nodeSelectedIcon={nodeSelectedIcon}
      >
        {node.children && renderTree(node.children, level + 1)}
      </TreeItem>
    ))

  return <div>{renderTree(data)}</div>
}

export default Tree

import { FormBuilder } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { guid } from '@/constants'
import { createFindingActions } from '@/modules/technical/pages/vesselInspections/details/findings/details/actions/api'
import {
  FindingActionSchema,
  FindingActionSchemaType
} from '@/modules/technical/pages/vesselInspections/details/findings/details/actions/schema'
import { formContainerClass } from '@/styles/variables'
// import useHasPermission from '@/utilities/checkActionPermission'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

// import { useTranslation } from 'react-i18next'
type VesselTankInfoProps = {
  data: any
  open: boolean
  onClose: () => void
  edit: boolean
  view: boolean
  title: string
  refetch: any
}

const VesselTankManage: React.FC<VesselTankInfoProps> = ({
  data,
  edit,
  view,
  open,
  title,
  onClose,
  refetch
}) => {
  const [isSaving, setIsSaving] = useState(false)
 const { showSnackBar } = useCustomSnackBar()
  const onSubmit = (res: FindingActionSchemaType) => {
    const api = createFindingActions
    const payload = {
      ...res,
      actionOn: res.actionOn ? dayjs(res.actionOn).format('YYYY-MM-DD') : null,
      actionId: guid,
      vesselId: data?.VesselId,
      findingId: data?.FindingId
    }
    setIsSaving(true)
    api(payload)
      .then((res) => {
        setIsSaving(false)
        onSuccessAction()
        showSnackBar(res)
      })
      .catch((err) => {
        setIsSaving(false)
        showSnackBar(err?.response?.data?.error, 'error')
      })
  }

  const onSuccessAction = () => {
    handleCloseForm()
    refetch()
  }

  const formConfig = [
    {
      label: 'Action On',
      placeholder: 'DD-MMM-YY',
      name: 'actionOn',
      type: 'date',
      required: true,
      spacing: 6,
      maxDate: new Date()
    },

    {
      label: 'Action By',
      placeholder: 'Action By',
      name: 'actionBy',
      type: 'text',
      maxLength: 90,
      required: true,
      spacing: 6
    },
    {
      label: 'Action Done',
      placeholder: 'Action Done',
      name: 'actionDone',
      required: true,
      type: 'textarea',
      spacing: 12
    }
  ]

  const initData = () => {
    return { ...data }
  }

  const methods = useForm<FindingActionSchemaType>({
    resolver: zodResolver(FindingActionSchema),
    mode: 'onChange',
    defaultValues: initData()
  })

  useEffect(() => {
    methods.reset({ ...initData() })
  }, [data])

  const handleCloseForm = () => {
    onClose()
  }

  return (
    <>
      <DialogModal
        isOpen={open}
        small={false}
        title={title}
        body={
          <div className="h-full relative w-full">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={formContainerClass}>
                  <FormBuilder data={formConfig} edit={edit} />
                </div>
              </form>
            </FormProvider>
          </div>
        }
        onClose={handleCloseForm}
        actionLabel={edit ? 'Save' : view ? undefined : 'Save'}
        onSubmit={methods.handleSubmit(onSubmit)}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={view ? 'Close' : 'Cancel'}
        actionLoader={isSaving}
      />
    </>
  )
}

export default VesselTankManage

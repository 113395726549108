import CustomIcons from '@/components/common/icons/customIcons'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { options } from './SelectOptions'

type Props = {
  methods: any
  activeStep: number
}
type DetailDataProps = {
  label: string
  value?: any
}

const DetailPanel: React.FC<Props> = ({ methods, activeStep }) => {
  const { watch } = methods
  const { BudgetTypeName, BudgetName, CurrencyName, EntityName, BudgetYear, Option } =
    watch()
  const getOptionLabel = () => {
    const optionObj = options.find((item) => item.id === Option)
    return optionObj ? optionObj.name : undefined
  }
  const details: DetailDataProps[] = [
    ...(activeStep === 5 ? [{ label: 'Budget Name', value: BudgetName }] : []),
    ...(activeStep === 5 ? [{ label: 'Currency', value: CurrencyName }] : []),
    { label: 'Budget Type', value: BudgetTypeName },

    { label: 'Entity Name', value: EntityName },
    { label: 'Budget Year', value: BudgetYear ? dayjs(BudgetYear).format('YYYY') : '' },

    { label: 'Budget Option', value: getOptionLabel() }
  ]
  const [showMore, setShowMore] = useState(false)
  const { width } = useScreenBreakPoint()
  const dataGenerator = () => {
    let data = [...details]

    if (width < 1500 && activeStep === 5) {
      if (!showMore) {
        data.splice(-2)
      }
    }
    return data
  }
  return (
    <div
      className={`${width < 1500 ? 'w-full' : 'w-[320px]'} overflow-auto  flex flex-col  bg-[#fff] rounded-[6px] h-full`}
    >
      <div
        className={` h-full flex px-5 py-5 ${width < 1500 ? 'flex-row flex-wrap ' : 'flex-col '} overflow-auto  `}
      >
        {dataGenerator().map((item: DetailDataProps, i: number) => (
          <React.Fragment key={`${i}${item.value}-data`}>
            <div className={`${width < 1500 ? 'w-1/2 px-4' : ' w-full'}   `}>
              <div
              >
                <div className="text-grey-dark text-xxs font-medium leading-[16.39px] text-nowrap">
                  {item.label}
                </div>
                <div className="text-text-primary font-normal text-common text-no-wrap leading-[16.39px]">
                  {item.value}
                </div>
              </div>
              {dataGenerator().length !== i + 1 && width > 1500 &&(
                <div className={`w-full h-[1px] ${width>1500?"mt-4 mb-4":""} bg-background-borderLight`} />
              )}
            
              {width < 1500 && ((showMore && i < 4) || (!showMore && i < 2)) && (
                <div className={`w-full h-[1px] mt-4 mb-4 bg-background-borderLight`} />
              )}
            </div>
          </React.Fragment>
        ))}
        {activeStep === 5 && width < 1500 && (
          <div
            onClick={() => {
              setShowMore((m) => !m)
            }}
            className="w-full col-span-12 pt-5 pb-[7px] flex items-center justify-center relative"
          >
            <div className="w-full h-[2px] bg-[#EAECF0]"></div>
            <div className="absolute flex leading-[14px] items-center justify-center bg-[#f9fbfc] px-2">
              <CustomIcons
                type="medium"
                isWrapper={false}
                name={showMore ? 'up_arrow_rounded' : 'down_arrow_rounded'}
              />

              <div className="flex items-center justify-center pl-1.5 text-grey-secondary text-common font-normal">
                {showMore ? 'Show Less' : 'Show More'}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DetailPanel

import { CardListProps } from '@/common/customComponenttypes'
import { useCallback, useEffect, useRef, useState } from 'react'

import DynamicActionMenu from '../dynamicMenu'
import CustomIcons from '../icons/customIcons'
import MenuDropDown from '../menudropdown/MenuDropDown'
import DataColumn from './DataColumn'
import DataColumnHead from './DataColumnHead'

export default function NormalCard({
  columns,
  columnsHeader,
  avatar,
  rowData,
  index,
  actionDropDown,
  actionDropDownParams,
  handleMenuActions,
  cardRef,
  staticMenus,
  workflowParams
}: CardListProps) {
  const colRef = useRef<HTMLDivElement>(null)
  const [isClickActive, setIsClickActive] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const handleMenu = (slug: string) => {
    handleMenuActions?.(slug, rowData)
  }
  const handleScroll = useCallback(() => {
    onIconClick(false)
  }, [])
  useEffect(() => {
    const div = cardRef?.current
    div.addEventListener('scroll', handleScroll)
  }, [handleScroll])

  const isLastChild = (index: number, length: number) => {
    if (index === length - 1) {
      return true
    }
    return false
  }
  const onIconClick = (clicked: boolean) => {
    setIsClickActive(clicked)
  }

  let indexInc: number = 0
  return (
    <div
      className="p-[22px] flex flex-col col-span-12 gap-[22px] bg-white rounded  relative  group/item"
      ref={colRef}
    >
      <div className="md:flex flex-row w-full" key={index}>
        {avatar && (
          <div className="h-full flex items-center justify-center pr-3 text-grey-secondary">
            <CustomIcons
              name={rowData[avatar] ?? avatar}
              viewBox={true}
              custDimension={32}
            />
          </div>
        )}
        <div className={`w-full`}>
          <div className="flex gap-x-2.5 py-1">
            {columnsHeader?.map((col, index) => (
              <DataColumnHead col={col} rowData={rowData} key={index} />
            ))}
          </div>
          <div className="flex gap-x-4 divide-x divide-grey-line">
            {columns.map((col, index) => {
              indexInc = !col.hidden ? indexInc + 1 : indexInc
              return (
                <>
                  {!col.hidden && (
                    <DataColumn
                      col={col}
                      rowData={rowData}
                      key={index}
                      lastChild={isLastChild(index, columns.length)}
                      index={indexInc === 1 ? 0 : indexInc}
                    />
                  )}
                </>
              )
            })}
          </div>
        </div>
        {workflowParams?.isDynamicMenu ? (
          <div className="w-[20px] bg-white group-hover:bg-table-hover z-9">
            <div className="flex items-center justify-center w-[20px] bg-white group-hover:bg-table-hover">
              <DynamicActionMenu
                iconName="three_dot"
                staticMenus={staticMenus}
                handleMenuActions={handleMenu}
                rowData={rowData}
                handleMenuItems={() => workflowParams?.handleMenuItems?.(rowData)}
                updateMenuRef={(ref) => (menuRef.current = ref)}
                handleRefresh={(id) => workflowParams?.handleRefresh(id)}
                fromTable={true}
              />
            </div>
          </div>
        ) : (
          <>
            {actionDropDown && !actionDropDownParams?.hideDropDown?.(rowData) && (
              <div className="w-[24px]">
                <div
                  className={`flex items-center justify-center py-1 bg-button-light hover:bg-dropdown-secondary ${!isClickActive ? ' invisible' : ''}  group-hover/item:visible`}
                >
                  <MenuDropDown
                    iconName="three_dot"
                    iconClass="text-dropdown-primary hover:text-secondary"
                    actionDropDown={actionDropDown}
                    onIconClick={onIconClick}
                    isClickActive={isClickActive}
                    handleMenuActions={handleMenu}
                    updateMenuRef={(ref) => (menuRef.current = ref)}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

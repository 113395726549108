import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import { Accordion, AccordionBody, AccordionHeader } from '@/components/common/accordion'
import { getFormErrorMessage } from '@/components/common/formBuilder/utils/helpers'
import CustomIcons from '@/components/common/icons/customIcons'
import useDataStore from '@/store/dynamicStore/useDataStore'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { getErrorMessage } from '@/utilities/dataGenerators'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { updateCrew } from '../../../api'
import useGenerateCommonSpacing, { generateAddress } from '../../../utils'
import { personalAddress, useCrewProfileStore } from '../store'
import { PersonalDetailsSchema, PersonalDetailsSchemaType } from './schema'

type Props = {
  getDetails: () => void
  data?: any

  loading: boolean
  onSubmiting: (loading: boolean) => void
  onSuccessSubmit: () => void
}
export type CommonUpdateRefProps = {
  onSubmit: () => void
  onCancel: () => void
  onEdit: () => void
}
const PersonalDetails = forwardRef<CommonUpdateRefProps, Props>(
  ({ data, loading, onSubmiting, onSuccessSubmit }, ref) => {
    PersonalDetails.displayName = 'Personal Details'
    const { expand, personalEdit, setPersonalEdit, resetEdit } = useCrewProfileStore()

    const generateCommonSpacing = useGenerateCommonSpacing()
    const submitRef = useRef<HTMLButtonElement>(null)
    const { width } = useScreenBreakPoint()
    const cancelRef = useRef<HTMLButtonElement>(null)
    const editRef = useRef<HTMLButtonElement>(null)

    const { getData, dataStore } = useDataStore()
    const { showSnackBar } = useCustomSnackBar()
    useImperativeHandle(
      ref,
      () => ({
        onSubmit: () => {
          submitRef?.current?.click()
        },
        onCancel: () => {
          cancelRef?.current?.click()
        },
        onEdit: () => {
          editRef?.current?.click()
        }
      }),
      [submitRef, cancelRef, editRef]
    )
    const [openAddress, setOpenAddress] = useState(false)

    const { t } = useTranslation()

    const getListDatas = async () => {
      getData('gender', { key: 'GENDER' }, '*')
      getData('maritals', { key: 'MARITALSTS' }, '*')
    }

    const initData = () => {
      return {
        ...data,
        dateOfBirth: data?.dateOfBirth ? new Date(data?.dateOfBirth) : null
      }
    }

    const methods = useForm<PersonalDetailsSchemaType>({
      resolver: zodResolver(PersonalDetailsSchema),
      mode: 'onChange',
      defaultValues: initData()
    })

    useEffect(() => {
      methods.reset({ ...initData() })
    }, [data])

    useEffect(() => {
      getListDatas()
    }, [])

    const {
      control,
      setValue,
      watch,
      formState: { errors }
    } = methods

    const getAddressFields = (prefix: string) => {
      return personalAddress.map((field) => `${prefix}${field}`)
    }

    const { permanentSameAsPresent } = watch()
    const permanentAddress = useWatch<any>({
      control,
      name: getAddressFields('permanent')
    })

    useEffect(() => {
      if (permanentSameAsPresent) {
        getAddressFields('present').forEach((field: string, index: number) => {
          setValue(field as keyof PersonalDetailsSchemaType, permanentAddress[index])
        })
      }
    }, [permanentSameAsPresent, permanentAddress, setValue])

    useEffect(() => {
      if (Object.keys(errors).length > 0) {
        const errMsg = getFormErrorMessage(errors, [
          ...formFields,
          ...primaryAddressFormFields,
          ...secondaryAddressFormFields
        ])
        if (errMsg) {
          showSnackBar(`${t('errorToastMessage')} ${errMsg}`, 'error')
          const errMsgAddress = getFormErrorMessage(errors, [
            ...primaryAddressFormFields,
            ...secondaryAddressFormFields
          ])
          if (errMsgAddress) setOpenAddress(true)
        }
      }
    }, [errors])

    const formFields: FormBuilderProps[] = [
      {
        label: t('First Name'),
        placeholder: t('First Name'),
        name: 'givenName',
        id: 'givenName',
        required: true,
        display_name: data?.givenName,
        type: 'text',
        maxLength: 100,
        spacing: generateCommonSpacing
      },
      {
        label: t('Middle Name'),
        placeholder: t('Middle Name'),
        name: 'middleName',
        display_name: data?.middleName ?? '',
        id: 'middleName',
        type: 'text',
        maxLength: 100,
        spacing: generateCommonSpacing
      },
      {
        label: t('Surname'),
        placeholder: t('Surname'),
        name: 'surname',
        id: 'surname',
        display_name: data?.surname,
        required: true,
        type: 'text',
        maxLength: 100,
        spacing: generateCommonSpacing
      },
      {
        label: t('Nationality'),
        required: true,
        placeholder: t('typeToSearch'),
        name: 'nationalityName',
        display_name: data?.nationalityName,
        noLocalFilter: true,
        id: 'nationalityId',
        type: 'autocomplete',
        apiParams: { key: 'COUNTRY' },
        isStaticList: true,
        spacing: generateCommonSpacing
      },

      {
        label: t('Date of Birth'),
        placeholder: t('DD-MM-YYYY'),
        name: 'dateOfBirth',
        id: 'dateOfBirth',
        type: 'date',
        display_name: data?.dateOfBirth
          ? dayjs(data?.dateOfBirth).format('DD MMM YYYY')
          : undefined,
        required: true,
        spacing: generateCommonSpacing,
        minYearRange: 100,
        maxDate: new Date()
      },
      {
        label: t('Place of Birth'),
        placeholder: t('Place of Birth'),
        name: 'placeOfBirth',
        id: 'placeOfBirth',
        required: true,
        type: 'text',
        maxLength: 100,
        display_name: data?.placeOfBirth,
        spacing: generateCommonSpacing
      },
      {
        label: t('Gender'),
        required: true,
        placeholder: t('Gender'),
        name: 'genderName',
        id: 'genderId',
        display_name: data?.genderName,
        type: 'custom_select',
        data: dataStore?.gender,
        spacing: generateCommonSpacing
      },
      {
        label: t('Marital Status'),
        required: true,
        placeholder: t('Marital Status'),
        name: 'maritalStatusName',
        id: 'maritalStatusId',
        display_name: data?.maritalStatusName,
        type: 'custom_select',
        data: dataStore?.maritals,
        spacing: generateCommonSpacing
      },
      {
        label: t('Contact Number'),
        placeholder: t('Contact Number'),
        name: 'mobile',
        id: 'mobile',
        required: true,
        type: 'text',
        maxLength: 20,
        spacing: generateCommonSpacing,
        display_name: data?.mobile
      },
      {
        label: t('Alternate Contact Number'),
        placeholder: t('Alternate Contact Number'),
        name: 'phone',
        id: 'phone',
        type: 'text',
        maxLength: 20,
        display_name: data?.phone,
        spacing: generateCommonSpacing
      },
      {
        label: t('Email'),
        placeholder: t('Email'),
        name: 'emailId',
        id: 'emailId',
        required: true,
        display_name: data?.emailId,
        type: 'email',
        maxLength: 100,
        spacing: generateCommonSpacing
      },
      {
        label: t('Nearest Airport'),
        required: true,
        placeholder: t('typeToSearch'),
        name: 'nearestAirportName',
        display_name: data?.nearestAirportName,
        noLocalFilter: true,
        id: 'nearestAirportId',
        type: 'autocomplete',
        apiParams: { key: 'AIRPORT' },
        isStaticList: true,
        spacing: generateCommonSpacing
      },
      {
        label: t('Alternate Airport'),
        placeholder: t('typeToSearch'),
        name: 'alternateAirportName',
        noLocalFilter: true,
        id: 'alternateAirportId',
        display_name: data?.alternateAirportName,
        type: 'autocomplete',
        apiParams: { key: 'AIRPORT' },
        isStaticList: true,
        spacing: generateCommonSpacing
      }
    ]

    const primaryAddressFormFields: FormBuilderProps[] = [
      ...(personalEdit
        ? [
            {
              label: t('Address line 1'),
              placeholder: t('Address line 1'),
              name: 'permanentAddressLine1',
              id: 'permanentAddressLine1',
              required: true,
              display_name: data?.permanentAddressLine1,
              type: 'text',
              maxLength: 100,
              spacing: 12
            },
            {
              label: t('Address line 2'),
              placeholder: t('Address line 2'),
              name: 'permanentAddressLine2',
              id: 'permanentAddressLine2',
              required: true,
              display_name: data?.permanentAddressLine2,
              type: 'text',
              maxLength: 100,
              spacing: 12
            }
          ]
        : [
            {
              label: t('Address'),
              placeholder: t('Address'),
              name: 'permanentAddressLine1',
              id: 'permanentAddressLine1',
              display_name: generateAddress(
                data?.permanentAddressLine1,
                data?.permanentAddressLine2
              ),
              type: 'text',
              spacing: 12
            }
          ]),

      {
        label: t('City'),
        placeholder: t('City'),
        name: 'permanentCity',
        id: 'permanentCity',
        required: true,
        display_name: data?.permanentCity,
        type: 'text',
        maxLength: 100,
        spacing: 6
      },
      {
        label: t('State'),
        placeholder: t('State'),
        name: 'permanentState',
        id: 'permanentState',
        required: true,
        display_name: data?.permanentState,
        type: 'text',
        maxLength: 100,
        spacing: 6
      },

      {
        label: t('Country'),
        required: true,
        placeholder: t('typeToSearch'),
        name: 'permanentCountryName',
        display_name: data?.permanentCountryName,
        noLocalFilter: true,
        id: 'permanentCountryId',
        type: 'autocomplete',
        apiParams: { key: 'COUNTRY' },
        isStaticList: true,
        spacing: 6
      },
      {
        label: t('Postcode'),
        required: true,
        placeholder: t('typeToSearch'),
        name: 'permanentPostCode',
        display_name: data?.permanentPostCode,
        id: 'permanentPostCode',
        type: 'text',
        maxLength: 50,
        spacing: 6
      },
      ...(personalEdit
        ? [
            {
              label: t('Alternate address is same as permanent address.'),
              name: 'permanentSameAsPresent',
              id: 'permanentSameAsPresent',
              type: 'checkbox',
              spacing: 12
            }
          ]
        : [])
    ]
    const secondaryAddressFormFields: FormBuilderProps[] = [
      ...(personalEdit
        ? [
            {
              label: t('Address line 1'),
              placeholder: t('Address line 1'),
              name: 'presentAddressLine1',
              id: 'presentAddressLine1',
              display_name: data?.presentAddressLine1,
              type: 'text',
              maxLength: 100,
              disabled: watch()?.permanentSameAsPresent,
              spacing: 12
            },
            {
              label: t('Address line 2'),
              placeholder: t('Address line 2'),
              name: 'presentAddressLine2',
              id: 'presentAddressLine2',
              display_name: data?.presentAddressLine2,
              type: 'text',
              maxLength: 100,
              disabled: watch()?.permanentSameAsPresent,
              spacing: 12
            }
          ]
        : [
            {
              label: t('Address'),
              placeholder: t('Address'),
              name: 'presentAddressLine1',
              id: 'presentAddressLine1',
              display_name: generateAddress(
                data?.presentAddressLine1,
                data?.presentAddressLine2
              ),
              type: 'text',
              spacing: 12
            }
          ]),

      {
        label: t('City'),
        placeholder: t('City'),
        name: 'presentCity',
        id: 'presentCity',
        display_name: data?.presentCity,
        type: 'text',
        maxLength: 100,
        disabled: watch()?.permanentSameAsPresent,
        spacing: 6
      },
      {
        label: t('State'),
        placeholder: t('State'),
        name: 'presentState',
        id: 'presentState',
        display_name: data?.presentState,
        type: 'text',
        maxLength: 100,
        disabled: watch()?.permanentSameAsPresent,
        spacing: 6
      },
      {
        label: t('Country'),
        placeholder: t('typeToSearch'),
        name: 'presentCountryName',
        display_name: data?.presentCountryName,
        noLocalFilter: true,
        id: 'presentCountryId',
        type: 'autocomplete',
        apiParams: { key: 'COUNTRY' },
        isStaticList: true,
        disabled: watch()?.permanentSameAsPresent,
        spacing: 6
      },
      {
        label: t('Postcode'),
        placeholder: t('typeToSearch'),
        name: 'presentPostCode',
        display_name: data?.presentPostCode,
        id: 'presentPostCode',
        type: 'text',
        maxLength: 50,
        disabled: watch()?.permanentSameAsPresent,
        spacing: 6
      }
    ]

    const onSubmit = (res: PersonalDetailsSchemaType) => {
      onSubmiting(true)
      const payload = {
        ...res
      }

      updateCrew(payload)
        .then((res) => {
          showSnackBar(res?.message)
          onSuccessSubmit()
          resetEdit()
        })
        .catch((err) => {
          showSnackBar(getErrorMessage(err), 'error')
        })
        .finally(() => {
          onSubmiting(false)
        })
    }
    const onCancelEdit = () => {
      resetEdit()
    }
    const onEditClick = () => {
      setPersonalEdit(true)
    }
    return (
      <>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="h-full relative w-full p-[5px]">
              <div className="grid grid-cols-12 gap-5 w-full">
                <FormBuilder
                  data={formFields}
                  edit={true}
                  isLoading={loading}
                  isView={!personalEdit}
                  hasCustombreakpoint
                  showErrors={false}
                  autoFocus={true}
                />
              </div>
              <div className="w-full pt-[30px]">
                <Accordion open={openAddress} id="1" className="">
                  <AccordionHeader
                    onClick={() => setOpenAddress((c) => !c)}
                    className="bg-[#edf2f6] w-full  p-3 flex items-center justify-between text-common text-text-primary font-medium leading-[14px]"
                  >
                    <div>Address</div>
                    <div>
                      <CustomIcons
                        name={openAddress ? 'up_arrow' : 'down_arrow'}
                        type={'large-m'}
                      />
                    </div>
                  </AccordionHeader>
                  <AccordionBody>
                    <div className="grid grid-cols-12 gap-5 w-full p-5 bg-[#FAFBFC]">
                      <div
                        className={width > 1000 || !expand ? 'col-span-6' : 'col-span-12'}
                      >
                        <div className="grid grid-cols-12 gap-5 w-full">
                          <div className="col-span-12 text-dark font-medium text-common">
                            Permanent Address
                          </div>
                          <FormBuilder
                            data={primaryAddressFormFields}
                            edit={true}
                            isLoading={loading}
                            hasCustombreakpoint
                            isView={!personalEdit}
                            showErrors={false}
                            autoFocus={false}
                          />
                        </div>
                      </div>
                      <div
                        className={width > 1000 || !expand ? 'col-span-6' : 'col-span-12'}
                      >
                        <div className="grid grid-cols-12 gap-5 w-full">
                          <div className="col-span-12 text-dark font-medium text-common">
                            Alternate Address
                          </div>
                          <FormBuilder
                            data={secondaryAddressFormFields}
                            edit={true}
                            isLoading={loading}
                            hasCustombreakpoint
                            isView={!personalEdit}
                            showErrors={false}
                            autoFocus={false}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>
              </div>
            </div>
            <button
              className="hidden"
              type="button"
              ref={cancelRef}
              onClick={onCancelEdit}
            >
              Cancel
            </button>
            <button className="hidden" type="button" ref={editRef} onClick={onEditClick}>
              Edit
            </button>
            <button className="hidden" type="submit" ref={submitRef}>
              Save
            </button>
          </form>
        </FormProvider>
      </>
    )
  }
)

export default PersonalDetails

import React from 'react'

const ExcelIcon: React.FC = () => {
  return (
    <path
      d="M2.85902 2.87697L15.429 1.08197C15.4999 1.07181 15.5721 1.07697 15.6408 1.09713C15.7095 1.11728 15.773 1.15194 15.8272 1.19878C15.8813 1.24561 15.9247 1.30352 15.9546 1.3686C15.9844 1.43367 15.9999 1.50439 16 1.57597V22.424C15.9999 22.4955 15.9844 22.5661 15.9546 22.6311C15.9249 22.6961 15.8815 22.7539 15.8275 22.8007C15.7735 22.8476 15.71 22.8822 15.6415 22.9025C15.5729 22.9227 15.5008 22.928 15.43 22.918L2.85802 21.123C2.61964 21.089 2.40152 20.9702 2.24371 20.7883C2.08591 20.6065 1.99903 20.3738 1.99902 20.133V3.86697C1.99903 3.62618 2.08591 3.39348 2.24371 3.21161C2.40152 3.02975 2.62064 2.91092 2.85902 2.87697ZM4.00002 4.73497V19.265L14 20.694V3.30597L4.00002 4.73497ZM17 19H20V4.99997H17V2.99997H21C21.2652 2.99997 21.5196 3.10533 21.7071 3.29286C21.8947 3.4804 22 3.73475 22 3.99997V20C22 20.2652 21.8947 20.5195 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H17V19ZM10.2 12L13 16H10.6L9.00002 13.714L7.40002 16H5.00002L7.80002 12L5.00002 7.99997H7.40002L9.00002 10.286L10.6 7.99997H13L10.2 12Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.266667"
    />
  )
}
export default ExcelIcon

import { Button } from '@/components/common'
import React from 'react'
type Props = {
  title?: string
  subtitle?: string
  img?: string

  button?: {
    label?: string
    icon?: string
    primary?: boolean
    onClick?: () => void
  }
}
const NoData: React.FC<Props> = ({ title, subtitle, img, button }) => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="flex flex-col md:flex-row p-3 md:p-7">
        <div className="text-center px-2 md:px-10 text-grey-medium text-common font-medium">
          <img
            src={img || '/assets/empty-dashboard.png'}
            alt=""
            className="w-5/6 md:w-4/6 mx-auto mb-4"
          />

          {title && (
            <h1 className=" text-xl pb-1 font-semibold text-[#292D32] ">{title}</h1>
          )}
          {subtitle && (
            <p className="mx-auto text-common text-text-secondary py-1 font-normal">
              {subtitle}
            </p>
          )}
          <div className="flex justify-center p-3">
            {button && (
              <Button
                label={button?.label}
                primary={button?.primary}
                icon={button?.icon}
                iconSize="large-m"
                onClick={button?.onClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoData

import { Button, CropImage, Portal } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'
import { cropRefType } from '@/components/common/imageTools/imageCropper'
import DialogModal from '@/components/common/modal/DialogModal'
import { useAppContext } from '@/store/context/appContext'
import { blobToFile } from '@/utilities'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { blobToActualFile, getFileExtension } from '@/utilities/dataGenerators'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import React, { useEffect, useRef, useState } from 'react'
type UsersFormProps = {
  edit: boolean
  view: boolean
  methods: any
  data?: any
  title: string
  isOpen: boolean
  loading: boolean
  onClose: () => void
}
export const UserFormLeftSection: React.FC<UsersFormProps> = ({
  view,
  methods,
  data,
  onClose,
  edit,
  isOpen,
  title,
  loading
}) => {
  const { showSnackBar } = useCustomSnackBar()
  const { width } = useScreenBreakPoint()
  const [preview, setPreview] = useState<any>()
  const [fileName, setFileName] = useState<any>()
  const [selectedImageURL, setSelectedImageURL] = useState<any>()

  const [cropperOpen, setCropperOpen] = useState<boolean>(false)
  useEffect(() => {
    if (!isOpen) {
      setPreview(null)
    }
  }, [isOpen])

  useEffect(() => {
    if (data?.fileData && data?.fileName) {
      setPreview(blobToFile(data?.fileData, data?.fileName))
    } else {
      setPreview(null)
    }
  }, [data?.fileData, data?.fileName])
  const imageRef = useRef<HTMLInputElement>(null)
  const cropperRef = useRef<cropRefType>(null)

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files?.length) {
      const file = files[0]

      if (file) {
        const acceptedFileTypes = ['png', 'jpg', 'jpeg']
        const fileType = getFileExtension(file.name)

        if (acceptedFileTypes.some((type) => type === fileType)) {
          setFileName(file.name)
          let img = new Image()
          img.src = window.URL.createObjectURL(file)

          img.onload = () => {
            if (file?.size > 307200) {
              showSnackBar('Maximum file size is 300 kB', 'error')
            } else {
              setSelectedImageURL(URL.createObjectURL(file))
              setCropperOpen(true)
              // methods.reset({ ...methods.watch(), file: file })
              // setPreview(URL.createObjectURL(file))
            }
          }
        } else {
          showSnackBar('Select a valid JPEG / JPG / PNG file', 'error')
        }
        if (imageRef?.current?.value) {
          imageRef.current.value = ''
        }
      }
    }
  }
  const { setIsCommonLoader } = useAppContext()
  useEffect(() => {
    setIsCommonLoader(loading)
  }, [loading])
  const onCrop = () => {
    cropperRef.current?.onCrop().then((res: any) => {
      const file = blobToActualFile(res, fileName)
      if (file) {
        setPreview(URL.createObjectURL(file))

        methods.reset({ ...methods.watch(), file: file })
        onCropperClose()
      }
    })
  }
  const onCropperClose = () => {
    setCropperOpen(false)
    setFileName(undefined)
    setSelectedImageURL(undefined)
  }
  return (
    <div className="p-10 bg-[#D9E2EA] rounded-l-dialog h-full flex flex-col justify-between gap-3.5 relative">
      {width <= 1000 && (
        <div className={`flex justify-between items-center pb-[26px] `}>
          <div className="">
            <h3 className="  font-semibold text-xl leading-5 tracking-[0.4px] text-secondary">
              {title}
            </h3>
          </div>

          <button
            type="button"
            className="close-button text-gray-400 hover:bg-gray-200 rounded-full  dark:hover:bg-gray-600 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
            data-modal-hide="popup-modal"
            onClick={() => onClose()}
            data-testid="close-icon"
          >
            <CustomIcons
              name="close"
              className="flex items-center justify-center   "
              data-testid="button-icon-left"
            />
          </button>
        </div>
      )}

      <div className="flex flex-col gap-2 ">
        <div className="flex items-center justify-center">
          <div
            className="  w-[111px] h-[111px] bg-white  rounded-full top-2 flex items-center justify-center"
            style={{
              fill: '#FFF',
              filter: 'drop-shadow(0px 0px 20px rgba(0, 60, 113, 0.15))'
            }}
          >
            <div className="relative">
              <img
                src={preview || '/assets/avatar_empty.jpeg'}
                className="w-[93x]  h-[93px] rounded-full cover object-cover"
                alt="avatar"
              />
              {edit && (
                <div
                  onClick={() => imageRef.current?.click()}
                  className="absolute right  bottom-1 cursor-pointer right-1 rounded-full w-[26px] h-[26px] bg-white border border-[#DDDEE0] flex items-center justify-center "
                >
                  {edit && (
                    <CustomIcons
                      name={'camera'}
                      type={'large'}
                      className="flex items-center justify-center text-[#667085]"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {!view && !edit && (
          <>
            <div className="flex items-center justify-center">
              <Button
                label="Upload Photo"
                iconSize="medium"
                iconDimension={24}
                size="sm"
                primary={false}
                icon="camera"
                hasCustomIconSize
                iconAlignment="left"
                onClick={() => imageRef.current?.click()}
              />
            </div>

            <div
              className={`flex flex-col text-center  font-normal leading-6 ${width <= 1000 ? 'w-auto' : 'w-[150px]'} text-xxs`}
            >
              {!edit && !view && (
                <ul className="text-grey-strong">
                  <li>
                    Only <strong>JPEG, JPG </strong>or <strong>PNG</strong>
                  </li>
                  <li>
                    Size upto <strong>300KB</strong>
                  </li>
                </ul>
              )}
            </div>
          </>
        )}
        {(edit || view) && !loading && (
          <div
            className={`flex flex-col text-center  font-normal leading-6 items-center ${width <= 1000 ? 'w-auto' : 'w-[150px]'} text-xxs`}
          >
            <div className="text-grey-strong text-m text-wrap font-bold pb-1">
              {`${data?.title} ${data?.firstName}`}
            </div>
            <div className="flex flex-col items-center justify-center gap-1 ">
              <div className="flex items-center text-text-primary font-semibold">
                <div> {`${data?.designationName}`}</div>
              </div>
              <div className="flex items-center text-text-primary font-semibold">
                <div> {`${data?.departmentName}`}</div>
              </div>

              {/* <div className="flex items-center text-primary font-bold">
                <div> {`${data?.roleName}`}</div>
              </div> */}
            </div>
          </div>
        )}
        <input
          type="file"
          ref={imageRef}
          accept=".png,,.jpeg,.jpg"
          style={{ display: 'none' }}
          onChange={handleChangeFile}
        />
        {cropperOpen && (
          <Portal wrapperId="child-popup">
            <div className="bg-black">
              <DialogModal
                isOpen={cropperOpen}
                type="sm"
                noPortal
                body={
                  <CropImage
                    imgURL={selectedImageURL}
                    fileName={fileName}
                    ref={cropperRef}
                  />
                }
                title={'Crop Image'}
                onClose={() => onCropperClose()}
                actionLabel={'Crop'}
                secondaryAction={() => onCropperClose()}
                secondaryActionLabel={'cancel'}
                onSubmit={() => onCrop()}
                small={false}
                actionLoader={false}
              />
            </div>
          </Portal>
        )}
      </div>
      {!view && !edit && (
        <div
          className={`${width <= 1000 ? 'w-auto' : 'w-[150px]'} text-center text-xxxs text-wrap text-[#667085] `}
        >
          The photo should be in focus and well lit, and have no significant alterations
          or excessive use of filters. In other words, the image should represent reality
        </div>
      )}
    </div>
  )
}

import { useSnackbar } from 'notistack'

import { getErrorMessage } from './dataGenerators'

const useCustomSnackBar = () => {
  const { enqueueSnackbar } = useSnackbar()

  const htmlTagRemove = (value: string | string[]) => {
    if (typeof value === 'string') {
      const filterHtmlTag = value.replace(/<[^>]*>?/gm, '')
      return filterHtmlTag.replace(/&[^;]+;?/gm, '')
    }
    return value
  }

  const showSnackBar = (
    message: any,
    type: 'success' | 'error' | 'info' | 'warning' = 'success'
  ) => {
    if (message !== 'UNAUTHORIZED') {
      const errorMessage = getErrorMessage(message)
      const filteredMessage = htmlTagRemove(errorMessage)
      enqueueSnackbar(filteredMessage, { variant: type, autoHideDuration: 5000 })
    }
  }

  return { showSnackBar }
}

export default useCustomSnackBar

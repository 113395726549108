import React from 'react'

const NoRecordsFound: React.FC = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center h-[20dvh] p-10 pt-32">
      <div>
        <img src="/assets/no_records_found.svg" alt="No records found" />
      </div>
      <div className="text-text-more text-common leading-5 font-normal">
        No Records Found
      </div>
    </div>
  )
}

export default NoRecordsFound

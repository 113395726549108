import React, { CSSProperties, useEffect, useRef, useState } from 'react'

type NoDataTypes = {
  style?: CSSProperties | undefined
  viewPortWidth?: string
  gridRef: any
  searchCount?: number
}
const NoRecordsFound: React.FC<NoDataTypes> = ({
  style,
  viewPortWidth,
  gridRef,
  searchCount
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const handleScroll = (event: any) => {
      updateScrollPosition(event)
    }

    const updateScrollPosition = (event: any) => {
      if (containerRef.current && contentRef.current) {
        const newScrollPosition = event.target.scrollLeft
        setScrollPosition(newScrollPosition)
      }
    }

    const gridContent = gridRef.current?.containerRef?.current
    if (gridContent) {
      gridContent.addEventListener('scroll', handleScroll)
    }

    // Remove the event listener when the component is unmounted
    return () => {
      if (gridContent) {
        gridContent.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const contentStyle: CSSProperties = {
    position: 'absolute',
    left: `${scrollPosition}px`,
    display: 'inline-block',
    // top: '40%',
    width: viewPortWidth
  }

  return (
    <div
      style={style}
      className=" flex items-start justify-start relative"
      ref={containerRef}
    >
      <div style={contentStyle} ref={contentRef}>
        <div
          className={` flex flex-col items-center justify-center h-[20dvh] p-10 pt-36`}
        >
          <div className="">
            <img src="/assets/no_records_found.svg" alt="No records found" />
          </div>
          <div className="text-text-more text-common leading-5 font-normal">
            {searchCount === undefined || searchCount <= 1
              ? 'Tap Search button to view the records'
              : 'No Records Found'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoRecordsFound

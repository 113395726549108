import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const PlusTabIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <path
        d="M3.42857 3.42857V0H4.57143V3.42857H8V4.57143H4.57143V8H3.42857V4.57143H0V3.42857H3.42857Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PlusTabIcon

import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { FieldErrors } from 'react-hook-form'

import { getInnerWidth } from '.././../../utilities'
import CustomIcons from '../icons/customIcons'
import ModernCustomSelectWithArrow from './ModernCustomSelectWithArrow'

type Props = {
  label?: string
  onChange: (data: any) => void
  value?: any
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  autoFocus?: boolean
  fullWidth?: boolean
  required?: boolean
  errors?: FieldErrors
  placeholder?: string
  selectRange?: boolean
  name: string
  showYearPicker?: boolean
  minYearRange?: number
  maxYearRange?: number
  readOnly?: boolean
}

const DatePickerConponent = (props: Props) => {
  const {
    label,
    errors,
    onChange,
    value,
    maxDate,
    autoFocus,
    minDate,
    disabled = false,
    readOnly = false,
    required,
    fullWidth = false,
    placeholder,
    selectRange,
    name,
    showYearPicker,
    minYearRange,
    maxYearRange
  } = props
  const [open, setOpen] = useState(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)
  const inputRef = useRef<HTMLInputElement>(null)
  const calanderRef = useRef<any>(null)
  const [isFocused, setIsFocused] = useState(false)
  const adorementRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const handleClear = () => {
    const value = selectRange ? [null, null] : null
    onChange({ value, name })
    onLabelClick()
  }

  const range = (start: number, end: number): number[] => {
    return Array.from({ length: end - start + 1 }, (_, index) => start + index)
  }
  const getYear = (date: Date): number => {
    return date.getFullYear()
  }
  const getMonth = (date: Date) => {
    return date.getMonth()
  }
  const years = range(
    getYear(new Date()) - (minYearRange ?? 25),
    getYear(new Date()) + (maxYearRange ?? 5)
  )
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])
  const handleDatePickerChange = (date: any) => {
    if (date) {
      setOpen(false)

      onChange({ value: date, name: name })
    } else {
      onChange({ value: null, name: name })
    }
  }
  const getErrors = (err: any) => {
    let errMsg = ''
    if (err.message) {
      errMsg = err?.message
    }
    return errMsg
  }
  const generateClassName = (
    type: 'input' | 'label' | 'message' | 'adorement'
  ): string => {
    let className = ' '
    switch (type) {
      case 'input':
        className +=
          'block text-common text-input-text font-normal px-4 w-full text-sm text-gray-900 bg-transparent  border  appearance-none    peer h-10 rounded-[4px] disabled:text-input-disabled disabled:bg-disabled placeholder-transparent focus:placeholder-grey-secondary'
        if (errors && errors[name]) {
          className +=
            ' border-[#FDA29B] focus:border-error-[#FDA29B] focus:ring-[#FDA29B] focus:ring-3 focus:outline-[#FDA29B] input-outline'
        } else {
          className +=
            ' text-grey-dark border-input-light focus:border-blue-navy  focus:outline-none  focus:ring-0'
        }
        break
      case 'label':
        className += `   ${
          isFocused || value
            ? 'modern-input-peer-focus-label-size  px-1 placeholder-shown:-translate-y-1/2 placeholder-shown:top-1/2 top-2 -translate-y-4'
            : '  top-6 '
        } 
         modern-input-label
        modern-input-label-size peer-focus:modern-input-peer-focus-label-size 
         bg-white   absolute  duration-300 transform -translate-y-4  z-1 origin-[0]   start-[14px] ${
           isDisabled ? 'cursor-pointer' : 'cursor-text peer-focus:cursor-pointer'
         } `
        if (errors && errors[name]) {
          className += ' text-error-light '
        } else {
          className += ` ${
            isFocused ? ' modern-input-label-focused' : ' modern-input-label'
          } `
        }
        break
      case 'message':
        className += ' text-error-light text-[11px]'
        break
      case 'adorement':
        className += '  absolute right-0 adorement mr-[1px]'
        break
      default:
        break
    }
    return className
  }
  const onLabelClick = () => {
    if (!isDisabled) {
      enableFocus()
    }
  }
  const onClickDisabled = () => {
    if (!isDisabled) {
      enableFocus()
    }
  }
  const getPosition = () => {
    return 'bottom'
  }
  useEffect(() => {
    const handleClickOutside = (event: React.MouseEvent) => {
      if (
        containerRef.current &&
        event.target instanceof Node &&
        !containerRef.current.contains(event.target)
      ) {
        disableFocus()
      }
    }
    document.addEventListener('mousedown', handleClickOutside as any)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside as any)
    }
  }, [])
  const enableFocus = () => {
    setIsFocused(true)
    setOpen(true)
    calanderRef?.current?.input?.focus()
    inputRef?.current?.click()
  }
  const disableFocus = () => {
    setOpen(false)
    setIsFocused(false)
  }
  useEffect(() => {
    if (autoFocus) {
      onLabelClick()
    }
  }, [autoFocus])

  const handleError = (data: any) => {
    if (getErrors(data[name]) === 'required' || getErrors(data[name]) === 'Required') {
      return `${label} is ${getErrors(data[name])}` ?? ''
    } else {
      return getErrors(data[name]) ?? ''
    }
  }
  return (
    <div
      className={`${getPosition()} ${fullWidth ? 'w-full' : 'w-auto'}`}
      ref={containerRef}
    >
      <div className="tooltip-container ">
        {isHovered && errors && errors[name] && (
          <span className="tooltip"> {handleError(errors)} </span>
        )}
        <div className={`flex relative ${fullWidth ? 'w-full' : 'w-auto'}`}>
          <div className="relative w-full">
            <DatePicker
              selected={value ? new Date(value) : null}
              todayButton={showYearPicker ? '' : 'Today'}
              disabled={disabled}
              maxDate={maxDate}
              minDate={minDate}
              ref={calanderRef}
              showYearPicker={showYearPicker}
              onInputClick={() => setOpen((o) => !o)}
              onBlur={() => {
                setOpen(false)
                setIsFocused(false)
              }}
              open={open}
              readOnly={readOnly}
              onChange={(date) => handleDatePickerChange(date)}
              onFocus={(e) => {
                // e.target.readOnly = true
                setIsFocused(true)
              }}
              placeholderText={placeholder}
              selectsRange={selectRange}
              dateFormat={showYearPicker ? 'yyyy' : 'dd-MMM-yyyy'}
              className={` ${generateClassName('input')}`}
              popperModifiers={[
                {
                  name: 'preventOverflow',
                  options: {
                    rootBoundary: 'viewport',
                    tether: true,
                    altAxis: false
                  }
                }
              ]}
              customInput={
                <input
                  value={value}
                  ref={inputRef}
                  style={{ paddingRight: getInnerWidth(adorementRef, 11) }}
                  className={`w-full ${generateClassName('input')}`}
                  placeholder={placeholder}
                />
              }
              renderCustomHeader={
                showYearPicker
                  ? undefined
                  : ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth }) => (
                      <div className="mx-1.5 my-0.5 flex justify-between bg-background-modern-datepicker-header">
                        <button
                          type="button"
                          onClick={decreaseMonth}
                          className="text-text-modern-datepicker-navigation-button "
                        >
                          <CustomIcons name="previous-arrow" />
                        </button>

                        <ModernCustomSelectWithArrow
                          className="ModernDateMonthSelect"
                          optionContainerClassName="bg-background-modern-datepicker-dropdown-container"
                          optionClassName="odd:bg-background-modern-datepicker-dropdown-container-list-odd
                      odd:text-text-modern-datepicker-dropdown-container-list-odd
                      even:bg-background-modern-datepicker-dropdown-container-list-even
                      even:text-text-modern-datepicker-dropdown-container-list-even
                      text-[14px] border-[solid!important]
                      w-[115px!important] h-[26px!important] focus:outline-[none!important]
                      pt-1.5 ps-2 pb-1.5"
                          items={months}
                          value={months[getMonth(date)]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        />
                        <ModernCustomSelectWithArrow
                          className="ModernDateYearSelect"
                          optionContainerClassName="bg-background-modern-datepicker-dropdown-container"
                          optionClassName="
                      odd:bg-background-modern-datepicker-dropdown-container-list-odd
                      odd:text-text-modern-datepicker-dropdown-container-list-odd
                      even:bg-background-modern-datepicker-dropdown-container-list-even
                      even:text-text-modern-datepicker-dropdown-container-list-even
                      text-[14px] border-[solid!important]
                      w-[83px!important] h-[26px!important]
                      pt-1.5 ps-2 pb-1.5"
                          items={years}
                          value={getYear(date)}
                          onChange={({ target: { value } }) =>
                            changeYear(parseInt(value))
                          }
                        />

                        <button
                          type="button"
                          onClick={increaseMonth}
                          className="text-text-modern-datepicker-navigation-button"
                        >
                          <CustomIcons name="next-arrow" />
                        </button>
                      </div>
                    )
              }
            />
            <label
              htmlFor="id"
              onClick={(e) => {
                e.stopPropagation()
                onLabelClick()
              }}
              className={generateClassName('label')}
            >
              {label}
              {required ? <span className="text-error"> *</span> : <></>}
            </label>
          </div>
          <div className="flex items-center justify-center ">
            <div className={generateClassName('adorement')} ref={adorementRef}>
              {value && !disabled && (
                <button
                  onClick={() => handleClear()}
                  className=" text-table-bodyColor px-1.5"
                  aria-label="close"
                >
                  <CustomIcons name="close" type="large-m" />
                </button>
              )}
              <CustomIcons
                onClick={() => {
                  onClickDisabled()
                }}
                name="CalendarIcon"
                type="large-m"
                className={errors && errors[name] ? ' text-error' : 'text-[#667085]'}
              />
              {errors && errors[name] && (
                <div
                  className={`pl-1 text-error-label relative cursor-pointer ${generateClassName(
                    'message'
                  )}`}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CustomIcons name="alert" type="medium" />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {errors && errors[name] && (
        <div className="text-error text-error-label mt-[1px]">
          {getErrors(errors[name])}
        </div>
      )}{' '} */}
      </div>
    </div>
  )
}

export default DatePickerConponent

import { Button } from '@/components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Maintenance: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/')
  }
  return (
    <div className="w-full h-screen bg-background flex items-center justify-center">
      <div className="flex flex-col md:flex-row p-3 md:p-7 md:w-4/6">
        <div className="text-center px-2 md:px-10 text-grey-medium text-common font-medium">
          <img src="/assets/500.png" alt="" className="w-5/6 md:w-3/6 mx-auto mb-6" />
          <h1 className="text-[34px] md:text-7xl text-secondary pb-1 leading-10 my-3 md:my-0 md:leading-normal">
            {t('maintenanceHeading')}
          </h1>
          <h5 className="text-xxxl text-black font-medium pb-1 md:break-all">
            {t('maintenanceSubHeading')}
          </h5>
          <p className="mx-auto text-slug-text-errors py-2 font-normal md:w-5/6">
            {t('maintenanceSlogan')}
          </p>
          <div className="flex justify-center p-3">
            <Button label={t('backToHome')} outlined={true} onClick={handleBack} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Maintenance

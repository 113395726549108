import {
  postCrewData,
  postMasterData,
  postMasterDataMips,
  postOperationsData,
  postPmsData,
  postSystemData,
  postSystemMipsData,
  postTechnicalData,
  postTechnicalMipsData
} from '@/apis/api.helpers'
import { useAppContext } from '@/store/context/appContext'

import { usePermissionStore } from '@/store/permission'
import useCustomSnackBar from './customSnackBar'

type ErrorWithResponse = {
  response?: {
    data?: {
      error?: string
    }
  }
}
function useHasPermission(url: string, module?: string) {
  const { setIsOpenDialoug, setContent } = usePermissionStore()
  const { setIsCommonLoader } = useAppContext()
  const handleError = (error: ErrorWithResponse) => {
    setContent({ subTitle: error?.response?.data?.error, title: 'Not Allowed' })
    setIsOpenDialoug(true)
  }
  const { showSnackBar } = useCustomSnackBar()

  const handleModule = () => {
    switch (module) {
      case 'technical':
        return postTechnicalData
      case 'technical_v2':
        return postTechnicalMipsData
      case 'pms':
      case 'procurement':
        return postPmsData
      case 'master':
        return postMasterData
      case 'master_v2':
        return postMasterDataMips
      case 'system':
        return postSystemData
      case 'system_v2':
        return postSystemMipsData
      case 'crewing':
        return postCrewData
      case 'operations':
        return postOperationsData
      default:
        return postTechnicalData
    }
  }
  const hasPermission = async (action: any): Promise<boolean> => {
    try {
      setIsCommonLoader(true)
      const api = module?.includes('v2')
        ? handleModule()(`/${url}/isAllowed`, action)
        : handleModule()(`v1/${url}/isAllowed`, action)
      const response = await api
      if (module?.includes('v2')) {
        if (!response) {
          showSnackBar('You do not have enough permission to do this action', 'error')
        }
        return response
      } else {
        return !response
      }
    } catch (error: any) {
      handleError(error)
      return false
    } finally {
      setIsCommonLoader(false)
    }
  }

  return hasPermission
}
export default useHasPermission

import { CustomKendoTable, DeletePopUp } from '@/components/common'
//import ConfirmModal from '@/components/common/modal/ConfirmModal'
import DialogModal from '@/components/common/modal/DialogModal'
import { defaultPageSize, guid, isAllow } from '@/constants'
import {
  createSeaTrialItemDetailInfo,
  deleteASeaTrialItemDetailInfo,
  getSeaTrialItemDetailInfo,
  updatSeaTrialItemDetailInfo,
  useSeaTrialItemDetail
} from '@/modules/technical/pages/vesselParticulars/details/api'
import {
  seaTrialItemFormSchema,
  SeaTrialItemFormSchema
} from '@/modules/technical/pages/vesselParticulars/details/seaTrails/itemSchema'
import { useAppStore } from '@/store/appStore'
import useHasPermission from '@/utilities/checkActionPermission'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAppContext } from '@/store/context/appContext'
import { CreateSeaTrialDetailsForm } from './SeaTrialDetailsForm'

type SeaTrialDetailsProps = {
  vesselID: string
  vesSeaTrialId: string
}
type PageParams = {
  VesFiltType: number
  pageNo: number
  VesSeaTrialId: string
  VesselId: string
  pageSize: number
  sortBy: string
  archived: boolean
}

export const SeaTrialDetails: React.FC<SeaTrialDetailsProps> = ({
  vesSeaTrialId,
  vesselID
}) => {
  const { t } = useTranslation()
  const { setIsLoading } = useAppStore()
  const [total, setTotal] = useState(0)
  const [viewable, setViewable] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [edit, setEdit] = useState(true)
  const { showSnackBar } = useCustomSnackBar()
  const hasPermission = useHasPermission('VesselSeaTrial')
  const [isSaving, setIsSaving] = useState(false)
  const [trialItemId, setTrialItemId] = useState<string>('')
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [subTitle, setSubTitle] = useState<string>('')
  const sortFields = ['LoadConditionName']
  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    VesFiltType: 1,
    VesSeaTrialId: vesSeaTrialId,
    VesselId: vesselID,
    pageSize: defaultPageSize,
    sortBy: sortFields[0],
    archived: false
  })

  const { data, refetch, isFetching } = useSeaTrialItemDetail(params)
  const { setIsActionLoader, isActionLoader } = useAppContext()

  const getDeFaultValues = (rowData: any) => {
    if (Object.keys(rowData)?.length > 0) {
      return {
        ...rowData,
        vesSeaTrialId: vesSeaTrialId,
        vesselId: vesselID,
        fuelConsHourly:
          (rowData?.fuelConsHourly || rowData?.fuelConsHourly === 0) &&
          rowData.fuelConsHourly.toString(),
        fuelConsDaily:
          (rowData?.fuelConsDaily || rowData?.fuelConsDaily === 0) &&
          rowData.fuelConsDaily.toString(),
        draftFWD:
          (rowData?.draftFWD || rowData?.draftFWD === 0) && rowData.draftFWD.toString(),
        draftAFT:
          (rowData?.draftAFT || rowData?.draftAFT === 0) && rowData.draftAFT.toString(),
        deadWeight:
          (rowData?.deadWeight || rowData?.deadWeight === 0) &&
          rowData.deadWeight.toString()
      }
    } else {
      return {
        vesTrialItemId: guid,
        vesSeaTrialId: vesSeaTrialId,
        vesselId: vesselID,
        loadConditionId: '',
        loadConditionName: '',
        tier: '',
        tierName: '',
        paramsower: undefined,
        rpm: undefined,
        speed: undefined,
        sfoc: undefined,
        fuelConsHourly: undefined,
        fuelConsDaily: undefined,
        draftFWD: undefined,
        draftAFT: undefined,
        deadWeight: undefined,
        remarks: ''
      }
    }
  }

  const methods = useForm<SeaTrialItemFormSchema>({
    resolver: zodResolver(seaTrialItemFormSchema),
    defaultValues: { ...getDeFaultValues({}) }
  })

  const handleClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    rowData: any
  ) => {
    e.preventDefault()
    setViewable(true)
    const { data } = await getSeaTrialItemDetailInfo(rowData?.vesTrialItemId)
    methods.reset(getDeFaultValues(data))
    setCreateOpen(true)
  }

  const columns = [
    {
      title: t('Load'),
      field: 'loadConditionName',
      width: 200,
      render: (row: any) => (
        <Link
          to={'/'}
          onClick={(e) => {
            handleClick(e, row)
          }}
          className="kendo-column-text-link cursor-pointer truncate"
        >
          {row.loadConditionName}
        </Link>
      ),
      sort: true,
      action: true
    },
    {
      title: t('Tier'),
      field: 'tierName',
      sort: true
    },
    {
      title: t('Power'),
      field: 'power',
      align: 'center',
      sort: true
    },
    {
      title: t('RPM'),
      sort: true,
      align: 'center',
      field: 'rpm'
    },
    {
      title: t('Speed'),
      sort: true,
      align: 'center',
      field: 'speed'
    },
    {
      title: t('SFOC'),
      sort: true,
      align: 'center',
      field: 'sfoc'
    }
  ]

  useEffect(() => {
    setTotal(data?.totalRecords)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])

  useEffect(() => {
    setPrams({
      pageNo: 1,
      VesFiltType: 1,
      VesSeaTrialId: vesSeaTrialId,
      VesselId: vesselID,
      pageSize: defaultPageSize,
      sortBy: sortFields[0],
      archived: false
    })
  }, [vesSeaTrialId])

  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }

  const handleMenuActions = async (slug: string, rowData: any) => {
    setTrialItemId(rowData?.vesTrialItemId)
    setSubTitle(rowData.loadConditionName)
    const permissionParams = {
      UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
      AccessKey: '',
      ID1: vesSeaTrialId
    }
    const permission = await hasPermission(permissionParams)

    if (slug === 'delete') {
      setDeleteOpen(permission)
    } else {
      const { data } = await getSeaTrialItemDetailInfo(rowData?.vesTrialItemId)
      methods.reset(getDeFaultValues(data))
      setCreateOpen(permission)
      setEdit(true)
      setViewable(false)
    }
  }
  const handleCreate = async () => {
    setIsLoading(true)
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: '',
      ID2: vesSeaTrialId
    })
    setEdit(false)
    setViewable(false)
    setCreateOpen(permission)
    setIsLoading(false)
    methods.reset(getDeFaultValues({}))
  }
  const handleDelete = () => {
    setIsActionLoader(true)
    deleteASeaTrialItemDetailInfo(trialItemId)
      .then((res) => {
        showSnackBar(res)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err, 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }

  const handleToolActions = (slug: string) => {
    if (slug === 'archived') {
      setPrams({ ...params, archived: !params?.archived, pageNo: 1 })
    }
  }

  const getArchiveText = () => (params?.archived ? 'Hide Archived' : 'Show Archived')

  const setActionLabel = () => {
    if (viewable) return ''
    return edit ? t('update') : t('save')
  }

  const onSubmit = (data: SeaTrialItemFormSchema) => {
    const api = edit ? updatSeaTrialItemDetailInfo : createSeaTrialItemDetailInfo
    setIsSaving(true)
    api({ ...data, vesSeaTrialId: vesSeaTrialId })
      .then((res) => {
        setIsSaving(false)
        showSnackBar(res)
        refetch()
        setCreateOpen(false)
      })
      .catch((err) => {
        setIsSaving(false)
        showSnackBar(err, 'error')
      })
  }
  return (
    <div>
      <CustomKendoTable
        columns={columns}
        data={data?.results}
        pagination={true}
        toolBar={true}
        sortable={true}
        resizable={true}
        isFetching={isFetching}
        defaultSort={sortFields[0]}
        title={t('Sea Trial Details')}
        isDownloadable={true}
        adjustWidth={0}
        handleSort={handleSort}
        actionDropDown={[
          { title: t('edit'), iconName: 'edit', slug: 'edit', id: '1' },
          {
            title: t('delete'),
            iconName: 'delete',
            slug: 'delete',
            id: '2',
            isWarning: true
          }
        ]}
        handleToolActions={handleToolActions}
        actionToolDropDown={[
          {
            title: getArchiveText(),
            iconName: 'archieve',
            slug: 'archived',
            id: '1'
          }
        ]}
        handleMenuActions={handleMenuActions}
        primaryAction={{ title: t('New'), handlePrimaryAction: handleCreate }}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },
          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
      />

      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
        itemLabels={{ title: 'sea trial details', subTitle: subTitle }}
        actionLoader={isActionLoader}
      />

      <FormProvider {...methods}>
        <DialogModal
          isOpen={createOpen}
          type="md"
          title={
            edit && !viewable
              ? t('Edit Vessel Sea Trial Item')
              : viewable
                ? t('View Vessel Sea Trial Item')
                : t('Add Vessel Sea Trial Item')
          }
          body={<CreateSeaTrialDetailsForm viewable={viewable} />}
          onClose={() => setCreateOpen(false)}
          actionLabel={setActionLabel()}
          onSubmit={() => methods.handleSubmit(onSubmit)()}
          secondaryAction={() => setCreateOpen(false)}
          secondaryActionLabel={viewable ? t('Close') : t('cancel')}
          small={false}
          actionLoader={isSaving}
        />
      </FormProvider>
    </div>
  )
}

import { useHeaderStore } from '@/store/appStore'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../buttons/Button'
import CustomIcons from '../icons/customIcons'

type Props = {
  activeStep: number
  children: React.ReactNode
  secondaryAction: any
  secondaryActionLabel: any
  actionLoader: any
  actionDisabled?: any

  disabled?: any
  onSubmit: any
  actionLabel: any
  modalId?: any
  title: string
  data: {
    step: number
    title: string

    icon: string
    description?: string
  }[]
}
export default function PageStepperComponent({
  activeStep,
  disabled,
  data,
  actionLoader,
  onSubmit,
  title,
  secondaryActionLabel,
  actionLabel,
  secondaryAction,
  actionDisabled,
  children
}: Props) {
  const { setHeaderTitle } = useHeaderStore()
  useEffect(() => {
    setHeaderTitle(title)
  }, [title])
  const { width } = useScreenBreakPoint()
  const navigate = useNavigate()
  const generateClassName = (item: any) => {
    if (item.step > activeStep) {
      return 'upcomming'
    } else if (item.step === activeStep) {
      return 'current'
    } else {
      return 'finished'
    }
  }
  const handleSecondaryAction = useCallback(() => {
    if (disabled) {
      return
    }

    secondaryAction?.()
  }, [disabled, secondaryAction])

  const handleSubmit = useCallback(() => {
    if (disabled) {
      return
    }
    if (onSubmit) onSubmit()
  }, [onSubmit, disabled])
  return (
    <div className="flex relative flex-col justify-between h-full">
      <div className="">
        <div className="arrow-steps  overflow-x-auto">
          {data?.map((item: any, i: number) => (
            <div
              className={`step ${width < 1000 ? 'pr-3' : 'pr-5'} ${generateClassName(item)} ${i === 0 ? 'pl-6' : width < 1000 ? 'pl-10' : 'pl-[55px]'}`}
              key={item.step}
            >
              {item.step < activeStep ? (
                <CustomIcons name="stepperCheckSmall" isWrapper />
              ) : (
                <div
                  className={`w-[22px] h-[22px]  text-xxs rounded-full stepperCounter ${item.step > activeStep ? 'upcoming' : ''} flex items-center justify-center`}
                >
                  {item.step}
                </div>
              )}
              {width > 700 && (
                <p className="text-text-secondary uppercase tracking-[0.48px] text-nowrap text-xxs font-normal pl-2">
                  {item.title}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="overflow-auto h-full stepper-page-content">{children}</div>

      <div className="w-full bg-white">
        <div className="h-[72px] px-[18px] py-4 flex items-center ">
          {(actionLabel || secondaryActionLabel) && (
            <div className="flex w-full justify-between ">
              <div
                className="
                    flex
                    flex-row
                    items-end
                    gap-2.5
                    w-full
                    justify-start
                  "
              >
                {secondaryAction && secondaryActionLabel && (
                  <Button
                    size="xs"
                    disabled={disabled}
                    label={secondaryActionLabel}
                    onClick={() =>
                      secondaryAction ? secondaryAction?.() : handleSecondaryAction()
                    }
                    outlined
                  />
                )}
              </div>
              <div
                className="
                    flex
                    flex-row
                    items-end
                    gap-2.5
                    w-full
                    justify-end
                  "
              >
                {actionLabel && (
                  <Button
                    disabled={actionDisabled ?? disabled}
                    size="xs"
                    type="submit"
                    primary={false}
                    isLoading={actionLoader}
                    label={actionLabel}
                    onClick={handleSubmit}
                    className="inline-flex items-center  text-center "
                  />
                )}

                <Button
                  size="xs"
                  disabled={disabled}
                  label={'Cancel'}
                  onClick={() => navigate(-1)}
                  outlined
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

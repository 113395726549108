import { TableProps } from '@/common/customComponenttypes'

import { Debounce } from '../../../utilities'
import useScreenBreakPoint from '../../../utilities/useScreenBreakpoint'
import Button from '../buttons/Button'
import CustomIcons from '../icons/customIcons'
import SearchInput from '../inputs/SearchInput'
import MenuDropDown from '../menudropdown/MenuDropDown'

export default function Toolbar({
  onSearch,
  search,
  searchValue,
  handleSearchValue,
  asyncSearch,
  title = '',
  primaryAction,
  closeAction,
  isDownloadable,
  renderSearch,
  renderToolAction,
  onDownload,
  handleToolActions,
  actionToolDropDown
}: TableProps) {
  const handleSearch = (e?: string) => {
    Debounce(() => {
      onSearch && onSearch(e)
    }, 1000)
  }
  const handleChange = (e?: string) => {
    if (asyncSearch) {
      handleSearch(e)
    } else {
      handleSearchValue && handleSearchValue(e)
    }
  }
  const handleMenu = (slug: string) => {
    if (slug === 'pdf' || slug === 'csv') {
      onDownload?.(slug)
    } else if (handleToolActions) {
      handleToolActions(slug)
    }
  }
  const actionDropDown = [
    {
      title: 'Export PDF',
      iconName: 'pdf',
      id: '1',
      slug: 'pdf'
    },
    {
      title: 'Export CSV',
      iconName: 'csvIcon',
      id: '2',
      slug: 'csv'
    }
  ]
  const { isMobile, isTab } = useScreenBreakPoint()
  const allActionDropDown = actionToolDropDown
    ? actionToolDropDown.concat(actionDropDown)
    : isDownloadable
      ? actionDropDown
      : []

  return (
    <>
      <div className="w-full bg-white flex items-center py-0 pb-1 toolbar-outer">
        <div>
          <p className="text-input-text text-m font-medium whitespace-nowrap">{title}</p>
        </div>

        <div className="flex gap-2 w-full toolbar-right justify-end ml-0 lg:ml-10">
          <>
            {renderSearch ? (
              <div className="w-fit k-search">
                <>{renderSearch?.()}</>
              </div>
            ) : (
              <>
                {search && (
                  <div className="w-48 ">
                    <SearchInput
                      placeholder="Search"
                      handleChange={(e) => handleChange(e)}
                      handleSearch={(e) => handleSearch(e)}
                      searchValue={searchValue}
                    />
                  </div>
                )}
              </>
            )}
          </>
          {primaryAction ? ( //Changes: Added a dummy div if primary action is not present, (Changed operator && to ?:)
            <div className={`${isMobile || isTab ? '' : 'lg:ml-auto'}`}>
              <Button
                primary={false}
                size="sm"
                label={primaryAction?.title ?? 'New'}
                icon={primaryAction?.icon ?? 'plus'}
                onClick={() => primaryAction?.handlePrimaryAction?.()}
                className=" pr-5 "
              />
            </div>
          ) : (
            <div className={`${isMobile || isTab ? '' : 'lg:ml-auto'}`}></div>
          )}
          {renderToolAction && (
            <div>
              <>{renderToolAction?.()}</>
            </div>
          )}
          {allActionDropDown.length > 0 && (
            <div>
              <MenuDropDown
                isDownload={true}
                buttonSize="sm"
                iconName="three_dot"
                iconCustDimension={18}
                iconViewBox={false}
                actionDropDown={allActionDropDown}
                handleMenuActions={handleMenu}
              />
            </div>
          )}
          {closeAction && (
            <div>
              <button
                type="button"
                className="close-button text-gray-400 hover:bg-gray-200 rounded-full dark:hover:bg-gray-600 hover:text-gray-900 rounded-lg text-sm w-9 h-9 ml-auto inline-flex justify-center items-center"
                onClick={() => closeAction?.handleCloseAction?.()}
              >
                <CustomIcons
                  name={primaryAction?.icon ?? 'close'}
                  className="flex items-center justify-center"
                  type="large"
                  data-testid="button-grid-close-icon"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getRegister } from '@/apis/common.api'
import { Mention, MentionsInput } from 'react-mentions'
import CustomIcons from '../icons/customIcons'
type MentionInputProps = {
  name: string
  id: string
  label?: string
  Param1?: string
  markup?: string
  trigger?: string
  idKey?: string
  type?: 'multi' | 'single'
  disabled?: boolean
  required?: boolean
  placeholder?: string
  isValid?: boolean
  isTooltip?: boolean
  autoFocus?: boolean
  errors?: any
  data?: any[]
  adorement?: any
  fullwidth?: boolean
  apiParams?: any
  backGround?: string
  displayTransform?: (id: any, display: any) => any
  onAdd?: (id: any, display: any) => void
  onkeydown?: (e: any) => void
  onChange?: (e: any) => void
  value?: string
}
const MentionInput: React.FC<MentionInputProps> = ({
  name,
  id,
  label,
  type = 'single',
  disabled = false,
  idKey,
  trigger = '@',
  fullwidth = true,
  data = [],
  onkeydown,
  adorement,
  placeholder,
  value = '',
  markup = '@[__id__]',
  onAdd,
  displayTransform = (id: any, _display: any) => {
    return ` @${id} `
  },
  required = false,

  isValid,
  isTooltip = true,

  autoFocus = false,
  onChange,

  errors,
  apiParams,
  backGround //added for set background colour according to the form
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)
  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const adorementRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const onLabelClick = () => {
    if (!isDisabled) {
      inputRef?.current?.focus()
    }
  }

  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  const getErrors = (err?: any) => {
    let errMsg = ''
    if (err?.message) {
      errMsg = t(err?.message)
    }
    return errMsg
  }
  useEffect(() => {
    if (autoFocus) {
      onLabelClick()
    }
  }, [autoFocus])

  const getBorderColor = () => {
    if (errors && errors[name]) {
      return '#FDA29B'
    } else if (isFocused) {
      return '#0056A1'
    }
    return '#D9D9D9'
  }
  const getInnerWidth = () => {
    const innerwidth = adorementRef.current ? adorementRef.current.offsetWidth : 1
    return innerwidth
  }
  const getBg = () => backGround || 'bg-transparent' //added for set background colour according to the form
  const mentionsInputStyle = {
    control: {
      backgroundColor: disabled ? '#f9fafb' : '#fff',
      fontSize: 14,
      paddingLeft: 14,
      paddingRight: getInnerWidth()
    },
    highlighter: {
      padding: '0px',
      border: 'none',
      color: '#3395EC!important'
    },

    input: {
      paddingLeft: 14,
      paddingRight: getInnerWidth(),

      border: `1px solid ${getBorderColor()}`,

      fontWeight: 400,
      outline: 'none',
      borderRadius: 4
    },

    '&singleLine': {
      width: '100%',

      input: {
        height: 40
      },
      control: {
        height: 40
      }
    },
    suggestions: {
      marginLeft: 14,
      maxHeight: 300,
      scrollWidth: 1,
      overflow: 'auto',
      marginTop: 30,
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 16,
        padding: 9,
        borderRadius: 10
      },
      item: {
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 14,
        fontWeight: 400,
        '&focused': {
          backgroundColor: '#EFF6FC',
          color: '#003C71'
        }
      }
    }
  }

  const mentionStyle = {
    color: 'transparent',
    borderRadius: 5,
    padding: '0px',
    zIndex: 999,
    width: 'fit-content'
  }

  //   const notMatchingRegex = /($a)/
  function getData(query: any, callback: any) {
    if (!query) return
    getRegister({ ...apiParams, desc: query })
      .then((res) =>
        res.map((data: any) => ({
          display: data.Description,
          id: idKey ? data[idKey] : data.Id,
          param1: data.Param1,
          param2: data.Param2
        }))
      )

      .then(callback)
  }
  const generateClassName = (
    from: 'input' | 'label' | 'message' | 'adorement'
  ): string => {
    let className = ' '

    switch (from) {
      case 'input':
        className += `block text-common text-input-text font-normal px-3.5 w-full text-sm text-gray-900 ${getBg()} border appearance-none peer rounded-[4px] disabled:text-input-disabled bg-white disabled:bg-disabled placeholder-transparent focus:placeholder-grey-secondary h-10`

        if (errors && errors[name]) {
          className +=
            ' border-[#FDA29B] focus:border-error-[#FDA29B] focus:ring-[#FDA29B] focus:ring-3 focus:outline-[#FDA29B] input-outline'
        } else {
          className +=
            ' text-grey-dark border-input-light focus:border-blue-navy  focus:outline-none  focus:ring-0'
        }
        break
      case 'label':
        className += `   ${
          isFocused || value
            ? 'modern-input-peer-focus-label-size  px-1 placeholder-shown:-translate-y-1/2 placeholder-shown:top-1/2 top-2 -translate-y-4'
            : '  top-6 '
        } 
         modern-input-label
        modern-input-label-size peer-focus:modern-input-peer-focus-label-size 
         bg-white   absolute  duration-300 transform -translate-y-4  z-1 origin-[0]   start-[14px] ${
           isDisabled
             ? 'cursor-pointer text-[#85888e]'
             : 'cursor-text peer-focus:cursor-pointer'
         } `
        if (errors && errors[name]) {
          className += ' text-error-light '
        } else {
          className += ' text-grey-dark peer-focus:text-blue-navy'
        }
        break
      case 'message':
        className = ' text-error-icon '
        break
      case 'adorement':
        className += '  absolute right-0 adorement gap-1 flex items-center '
        break
      default:
        break
    }
    return className
  }
  const getPosition = () => {
    return 'bottom'
  }
  const handleError = (data: any) => {
    if (getErrors(data[name]) === 'required' || getErrors(data[name]) === 'Required') {
      return `${label} is ${getErrors(data[name])}` ?? ''
    } else {
      return getErrors(data[name]) ?? ''
    }
  }

  const handleChange = (event: any) => {
    inputRef?.current?.focus()
    onChange?.(event)
  }
  return (
    <div className={`${getPosition()} ${fullwidth ? 'w-full' : 'w-auto'}`}>
      <div className="tooltip-container ">
        {isHovered && errors && errors[name] && (
          <span className="tooltip">{handleError(errors)} </span>
        )}

        <div className={`flex relative ${fullwidth ? 'w-full' : 'w-auto'}`}>
          <div className="relative w-full">
            <MentionsInput
              value={value === null ? undefined : value}
              autoFocus
              disabled={disabled}
              // className={generateClassName("input")}
              singleLine={type === 'single' ? true : false}
              onFocus={() => {
                setIsFocused(true)
              }}
              //   allowSuggestionsAboveCursor={false}
              //   allowSpaceInQuery
              inputRef={inputRef}
              //  suggestionsPortalHost |	DOM Element	| undefined	| Render suggestions into the DOM in the supplied host element.
              //  inputRef	| React ref	| undefined	| Accepts a React ref to forward to the underlying input element
              //  forceSuggestionsAboveCursor | boolean	| false |	Forces the SuggestionList to be rendered above the cursor
              // a11ySuggestionsListLabel |	string |	''	| This label would be exposed to screen readers when suggestion popup appears
              // customSuggestionsContainer	| function(children)	| empty function	| Allows customizing the container of the suggestions
              onChange={handleChange}
              onBlur={(_e, _clickedSuggestion) => {
                setIsFocused(false)
              }}
              onKeyDown={onkeydown}
              style={mentionsInputStyle}
              placeholder={(isFocused && value) || !isFocused ? '' : placeholder || label}
              //   a11ySuggestionsListLabel={'Suggested JsonPlaceholder username for mention'}
            >
              <Mention
                trigger={trigger}
                markup={markup}
                // displayTransform={(id) => `@${id}`}
                displayTransform={displayTransform}
                // regex	RegExp	automatically derived from markup pattern	Allows providing a custom regular expression for parsing your markup and extracting the placeholder interpolations (optional)
                onAdd={onAdd}
                data={apiParams ? getData : data}
                style={mentionStyle}
                appendSpaceOnAdd
              />
              {/* <Mention
                trigger={' '}
                markup={markup}
                displayTransform={(id) => ` ${id}`}
                // displayTransform={displayTransform}
                // regex	RegExp	automatically derived from markup pattern	Allows providing a custom regular expression for parsing your markup and extracting the placeholder interpolations (optional)
                // onAdd={onAdd}
                data={[
                  { id: '+', display: '+' },
                  { id: '-', display: '-' },
                  { id: '/', display: '/' },
                  { id: '*', display: '*' },
                  { id: '(', display: '(' },
                  { id: ')', display: ')' }
                ]}
                style={mentionStyle}
                // appendSpaceOnAdd
              /> */}
            </MentionsInput>
            <label
              htmlFor={id}
              onClick={(e) => onLabelClick()}
              className={generateClassName('label')}
            >
              {label}
              {required ? <span className="text-error"> *</span> : <></>}
            </label>
          </div>
          {((!isValid && isTooltip && errors && errors[name]) || isValid) && (
            <div className="flex items-center justify-center gap-1 ">
              <div
                className={`${generateClassName('adorement')} bg-white h-[20px] right-[1px]`}
                ref={adorementRef}
              >
                {adorement && (
                  <div
                    className={`  ${isDisabled ? 'text-grey-light' : 'text-grey-medium'} `}
                  >
                    <>{adorement}</>
                  </div>
                )}
                {!isValid && isTooltip && errors && errors[name] && (
                  <div
                    className={` text-error-label relative bg-white cursor-pointer ${generateClassName(
                      'message'
                    )}`}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <CustomIcons name="alert" type="medium" />
                  </div>
                )}
                {isValid && (
                  <div className="pl-2">
                    <CustomIcons
                      name="check_mark"
                      type="medium"
                      className="text-[#28B440]"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MentionInput

import DialogModal from '@/components/common/modal/DialogModal'
import React from 'react'

export interface LogDetailsProps {
  data: any
  open: boolean
  onClose: () => void
}

export const LogDetails: React.FC<LogDetailsProps> = ({ data, open, onClose }) => {
  const handleCloseForm = () => {
    onClose?.()
  }

  const UserCircleCell = (props: any) => {
    const value = props?.userId

    const getRandomColor = () => {
      const letters = '0123456789ABCDEF'
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    }

    const getInitials = (name: any) => {
      if (!name) return ''
      const cleanedName = name.trim()
      if (cleanedName.includes(' - ')) {
        const parts = cleanedName.split(' - ')
        return parts.map((part: any) => part[0].toUpperCase()).join('')
      }
      const parts = cleanedName.split(' ')
      if (parts.length === 2) {
        return parts.map((part: any) => part[0].toUpperCase()).join('')
      }
      return cleanedName.substring(0, 2).toUpperCase()
    }

    const initials = getInitials(value)
    return (
      <div
        style={{
          display: 'inline-block',
          width: '25px',
          height: '25px',
          borderRadius: '50%',
          backgroundColor: getRandomColor(),
          color: 'white',
          textAlign: 'center',
          lineHeight: '25px',
          fontSize: '9px'
        }}
      >
        {initials}
      </div>
    )
  }

  const createPage = (data: any) => {
    const date = new Date(data?.logDate)
    const formattedDate = date
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      })
      .replace(/ /g, '-')
    const getTime = (date: any) => (date ? date.toString().substring(11) : '')

    return (
      <div className="h-full relative w-full text-[12px] mt-[-30px]">
        <div className="flex flex-row ... w-full mt-2">
          <div className="w-[50%] text-l font-semibold text-gray-900 mb-3">
            Log Details
          </div>
          <div className="w-[50%] text-l font-semibold text-gray-900 mb-3">Log Data</div>
        </div>

        <div className="flex flex-row ... w-full">
          <div className="w-[50%]">
            <div className="flex flex-col ...">
              <div className="flex row ... w-full border-b-[1px] pb-2 pt-2">
                <div className="w-[50%]">Reference No</div>
                <div className="w-[50%]">{data?.logId}</div>
              </div>

              <div className="flex row ... w-full border-b-[1px] pb-2 pt-2">
                <div className="w-[50%]">User</div>
                <div className="w-[50%]">
                  {UserCircleCell(data)} {data?.userId}
                </div>
              </div>

              <div className="flex row ... w-full border-b-[1px] pb-2 pt-2">
                <div className="w-[50%]">Log Date</div>
                <div className="w-[50%]">{formattedDate}</div>
              </div>

              <div className="flex row ... w-full border-b-[1px] pb-2 pt-2">
                <div className="w-[50%]">Log Time</div>
                <div className="w-[50%]">{getTime(data?.logDate)}</div>
              </div>

              <div className="flex row ... w-full border-b-[1px] pb-2 pt-2">
                <div className="w-[50%]">Entity</div>
                <div className="w-[50%]">{data?.entityName}</div>
              </div>

              <div className="flex row ... w-full border-b-[1px] pb-2 pt-2">
                <div className="w-[50%]">Description</div>
                <div className="w-[50%]">{data?.logDesc}</div>
              </div>
            </div>
          </div>
          <div
            className="border-gray-200 border-[1px] rounded-s-md w-[50%] p-4 ml-4 bg-black text-white"
            style={{ overflow: 'hidden', maxWidth: '100%', borderRadius: '4px' }}
          >
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                overflow: 'auto'
              }}
            >
              {data?.logData}
            </pre>
          </div>
        </div>
        <div className="border-t border-gray-200 w-full mt-4"></div>
      </div>
    )
  }

  return (
    <>
      <DialogModal
        isOpen={open}
        noPortal={true}
        small={true}
        type="md"
        fromModal={true}
        isCloseIcon={false}
        body={createPage(data)}
        onClose={handleCloseForm}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={'Close'}
      />
    </>
  )
}

import { getTechnicalData,  postTechnicalMipsData } from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useCustomSnackBar from '@/utilities/customSnackBar'

 
export const useInspAuditLog = (params: any) => {
  const fetchData = async () => {
    return await postTechnicalMipsData(
      `${apiTechnicalUrl.VESSEL_INSPECTION_FINDINGACTION_SEARCH_MIPS}`,
      params
    )
  }
  return useQuery({
    queryKey: ['finding-action-action-log'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

export const createFindingActions = (data: any) => {
  return postTechnicalMipsData(
    apiTechnicalUrl.VESSEL_INSPECTION_FINDINGACTION_CREATE_MIPS,
    data
  )
}

export const useFActions = (onSuccessAction: () => void, params: any) => {
const { showSnackBar } = useCustomSnackBar()

  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createFindingActions,
    mutationKey: ['create-finding-actions'],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
    },
    onSuccess: (response, data) => {
      onSuccessAction()
      queryClient.invalidateQueries(['finding-action-listing', params])
    }
  })
}

export const getInspDynamicMenu = (id: string, token: string) => {
  return getTechnicalData(
    `${apiTechnicalUrl.VESSEL_INSPECTION_FINDING_ACTION}/GetMenu?id=` +
      id +
      '&src=' +
      token
  )
}

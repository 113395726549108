import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const VesselShipIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <path
      d="M8.40384 11.9808V1.25L2.44231 8.40384H8.40384M10.7885 3.63461L14.9615 8.40384H10.7885M16.75 11.9808H1.25L2.44231 15.2C2.61284 15.6564 2.91908 16.0496 3.31984 16.3267C3.72059 16.6039 4.19661 16.7516 4.68384 16.75H13.2923C13.7795 16.7516 14.2556 16.6039 14.6563 16.3267C15.0571 16.0496 15.3633 15.6564 15.5338 15.2L16.75 11.9808Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}

export default VesselShipIcon

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const UserIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 9.47526 2.68629 7.42857 6 7.42857C9.31373 7.42857 12 9.47526 12 12H10.5C10.5 10.1065 8.48528 8.57143 6 8.57143C3.51472 8.57143 1.5 10.1065 1.5 12H0ZM6 6.85714C3.51375 6.85714 1.5 5.32286 1.5 3.42857C1.5 1.53429 3.51375 0 6 0C8.48625 0 10.5 1.53429 10.5 3.42857C10.5 5.32286 8.48625 6.85714 6 6.85714ZM6 5.71429C7.6575 5.71429 9 4.69143 9 3.42857C9 2.16571 7.6575 1.14286 6 1.14286C4.3425 1.14286 3 2.16571 3 3.42857C3 4.69143 4.3425 5.71429 6 5.71429Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default UserIcon

import { postTechnicalData } from '@/apis/api.helpers'

import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useQuery } from '@tanstack/react-query'

export const useInspAuditLog = (params: any) => {
  const fetchData = async () => {
    return await postTechnicalData(
      `${apiTechnicalUrl.VESSEL_INSPECTION_FINDING_AUDITLOG}`,
      params
    )
  }
  return useQuery({
    queryKey: ['vessel-inspection-audit-log'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

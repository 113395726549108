import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const ModuleIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.02657 5.99438C4.02657 5.99438 3.34552 5.62548 3.34552 4.88767C3.34552 4.14987 3.91306 3.89447 3.9982 3.83772C4.08333 3.78096 8.93582 1.28377 9.07771 1.22702C9.2196 1.17026 9.64525 1 10.0142 1C10.3831 1 10.6668 1.08513 10.8655 1.17026C11.0641 1.25539 15.8315 3.72421 15.9734 3.80934C16.1153 3.89447 16.6828 4.14987 16.6828 4.94443C16.6828 5.73899 16.1153 5.93763 15.9166 6.05114C15.718 6.16465 11.4898 8.37807 11.3195 8.4632C11.1492 8.54833 10.0425 9.14425 8.99258 8.60508C7.94262 8.06592 4.02657 5.99438 4.02657 5.99438Z"
        stroke="#003C71"
        strokeMiterlimit="10"
      />
      <path
        d="M1 8.86326C1 8.86326 1 8.16944 1.3689 7.80054C1.73781 7.43164 2.33373 7.26137 2.87289 7.54514C3.41206 7.82892 7.70127 10.1275 7.70127 10.1275C7.70127 10.1275 8.8321 10.6746 8.8321 11.8483C8.8321 13.0219 8.8321 16.8693 8.8321 17.0455C8.8321 17.2218 8.8321 18.8676 6.98758 18.3852L2.04996 15.7178C2.04996 15.7178 1 15.245 1 13.9348C1 12.6247 1 8.86326 1 8.86326Z"
        stroke="#003C71"
        strokeMiterlimit="10"
      />
      <path
        d="M11.1395 12.0575C11.1395 12.0575 11.1679 10.7522 12.1895 10.213C13.2111 9.67384 16.8433 7.68743 16.9852 7.6023C17.1271 7.51717 17.6947 7.2334 18.2622 7.54555C18.8297 7.8577 19 8.50498 19 8.80549C19 9.10629 19 13.7789 19 13.9256C19 14.0723 18.8865 15.1506 18.0352 15.633C17.1839 16.1155 13.3813 18.187 13.1827 18.3005C12.984 18.414 11.3382 19.0003 11.1395 17.1038V12.0575Z"
        stroke="#003C71"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default ModuleIcon

import { SearchBuilderProps } from '@/common/customComponenttypes'
import { CommonSearch } from '@/components/common'
import useAssignmentTypeStore from '@/modules/crewing/pages/registers/assignmentType/store'
import React from 'react'
import { useTranslation } from 'react-i18next'
type SearchBoxProps = {
  onSearch: (filters: any) => void
}
const AssignmentTypeSearch: React.FC<SearchBoxProps> = ({ onSearch }) => {
  const { t } = useTranslation()
  const { filters } = useAssignmentTypeStore()

  const formFields: SearchBuilderProps[] = [
    {
      label: t('Assignment Type'),
      placeholder: t('Assignment Type'),
      name: 'assignmentTypeName',
      id: 'assignmentTypeName',
      type: 'text',
      spacing: 3
    },
    {
      label: t('Core Activity'),
      placeholder: t('Core Activity'),
      name: 'coreActivityTypeId',
      id: 'coreActivityTypeId',
      type: 'autocomplete',
      apiParams: { key: 'CRWACTTYPES' },
      spacing: 3
    },
    {
      label: t('Completion Activity'),
      placeholder: t('Completion Activity'),
      name: 'completionActivityTypeId',
      id: 'completionActivityTypeId',
      type: 'autocomplete',
      apiParams: { key: 'CRWACTTYPES' },
      spacing: 3
    }
  ]

  return (
    <CommonSearch
      onSearch={onSearch}
      // fromTable={true}
      data={formFields}
      activeFilters={filters}
    />
  )
}

export default AssignmentTypeSearch

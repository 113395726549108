import { ButtonSpinner } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'
import { useLogin } from '@/pages/auth/login/api'
import { useAppStore } from '@/store/appStore'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LoginForm, LoginSchema } from '../schema'

const Login: React.FC = () => {
  const { t } = useTranslation()
  const { mutate } = useLogin()
  const [showPassword, setShowPassword] = useState(false)
  const { isLoading } = useAppStore()
  const {
    handleSubmit,
    register,

    formState: { errors }
  } = useForm<LoginForm>({
    resolver: zodResolver(LoginSchema),
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: ''
    }
  })
  const onSubmit = (payload: LoginForm) => {
    mutate(payload)
  }
  const { isMobile, isTab, customTab } = useScreenBreakPoint()
  const renderAction = () => {
    return (
      <>
        <div className="w-full ">
          <button
            type="submit"
            disabled={isLoading}
            className="relative btn-big btn-secondary text-m font-normal tracking-[0.3px] w-full text-white flex items-center justify-center"
          >
            {isLoading && (
              <div className="absolute w-full h-full flex items-center justify-center">
                <ButtonSpinner
                  position="center"
                  // data-testid="app-common-button-spinner"
                />
              </div>
            )}
            <div>{t('auth.loginButton')}</div>
            {/* {isLoading && <ButtonSpinner position="right" />} */}
          </button>
        </div>
        <div className="w-full">
          <button
            type="button"
            disabled={false}
            className="btn-big h-[45px] bg-white  text-m font-normal border border-[#DCDCDC] text-[#344054] tracking-[0.3px] w-full  flex items-center justify-center"
          >
            <div className="flex gap-2">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_3754_391)">
                    <path
                      d="M23.625 1.08691L11.0625 2.93079V12.2741L23.625 12.174V1.08691ZM0.308814 13.2879L0.309376 21.2387L9.83269 22.548L9.82519 13.3498L0.308814 13.2879ZM10.9626 13.4154L10.9802 22.6901L23.6132 24.473L23.6162 13.4362L10.9626 13.4154ZM0.301876 4.39816L0.310689 12.345L9.834 12.2908L9.82969 3.10029L0.301876 4.39816Z"
                      fill="#00ADEF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3754_391">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.779785)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              {!customTab && <div className=" text-m">Login with Windows</div>}
            </div>
          </button>
        </div>
      </>
    )
  }
  return (
    <div className="relative bg-opacity-50">
      <div
        className={`${
          isTab &&
          ' absolute bg-full-login-image blur-sm bg-no-repeat bg-cover bg-opacity-15 bg-center inset-0'
        }`}
      />
      <div
        className={`${
          isMobile
            ? ' bg-white min-h-screen '
            : 'mx-auto my-auto relative z-10 h-screen w-screen  flex items-center justify-center '
        }  `}
      >
        <div
          className={` ${
            isMobile ? 'p-0' : 'p-[29px] mx-auto my-auto '
          } md:flex md:flex-col md:items-center md:justify-center  md:p-0 `}
        >
          <div
            className={`${
              customTab ? 'p-0' : isMobile ? 'p-4' : 'p-5'
            } flex flex-col md:flex-row bg-white rounded `}
          >
            {/* form section starts*/}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={`${
                isTab ? 'p-5' : isMobile ? '' : 'p-[30px] pr-[50px]'
              }  pt-5  flex flex-col justify-start`}
              // className="p-[18px] pt-[31px] md:pt-[55px] md:pl-[136px] md:pr-[100px]"
              autoComplete="off"
            >
              <div className="flex flex-col gap-2  h-full ">
                {/* <div
                  // className=" md:pt-0 text-common md:text-l text-center uppercase font-medium font-poppins tracking-[0.54px] text-[#575757]"
                  className=" md:pt-0 text-m  font-medium font-poppins tracking-[0.54px] text-[#475467]"
                >
                  Welcome To
                </div> */}
                <div className="flex items-center">
                  <div className="flex items-center gap-2.5">
                    {/* <div className="w-[26.898px] h-[36.775px]"> */}
                    {/* <div className="w-[26.898px] h-[36.775px] md:h-[50px] md:w-[36.571px]"> */}

                    {/* <img src="assets/mips.png" alt="logo" className="h-full w-full" />
                    </div> */}
                    <div
                      className={
                        isMobile
                          ? 'w-full h-full pt-[78px]'
                          : isTab
                            ? 'w-full h-full '
                            : 'w-full h-full pt-5'
                      }
                    >
                      <img
                        src="assets/newLogo.svg"
                        alt="logo"
                        className="h-full w-full"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    customTab ? 'pb-3' : 'pb-[24px]'
                  } text-text-secondary text-m  font-normal font-Montserrat pt-1.5 `}
                >
                  Please Sign In to access{' '}
                  <span className="text-m font-semibold text-text-primary">MIPS</span>
                </div>
                <div>
                  <div className="flex flex-col gap-[12px]">
                    <div className="relative flex flex-col">
                      <div className={`relative   `}>
                        <div className="w-full relative flex items-center justify-center pb-2.5">
                          <input
                            type="text"
                            id="username"
                            className={` w-full placeholder:font-medium  placeholder:tracking-[0.39px] pl-[38px] placeholder:text-[#545964] placeholder:text-[14px] input-big pr-input  ${
                              errors?.username?.message
                                ? 'textfield-big-error'
                                : 'textfield-big'
                            }`}
                            placeholder={t('auth.usernamePlaceholder') ?? ''}
                            {...register('username')}
                          />

                          <CustomIcons
                            name={'user'}
                            className="absolute left-[10px] z-50 text-[#667085]"
                            type="large-xl"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="relative  flex flex-col">
                      <div className={`relative  `}>
                        <div className="w-full relative flex items-center justify-center">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            placeholder={t('auth.passwordPlaceholder') ?? ''}
                            className={` w-full placeholder:font-medium h-11 placeholder:tracking-[0.39px] placeholder:text-[#545964] placeholder:text-[14px] input-big pr-[50px] ${
                              errors?.password?.message
                                ? 'textfield-big-error'
                                : 'textfield-big'
                            }`}
                            {...register('password')}
                          />
                          <CustomIcons
                            name={'pass_key'}
                            className="cursor-pointer absolute left-[10px] z-50"
                            type="large-xl"
                          />
                          <CustomIcons
                            name={showPassword ? 'eye' : 'eye_close'}
                            className="cursor-pointer absolute right-[10px] z-50"
                            type="large-xl"
                            onClick={() => {
                              setShowPassword((s) => !s)
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="flex flex-col gap-2  "> */}
                    <div className="pb-4 md:pb-2.5">
                      <Link
                        to="#"
                        className="text-text-primary text-common font-medium text-right leading-[15.25px] pt-[17px] md:pt-[0px]"
                      >
                        {t('auth.forgotPassword')}
                      </Link>
                    </div>
                    {/* {customTab ? (
                      <div className=" flex justify-between gap-2">{renderAction()}</div>
                    ) : ( */}
                    <>{renderAction()}</>
                    {/* )} */}
                  </div>

                  <div className="text-center pt-5 text-text-secondary text-common md:text-common font-medium">
                    {t('auth.registerSlogan')}
                    <Link className=" text-text-primary" to="#">
                      {` Sign up`}
                    </Link>
                  </div>
                </div>
              </div>
            </form>
            {/* <div className="w-[372px] bg-no-repeat	 h-[289px] md:w-[520px] md:h-[514px] rounded-t-0 md:rounded-r-[30px] bg-login-image-sm md:bg-login-image flex flex-col items-center justify-end  mt-0 relative" /> */}
            {!isTab && !isMobile && (
              <div className=" bg-no-repeat	 w-[520px] h-[514px] rounded-t-0 md:rounded-r-[12px] bg-login-image-sm md:bg-login-image flex flex-col items-center justify-end  mt-0 relative" />
            )}
          </div>
        </div>
        {!customTab && (
          <div
            className={`${
              isMobile
                ? ' absolute bottom-5 left-1/2 transform -translate-x-1/2'
                : ` absolute bottom-[30px] right-[30px]`
            }`}
          >
            <span
              className={`${
                isTab ? ' text-white' : 'text-text-secondary'
              }  text-common font-normal`}
            >
              © Bahri 2024
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default Login

import {
  deleteCrewData,
  getCrewData,
  postCrewData,
  updateCrewData
} from '@/apis/api.helpers'
import apiCrewingUrl from '@/modules/crewing/apis/api.urls'
import { useQuery } from '@tanstack/react-query'

export const addCrewingAssignmentType = async (payload: any) => {
  return await postCrewData(
    `${apiCrewingUrl.CREWING_ASSIGNMENT_TYPE}/CreateAssignmentType`,
    payload
  )
}

export const updateCrewingAssignmentType = async (payload: any) => {
  return await updateCrewData(
    `${apiCrewingUrl.CREWING_ASSIGNMENT_TYPE}/UpdateAssignmentType`,
    payload
  )
}
export const deleteCrewingAssignmentType = async (id: number) => {
  return await deleteCrewData(
    `${apiCrewingUrl.CREWING_ASSIGNMENT_TYPE}/DeleteAssignmentType/${id}`
  )
}

export const getCrewingAssignmentType = async (id: number) => {
  const { data } = await getCrewData(
    `${apiCrewingUrl.CREWING_ASSIGNMENT_TYPE}/GetAssignmentType/${id}`
  )
  return data
}
export const fetchCrewingAssignmentType = async (filters: any) => {
  const { data } = await postCrewData(
    `${apiCrewingUrl.CREWING_ASSIGNMENT_TYPE}/SearchAssignmentType`,
    {
      ...filters
    }
  )
  return data
}

export const useCrewingAssignmentTypeList = (filters: any, searchCount: number) => {
  return useQuery({
    queryKey: ['crewing-assignment-type-list', filters],
    queryFn: () => fetchCrewingAssignmentType(filters),
    //initialData: false,
    staleTime: 500,
    enabled: searchCount > 1 ? true : false
  })
}

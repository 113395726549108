import CreateBranch from '@/components/app/masterData/company/branch/CreateBranch'
import { CustomKendoTable, DeletePopUp } from '@/components/common'
import { TableHeight, defaultPageSize, isAllow } from '@/constants'
import { useAppContext } from '@/store/context/appContext'
import useHasPermission from '@/utilities/checkActionPermission'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import { deleteBranch, getbranchMenu, useBranchList } from '../../api'

const sortFields = ['BranchName']
type PageParams = {
  pageNo: number
  CompanyId?: string
  pageSize: number
  sortBy: string
}
const CompanyBranchesList: React.FC = () => {
  const { companyId: CompanyId } = useOutletContext<any>()

  const [total, setTotal] = useState(0)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const hasPermission = useHasPermission('CompBranch', 'master')
  const [edit, setEdit] = useState(true)
  const [view, setView] = useState<boolean>(false)
  const [BranchId, setBranchId] = useState<string>('')
  const [subTitle, setSubTitle] = useState<string>('')
  const { showSnackBar } = useCustomSnackBar()
  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    CompanyId: CompanyId,
    pageSize: defaultPageSize,
    sortBy: sortFields[0]
  })
  const { setIsCommonLoader, setIsActionLoader, isActionLoader } = useAppContext()

  const { data, refetch, isFetching } = useBranchList(params)
  const columns = [
    {
      title: 'City',
      field: 'City',
      sort: true,
      action: true,
      render: (row: any) => (
        <div
          className="kendo-column-text-link cursor-pointer truncate"
          onClick={() => {
            manageViewEditToogle(row.BranchId)
          }}
        >
          {row?.City}
        </div>
      )
    },

    {
      title: 'State',
      field: 'State',
      sort: true
    },

    {
      title: 'Email',
      field: 'Email',
      sort: true
    },
    {
      title: 'Telephone',
      field: 'Telephone',
      sort: true
    },
    {
      title: 'Head Office',

      align: 'center',
      field: 'Primary',
      type: 'boolean',
      sort: true
    },
    {
      title: 'Archived',
      align: 'center',
      field: 'Archived',
      type: 'boolean',
      sort: true
    }
  ]

  const manageViewEditToogle = (id?: string) => {
    if (id) {
      setIsCommonLoader(true)
      setBranchId(id)
      setView(true)
      setEdit(false)
      setCreateOpen(true)
      setIsCommonLoader(false)
    }
  }
  useEffect(() => {
    setTotal(data?.length)
  }, [data])

  useEffect(() => {
    if (!data) refetch()
  }, [params])
  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }

  const handleMenuActions = async (slug: string, rowData: any) => {
    setBranchId(rowData?.BranchId)
    setSubTitle(rowData?.City)
    const permissionParams = {
      UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
      AccessKey: '',
      ID1: CompanyId
    }
    const permission = await hasPermission(permissionParams)
    if (slug === 'delete') {
      setDeleteOpen(permission)
    } else {
      setEdit(false)
      setCreateOpen(permission)
      setEdit(true)
      setView(false)
    }
  }

  const handleDelete = () => {
    setIsActionLoader(true)
    deleteBranch(BranchId)
      .then((res) => {
        showSnackBar(res)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err.response.data.error.message, 'error')
      })
      .finally(() => {
        setDeleteOpen(false)
        setIsActionLoader(false)
      })
  }
  const handleCreate = async () => {
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: CompanyId
    })
    setEdit(false)
    setView(false)
    setCreateOpen(permission)
  }
  const handleMenuItems = async (row: any) => {
    const data = await getbranchMenu(row.BranchId)
    return data
  }
  return (
    <div className="px-kendo-simple-x py-kendo-simple-y bg-background-kendo">
      <CustomKendoTable
        columns={columns}
        data={data ? data : []}
        pagination={true}
        toolBar={true}
        sortable={true}
        resizable={true}
        async={false}
        isFetching={isFetching}
        defaultSort={sortFields[0]}
        title="Branch Details"
        isDownloadable={true}
        handleSort={handleSort}
        tableHeight={`calc(100vh - ${TableHeight.WithOneColHeader})`}
        actionDropDown={[
          { title: 'Edit', iconName: 'edit', slug: 'edit', id: '1' },
          {
            title: 'Delete',
            iconName: 'delete',
            slug: 'delete',
            id: '2',
            isWarning: true
          }
        ]}
        handleMenuActions={handleMenuActions}
        workflowParams={{
          isDynamicMenu: true,
          handleMenuItems: handleMenuItems,
          handleRefresh: refetch
        }}
        primaryAction={{ title: 'New', handlePrimaryAction: handleCreate }}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },
          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        itemLabels={{ title: 'company branch', subTitle: subTitle }}
        actionLoader={isActionLoader}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
      />
      <CreateBranch
        createOpen={createOpen}
        view={view}
        setCreateOpen={() => setCreateOpen(false)}
        edit={edit}
        CompanyId={CompanyId}
        BranchId={BranchId}
      />
    </div>
  )
}

export default CompanyBranchesList

import DialogModal from '@/components/common/modal/DialogModal'
import { guid } from '@/constants'
import {
  createBudgetItem,
  fetchBudgetItemById,
  getBudgetItems,
  updateBudgetItem
} from '@/modules/technical/pages/vesselBudget/details/api'
import {
  BudgetItemDataType,
  BudgetItemSchema
} from '@/modules/technical/pages/vesselBudget/details/budgetdetails/itemSchema'
import { useAppContext } from '@/store/context/appContext'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BudgetItemForm } from './BudgetItemForm'

type BudgetItemsProps = {
  budgetAccId: string
  budgetItem: any
  mode: string
  onHandleClose: () => void
  onRefetch: () => void
}
const VesselBudgetItems: React.FC<BudgetItemsProps> = ({
  budgetAccId,
  budgetItem,
  mode,
  onHandleClose,
  onRefetch
}) => {
  const { t } = useTranslation()
  const { showSnackBar } = useCustomSnackBar()
  //const { setIsLoading } = useAppStore()
  const [isOpen, setOpen] = useState(true)
  const [data, setData] = useState<any[]>([])
  const [isSaving, setIsSaving] = useState(false)
  //const [edit, setEdit] = useState(true)

  const getData = async () => {
    setIsCommonLoader(true)
    const data = await getBudgetItems(budgetAccId)
    methods.reset({ ...data })
    setData(data)
    setIsCommonLoader(false)
  }
  const { setIsCommonLoader } = useAppContext()

  const getItemData = () => {
    setIsCommonLoader(true)
    try {
      fetchBudgetItemById(budgetItem).then((res: any) => {
        methods.reset({ ...res })
        setIsCommonLoader(false)
      })
    } catch {
      setIsCommonLoader(false)
    }
  }

  useEffect(() => {
    if (budgetAccId && mode === 'add') {
      getData()
    } else if (budgetItem && mode !== 'add') {
      getItemData()
    }
  }, [budgetAccId, budgetItem, mode])

  const getDeFaultValues = (rowData: any) => {
    if (Object.keys(rowData)?.length > 0) {
      return {
        BudgetItemId: rowData?.BudgetItemId,
        BudgetAccountId: rowData?.BudgetAccountId,
        AccountId: rowData?.AccountId,
        Daily: rowData?.Daily,
        Days: rowData?.Days,
        AccountDescription: rowData?.AccountDescription,
        ItemDescription: rowData.ItemDescription,
        Budget: rowData.Budget,
        SplitEqually: rowData.SplitEqually,
        BudgetDetails: rowData.BudgetDetails,
        Assumption: rowData.Assumption
      }
    } else {
      return {
        BudgetItemId: guid,
        BudgetAccountId: guid,
        AccountId: guid,
        Daily: 0,
        Days: 0,
        AccountDescription: '',
        ItemDescription: '',
        Budget: 0,
        SplitEqually: false,
        BudgetDetails: {},
        Assumption: null
      }
    }
  }

  const methods = useForm<BudgetItemDataType>({
    resolver: zodResolver(BudgetItemSchema),
    mode: 'onChange',
    defaultValues: getDeFaultValues({})
  })

  const getHeading = () => {
    return mode === 'edit'
      ? t('Edit Budget Items - Expenses')
      : mode === 'add'
        ? t('Add Budget Items - Expenses')
        : t('View Budget Items - Expenses')
  }

  const onSubmit = (data: BudgetItemDataType) => {
    let totalBudget = 0
    let errorFound = false
    data?.BudgetDetails?.map((item: any) => {
      let tableTotal = 0
      for (let i = 1; i <= 12; i++) {
        let Months = 'Amount_' + ('0' + i).slice(-2)
        tableTotal += Number(item?.[Months])
      }
      tableTotal = Number(parseFloat(tableTotal.toString()).toFixed(2))
      totalBudget += Number(parseFloat(item.Budget).toFixed(2))

      if (Number(parseFloat(item.Budget.toString()).toFixed(2)) !== tableTotal) {
        errorFound = true
      }
      return null
    })
    if (Number(parseFloat(data?.Budget.toString()).toFixed(2)) !== totalBudget)
      errorFound = true

    if (errorFound) {
      showSnackBar(
        'The budget amount entered and the amount distributed does not matched.',
        'error'
      )
      return false
    }
    const api = mode === 'edit' ? updateBudgetItem : createBudgetItem
    setIsSaving(true)
    api(data)
      .then((res) => {
        onSuccessAction()
        showSnackBar(res)
      })
      .catch((err) => {
        showSnackBar(err?.response?.data?.error, 'error')
        setIsSaving(false)
      })
  }

  const onSuccessAction = () => {
    handleItemClose()
    setIsSaving(false)
  }

  const handleItemClose = () => {
    setOpen(false)
    onHandleClose()
    onRefetch()
  }

  return (
    <>
      <FormProvider {...methods}>
        <DialogModal
          isOpen={isOpen}
          type="lg"
          title={getHeading()}
          body={<BudgetItemForm viewable={mode === 'view' ? true : false} data={data} />}
          onClose={handleItemClose}
          actionLabel={mode === 'edit' ? 'Update' : mode === 'view' ? undefined : 'Save'}
          onSubmit={() => methods.handleSubmit(onSubmit)()}
          actionLoader={isSaving}
          secondaryAction={handleItemClose}
          secondaryActionLabel={mode === 'view' ? 'Close' : 'Cancel'}
        />
      </FormProvider>
    </>
  )
}

export default VesselBudgetItems

import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import { formContainerClass } from '@/styles/variables'
import React from 'react'
import { useTranslation } from 'react-i18next'
type SeaTrialDetailsFormProps = { viewable: boolean }
export const CreateSeaTrialDetailsForm: React.FC<SeaTrialDetailsFormProps> = ({
  viewable
}) => {
  const { t } = useTranslation()
  const formFields: FormBuilderProps[] = [
    {
      label: t('Load'),
      placeholder: t('typeToSearch'),
      name: 'loadConditionName',
      id: 'loadConditionId',
      type: 'autocomplete',
      required: true,
      apiParams: { key: 'LOADCOND' },
      spacing: 4
    },
    {
      label: t('Tier'),
      placeholder: t('typeToSearch'),
      name: 'tierName',
      id: 'tier',
      type: 'autocomplete',
      required: true,
      apiParams: { key: 'TRIALTIER' },
      spacing: 4
    },
    {
      label: t('Power'),
      placeholder: t('Power'),
      name: 'power',
      id: 'power',
      type: 'number',
      required: true,
      spacing: 4,
      adorement: 'KW',
      maxLength: 8
    },
    {
      label: t('RPM'),
      placeholder: t('RPM'),
      name: 'rpm',
      id: 'rpm',
      type: 'number',
      spacing: 4,
      maxLength: 8
    },
    {
      label: t('Speed'),
      placeholder: t('Speed'),
      name: 'speed',
      id: 'speed',
      type: 'number',
      spacing: 4,
      adorement: 'Knots',
      maxLength: 8
    },
    {
      label: t('SFOC'),
      placeholder: t('SFOC'),
      name: 'sfoc',
      id: 'sfoc',
      type: 'number',
      spacing: 4,
      adorement: 'gm/KwHr',
      maxLength: 8
    },
    {
      label: t('Fuel Cons. Hourly'),
      placeholder: t('Fuel Cons. Hourly'),
      name: 'fuelConsHourly',
      id: 'fuelConsHourly',
      type: 'text',
      spacing: 4,
      adorement: 'MT',
      maxLength: 8
    },
    {
      label: t('Fuel Cons. Daily'),
      placeholder: t('Fuel Cons. Daily'),
      name: 'fuelConsDaily',
      id: 'fuelConsDaily',
      type: 'text',
      spacing: 4,
      adorement: 'MT',
      maxLength: 8
    },
    {
      label: t('Draft FWD'),
      placeholder: t('Draft FWD'),
      name: 'draftFWD',
      id: 'draftFWD',
      type: 'text',
      spacing: 4,
      maxLength: 8
    },
    {
      label: t('Draft AFT'),
      placeholder: t('Draft AFT'),
      name: 'draftAFT',
      id: 'draftAFT',
      type: 'text',
      spacing: 4,
      maxLength: 8
    },
    {
      label: t('Dead Weight'),
      placeholder: t('Dead Weight'),
      name: 'deadWeight',
      id: 'deadWeight',
      type: 'text',
      spacing: 4,
      maxLength: 8
    },
    {
      label: t('Remarks'),
      placeholder: t('Remarks'),
      name: 'remarks',
      id: 'remarks',
      type: 'textarea',
      spacing: 12
    }
  ]
  return (
    <div className={formContainerClass}>
      <FormBuilder data={formFields} edit={!viewable} isView={viewable} />
    </div>
  )
}

const GET_ROLES_LIST = '/Role/SearchRoles'
const DELETE_ROLE = '/Role/'
const CREATE_ROLE = '/Role/CreateRole'
const UPDATE_ROLE = '/Role/UpdateRole/'
const GET_ROLE = '/Role/'
const USERS = '/User'

const GET_ROLE_PRIVILEGES_REVIEW = '/Role/GetPrevilegeReviewData?roleId='
const GET_ROLE_PRIVILEGES_ACCESS = '/Privilege/GetAllFuncWithPrivileges?roleId='
const GET_ROLE_PRIVILEGES_RESET = '/Privilege/ResetPrivilege'
const SET_ROLE_PRIVILEGES = '/Privilege/SetPrivilegesToRoles'
const GET_AUDIT_LOG = '/AuditLog/SearchAuditLogs'
const CONFIGURATIONS = '/Config'
const UPDATE_ROLE_PRIVILEGE = '/Privilege/UpdatePrivilege'
const GET_USER_DELIGATION_LIST = '/UserDelegation'
const apiSystemUrl = {
  GET_ROLES_LIST,
  DELETE_ROLE,
  CREATE_ROLE,
  UPDATE_ROLE,
  GET_ROLE,
  USERS,
  SET_ROLE_PRIVILEGES,
  GET_ROLE_PRIVILEGES_REVIEW,
  GET_ROLE_PRIVILEGES_ACCESS,
  GET_ROLE_PRIVILEGES_RESET,
  GET_AUDIT_LOG,
  UPDATE_ROLE_PRIVILEGE,
  CONFIGURATIONS,
  GET_USER_DELIGATION_LIST
}

export default apiSystemUrl

const Section = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 3V21H21V3H3ZM19.9 19.9H4.1V4.1H19.9V19.9ZM11 7H13V9H11V7ZM7 7H9V9H7V7ZM15 7H17V9H15V7ZM7 11H9V13H7V11ZM11 11H13V13H11V11ZM15 11H17V13H15V11Z"
          fill="currentColor"
        />
      </svg>
    </>
  )
}

export default Section

import { z } from 'zod'
const checkValidations = (data: any) => {
  if (data?.Checked) {
    if (data?.StartDate && data?.EndDate) {
      return true
    }
    return false
  }
  return true
}
const checkForSelection = (data: any) => {
  let hasSelected = false
  data.forEach((item: any) => {
    if (item.Checked) {
      hasSelected = true
    }
  })
  return hasSelected
}
export const ManageBudgetSchema = z.object({
  //basic data
  BudgetTypeId: z
    .number({ required_error: 'Budget type is required' })
    .min(1, 'Budget type is required'),
  BudgetTypeName: z
    .string({ required_error: 'Budget type is required' })
    .min(1, 'Budget type is required'),
  EntityId: z.string({ required_error: 'Fleet is required' }).min(1, 'Fleet is required'),
  EntityName: z
    .string({ required_error: 'Fleet is required' })
    .min(1, 'Fleet is required'),
  BudgetYear: z.date({
    required_error: 'Budget year is required',
    invalid_type_error: 'Budget year is required'
  }),

  Option: z.string({ required_error: 'Select an option' }),
  /// vessel selection

  VesselGroup: z
    .object({
      EntityId: z.string(),
      EntityName: z.string(),
      StartDate: z.date(),
      EndDate: z.date(),
      Checked: z.boolean().optional()
    })
    .refine((data) => checkValidations(data), {
      message: 'Select a valid Date'
    })
    .array()
    .refine((data) => checkForSelection(data), {
      message: 'Select atleast one item'
    }),
  /// Budget selection
  YearDropDown: z.string().array(),
  SelectedBudgetYear: z
    .string({ invalid_type_error: 'Select a valid budget year' })
    .min(1, 'Select a budget year'),
  BudgetGroup: z
    .object({
      Actual: z.number(),
      Budget: z.number(),
      Variance: z.number(),
      VariancePer: z.number(),
      EntityCount: z.number(),
      BudgetYear: z.number(),
      BudgetId: z.string(),
      BudgetTitle: z.string(),
      StatusName: z.string(),
      BudgetTypeName: z.string(),
      EntityName: z.string(),
      CurrencyId: z.string(),
      CurrencyName: z.string(),
      Checked: z.boolean().optional()
    })
    .array()
    .refine((data) => checkForSelection(data), {
      message: 'Select atleast one item'
    }),
  // Additional data
  CurrencyId: z
    .string({ required_error: 'Currency is required' })
    .min(1, 'Currency is required'),
  CurrencyName: z
    .string({ required_error: 'Currency is required' })
    .min(1, 'Currency is required'),
  Remarks: z
    .string()
    .max(4000, 'Remarks should not exceed 4000 characters')
    .optional()
    .nullable(),
  BudgetName: z
    .string({ required_error: 'Budget name is required' })
    .min(1, 'Budget name is required')
    .max(100, 'Budget name should not exceed 100 characters')
})

export const handleSchema = (type: '1' | '2' | '3') => {
  if (type === '1') {
    return ManageBudgetSchema.omit({ VesselGroup: true })
  } else {
    return ManageBudgetSchema.omit({
      BudgetGroup: true,
      SelectedBudgetYear: true,
      YearDropDown: true
    })
  }
}
export type ManageBudgetSchemaType = z.infer<typeof ManageBudgetSchema>

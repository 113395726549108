import { useAuthStore } from '@/store/authStore'
import { useDateFormat } from '@/utilities'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import decode from 'jwt-decode'
import { useEffect, useState } from 'react'
export default function Footer() {
  const { accessToken } = useAuthStore()
  let payload = { UserId: '' }

  let userData: any = undefined

  if (accessToken) {
    payload = decode(accessToken)
    userData = payload
  }
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date())
    }, 10000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])
  const dotClass = 'w-1 h-1 bg-grey-dark rounded-full'
  const { isMobile, isTab } = useScreenBreakPoint()
  const copyRightYear = useDateFormat(currentDate, 'YYYY')
  const date = useDateFormat(currentDate, 'DD-MMM-YYYY')
  const time = useDateFormat(currentDate, '', true)
  return (
    <>
      <div
        className={`fixed flex  md:flex items-center bottom-0 z-footer ${
          isMobile || isTab ? 'h-[30px]' : 'h-[36px]'
        } w-full bg-background-footer justify-between text-grey-dark text-xxs font-semibold`}
      >
        {!isTab && !isMobile && (
          <div className="flex relative left-[54px] gap-5 pl-[15px] items-center">
            <span>{userData?.Name}</span>
            <span className={dotClass}></span>
            <span>{userData?.Role}</span>
            <span className="w-1 h-1 bg-grey-dark rounded-full"></span>

            <span>Version 1.1.0</span>
            <span className={dotClass}></span>

            <span>{date}</span>
            <span className={dotClass}></span>

            <span>{time}</span>
          </div>
        )}
        <div className={isMobile || isTab ? 'pl-[15px]' : ''}>
          © {copyRightYear} Bahri
        </div>
        <div className="flex items-center pr-[15px]">
          <p className="leading-4">Powerd by : </p>
          <span className="font-semibold text-xxs"> &nbsp;MIPS</span>
        </div>
      </div>
    </>
  )
}

import React from 'react'

const StepperSuccess: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <mask
        id="mask0_458_4395"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="74"
        height="74"
      >
        <path
          d="M40.3334 73.3337C44.7116 73.3394 49.0478 72.4797 53.0927 70.8042C57.1376 69.1287 60.8116 66.6703 63.9034 63.5704C67.0033 60.4786 69.4617 56.8046 71.1372 52.7597C72.8127 48.7148 73.6724 44.3786 73.6667 40.0004C73.6723 35.6221 72.8126 31.286 71.1371 27.2411C69.4616 23.1961 67.0032 19.5222 63.9034 16.4304C60.8116 13.3304 57.1376 10.872 53.0927 9.1965C49.0478 7.52097 44.7116 6.66135 40.3334 6.66702C35.9552 6.66144 31.619 7.52111 27.5741 9.19662C23.5292 10.8721 19.8552 13.3305 16.7634 16.4304C13.6635 19.5222 11.2051 23.1961 9.52963 27.2411C7.85411 31.286 6.99445 35.6221 7.00003 40.0004C6.99436 44.3786 7.85398 48.7148 9.5295 52.7597C11.205 56.8046 13.6634 60.4786 16.7634 63.5704C19.8552 66.6702 23.5292 69.1286 27.5741 70.8041C31.619 72.4796 35.9552 73.3393 40.3334 73.3337Z"
          fill="white"
          stroke="white"
          strokeWidth="6.66667"
          strokeLinejoin="round"
        />
        <path
          d="M27 40.0005L37 50.0005L57 30.0005"
          stroke="black"
          strokeWidth="6.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0_458_4395)">
        <path d="M0.332031 0H80.332V80H0.332031V0Z" fill="#17B26A" />
      </g>
    </svg>
  )
}

export default StepperSuccess

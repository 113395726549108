import { CustomKendoTable } from '@/components/common'
//import useCustomSnackBar from '@/utilities/customSnackBar'
import CustomIcons from '@/components/common/icons/customIcons'
//import { TableHeight } from '../../../../components/app/vesselBudget/AddAccountWOCForm'
import { TableHeight } from '@/constants'
import { useBudgetEntityList } from '@/modules/technical/pages/vesselBudget/details/budgetdetails/api'
import React, { useEffect, useState } from 'react'

export interface outLet {
  BudgetId?: any
  ParentBudgetAccountId?: any
  activeNode?: any
  Title?: any
  PsType?: any
  budgetData?: any
}

type PageParams = {
  ShowZeroBudgetItems: true
  ImmediateChildOnly: true
  HideMonthlyData: false
  NameOrDesc: ''
  pageNo: number
  pageSize: number
  sortBy: string
}

export interface columnInterface {
  title?: string
  field?: string
  show?: boolean
  filter?: 'boolean' | 'numeric' | 'text' | 'date' | undefined
  minWidth?: number
  minGridWidth?: number
  locked?: boolean
  width?: string | number
  cell?: any
}

export const VesselSummary: React.FC<outLet> = ({
  BudgetId,
  ParentBudgetAccountId,
  Title,
  PsType,
  activeNode
}) => {
  const [parentId, setParentId] = useState<any>(ParentBudgetAccountId)
  const [titleNode, setTitleNode] = useState<any>()

  //const { showSnackBar } = useCustomSnackBar()

  const [params, setParams] = useState<PageParams>({
    ShowZeroBudgetItems: true,
    ImmediateChildOnly: true,
    HideMonthlyData: false,
    NameOrDesc: '',
    pageNo: 0,
    pageSize: 100,
    sortBy: 'EntityName'
  })

  const { data, refetch, isFetching } = useBudgetEntityList({
    ...params,
    ...{ BudgetAccountId: parentId, BudgetId: BudgetId }
  })

  useEffect(() => {
    setTitleNode(Title)
  }, [Title])

  useEffect(() => {
    refetch()
    //setTotal(data?.TotalRecords)
  }, [parentId])

  useEffect(() => {
    setParentId(ParentBudgetAccountId)
  }, [ParentBudgetAccountId, PsType])

  const handleSort = (order: string) => {
    setParams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }

  const formatAmount = (data: any) => {
    return parseFloat(data)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const columns = [
    {
      field: 'entityName',
      title: 'Name',
      width: 150,
      action: true,
      // icon: 'folder',
      sort: true
    },
    {
      field: 'startDate',
      title: 'Start Date',
      type: 'date',
      width: 150,
      sort: true
    },
    { field: 'endDate', title: 'End Date', width: 150, sort: true, type: 'date' },
    { field: 'days', title: 'Days', width: 100, sort: true },
    {
      field: 'budget',
      title: 'Budget Amount',
      width: 150,
      sort: true,
      render: (rowData: any) => formatAmount(rowData.budget)
    },
    {
      field: 'actual',
      title: 'Actual',
      width: 100,
      sort: true,
      render: (rowData: any) => formatAmount(rowData.actual)
    },
    {
      field: 'variance',
      title: 'Variance',
      width: 100,
      sort: true,
      render: (rowData: any) => formatAmount(rowData.variance)
    },
    {
      field: 'variancePer',
      title: 'Var %',
      width: 100,
      sort: true,
      render: (rowData: any) => formatAmount(rowData.variancePer)
    },
    {
      field: 'dailyBudget',
      title: 'Daily Budget',
      width: 150,
      sort: true,
      render: (rowData: any) => formatAmount(rowData.dailyBudget)
    },
    {
      field: 'dailyActual',
      title: 'Daily Actual',
      width: 150,
      sort: true,
      render: (rowData: any) => formatAmount(rowData.dailyActual)
    },
    {
      field: 'dailyVariance',
      title: 'Daily Variance',
      width: 150,
      sort: true,
      render: (rowData: any) => formatAmount(rowData.dailyVariance)
    },
    {
      field: 'dailyVarPer',
      title: 'Daily Var%',
      width: 150,
      sort: true,
      render: (rowData: any) => formatAmount(rowData.dailyVarPer)
    }
  ]
  const tableHeaderFirst = (
    <div className=" flex gap-2">
      <CustomIcons name={'budget_item'} type="large" viewBox />

      <span className="text-text-secondary font-normal text-[14px]">
        {`${titleNode ?? '--'} ${activeNode?.budgetAmount ? '- Total Amount :' : ''}  `}
        {activeNode?.budgetAmount && (
          <span className=" text-button-primary">
            {parseFloat(activeNode?.budgetAmount)
              ? parseFloat(activeNode?.budgetAmount)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')
              : 0.0}
          </span>
        )}
      </span>
    </div>
  )

  return (
    <div className="grid grid-cols-1 md:grid-cols-1">
      <CustomKendoTable
        columns={columns}
        data={data || []}
        isFetching={isFetching}
        pagination={false}
        defaultSort={'EntityName'}
        title={tableHeaderFirst}
        async={false}
        toolBar={true}
        sortable={true}
        resizable={true}
        isDownloadable={false}
        handleSort={handleSort}
        tableHeight={`calc(100vh - ${TableHeight.OneColSearch})`}
      />
    </div>
  )
}

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const ReviewPrivilegeIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.245 7.35L6 6.285L7.755 7.35L7.29 5.355L8.85 4.005L6.795 3.84L6 1.95L5.205 3.84L3.15 4.005L4.71 5.355L4.245 7.35ZM0 12V1.2C0 0.87 0.1175 0.5875 0.3525 0.3525C0.5875 0.1175 0.87 0 1.2 0H10.8C11.13 0 11.4125 0.1175 11.6475 0.3525C11.8825 0.5875 12 0.87 12 1.2V8.4C12 8.73 11.8825 9.0125 11.6475 9.2475C11.4125 9.4825 11.13 9.6 10.8 9.6H2.4L0 12ZM1.89 8.4H10.8V1.2H1.2V9.075L1.89 8.4Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ReviewPrivilegeIcon

import { postSystemMipsData } from '@/apis/api.helpers'
import apiUrl from '@/apis/common.api.urls'
import { LoginForm, LoginResponseProps } from '@/pages/auth/schema'
import { useAppStore } from '@/store/appStore'
import { useAuthStore } from '@/store/authStore'
import { useExpireTimeStore } from '@/store/idleTimeoutStore'
import useCustomSnackBar from '@/utilities/customSnackBar'
// import { refreshToken } from '@/utilities/refreshToken'
import { useMutation } from '@tanstack/react-query'
// import jwtDecode from 'jwt-decode'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid'

const NAMESPACE = 'c663270e-133a-4a7f-bed1-7407038666a8'

const loginParamsGenerator = (params: LoginForm) => {
  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent
    const language = navigator.language
    const screenResolution = `${window.screen.width}x${window.screen.height}`
    const colorDepth = window.screen.colorDepth

    return `${userAgent}-${language}-${screenResolution}-${colorDepth}-${new Date().toString()}-${uuidv4()}`
  }

  const generateBrowserId = () => {
    const browserInfo = getBrowserInfo()
    return uuidv5(browserInfo, NAMESPACE)
  }

  const data = {
    // LoginData: {
    //   IP: '49.37.232.214',
    //   BrowserId:
    //     'U2FsdGVkX1s1L2a3S4hj3VBEifG5OS7QAZBSUma1XiRkEV3tGsjvi5eE65kZ8G3kNPBhQ5ikSRDfLSCDvBml5VHr49jKVCPTgHe5dvQXEQp1L2u3SEUvEeqC3RwAKeUtpN9E49Kazp1L2u3SrMJ8',
    //   IsMobile: false,
    //   IsTablet: false,
    //   OsName: 'Windows',
    //   OsVersion: '10',
    //   MobileModel: 'none',
    //   BrowserName: 'Edge',
    //   UserAgent:
    //     'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 Safari/537.36 Edg/115.0.1901.203'
    // },
    // LoginId: params.username,
    // Pswd: params.password

    loginId: params.username,
    password: params.password,
    browserId: generateBrowserId(),
    authProvider: 1
  }
  return { ...data }
}
export const useLogin = () => {
  const { setAccessToken, setRefreshToken, setAuthenticated, setBrowserId } =
    useAuthStore()
  const [browserId, setBrowserInfo] = useState<string | null>(null)
  const { setIsLoading } = useAppStore()
  const navigate = useNavigate()
  const { showSnackBar } = useCustomSnackBar()
  const { setIdleExpire, setTokenExpire } = useExpireTimeStore()
  const loginMutation = useMutation(
    async (params: LoginForm) => {
      setIsLoading(true)
      const paramsData = loginParamsGenerator(params)
      // setBrowserInfo(paramsData.LoginData?.BrowserId || null)
      setBrowserInfo(paramsData?.browserId || null)
      const response = await postSystemMipsData(apiUrl.LOGIN_URL, paramsData)
      // const response = await postSystemData(apiUrl.LOGIN_URL, paramsData)
      return response
    },
    {
      onError: (err: any) => {
        showSnackBar(err, 'error')
      },
      onSuccess: (data: LoginResponseProps) => {
        setTokenExpire(data.expires_in)
        setIdleExpire(data.idle_Timeout)
        setAccessToken(data.token)
        setRefreshToken(data.refresh_token)
        setBrowserId(browserId)
        setAuthenticated(true)
        const now = Date.now()
        const tokenInterval = 10 * 60 * 1000 // 10 minute
        localStorage.setItem('lastTokenRefresh', now.toString())
        const nextRefreshTime = now + tokenInterval
        localStorage.setItem('nextRefresh', nextRefreshTime.toString())
        localStorage.setItem('should_reload', new Date().toString())

        navigate('/dashboard')

        // queryClient.invalidateQueries(['list']) refresh api call
      },
      onSettled: () => {
        setIsLoading(false)
      }
    }
  )

  return loginMutation
}

import { TextField } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'
import { Controller, useFormContext } from 'react-hook-form'

export default function AdjustBudgetFigure({ treeNode }: any) {
  const {
    control,
    watch,
    formState: { errors }
  } = useFormContext()
  const { percentage } = watch()
  const handleCalcBudgetAmount = () => {
    const budgetAmount = treeNode.budgetAmount
    let result = parseFloat(budgetAmount)
    if (percentage && percentage !== '') {
      const percentAmount: any = (parseFloat(budgetAmount) * parseFloat(percentage)) / 100
      if (percentAmount) {
        result = parseFloat(budgetAmount) + parseFloat(percentAmount)
      }
    }
    return result ? result.toFixed(2) : 0.0
  }
  return (
    <div className="h-full relative w-full flex flex-col gap-5">
      <div className=" flex items-center gap-2">
        <CustomIcons name="folder" className=" text-customLabel" type="large" />
        <span className=" text-customLabel text-common">
          {treeNode?.accountDescription}
        </span>
      </div>
      <div className=" flex gap-2 items-center">
        <span className=" p-2 bg-customIconBg h-10 w-10 rounded-full flex justify-center items-center">
          <CustomIcons name="CalendarIcon" className=" text-text-primary" />
        </span>
        <div className=" flex flex-col gap-2">
          <p className="text-customLabel text-input leading-3 ">Current Budget</p>
          <p className=" text-common text-text-primary font-semibold  leading-[14px]">
            {treeNode?.budgetAmount}
          </p>
        </div>
      </div>
      <div className=" flex gap-2 items-center">
        <span className=" p-2 bg-customIconBg h-10 w-10 rounded-full flex justify-center items-center">
          <CustomIcons name="CalendarIcon" className=" text-text-primary" />
        </span>
        <div className=" flex flex-col gap-2 max-w-[100px]  ">
          <p className="text-customLabel text-input leading-3 ">Adjust by %</p>

          <Controller
            name="percentage"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                name="percentage"
                id="percentage"
                label=""
                errors={errors}
                onChange={(e) => {
                  const parsedValue = parseFloat(e.target.value)
                  onChange(isNaN(parsedValue) ? '' : parsedValue)
                }}
                type="number"
                size="xxs"
                value={value}
                placeholder=""
              />
            )}
          />
        </div>
      </div>
      <div className=" flex gap-2 items-center">
        <span className=" p-2 bg-customIconBg h-10 w-10 rounded-full flex justify-center items-center">
          <CustomIcons name="CalendarIcon" className=" text-text-primary" />
        </span>
        <div className=" flex flex-col gap-2">
          <p className="text-customLabel text-input leading-3 ">New Budget</p>
          <p className="text-common text-button-primary font-semibold leading-[14px]">
            {handleCalcBudgetAmount()}
          </p>
        </div>
      </div>
    </div>
  )
}

const DimenstionIcon = () => {
  return (
    <>
      <rect width="24" height="24" fill="white" />
      <path
        d="M20.4 5.34675L13.0925 2.22275C12.7484 2.07587 12.3763 2 12 2C11.6237 2 11.2516 2.07587 10.9075 2.22275L3.6 5.34675C3.12373 5.55193 2.71919 5.88591 2.43526 6.30834C2.15132 6.73076 2.00015 7.22356 2 7.72717V16.2735C2.00079 16.7779 2.15308 17.2713 2.4384 17.6938C2.72373 18.1163 3.12983 18.4498 3.6075 18.6539L10.9075 21.7779C11.2518 21.9244 11.6238 22 12 22C12.3762 22 12.7482 21.9244 13.0925 21.7779L20.4 18.6539C20.8763 18.4487 21.2808 18.1148 21.5647 17.6923C21.8487 17.2699 21.9999 16.7771 22 16.2735V7.72717C21.9999 7.22356 21.8487 6.73076 21.5647 6.30834C21.2808 5.88591 20.8763 5.55193 20.4 5.34675ZM11.415 3.32605C11.5994 3.248 11.7986 3.2077 12 3.2077C12.2014 3.2077 12.4006 3.248 12.585 3.32605L19.895 6.44764L19.9325 6.46695L12 9.86134L4.0675 6.48143L4.105 6.46212L11.415 3.32605ZM4.105 17.553C3.85003 17.4419 3.63371 17.2621 3.48204 17.0351C3.33036 16.8081 3.24979 16.5436 3.25 16.2735V7.72717C3.25188 7.63465 3.26277 7.5425 3.2825 7.45195L11.375 10.9139V20.6577L4.105 17.553ZM20.75 16.2735C20.7502 16.5436 20.6696 16.8081 20.518 17.0351C20.3663 17.2621 20.15 17.4419 19.895 17.553L12.625 20.6577V10.9139L20.725 7.45195C20.7422 7.54273 20.7506 7.63488 20.75 7.72717V16.2735Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </>
  )
}

export default DimenstionIcon

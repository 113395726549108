import { Columns } from '@/common/customComponenttypes'
import { generateAlignment } from '@/utilities'
import formatDate from '@/utilities/usedateformat'
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools'
import { GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid'

import KendoDynamicDropDown from '../../dynamicMenu/KendoDynamicDropDown'
import CustomIcons from '../../icons/customIcons'
import KendoDropDown from '../../menudropdown/KendoDropDown'

export interface kendoProps {
  columns: Columns[]
  gridRef: any
  workflowParams?: any
  handleMenuActions?: (slug: string, rowData?: any) => void
  staticMenus?: any[]
  actionDropDown?: any
  actionDropDownParams?: any
  version?: string
}

const useKendoHelper = ({
  columns,
  gridRef,
  workflowParams,
  handleMenuActions,
  staticMenus,
  actionDropDown,
  actionDropDownParams,
  version
}: kendoProps) => {
  const CustomRender = (props: any) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id)
    const field = props.field || ''
    const curColumn = columns.find((column: any) => column.field === field)
    return (
      <td
        style={props.style}
        className={props.className}
        colSpan={props.colSpan}
        role={'gridcell'}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex
        }}
        {...navigationAttributes}
      >
        <>
          {curColumn?.render || curColumn?.action
            ? custColumn(props, field, curColumn)
            : CustomOtherRender(props, field, curColumn)}
        </>
      </td>
    )
  }

  const CustomOtherRender = (props: any, field: string, curColumn: any) => {
    if (curColumn?.type === 'boolean') return CustomCheckbox(props, field)
    else if (curColumn?.type === 'status') return CustomStatus(props, field)
    else if (curColumn?.type === 'amount') return CustomAmount(props, field)
    else if (
      curColumn?.type === 'date' ||
      curColumn?.type === 'dateTime' ||
      curColumn?.type === 'dateSec'
    )
      return CustomDateFormat(props, field, curColumn)

    return undefined
  }
  const CustomCheckbox = (props: any, field: string) => {
    return (
      <span className={`${generateAlignment(props.dataItem.align || 'center')}`}>
        {props.dataItem[field] ? (
          <CustomIcons name="check_mark" type="large" className="text-link" />
        ) : (
          ''
        )}
      </span>
    )
  }

  const CustomStatus = (props: any, field: string) => {
    const textStyle = {
      color: props.dataItem.textColor ? `${props.dataItem.textColor}` : '#FFFFFF'
    }
    const backgroundStyle = {
      backgroundColor: props.dataItem.backgroundColor
        ? `${props.dataItem.backgroundColor}`
        : '#FF681D'
    }
    return (
      <div
        className={`${generateAlignment(
          props.dataItem.align
        )}  p-[7px] max-w-fit rounded-[3px] truncate`}
        style={{ ...textStyle, ...backgroundStyle }}
      >
        {props.dataItem[field]}
      </div>
    )
  }
  const dateFormat = (type: string, val: Date) => {
    switch (type) {
      case 'date':
        return formatDate(val)
      case 'dateTime':
        return formatDate(val, 'DD-MMM-YYYY hh:mm')
      case 'dateSec':
        return formatDate(val, 'DD-MMM-YYYY HH:mm:ss:SSS')
    }
    return val
  }
  const CustomDateFormat = (props: any, field: string, curColumn: any) => {
    return (
      <>
        {props.dataItem[field] && (
          <div className={`${generateAlignment(props.dataItem.align)} truncate`}>
            <>{dateFormat(curColumn?.type, props.dataItem[field])}</>
          </div>
        )}
      </>
    )
  }

  const handleMenu = (slug: string, rowData: any) => {
    handleMenuActions?.(slug, rowData)
  }

  const CustomAmount = (props: any, field: string) => {
    return (
      <>
        <div className={`${generateAlignment(props.dataItem.align)} truncate`}>
          {parseFloat(props.dataItem[field])
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
        </div>
      </>
    )
  }

  const custColumn = (props: any, field: string, curColumn: any) => {
    gridRef.current = document.querySelector('.k-grid-content')
    return (
      <div className="relative">
        {curColumn?.render ? (
          curColumn?.render(props.dataItem)
        ) : (
          <>
            {props.dataItem[field] && (
              <div className="truncate">{props.dataItem[field].toString()}</div>
            )}
          </>
        )}

        {curColumn?.action && (
          <>
            {workflowParams?.isDynamicMenu ? (
              <div
                className={`absolute -top-0.5 action-menu right-${curColumn?.actionPos ?? '0'}`}
              >
                <KendoDynamicDropDown
                  iconName={'three_dot_line'}
                  iconClass="three_dot_box"
                  staticMenus={staticMenus}
                  handleMenuActions={handleMenu}
                  rowData={props?.dataItem}
                  version={version}
                  scrollRef={gridRef}
                  handleMenuItems={() =>
                    workflowParams?.handleMenuItems?.(props?.dataItem)
                  }
                  handleRefresh={(id) => workflowParams?.handleRefresh(id)}
                />
              </div>
            ) : (
              <>
                {actionDropDown &&
                  !actionDropDownParams?.hideDropDown?.(props?.dataItem) && (
                    <div
                      className={`absolute -top-0.5 action-menu right-${curColumn?.actionPos ?? '0'}`}
                    >
                      <KendoDropDown
                        iconName={'three_dot_line'}
                        iconClass="three_dot_box"
                        scrollRef={gridRef}
                        rows={props?.dataItem}
                        actionDropDown={actionDropDown}
                        handleMenuActions={handleMenu}
                      />
                    </div>
                  )}
              </>
            )}
          </>
        )}
      </div>
    )
  }
  return { CustomRender }
}

export default useKendoHelper

import {
  getTechnicalData,
  postTechnicalData,
  postTechnicalMipsData
} from '@/apis/api.helpers'

import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useQuery } from '@tanstack/react-query'

export const useInspFinding = (params: any) => {
  const fetchData = async () => {
    const { data } = await postTechnicalMipsData(
      `${apiTechnicalUrl.VESSEL_INSPECTION_FINDINGS_MIPS}/SearchFindings`,
      params
    )
    return data
  }
  return useQuery({
    queryKey: ['vessel-inspection-findings'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

export const createInspectionFindings = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.VESSEL_INSPECTION_FINDING_CREATE}`, data)
}
export const getInspectionTypeDetails = (id: number) => {
  return getTechnicalData(`${apiTechnicalUrl.GET_INSPECTION_TYPE_DETAILS}${id}`)
}
export const getInspFindingDynamicMenu = (id: string, token: string) => {
  return getTechnicalData(
    `${apiTechnicalUrl.GET_VI_FDETAILS}/GetMenu?id=${id}&src=${token}`
  )
}

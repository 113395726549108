import {
  deleteMasterData,
  getMasterData,
  getMasterDataMips,
  postMasterData,
  updateMasterData
} from '@/apis/api.helpers'
import apiMasterDataUrl from '@/apis/masterdata.api.urls'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const fetchCompanyData = async (filters: any) => {
  return await postMasterData(`${apiMasterDataUrl.COMPANY}/Search`, {
    ...filters
  })
}
export const useCompanyList = (filters: any, searchCount: number) => {
  return useQuery({
    queryKey: ['system-company-list', filters],
    queryFn: () => fetchCompanyData(filters),
    //initialData: false,
    staleTime: 500,
    enabled: searchCount > 1 ? true : false
  })
}

export const deleteCompany = (id: string) => {
  return deleteMasterData(`${apiMasterDataUrl.COMPANY}/Delete/${id}`)
}

export const createCompany = (data: any) => {
  return postMasterData(`${apiMasterDataUrl.COMPANY}/Create`, data)
}
export const getCompany = (data: any) => {
  return getMasterData(`${apiMasterDataUrl.COMPANY}/GetById/${data}`)
}
export const updateCompany = (data: any) => {
  return updateMasterData(`${apiMasterDataUrl.COMPANY}/Update`, data)
}

export const fetchBranchData = async (filters: any) => {
  return await postMasterData(`${apiMasterDataUrl.COMPANY_BRANCH}/Search`, {
    ...filters
  })
}
export const useBranchList = (filters: any) => {
  return useQuery({
    queryKey: ['system-company-branch-list', filters],
    queryFn: () => fetchBranchData(filters),
    initialData: false,
    staleTime: 500
  })
}

export const deleteBranch = (id: string) => {
  return deleteMasterData(`${apiMasterDataUrl.COMPANY_BRANCH}/Delete?id=${id}`)
}

export const getBranch = (data: any) => {
  return getMasterData(`${apiMasterDataUrl.COMPANY_BRANCH}/GetById/${data}`)
}
export const getbranchMenu = (id: string) => {
  return getMasterData(`${apiMasterDataUrl.COMPANY_BRANCH}/GetMenu?Id=${id}`)
}
export const getCurrencyMenu = (id: string) => {
  return getMasterData(`${apiMasterDataUrl.COMPANY_CURRENCY}/GetMenu?Id=${id}`)
}
export const getServiceMenu = (id: string) => {
  return getMasterDataMips(`${apiMasterDataUrl.COMPANY_SERVICE_MIPS}/GetMenu?Id=${id}`)
}

export const useUpdateBranch = (isSubmitting: any) => {
  const queryClient = useQueryClient()
  const { showSnackBar } = useCustomSnackBar()
  return useMutation(
    (data: any) => updateMasterData(`${apiMasterDataUrl.COMPANY_BRANCH}/Update`, data),
    {
      onSuccess: (res) => {
        showSnackBar(res)
        queryClient.invalidateQueries(['system-company-branch-list'])
        isSubmitting(false)
      },
      onError: (error: any) => {
        showSnackBar(error.resoponse.data.error, 'error')
        isSubmitting(false)
      }
    }
  )
}
export const useCreateBranch = (isSubmitting: any) => {
  const queryClient = useQueryClient()
  const { showSnackBar } = useCustomSnackBar()

  return useMutation(
    (data: any) => postMasterData(`${apiMasterDataUrl.COMPANY_BRANCH}/Create`, data),
    {
      onSuccess: (res) => {
        showSnackBar(res)
        queryClient.invalidateQueries(['system-company-branch-list'])
        isSubmitting(false)
      },
      onError: (error: any) => {
        showSnackBar(error.response.data.error, 'error')
        isSubmitting(false)
      }
    }
  )
}

export const fetchCurrencyData = async (filters: any) => {
  return await postMasterData(`${apiMasterDataUrl.COMPANY_CURRENCY}/Search`, {
    ...filters
  })
}
export const useCurrencyList = (filters: any) => {
  return useQuery({
    queryKey: ['system-company-currency-list'],
    queryFn: () => fetchCurrencyData(filters),
    initialData: false,
    staleTime: 500
  })
}
export const useCreateCompanyCurrency = (
  onSuccessAction: () => void,
  isSubmitting: any
) => {
  const queryClient = useQueryClient()
  const { showSnackBar } = useCustomSnackBar()
  return useMutation({
    mutationFn: createCurrency,
    mutationKey: ['create-company-currency'],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
      isSubmitting(false)
    },
    onSuccess: (response: any) => {
      queryClient.invalidateQueries(['system-company-currency-list'])
      onSuccessAction()
      showSnackBar(response?.data?.message || 'Currency added successfully')
      isSubmitting(false)
    }
  })
}
export const deleteCurrency = (id: string) => {
  return deleteMasterData(`${apiMasterDataUrl.COMPANY_CURRENCY}/Delete?id=${id}`)
}
export const createCurrency = (data: any) => {
  return postMasterData(`${apiMasterDataUrl.COMPANY_CURRENCY}/Create`, data)
}
export const getCurrency = (data: any) => {
  return getMasterData(`${apiMasterDataUrl.COMPANY_CURRENCY}/GetById/${data}`)
}
export const updateCurrency = (data: any) => {
  return updateMasterData(`${apiMasterDataUrl.COMPANY_CURRENCY}/Update`, data)
}

export const fetchServiceData = async (filters: any) => {
  return await postMasterData(`${apiMasterDataUrl.COMPANY_SERVICE}/Search`, {
    ...filters
  })
}
export const useServiceList = (filters: any) => {
  return useQuery({
    queryKey: ['system-company-service-list'],
    queryFn: () => fetchServiceData(filters),
    initialData: false,
    staleTime: 500
  })
}

export const deleteService = (id: string) => {
  return deleteMasterData(`${apiMasterDataUrl.COMPANY_SERVICE}/Delete/${id}`)
}
export const useCreateCompanyService = (
  onSuccessAction: () => void,
  isSubmitting: any
) => {
  const queryClient = useQueryClient()
  const { showSnackBar } = useCustomSnackBar()
  return useMutation({
    mutationFn: createService,
    mutationKey: ['create-company-currency'],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
      isSubmitting(false)
    },
    onSuccess: (response: any) => {
      queryClient.invalidateQueries(['system-company-service-list'])
      onSuccessAction()
      showSnackBar(response?.data?.message || 'Service added successfully')
      isSubmitting(false)
    }
  })
}
export const createService = (data: any) => {
  return postMasterData(`${apiMasterDataUrl.COMPANY_SERVICE}/Create`, data)
}
export const getService = (data: any) => {
  return getMasterData(`${apiMasterDataUrl.COMPANY_SERVICE}/GetById/${data}`)
}
export const updateService = (data: any) => {
  return updateMasterData(`${apiMasterDataUrl.COMPANY_SERVICE}/Update`, data)
}

// export const getCompanyAuditLog = (data: any) => {
//   return postMasterData(`${apiMasterDataUrl.COMPANY}/GetAuditLog`, data)
// }

export const fetchData = async (filters: any) => {
  return await postMasterData(`${apiMasterDataUrl.COMPANY}/GetAuditLog`, {
    ...filters
  })
}
export const useCompanyAuditLog = (filters: any) => {
  return useQuery({
    queryKey: ['system-company-audit-log'],
    queryFn: () => fetchData(filters),
    initialData: false,
    staleTime: 500
  })
}

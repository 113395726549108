//import { noLeadingSpaces } from '@/utilities'
import { z } from 'zod'

export const generalSchema = z.object({
  TargetDate: z
    .date({
      invalid_type_error: 'Target Date is Required',
      required_error: 'Target Date is Required'
    })
    .refine((date) => Boolean(date), {
      message: 'Target Date is Required'
    }),
  FindingTypeName: z
    .string({ required_error: 'Finding Type Name is Required' })
    .min(1, { message: 'Finding Type Name is Required' }),
  FindCatId: z.union([z.string(), z.number()]).optional().nullable(),
  // FindCategoryName: z
  //   .string({ required_error: 'Category Name is Required' })
  //   //.min(1, { message: 'Category Name is Required' })
  //   .optional()
  //   .nullable(),
  FindingTypeId: z.union([z.string(), z.number()]).optional().nullable(),
  // PerformanceIndName: z
  //   .string({ required_error: 'Performance Indicator is Required' })
  //   //.min(1, { message: 'Performance Indicator is Required' })
  //   .optional()
  //   .nullable(),
  // PSCDeficiecnyName: z
  //   .string({ required_error: 'PSC Deficiecny is Required' })
  //   //.min(1, { message: 'PSC Deficiecny is Required' })
  //   .optional()
  //   .nullable(),
  // PSCActionName: z
  //   .string({ required_error: 'PSC Action is Required' })
  //   //.min(1, { message: 'PSC Action is Required' })
  //   .optional()
  //   .nullable(),
  PerformanceIndId: z.union([z.string(), z.number()]).optional().nullable(),
  // SystemAreaName: z
  //   .string({ required_error: 'System Area is Required' })
  //   //.min(1, { message: 'System Area is Required' })
  //   .optional()
  //   .nullable(),
  SystemAreaId: z.union([z.string(), z.number()]).optional().nullable(),
  // SireCodeName: z
  //   .string({ required_error: 'Sire Chapter is Required' })
  //   //.min(1, { message: 'Sire Chapter is Required' })
  //   .optional()
  //   .nullable(),
  SireCodeId: z.union([z.string(), z.number()]).optional().nullable(),
  FocusArea: z
    .string({ required_error: 'Focus Area is Required' })
    .min(1, { message: 'Focus Area is Required' }),
  FindingDetails: z
    .string({ required_error: 'Finding Detail is Required' })
    .min(1, { message: 'Finding Detail is Required' }),
  InspectionId: z.union([z.string(), z.number()]).optional().nullable(),
  VesselId: z.union([z.string(), z.number()]).optional().nullable(),
  PSCActionId: z.union([z.string(), z.number()]).optional().nullable(),
  PSCDeficiencyId: z.union([z.string(), z.number()]).optional().nullable(),
  RecFindCategory: z.number().optional().nullable(),
  RecPerfIndicator: z.number().optional().nullable(),
  RecPSCInfo: z.number().optional().nullable(),
  RecSysArea: z.number().optional().nullable(),
  RecSireChapter: z.number().optional().nullable()
})

export default GeneralSchemaType
export type GeneralSchemaType = z.infer<typeof generalSchema>

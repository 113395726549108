import { MenuExplorerProps } from '@/common/customComponenttypes'
import { create } from 'zustand'

type SideMenuStore = {
  isActiveMenu: string | undefined
  setIsActiveMenu: (authenticated: string | undefined) => void
  sectionId: string
  setSectionId: (id: string | undefined) => void
  subSectionId: string
  setSubSectionId: (id: string | undefined) => void
  menuData: MenuExplorerProps[]
  setMenuData: (data: MenuExplorerProps[]) => void
}

export const useSideMenuStore = create<SideMenuStore>()((set) => ({
  isActiveMenu: undefined,
  setIsActiveMenu: (isActive) => set(() => ({ isActiveMenu: isActive })),
  sectionId: '',
  setSectionId: (id) => set(() => ({ sectionId: id })),
  subSectionId: '',
  setSubSectionId: (id) => set(() => ({ subSectionId: id })),
  menuData: [],
  setMenuData: (newData) => set((state) => ({ menuData: newData }))
}))

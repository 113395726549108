import { HeaderWrapper, SectionWraper } from '@/components/app/common'
import { Breadcrumb, CustomKendoTable, DeletePopUp } from '@/components/common'
import { TableHeight, isAllow } from '@/constants'
import useHasPermission from '@/utilities/checkActionPermission'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import FindingCategoriesSearch from '@/modules/technical/components/app/registers/findingCategories/FindingCategoriesSearch'
import { CreateFindingCategory } from '@/modules/technical/components/app/registers/findingCategories/FindingCategoryForm'
import { useAppContext } from '@/store/context/appContext'
import { parseSearchParams } from '@/utilities/dataParse'
import {
  deleteFindingCategoryFromRegister,
  fetchFindingCategoryById,
  useFindingCategoriesList
} from './api'
import useFindingCategoriesFilterStore from './store'

const initData = {
  FindingCatId: 0
}

const globalID = 0
const FindingCategoriesList: React.FC = () => {
  const hasPermission = useHasPermission('FindCategories', 'technical')
  const [findingCategoryId, setFindingCategoryId] = useState<number>(0)
  const [total, setTotal] = useState(0)
  const [createOpen, setCreateOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [rowData, setRowData] = useState<any>(initData)
  const [edit, setEdit] = useState(true)
  const [viewable, setViewable] = useState(false)
  const { filters, setFilters, isSearchCount, setIsSearched } =
    useFindingCategoriesFilterStore()
  const [subTitle, setSubTitle] = useState<string>('')
  const { t } = useTranslation()
  const { showSnackBar } = useCustomSnackBar()
  const { setIsCommonLoader, setIsActionLoader, isActionLoader } = useAppContext()
  const { data, refetch, isFetching } = useFindingCategoriesList(
    {
      ...parseSearchParams(filters)
    },
    isSearchCount
  )

  const breadcrumbConfig = [{ label: t('Technical') }, { label: t('Finding Categories') }]

  const handleCloseForm = () => {
    setCreateOpen(false)
    setRowData(initData)
    setEdit(false)
    setViewable(false)
  }

  const columns = [
    {
      title: t('Finding Category'),
      field: 'FindingCatName',
      type: 'text',
      sort: true,
      action: true,
      render: (row: any) => (
        <div
          className="kendo-column-text-link cursor-pointer truncate"
          onClick={() => manageViewEditToogle(row.FindingCatId, 'view')}
        >
          {row?.FindingCatName}
        </div>
      )
    },
    {
      title: t('Sire Category'),
      field: 'SireCatCode',
      type: 'text',
      sort: true,
      width: 200
    },
    {
      title: t('Non-Confirmity'),
      field: 'NonConfirmity',
      type: 'boolean',
      sort: true,
      width: 200
    },
    {
      title: t('Sire Ins. only'),
      field: 'SireInspOnly',
      type: 'boolean',
      sort: true,
      width: 200
    },
    {
      title: t('Archived'),
      field: 'Archived',
      type: 'boolean',
      sort: true,
      width: 200
    }
  ]

  useEffect(() => {
    setTotal(data?.TotalRecords)
  }, [data])

  const manageViewEditToogle = (id?: number, mode = 'edit') => {
    if (id) {
      setIsCommonLoader(true)
      try {
        fetchFindingCategoryById(id).then((res) => {
          setRowData(res)
          setIsCommonLoader(false)
          setViewable(mode === 'edit' ? false : true)
          setEdit(mode === 'edit' ? true : false)
          setCreateOpen(true)
        })
      } catch {
        setIsCommonLoader(false)
      }
    }
  }
  const handleMenuActions = async (slug: string, row: any) => {
    setIsCommonLoader(true)
    setFindingCategoryId(row?.FindingCatId)
    setSubTitle(row?.FindingCatName)
    const permissionParams = {
      UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
      AccessKey: '',
      ID1: row?.FindingCatId
    }
    const permission = await hasPermission(permissionParams)

    if (slug === 'delete') {
      setDeleteOpen(permission)
      setIsCommonLoader(false)
    } else if (slug === 'edit') {
      if (permission) {
        manageViewEditToogle(row?.FindingCatId)
      }
      setIsCommonLoader(false)
    }
  }
  const handleCreate = async () => {
    setIsCommonLoader(true)
    setViewable(false)
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: ''
    })
    setEdit(false)
    setCreateOpen(permission)
    setIsCommonLoader(false)
  }

  const handleDelete = () => {
    setIsActionLoader(true)
    deleteFindingCategoryFromRegister(findingCategoryId)
      .then((res) => {
        showSnackBar(res)
        setFindingCategoryId(globalID)
        refetch()
      })
      .catch((err) => {
        showSnackBar(err.response.data.error, 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }

  const setFiltersAndUpdate = (newFilters: any) => {
    setFilters(newFilters)
    setIsSearched?.()
  }

  const onSearch = (data: any) => {
    setFiltersAndUpdate({
      ...filters,
      ...data,
      pageNo: 1
    })
  }

  const onPagination = (row: number) => {
    setFiltersAndUpdate({ ...filters, pageNo: row })
  }

  const onRowsPerPage = (row: number) => {
    setFiltersAndUpdate({ ...filters, pageSize: row, pageNo: 1 })
  }

  const renderSearch = () => {
    return <FindingCategoriesSearch onSearch={onSearch} />
  }

  const handleSort = (order: string) => {
    setFiltersAndUpdate({ ...filters, sortBy: order })
  }

  return (
    <>
      <Breadcrumb items={breadcrumbConfig} />
      <HeaderWrapper>{renderSearch()}</HeaderWrapper>
      <SectionWraper>
        <div className="px-kendo-x py-kendo-y bg-background-kendo rounded ">
          <CustomKendoTable
            columns={columns}
            data={data?.Results}
            isFetching={isFetching}
            searchCount={isSearchCount}
            pagination={true}
            defaultSort={'FindingCatName'}
            title="Finding Categories"
            toolBar={true}
            sortable={true}
            resizable={true}
            isDownloadable={true}
            handleSort={handleSort}
            tableHeight={`calc(100vh - ${TableHeight.OneColSearch})`}
            handleMenuActions={handleMenuActions}
            primaryAction={{
              title: t('New'),
              handlePrimaryAction: handleCreate
            }}
            actionDropDown={[
              { title: 'Edit', iconName: 'edit', slug: 'edit', id: '1' },
              {
                title: 'Delete',
                iconName: 'delete',
                slug: 'delete',
                id: '2',
                isWarning: true
              }
            ]}
            paginationProps={{
              total: total,
              currentPage: filters.pageNo as number,
              rowsPerPage: filters.pageSize as number,
              onPagination,
              onRowsPerPage
            }}
          />
        </div>
      </SectionWraper>
      <CreateFindingCategory
        edit={edit}
        data={rowData}
        onClose={handleCloseForm}
        open={createOpen}
        view={viewable}
        title={`${edit ? 'Edit' : viewable ? 'View' : 'Add'} Finding Category`}
        refetch={refetch}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
        itemLabels={{ title: 'finding category', subTitle: subTitle }}
        actionLoader={isActionLoader}
      />
    </>
  )
}

export default FindingCategoriesList

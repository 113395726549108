import { Loader, PageStepper } from '@/components/common'
import { EntityFilterType, guid } from '@/constants'
import { createBudget } from '@/modules/technical/pages/vesselBudget/api'
import useCustomSnackBar from '@/utilities/customSnackBar'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'
import ManageBudgetAdditionalDetails from './AdditionalDetails'
import ManageBudgetBasicDetails from './BasicDetails'
import DetailPanel from './DetailPanel'
import SelectOptions from './SelectOptions'
import SuccessSection from './SuccessSection'
import ManageBudgetSummary from './Summary'
import ManageBudgetVesselsAndYear from './VesselsAndYear'
import { fetBudgetsBasedOnYear, fetchBudgetYears, fetchSubEntities } from './api'
import { handleSchema } from './schema'

export type OptionValueProps = '1' | '2' | '3'

const CreateVesselBudget: React.FC = () => {
  const { showSnackBar } = useCustomSnackBar()
  const location = useLocation()
  const navigate = useNavigate()

  const [isActionLoader, setIsActionLoader] = useState(false)
  const [option, setOption] = useState<OptionValueProps>('1')
  const [selectedBudgetYear, setSelectedBudgetYear] = useState<string>('')
  const [budgetData, setBudgetData] = useState<any>([])
  const [isFetching, setIsFetching] = useState(false)
  const [activeStep, setActiveStep] = useState(1)
  const [entityData, setEntityData] = useState<any>([])
  const [yearDropDownData, setYearDropDownData] = useState<any>([])
  const [checkedItems, setCheckedItems] = useState<any>()
  const [selectedItem, setSelectedItem] = useState<any>()
  const ManageBudgetSchema = handleSchema(option)

  type ManageBudgetSchemaType = z.infer<typeof ManageBudgetSchema>
  useEffect(() => {
    if (!location?.state?.isAllow) {
      navigate(-1)
    }
  }, [location?.state?.isAllow])
  const methods = useForm<ManageBudgetSchemaType>({
    resolver: zodResolver(ManageBudgetSchema),
    defaultValues: { Option: '1' }
  })
  const { width } = useScreenBreakPoint()
  const { watch, trigger, setValue } = methods
  const watchData: ManageBudgetSchemaType = watch()
  const { BudgetTypeId, BudgetYear, Option, EntityId, BudgetName, CurrencyId, Remarks } =
    watchData

  useEffect(() => {
    setOption(Option as OptionValueProps)
  }, [Option])

  useEffect(() => {
    setCheckedItems([])
  }, [EntityId, BudgetTypeId, BudgetYear, option])
  useEffect(() => {
    setValue('SelectedBudgetYear', selectedBudgetYear)
  }, [selectedBudgetYear])
  useEffect(() => {
    setValue('BudgetGroup', budgetData)
  }, [budgetData])
  useEffect(() => {
    setValue('VesselGroup', entityData)
  }, [entityData])
  useEffect(() => {
    setValue('YearDropDown', yearDropDownData)
  }, [yearDropDownData])

  const { t } = useTranslation()

  const checkDateValidations = () => {
    const data = entityData
    let allowNext = true
    data.forEach((item: any) => {
      if (item?.Checked) {
        if (!item?.StartDate || !item?.EndDate) {
          allowNext = false
        }
        if (!allowNext) {
          showSnackBar('Select Start date and End Date for  Selected Vessel(s)', 'error')
        }
      }
    })

    return allowNext
  }
  const clearDatas = () => {
    setBudgetData([])
    setEntityData([])
    setYearDropDownData([])
    setSelectedBudgetYear('')
    setValue('CurrencyId', '')
    setValue('CurrencyName', '')
  }
  const checkForSelection = () => {
    const data = option === '1' ? budgetData : entityData
    let errorMsg
    if (option !== '1') {
      errorMsg = 'Select atleast one item'
    } else if (budgetData.length) {
      errorMsg = 'Select an item'
    } else {
      errorMsg = 'Choose a different budget year to copy'
    }

    let hasSelected = false
    data.forEach((item: any) => {
      if (item.Checked) {
        hasSelected = true
      }
    })
    if (!hasSelected) {
      showSnackBar(errorMsg, 'error')
    }
    return hasSelected
  }

  const fetchEntities = () => {
    if (!entityData || entityData?.length === 0) {
      setIsFetching(true)
      const formSearch = {
        EntityId,
        BudgetTypeId,
        BudgetYear: BudgetYear ? dayjs(BudgetYear).format('YYYY') : undefined
      }
      fetchSubEntities(formSearch)
        .then((res: any) => {
          setEntityData(res)
        })
        .catch((err) => {
          showSnackBar(err?.response?.data?.error, 'error')
        })
        .finally(() => {
          setIsFetching(false)
        })
    }
  }

  const getBudgetYears = () => {
    if (!yearDropDownData || yearDropDownData?.length === 0) {
      const formSearch = {
        entityId: EntityId,
        budgetTypeId: BudgetTypeId,
        EntityFiltType: EntityFilterType.Fleet
      }
      setIsFetching(true)
      fetchBudgetYears(formSearch)
        .then((res: { data: { budgetYear: number }[] }) => {
          const yearDropdown: any = []
          res?.data?.forEach((item: { budgetYear: number }) => {
            yearDropdown.push(String(item.budgetYear))
          })
          setYearDropDownData(yearDropdown)
        })
        .catch((err) => {
          showSnackBar(err?.response?.data?.error, 'error')
        })
        .finally(() => {
          setIsFetching(false)
        })
    }
  }
  const getBudgetDatas = (value: string) => {
    const payLoad = {
      EntityId,
      BudgetTypeId,
      BudgetYear: value
    }
    setIsFetching(true)
    fetBudgetsBasedOnYear(payLoad)
      .then((res: any) => {
        setBudgetData(res.Results)
      })
      .catch((err) => {
        showSnackBar(err?.response?.data?.error, 'error')
      })
      .finally(() => {
        setIsFetching(false)
      })
  }
  const handleBudgetYearChange = (e: any) => {
    const { value } = e.target
    setCheckedItems([])
    setBudgetData([])
    setSelectedItem(undefined)
    setSelectedBudgetYear(value)

    if (value) {
      getBudgetDatas(value)
    }
  }
  const handleSingleSelect = (data: any) => {
    setSelectedItem(data)
    setValue('CurrencyId', data?.CurrencyId)
    setValue('CurrencyName', data?.CurrencyName)
    setBudgetData((current: any) =>
      current.map((item: any) => ({
        ...item,

        Checked: item.BudgetId === data.BudgetId
      }))
    )
  }
  const handleMultiSelect = (data: any) => {
    const year = Number(BudgetYear ? dayjs(BudgetYear).format('YYYY') : 0)
    const start = new Date(year, 0, 1, 0, 0, 0, 0)
    const end = new Date(year, 11, 31, 0, 0, 0, 0)

    setEntityData((current: any) =>
      current.map((item: any) => ({
        ...item,

        Checked: data?.includes(item.EntityId),
        ...(data?.includes(item.EntityId)
          ? {
              StartDate: start,
              EndDate: end
            }
          : { StartDate: undefined, EndDate: undefined })
      }))
    )

    setCheckedItems(data)
  }
  const handleDatePickerChange = (key: string, row: any, value: any) => {
    setEntityData((current: any) =>
      current.map((item: any) =>
        item.EntityId === row.EntityId
          ? { ...item, [key]: value?.value ? new Date(value.value) : null }
          : { ...item }
      )
    )
  }

  const handleNext = async () => {
    const basicValidationFields: any = [
      'BudgetTypeId',
      'BudgetTypeName',
      'EntityId',
      'EntityName',
      'BudgetYear'
    ]

    const additionalValidationFields: any = [
      'CurrencyId',
      'CurrencyName',
      'BudgetName',
      'Remarks'
    ]
    let isValid
    switch (activeStep) {
      case 1:
        isValid = await trigger(basicValidationFields)
        break
      case 2:
        isValid = true
        break
      case 3:
        isValid = handleCheckTableValidation()
        break
      case 4:
        isValid = await trigger(additionalValidationFields)
        break
      default:
        isValid = false
    }

    if (isValid) {
      manageNextAction()
      setActiveStep(activeStep + 1)
    }
  }
  const handleVesselYearManager = () => {
    if (option === '1') {
      getBudgetYears()
    } else {
      fetchEntities()
    }
  }
  const handleCheckTableValidation = () => {
    let isAllow = false
    if (option === '1') {
      isAllow = checkForSelection()
    } else {
      isAllow = checkDateValidations() && checkForSelection()
    }
    return isAllow
  }
  const manageNextAction = () => {
    if (activeStep === 2) {
      handleVesselYearManager()
    }
  }
  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }
  const handleUpdate = () => {
    setIsActionLoader(true)
    const getSrcBudgetId = () => {
      let budgetId = guid
      budgetData.forEach((item: any) => {
        if (item.Checked) {
          budgetId = item.BudgetId
        }
      })
      return budgetId
    }
    const generateVesselDatas = () => {
      const entDatas: any = []
      entityData.forEach((item: any) => {
        if (item.Checked) {
          entDatas.push({
            EntityId: item.EntityId,
            StartDate: item.StartDate
              ? dayjs(item.StartDate).format('YYYY-MM-DD')
              : undefined,
            EndDate: item.EndDate ? dayjs(item.EndDate).format('YYYY-MM-DD') : undefined
          })
        }
      })

      return entDatas
    }

    const finalData = {
      BudgetId: guid,
      BudgetYear: BudgetYear ? dayjs(BudgetYear).format('YYYY') : undefined,
      BudgetTypeId,
      ChartBased: Option === '2',
      EntityId,
      BudgetTitle: BudgetName,
      CurrencyId,
      StatusId: 0,
      Remarks,
      ...(Option === '1' ? { SrcBudgetId: getSrcBudgetId() } : {}),
      ...(Option !== '1' ? { BudEntityDTOs: generateVesselDatas() } : {})
    }

    createBudget(finalData)
      .then(() => {
        setActiveStep(6)
      })
      .catch((err) => {
        showSnackBar(err?.response?.data?.error, 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
      })
  }

  const generateCheckedDatas = (data: any) => {
    const checkedItems: any[] = []
    data.forEach((item: any) => {
      if (item.Checked) {
        checkedItems.push(item)
      }
    })
    return checkedItems
  }
  const stepperData = [
    {
      step: 1,
      title: 'Basic Details',
      icon: 'details',
      description: 'Fill Basic Details and Select an Option.'
    },
    {
      step: 2,
      title: 'Options',
      icon: 'details',
      description: 'Fill Basic Details and Select an Option.'
    },
    ...(option !== '1'
      ? [
          {
            step: 3,
            title: 'Vessels',
            icon: 'vesselWheel',
            description:
              'Select Vessels for this budget and fill budget start and end dates'
          }
        ]
      : [
          {
            step: 3,
            title: 'Budget',
            icon: 'doller',
            description: 'Select a budget from previous budgets'
          }
        ]),
    {
      step: 4,
      title: 'Additional Details',
      icon: 'fileReport',
      description: 'Fill additional details'
    },
    {
      step: 5,
      title: 'Summary',
      icon: 'stepperCheck',
      description: 'Verify and submit'
    }
  ]

  return (
    <FormProvider {...methods}>
      <PageStepper
        title={'Add New Budget'}
        data={stepperData}
        actionDisabled={isFetching}
        activeStep={activeStep}
        secondaryAction={
          activeStep === 6 ? undefined : activeStep !== 1 ? handleBack : undefined
        }
        secondaryActionLabel={
          activeStep === 6 ? undefined : activeStep !== 1 ? 'Back' : undefined
        }
        actionLoader={isActionLoader}
        onSubmit={
          activeStep === 6 ? undefined : activeStep < 5 ? handleNext : handleUpdate
        }
        actionLabel={activeStep === 6 ? undefined : activeStep < 5 ? t('Next') : 'Submit'}
      >
        <div
          className={`flex overflow-auto h-full ${width < 1500 ? ' px-5 pt-5' : 'p-5'}`}
        >
          {activeStep === 1 && <ManageBudgetBasicDetails clearDatas={clearDatas} />}
          {activeStep === 2 && (
            <SelectOptions clearDatas={clearDatas} option={option} methods={methods} />
          )}
          {activeStep > 2 && activeStep !== 6 && (
            <div
              className={`flex ${width < 1500 ? 'flex-col' : 'flex-row gap-4'} flex-grow   `}
            >
              <div>
                <DetailPanel methods={methods} activeStep={activeStep} />
              </div>
              <div className="flex-grow ">
                <div
                  className={`${width < 1500 ? 'my-5 h-[calc(100%-40px)] min-h-[calc(100%-40px)] max-h-[calc(100%-40px)]' : 'h-full min-h-full max-h-full'} p-5 bg-white  overflow-auto rounded-[6px] relative`}
                >
                  <Loader isActive={isFetching} />
                  <div className={`overflow-auto`}>
                    {activeStep === 3 && (
                      <ManageBudgetVesselsAndYear
                        methods={methods}
                        option={option}
                        isFetching={isFetching}
                        checkedItems={checkedItems}
                        yearDropDown={yearDropDownData}
                        handleMultiSelect={handleMultiSelect}
                        handleSingleSelect={handleSingleSelect}
                        selectedItem={selectedItem}
                        handleDatePickerChange={handleDatePickerChange}
                        selectedBudgetYear={selectedBudgetYear}
                        handleBudgetYearChange={handleBudgetYearChange}
                        data={option === '1' ? budgetData : entityData}
                      />
                    )}
                    {activeStep === 4 && (
                      <ManageBudgetAdditionalDetails methods={methods} />
                    )}
                    {activeStep === 5 && (
                      <ManageBudgetSummary
                        option={option}
                        data={generateCheckedDatas(
                          option === '1' ? budgetData : entityData
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeStep === 6 && (
            <div className={`w-full flex items-center justify-center `}>
              <SuccessSection />
            </div>
          )}
        </div>
      </PageStepper>
    </FormProvider>
  )
}

export default CreateVesselBudget

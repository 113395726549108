// !! no need to use this file for now
// import { useAssessmentAreaFilterStore } from '@/modules/technical/pages/registers/assessmentAreas/store'
// import { useBudgetTypesFilterStore } from '@/modules/technical/pages/registers/budgetTypes/store'
// import { useCommunicationTypesFilterStore } from '@/modules/technical/pages/registers/communicationTypes/store'
// import { useEngineTypesFilterStore } from '@/modules/technical/pages/registers/engineTypes/store'
// import { useFindingCausesFilterStore } from '@/modules/technical/pages/registers/findingCauses/store'
// import { useHullTypesFilterStore } from '@/modules/technical/pages/registers/HullTypes/store'
// import usePaintStore from '@/modules/technical/pages/registers/paintTypes/store'
// import { usePSCActionsStore } from '@/modules/technical/pages/registers/pscActions/store'
// import usePSCDeficiencyStore from '@/modules/technical/pages/registers/pscDeficiency/store'
// import { usePSCMOUsFilterStore } from '@/modules/technical/pages/registers/pscMOUs/store'
// import { useSafetyIndicatorFilterStore } from '@/modules/technical/pages/registers/safetyIndicators/store'
// // import { useSteamCoilTypesFilterStore } from '@/modules/technical/pages/registers/pscMOUs/store'
// import { useSteamCoilTypesFilterStore } from '@/modules/technical/pages/registers/steamCoilType/store'
// import { useTankHeatingTypesFilterStore } from '@/modules/technical/pages/registers/tankHeatingTypes/store'
// import { usevesselRoleTypesFilterStore } from '@/modules/technical/pages/registers/VesselRoles/store'
// import { useBudgetStore } from '@/modules/technical/pages/vesselBudget/store'
// import { useParticularFilterStore } from '@/modules/technical/pages/vesselParticulars/store'
// import useAuthorityStore from '@/pages/masterData/authorities/store'
// import { useCountriesFilterStore } from '@/pages/masterData/countries/store'
// import { useCurrenciesFilterStore } from '@/pages/masterData/currencies/store'
// import { useDepartmentsFilterStore } from '@/pages/masterData/departments/store'
// import { useDesignationsFilterStore } from '@/pages/masterData/designations/store'
// import { useFleetsFilterStore } from '@/pages/masterData/fleets/store'
// import { useMakerTypesFilterStore } from '@/pages/masterData/maker/store'
// import { useRegionsFilterStore } from '@/pages/masterData/regions/store'
// import { useServiceTypesFilterStore } from '@/pages/masterData/serviceTypes/store'
// import { useMeasurementOfUnitFilterStore } from '@/pages/masterData/unitOfMeasurements/store'
// import { useVesselClassesFilterStore } from '@/pages/masterData/vesselClass/store'
// import useVesselRegisterStore from '@/pages/masterData/vesselRegister/store'
// import useConfigurationStore from '@/pages/system/configurations/store'
// import { useRolesFilterStore } from '@/pages/system/roles/store'
// import { useRolesUserFilterStore } from '@/pages/system/roles/users/store'
// import { useUserFilterStore } from '@/pages/system/users/store'

import { useAuthStore } from '@/store/authStore'

// interface FilterStore {
//   clearFilters: () => void
// }

// // Define the type for a store entry
// interface StoreEntry {
//   store: FilterStore
// }
// // Function to create a store entry
// const createStoreEntry = (store: FilterStore): StoreEntry => ({ store })

export function useClearFilter() {
  const { clearAuthenticated } = useAuthStore()
  return () => {
    clearAuthenticated()
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem('should_reload', new Date().toString())
    window.open('/', '_self')
  }
}
//   const particularFilterStore = useParticularFilterStore()
//   const vesselRegisterStore = useVesselRegisterStore()
//   const countriesStore = useCountriesFilterStore()
//   const departmentStore = useDepartmentsFilterStore()
//   const currencyStore = useCurrenciesFilterStore()
//   const designationStore = useDesignationsFilterStore()
//   const rolesStore = useRolesFilterStore()
//   const fleetStore = useFleetsFilterStore()
//   const userStore = useUserFilterStore()
//   const confStore = useConfigurationStore()
//   const rolesUserStore = useRolesUserFilterStore()
//   const BudgetFilterStore = useBudgetStore()
//   const VesselRoleFilterStore = usevesselRoleTypesFilterStore()
//   const EngineTypesFilterStore = useEngineTypesFilterStore()
//   const CommunicationTypesFilterStore = useCommunicationTypesFilterStore()
//   const HullTypeFilterStore = useHullTypesFilterStore()
//   const PaintTypeFilterStore = usePaintStore()
//   const MakersFilterStore = useMakerTypesFilterStore()
//   const TankHeatFilter = useTankHeatingTypesFilterStore()
//   const FindingCausesFilterStore = useFindingCausesFilterStore()
//   const VesselClassesFilterStore = useVesselClassesFilterStore()
//   const RegionsFilterStore = useRegionsFilterStore()
//   const RegisterBudgetFilterStore = useBudgetTypesFilterStore()
//   const steamCoilTyperStore = useSteamCoilTypesFilterStore()
//   const masterAuthorities = useAuthorityStore()
//   const UnitOfMeasurementFilterrStore = useMeasurementOfUnitFilterStore()
//   const pscMOUsStore = usePSCMOUsFilterStore()
//   const SafetIndicatorStore = useSafetyIndicatorFilterStore()
//   const ServiceTypesFilterStore = useServiceTypesFilterStore()
//   const AssessmentFilterStore = useAssessmentAreaFilterStore()
//   const PSCActionstFilterStore = usePSCActionsStore()
//   const PSCDeficiencyFilterStore = usePSCDeficiencyStore()

//   // Create store entries using the factory function
//   const stores: StoreEntry[] = [
//     createStoreEntry(particularFilterStore),
//     createStoreEntry(vesselRegisterStore),
//     createStoreEntry(countriesStore),
//     createStoreEntry(departmentStore),
//     createStoreEntry(currencyStore),
//     createStoreEntry(designationStore),
//     createStoreEntry(rolesStore),
//     createStoreEntry(fleetStore),
//     createStoreEntry(userStore),
//     createStoreEntry(confStore),
//     createStoreEntry(rolesUserStore),
//     createStoreEntry(BudgetFilterStore),
//     createStoreEntry(VesselRoleFilterStore),
//     createStoreEntry(EngineTypesFilterStore),
//     createStoreEntry(CommunicationTypesFilterStore),
//     createStoreEntry(HullTypeFilterStore),
//     createStoreEntry(PaintTypeFilterStore),
//     createStoreEntry(MakersFilterStore),
//     createStoreEntry(TankHeatFilter),
//     createStoreEntry(FindingCausesFilterStore),
//     createStoreEntry(VesselClassesFilterStore),
//     createStoreEntry(RegionsFilterStore),
//     createStoreEntry(RegisterBudgetFilterStore),
//     createStoreEntry(steamCoilTyperStore),
//     createStoreEntry(masterAuthorities),
//     createStoreEntry(UnitOfMeasurementFilterrStore),
//     createStoreEntry(pscMOUsStore),
//     createStoreEntry(SafetIndicatorStore),
//     createStoreEntry(ServiceTypesFilterStore),
//     createStoreEntry(SafetIndicatorStore),
//     createStoreEntry(AssessmentFilterStore),
//     createStoreEntry(PSCActionstFilterStore),
//     createStoreEntry(PSCDeficiencyFilterStore)

//     // createStoreEntry(pscMOUsStore)
//   ]

//   // Return the function to clear filters
//   return () => {
//     stores.forEach(({ store }) => {
//       store.clearFilters()
//     })
//   }
// }

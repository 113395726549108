import { Checkbox, SearchInput } from '@/components/common'
import NoRecordsFound from '@/components/common/table/NoRecords'
import { getVesselConsumptionTypes } from '@/modules/technical/pages/vesselParticulars/details/api'
import useCustomSnackBar from '@/utilities/customSnackBar'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
type VesselEquipActivities = {
  VesselId: string
  loading: boolean
  selections: any[]
  setIsLoading: (status: boolean) => void
  ConfigId: string
}
export interface ManageActivityRef {
  getSelectedData: () => any[]
}
const EquipmentApplicableOils = forwardRef<ManageActivityRef, VesselEquipActivities>(
  ({ VesselId, setIsLoading, loading, selections, ConfigId }, ref) => {
    const [allSelected, setAllSelected] = useState<boolean>(false)
    const [search, setSearch] = useState<string | undefined>('')
    const [hasResults, setHasResults] = useState<boolean>(false)
    const [data, setData] = useState<any[]>([])
    // const [selectionData, setSelectionData] = useState<any[]>([])

    const handleChange = (value: string) => {
      setSearch(value)
    }
  const { showSnackBar } = useCustomSnackBar()
    
    const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setAllSelected(e.target.checked)
      setData((items: any[]) =>
        items.map((item: any) => ({
          ...item,
          Selected: e.target.checked
        }))
      )
    }
    const getEqActivityListData = () => {
      setIsLoading(true)
      getVesselConsumptionTypes({
        VesselId: VesselId,
        pageSize: null
      })
        .then((res) => {
          const oilTypeData = res.Results && res.Results.length !== 0 ? res.Results : []

          console.log(selections)
          setData(
            oilTypeData.map((item: any) => ({
              ...item,
              filter: true,
              Selected: selections?.filter((sel) => sel.ConsTypeId === item.ConsTypeId)
                .length
                ? true
                : false
            }))
          )

          setHasResults(oilTypeData.length ? true : false)
        })
        .catch((error) => {
          showSnackBar(error.response.data.error || 'Something went wrong', 'error')
          setHasResults(false)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    const handleSelectChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      id: string | number
    ) => {
      setData((items: any[]) =>
        items.map((item: any) => ({
          ...item,
          ...(item.ConsTypeId === id ? { Selected: e.target.checked } : {})
        }))
      )
    }
    const handleCheckParentSelect = () => {
      let selected = true
      let filtered = false
      if (data.length) {
        data.forEach((item: any) => {
          if (!item.Selected) {
            selected = false
          }
          if (item.filter) {
            filtered = true
          }
        })
      } else {
        filtered = false
        selected = false
      }
      setAllSelected(selected)
      setHasResults(filtered)
    }
    const handleFilter = () => {
      if (search && search !== '') {
        setData((items: any[]) =>
          items.map((item: any) => ({
            ...item,
            filter: item['ConsTypeName']!.toString()
              .toUpperCase()
              .includes(search.toUpperCase())
              ? true
              : false
          }))
        )
      } else {
        setData((items: any[]) =>
          items.map((item: any) => ({
            ...item,
            filter: true
          }))
        )
      }
    }
    useEffect(() => {
      handleCheckParentSelect()
    }, [data])
    useEffect(() => {
      handleFilter()
    }, [search])
    useEffect(() => {
      if (VesselId) {
        getEqActivityListData()
      }
    }, [VesselId, ConfigId])
    useImperativeHandle(ref, () => ({
      getSelectedData: () => {
        return data?.filter((item) => item.Selected)
      }
    }))

    EquipmentApplicableOils.displayName = 'Manage Applicable Activities'
    return (
      <div>
        {' '}
        <div className="flex flex-col gap-[18px] w-full pb-3 pt-4">
          <div className="w-full">
            <SearchInput
              placeholder="Search applicable activities"
              handleChange={handleChange}
              searchValue={search}
              handleSearch={() => {}}
            />
          </div>
          <div className="w-full ">
            <Checkbox
              name="select_all"
              label="Select All"
              id="select_all"
              checked={allSelected}
              handleChange={handleSelectAllChange}
            />
          </div>
        </div>
        <div className="min-h-[200px] max-h-[300px] overflow-auto flex pt-[13px] flex-col gap-4">
          {data.map((item: any, i: number) => (
            <React.Fragment key={`${i}`}>
              {item.filter && (
                <Checkbox
                  name={item.ConsTypeName}
                  label={item.ConsTypeName}
                  id={item.ConsTypeName}
                  checked={item.Selected}
                  handleChange={(e) => handleSelectChange(e, item.ConsTypeId)}
                />
              )}
            </React.Fragment>
          ))}

          {!hasResults && !loading ? <NoRecordsFound /> : null}
        </div>
      </div>
    )
  }
)

export default EquipmentApplicableOils

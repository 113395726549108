export const typeList = [
  { id: 1, name: 'Deficiency', code: '' },
  { id: 2, name: 'Observation', code: '' },
  { id: 3, name: 'Recommendation', code: '' }
]

export const customValidationItems = [
  {
    title: 'FindCategoryName',
    message: 'Category Name is Required',
    validator: 'RecFindCategory'
  },
  {
    title: 'PerformanceIndName',
    message: 'Performance Indicator is Required',
    validator: 'RecPerfIndicator'
  },
  {
    title: 'PSCDeficiecnyName',
    message: 'PSC Deficiecny is Required',
    validator: 'RecPSCInfo'
  },
  {
    title: 'PSCActionName',
    message: 'PSC Action is Required',
    validator: 'RecPSCInfo'
  },
  {
    title: 'SystemAreaName',
    message: 'System Area is Required',
    validator: 'RecSysArea'
  },
  {
    title: 'SireCodeName',
    message: 'Sire Chapter is Required',
    validator: 'RecSireChapter'
  }
]

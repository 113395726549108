import DynamicActionMenu from '@/components/common/dynamicMenu'
import MenuDropDown from '@/components/common/menudropdown/MenuDropDown'
import { generateSpacing } from '@/utilities'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import React, { useState } from 'react'

import CustomIcons from '../../../../src/components/common/icons/customIcons'

type SpacingProps = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type TypeProps = 'title' | 'status' | 'desc' | 'mode1'
export type MainContentProps = {
  data: string | number | React.ReactElement
  type?: TypeProps
  textColor?: string
  backgroundColor?: string
}

export type SubContentProps = {
  label: string
  value: string | number | React.ReactElement | null | undefined
  spacing: SpacingProps
  hasDivider?: boolean
  position?: number
}
type DetailHeaderComponentProps = {
  detailHeaders?: MainContentProps[]
  detailContents: SubContentProps[]
  handleIconName?: string
  handleMenuActions?: (slug: string, rowData?: any) => void
  isLoading?: boolean
  singleRow?: boolean
  actionDropDown?: {
    title: string
    slug: string
    iconName: string
    id: string
    isWarning?: boolean
  }[]
  workflowParams?: {
    isDynamicMenu?: boolean
    handleMenuItems?: (data: any) => any
    handleRefresh: (id?: any) => void
  }
  detailId?: string
  avatar?: string
}
const DetailHeaderComponent: React.FC<DetailHeaderComponentProps> = ({
  detailHeaders,
  actionDropDown,
  handleIconName,
  handleMenuActions,
  detailContents,
  avatar,
  workflowParams,
  detailId,
  isLoading,
  singleRow = false
}) => {
  const [showAll, setShowAll] = useState<boolean>(false)

  const generateClassName = (type?: string) => {
    let className = ' text-xxs font-medium '
    switch (type) {
      case 'title':
        className = 'text-white font-semibold text-l  md:text-l ml-3 '
        break
      case 'status':
        className =
          'text-white text-xxs font-medium leading-3 bg-primary rounded-[3px]  pr-[10px] pl-[10px] pt-[5px] pb-[5px] -ml-3 '
        break
      default:
        className =
          'text-white text-xxs font-medium  leading-3 bg-primary rounded-[3px] pr-[10px] pl-[10px] pt-[5px] pb-[5px] -pl-1 '
        break
    }
    return className
  }
  const isLastChild = (index: number, length: number) => {
    if (index === length - 1) {
      return true
    }
    return false
  }
  const { isMobile, isTab } = useScreenBreakPoint()
  let totalSpacing = 0
  return (
    <>
      {isLoading ? (
        <div className=" flex h-full mb-3">
          <div className=" w-full">
            <div
              className={`${
                avatar ? 'pl-32' : 'pl-0'
              } w-full flex justify-between items-center  h-12 bg-background-skeltonLoader `}
            >
              <div className=" w-full">
                <div className="flex  w-full  items-center justify-between ml-2">
                  <div className=" flex gap-[10px]">
                    <span className=" custom-skelton-gradient w-[111px] h-[22px] rounded-[20px]"></span>
                    <span className=" border-r border-white"></span>
                    <span className="custom-skelton-gradient w-[111px] h-[22px] rounded-[20px]"></span>
                  </div>
                  <div className=" flex">
                    <span className="flex bg-white w-6 h-6 rounded-[20px] mr-4"></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:flex flex-row w-full">
              {avatar && (
                <div className="h-full  hidden gap-2 md:flex items-center justify-center ">
                  <div className="h-full">
                    <span className=""></span>
                    <div className="rounded-[70px] w-[115px] h-[115px] border-white border-[6px] custom-skelton-gradient object-none -mt-5 ml-6 "></div>
                  </div>
                </div>
              )}
              <div className={`w-full grid grid-cols-12`}>
                <div className={isMobile || isTab ? 'col-span-10' : 'col-span-12'}>
                  <div className={`grid grid-cols-12  w-full pl-5 `}>
                    {detailContents
                      ?.slice(0, isMobile || isTab ? 1 : detailContents.length)
                      ?.map((content: any, i: any) => {
                        content['position'] = totalSpacing + content.spacing
                        totalSpacing = totalSpacing + content.spacing
                        return (
                          <div
                            key={`subcontent-${i}`}
                            className={`${generateSpacing(
                              isTab ? 10 : content.spacing
                            )} text-xxs ${' pt-2.5'}`}
                          >
                            <div
                              className={`${
                                content.hasDivider || content.position % 12 === 0
                                  ? 'px-3'
                                  : ' px-3'
                              } ${content.hasDivider ? 'pt-2.5' : 'pt-2.5'}${
                                i !== 0 && (isMobile || isTab) ? 'hidden' : ''
                              } `}
                            >
                              <div className="custom-skelton-gradient w-[55px] h-[10px] rounded-[30px]"></div>
                              <div className="custom-skelton-gradient w-[85px] h-[14px] mt-2 rounded-[30px]"></div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`flex h-full  ${!singleRow && 'mb-3'}`}>
          <div className="w-full">
            {detailHeaders?.length && (
              <div
                className={`${
                  avatar ? 'pl-32' : 'pl-0'
                } w-full flex justify-between items-center bg-text-primary h-12 `}
              >
                <div>
                  <div
                    className={`flex  w-full ${!singleRow && 'leading-[16.39px]'}  tracking-[0.3px]`}
                  >
                    {detailHeaders?.map((content, i) => (
                      <div
                        className={
                          isLastChild(i, detailHeaders.length)
                            ? `${!singleRow ? 'px-8 ' : 'px-5'}  flex justify-center items-center`
                            : 'border-r border-grey-dark px-5 h-[22px] flex justify-center items-center  '
                        }
                        key={`main-content-${i}`}
                      >
                        <div
                          className={`${generateClassName(content.type)} `}
                          style={{
                            color: content.type === 'status' ? content.textColor : '',
                            backgroundColor:
                              content.type === 'status' ? content.backgroundColor : ''
                          }}
                        >
                          {content.data || 'Unavailable'}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {workflowParams?.isDynamicMenu ? (
                  <td className=" group-hover:bg-table-hover z-9">
                    <div className="flex items-center justify-center w-[20px] group-hover:bg-table-hover pr-5 pt-3 pb-3">
                      <DynamicActionMenu
                        iconName={handleIconName ?? 'hamburger'}
                        iconClass="text-white"
                        iconSize=""
                        handleMenuItems={() =>
                          workflowParams?.handleMenuItems?.(detailId)
                        }
                        handleRefresh={(id) => workflowParams?.handleRefresh(id)}
                        fromTable={false}
                      />
                    </div>
                  </td>
                ) : (
                  <>
                    {actionDropDown && handleMenuActions && (
                      <div className="pr-5 pt-3 pb-3">
                        <MenuDropDown
                          fromTable={false}
                          iconName={handleIconName ?? 'hamburger'}
                          iconClass="text-white"
                          iconSize=""
                          // iconClass="group-hover:bg-table-hover bg-white"
                          actionDropDown={actionDropDown}
                          handleMenuActions={handleMenuActions}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <div className="md:flex flex-row w-full">
              {avatar && (
                <div className="h-full  hidden gap-2 md:flex items-center justify-center ">
                  <div className="h-full">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="rounded-[70px] w-[115px] h-[115px] border-white border-[6px] bg-[#EAEFF4] object-none -mt-5 ml-6 "
                    />
                  </div>
                </div>
              )}
              {detailHeaders?.length ? (
                <div className={`w-full grid grid-cols-12`}>
                  <div
                    className={`${isMobile || isTab ? 'col-span-10' : 'col-span-12'} ${singleRow && ' flex items-center'} `}
                  >
                    <div className={`grid grid-cols-12  w-full pl-5 `}>
                      {detailContents
                        ?.slice(0, isMobile || isTab ? 1 : detailContents.length)
                        ?.map((content: any, i: any) => {
                          content['position'] = totalSpacing + content.spacing
                          totalSpacing = totalSpacing + content.spacing
                          return (
                            <div
                              key={`subcontent-${i}`}
                              className={`${generateSpacing(
                                isTab ? 10 : content.spacing
                              )} text-xxs ${!singleRow && avatar && ' pt-2.5'}`}
                            >
                              <div
                                className={`${
                                  content.hasDivider || content.position % 12 === 0
                                    ? 'px-3'
                                    : ' px-3'
                                } ${content.hasDivider ? 'pt-2.5' : 'pt-2.5'}${
                                  i !== 0 && (isMobile || isTab) ? 'hidden' : ''
                                } `}
                              >
                                <div
                                  className={`text-text-secondary text-xxs  font-medium  ${!singleRow && 'leading-[16.39px]'}   tracking-[0.24px]`}
                                >
                                  {content.label}
                                </div>
                                <div
                                  className={`text-text-primary text-common truncate font-semibold ${!singleRow && 'leading-[16.39px]'}  pt-1 tracking-[0.24px]`}
                                >
                                  {content.value || 'Unavailable'}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                  {(isMobile || isTab) && (
                    <div className={` col-span-2 flex justify-center items-center`}>
                      <div>
                        <p className="font-medium text-[14px] text-text-more">
                          {!showAll ? 'More Details' : 'Hide Details'}
                        </p>
                      </div>
                      <div>
                        <span
                          className="text-[#040404]"
                          onClick={() => setShowAll(!showAll)}
                        >
                          <CustomIcons name={!showAll ? 'down_arrow' : 'up_arrow'} />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className={`flex w-full pl-5 gap-[118px] `}>
                  {detailContents?.map((content: any, i: any) => {
                    content['position'] = totalSpacing + content.spacing
                    totalSpacing = totalSpacing + content.spacing
                    return (
                      <div
                        key={`subcontent-${i}`}
                        className={`${generateSpacing(
                          isTab ? 10 : content.spacing
                        )} text-xxs ${!singleRow && avatar ? ' pt-2.5' : 'pt-2.5 -mb-2.5'}`}
                      >
                        <div
                          className={`${
                            content.hasDivider || content.position % 12 === 0
                              ? 'px-3'
                              : ' px-3'
                          }`}
                        >
                          <div
                            className={`text-text-secondary  font-medium ${!singleRow && 'leading-[16.39px]'}  tracking-[0.24px]`}
                          >
                            {content.label}
                          </div>
                          <div
                            className={`text-[#003C71] truncate font-semibold ${!singleRow && 'leading-[16.39px]'}  pt-1 tracking-[0.24px]`}
                          >
                            {content.value || 'Unavailable'}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
            <>
              {(isMobile || isTab) &&
                showAll &&
                detailContents
                  ?.slice(1, detailContents.length)
                  ?.map((content: any, i: any) => {
                    content['position'] = totalSpacing + content.spacing
                    totalSpacing = totalSpacing + content.spacing
                    return (
                      <div
                        key={`subcontent-${i}`}
                        className={`${generateSpacing(
                          isTab ? 10 : content.spacing
                        )} text-xxs ${'pt-2.5'}  `}
                      >
                        <div className="ml-[16px] mr-[16px] border-t-[1.5px] border-[#DBDBDD] ">
                          <div className="text-text-secondary text-xxs  font-normal leading-[16.39px] tracking-[0.24px] pt-[13px] pl-[10px] ">
                            {content.label}
                          </div>
                          <div className="text-[#003C71] text-common truncate font-semibold leading-[16.39px] pt-2 tracking-[0.24px] pb-[4px] pl-[10px]">
                            {content.value || 'Unavailable'}
                          </div>
                        </div>
                      </div>
                    )
                  })}
            </>
          </div>
        </div>
      )}
    </>
  )
}

export default DetailHeaderComponent

import { useState } from 'react'

import { generateAlignment } from '../../../utilities'
import CustomIcons from '../icons/customIcons'

export default function HeaderCell({
  column,
  handleSort,
  cellWidth,
  selectedColumn,
  setSelectedColumn
}: any) {
  const [sorted, setSorted] = useState('')
  const sortHandler = (title: string) => {
    if (handleSort) {
      const order =
        sorted === 'ASC' || (sorted === '' && selectedColumn === title)
          ? `${title} DESC`
          : `${title}`
      handleSort(order)
      setSorted(
        sorted === 'ASC' || (sorted === '' && selectedColumn === title)
          ? 'DESC'
          : sorted === 'DESC'
            ? 'ASC'
            : 'DESC'
      )
    }
    setSelectedColumn(title)
  }

  return (
    <th
      key={column.title}
      style={{ minWidth: column.width ?? cellWidth }}
      className={` text-left p-cell border-0 group sticky top-0 text-m text-dark font-medium text-nowrap ${
        column.type === 'boolean' && 'justify-center items-center'
      }`}
    >
      <span
        // style={{ minWidth: column.width ?? cellWidth }}
        className={` gap-1.5 cursor-pointer ${generateAlignment(
          column.align,
          column.type
        )}  `}
        onClick={() => sortHandler(column.field)}
      >
        {column.title}
        {column.sort && (
          <span className={` flex w-6 h-6 cursor-pointer  items-center justify-end `}>
            {selectedColumn === column.field && (
              <CustomIcons
                name={sorted === 'DESC' ? 'sort-desc' : 'sort-asc'}
                type="medium"
                className=" text-primary"
              />
            )}
            {selectedColumn !== column.field && (
              <CustomIcons
                name={sorted === 'DESC' ? 'sort-asc' : 'sort-asc'}
                type="medium"
                className="group-hover:text-primary text-[#b8b5b5a6] "
              />
            )}
          </span>
        )}
      </span>
    </th>
  )
}

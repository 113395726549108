import { Checkbox } from '@/components/common'
import CustomIcons from '@/components/common/icons/customIcons'
import { guid } from '@/constants'
import React from 'react'
import { entityTypes } from './store'

type TreeItemProps = {
  label: string
  level?: number
  children?: React.ReactNode
  handleNodeCheck?: (node: any, e: React.ChangeEvent<HTMLInputElement>) => void
  node: any
  treeSelect?: boolean
  nodeIcon?: string
  handleTreeExpand?: (data: any) => void

  nodeSelectedIcon?: string

  nodeId?: any
  data?: any
}

const UserEntityTreeNode: React.FC<TreeItemProps> = ({
  label,
  level = 0,
  children,
  node,
  handleNodeCheck,
  nodeIcon,
  handleTreeExpand,

  nodeId
}) => {
  const handleTree = () => {
    handleTreeExpand?.(nodeId)
  }

  const preparedChildren = node.isOpen ? <>{children}</> : null

  return (
    <div>
      <div className="grid grid-cols-12 gap-3 items-center  h-[42px] ">
        <div className="col-span-12 relative">
          <div
            style={{ marginLeft: level > 0 ? level * 10 : 0 }}
            className={` flex  items-center p-1 tree-content gap-2  `}
          >
            {node?.hasChild || node.entityParentId === guid ? (
              <button onClick={() => handleTree()} className="focus:outline-none">
                <span className={`font-semibold text-l  text-secondary`}>
                  <CustomIcons
                    name={node?.isOpen ? 'tree_expand' : 'tree_colapse'}
                    type="large"
                    className={`${!node?.hasChild && 'text-gray-300'}`}
                  />
                </span>
              </button>
            ) : (
              <div className="w-5"></div>
            )}

            {node && (
              <Checkbox
                name={node.entityId}
                id={node.entityId}
                value={node.entityId}
                intermediate={node.intermediate}
                checked={node.hasAccess || false}
                handleChange={(e) => handleNodeCheck?.(node, e)}
              />
            )}
            {node && Object.keys(node)?.length > 0 && (
              <span className="text-l cursor-pointer text-secondary">
                <CustomIcons
                  name={entityTypes.find((item) => item.id === node?.entityType)?.code}
                  type="large-xl"
                />
              </span>
            )}

            <p
              title={label}
              className={` text-l leading-[14px] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap  ${
                node?.hasAccess || node.isOpen ? 'text-black font-normal' : 'text-black'
              }   ${nodeIcon ? '' : 'ml-1'} `}
            >
              {label}
            </p>
          </div>
        </div>
      </div>

      {preparedChildren}
    </div>
  )
}

export default UserEntityTreeNode

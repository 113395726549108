import DialogModal from '@/components/common/modal/DialogModal'
import {
  getBranch,
  useCreateBranch,
  useUpdateBranch
} from '@/pages/masterData/company/api'
import {
  formSchema,
  FormSchema
} from '@/pages/masterData/company/details/branches/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { BranchForm } from './BranchForm'
import { guid } from '@/constants'

type Props = {
  createOpen: boolean
  setCreateOpen: (value: React.SetStateAction<boolean>) => void
  edit?: boolean
  CompanyId?: string
  BranchId?: string
  view?: boolean
}
export default function CreateBranch({
  createOpen = false,
  setCreateOpen,
  edit = false,
  CompanyId = '',
  BranchId = '',
  view
}: Readonly<Props>) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const methods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      CompanyId: CompanyId,
      BranchId: guid
    }
  })
  const getBranchDetails = async (id: string) => {
    const data = await getBranch(id)
    methods.reset({ ...data })
  }
  const { mutate: createMutation, isSuccess: createSuccess } =
    useCreateBranch(setIsSubmitting)
  const { mutate: updateMutation, isSuccess: updateSuccess } =
    useUpdateBranch(setIsSubmitting)
  const onSubmit = (data: FormSchema) => {
    setIsSubmitting(true)
    const api = edit ? updateMutation : createMutation

    api(data)
  }
  useEffect(() => {
    if (createSuccess || updateSuccess) {
      setCreateOpen(false)
    }
  }, [createSuccess, updateSuccess])
  useEffect(() => {
    if (createOpen && BranchId && edit) {
      getBranchDetails(BranchId as string)
    } else if (createOpen && BranchId && view) {
      getBranchDetails(BranchId as string)
    } else {
      methods.reset({
        BranchId: guid,
        Archived: false,
        CompanyId: CompanyId
      })
    }
  }, [createOpen, edit, BranchId, CompanyId, view])
  return (
    <FormProvider {...methods}>
      <DialogModal
        isOpen={createOpen}
        type="md"
        title={edit ? 'Edit Branch' : view ? 'View Branch' : 'Add Branch'}
        body={<BranchForm edit={!view} editMode={false} />}
        onClose={() => setCreateOpen(false)}
        actionLabel={edit ? 'Update' : view ? undefined : 'Save'}
        onSubmit={() => methods.handleSubmit(onSubmit)()}
        secondaryAction={() => setCreateOpen(false)}
        secondaryActionLabel={view ? 'close' : 'Cancel'}
        actionLoader={isSubmitting}
      />
    </FormProvider>
  )
}

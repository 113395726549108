import { FormBuilderProps } from '@/common/customComponenttypes'
import { CustomKendoTable, FormBuilder } from '@/components/common'
import { formContainerClass } from '@/styles/variables'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useGetBudgetItemColumns } from './BudgetItemColumns'

type BudgetItemFormFormProps = {
  viewable: boolean
  data: any
}

export const BudgetItemForm: React.FC<BudgetItemFormFormProps> = ({ viewable, data }) => {
  const { t } = useTranslation()
  const { watch, setValue } = useFormContext()
  const { BudgetDetails = [], Budget, SplitEqually } = watch()

  const onInit = () => {
    setValue('BudgetDetails', data?.BudgetDetails)
  }
  useEffect(() => {
    onInit()
  }, [data])

  useEffect(() => {
    handleMainBudgetChange(BudgetDetails)
  }, [Budget, SplitEqually])

  const handleMainBudgetChange = (budgetDetails: any) => {
    if (SplitEqually) {
      handleSplitEquallyBudget(budgetDetails)
    } else {
      resetBudgetDetails(budgetDetails)
    }
  }

  const handleSplitEquallyBudget = (budgetDetails: any) => {
    const totalVessel = budgetDetails?.length
    if (Budget > 0 && totalVessel > 0) {
      const vesselBudget = parseFloat((Budget / totalVessel).toFixed(2))
      equalSplitBudget(budgetDetails, vesselBudget, totalVessel)
    } else {
      equalSplitBudget(budgetDetails, 0, totalVessel)
    }
  }

  const equalSplitBudget = (
    budgetDetails: any,
    vesselBudget: number,
    totalVessel: number
  ) => {
    const remaining = Budget - vesselBudget * totalVessel
    const lastBudget = vesselBudget + remaining
    if (budgetDetails?.length > 0) {
      const data = budgetDetails?.map((item: any, index: number) =>
        index === totalVessel - 1
          ? {
              ...item,
              ...checkBreakUp(item, lastBudget),
              focus: {}
            }
          : {
              ...item,
              ...checkBreakUp(item, vesselBudget),
              focus: {}
            }
      )
      setValue('BudgetDetails', data)
    }
  }

  const checkBreakUp = (item: any, budget: number, budgetFocus?: boolean) => {
    if (item.SplitTypeId === 1) {
      return handleMonthlyBreakUp(item, budget, budgetFocus)
    } else if (item.SplitTypeId === 2) {
      return handleDailyBreakUp(item, budget, budgetFocus)
    } else {
      return { ...item, Budget: parseFloat(budget.toFixed(2)) }
    }
  }

  const handleMonthlyBreakUp = (item: any, budget: number, budgetFocus?: boolean) => {
    const StartMonth = new Date(item.StartDate).getMonth() + 1
    const EndMonth = new Date(item.EndDate).getMonth() + 1
    const MonthDiff = EndMonth - StartMonth + 1
    const BrBudgetVal = budget / MonthDiff
    const BrBudget = parseFloat(BrBudgetVal.toFixed(2))
    const BrBudgetProd = BrBudget * MonthDiff
    const BrBudgetDiff = budget - BrBudgetProd
    const LastBrBudget = BrBudget + BrBudgetDiff

    item['StartMonth'] = StartMonth
    item['EndMonth'] = EndMonth
    item['MonthDiff'] = MonthDiff
    item['LastBrBudget'] = parseFloat(LastBrBudget.toFixed(2))
    item['BrBudget'] = BrBudget
    item['Budget'] = budgetFocus ? budget : parseFloat(budget.toString()).toFixed(2)

    const lastMonth = 'Amount_' + ('0' + EndMonth).slice(-2)
    for (let i = StartMonth; i <= EndMonth; i++) {
      let Months = 'Amount_' + ('0' + i).slice(-2)
      if (Months === lastMonth) {
        item[lastMonth] = parseFloat(LastBrBudget.toString()).toFixed(2)
      } else {
        item[Months] = parseFloat(BrBudget.toFixed(2))
      }
    }
    return item
  }

  const handleDailyBreakUp = (item: any, budget: number, budgetFocus?: boolean) => {
    const StartMonth = new Date(item.StartDate).getMonth() + 1
    const EndMonth = new Date(item.EndDate).getMonth() + 1
    const Year = new Date(item.StartDate).getFullYear()
    const StartDate = new Date(item.StartDate)
    const FirstMonthLast = new Date(StartDate.getFullYear(), StartDate.getMonth() + 1, 0)
    const EndDate = new Date(item.EndDate)
    const lastMonthFirst = new Date(EndDate.getFullYear(), EndDate.getMonth(), 1)

    const FirstMonthDifference =
      Math.ceil(
        Math.abs(FirstMonthLast.getTime() - StartDate.getTime()) / (1000 * 60 * 60 * 24)
      ) + 1
    const LastMonthDifference =
      Math.ceil(
        Math.abs(EndDate.getTime() - lastMonthFirst.getTime()) / (1000 * 60 * 60 * 24)
      ) + 1

    const OneDayBudget = budget / item.Days
    item['OneDayBudget'] = OneDayBudget
    item['LastMonthDifference'] = LastMonthDifference
    item['FirstMonthDifference'] = FirstMonthDifference
    item['StartMonth'] = StartMonth
    item['EndMonth'] = EndMonth
    item['Year'] = Year
    item['Budget'] = budgetFocus ? budget : parseFloat(budget.toFixed(2))
    const FirstMonthBudget = parseFloat((FirstMonthDifference * OneDayBudget).toFixed(2))

    const lastMonth = 'Amount_' + ('0' + EndMonth).slice(-2)
    let total = 0
    for (let i = StartMonth; i <= EndMonth; i++) {
      let Months = 'Amount_' + ('0' + i).slice(-2)
      let firstMonth = 'Amount_' + ('0' + StartMonth).slice(-2)

      if (Months === firstMonth) {
        item[firstMonth] = FirstMonthBudget
        total += FirstMonthBudget
      } else {
        let BudgetMonth = parseFloat(
          (new Date(Year, i, 0).getDate() * OneDayBudget).toFixed(2)
        )
        if (Months === lastMonth) {
          BudgetMonth = parseFloat((budget - total).toFixed(2))
        }
        item[Months] = BudgetMonth
        total += BudgetMonth
      }
    }

    return item
  }

  const handleChange = (
    key: string,
    row: any,
    e: React.ChangeEvent<HTMLInputElement>,
    focus: boolean
  ) => {
    const { value } = e.target
    let hasChanged = false
    BudgetDetails?.forEach((item: any) => {
      if (item.BudgetEntityId === row.BudgetEntityId && item[key] !== value) {
        if (parseFloat(item[key]) !== parseFloat(value)) {
          hasChanged = true
        }
      }
    })
    if (hasChanged || focus) {
      const newArray = BudgetDetails?.map((item: any) =>
        item.BudgetEntityId === row.BudgetEntityId
          ? {
              ...item,
              [key]: value && value !== '' ? value : null,

              ...(key === 'Budget' ? checkBreakUp(item, parseFloat(value), focus) : {}),
              focus: { [key]: focus }
            }
          : { ...item, focus: {} }
      )
      setValue('BudgetDetails', newArray)
    }
  }

  const handleDropChange = (key: string, id: string, row: any, e: any) => {
    const newArray = BudgetDetails?.map((item: any) =>
      item.BudgetEntityId === row.BudgetEntityId
        ? {
            ...item,
            [key]: e?.name && e?.name !== '' ? e?.name : undefined,
            [id]: e?.id && e?.id !== '' ? e?.id : undefined
          }
        : { ...item }
    )
    handleMainBudgetChange(newArray)
  }

  const clearFocus = (e: any) => {
    resetBudgetDetails(BudgetDetails)
  }

  const resetBudgetDetails = (budgetDetails: any) => {
    if (budgetDetails?.length > 0) {
      const data = budgetDetails?.map((item: any) => ({
        ...item,
        focus: {}
      }))
      setValue('BudgetDetails', data)
    }
  }

  const formFields: FormBuilderProps[] = [
    {
      label: t('Item Description'),
      placeholder: t('Enter item description'),
      name: 'ItemDescription',
      id: 'ItemDescription',
      type: 'text',
      required: true,
      spacing: 4,
      maxLength: 100,
      callbackFn: clearFocus
    },
    {
      label: t('Budget'),
      placeholder: t('Enter budget'),
      name: 'Budget',
      id: 'Budget',
      type: 'text',
      required: true,
      spacing: 4,
      maxLength: 10,
      keyRegexPattern: '^(\\d*\\.?\\d{0,2}|\\d+\\.\\d{0,2})$',
      callbackFn: clearFocus
    },
    {
      label: t('Split Equally'),
      name: 'SplitEqually',
      id: 'SplitEqually',
      required: false,
      type: 'switch',
      spacing: 4
    }
  ]
  const itemColums = useGetBudgetItemColumns({
    handleChange: handleChange,
    BudgetDetails: BudgetDetails,
    handleDropChange: handleDropChange,
    viewable: viewable,
    SplitEqually: SplitEqually
  })

  return (
    <>
      <div className={formContainerClass}>
        <FormBuilder data={formFields} edit={!viewable} />
      </div>
      <div className="w-full pt-5">
        <p className="text-dark text-m leading-[20px] font-medium">
          Vessel wise Break-up
        </p>

        <div className="pt-5">
          {BudgetDetails?.length > 0 && (
            <CustomKendoTable
              columns={itemColums}
              data={BudgetDetails}
              async={true}
              pagination={false}
              title=""
              toolBar={false}
              sortable={false}
              resizable={true}
            />
          )}
        </div>
      </div>
    </>
  )
}

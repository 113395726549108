import { FormBuilderProps } from '@/common/customComponenttypes'

export const getFormErrorMessage = (errors: any, data: any) => {
  const errorFields = Object.keys(errors)
    .map((error) =>
      data.find((field: FormBuilderProps) => field.name === error || field?.id === error)
    )
    .filter(Boolean)
  const uniqueErrorLabels = Array.from(new Set(errorFields.map((field) => field.label)))
  const errorLabels = uniqueErrorLabels.join(', ')
  return errorLabels
}

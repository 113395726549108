import React, { useEffect, useRef, useState } from 'react'
import CustomIcons from '../icons/customIcons'

type Props = {
  onChange: (data: any) => void
  value?: any
  items?: any[]
  minDate?: Date
  maxDate?: Date
  className?: string
  optionContainerClassName?: string
  optionClassName?: string
}
export default function ModernCustomSelectWithArrow(props: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const selectRef = useRef<HTMLDivElement>(null)
  const optionContainerRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <>
      {' '}
      <div className={props.className} ref={selectRef}>
        <button
          type="button"
          onClick={(e) => {
            setIsOpen((prev) => !prev)
          }}
          className={` flex cursor-auto ps-1.5 ${
            isOpen
              ? 'bg-background-modern-datepicker-dropdown-opened text-text-modern-datepicker-dropdown-opened'
              : 'bg-background-modern-datepicker-dropdown-closed text-text-modern-datepicker-dropdown-closed'
          }`}
        >
          {' '}
          <span className="font-semibold">{props.value}</span>
          <CustomIcons
            className="my-auto p-0 ps-0.5 pe-0.5"
            name={isOpen ? 'menu-up-arrow' : 'menu-down-arrow'}
            type="large"
          />
        </button>
        <div
          ref={optionContainerRef}
          className={`absolute max-h-[324px] scroll-smooth shadow-lg shadow-shadow-modern-datepicker-dropdown-container 
          pt-1.5 pb-1.5 pe-0 m-0  z-input-dropdown-level-2 overflow-auto ${
            isOpen ? 'block' : 'hidden'
          } ${props.optionContainerClassName}`}
        >
          {props.items?.map((option: any) => (
            <option
              className={`cursor-default text-[12px] font-normal text-left ${props.optionClassName}`}
              key={option}
              value={option}
              onClick={() => {
                props.onChange({ target: { value: option } })
                setIsOpen(false)
              }}
            >
              {option}
            </option>
          ))}
        </div>
      </div>
    </>
  )
}

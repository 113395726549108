import { HeadertitleProps } from '@/common/customComponenttypes'
import React from 'react'
const Headertitle: React.FC<HeadertitleProps> = ({ title }) => {
  return (
    <>
      <div className="absolute top-[58px] md:top-0 px-4 md:px-0 md:pt-4 h-[41px] border-y border-grey-light md:border-none md:h-auto   md:bg-transparent md:w-auto w-full bg-white flex items-center">
        <h1 className="md:ml-[15px] text-secondary text-xl font-medium leading-6 tracking-wide">
          {title}
        </h1>
      </div>
    </>
  )
}

export default Headertitle

import { ActionDropDown } from '@/common/commontypes'
import CreateBranch from '@/components/app/masterData/company/branch/CreateBranch'

import ManageConfirmMessage from './confirmationDialog'
import ResetPassword from './resetPassword'
import StatusUpdate from './StatusUpdate'

type Props = {
  confirmData: {
    actionData: ActionDropDown
    isOpen: boolean
    primaryApi: string
    method: string
  }
  rowData?: any
  handleRefresh?: (id?: string) => void
  handleconfrimClose: () => void
  handleStatusAction: (api: string, method: string, data: ActionDropDown) => void
  actionLoader?: boolean
}
export default function SwitchComponent({
  confirmData,
  handleStatusAction,
  handleconfrimClose,
  handleRefresh,
  actionLoader,
  rowData
}: Props) {
  const { actionData, isOpen, primaryApi, method } = confirmData

  switch (actionData.CompPopup) {
    case 'WfObjStatus':
      return (
        <StatusUpdate
          openAction={isOpen}
          actionData={actionData}
          actionLoader={actionLoader}
          handleConfirmClose={() => handleconfrimClose()}
          handleRefresh={handleRefresh}
        />
      )
    case 'ResetPswd':
      return (
        <ResetPassword
          openAction={isOpen}
          rowData={rowData}
          handleConfirmClose={() => handleconfrimClose()}
          handleRefresh={handleRefresh}
        />
      )
    case 'edit_branch':
      return (
        <CreateBranch
          createOpen={true}
          setCreateOpen={() => handleconfrimClose()}
          edit={true}
          //CompanyId={'CompanyId'}
          BranchId={actionData.Data}
        />
      )
    default:
      return (
        <ManageConfirmMessage
          openAction={isOpen}
          primaryApi={primaryApi}
          handleconfrimClose={() => handleconfrimClose()}
          method={method}
          handleStatusAction={(api, method) =>
            handleStatusAction(api, method, actionData)
          }
          actionData={actionData}
          actionLoader={actionLoader}
        />
      )
  }
}

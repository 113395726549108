import { ModulesRouteProps } from '@/common/commontypes'
import { RoleEditPrivileges } from '@/components/app/system/roles/EditPrivileges'
import { SystemRolesUserList } from '@/pages/system/roles/users'
import { routerStore } from '@/store'
import { lazy } from 'react'

const SystemFunctions = lazy(() => import('@/pages/system/functions'))
const SystemRolesList = lazy(() => import('@/pages/system/roles'))
const SystemUserList = lazy(() => import('@/pages/system/users'))
const SystemConfigList = lazy(() => import('@/pages/system/configurations'))
const SystemAuditLogs = lazy(() => import('@/pages/system/auditlogs'))
const SystemRoleDetails = lazy(() => import('@/pages/system/roles/details'))
const SystemUserDeligationList = lazy(() => import('@/pages/system/userdeligations'))
// const SystemRolesUserList = lazy(() => import('@/pages/system/roles/users'))

const systemRoutes: ModulesRouteProps[] = [
  {
    slug: 'SYSTEM_ROLES',
    path: routerStore.ROLES_LIST,
    component: <SystemRolesList />
  },
  {
    slug: 'SYSTEM_ROLES_DETAILS',
    path: routerStore.ROLES_DETAILS,
    component: <SystemRoleDetails />,
    children: [
      {
        slug: 'SYSTEM_ROLES_DETAILS_USERS',
        path: routerStore.ROLES_DETAIL_USERS,
        component: <SystemRolesUserList />
      },
      {
        slug: 'SYSTEM_ROLES_DETAILS_PRIVILEGE',
        path: routerStore.ROLES_DETAIL_PRIVILEGE,
        component: <RoleEditPrivileges />
      }
    ]
  },
  {
    slug: 'SYSTEM_USERS',
    path: routerStore.SYS_USER_LIST,
    component: <SystemUserList />
  },
  {
    slug: 'SYSTEM_FUNCTIONS',
    path: routerStore.SYS_FUNTION_LIST,
    component: <SystemFunctions />
  },
  {
    slug: 'SYSTEM_CONFIGS',
    path: routerStore.SYS_CONF_LIST,
    component: <SystemConfigList />
  },
  {
    slug: 'SYSTEM_AUDITLOGS',
    path: routerStore.SYS_AUDIT_LOGS,
    component: <SystemAuditLogs />
  },
  {
    slug: 'SYSTEM_USER_DELIGATIONS',
    path: routerStore.SYSTEM_USER_DELIGATIONS,
    component: <SystemUserDeligationList />
  }
]

export default systemRoutes

import React, { useEffect, useRef, useState } from 'react'
import { Portal } from '../../common'
import CustomIcons from '../icons/customIcons'

type Props = {
  iconName: string
  disabled?: boolean
  rows?: any
  iconClass?: string
  scrollRef?: any
  updateMenuRef?: (ref: HTMLDivElement | null) => void
  position?: string
  actionDropDown?: {
    title: string
    slug: string
    isWarning?: boolean
    iconName: string
    hidden?: boolean
    id: string
    menuIconClass?: string
    hide?: (rowData?: any) => boolean
  }[]
  handleMenuActions?: (slug: string, rowData?: any) => void
  isMouseOut?: boolean
}

export default function KendoDropDown({
  iconName,
  iconClass,
  scrollRef,
  actionDropDown,
  disabled = false,
  updateMenuRef,
  handleMenuActions,
  position,
  isMouseOut,
  rows
}: Props) {
  const [openMenu, setOpenMenu] = useState(false)
  const menuButtonRef = useRef<HTMLButtonElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const dropRef = useRef<HTMLDivElement>(null)
  // const [dropdownPoint, setDropdownPoint] = useState({ x: 0, y: 0 })
  const [dropdownPosition, setDropdownPosition] = useState('bottom-position')
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  })

  const adjustDropdownPoint = (e: any) => {
    if (!openMenu && menuButtonRef.current) {
      const rect = menuButtonRef.current.getBoundingClientRect()
      const topSpace = rect.top
      const bottomSpace = window.innerHeight - rect.bottom
      const leftSpace = rect.left
      const rightSpace = window.innerWidth - rect.right

      const dropdownHeight =
        actionDropDown?.length && actionDropDown?.length > 0
          ? actionDropDown?.length * 50
          : 200
      const dropdownWidth = 200

      let newPosition = 'bottom-right'

      if (bottomSpace < dropdownHeight && topSpace > dropdownHeight) {
        newPosition = 'top-right'
      }

      if (rightSpace < dropdownWidth && leftSpace > dropdownWidth) {
        newPosition = newPosition.replace('right', 'left')
      }

      if (topSpace < dropdownHeight && bottomSpace < dropdownHeight) {
        newPosition = leftSpace > rightSpace ? 'bottom-left' : 'bottom-right'
      }

      setDropdownPosition(newPosition)
      setMenuPosition({
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
        bottom: window.innerHeight - rect.bottom,
        right: window.innerWidth - rect.right
      })
    }
    setTimeout(() => {
      setOpenMenu(!openMenu)
    }, 200)
    /*
    const position = e.target.getBoundingClientRect()
    const x = position.left - 10
    const y = position.bottom + 10


    let adjustedX = x
    let adjustedY = y
  
    setDropdownPoint({ x: adjustedX, y: adjustedY })*/
  }

  const handleScroll = () => {
    setOpenMenu(false)
    removeHoverClass()
  }

  const removeHoverClass = () => {
    const rows = scrollRef.current?.querySelectorAll('tr')
    if (rows) {
      rows.forEach((row: any) => {
        if (row.classList) row.classList.remove('k-hover')
      })
    }
  }

  const addHoverClassDisable = () => {
    const rows = scrollRef.current?.querySelectorAll('tr')
    if (rows) {
      rows.forEach((row: any) => {
        if (row.classList) row.classList.add('k-disabled-hover')
      })
    }

    setTimeout(() => {
      removeHoverClassDisable()
    }, 5000)
  }

  const removeHoverClassDisable = () => {
    const rows = scrollRef.current?.querySelectorAll('tr')
    if (rows) {
      rows.forEach((row: any) => {
        if (row.classList) row.classList.remove('k-disabled-hover')
      })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    if (scrollRef && scrollRef.current && scrollRef.current !== null) {
      scrollRef.current.addEventListener('scroll', handleScroll)
    }
    const mainElement = document.querySelector('main')
    mainElement?.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (scrollRef && scrollRef.current) {
        scrollRef.current?.removeEventListener('scroll', handleScroll)
      }

      const mainElement = document.querySelector('main')
      mainElement?.removeEventListener('scroll', handleScroll)
    }
  }, [openMenu])

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpenMenu(false)
      removeHoverClass()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const toggleMenu = (e: any) => {
    //setOpenMenu(!openMenu)
    adjustDropdownPoint(e)
    const ele = e.currentTarget
    removeHoverClass()
    setTimeout(() => {
      ele.closest('tr').classList.toggle('k-hover')
    }, 500)
  }

  useEffect(() => {
    if (isMouseOut) {
      document.addEventListener('mouseout', handleClickOutside)
      return () => {
        document.removeEventListener('mouseout', handleClickOutside)
      }
    }
  }, [isMouseOut])

  useEffect(() => {
    window.addEventListener('mousemove', removeHoverClassDisable)
    return () => {
      window.removeEventListener('mousemove', removeHoverClassDisable)
    }
  }, [])

  const handleMenuItemClick = (slug: string, rowData?: any) => {
    const data = { ...rows, dropData: rowData }
    handleMenuActions?.(slug, data)
    addHoverClassDisable()
    setOpenMenu(false)
  }
  useEffect(() => {
    if (updateMenuRef) {
      updateMenuRef(menuRef.current)
    }
  }, [menuRef])

  const buttonWidth = menuButtonRef.current ? menuButtonRef.current.offsetWidth : 0

  return (
    <div className="" ref={menuRef}>
      <button
        className={`dropdown relative `}
        disabled={disabled}
        onClick={(e) => toggleMenu(e)}
        ref={menuButtonRef}
      >
        <CustomIcons type="large" name={iconName} className={iconClass} />
      </button>
      {openMenu && (
        <Portal>
          <div
            ref={dropRef}
            className={`custom-dropdown-menu dropdown-menu  ${dropdownPosition}  absolute ${
              position ? position : 'right-0'
            } mt-2 w-max  shadow-lg bg-white focus:outline-none `}
            style={{
              top: dropdownPosition.startsWith('bottom')
                ? `${menuPosition.top + window.scrollY + 30}px`
                : 'auto',
              bottom: dropdownPosition.startsWith('top')
                ? `${menuPosition.bottom + 30}px`
                : 'auto',
              left: dropdownPosition.endsWith('right')
                ? `${menuPosition.left + 0}px`
                : 'auto',
              right: dropdownPosition.endsWith('left')
                ? `${window.innerWidth - menuPosition.left - buttonWidth + 10}px`
                : 'auto'
            }}
          >
            <div className="py-2 bg-white  w-full shadow-menudropdown">
              {actionDropDown?.map((item) => (
                <React.Fragment key={item.id}>
                  {!item?.hidden && !item?.hide?.(rows) && (
                    <a
                      key={item.id}
                      href="#/"
                      className={`px-3 py-2.5 leading-7 hover:bg-table-hover hover:text-secondary `}
                      onClick={(e) => {
                        e.preventDefault()
                        handleMenuItemClick(item.slug, item)
                      }}
                    >
                      <div
                        className={`${
                          item.isWarning ? 'text-error-light' : ''
                        } text-common flex items-center w-full tracking-[0.24px] font-normal leading-4 `}
                      >
                        <CustomIcons
                          name={item.iconName}
                          type="large"
                          className={`${
                            item.isWarning ? 'text-error-light' : ''
                          } text-center ${item.menuIconClass}`}
                        />
                        <span className="pl-2">{item.title}</span>
                      </div>
                    </a>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </Portal>
      )}
    </div>
  )
}

import DynamicActionMenu from '@/components/common/dynamicMenu'
import MenuDropDown from '@/components/common/menudropdown/MenuDropDown'
import { generateSpacing } from '@/utilities'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import React, { useRef, useState } from 'react'

import CustomIcons from '../../common/icons/customIcons'

type SpacingProps = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type TypeProps = 'title' | 'subTitle' | 'status' | 'desc' | 'mode1'
export type MainContentProps = {
  data: string | number | React.ReactElement
  type?: TypeProps
  textColor?: string
  backgroundColor?: string
}

export type SubContentProps = {
  label: string
  value: string | number | React.ReactElement | null | undefined
  spacing: SpacingProps
  hasDivider?: boolean
  position?: number
}
type CustomDetailHeaderComponentProps = {
  detailHeaders?: MainContentProps[]
  detailContents: SubContentProps[]
  handleIconName?: string
  handleMenuActions?: (slug: string, rowData?: any) => void

  isLoading?: boolean
  singleRow?: boolean
  actionDropDown?: {
    title: string
    slug: string
    iconName: string
    id: string
    isWarning?: boolean
  }[]
  workflowParams?: {
    isDynamicMenu?: boolean
    handleMenuItems?: (data: any) => any
    handleRefresh: (id?: any) => void
  }
  detailId?: string
  avatar?: string
  onchange?: any
  bgClassName?: string
  enableBack?: boolean
  handleBackClick?: () => any
  seperator?: string
}
const CustomDetailHeaderComponent: React.FC<CustomDetailHeaderComponentProps> = ({
  detailHeaders,
  actionDropDown,
  handleIconName,
  handleMenuActions,
  detailContents,
  avatar,
  workflowParams,
  detailId,
  isLoading,
  singleRow = false,
  onchange,
  bgClassName = 'bg-text-primary',
  enableBack = false,
  handleBackClick,
  seperator
}) => {
  const [showAll, setShowAll] = useState<boolean>(false)

  const generateClassName = (type?: string) => {
    let className = ' text-xxs font-medium '
    const chipclasses =
      'px-2.5 py-[5px] rounded-[3px] leading-3 font-medium text-white text-xxs font-medium leading-3 '
    switch (type) {
      case 'title':
        className = 'text-white font-semibold text-l md:text-l '
        break
      case 'subTitle':
        className = 'text-white font-medium text-s'
        break
      case 'status':
        className = chipclasses + 'outline  outline-[1px]  outline-white '
        break
      case 'desc':
        className = chipclasses + ' bg-primary  '
        break
      case 'mode1':
        className = chipclasses + ' bg-[#DBF4FB1a]  '
        break
      default:
        className = chipclasses + ' bg-primary  '
        break
    }
    return className
  }
  const isLastChild = (index: number, length: number) => {
    if (index === length - 1) {
      return true
    }
    return false
  }
  const { isMobile, isTab } = useScreenBreakPoint()
  let totalSpacing = 0

  const imageRef = useRef<HTMLInputElement>(null)

  const handleBack = () => {
    if (handleBackClick) handleBackClick?.()
  }

  const getSeperator = () => {
    switch (seperator) {
      case 'small':
        return 'h-4 bg-white mx-2'
      default:
        return 'h-[22px] bg-[#85888E] mx-5'
    }
  }

  return (
    <>
      {isLoading ? (
        <div className=" flex h-full mb-3">
          <div className=" w-full">
            <div
              className={`${
                avatar ? 'pl-[165px]' : 'pl-0'
              } w-full flex justify-between items-center  h-12 bg-background-skeltonLoader `}
            >
              <div className=" w-full">
                <div className="flex  w-full  items-center justify-between ml-2">
                  <div className=" flex gap-[10px]">
                    <span className=" custom-skelton-gradient w-[111px] h-[22px] rounded-[20px]"></span>
                    <span className=" border-r border-white"></span>
                    <span className="custom-skelton-gradient w-[111px] h-[22px] rounded-[20px]"></span>
                  </div>
                  <div className=" flex">
                    <span className="flex bg-white w-6 h-6 rounded-[20px] mr-4"></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:flex flex-row w-full">
              {avatar && (
                <div className="h-full  hidden gap-2 md:flex items-center justify-center ">
                  <div className="h-full -mt-5 ml-6">
                    <span className=""></span>
                    <div className="rounded-[70px] w-[115px] h-[115px] border-white border-[6px] custom-skelton-gradient object-none  "></div>
                  </div>
                </div>
              )}
              <div className={`w-full grid grid-cols-12`}>
                <div className={isMobile || isTab ? 'col-span-10' : 'col-span-12'}>
                  <div className={`grid grid-cols-12  w-full pl-5 `}>
                    {detailContents
                      ?.slice(0, isMobile || isTab ? 1 : detailContents.length)
                      ?.map((content: any, i: any) => {
                        content['position'] = totalSpacing + content.spacing
                        totalSpacing = totalSpacing + content.spacing
                        return (
                          <div
                            key={`subcontent-${i}`}
                            className={`${generateSpacing(
                              isTab ? 10 : content.spacing
                            )} text-xxs ${' pt-2.5'}`}
                          >
                            <div
                              className={`${
                                content.hasDivider || content.position % 12 === 0
                                  ? 'px-3'
                                  : ' px-3'
                              } ${content.hasDivider ? 'pt-2.5' : 'pt-2.5'}${
                                i !== 0 && (isMobile || isTab) ? 'hidden' : ''
                              } `}
                            >
                              <div className="custom-skelton-gradient w-[55px] h-[10px] rounded-[30px]"></div>
                              <div className="custom-skelton-gradient w-[85px] h-[14px] mt-2 rounded-[30px]"></div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`flex h-full  ${!singleRow && 'mb-3'}`}>
          <div className="w-full">
            {detailHeaders?.length && (
              <div
                className={`${
                  avatar ? 'pl-[170px]' : 'pl-0'
                } w-full flex justify-between items-center h-12 ${bgClassName} `}
              >
                <div>
                  <div
                    className={`flex  w-full ${!singleRow && 'leading-[16.39px]'}  tracking-[0.3px] items-center`}
                  >
                    {enableBack && (
                      <button className={`ml-5 text-white mr-3`} onClick={handleBack}>
                        <CustomIcons
                          type={'large'}
                          name={'BackCircle'}
                          viewBox={true}
                          custDimension={30}
                        />
                      </button>
                    )}
                    {detailHeaders?.map((content, i) => (
                      <React.Fragment key={`main-content-${i}`}>
                        <div className={` h-[22px] flex justify-center items-center`}>
                          <div
                            className={`${generateClassName(content.type)} items-center `}
                            style={{
                              color: content.type === 'status' ? content.textColor : '',
                              backgroundColor:
                                content.type === 'status' ? content.backgroundColor : '',
                              display: 'flex'
                            }}
                          >
                            {content.type === 'mode1' ? (
                              <CustomIcons
                                name="caption"
                                className="mr-2"
                                type="small"
                                // onClick={() => setIsMyVesselOpen(!isMyVesselOpen)}
                              />
                            ) : null}
                            {content.type === 'status' ? (
                              <CustomIcons
                                name="Ship_Icon"
                                className="mr-2"
                                type="small"
                                // onClick={() => setIsMyVesselOpen(!isMyVesselOpen)}
                              />
                            ) : null}

                            {content.data || '----'}
                          </div>
                        </div>
                        {isLastChild(i, detailHeaders.length) ? (
                          <></>
                        ) : (
                          <div className={`w-[1px] ${getSeperator()}`} />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                {workflowParams?.isDynamicMenu ? (
                  <td className=" group-hover:bg-table-hover z-9">
                    <div className="flex items-center justify-center w-[20px] group-hover:bg-table-hover pr-5 pt-3 pb-3">
                      <DynamicActionMenu
                        iconName={handleIconName ?? 'hamburger'}
                        iconClass="text-white"
                        iconSize=""
                        handleMenuItems={() =>
                          workflowParams?.handleMenuItems?.(detailId)
                        }
                        handleRefresh={(id) => workflowParams?.handleRefresh(id)}
                        fromTable={false}
                      />
                    </div>
                  </td>
                ) : (
                  <>
                    {actionDropDown && handleMenuActions && (
                      <div className="pr-5 pt-3 pb-3">
                        <MenuDropDown
                          fromTable={false}
                          iconName={handleIconName ?? 'hamburger'}
                          iconClass="text-white hover:text-dropdown-primary"
                          iconSize="large-m"
                          actionDropDown={actionDropDown}
                          handleMenuActions={handleMenuActions}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <div className="md:flex flex-row w-full">
              {avatar && (
                <div className="col-span-7">
                  <div className="h-full  hidden gap-2 md:flex items-center justify-center ">
                    <div className="h-full">
                      <div className=" relative w-[115px] h-[115px] rounded-full  -mt-7 ml-6">
                        <img
                          src={avatar}
                          alt="Avatar"
                          className="rounded-[70px] w-[115px] h-[115px] border-white border-[6px] bg-[#EAEFF4] object-cover "
                        />
                        {onchange && (
                          <div
                            onClick={() => imageRef.current?.click()}
                            className="absolute right  bottom-1 cursor-pointer right-2.5 rounded-full w-[26px] h-[26px] bg-white border border-[#DDDEE0] flex items-center justify-center "
                          >
                            <div className="tooltip-container">
                              <CustomIcons
                                name={'camera'}
                                type={'large'}
                                className="flex items-center justify-center text-[#667085]"
                              />
                              <span className="tooltip min-w-[300px]">
                                Should not exceed 300 KB.
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    ref={imageRef}
                    accept=".png,.gif,.jpeg,.jpg"
                    style={{ display: 'none' }}
                    onChange={onchange}
                  />
                </div>
              )}

              {/* <div className="col-span-7">
                <div className="flex justify-center items-center">
                  <div className=" relative w-[81px] h-[81px] rounded-full top-2">
                    <img
                      src={preview || '/assets/avatar_empty.jpeg'}
                      className="w-[81px]  h-[81px] rounded-full cover object-cover"
                      alt="avatar"
                    />
                    {!avatar && (
                      <div
                        onClick={() => imageRef.current?.click()}
                        className="absolute right  bottom-1 cursor-pointer right-[-15px] rounded-full w-[37px] h-[37px] bg-white border border-[#DDDEE0] flex items-center justify-center "
                      >
                        <div className="tooltip-container">
                          <CustomIcons
                            name={'camera'}
                            type={'large'}
                            className="flex items-center justify-center"
                          />
                          <span className="tooltip min-w-[300px]">
                            Should not exceed 300 KB.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <input
                  type="file"
                  ref={imageRef}
                  accept=".png,.gif,.jpeg,.jpg"
                  // style={{ display: 'none' }}
                  onChange={handleChangeFile}
                />
              </div> */}

              {detailHeaders?.length ? (
                <div className={`w-full grid grid-cols-12`}>
                  <div
                    className={`${isMobile || isTab ? 'col-span-10' : 'col-span-12'} ${singleRow && ' flex items-center'} `}
                  >
                    <div
                      className={`grid grid-cols-12  w-full pl-5 ${avatar ? '' : 'pt-2'} `}
                    >
                      {detailContents
                        ?.slice(0, isMobile || isTab ? 1 : detailContents.length)
                        ?.map((content: any, i: any) => {
                          content['position'] = totalSpacing + content.spacing
                          totalSpacing = totalSpacing + content.spacing
                          return (
                            <div
                              key={`subcontent-${i}`}
                              className={`${generateSpacing(
                                isTab ? 10 : content.spacing
                              )} text-xxs ${!singleRow && avatar && ' pt-2.5'}`}
                            >
                              <div
                                className={`${
                                  content.hasDivider || content.position % 12 === 0
                                    ? 'px-3'
                                    : ' px-3'
                                } ${content.hasDivider ? 'pt-2.5' : 'pt-2.5'}${
                                  i !== 0 && (isMobile || isTab) ? 'hidden' : ''
                                } `}
                              >
                                <div
                                  className={`text-text-secondary text-xxs  font-medium  ${!singleRow && 'leading-[16.39px]'}   tracking-[0.24px]`}
                                >
                                  {content.label}
                                </div>
                                <div
                                  className={`text-text-primary text-common truncate font-semibold ${!singleRow && 'leading-[16.39px]'}  pt-1 tracking-[0.24px]`}
                                >
                                  {content.value || 'Unavailable'}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                  {(isMobile || isTab) && (
                    <div className={` col-span-2 flex justify-center items-center`}>
                      <div>
                        <p className="font-medium text-[14px] text-text-more">
                          {!showAll ? 'More Details' : 'Hide Details'}
                        </p>
                      </div>
                      <div>
                        <span
                          className="text-[#040404]"
                          onClick={() => setShowAll(!showAll)}
                        >
                          <CustomIcons name={!showAll ? 'down_arrow' : 'up_arrow'} />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className={`flex w-full pl-5 gap-[118px] `}>
                  {detailContents?.map((content: any, i: any) => {
                    content['position'] = totalSpacing + content.spacing
                    totalSpacing = totalSpacing + content.spacing
                    return (
                      <div
                        key={`subcontent-${i}`}
                        className={`${generateSpacing(
                          isTab ? 10 : content.spacing
                        )} text-xxs ${!singleRow && avatar ? ' pt-2.5' : 'pt-2.5 -mb-2.5'}`}
                      >
                        <div
                          className={`${
                            content.hasDivider || content.position % 12 === 0
                              ? 'px-3'
                              : ' px-3'
                          }`}
                        >
                          <div
                            className={`text-text-secondary  font-medium ${!singleRow && 'leading-[16.39px]'}  tracking-[0.24px]`}
                          >
                            {content.label}
                          </div>
                          <div
                            className={`text-[#003C71] truncate font-semibold ${!singleRow && 'leading-[16.39px]'}  pt-1 tracking-[0.24px]`}
                          >
                            {content.value || 'Unavailable'}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
            <>
              {(isMobile || isTab) &&
                showAll &&
                detailContents
                  ?.slice(1, detailContents.length)
                  ?.map((content: any, i: any) => {
                    content['position'] = totalSpacing + content.spacing
                    totalSpacing = totalSpacing + content.spacing
                    return (
                      <div
                        key={`subcontent-${i}`}
                        className={`${generateSpacing(
                          isTab ? 10 : content.spacing
                        )} text-xxs ${'pt-2.5'}  `}
                      >
                        <div className="ml-[16px] mr-[16px] border-t-[1.5px] border-[#DBDBDD] ">
                          <div className="text-text-secondary text-xxs  font-normal leading-[16.39px] tracking-[0.24px] pt-[13px] pl-[10px] ">
                            {content.label}
                          </div>
                          <div className="text-[#003C71] text-common truncate font-semibold leading-[16.39px] pt-2 tracking-[0.24px] pb-[4px] pl-[10px]">
                            {content.value || 'Unavailable'}
                          </div>
                        </div>
                      </div>
                    )
                  })}
            </>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomDetailHeaderComponent

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const EditFabIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M14.5882 22H21.5882H14.5882ZM19.5294 13.7647L22 11.2941L18.7059 8L16.2353 10.4706M19.5294 13.7647L11.2941 22H8V18.7059L16.2353 10.4706M19.5294 13.7647L16.2353 10.4706L19.5294 13.7647Z"
        fill="currentColor"
      />
      <rect x="14" y="22" width="8" height="1" fill="currentColor" />
    </svg>
  )
}

export default EditFabIcon

const ReportGroupIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" />

        <path
          d="M15.5385 3.53845H17.8462C18.2542 3.53845 18.6455 3.70054 18.934 3.98906C19.2225 4.27757 19.3846 4.66889 19.3846 5.07691V20.4615C19.3846 20.8696 19.2225 21.2609 18.934 21.5494C18.6455 21.8379 18.2542 22 17.8462 22H5.53846C5.13044 22 4.73912 21.8379 4.4506 21.5494C4.16209 21.2609 4 20.8696 4 20.4615V5.07691C4 4.66889 4.16209 4.27757 4.4506 3.98906C4.73912 3.70054 5.13044 3.53845 5.53846 3.53845H7.84615"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          d="M14.0002 2H9.3848C8.97678 2 8.58546 2.16209 8.29694 2.45061C8.00843 2.73912 7.84634 3.13044 7.84634 3.53846V4.30769C7.84634 4.71572 8.00843 5.10703 8.29694 5.39555C8.58546 5.68407 8.97678 5.84615 9.3848 5.84615H14.0002C14.4082 5.84615 14.7995 5.68407 15.088 5.39555C15.3766 5.10703 15.5386 4.71572 15.5386 4.30769V3.53846C15.5386 3.13044 15.3766 2.73912 15.088 2.45061C14.7995 2.16209 14.4082 2 14.0002 2ZM10.6202 9.24616C10.4447 9.24616 10.2765 9.31585 10.1524 9.43992C10.0283 9.56398 9.95865 9.73224 9.95865 9.90769V11.7646H8.10172C7.92627 11.7646 7.75801 11.8343 7.63395 11.9584C7.50988 12.0824 7.44019 12.2507 7.44019 12.4262V14.5723C7.44019 14.9369 7.73711 15.2338 8.10172 15.2338H9.95865V17.0908C9.95865 17.4554 10.2556 17.7523 10.6202 17.7523H12.7663C12.9418 17.7523 13.1101 17.6826 13.2341 17.5586C13.3582 17.4345 13.4279 17.2662 13.4279 17.0908V15.2308H15.2848C15.4603 15.2308 15.6285 15.1611 15.7526 15.037C15.8766 14.9129 15.9463 14.7447 15.9463 14.5692V12.4262C15.9459 12.251 15.8761 12.0831 15.752 11.9594C15.628 11.8356 15.46 11.7662 15.2848 11.7662H13.4279V9.90616C13.4275 9.73097 13.3576 9.5631 13.2336 9.43937C13.1096 9.31564 12.9415 9.24615 12.7663 9.24616H10.6202Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default ReportGroupIcon

import React from 'react'
import ReactApexChart from 'react-apexcharts'

import { dashboardDummyDataGen } from '@/utilities/dataGenerators'
import { ApexOptions } from 'apexcharts'

const Bar: React.FC = () => {
  const series = [
    {
      name: 'Draft',
      color: '#006DCD',

      data: dashboardDummyDataGen([44, 55, 41, 67])
    },
    {
      name: 'Final',
      color: '#003C71',
      data: dashboardDummyDataGen([13, 23, 20, 8])
    },
    {
      name: 'Closed',
      color: '#FF681D',
      data: dashboardDummyDataGen([11, 17, 15, 15])
    },
    {
      name: 'Rejected',
      color: '#F1B635',
      data: dashboardDummyDataGen([21, 7, 25, 13])
    }
  ]
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 200,
      stacked: true,
      toolbar: {
        show: false
      },

      zoom: {
        enabled: false
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        borderRadiusApplication: 'end', // 'around', 'end'
        borderRadiusWhenStacked: 'last', // 'all', 'last'
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      }
    },
    xaxis: {
      type: 'category',
      categories: ['Alpha Ship', 'Antartic', 'Beta Ship', 'Gamma Ship']
    },
    legend: {
      position: 'right',
      offsetY: 40
    },
    fill: {
      opacity: 1
    }
  }
  return (
    <div>
      <ReactApexChart options={options} series={series} type="bar" height={200} />
    </div>
  )
}

export default Bar

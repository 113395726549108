import { postTechnicalMipsData } from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useQuery } from '@tanstack/react-query'

export const useBudgEntities = (params: any) => {
  const fetchData = async () => {
    return await postTechnicalMipsData(
      `${apiTechnicalUrl.GET_VESSEL_BUDGET_ENTITY_DETAILS}`,
      params
    )
  }
  return useQuery({
    queryKey: ['vessel-budget-entities'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

import { CustomTable } from '@/components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  option: string
  data: any[]
}

const ManageBudgetSummary: React.FC<Props> = ({ option, data }) => {
  const { t } = useTranslation()

  const columns = [
    ...(option === '1'
      ? [
          {
            title: t('Budget'),
            field: 'BudgetTitle',

            type: 'text',
            sort: false
          }
        ]
      : []),
    {
      title: t('Vessels'),
      field: 'EntityName',
      width: 300,
      type: 'text',
      sort: false
    },
    ...(option === '1'
      ? [
          {
            title: t('Budget Amount'),
            field: 'Budget',

            align: 'center',
            type: 'amount',
            sort: false
          },
          {
            title: t('Currency'),
            field: 'CurrencyName',

            type: 'text',
            sort: false
          }
        ]
      : [
          {
            title: t('Start Date'),
            field: 'StartDate',
            type: 'date',
            sort: false

            //  align: 'center'
          },
          {
            title: t('End Date'),
            field: 'EndDate',
            //  type: 'boolean',
            type: 'date',
            sort: false
          }
        ])
  ]

  return (
    <div className="w-full ">
      {' '}
      <CustomTable
        columns={columns}
        data={data}
        async={false}
        toolBar={true}
        title={
          <div className="w-auto text-text-primary flex items-center">
            {option === '1' ? 'Selected Budget' : 'Selected Vessel(s)'}
          </div>
        }
        sortable={true}
        //  adjustWidth={-15}
        isDownloadable={false}
        // tableHeight={`calc(100vh - 355px)`}
      />
    </div>
  )
}

export default ManageBudgetSummary

import CustomIcons from '../icons/customIcons'

type ColProps = {
  col: {
    title: string
    field: string
    type?: string
    align?: 'right' | 'left' | 'center'
    icon?: string
    tooltip?: boolean
    render?: (row: any) => any
  }
  rowData: any
  lastChild?: boolean
  index?: number
}
export default function DataColumnHead({ col, rowData }: ColProps) {
  const handleCol = () => {
    switch (col.type) {
      case 'title':
        return (
          <div key={col.title} className={`text-common`}>
            <span className={`text-dark font-medium text-m`}>{rowData[col.field]}</span>
          </div>
        )
      case 'boolen':
        return (
          <div key={col.title} className={`text-common`}>
            {col?.icon && rowData[col.field] && (
              <div className="h-full flex items-center justify-center pr-3 right text-text-more">
                <div className="tooltip-container">
                  <span className="tooltip tooltip-card">{col.title}</span>
                  <CustomIcons name={col?.icon ?? 'archive'} type="large-xl" />
                </div>
              </div>
            )}
          </div>
        )
      default:
        return (
          <div key={col.title} className={`text-common`}>
            <span className={`text-dark`}>{rowData[col.field]}</span>
          </div>
        )
    }
  }
  const renderFunc = col.render && col.render
  return (
    <>
      {renderFunc && (
        <div
          key={col.title}
          className="p-cell text-xxs text-table-bodyColor font-normal td-custom"
        >
          {renderFunc(rowData)}
        </div>
      )}
      {!renderFunc && handleCol()}
    </>
  )
}

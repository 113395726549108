import { CardListProps } from '@/common/customComponenttypes'
import { useCallback, useEffect, useRef, useState } from 'react'

import DynamicActionMenu from '../../dynamicMenu'
// import CustomIcons from '../icons/customIcons'
import { convertHexToRgbA } from '@/utilities/commonParsers'
import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { Accordion, AccordionBody, AccordionHeader } from '../../accordion'
import CustomIcons from '../../icons/customIcons'
import MenuDropDown from '../../menudropdown/MenuDropDown'
import DataColumnHead from './DataColumnHead'

export default function ExpandCard({
  columns,
  rowData,
  index,
  themeKey = 'theme',
  actionDropDown,
  actionDropDownParams,
  expansionComponent,
  handleMenuActions,
  cardRef,
  staticMenus,
  onExpansionDropDown,
  workflowParams,
  defaultBorder
}: CardListProps) {
  const colRef = useRef<HTMLDivElement>(null)

  const [isClickActive, setIsClickActive] = useState(false)
  const [open, setOpen] = useState<boolean>(false)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const handleMenu = (slug: string) => {
    handleMenuActions?.(slug, rowData)
  }
  const handleScroll = useCallback(() => {
    onIconClick(false)
  }, [])
  const { width } = useScreenBreakPoint()
  useEffect(() => {
    const div = cardRef?.current
    div.addEventListener('scroll', handleScroll)
  }, [handleScroll])

  const onIconClick = (clicked: boolean) => {
    setIsClickActive(clicked)
  }

  const ExpansionComponent = expansionComponent
  const getMenuItems = () => {
    if (actionDropDown && onExpansionDropDown) {
      return [
        ...(open ? [...actionDropDown] : [...onExpansionDropDown, ...actionDropDown])
      ]
    } else if (onExpansionDropDown) {
      return [...onExpansionDropDown]
    } else if (actionDropDown) {
      return [...actionDropDown]
    } else {
      return
    }
  }
  const generateGap = () => {
    if (columns.length > 4) {
      return 'gap-x-[50px]'
    }
    return 'gap-x-[100px]'
  }
  return (
    <div
      style={{ borderColor: rowData?.[themeKey] || '#68d8d6' }}
      className={`flex flex-col justify-between rounded-[12px] bg-white   relative cursor-pointer group/item col-span-12 card-shadow`}
      ref={colRef}
    >
      <Accordion open={open} id="1" className="">
        <AccordionHeader
          onClick={() => setOpen((c) => !c)}
          className="bg-white w-full p-5 flex items-center justify-between text-common text-text-primary font-normal leading-[14px] rounded-[12px]"
        >
          <div className="flex flex-row w-full  items-center  " key={index}>
            <div className="flex items-center w-full">
              <div className="pl-1">
                <div
                  className="rounded-full h-10 w-10  flex items-center justify-center"
                  style={{
                    background: convertHexToRgbA(rowData?.[themeKey] || '#68d8d6', 1)
                  }}
                >
                  <div className="h-10 w-10 rounded-full  flex items-center justify-center">
                    <CustomIcons
                      name="file"
                      className="text-white"
                      type="large-m"
                      isWrapper={false}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col flex-grow px-[14px]">
                <div
                  className={`flex ${width > 1500 ? generateGap() : 'gap-x-[60px] flex-wrap text-[#667085] '} text-[#667085] w-full items-center gap-y-5`}
                >
                  {columns?.map((col, index) => (
                    <>
                      {(col.type !== 'archive' ||
                        (col.type === 'archive' && rowData[col.field])) && (
                        <DataColumnHead col={col} rowData={rowData} key={index} />
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>

            {workflowParams?.isDynamicMenu ? (
              <div
                className="w-[20px] bg-white group-hover:bg-table-hover z-9"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex items-center justify-center w-[20px] bg-white group-hover:bg-table-hover">
                  <DynamicActionMenu
                    iconName="three_dot_horizontal"
                    staticMenus={staticMenus}
                    handleMenuActions={handleMenu}
                    rowData={rowData}
                    handleMenuItems={() => workflowParams?.handleMenuItems?.(rowData)}
                    updateMenuRef={(ref) => (menuRef.current = ref)}
                    handleRefresh={(id) => workflowParams?.handleRefresh(id)}
                    fromTable={true}
                  />
                </div>
              </div>
            ) : (
              <div className="flex gap-5">
                {getMenuItems() && !actionDropDownParams?.hideDropDown?.(rowData) && (
                  <div className="w-[24px]" onClick={(e) => e.stopPropagation()}>
                    <div
                      className={`flex items-center justify-center py-1 text-[#667085] hover:bg-[#ffffff50] 
                      ${!isClickActive ? ' invisible' : ''}  group-hover/item:visible`}
                    >
                      <MenuDropDown
                        iconName="three_dot_horizontal"
                        iconClass="text-[#667085]"
                        actionDropDown={getMenuItems()}
                        onIconClick={onIconClick}
                        isClickActive={isClickActive}
                        handleMenuActions={handleMenu}
                        updateMenuRef={(ref) => (menuRef.current = ref)}
                      />
                    </div>
                  </div>
                )}
                <div>
                  <CustomIcons name={open ? 'up_arrow' : 'down_arrow'} type={'large-m'} />
                </div>
              </div>
            )}
          </div>
        </AccordionHeader>
        <AccordionBody>
          <div
            className={`w-full ${defaultBorder === false ? '' : ' px-6 border border-t-[1px]  py-6 border-x-0 border-b-0'}`}
          >
            {expansionComponent && <ExpansionComponent data={rowData} open={open} />}
          </div>

          {/* <div className="  w-full px-5 ">
            <div className="border border-t-[1px] w-full py-5 border-x-0 border-b-0">
              {expansionComponent && <ExpansionComponent data={rowData} open={open} />}
            </div> */}
        </AccordionBody>
      </Accordion>
    </div>
  )
}

import { Button } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { useState } from 'react'
import { DummyComponent } from './dummyComponent'

const ButtonComponent = () => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 ">
      <div className="py-3">
        <div className="comp-label py-2">Primary</div>

        <div>
          <Button
            label="Create"
            onClick={() => {
              setOpenModal(true)
            }}
          />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary</div>
        <div>
          <Button label="Create" primary={false} />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary Outlined</div>
        <div>
          {' '}
          <Button label="Create" outlined />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary Outlined</div>
        <div>
          <Button label="Create" outlined primary={false} />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary with left Icon alignment</div>
        <div>
          {' '}
          <Button label="Create" primary icon="plus" />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary with left Icon alignment</div>
        <div>
          {' '}
          <Button label="Create" primary={false} icon="plus" />
        </div>
      </div>

      <div className="py-3">
        <div className="comp-label py-2">Primary Outlined with left Icon alignment</div>
        <div>
          <Button label="Create" primary icon="plus" outlined />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary Outlined with left Icon alignment</div>
        <div>
          <Button label="Create" primary={false} icon="plus" outlined />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary with right icon alignment</div>
        <div>
          <Button label="Create" primary icon="plus" iconAlignment="right" />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary with right icon alignment</div>
        <div>
          <Button label="Create" primary={false} icon="plus" iconAlignment="right" />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary Outlined with right icon alignment</div>
        <div>
          <Button label="Create" primary icon="plus" iconAlignment="right" outlined />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">
          Secondary Outlined with right icon alignment
        </div>
        <div>
          <Button
            label="Create"
            primary={false}
            icon="plus"
            iconAlignment="right"
            outlined
          />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary Disabled</div>

        <div>
          <Button label="Create" disabled />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary Disabled</div>
        <div>
          <Button label="Create" primary={false} disabled />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary Outlined Disabled</div>
        <div>
          {' '}
          <Button label="Create" outlined disabled />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary Outlined Disabled</div>
        <div>
          <Button label="Create" outlined primary={false} disabled />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary with left Icon alignment Disabled</div>
        <div>
          {' '}
          <Button label="Create" primary icon="plus" disabled />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Secondary with left Icon alignment Disabled</div>
        <div>
          {' '}
          <Button label="Create" primary={false} icon="plus" disabled />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">
          Primary Outlined with left Icon alignment Disabled
        </div>
        <div>
          <Button label="Create" primary icon="plus" outlined disabled />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">
          Secondary Outlined with left Icon alignment Disabled
        </div>
        <div>
          <Button label="Create" primary={false} icon="plus" outlined disabled />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">Primary with right icon alignment Disabled</div>
        <div>
          <Button label="Create" primary icon="plus" iconAlignment="right" disabled />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">
          Secondary with right icon alignment Disabled
        </div>
        <div>
          <Button
            label="Create"
            primary={false}
            icon="plus"
            iconAlignment="right"
            disabled
          />
        </div>
      </div>

      <div className="py-3">
        <div className="comp-label py-2">
          Primary Outlined with right icon alignment Disabled
        </div>
        <div>
          <Button
            label="Create"
            primary
            icon="plus"
            iconAlignment="right"
            outlined
            disabled
          />
        </div>
      </div>
      <div className="py-3">
        <div className="comp-label py-2">
          Secondary Outlined with right icon alignment Disabled
        </div>
        <div>
          <Button
            label="Create"
            primary={false}
            icon="plus"
            iconAlignment="right"
            outlined
            disabled
          />
        </div>
      </div>
      <DialogModal
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        isFullView
        // title={rename ? 'Rename Dashboard' : 'Add Dashboard'}
        secondaryAction={() => {
          setOpenModal(false)
        }}
        secondaryActionLabel={'Close'}
        // actionLabel={t('save')}
        // actionLoader={isLoading || isUpdating}
        body={<DummyComponent />}
      />
    </div>
  )
}

export default ButtonComponent

import React, { useEffect, useRef } from 'react'
import { I18nextProvider } from 'react-i18next'

import CountdownPopup from './components/app/common/ideTimeoutPopup'
import { Loader } from './components/common'
import ErrorBoundary from './error/ErrorBoundary'
import MainRoutes from './routes'
import { useAuthStore } from './store/authStore'
import { useExpireTimeStore, useIdleTimeoutStore } from './store/idleTimeoutStore'
import { usePermissionStore } from './store/permission'
import { useClearFilter } from './utilities/clearStore'
import i18n from './utilities/i18nextconfig'
import { useIdleTimeout } from './utilities/idleTimeout'
import { refreshToken, useTokenRefresh } from './utilities/refreshToken'

import '@progress/kendo-theme-default/dist/all.css'
import 'react-datepicker/dist/react-datepicker.css'

function App() {
  const { setIsOpenDialoug, setContent } = usePermissionStore()
  const { countDown, isIdle, showPopup, setShowPopup, setCountDown, setIsIdle } =
    useIdleTimeoutStore()
  const { idleExpire } = useExpireTimeStore()
  const { authenticated } = useAuthStore()
  const { clearAll, stopCountDown, resetTimeouts } = useIdleTimeout({
    idleTimeBeforeWarning: (idleExpire / 2) * 60 * 1000, // 5 minutes
    totalIdleTimeout: idleExpire * 60 * 1000, // 10 minutes
    onIdle: () => {
      setShowPopup(false)
    }
  })

  const handleDismiss = () => {
    setCountDown(0)
    clearAll()
    stopCountDown()
    resetTimeouts()
    setIsIdle(false)
    setShowPopup(false)
    localStorage.setItem('countDownValue', '0')

    localStorage.setItem(
      'idleTimeoutCancelled',
      JSON.stringify({ dismissed: true, timestamp: Date.now() })
    )
  }
  const isRefreshingRef = useRef(false)

  const handleRefresh = async () => {
    if (isRefreshingRef.current) {
      return
    }
    isRefreshingRef.current = true
    try {
      await refreshToken()
    } catch (error) {
      console.error('Failed to refresh token:', error)
    } finally {
      isRefreshingRef.current = false
    }
  }
  useEffect(() => {
    if (isIdle && countDown > 1000 && authenticated) {
      if (!showPopup) {
        handleRefresh()
      }
      setShowPopup(true)

      localStorage.setItem(
        'idleTimeoutStarted',
        JSON.stringify({ started: true, timestamp: Date.now() })
      )
    }
    if (countDown < 1999 && countDown > 0 && isIdle) {
      handleLogout()
    }
  }, [isIdle, countDown])
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'countDownValue') {
        const updatedCountDown = parseInt(event.newValue || '0', 10)
        if (!isNaN(updatedCountDown)) {
          setCountDown(updatedCountDown)
        }
      }
      if (event.key === 'idleTimeoutCancelled') {
        const { dismissed } = JSON.parse(event.newValue || '{}')
        if (dismissed) {
          setCountDown(0)
          clearAll()
          stopCountDown()
          setIsIdle(false)
          setShowPopup(false)
        }
      }
      if (event.key === 'idleTimeoutStarted') {
        const { started } = JSON.parse(event.newValue || '{}')
        if (started && !isIdle) {
          setIsIdle(true)
        }
        // setCountDown(countDown)
        setShowPopup(true)
      }
      if (event.key === 'should_reload' || event.key === 'lastTokenRefresh') {
        window.location.reload()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    const handleUnAuthorized = (event: any) => {
      setContent({ subTitle: event.detail.message, title: 'Not Allowed' })
      setIsOpenDialoug(true)
    }
    const storedCountDown = parseInt(localStorage.getItem('countDownValue') || '0', 10)
    if (!isNaN(storedCountDown)) {
      setCountDown(storedCountDown)
    }
    window.addEventListener('UNAUTHORIZED', handleUnAuthorized)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
      window.removeEventListener('UNAUTHORIZED', handleUnAuthorized)
    }
  }, [])
  const hadleClear = useClearFilter()

  const handleLogout = () => {
    hadleClear()
    setCountDown(0)
    localStorage.clear()
    setIsIdle(false)
    setShowPopup(false)
  }
  useTokenRefresh()
  return (
    <div className="App">
      <React.Suspense fallback={<Loader isActive />}>
        <I18nextProvider i18n={i18n}>
          <ErrorBoundary>
            <MainRoutes />
            {showPopup && isIdle && countDown > 0 && authenticated && (
              <CountdownPopup
                countdown={countDown ?? 0}
                // key={countDown}
                isOpen={showPopup}
                handleLogout={handleLogout}
                onDismiss={handleDismiss}
              />
            )}
          </ErrorBoundary>
        </I18nextProvider>
      </React.Suspense>
    </div>
  )
}

export default App

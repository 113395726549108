import { postTechnicalData } from '@/apis/api.helpers'

import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useQuery } from '@tanstack/react-query'

export const useAudiLogList= (params: any) => {
  const fetchData = async () => {
    return await postTechnicalData(
      `${apiTechnicalUrl.GET_VESSEL_BUDGET_AUDITLOG}`,
      params
    )
  }
  return useQuery({
    queryKey: ['vessel-budget-audit-log'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

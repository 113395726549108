import { CommonManageModalProps } from '@/common/commontypes'
import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { addCrew, updateCrew } from '@/modules/crewing/pages/crewSearch/api'
import {
  CrewingCrewDataType,
  CrewingCrewSchema
} from '@/modules/crewing/pages/crewSearch/schema'
import { formContainerClass } from '@/styles/variables'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { getErrorMessage } from '@/utilities/dataGenerators'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const CreateCrew: React.FC<CommonManageModalProps> = ({
  data,
  edit,
  view,
  open,
  title,
  onClose,
  refetch
}) => {
  const { t } = useTranslation()
  const { showSnackBar } = useCustomSnackBar()
  const onSuccessAction = () => {
    methods.reset(initData())
    onClose()
    refetch()
  }
  const initData = () => {
    return { ...data }
  }

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = (res: CrewingCrewDataType) => {
    setIsSubmitting(true)
    const api = edit ? updateCrew : addCrew
    const payload = { ...res, crewStatusId: 2 } // 2 => Applicant

    api(payload)
      .then((res) => {
        onSuccessAction()
        showSnackBar(res?.message)
      })
      .catch((err) => {
        showSnackBar(getErrorMessage(err), 'error')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const handleCloseForm = () => {
    methods.reset(initData())
    onClose()
  }

  const methods = useForm<CrewingCrewDataType>({
    resolver: zodResolver(CrewingCrewSchema),
    mode: 'onChange',
    defaultValues: initData()
  })

  useEffect(() => {
    methods.reset({ ...data })
  }, [data])

  const formFields: FormBuilderProps[] = [
    {
      label: t('First Name'),
      placeholder: t('First Name'),
      name: 'givenName',
      id: 'givenName',
      required: true,
      type: 'text',
      spacing: 6,
      maxLength: 100
    },
    {
      label: t('Middle Name'),
      placeholder: t('Last Name'),
      name: 'middleName',
      id: 'middleName',
      type: 'text',
      spacing: 6,
      maxLength: 100
    },
    {
      label: t('Surname'),
      placeholder: t('Surname'),
      name: 'surName',
      id: 'surName',
      type: 'text',
      required: true,
      spacing: 6,
      maxLength: 100
    },
    {
      label: 'Date of Birth',
      id: 'dob',
      name: 'dob',
      required: true,
      placeholder: 'Date of Birth (DD-MM-YYYY)',
      type: 'date',
      minYearRange: 100,
      maxDate: new Date(),
      spacing: 6,
      maxLength: 10
    },
    {
      label: 'Nationality',
      placeholder: 'Type to search',
      name: 'nationality',
      id: 'nationalityId',
      type: 'autocomplete',
      apiParams: { key: 'COUNTRY' },
      required: true,
      spacing: 6
    },
    {
      label: 'Crew Rank',
      placeholder: 'Type to search',
      name: 'crewRank',
      id: 'crewRankId',
      type: 'autocomplete',
      apiParams: { key: 'CREWRANKS' },
      required: true,
      spacing: 6
    },
    {
      label: t('Email'),
      placeholder: t('Email'),
      name: 'emailId',
      id: 'emailId',
      required: true,
      type: 'email',
      spacing: 12
    }
  ]

  return (
    <>
      <DialogModal
        isOpen={open}
        small={true}
        type="sm"
        title={title}
        body={
          <div className="h-full relative w-full">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={formContainerClass}>
                  <FormBuilder data={formFields} edit={!view} />
                </div>
              </form>
            </FormProvider>
          </div>
        }
        onClose={handleCloseForm}
        actionLabel={edit ? 'Update' : view ? undefined : 'Save'}
        onSubmit={methods.handleSubmit(onSubmit)}
        secondaryAction={handleCloseForm}
        secondaryActionLabel={view ? 'Close' : 'Cancel'}
        actionLoader={isSubmitting}
      />
    </>
  )
}

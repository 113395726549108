import {
  getTechnicalData,
  postTechnicalData,
  updateTechnicalData
} from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useCustomSnackBar from '@/utilities/customSnackBar'
import GeneralSchema from './schema'

 
export const getInspectionTypeDetails = (id: number) => {
  return getTechnicalData(`${apiTechnicalUrl.GET_INSPECTION_TYPE_DETAILS}${id}`)
}

export const updateGeneralInfo = (data: GeneralSchema) => {
  return updateTechnicalData(`${apiTechnicalUrl.INSPECTION_GENERAL_INFO_UPDATE}`, data)
}

export const useGeneralInfo = (onSuccessAction: () => void) => {
const { showSnackBar } = useCustomSnackBar()

  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateGeneralInfo,
    mutationKey: ['update-general-info'],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
    },
    onSuccess: (response, data) => {
      onSuccessAction()
      queryClient.invalidateQueries(['vessel-inspection-technical', data.VesselId])
    }
  })
}

export const createInspection = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.GET_VESSEL_INSPECTION}/Create`, data)
}
export const getGeneralInfo = (id: string) => {
  return getTechnicalData(`${apiTechnicalUrl.GENERAL_INFO}${id}`)
}

import { ModuleType } from '@/common/commontypes'

import { dataParse } from '../utilities'
import {
  deleteFinanceData,
  deleteMasterData,
  deleteMasterDataMips,
  deleteOperationsData,
  deletePmsData,
  deletePurchaseData,
  deleteSystemData,
  deleteSystemMipsData,
  deleteTechnicalData,
  deleteTechnicalMipsData,
  getFinanceData,
  getMasterData,
  getMasterDataMips,
  getOperationsData,
  getPmsData,
  getPurchaseData,
  getSystemData,
  getSystemMipsData,
  getTechnicalData,
  getTechnicalMipsData,
  postJsonSystemMipsData,
  postSystemData,
  updateMasterData,
  updateMasterDataMips,
  updateOperationsData,
  updatePmsData,
  updateSystemData,
  updateSystemMipsData,
  updateTechnicalData,
  updateTechnicalMipsData
} from './api.helpers'
import apiUrl from './common.api.urls'
import apiMasterDataUrl from './masterdata.api.urls'

export const getProfileData = () =>
  getSystemMipsData(`${apiUrl.PROFILE}`).then((res) => res)
export const forgotPassword = (data?: any) =>
  postSystemData(`${apiUrl.FORGOTPASSWORD}${dataParse(data)}`, data).then((res) => res)
export const login = (data: any) =>
  postJsonSystemMipsData(apiUrl.LOGIN_URL, data).then((res) => res)
export const getRegister = (data: any) =>
  getMasterData(`${apiUrl.REGISTER}${dataParse(data)}`).then((res) => res)
export const getProfilePhoto = (data: any) =>
  getSystemMipsData(`${apiUrl.USER_PHOTO}${dataParse(data)}`, 'blob').then((res) => res)

export const getMenuExplorer = (parentId?: string | null) =>
  getSystemData(apiUrl.MENU_EXPLORER).then((res) => res)
export const getFavouriteMenus = () => getSystemData(apiUrl.FAV_MENU).then((res) => res)
export const setFavouriteMenu = (id: string) =>
  postSystemData(apiUrl.SET_FAV_MENU + '?FunctionId=' + id, []).then((res) => res)
export const deleteFavouriteMenu = (id: string) =>
  deleteSystemData(apiUrl.DELETE_FAV_MENU + '?FunctionId=' + id).then((res) => res)

export const getUseruservessels = (id: string) =>
  getSystemData(`${apiUrl.USER_VESSELS}${id}`)

export const createVesselGroup = (data: any) => {
  return postSystemData(apiUrl.CREATE_VESSEL_GROUP, data)
}
export const getUserVesselGroup = () => getSystemData(`${apiUrl.GET_USER_VESSELS_GROUP}`)

export const getUserVesselGroupDetails = (id: string) =>
  getSystemData(`${apiUrl.USER_VESSEL_GROUP_DETAIL}${id}`)

export const deleteUserVesselGroup = (id: string) =>
  deleteSystemData(`${apiUrl.DELETE_USER_GROUP}${id}`)

export const deleteUserVessel = (id: string) =>
  deleteSystemData(`${apiUrl.DELETE_USER_VESSEL}${id}`)
export const getUserPrivilege = (id: string) => {
  return getSystemData(`${apiUrl.GET_USER_PRIVILEGE_MENU}${id}`)
}
export const dynamicUpdateAction = (id: string, value: any, mod: ModuleType) => {
  switch (mod) {
    case 'pms':
      return updatePmsData(id, value)
    case 'technical':
      return updateTechnicalData(id, value)
    case 'procurement':
      return updateOperationsData(id, value)
    case 'operations':
      return updateOperationsData(id, value)
    case 'voyage':
      return updateTechnicalData(id, value)
    case 'mastreg':
      return updateMasterData(id, value)
    case 'system':
      return updateSystemData(id, value)
    case 'system_v2':
      return updateSystemMipsData(id, value)
    case 'mastreg_v2':
      return updateMasterDataMips(id, value)
    case 'technical_v2':
      return updateTechnicalMipsData(id, value)
    default:
      return updateTechnicalData(id, value)
  }
}
export const dynamicActionDelete = (id: string, mod: ModuleType) => {
  switch (mod) {
    case 'pms':
      return deletePmsData(id)
    case 'technical':
      return deleteTechnicalData(id)
    case 'procurement':
      return deletePurchaseData(id)
    case 'operations':
    case 'voyage':
      return deleteOperationsData(id)
    case 'mastreg':
      return deleteMasterData(id)
    case 'system':
      return deleteSystemData(id)
    case 'system_v2':
      return deleteSystemMipsData(id)
    case 'mastreg_v2':
      return deleteMasterDataMips(id)
    case 'technical_v2':
      return deleteTechnicalMipsData(id)
    default:
      return deleteFinanceData(id)
  }
}
export const dyanmicActionGet = (id: string, mod: ModuleType) => {
  switch (mod) {
    case 'pms':
      return getPmsData(id)
    case 'technical':
      return getTechnicalData(id)
    case 'procurement':
      return getPurchaseData(id)
    case 'operations':
    case 'voyage':
      return getOperationsData(id)
    case 'mastreg':
      return getMasterData(id)
    case 'system':
      return getSystemData(id)
    case 'system_v2':
      return getSystemMipsData(id)
    case 'mastreg_v2':
      return getMasterDataMips(id)
    case 'technical_v2':
      return getTechnicalMipsData(id)
    default:
      return getFinanceData(id)
  }
}

export const updateWOrkFlowStatus = (data: any) => {
  return updateMasterData(`${apiMasterDataUrl.UPDATE_WORKFLOW_STATUS}`, data)
}
export const getWorkFlowStatus = (id: string) => {
  return getMasterData(`${apiMasterDataUrl.WORKFLOW_STATUS}${id}`)
}
// export const dyanmicActionPrint = (id: string, mod: ModuleType, type: string) =>
//   mod === 'pms'
//     ? getPmsData(id, {
//         responseType: 'blob'
//       })
//     : mod === 'technical'
//     ? getTechnical(id, {
//         responseType: 'blob'
//       }).then((res) => res.data)
//     : mod === 'procurement'
//     ? getPurchase(id, {
//         responseType: 'blob'
//       }).then((res) => res.data)
//     : mod === 'operations'
//     ? getOperations(id, {
//         responseType: 'blob'
//       }).then((res) => res.data)
//     : mod === 'voyage'
//     ? getOperations(id, {
//         responseType: 'blob'
//       }).then((res) => res.data)
//     : mod === 'mastreg'
//     ? getMaster(id, {
//         responseType: 'blob'
//       }).then((res) => res.data)
//     : mod === 'system'
//     ? getServer(id, {
//         responseType: 'blob'
//       }).then((res) => res.data)
//     : getFinance(id, {
//         responseType: 'blob'
//       }).then((res) => res.data)

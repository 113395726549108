const TreeColapseIcon = () => {
  return (
    <path
      d="M7.91945 5.4004L7.91945 5.39961C7.91938 5.3571 7.93097 5.31538 7.95295 5.27899C7.97493 5.2426 8.00646 5.21292 8.04412 5.19319C8.08178 5.17346 8.12412 5.16443 8.16656 5.16708C8.20899 5.16973 8.24988 5.18395 8.2848 5.20821L8.28548 5.20868L17.8186 11.8086C17.8187 11.8086 17.8187 11.8086 17.8187 11.8086C17.8575 11.8356 17.9001 11.9003 17.9001 12.0012C17.9001 12.1023 17.8575 12.1665 17.8192 12.193L17.8191 12.193L8.28715 18.7921C8.28697 18.7922 8.28679 18.7923 8.28661 18.7925C8.25151 18.8164 8.21058 18.8304 8.16816 18.8329C8.12553 18.8354 8.08301 18.8263 8.04519 18.8064C8.00737 18.7866 7.97568 18.7568 7.95352 18.7203C7.93147 18.684 7.9197 18.6423 7.91945 18.5998L7.91945 5.4004Z"
      fill="currentColor"
      stroke="currentColor"
    />
  )
}
export default TreeColapseIcon

import {
  deleteTechnicalData,
  getTechnicalData,
  postTechnicalData
} from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useQuery } from '@tanstack/react-query'
//
export const useInspDocs = (params: any) => {
  const fetchData = async () => {
    return await postTechnicalData(
      `${apiTechnicalUrl.VESSEL_INSPECTION_DOCUMENTS_DETAILS}`,
      params
    )
  }
  return useQuery({
    queryKey: ['vessel-inspection-documents'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}

export const uploadFile = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.GET_VESSEL_INSPECTION}/UploadDocs`, data)
}
export const getFile = (id: string) => {
  return getTechnicalData(
    `${apiTechnicalUrl.VESSEL_INSPECTION_GET_DOCS}` + id + `&file=false`
  )
}
export const deleteDocument = (id: string) => {
  return deleteTechnicalData(
    `${apiTechnicalUrl.VESSEL_INSPECTION_FINDING_DOCUMENTS_DELETE}${id}`
  )
}

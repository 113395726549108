import { MenuItemProps } from '@/modules/technical/pages/vesselParticulars/details/technical/schema'
import { t } from 'i18next'

export const menuData: MenuItemProps[] = [
  {
    label: t('technical.technicalDetails'),
    slug: 'technical',
    icon: 'Settings_Icon'
  },
  {
    label: t('technical.dimenstions'),
    slug: 'dimenstions',
    icon: 'dimenstion-icon'
  },
  {
    label: t('technical.capacityUsage'),
    slug: 'capacityUsage',
    icon: 'CapacityIcon'
  },
  {
    label: t('technical.loadLineInfo'),
    slug: 'loadLineInfo',
    icon: 'loadline-icon'
  },
  {
    label: t('technical.tonnage'),
    slug: 'tonnage',
    icon: 'tonnage'
  },
  {
    label: t('technical.ratedPower'),
    slug: 'ratedPower',
    icon: 'ratedPower-icon'
  },
  {
    label: t('others'),
    slug: 'others',
    icon: 'others'
  }
]

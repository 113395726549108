import {
  deleteTechnicalData,
  getTechnicalData,
  postTechnicalData,
  updateTechnicalData
} from '@/apis/api.helpers'
import apiTechnicalUrl from '@/modules/technical/apis/api.urls'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useCustomSnackBar from '@/utilities/customSnackBar'

 
export const useTankListing = (params: any) => {
  const fetchData = async () => {
    return await postTechnicalData(`${apiTechnicalUrl.LIST_TANKS}`, params)
  }
  return useQuery({
    queryKey: ['technical-tank-listing'],
    queryFn: () => fetchData(),
    staleTime: 500
  })
}
export const createTank = (data: any) => {
  return postTechnicalData(apiTechnicalUrl.CREATE_TANK, data)
}
export const updateTank = (data: any) => {
  return updateTechnicalData(apiTechnicalUrl.UPDATE_TANK, data)
}
export const useCreateTanks = (onSuccessAction: () => void, params: any) => {
const { showSnackBar } = useCustomSnackBar()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createTank,
    mutationKey: ['update-general-info'],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
    },
    onSuccess: (response, data) => {
      onSuccessAction()
      queryClient.invalidateQueries(['technical-tank-listing', params])
    }
  })
}
export const useUpdateTank = (onSuccessAction: () => void, params: any) => {
const { showSnackBar } = useCustomSnackBar()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateTank,
    mutationKey: ['update-general-info'],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
    },
    onSuccess: (response, data) => {
      onSuccessAction()
      queryClient.invalidateQueries(['technical-tank-listing', params])
    }
  })
}
export const deleteTank = (id: string) => {
  return deleteTechnicalData(`${apiTechnicalUrl.DELETE_TANK}${id}`)
}
export const getVolumePrenentinTankByTankId = (data: string) => {
  return postTechnicalData(`${apiTechnicalUrl.GET_OIL_PRESENT_IN_TANK}`, data)
}
export const getOilTypesInTankByTankId = (id: string) => {
  return getTechnicalData(`${apiTechnicalUrl.GET_OIL_TYPES_IN_TANK}?Id=${id}`)
}
export const updateOilTypesByTankId = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.UPDATE_OIL_TYPES_IN_TANK}`, data)
}

export const getTankTypeDetails = (id: number) => {
  return getTechnicalData(`${apiTechnicalUrl.GET_TANK_TYPE_DETAILS}${id}`)
}

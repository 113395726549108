// const LOGIN_URL = '/Login/VerifyLogin'
const LOGIN_URL = '/Login/VerifyLogin'
const REGISTER = '/v1/shared/GetRegisters'
const FORGOTPASSWORD = 'forgot-password'
const PROFILE = 'profile'
const MENU_EXPLORER = '/v1/Home/GetAllUserPrivileges'
const USER_PHOTO = '/Home/GetUserPhoto'
const FAV_MENU = '/v1/Home/GetFavourites'
const SET_FAV_MENU = '/v1/Home/AddFavourite'
const DELETE_FAV_MENU = '/v1/Home/DeleteFavourite'
const GET_DASHBOARDS = '/v1/Home/GetDashboards'
const GET_DASHBOARD_TILES = '/v1/Home/GetDashTiles'
const GET_TILES = 'v1/Home/GetTiles'
const GET_DASHBOARD_MODULES = '/v1/Home/GetDashModules'
const ADD_DASHBOARDS = '/v1/Home/AddDashboard'
const RENAME_DASHBOARDS = '/v1/Home/ChangeDashName'
const USER_VESSELS = 'v1/Home/GetVesselAccess?Id='
const CREATE_VESSEL_GROUP = '/v1/UserVessel/CreateVesselGroup'
const GET_USER_VESSELS_GROUP = 'v1/UserVessel/GetVesselGroups'
const USER_VESSEL_GROUP_DETAIL = '/v1/UserVessel/GetGroupVessels?Id='
const DELETE_USER_GROUP = '/v1/UserVessel/DeleteVesselGroup?Id='
const DELETE_USER_VESSEL = '/v1/UserVessel/DeleteUserVessel?UserVesselId='
const UPDATE_DASHBOARD_TILES = '/v1/Home/AddDashTiles'
const DELETE_DASHBOARD_TILES = '/v1/Home/RemoveDashTile'
const DELETE_DASHBOARD = '/v1/Home/DeleteDash'
const GET_USER_PRIVILEGE_MENU = '/v1/Home/GetUserPrivileges?ParentId='
const apiUrl = {
  LOGIN_URL,
  REGISTER,
  PROFILE,
  FORGOTPASSWORD,
  MENU_EXPLORER,
  FAV_MENU,
  SET_FAV_MENU,
  DELETE_FAV_MENU,
  GET_DASHBOARDS,
  ADD_DASHBOARDS,
  RENAME_DASHBOARDS,
  USER_VESSELS,
  CREATE_VESSEL_GROUP,
  GET_USER_VESSELS_GROUP,
  USER_VESSEL_GROUP_DETAIL,
  DELETE_USER_GROUP,
  DELETE_USER_VESSEL,
  GET_DASHBOARD_TILES,
  GET_DASHBOARD_MODULES,
  GET_TILES,
  UPDATE_DASHBOARD_TILES,
  DELETE_DASHBOARD_TILES,
  DELETE_DASHBOARD,
  GET_USER_PRIVILEGE_MENU,
  USER_PHOTO
}

export default apiUrl

import { AutoComplete, Switch, TextField } from '@/components/common'
import {
  FormDataType,
  getMaxValue,
  getMinValue
} from '@/modules/technical/pages/vesselParticulars/details/communication/schema'
import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type CreateCommunicationProps = {
  getData: (value?: string) => Promise<any>
}

export const CreateCommunication: React.FC<CreateCommunicationProps> = ({ getData }) => {
  const [keyVal, setKeyVal] = useState<string>('')
  const {
    control,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
    watch
  } = useFormContext<FormDataType>()

  const getLabel = () => {
    switch (watch()?.commTypeId?.param1) {
      case 2:
        return 'Communication Email'
      case 3:
        return 'Communication Number'
      default:
        return 'Communication Details'
    }
  }
  const getType = () => {
    switch (watch()?.commTypeId?.param1) {
      case 2:
        return 'email'
      case 3:
        return 'number'
      default:
        return 'text'
    }
  }
  const checkValidations = () => {
    const data = watch()
    if (data?.commNumber) {
      const maxLen = getMaxValue(data.commTypeId.param3, 150)
      const minLen = getMinValue(data.commTypeId.param2, 1)
      if (data.commTypeId.param1 === 2 || data.commTypeId.param1 === '2') {
        const emailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/
        if (!emailPattern.test(data.commNumber)) {
          setError('commNumber', { message: 'Should be a valid email' })
          return false
        }
      } else if (data.commTypeId.param1 === 3 || data.commTypeId.param1 === '3') {
        if (isNaN(Number(data.commNumber))) {
          setError('commNumber', { message: 'Communication Number should be a number' })
          return false
        }
      }
      if (data.commNumber.length === 0) {
        let msg = 'Communication Details are'
        if (data.commTypeId.param1 === 2 || data.commTypeId.param1 === '2')
          msg = 'Email is'
        else if (data.commTypeId.param1 === 3 || data.commTypeId.param1 === '3')
          msg = 'Communication Number is'

        setError('commNumber', { message: `${msg} required` })
      } else if (data.commNumber.length > maxLen) {
        setError('commNumber', { message: `Max length is ${maxLen} characters` })
      } else if (data.commNumber.length < minLen) {
        setError('commNumber', { message: `Min length is ${minLen} characters` })
      } else {
        clearErrors('commNumber')
      }
    }
  }

  const handleChange = (val: any) => {
    setValue('commTypeId', val)
    setValue('commNumber', '')
  }
  useEffect(() => {
    checkValidations()
  }, [watch().commNumber])

  useEffect(() => {
    clearErrors('commNumber')
  }, [watch()?.commTypeId?.id])

  useEffect(() => {
    setKeyVal(Date.now().toString())
  }, [])

  return (
    <div className="flex flex-col gap-x-2 gap-y-4">
      <Controller
        name="commTypeId"
        control={control}
        render={({ field }) => (
          <AutoComplete
            onChange={(val) => handleChange(val)}
            name="commTypeId"
            id="commTypeId"
            required
            value={field.value}
            type="auto_complete"
            label="Communication Type"
            getData={getData}
            errors={errors}
            autoFocus={true}
            key={keyVal}
          />
        )}
      />
      <Controller
        name="commNumber"
        control={control}
        render={({ field }) => (
          <TextField
            id="name"
            name="commNumber"
            required
            // disabled={!watch()?.CommTypeId?.id}
            label={getLabel()}
            placeholder={getLabel()}
            type={getType()}
            errors={errors}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        name="archived"
        control={control}
        render={({ field }) => (
          <Switch
            id="archived"
            name="archived"
            value={field.value}
            handleChange={field.onChange}
            label="Archived"
          />
        )}
      />
    </div>
  )
}

import { CustomKendoTable, DeletePopUp } from '@/components/common'
import DialogModal from '@/components/common/modal/DialogModal'
import { TableHeight, defaultPageSize, guid, isAllow } from '@/constants'
import { SeaTrialDetails } from '@/modules/technical/components/app/vesselParticulars/seaTrials/SeaTrialDetails'
import { CreateSeaTrialForm } from '@/modules/technical/components/app/vesselParticulars/seaTrials/SeaTrialForm'
import { useAppStore } from '@/store/appStore'
import { useAuthStore } from '@/store/authStore'
import { useAppContext } from '@/store/context/appContext'
import useHasPermission from '@/utilities/checkActionPermission'
import useCustomSnackBar from '@/utilities/customSnackBar'
import formatDate from '@/utilities/usedateformat'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import decode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import {
  createSeaTrial,
  deleteSeaTrial,
  updateSeaTrial,
  useSeaTrialsDetail
} from '../api'
import { ParticularDetailsContextProps } from '../schema'
import { FormDataType, FormSchema } from './schema'

const sortFields = ['SeaTrialDate']
type PageParams = {
  pageNo: number
  vesselId?: string
  pageSize: number
  vesselFilterType: number
  sortBy: string
  archived: boolean
  loginId?: string
}

const TechnicalParticularSeaTrails: React.FC = () => {
  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const { setIsLoading } = useAppStore()
  const { showSnackBar } = useCustomSnackBar()
  const { vesselID } = useOutletContext<ParticularDetailsContextProps>()
  const [seaTrialOpen, setSeaTrialOpen] = useState(false)
  const [vesSeaTrialSelId, setSeaTrialSelId] = useState<string>('')
  const [vesSeaTrialId, setSeaTrialId] = useState<string>('')
  const [createOpen, setCreateOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [edit, setEdit] = useState(true)
  const [subTitle, setSubTitle] = useState<string>('')
  const hasPermission = useHasPermission('VesselSeaTrial')
  const { setIsActionLoader, isActionLoader } = useAppContext()
  const { accessToken } = useAuthStore()
  let payload = { UserId: '' }
  let userid = ''

  if (accessToken) {
    payload = decode(accessToken)
    userid = payload.UserId
  }

  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    vesselId: vesselID,
    vesselFilterType: 1,
    pageSize: defaultPageSize,
    sortBy: sortFields[0],
    loginId: userid,
    archived: false
  })

  const { data, refetch, isFetching } = useSeaTrialsDetail(params)

  const columns = [
    {
      title: t('Date'),
      field: 'seaTrialDate',
      sort: true,
      type: 'date',
      width: 140,
      action: true,
      render: (row: any) => <div>{formatDate(row.seaTrialDate)}</div>
    },
    {
      title: t('Engine'),
      field: 'materialTypeName',
      sort: true
    },
    {
      title: t('Carbon Factor'),
      field: 'carbonFactor',
      sort: true,
      align: 'right'
    },
    {
      title: t('Barred Speed Max (RPM)'),
      sort: true,
      field: 'barredSpeedMax',
      align: 'right'
    },
    {
      title: t('Barred Speed Min (RPM)'),
      sort: true,
      field: 'barredSpeedMin',
      align: 'right'
    }
  ]

  useEffect(() => {
    setTotal(data?.totalRecords)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])

  const getDeFaultValues = (rowData: any) => {
    if (rowData && rowData?.vesSeaTrialId) {
      return {
        materialTypeId: rowData?.materialTypeId,
        materialTypeName: rowData?.materialTypeName,
        seaTrialDate: rowData?.seaTrialDate && new Date(rowData?.seaTrialDate),
        vesSeaTrialId: rowData?.vesSeaTrialId,
        vesselId: vesselID,
        carbonFactor:
          (rowData?.carbonFactor || rowData?.carbonFactor === 0) &&
          rowData?.carbonFactor.toString(),
        barredSpeedMax: rowData?.barredSpeedMax,
        barredSpeedMin: rowData?.barredSpeedMin
      }
    } else {
      return {
        seaTrialDate: '',
        materialTypeId: '',
        materialTypeName: '',
        vesSeaTrialId: guid,
        vesselId: vesselID,
        carbonFactor: undefined,
        barredSpeedMax: undefined,
        barredSpeedMin: undefined
      }
    }
  }

  const setActionLabel = () => {
    return edit ? t('update') : t('save')
  }

  const methods = useForm<FormDataType>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDeFaultValues({})
  })

  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }
  const handleMenuActions = async (slug: string, rowData: any) => {
    setSeaTrialId(rowData?.vesSeaTrialId)
    setSubTitle(rowData?.seaTrialDate)
    const permissionParams = {
      UserAction: slug === 'delete' ? isAllow.DELETE : isAllow.UPDATE,
      AccessKey: '',
      ID1: rowData?.vesSeaTrialId
    }
    const permission = await hasPermission(permissionParams)

    if (slug === 'delete') {
      setDeleteOpen(permission)
    } else {
      setCreateOpen(permission)
      setEdit(true)
      methods.reset(getDeFaultValues(rowData))
    }
  }
  const handleDelete = () => {
    setIsActionLoader(true)
    deleteSeaTrial(vesSeaTrialId)
      .then((res) => {
        showSnackBar(res)
        if (vesSeaTrialSelId === vesSeaTrialId) setSeaTrialSelId(guid)

        refetch()
      })
      .catch((err) => {
        showSnackBar(err.response.data.error, 'error')
      })
      .finally(() => {
        setIsActionLoader(false)
        setDeleteOpen(false)
      })
  }

  const handleCreate = async () => {
    setIsLoading(true)
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: '',
      ID2: vesselID
    })
    setEdit(false)
    setCreateOpen(permission)
    setIsLoading(false)
    methods.reset(getDeFaultValues({}))
  }

  const handleRadioClick = (rowData: any) => {
    setSeaTrialOpen(true)
    setSeaTrialSelId(rowData?.vesSeaTrialId)
  }

  useEffect(() => {
    if (seaTrialOpen && vesSeaTrialSelId && vesSeaTrialSelId !== guid) {
      const element = document.getElementById('sublist')
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        })
      }
    }
  }, [seaTrialOpen, vesSeaTrialSelId, guid])

  const getHeading = () => {
    return edit ? t('Edit Vessel Sea Trial') : t('Add Vessel Sea Trial')
  }


  const handleToolActions = (slug: string) => {
    if (slug === 'archived') {
      setPrams({ ...params, archived: !params?.archived, pageNo: 1 })
    }
  }

  const getArchiveText = () => (params?.archived ? 'Hide Archived' : 'Show Archived')

  const onSubmit = (data: FormDataType) => {
    const payload = {
      ...data,
      seaTrialDate: dayjs(data.seaTrialDate).format('YYYY-MM-DD')
    }
    const api = edit ? updateSeaTrial : createSeaTrial
    setIsSaving(true)
    api(payload)
      .then((res) => {
        setIsSaving(false)
        showSnackBar(res)
        refetch()
        setCreateOpen(false)
      })
      .catch((err) => {
        setIsSaving(false)
        showSnackBar(err?.response?.data?.error, 'error')
      })
  }

  return (
    <>
      <div className={`bg-white rounded p-[15px]`}>
        <CustomKendoTable
          columns={columns}
          data={data?.results}
          pagination={true}
          toolBar={true}
          sortable={true}
          resizable={true}
          isFetching={isFetching}
          defaultSort={sortFields[0]}
          rowKey="vesSeaTrialId"
          selectedKey={vesSeaTrialSelId}
          singleSelection={true}
          handleSingleSelect={handleRadioClick}
          title={t('Sea Trial Information')}
          tableHeight={`calc(100vh - ${TableHeight.WithHeader})`}
          isDownloadable={true}
          adjustWidth={0}
          handleSort={handleSort}
          actionDropDown={[
            { title: t('edit'), iconName: 'edit', slug: 'edit', id: '1' },
            {
              title: t('delete'),
              iconName: 'delete',
              slug: 'delete',
              id: '2',
              isWarning: true
            }
          ]}
          handleMenuActions={handleMenuActions}
          primaryAction={{
            title: t('New'),
            handlePrimaryAction: handleCreate
          }}
          handleToolActions={handleToolActions}
          actionToolDropDown={[
            {
              title: getArchiveText(),
              iconName: 'archieve',
              slug: 'archived',
              id: '1'
            }
          ]}
          paginationProps={{
            total: total,
            currentPage: params.pageNo,
            rowsPerPage: params.pageSize,
            onPagination(row) {
              setPrams((prev) => ({
                ...prev,
                pageNo: row
              }))
            },
            onRowsPerPage(row) {
              setPrams((prev) => ({
                ...prev,
                pageSize: row,
                pageNo: 1
              }))
            }
          }}
        />
      </div>

      {seaTrialOpen && vesSeaTrialSelId && vesSeaTrialSelId !== guid && (
        <div className={`bg-white rounded p-[15px] mt-[15px]`} id="sublist">
          <SeaTrialDetails vesselID={vesselID} vesSeaTrialId={vesSeaTrialSelId} />
        </div>
      )}

      <DeletePopUp
        isOpen={deleteOpen}
        onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
        itemLabels={{
          title: 'sea trial information',
          subTitle: dayjs(subTitle).format('DD-MMM-YYYY')
        }}
        actionLoader={isActionLoader}
      />

      <FormProvider {...methods}>
        <DialogModal
          isOpen={createOpen}
          type="sm"
          title={getHeading()}
          body={<CreateSeaTrialForm />}
          onClose={() => setCreateOpen(false)}
          actionLabel={setActionLabel()}
          onSubmit={() => methods.handleSubmit(onSubmit)()}
          secondaryAction={() => setCreateOpen(false)}
          secondaryActionLabel={t('cancel')}
          small={false}
          actionLoader={isSaving}
        />
      </FormProvider>
    </>
  )
}

export default TechnicalParticularSeaTrails

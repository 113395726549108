import { CustomKendoTable, DeletePopUp } from '@/components/common'
import { TableHeight, defaultPageSize, isAllow } from '@/constants'
import VesselInspFindActionForm from '@/modules/technical/components/app/vesselInspections/findings/ActionForm'
import useHasPermission from '@/utilities/checkActionPermission'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { getInspDynamicMenu, useInspAuditLog } from './api'
import { VesselInspectionContextProps } from './schema'

type PageParams = {
  pageNo: number
  FindingId?: string
  pageSize: number
  sortBy: string
}

const VIActions: React.FC = () => {
  const { VesselId, FindingId, refetchData } =
    useOutletContext<VesselInspectionContextProps>()
  const hasPermission = useHasPermission('Inspection')

  const initData = {
    FindingId: FindingId,
    VesselId: VesselId
  }
  const [total, setTotal] = useState<number>(0)
  const [createOpen, setCreateOpen] = useState(false)
  const [rowData, setRowData] = useState<any>(initData)
  const [view, setView] = useState(false)
  const [edit, setEdit] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    pageSize: defaultPageSize,
    FindingId: FindingId,
    sortBy: 'actionBy'
  })

  const { data, refetch, isFetching } = useInspAuditLog(params)

  useEffect(() => {
    setTotal(data?.data?.totalRecords)
  }, [data])

  const handleView = (row: any) => {
    setRowData(row)
    setView(true)
    setCreateOpen(true)
    setEdit(false)
  }

  const handleCloseForm = () => {
    setCreateOpen(false)
    setRowData(initData)
    setEdit(false)
    setView(false)
  }
  const handleCreate = async () => {
    const permissionParams = {
      UserAction: isAllow.CREATE,
      AccessKey: ''
    }
    setRowData(initData)
    const permission = await hasPermission(permissionParams)
    setEdit(true)
    setCreateOpen(permission)
  }

  const handleMenuActions = async (slug: string, rowData: any) => {
    if (slug === 'completed') {
      //setDocId(rowData?.InspDocId)
      const permissionParams = {
        UserAction: true,
        AccessKey: '',
        ID1: ''
      }
      const permission = await hasPermission(permissionParams)
      setDeleteOpen(permission)
    }
  }

  useEffect(() => {
    refetch()
  }, [params])

  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }

  const columns = [
    {
      align: 'left',
      title: 'Action By',
      field: 'actionBy',
      sort: true,
      action: true,
      render: (row: any) => (
        <span
          onClick={() => handleView(row)}
          className="kendo-column-text-link cursor-pointer truncate"
        >
          {row?.actionBy}
        </span>
      )
    },
    {
      align: 'left',
      width: 200,
      title: 'Date',
      field: 'actionOn',
      sort: true,
      type: 'date'
      //render: (row: any) => <span>{dayjs(row.ActionOn).format('DD-MM-YYYY')}</span>
    }
  ]

  const handleDynamicmenu = async (row: any) => {
    const data = await getInspDynamicMenu(row.actionId, 'grid')
    return data
  }
  const handleRefresh = () => {
    refetch?.()
    refetchData?.()
  }
  return (
    <div>
      <CustomKendoTable
        pagination={false}
        columns={columns}
        data={ data?.data?.results ?? []}
        async={true}
        toolBar={true}
        sortable={true}
        resizable={true}
        isFetching={isFetching}
        title="Action Details"
        isDownloadable={true}
        handleSort={handleSort}
        tableHeight={`calc(100vh - ${TableHeight.WithOneColHeader})`}
        primaryAction={{ title: 'New', handlePrimaryAction: handleCreate }}
        workflowParams={{
          isDynamicMenu: true,
          handleMenuItems: handleDynamicmenu,
          handleRefresh: handleRefresh
        }}
        handleMenuActions={handleMenuActions}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },
          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
      />
      <VesselInspFindActionForm
        data={rowData}
        view={view}
        refetch={refetch}
        onClose={handleCloseForm}
        edit={edit}
        open={createOpen}
        title={`${edit ? 'Add ' : view ? 'View' : 'edit '} Action Details`}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        //onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
      />
    </div>
  )
}

export default VIActions

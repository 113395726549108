import { useEffect, useRef, useState } from 'react'
import { ExpandableAutoComplete } from 'react-core-ts'

type Props = {
  label?: string
  onChange: (e: any) => void
  value?: any
  disabled?: boolean
  autoFocus?: boolean
  fullWidth?: boolean
  required?: boolean
  errors?: any
  placeholder?: string
  name: string
  type: any
  desc: string
  descId: string
  selectedItems: any[]
  data: any[]
  isMultiple?: boolean
  handleAction?: () => void
  handleMultiChange?: (data: any) => void
  itemCount?: number
  textCount?: number
  getData?: ((key?: string) => Promise<any>) | ((key?: string) => any)
}

const QbsAutocompleteExpandableComponent = (props: Props) => {
  const {
    label,
    errors,
    onChange,
    disabled = false,
    required,
    fullWidth = false,
    placeholder,
    name,
    type,
    desc,
    descId,
    data,
    selectedItems,
    isMultiple = true,
    handleAction,
    itemCount = 5,
    textCount = 10,
    getData
  } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  const generateClassName = (type: 'input' | 'label' | 'message' | 'outer'): string => {
    let className = 'text-input-label '
    switch (type) {
      case 'input':
        /*if (errors && errors[name]) {
          className += ' border-error-light focus:border-error-light'
        }*/
        break
      case 'outer':
        if (errors && errors[name]) {
          className += ' qbs-error-input'
        }
        break
      default:
        break
    }
    return className
  }

  const getErrors = () => {
    return errors ? errors[name] : errors
  }

  return (
    <div className={fullWidth ? 'w-full' : 'w-auto'}>
      {label && (
        <div className="mb-2 ">
          <label className={`text-common`}>
            {label}
            {required ? <span className="text-error"> *</span> : <></>}
          </label>
        </div>
      )}
      <div className={`relative flex flex-col justify-end ${generateClassName('outer')}`}>
        <ExpandableAutoComplete
          key={name}
          name={name}
          type={type}
          ref={inputRef}
          desc={desc}
          descId={descId}
          onChange={(e: any) => onChange(e)}
          className={`${generateClassName('input')}`}
          selectedItems={selectedItems}
          label={''}
          errors={getErrors()}
          disabled={isDisabled}
          initialLoad={true}
          async={false}
          paginationEnabled={false}
          placeholder={placeholder}
          required={required}
          handleAction={handleAction}
          isMultiple={isMultiple}
          expandable={true}
          itemCount={itemCount}
          textCount={textCount}
          data={data}
          getData={getData}
          // onFocus={() => setIsFocused(true)}
        />
      </div>
    </div>
  )
}

export default QbsAutocompleteExpandableComponent

import { BreadcrumbItemProps } from '@/common/customComponenttypes'
import { Breadcrumb } from '@/components/common'

const BreadcrumbComponent = () => {
  const breadcrumbItems: BreadcrumbItemProps[] = [
    { label: 'Technical', link: '/' },
    { label: 'Vessel Particulars' }
  ]
  const breadcrumbIconItems: BreadcrumbItemProps[] = [
    { label: 'Technical', link: '/sdddd', icon: 'home' },
    { label: 'Vessel Particulars', icon: 'favourite' }
  ]
  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      <div className="py-3">
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <div className="py-3">
        <Breadcrumb items={breadcrumbIconItems} />
      </div>
    </div>
  )
}

export default BreadcrumbComponent

import useScreenBreakPoint from '@/utilities/useScreenBreakpoint'
import { useEffect } from 'react'
import { useCrewProfileStore } from './details/crewDetails/store'

const useGenerateCommonSpacing = () => {
  const expand = useCrewProfileStore.getState().expand || true

  const { width } = useScreenBreakPoint()
  const handleWindowSizeChange = () => {
    useCrewProfileStore.getState().setExpand(width < 1000 ? false : true)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  if (width > 1000) return expand ? 4 : 3

  return expand ? 6 : 4
}
export default useGenerateCommonSpacing

export const generateAddress = (add1: string, add2: string) => {
  if (add1 && add2) {
    return `${add1}, ${add2}`
  }
  if (add1) {
    return add1
  }
  if (add2) {
    return add2
  }
  return undefined
}

import { z } from 'zod'

const noLeadingSpaces = (value: string | null) => {
  if (value === null) return true // allow null values
  return value === value.trimStart()
}

export const managementSchema = z.object({
  PrevName: z
    .string()
    .max(100, { message: 'Must contain at most 100 character(s)' })
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  PrevOwnerName: z
    .string()
    .max(100, { message: 'Must contain at most 100 character(s)' })
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  PrevManagerName: z
    .string()
    .max(100, { message: 'Must contain at most 100 character(s)' })
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),

  DateOfOwnrChange: z.date().nullable().optional(),
  DateOfFlagChange: z.date().nullable().optional(),
  DateOfNameChange: z.date().nullable().optional(),
  MgmtStartDate: z.date().nullable().optional(),
  MgmtEndDate: z.date().nullable().optional(),
  DateOfMngrChange: z.date().nullable().optional(),
  PrevFlagId: z
    .number()
    .nullable()

    .optional(),
  PrevFlagName: z
    .string()
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  VesselId: z
    .string()
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional(),
  VesselName: z
    .string()
    .nullable()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    })
    .optional()
})

export default ManagementSchema
export type ManagementSchema = z.infer<typeof managementSchema>

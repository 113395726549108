import { FabProps } from '@/common/customComponenttypes'
import React from 'react'

import CustomIcons from '../icons/customIcons'

const Fab: React.FC<FabProps> = ({
  icon = 'edit',
  type = 'button',
  onClick = undefined,
  className = '',
  outlined = false,
  primary = true,
  size = '',
  isLoading = false,
  disabled = false,
  iconClass = '',
  icontType,
  iconViewBox
}) => {
  const generateClassName = () => {
    let genclass: string = `fab rounded-full `

    if (primary) {
      genclass += ` ${outlined ? 'btn-primary-outlined ' : 'btn-primary '}`
    } else {
      genclass += `${outlined ? 'btn-secondary-outlined ' : 'btn-secondary '}`
    }
    if (size === 'lg') {
      genclass += 'large'
    }
    if (size === 'sm') {
      genclass += ' small'
    }
    if (size === 'md') {
      genclass += 'medium'
    }
    if (size === 'xs') {
      genclass += ' extra-small'
    }
    if (className) {
      genclass += ` ${className}`
    }
    return genclass
  }
  return (
    <>
      <button
        type={type}
        disabled={disabled || isLoading}
        onClick={onClick}
        data-testid="app-common-fab"
        className={generateClassName()}
      >
        <div className="flex items-center justify-center">
          <CustomIcons
            name={icon}
            viewBox={iconViewBox}
            type={icontType ?? 'small'}
            className={`flex items-center justify-center   ${
              outlined ? '' : 'text-white'
            } ${iconClass}`}
          />
        </div>
      </button>
    </>
  )
}

export default Fab

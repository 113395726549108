import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const DashIconTable: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2 5.55556H18V2.22222H2V5.55556ZM12 17.7778V7.77778H8V17.7778H12ZM14 17.7778H18V7.77778H14V17.7778ZM6 17.7778V7.77778H2V17.7778H6ZM1 0H19C19.5523 0 20 0.497467 20 1.11111V18.8889C20 19.5026 19.5523 20 19 20H1C0.44772 20 0 19.5026 0 18.8889V1.11111C0 0.497467 0.44772 0 1 0Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DashIconTable

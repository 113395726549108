import { AccordionBodyProps } from '@/common/customComponenttypes'
import React from 'react'
import { useAccordion } from './AccordionContext'

const AccordionBody: React.FC<AccordionBodyProps> = ({ children, className }) => {
  const { open } = useAccordion()
  return (
    <>
      <div className={`${className} ${open ? 'block' : 'hidden'}`}>{children}</div>
    </>
  )
}

export default AccordionBody

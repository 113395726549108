import {
  deleteSystemData,
  getSystemData,
  postSystemData,
  updateSystemData
} from '@/apis/api.helpers'
import apiUrl from '@/apis/common.api.urls'
// import { useAuthStore } from '@/store/appStore'
import { useAuthStore } from '@/store/authStore'
import { dataParse } from '@/utilities'
import useCustomSnackBar from '@/utilities/customSnackBar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import decode from 'jwt-decode'

export const useDashBoradTabs = () => {
  const { accessToken } = useAuthStore()
  let payload = { UserId: '' }
  let userid = ''
  if (accessToken) {
    payload = decode(accessToken)
    userid = payload.UserId
  }

  const fetchData = async () => {
    return await getSystemData(`${apiUrl.GET_DASHBOARDS}?UserId=${userid}`)
  }

  return useQuery({
    queryKey: ['dashboard-tabs-list'],
    queryFn: () => fetchData(),
    refetchOnWindowFocus: false,
    staleTime: 0
  })
}
export const useDashBoradTiles = (dashid: string | number) => {
  const fetchData = async () => {
    const res =
      (await getSystemData(`${apiUrl.GET_DASHBOARD_TILES}?DashId=${dashid}`)) || []
    return res.map((item: any) => ({
      defaultTileType: item.TileGraph ? 0 : 2,
      TileName: item.TileName,
      TileId: item.TileId,
      DashTileId: item.DashTileId
    }))
  }

  return useQuery({
    queryKey: ['dashboard-tile-list', dashid],
    queryFn: () => fetchData(),
    refetchOnWindowFocus: false,
    staleTime: 0
  })
}
export const useDashBoradModules = (isOpen: boolean) => {
  const accessToken = useAuthStore.getState().accessToken
  let payload = { UserId: '' }
  let userid = ''
  if (accessToken) {
    payload = decode(accessToken)
    userid = payload.UserId
  }
  const fetchData = async () => {
    const res =
      (await getSystemData(`${apiUrl.GET_DASHBOARD_MODULES}?UserId=${userid}`)) || []
    const strecturedData = res.map((item: any) => ({
      id: item.ModuleId,
      name: item.ModuleName
    }))
    return strecturedData
  }

  return useQuery({
    queryKey: ['dashboard-module-list'],
    queryFn: () => fetchData(),
    refetchOnWindowFocus: false,
    staleTime: 500,
    enabled: isOpen
  })
}

export const getDashTileByModule = async (moduleId: string) => {
  const accessToken = useAuthStore.getState().accessToken
  let payload = { UserId: '' }
  let userid = ''
  if (accessToken) {
    payload = decode(accessToken)
    userid = payload.UserId
  }
  const res =
    (await getSystemData(`${apiUrl.GET_TILES}?UserId=${userid}&ModuleId=${moduleId}`)) ||
    []
  const strecturedData = res.map((item: any, key: number) => ({
    ...item,
    defaultTileType: key % 3
  }))
  return strecturedData
}
export const createDashboard = async (params: any) => {
  const accessToken = useAuthStore.getState().accessToken
  let payload = { UserId: '' }
  let userid = ''
  if (accessToken) {
    payload = decode(accessToken)
    userid = payload.UserId
  }
  const data = { DashId: 0, DashName: params.name, UserId: userid }
  return await postSystemData(`${apiUrl.ADD_DASHBOARDS}`, data)
}
export const renameDashboard = async (params: any) => {
  return await updateSystemData(`${apiUrl.RENAME_DASHBOARDS}${dataParse(params)}`, {})
}
export const updateDashboardTiles = async (data: any) => {
  return await updateSystemData(`${apiUrl.UPDATE_DASHBOARD_TILES}`, data)
}

export const useDashBoradCreate = (onSuccessAction: () => void) => {
  const { showSnackBar } = useCustomSnackBar()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createDashboard,
    mutationKey: ['create-dashboard'],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(['dashboard-tabs-list'])
      onSuccessAction()
      showSnackBar(response || 'Dashboard created successfully')
    }
  })
}
export const useDashBoradRename = (onSuccessAction: () => void) => {
  const { showSnackBar } = useCustomSnackBar()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: renameDashboard,
    mutationKey: ['rename-dashboard'],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(['dashboard-tabs-list'])
      onSuccessAction()
      showSnackBar(response || 'Dashboard renamed successfully')
    }
  })
}
export const useDashBoradTileUpdate = (
  onSuccessAction: () => void,
  dashboard_id: number | string
) => {
  const { showSnackBar } = useCustomSnackBar()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateDashboardTiles,
    mutationKey: ['create-dashboard-tile', dashboard_id],
    onError: (err: any) => {
      showSnackBar(err.response.data.error || 'Something went wrong', 'error')
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(['dashboard-tile-list', dashboard_id])
      onSuccessAction()
      // showSnackBar(response || 'Tiles updated successfully')
      showSnackBar('Dashboard updated successfully')
    }
  })
}
export const deleteDashboardTile = async (data: any) => {
  return await deleteSystemData(`${apiUrl.DELETE_DASHBOARD_TILES}${dataParse(data)}`)
}
export const deleteDashboard = async (data: any) => {
  return await deleteSystemData(`${apiUrl.DELETE_DASHBOARD}${dataParse(data)}`)
}

import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const ClosePopupIcon: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#EDF2F6" />
      <path
        d="M13.6688 23.1187L12.8813 22.3312L17.2125 18L12.8813 13.6687L13.6688 12.8812L18 17.2125L22.3313 12.8812L23.1188 13.6687L18.7875 18L23.1188 22.3312L22.3313 23.1187L18 18.7875L13.6688 23.1187Z"
        fill="black"
      />
    </svg>
  )
}

export default ClosePopupIcon

import React from 'react'
import { useParams } from 'react-router-dom'
import CrewDetailsComponent from './DetailPageContents'
const CrewDetails: React.FC = () => {
  const params = useParams()

  return <CrewDetailsComponent crewId={params?.id as string} pageRouting={true} />
}

export default CrewDetails

import React from 'react'
const BusinessUnitIcon: React.FC = () => {
  return (
    <>
      <g clipPath="url(#clip0_2660_4923)">
        <path
          d="M2 22V6.58333H6.58333V2H17.4167V11.1667H22V22H13.4722V17.4167H10.5278V22H2ZM3.66667 20.3333H6.58333V17.4167H3.66667V20.3333ZM3.66667 15.75H6.58333V12.8333H3.66667V15.75ZM3.66667 11.1667H6.58333V8.25H3.66667V11.1667ZM8.25 15.75H11.1667V12.8333H8.25V15.75ZM8.25 11.1667H11.1667V8.25H8.25V11.1667ZM8.25 6.58333H11.1667V3.66667H8.25V6.58333ZM12.8333 15.75H15.75V12.8333H12.8333V15.75ZM12.8333 11.1667H15.75V8.25H12.8333V11.1667ZM12.8333 6.58333H15.75V3.66667H12.8333V6.58333ZM17.4167 20.3333H20.3333V17.4167H17.4167V20.3333ZM17.4167 15.75H20.3333V12.8333H17.4167V15.75Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2660_4923">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </>
  )
}
export default BusinessUnitIcon

import { FormBuilderProps } from '@/common/customComponenttypes'
import { FormBuilder } from '@/components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { OptionValueProps } from '.'
export type OptionProps = {
  id: OptionValueProps
  name: string
  icon:string
}

type Props = {
  clearDatas: any

}
const ManageBudgetBasicDetails: React.FC<Props> = ({ clearDatas }) => {
  const { t } = useTranslation()

  const formFields: FormBuilderProps[] = [
    {
      label: t('Budget Type'),
      placeholder: t('Enter Budget Type'),
      name: 'BudgetTypeName',
      id: 'BudgetTypeId',
      callbackFn: clearDatas,
      autoFilter: true,
      required: true,
      type: 'custom_select',
      apiParams: { key: 'BUDGETTYPE' },
      spacing: 12
    },
    {
      label: t('Fleet'),
      placeholder: t('Enter Fleet'),
      name: 'EntityName',
      id: 'EntityId',
      callbackFn: clearDatas,
      required: true,
      type: 'autocomplete',
      apiParams: { key: 'ENTITYFLEET' },
      spacing: 12
    },
    {
      label: t('Budget Year'),
      placeholder: t('Budget Year'),
      name: 'BudgetYear',
      id: 'BudgetYear',
      callbackFn: clearDatas,
      required: true,
      showYearPicker: true,
      type: 'date',
      spacing: 12
    }
  ]

  return (
    <div className={`flex h-full w-full items-center justify-center p-5`}>
      <div className="bg-white w-[466px] p-5 rounded-[8px]">
        <p className="text-m font-bold text-dark pb-2.5">Enter Budget Details</p>
        <p className="text-grey-secondary text-common font-normal text-wrap w-[278px] pb-[24px]">
          Please specify the budget details.
        </p>
        <div className="pb-[30px]">
          <div className="flex flex-col gap-y-5">
            <FormBuilder data={formFields} edit={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageBudgetBasicDetails

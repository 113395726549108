import { noLeadingSpaces, validateNumberOfDigits } from '@/utilities'
import { z } from 'zod'

export const FormSchema = z.object({
  seaTrialDate: z.date({
    required_error: 'Sea Trial Date is required',
    invalid_type_error: 'Sea Trial Date is required'
  }),
  materialTypeId: z.number({
    required_error: 'Engine is required',
    invalid_type_error: 'Engine is Required'
  }),
  materialTypeName: z
    .string({
      required_error: 'Engine is required',
      invalid_type_error: 'Engine is required'
    })
    .min(1, { message: 'Engine is Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed'
    }),
  carbonFactor: z
    .string()
    .refine((value) => value === '' || !isNaN(Number(value)), {
      message: 'Must be a valid number'
    })
    .refine((value) => validateNumberOfDigits(value, 16), {
      message: 'Max length for Carbon Factor is 16'
    })
    .transform((value) => (value === '' ? undefined : Number(value)))
    .optional()
    .nullable(),
  barredSpeedMax: z
    .number({
      required_error: 'Barred Speed Max is required',
      invalid_type_error: 'Barred Speed Max is Required'
    })
    .refine(validateNumberOfDigits, {
      message: 'Max length for Barred Speed Max is 8'
    })
    .nullable(),
  barredSpeedMin: z
    .number({
      invalid_type_error: 'Barred Speed Min is Required'
    })
    .refine(validateNumberOfDigits, {
      message: 'Max length for Barred Speed Min is 8'
    })
    .optional()
    .nullable(),
  vesSeaTrialId: z.string().optional().nullable(),
  vesselId: z.string()
})

export type FormDataType = z.infer<typeof FormSchema>

import { CustomKendoTable, DeletePopUp } from '@/components/common'
import { defaultPageSize, TableHeight } from '@/constants'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import { useInspAuditLog } from './api'
import { VesselInspectionContextProps } from './schema'

type PageParams = {
  pageNo: number
  ObjectId?: string
  pageSize: number
}
const sortFields = ['LogDate']
const VesselInspctionAuditLog: React.FC = () => {
  const { inspID } = useOutletContext<VesselInspectionContextProps>()

  const [total, setTotal] = useState(0)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    ObjectId: inspID,
    pageSize: defaultPageSize
  })

  const { data, refetch, isFetching } = useInspAuditLog(params)
  const columns = [
    {
      title: 'User',
      field: 'UserId',
      sort: true,
      width: 150
    },
    {
      title: 'Log Date',
      field: 'LogDate',
      sort: true,
      width: 230,
      type: 'dateSec'
    },
    {
      title: 'Description',
      field: 'LogDesc',
      sort: true
    }
  ]

  useEffect(() => {
    setTotal(data?.TotalRecords)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])
  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }

  return (
    <div>
      <CustomKendoTable
        pagination={true}
        columns={columns}
        data={data?.Results}
        toolBar={true}
        sortable={true}
        resizable={true}
        isFetching={isFetching}
        title="Audit Log"
        isDownloadable={true}
        handleSort={handleSort}
        defaultSort={sortFields[0]}
        tableHeight={`calc(100vh - ${TableHeight.WithHeader})`}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },
          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
      />
      <DeletePopUp
        isOpen={deleteOpen}
        // onSubmit={handleDelete}
        onClose={() => setDeleteOpen(false)}
      />
    </div>
  )
}

export default VesselInspctionAuditLog

import { Navigate } from 'react-router-dom'

import { Loader } from '@/components/common'
import Layout from '@/layout'
import { useAuthStore } from '@/store/authStore'
import React, { Suspense } from 'react'
type Props = {
  children: React.ReactNode
}
const PrivateRoute = ({ children }: Props) => {
  const { authenticated } = useAuthStore()

  return authenticated ? (
    <Layout>
      <Suspense fallback={<Loader isActive />}>{children}</Suspense>
    </Layout>
  ) : (
    <Navigate to="/login" replace={true} />
  )
}
export default PrivateRoute

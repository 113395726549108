import React from 'react'
import { useOutletContext } from 'react-router-dom'

import CrewBasicInfoDetails from './crewBasicInfoDetails'

const CrewBasicInfo: React.FC = () => {
  const { crewId }: { crewId: string } = useOutletContext()

  return <CrewBasicInfoDetails crewId={crewId} pageRouting={true} />
}

export default CrewBasicInfo

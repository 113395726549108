import { IconProps } from '@/common/customComponenttypes'
import React from 'react'

const ForwardArrow: React.FC<IconProps> = ({ className = '', stroke = 1.5 }) => {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.01995 5.27143C6.01995 5.17835 6.00444 5.09302 5.97341 5.01546C5.94238 4.93789 5.88809 4.86032 5.81052 4.78275L1.20298 0.175212C1.07887 0.0511036 0.912099 -0.00707238 0.702665 0.000684424C0.493231 0.00844122 0.32646 0.074374 0.202351 0.198483C0.0472153 0.353619 -0.0187175 0.52039 0.0045529 0.698796C0.0278233 0.877203 0.101513 1.03622 0.225622 1.17584L4.32121 5.27143L0.225622 9.36702C0.101513 9.49113 0.0317017 9.6579 0.0161881 9.86734C0.000674504 10.0768 0.0704857 10.2435 0.225622 10.3676C0.349731 10.5228 0.512623 10.5887 0.7143 10.5654C0.915977 10.5422 1.08663 10.4685 1.22625 10.3444L5.81052 5.76011C5.88809 5.68254 5.94238 5.60497 5.97341 5.52741C6.00444 5.44984 6.01995 5.36451 6.01995 5.27143Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ForwardArrow

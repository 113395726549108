import { coreModules } from '../store'
import coreAPi from './core'

const executeApiCall = (
  apiType: string,
  method: 'get' | 'put' | 'post' | 'delete',
  url: string,
  params?: any,
  responseType: any = 'json'
) => {
  return coreAPi(apiType)
    .serverApi[method](url, params, {
      headers: {
        // 'Content-Type': 'image/png'
      },
      responseType: responseType
    })
    .then((res: any) => res.data)
}

// System Data APIs
export const getSystemData = (url: string) =>
  executeApiCall(coreModules.SYSTEM, 'get', url)
export const postSystemData = (url: string, params: any) =>
  executeApiCall(coreModules.SYSTEM, 'post', url, params)
export const postJsonSystemData = (url: string, params: any) =>
  executeApiCall(coreModules.SYSTEM, 'post', url, params)
export const updateSystemData = (url: string, params: any) =>
  executeApiCall(coreModules.SYSTEM, 'put', url, params)
export const deleteSystemData = (url: string) =>
  executeApiCall(coreModules.SYSTEM, 'delete', url)

// System MIPS Data APIs
export const getSystemMipsData = (url: string, reponseType?: any) =>
  executeApiCall(coreModules.SYSTEMMIPS, 'get', url, reponseType)
export const postSystemMipsData = (url: string, params: any) =>
  executeApiCall(coreModules.SYSTEMMIPS, 'post', url, params)
export const postJsonSystemMipsData = (url: string, params: any) =>
  executeApiCall(coreModules.SYSTEMMIPS, 'post', url, params)
export const updateSystemMipsData = (url: string, params: any) =>
  executeApiCall(coreModules.SYSTEMMIPS, 'put', url, params)
export const deleteSystemMipsData = (url: string) =>
  executeApiCall(coreModules.SYSTEMMIPS, 'delete', url)

// Purchase Data APIs
export const getPurchaseData = (url: string) =>
  executeApiCall(coreModules.MASTERDATA, 'get', url)
export const postPurchaseData = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATA, 'post', url, params)
export const postJsonPurchaseData = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATA, 'post', url, params)
export const updatePurchaseData = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATA, 'put', url, params)
export const deletePurchaseData = (url: string) =>
  executeApiCall(coreModules.MASTERDATA, 'delete', url)
// Master Data APIs
export const getMasterData = (url: string) =>
  executeApiCall(coreModules.MASTERDATA, 'get', url)
export const postMasterData = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATA, 'post', url, params)
export const postJsonMasterData = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATA, 'post', url, params)
export const updateMasterData = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATA, 'put', url, params)
export const deleteMasterData = (url: string) =>
  executeApiCall(coreModules.MASTERDATA, 'delete', url)
// Master Data MIPS APIs
export const getMasterDataMips = (url: string) =>
  executeApiCall(coreModules.MASTERDATAMIPS, 'get', url)
export const postMasterDataMips = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATAMIPS, 'post', url, params)
export const postJsonMasterDataMips = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATAMIPS, 'post', url, params)
export const updateMasterDataMips = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATAMIPS, 'put', url, params)
export const deleteMasterDataMips = (url: string) =>
  executeApiCall(coreModules.MASTERDATAMIPS, 'delete', url)
// Technical Data APIs
export const getTechnicalData = (url: string) =>
  executeApiCall(coreModules.TECHNICAL, 'get', url)
export const postTechnicalData = (url: string, params: any) =>
  executeApiCall(coreModules.TECHNICAL, 'post', url, params)
export const postJsonTechnicalData = (url: string, params: any) =>
  executeApiCall(coreModules.TECHNICAL, 'post', url, params)
export const updateTechnicalData = (url: string, params: any) =>
  executeApiCall(coreModules.TECHNICAL, 'put', url, params)
export const deleteTechnicalData = (url: string) =>
  executeApiCall(coreModules.TECHNICAL, 'delete', url)
// Technical MIPS Data APIs
export const getTechnicalMipsData = (url: string) =>
  executeApiCall(coreModules.TECHNICALMIPS, 'get', url)
export const postTechnicalMipsData = (url: string, params: any) =>
  executeApiCall(coreModules.TECHNICALMIPS, 'post', url, params)
export const postJsonTechnicalMipsData = (url: string, params: any) =>
  executeApiCall(coreModules.TECHNICALMIPS, 'post', url, params)
export const updateTechnicalMipsData = (url: string, params: any) =>
  executeApiCall(coreModules.TECHNICALMIPS, 'put', url, params)
export const deleteTechnicalMipsData = (url: string) =>
  executeApiCall(coreModules.TECHNICALMIPS, 'delete', url)
// Operations Data APIs
export const getOperationsData = (url: string) =>
  executeApiCall(coreModules.OPERATIONS, 'get', url)
export const postOperationsData = (url: string, params: any) =>
  executeApiCall(coreModules.OPERATIONS, 'post', url, params)
export const postJsonOperationsData = (url: string, params: any) =>
  executeApiCall(coreModules.OPERATIONS, 'post', url, params)
export const updateOperationsData = (url: string, params?: any) =>
  executeApiCall(coreModules.OPERATIONS, 'put', url, params)
export const deleteOperationsData = (url: string) =>
  executeApiCall(coreModules.OPERATIONS, 'delete', url)
// Finance Data APIs
export const getFinanceData = (url: string) =>
  executeApiCall(coreModules.MASTERDATA, 'get', url)
export const postFinanceData = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATA, 'post', url, params)
export const postJsonFinanceData = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATA, 'post', url, params)
export const updateFinanceData = (url: string, params: any) =>
  executeApiCall(coreModules.MASTERDATA, 'put', url, params)
export const deleteFinanceData = (url: string) =>
  executeApiCall(coreModules.MASTERDATA, 'delete', url)
// Pms Data APIs
export const getPmsData = (url: string) => executeApiCall(coreModules.PMS, 'get', url)
export const postPmsData = (url: string, params: any) =>
  executeApiCall(coreModules.PMS, 'post', url, params)
export const postJsonPmsData = (url: string, params: any) =>
  executeApiCall(coreModules.PMS, 'post', url, params)
export const updatePmsData = (url: string, params: any) =>
  executeApiCall(coreModules.PMS, 'put', url, params)
export const deletePmsData = (url: string) =>
  executeApiCall(coreModules.PMS, 'delete', url)

// Crewing MIPS Data APIs
export const getCrewData = (url: string) =>
  executeApiCall(coreModules.CREWING, 'get', url)
export const postCrewData = (url: string, params?: any) =>
  executeApiCall(coreModules.CREWING, 'post', url, params)
export const postJsonCrewData = (url: string, params: any) =>
  executeApiCall(coreModules.CREWING, 'post', url, params)
export const updateCrewData = (url: string, params: any) =>
  executeApiCall(coreModules.CREWING, 'put', url, params)
export const deleteCrewData = (url: string) =>
  executeApiCall(coreModules.CREWING, 'delete', url)

// Payroll MIPS Data APIs
export const getPayrollData = (url: string) =>
  executeApiCall(coreModules.PAYROLL, 'get', url)
export const postPayrollData = (url: string, params: any) =>
  executeApiCall(coreModules.PAYROLL, 'post', url, params)
export const postJsonPayrollData = (url: string, params: any) =>
  executeApiCall(coreModules.PAYROLL, 'post', url, params)
export const updatePayrollData = (url: string, params: any) =>
  executeApiCall(coreModules.PAYROLL, 'put', url, params)
export const deletePayrollData = (url: string) =>
  executeApiCall(coreModules.PAYROLL, 'delete', url)

// Pms Mips Data APIs
export const getPmsMipsData = (url: string) =>
  executeApiCall(coreModules.PMSMIPS, 'get', url)
export const postPmsMipsData = (url: string, params: any) =>
  executeApiCall(coreModules.PMSMIPS, 'post', url, params)
export const postJsonPmsMipsData = (url: string, params: any) =>
  executeApiCall(coreModules.PMSMIPS, 'post', url, params)
export const updatePmsMipsData = (url: string, params: any) =>
  executeApiCall(coreModules.PMSMIPS, 'put', url, params)
export const deletePmsMipsData = (url: string) =>
  executeApiCall(coreModules.PMSMIPS, 'delete', url)

import { TabItemProps } from '@/common/customComponenttypes'
import { HeaderWrapper, SectionWraper } from '@/components/app/common'
import DetailHeaderComponent, {
  MainContentProps,
  SubContentProps
} from '@/components/app/common/DetailHeaderComponent'
import { Breadcrumb, Tab, TabContainer } from '@/components/common'
import { routerStore } from '@/store'
import { useAppStore } from '@/store/appStore'
import { detailHeaderBackground } from '@/styles/variables'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getBudgDynamicMenu } from '../api'
import { getbudgetDetails } from './api'
let updateKey = 1
const VesselBudgetDetails: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { setIsLoading, isLoading } = useAppStore()
  const [details, setDetails] = useState<any>()
  const baseURL = `${routerStore.VESSEL_BUDGET}/${params.budgetID}`
  const items = location?.pathname?.split('/')
  const lastItem = items[items.length - 1]
  const [activeTab, setActiveTab] = useState<number | string>(lastItem)

  const { t } = useTranslation()

  const breadcrumbConfig = [
    {
      label: t('technical.technical'),
      link: routerStore.VESSEL_BUDGET
    },
    {
      label: t('Vessel Budgets'),
      link: routerStore.VESSEL_BUDGET
    },
    { label: 'Budget Details' }
  ]
  const getData = async () => {
    setIsLoading(true)
    const data = await getbudgetDetails(params.budgetID as string)
    setDetails(data)
    setIsLoading(false)

    updateKey = updateKey + 1
  }

  useEffect(() => {
    setActiveTab(lastItem)
  }, [lastItem])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (params.budgetID) {
      getData()
    }
  }, [params.budgetID])
  const detailHeaders: MainContentProps[] = [
    { data: details?.entityName, type: 'title' },
    { data: details?.statusName, type: 'status' }
  ]
  const detailContents: SubContentProps[] = [
    {
      label: t('Title'),
      value: details?.budgetTitle,
      spacing: 3,
      hasDivider: true
    },
    {
      label: t('Type'),
      value: details?.budgetTypeName,
      spacing: 3
    },
    {
      label: t('Budget Amount'),
      value: details?.budget,
      spacing: 3
    },
    {
      label: t('Budget Option'),
      value: details?.budgetCreationType,
      spacing: 3
    },
    {
      label: t('Currency'),
      value: details?.currencyId,
      spacing: 3
    },
    {
      label: t('Year'),
      value: details?.budgetYear,
      spacing: 3
    },
    {
      label: t('Entity Count'),
      value: details?.entityCount,
      spacing: 3
    }
  ]
  const onTabClick = (selection: TabItemProps) => {
    setActiveTab(selection.id)
    navigate(`${baseURL}/${selection.id as string}`)
  }
  const tabData: TabItemProps[] = [
    {
      label: 'Entities',
      id: routerStore.VESSEL_BUDGET_ENTITIES
    },
    {
      label: 'Budget Details',
      id: routerStore.VESSEL_BUDGET_DETAILS_TAB
    },
    {
      label: 'Assumptions',
      id: routerStore.VESSEL_BUDGET_ASSUMPTIONS
    },
    {
      label: 'Audit Log',
      id: routerStore.VESSEL_BUDGET_AUDITLOG
    }
  ]
  const handleDynamicmenu = async (row: any) => {
    const data = await getBudgDynamicMenu(params.budgetID as string, 'det')
    return data
  }
  const contextData = {
    detailData: details,
    handleRefresh: getData,
    BudgetId: params.budgetID,
    VesselID: details?.entityId
  }
  return (
    <>
      <Breadcrumb items={breadcrumbConfig} />
      <div className={detailHeaderBackground}>
        <HeaderWrapper search={false}>
          <DetailHeaderComponent
            detailId={params.budgetID}
            detailHeaders={detailHeaders}
            isLoading={isLoading}
            detailContents={detailContents}
            avatar="/assets/coinStacked.svg"
            // actionDropDown={headerActions}
            // handleMenuActions={handleMenuActions}
            workflowParams={{
              isDynamicMenu: true,
              handleMenuItems: handleDynamicmenu,
              handleRefresh: getData
            }}
          />
        </HeaderWrapper>
      </div>
      <SectionWraper>
        <TabContainer
          data={tabData}
          activeTab={activeTab}
          isContainer={activeTab === routerStore.VESSEL_BUDGET_ASSUMPTIONS ? false : true}
          onClick={onTabClick}
          tabBGClass="bg-tab-detail  px-[15px]  rounded-sm"
        >
          <Tab>
            <Outlet context={contextData} key={updateKey} />
          </Tab>
        </TabContainer>
      </SectionWraper>
    </>
  )
}

export default VesselBudgetDetails

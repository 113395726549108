import { z } from 'zod'

function amountSchema() {
  return z
    .union([z.string(), z.number()])
    .transform((value) => {
      if (typeof value === 'number') {
        return value.toString()
      } else if (typeof value === 'string') {
        return value.trim()
      }
      return ''
    })
    .refine(
      (value) => {
        return value === '' || /^\d+(\.\d{0,2})?$/.test(value)
      },
      {
        message: 'Enter a valid amount.'
      }
    )
}
export const BudgetItemSchema = z.object({
  ItemDescription: z
    .string({
      required_error: 'Item description is required',
      invalid_type_error: 'Item description is required'
    })
    .min(1, 'Item description is required')
    .max(100, 'Item description should not exceed 100 characters'),
  Budget: z
    .union([
      z
        .string({
          required_error: 'Budgets is required',
          invalid_type_error: 'Budgets is required'
        })
        .min(1, { message: 'Budgets is required' }),
      z.number()
    ])

    .transform((value) => (value === null || value.toString() === '0' ? '' : value))
    .refine(
      (value) => {
        if (value.toString() === '0') return false
        return (
          /^\d+(?:\.\d{0,2})?$/.test(value.toString()) && value.toString().length <= 10
        )
      },
      {
        message: 'Budget must be 10 digits or fewer'
      }
    ),
  SplitEqually: z.boolean().optional().nullable(),
  BudgetDetails: z
    .object({
      Amount_01: amountSchema(),
      Amount_02: amountSchema(),
      Amount_03: amountSchema(),
      Amount_04: amountSchema(),
      Amount_05: amountSchema(),
      Amount_06: amountSchema(),
      Amount_07: amountSchema(),
      Amount_08: amountSchema(),
      Amount_09: amountSchema(),
      Amount_10: amountSchema(),
      Amount_11: amountSchema(),
      Amount_12: amountSchema(),
      BrBudget: z.number().optional().nullable(),
      Budget: amountSchema(),
      Assumption: z.string().optional().nullable(),
      BudgetEntityId: z.string(),
      BudgetDetailId: z.string(),
      BudgetItemId: z.string(),
      Daily: z.number().optional().nullable(),
      Days: z.number().optional().nullable(),
      Disable_1: z.boolean().optional().nullable(),
      Disable_2: z.boolean().optional().nullable(),
      Disable_3: z.boolean().optional().nullable(),
      Disable_4: z.boolean().optional().nullable(),
      Disable_5: z.boolean().optional().nullable(),
      Disable_6: z.boolean().optional().nullable(),
      Disable_7: z.boolean().optional().nullable(),
      Disable_8: z.boolean().optional().nullable(),
      Disable_9: z.boolean().optional().nullable(),
      Disable_10: z.boolean().optional().nullable(),
      Disable_11: z.boolean().optional().nullable(),
      Disable_12: z.boolean().optional().nullable(),
      LastBrBudget: z.number().optional().nullable(),
      EndDate: z.string().optional().nullable(),
      StartDate: z.string().optional().nullable(),
      EntityName: z.string().optional().nullable(),
      EndMonth: z.number().optional().nullable(),
      LogDesc: z.string().optional().nullable(),
      MonthDiff: z.number().optional().nullable(),
      Months: z.number().optional().nullable(),
      SplitTypeId: z.number(),
      SplitTypeName: z.string().optional().nullable(),
      StartMonth: z.number().optional().nullable()
    })
    .array(),
  BudgetItemId: z.string(),
  BudgetAccountId: z.string(),
  Daily: z.number(),
  Days: z.number(),
  AccountDescription: z.string(),
  AccountId: z.string(),
  Assumption: z.string().optional().nullable()
})

export type BudgetItemDataType = z.infer<typeof BudgetItemSchema>

import React from 'react'

type Props = {
  children: React.ReactNode
  className?: string
}
const SectionWraper: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={`p-[15px] flex flex-col gap-[15px] ${className}`}>{children}</div>
  )
}

export default SectionWraper

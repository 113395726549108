import CustomColorSelect from '@/components/common/inputs/CustomColorSelect'
import { useState } from 'react'

const ColorFieldComponent = () => {
  const [val, setVal] = useState('#000000')
  const handleChange = (e: any) => {
    setVal(e?.target?.value)
  }
  return (
    <form>
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="py-3">
          <div className="flex p-2">
            <CustomColorSelect
              id="color"
              name="color"
              onChange={handleChange}
              value={val}
              label="Color Field"
              disabled={false}
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default ColorFieldComponent

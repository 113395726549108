import { CustomKendoTable } from '@/components/common'
import { TableHeight, defaultPageSize, isAllow } from '@/constants'
import { routerStore } from '@/store'
import { useAppStore } from '@/store/appStore'
import useHasPermission from '@/utilities/checkActionPermission'
import React, { useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { CreateInspectionFindDetails } from '../../../../components/app/vesselInspections/inspections/finddetails/InspectionForm'
import { getInspFindingDynamicMenu, useInspFinding } from './api'
import { VesselInspectionContextProps } from './schema'

const sortFields = [
  'FindReferenceNo',
  'FindingTypeName',
  'FindCategoryName',
  'TargetDate',
  'SystemAreaName',
  'StatusName'
]
type PageParams = {
  pageNo: number
  pageSize: number
  InspectionId?: string
  inspID?: string
  VesselId?: string
  archived:boolean
}
const VesselInspctionFindings: React.FC = () => {
  const { setIsLoading } = useAppStore()
  const [viewable, setViewable] = useState(false)
  const hasPermission = useHasPermission('Inspection')
  const { inspID, VesselId, InspectionTypeId } =
    useOutletContext<VesselInspectionContextProps>()

  const initData = {
    VesselId: VesselId,
    InspectionId: inspID,
    InspectionTypeId: InspectionTypeId
  }
  const [total, setTotal] = useState(0)

  const [edit, setEdit] = useState(true)
  const [createOpen, setCreateOpen] = useState(false)
  const [rowData, setRowData] = useState<any>(initData)

  const [params, setPrams] = useState<PageParams>({
    pageNo: 1,
    pageSize: defaultPageSize,
    InspectionId: inspID,
    VesselId: VesselId,
    archived:false
  })

  const { data, refetch, isFetching } = useInspFinding(params)

  const columns = [
    {
      title: 'Ref.No',
      field: 'findReferenceNo',
      sort: true,
      width: 100
    },
    {
      title: 'Type',
      field: 'findingTypeName',
      sort: true,
      width: 150,
      action: true,
      render: (row: any) => (
        <Link
          to={`${routerStore.VI_DETAILS_FINDINGS_DETAILS_MAIN}/${row?.findingId}/${inspID}/${routerStore.VI_DETAILS_GENERAL_INFO}`}
          className="kendo-column-text-link  cursor-pointer truncate"
        >
          {row?.findingTypeName}
        </Link>
      )
    },
    {
      title: 'Category',
      field: 'findCategoryName',
      sort: true
    },
    {
      title: 'Target Date',
      field: 'targetDate',
      sort: true,
      type: 'date',
      align: 'left',
      width: 130
    },
    {
      title: 'System Area',
      field: 'systemAreaName',
      sort: true,
      width: 200
    },
    {
      title: 'Status',
      field: 'statusName',
      sort: true,
      width: 140
    }
  ]

  useEffect(() => {
    setTotal(data?.totalRecords)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])

  const handleCloseForm = () => {
    setCreateOpen(false)
    setRowData(initData)
    setEdit(false)
    setViewable(false)
  }

  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
  }

  const handleCreate = async () => {
    setRowData(initData)
    setIsLoading(true)
    setViewable(false)
    const permission = await hasPermission({
      UserAction: isAllow.CREATE,
      AccessKey: '',
      ID1: ''
    })
    setEdit(false)
    setCreateOpen(permission)
    setIsLoading(false)
  }

  
  const handleToolActions = (slug: string) => {
    if (slug === 'archived') {
      setPrams({ ...params, archived: !params?.archived, pageNo: 1 })
    }
  }

  const getArchiveText = () => (params?.archived ? 'Hide Archived' : 'Show Archived')
  const handleDynamicmenu = async (row: any) => {
    const data = await getInspFindingDynamicMenu(row.findingId, 'grid')
    return data
  }
  return (
    <div>
      <CustomKendoTable
        pagination={true}
        toolBar={true}
        sortable={true}
        resizable={true}
        async={true}
        columns={columns}
        data={data?.results}
        isFetching={isFetching}
        workflowParams={{
          isDynamicMenu: true,
          handleMenuItems: handleDynamicmenu,
          handleRefresh: refetch
        }}
        defaultSort={sortFields[0]}
        title="Finding Details"
        isDownloadable={true}
        handleSort={handleSort}
        tableHeight={`calc(100vh - ${TableHeight.WithHeader})`}
        primaryAction={{ title: 'New', handlePrimaryAction: handleCreate }}
        handleToolActions={handleToolActions}
        actionToolDropDown={[
          {
            title: getArchiveText(),
            iconName: 'archieve',
            slug: 'archived',
            id: '1'
          }
        ]}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination(row) {
            setPrams((prev) => ({
              ...prev,
              pageNo: row
            }))
          },

          onRowsPerPage(row) {
            setPrams((prev) => ({
              ...prev,
              pageSize: row,
              pageNo: 1
            }))
          }
        }}
      />
      <CreateInspectionFindDetails
        //edit={edit}
        data={rowData}
        onClose={handleCloseForm}
        open={createOpen}
        view={viewable}
        title={`${
          edit ? 'Edit ' : viewable ? 'View' : 'Add '
        } Inspection Finding Details`}
        refetch={refetch}
      />
    </div>
  )
}

export default VesselInspctionFindings

import { CustomKendoTable } from '@/components/common'
import { defaultPageSize, TableHeight } from '@/constants'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import { useAudiLogList } from './api'
import { VesselBudgetAuditContextProps } from './schema'
import { useAuditlogFilterStore } from './store'

const sortFields = ['LogDate']

const VesselBudgetAuditLog: React.FC = () => {
  const { BudgetId, VesselID } = useOutletContext<VesselBudgetAuditContextProps>()

  const { setSortBy, setPageNo, setPageSize } = useAuditlogFilterStore()

  const [total, setTotal] = useState(0)

  const [params, setPrams] = useState({
    pageNo: 1,
    pageSize: defaultPageSize,
    EntityFiltType: 0,
    NameOrDesc: '',
    ObjectId: BudgetId,
    ObjectTypeId: '',
    PMSVessels: true,
    VesFiltType: 0,
    VesselId: VesselID,
    sortBy: ''
  })

  const { data, refetch, isFetching } = useAudiLogList(params)

  const columns = [
    {
      title: 'User',
      field: 'UserId',
      sort: true,
      width: 200
    },
    {
      title: 'Log Date',
      field: 'LogDate',
      sort: true,
      type: 'dateSec',
      align: 'left',
      width: 200
    },
    {
      title: 'Description',
      field: 'LogDesc',
      sort: true
    }
  ]

  useEffect(() => {
    setTotal(data?.TotalRecords)
  }, [data])

  useEffect(() => {
    refetch()
  }, [params])

  const onPagination = (row: number) => {
    setPrams((prev) => ({
      ...prev,
      pageNo: row
    }))
    setPageNo(row)
  }

  const onRowsPerPage = (row: number) => {
    setPrams((prev) => ({
      ...prev,
      pageSize: row,
      pageNo: 1
    }))
    setPageNo(1)
    setPageSize(row)
  }

  const handleSort = (order: string) => {
    setPrams((prev) => ({
      ...prev,
      sortBy: order
    }))
    setSortBy(order)
  }
  return (
    <div>
      <CustomKendoTable
        columns={columns}
        data={data?.Results}
        isFetching={isFetching}
        pagination={true}
        defaultSort={sortFields[0]}
        title="Audit Log"
        toolBar={true}
        sortable={true}
        resizable={true}
        isDownloadable={true}
        handleSort={handleSort}
        tableHeight={`calc(100vh - ${TableHeight.WithHeader})`}
        paginationProps={{
          total: total,
          currentPage: params.pageNo,
          rowsPerPage: params.pageSize,
          onPagination,
          onRowsPerPage
        }}
        adjustWidth={16}
      />
    </div>
  )
}

export default VesselBudgetAuditLog

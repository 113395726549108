const ArchieveList = () => {
  return (
    <>
      <path
        d="M1.83334 4.58333C1.83334 3.71892 1.83334 3.28717 2.10192 3.01858C2.3705 2.75 2.80225 2.75 3.66667 2.75H18.3333C19.1978 2.75 19.6295 2.75 19.8981 3.01858C20.1667 3.28717 20.1667 3.71892 20.1667 4.58333C20.1667 5.44775 20.1667 5.8795 19.8981 6.14808C19.6295 6.41667 19.1978 6.41667 18.3333 6.41667H3.66667C2.80225 6.41667 2.3705 6.41667 2.10192 6.14808C1.83334 5.8795 1.83334 5.44775 1.83334 4.58333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3966 7.79163C18.5258 7.79163 18.6588 7.79163 18.7917 7.78979V11.9166C18.7917 15.3734 18.7917 17.1022 17.7173 18.1756C16.6439 19.25 14.9151 19.25 11.4583 19.25H10.5417C7.08492 19.25 5.35609 19.25 4.28267 18.1756C3.20834 17.1022 3.20834 15.3734 3.20834 11.9166V7.78979C3.34125 7.79163 3.47417 7.79163 3.60342 7.79163H18.3966ZM13.7491 11.1741C13.8166 11.2343 13.8715 11.3071 13.9108 11.3885C13.9501 11.4699 13.973 11.5583 13.9781 11.6486C13.9832 11.7388 13.9705 11.8292 13.9406 11.9145C13.9108 11.9998 13.8644 12.0784 13.8041 12.1458L10.5307 15.8125C10.4662 15.8847 10.3872 15.9425 10.2988 15.9821C10.2104 16.0217 10.1146 16.0421 10.0178 16.0421C9.92095 16.0421 9.8252 16.0217 9.73682 15.9821C9.64844 15.9425 9.56941 15.8847 9.50492 15.8125L8.19592 14.3458C8.13511 14.2786 8.08819 14.2001 8.05785 14.1147C8.02751 14.0293 8.01435 13.9387 8.01913 13.8482C8.02391 13.7577 8.04653 13.6691 8.08569 13.5874C8.12485 13.5056 8.17979 13.4325 8.24733 13.3721C8.31487 13.3116 8.3937 13.2652 8.47926 13.2353C8.56483 13.2055 8.65545 13.1929 8.74591 13.1982C8.83637 13.2035 8.9249 13.2266 9.00639 13.2662C9.08788 13.3059 9.16073 13.3612 9.22075 13.4291L10.0183 14.322L12.7793 11.23C12.9007 11.0943 13.071 11.0123 13.2528 11.0019C13.4346 10.9916 13.6131 11.0539 13.7491 11.175"
        fill="currentColor"
      />
    </>
  )
}

export default ArchieveList

import { z } from 'zod'

//Regular expression for mob validation
const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/

export const formSchema = z.object({
  City: z
    .string()
    .max(100, 'City must be 100 characters or less')
    .min(1, 'City is required'),
  BranchId: z.string().optional(),
  CompanyId: z.string().optional(),
  Town: z
    .string()
    .max(100, 'Town must be 100 characters or less')
    .min(1, 'Town is required'),
  Postcode: z
    .string()
    .max(30, 'Postcode must be 30 characters or less')
    .min(1, 'Postcode is required'),
  State: z
    .string()
    .max(100, 'State must be 100 characters or less')
    .min(1, 'State is required'),
  Telephone: z
    .string()
    .max(30, 'Telephone must be 30 characters or less')
    .min(1, 'Telephone is required'),
  Mobile: z
    .string()
    .transform((val) => (val === '' ? undefined : val))
    .refine(
      (val) => val === undefined || val === null || phoneRegex.test(val),
      'Invalid phone Number!'
    )
    .optional()
    .nullable()
    .refine(
      (val) => val === undefined || val === null || (val.length >= 6 && val.length <= 15),
      {
        message: 'Phone number must be between 6 and 15 characters long.'
      }
    ),
  Email: z
    .string()
    .email('Invalid email address')
    .max(100, 'Email must be 100 characters or less')
    .min(1, 'Email is required'),
  NearestPort: z.string().optional(),
  Address: z
    .string()
    .max(200, 'Address must be 200 characters or less')
    .optional()
    .nullable(),
  Archived: z.boolean().optional(),
  PortName: z.string().optional().nullable(),
  PortId: z.number().optional().nullable()
})

export type FormSchema = z.infer<typeof formSchema>

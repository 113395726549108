import React from 'react'

const PdfIcon: React.FC = () => {
  return (
    <path
      d="M12.4523 13.4625L12.4594 13.4297C12.5953 12.8695 12.7664 12.1711 12.6328 11.5383C12.5437 11.0391 12.1758 10.8445 11.8617 10.8305C11.4914 10.8141 11.1609 11.025 11.0789 11.332C10.9242 11.8945 11.0625 12.6633 11.3156 13.643C10.9969 14.4023 10.4883 15.5062 10.1156 16.1625C9.42188 16.5211 8.49141 17.0742 8.35312 17.7727C8.325 17.9016 8.35781 18.0656 8.43516 18.2133C8.52187 18.3773 8.66016 18.5039 8.82187 18.5648C8.89219 18.5906 8.97656 18.6117 9.075 18.6117C9.4875 18.6117 10.1555 18.2789 11.0461 16.7508C11.182 16.7063 11.3227 16.6594 11.4586 16.6125C12.0961 16.3969 12.757 16.1719 13.3547 16.0711C14.0156 16.425 14.768 16.6523 15.2789 16.6523C15.7852 16.6523 15.9844 16.3523 16.0594 16.1719C16.1906 15.8555 16.1273 15.457 15.9141 15.2438C15.6047 14.9391 14.8523 14.8594 13.6805 15.0047C13.1039 14.6531 12.7266 14.175 12.4523 13.4625ZM9.88125 17.0227C9.55547 17.4961 9.30937 17.7328 9.17578 17.8359C9.33281 17.5477 9.63984 17.243 9.88125 17.0227ZM11.9344 11.5031C12.0563 11.7117 12.0398 12.3422 11.9461 12.6609C11.8313 12.1945 11.8148 11.5336 11.8828 11.4562C11.9016 11.4586 11.918 11.4727 11.9344 11.5031ZM11.8969 14.3273C12.1477 14.7609 12.4641 15.1336 12.8133 15.4102C12.307 15.525 11.8453 15.7148 11.4328 15.8836C11.3344 15.9234 11.2383 15.9633 11.1445 16.0008C11.4563 15.4359 11.7164 14.7961 11.8969 14.3273ZM15.5438 15.8625C15.5461 15.8672 15.5484 15.8742 15.5344 15.8836H15.5297L15.525 15.8906C15.5063 15.9023 15.3141 16.0148 14.4867 15.6891C15.4383 15.6445 15.5414 15.8602 15.5438 15.8625ZM20.0297 6.76406L14.9859 1.72031C14.8453 1.57969 14.6555 1.5 14.4562 1.5H4.5C4.08516 1.5 3.75 1.83516 3.75 2.25V21.75C3.75 22.1648 4.08516 22.5 4.5 22.5H19.5C19.9148 22.5 20.25 22.1648 20.25 21.75V7.29609C20.25 7.09687 20.1703 6.90469 20.0297 6.76406ZM18.5203 7.64062H14.1094V3.22969L18.5203 7.64062ZM18.5625 20.8125H5.4375V3.1875H12.5156V8.25C12.5156 8.51107 12.6193 8.76145 12.8039 8.94606C12.9885 9.13066 13.2389 9.23438 13.5 9.23438H18.5625V20.8125Z"
      fill="currentColor"
    />
  )
}
export default PdfIcon

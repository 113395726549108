import {
  getTechnicalData,
  postTechnicalData,
  postTechnicalMipsData
} from '@/apis/api.helpers'
import { useQuery } from '@tanstack/react-query'
import apiTechnicalUrl from '../../apis/api.urls'

export const fetchBudget = async (filters: any) => {
  return await postTechnicalMipsData(
    `${apiTechnicalUrl.GET_BUDGET}/SearchVesselBudgets`,
    {
      ...filters
    }
  )
}
export const useBudgetList = (filters: any, isEnable: boolean) => {
  return useQuery({
    queryKey: ['tech-budget-list', filters],
    queryFn: () => fetchBudget(filters),
    //initialData: false,
    staleTime: 500,
    enabled: isEnable
  })
}

export const getBudgDynamicMenu = (id: string, token: string) => {
  return getTechnicalData(
    `${apiTechnicalUrl.GET_VESSEL_BUDGET}/GetMenu?id=${id}&src=${token}`
  )
}
export const getBudgetStatusHistory = (id: string) => {
  return getTechnicalData(`${apiTechnicalUrl.GET_BUDGET_STATUS_HISTORY}${id}`)
}
export const createBudget = (data: any) => {
  return postTechnicalData(`${apiTechnicalUrl.CREATE_BUDGET}`, data)
}

import { useEffect, useState } from 'react'

export interface breakpontProps {
  breakpoint: '' | 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  mode: 'mobile' | 'tab' | 'desktop'
  isMobile?: boolean
  width: number
  height: number
  isTab?: boolean
  customTab?: boolean
}

const useScreenBreakPoint = (): breakpontProps => {
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [height, setHeight] = useState<number>(window.innerHeight)
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  switch (true) {
    case width <= 576:
      return {
        breakpoint: '',
        width,
        height,
        mode: 'mobile',
        isMobile: true,
        isTab: false,
        customTab: height < 650 ? true : false
      }
    case width >= 1536:
      return {
        breakpoint: 'xxl',
        width,
        height,
        mode: 'desktop',
        customTab: height < 650 ? true : false
      }
    case width >= 1200:
      return {
        breakpoint: 'xl',
        mode: 'desktop',
        width,
        height,
        customTab: height < 650 ? true : false
      }
    case width >= 1100:
      return {
        breakpoint: 'lg',
        mode: 'tab',
        width,
        height,
        isTab: true,
        customTab: height < 650 ? true : false
      }
    case width >= 768:
      return {
        breakpoint: 'md',
        mode: 'tab',
        width,
        height,
        isTab: true,
        customTab: height < 650 ? true : false
      }
    case width >= 576:
      return {
        breakpoint: 'sm',
        mode: 'tab',
        width,
        height,
        isTab: true,
        customTab: height < 650 ? true : false
      }
    case width >= 320:
      return {
        breakpoint: 'xs',
        mode: 'mobile',
        width,
        height,
        isMobile: true,
        isTab: false,
        customTab: height < 650 ? true : false
      }
    default:
      return {
        breakpoint: '',
        mode: 'mobile',
        isMobile: true,
        width,
        height,
        isTab: false,
        customTab: height < 650 ? true : false
      }
  }
}

export default useScreenBreakPoint

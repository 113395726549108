import { TabItemProps } from '@/common/customComponenttypes'
import DashboardManageTabs from '@/components/app/Dashboard/ManageTabs'
import NoData from '@/components/app/Dashboard/NoData'
import DashboardTileList from '@/components/app/Dashboard/TileList'
import { Tab, TabContainer } from '@/components/common'
import { useHeaderStore } from '@/store/appStore'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppContext } from '@/store/context/appContext'
import { useDashBoradTabs } from './api'
import { DashboardListProps } from './schema'

const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const { data, isLoading, refetch } = useDashBoradTabs()
  const tabdata = data?.map((tab: DashboardListProps) => {
    return { ...tab, id: tab.DashId, label: tab.DashName }
  })
  const { setHeaderTitle } = useHeaderStore()

  const [active, setActive] = useState<string | number>()
  const [renameTab, setRenameTab] = useState<boolean>(false)
  const [persistTab, setPersistTab] = useState<boolean>(false)
  const [addNewTab, setAddNewTab] = useState<boolean>(false)
  const { setIsCommonLoader } = useAppContext()
  useEffect(() => {
    setIsCommonLoader(isLoading)
  }, [isLoading])
  const onTabClick = (item: TabItemProps) => {
    // const parentPath
    setActive(item.id)
  }

  const afterSubmit = () => {
    setPersistTab(true)
    manageTabSelect()
  }
  useEffect(() => {
    setHeaderTitle(t('Dashboards'))
  }, [])
  const handlePopupOpen = () => {
    setAddNewTab(true)
  }
  const handlePopupClose = () => {
    setAddNewTab(false)
  }
  const manageTabSelect = () => {
    if (data?.length > 0) {
      if (!renameTab) {
        if (persistTab) {
          setActive(data[data?.length - 1].DashId)
        } else {
          setActive(data[0].DashId)
        }
      }
    }
  }
  useEffect(() => {
    manageTabSelect()
  }, [data])

  return (
    <>
      <DashboardManageTabs
        handleClose={handlePopupClose}
        isOpen={addNewTab}
        afterSubmit={afterSubmit}
      />
      {/* <Headertitle title={t('dashboard')} /> */}

      {/* <div className="w-full h-full relative"> */}

      {data?.length ? (
        <TabContainer
          data={tabdata}
          activeTab={active as string}
          newTab
          tabBGClass=" border  border-b border-color-[#e7e7e7]"
          onClick={onTabClick}
          onAdd={handlePopupOpen}
        >
          {tabdata.map((comp: DashboardListProps) => (
            <Tab id={comp.id} key={comp.id} className="h-full w-full">
              <DashboardTileList
                dashboardId={comp.id}
                setRenameTab={setRenameTab}
                dashboardName={comp.DashName}
                key={comp.id}
                resetDashboard={refetch}
              />
            </Tab>
          ))}
        </TabContainer>
      ) : (
        <>
          {!isLoading && (
            <NoData
              title={t('dashboardEmptyHead')}
              subtitle={t('dashboardEmptySlogan')}
              button={{
                label: t('Create New'),
                icon: 'plus',
                primary: false,
                onClick: handlePopupOpen
              }}
            />
          )}
        </>
      )}
      {/* </div> */}
    </>
  )
}

export default Dashboard
